import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbSend } from 'react-icons/tb'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { gFilterConsultation } from '../../../atoms'
import { getFilterConsultationController, requestNewConsultationController } from '../../../controllers/alumni_controllers'
import { ButtonHover, Column, Form, Headline3, LoadingDialog, OutlineInput, OutlineTextArea, PrimaryButton, SelectView, Text } from '../../../shared/ui'
import { almWaitingConsultationRoute } from '../../../shared/variable'

function AlmCreateConsultationPage() {

  const result = useLoaderData()
  const navigateTo = useNavigate()

  const [listFilter, setListFilter] = useRecoilState(gFilterConsultation)
  const [initalCategory, setInitialCategory] = useState({ label: 'Pilih kategori', value: null })
  const [initialTopic, setInitialTopic] = useState({ label: 'Pilih topik', value: null })
  const [opsiCategory, setOpsiCategory] = useState([])
  const [opsiTopic, setOpsiTopic] = useState([])
  const [loading, setLoading] = useState(false)

  const getFilterFromLoader = () => {
    if (result.status) {
      const listTemp = [...result.data]
      const listFilterTemp = listTemp.filter((filter) => filter.topik_konsultasi.length > 0)
      if (listFilterTemp.length > 0) {
        listFilterTemp.sort((a, b) => a.kategori_konsultasi > b.kategori_konsultasi ? 1 : -1)

        const opsiCategoryTemp = []
        const opsiTopicTemp = []

        listFilterTemp.map((filter, idx) => {
          const newCategory = {
            label: filter.kategori_konsultasi,
            value: filter.id_kategori_konsultasi,
          }
          opsiCategoryTemp.push(newCategory)
          if (idx === 0) {
            const listTopic = [...filter.topik_konsultasi]
            listTopic.sort((a, b) => a.topik > b.topik ? 1 : -1)
            listTopic.map((topic, idxx) => {
              const newTopic = {
                label: topic.topik,
                value: topic.id_topik,
              }
              opsiTopicTemp.push(newTopic)
              if (idxx === 0) {
                setInitialTopic(newTopic)
              }
            })
            setInitialCategory(newCategory)
          }
        })

        setOpsiCategory(opsiCategoryTemp)
        setOpsiTopic(opsiTopicTemp)
      }
      setListFilter(listFilterTemp)
    }
  }

  const onChangeCategory = (category) => {
    const opsiTopicTemp = []
    const listTopic = [...listFilter.find((filter) => filter.id_kategori_konsultasi === category.value).topik_konsultasi]
    listTopic.sort((a, b) => a.topik > b.topik ? 1 : -1)
    listTopic.map((topic, idxx) => {
      const newTopic = {
        label: topic.topik,
        value: topic.id_topik,
      }
      opsiTopicTemp.push(newTopic)
      if (idxx === 0) {
        setInitialTopic(newTopic)
      }
    })
    setOpsiTopic(opsiTopicTemp)
    setInitialCategory(category)
  }

  const onSubmitForm = async (event) => {
    event.preventDefault()
    const subject = event.target.subject.value
    const problems = event.target.problems.value

    if (initalCategory.value !== null && initialTopic.value !== null && subject && problems) {
      setLoading(true)
      requestNewConsultation(subject, problems)
    } else {
      toast.error('Lengkapi isian formulir!')
    }
  }

  const requestNewConsultation = async (subject, problems) => {
    const props = {
      topic: initialTopic.value,
      subject: subject,
      problems: problems,
    }
    const result = await requestNewConsultationController(props)
    if (result.status) {
      setTimeout(() => {
        setLoading(false)
        toast.success(result.message)
        navigateTo(almWaitingConsultationRoute)
      }, 200)
    } else {
      setTimeout(() => {
        setLoading(false)
        toast.error(result.message)
      }, 200)
    }
  }

  useEffect(() => {
    getFilterFromLoader()
  }, [])

  return (
    <>
      <Column className='w-full max-w-4xl mx-auto gap-y-8 sm:px-14'>
        <Column className={'p-4 gap-y-6 bg-white border-y border-gray-200 sm:shadow-sm sm:p-8 lg:border-x lg:hover:shadow-md'}>
          <Column className={'gap-y-2'}>
            <Headline3 className={'font-semibold'}>
              Konsultasi Baru
            </Headline3>
            <Text>
              Pengajuan Konsultasi akan melalui proses Verifikasi oleh Admin Alumni UTY dan semua informasi terkait perkembangan pengajuan konsultasi akan dikirimkan melalui email.
            </Text>
          </Column>
          <Form onSubmit={onSubmitForm}>
            <Column className={'gap-y-6'}>
              <Column className={'gap-x-6 gap-y-6 lg:flex-row'}>
                <div className={'w-full lg:w-[60%]'}>
                  <SelectView
                    width='w-full'
                    label='Kategori Konsultasi'
                    bgColor='bg-gray-50'
                    helperText='Pilih salah satu kategori konsultasi'
                    initial={initalCategory}
                    options={opsiCategory}
                    onChangeOptions={(value) => onChangeCategory(value)}
                    zindex='z-[15]'
                    required />
                </div>
                <SelectView
                  width='w-full'
                  label='Topik Konsultasi'
                  bgColor='bg-gray-50'
                  helperText='Pilih salah satu topik konsultasi'
                  initial={initialTopic}
                  options={opsiTopic}
                  onChangeOptions={(value) => setInitialTopic(value)}
                  zindex='z-10'
                  required />
              </Column>
              <OutlineInput
                id='subject'
                inputWidth={'w-full'}
                label='Subjek Konsultasi'
                placeholder='Tuliskan subjek'
                helperText='Contoh: Langkah mulai berkarir setelah lulus'
                bgColor='bg-gray-50'
                required />
              <OutlineTextArea
                id='problems'
                inputWidth={'w-full'}
                bgColor='bg-gray-50'
                label={'Silakan tulis pertanyaan Anda di sini'}
                placeholder={'Tuliskan pertanyaan'}
                helperText={'Contoh: Apa saja yang harus disiapkan untuk mulai berkarir setelah lulus'}
                initialRow={8}
                maxLength={1000}
                required />
              <ButtonHover>
                <PrimaryButton
                  pills
                  type='submit'
                  color='indigo'
                  className={'pl-5 pr-6'}
                  leadingIcon={
                    <TbSend className={'h-5 w-5 rotate-12 my-auto'} />
                  }>
                  Kirim
                </PrimaryButton>
              </ButtonHover>
            </Column>
          </Form>
        </Column>
      </Column>
      <LoadingDialog
        show={loading}
        message='Mengirim konsultasi...' />
    </>
  )
}

export default AlmCreateConsultationPage

export const getFilterConsultation = async () => {
  const result = await getFilterConsultationController()
  return result;
}