import HTMLParse from "html-react-parser";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableRejectedComments from "./rows/row_rejected_comments";
import {
  AlertDialog,
  Avatar,
  Caption,
  Card,
  Column,
  DotSeparated,
  IconButton,
  LoadingDialog,
  Row,
  ShimmerAnimation,
  ShimmerItem,
  Subtitle,
} from "../../../../shared/ui";
import { TbMessageCircle, TbX } from "react-icons/tb";
import toast from "react-hot-toast";
import CCommentsRecognition from "../../../../components/list/item/item_comment_recognition";
import {
  deleteCommentsRecognition,
  undoRejectCommentRecognition,
} from "../../../../controllers/apicontrollers";

function TableRejectedComments({ listComments, page, limit, onShort }) {
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [comments, setComments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeAgo, setTimeAgo] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [userComment, setUserComment] = useState(null);

  const onHandleClick = (recognition) => {
    setShowDialog(true);
    setComments(recognition);
    setUserComment(recognition.comments);
  };

  const closeDialog = () => {
    setShowDialog();
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const filterComments = (idcom) => {
    const newComments = userComment.filter(
      (com) => com.id_comment_rekognisi !== idcom
    );
    setUserComment(newComments);
  };

  const undoRejectCurrentComment = async (idcom) => {
    setShowDialog(false);
    setLoading(true);
    const result = await undoRejectCommentRecognition(idcom);
    if (result.status) {
      setTimeout(() => {
        filterComments(idcom);
        setLoading(false);
        toast.success("Pembatalan reject komentar berhasil");
        return true;
      }, 200);
    } else {
      return false;
    }
  };

  const deleteCurrentComment = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await deleteCommentsRecognition(
      comments.id_comment_rekognisi
    );
    if (result.status) {
      setTimeout(() => {
        // filterComments(idCom);
        setLoading(false);
        toast.success("Delete komentar berhasil");
      }, 200);
    }
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Penulis</TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="title"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Judul Berita
            </TableHeadCell>
            <TableHeadCell>Komentar</TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listComments.length > 0 ? (
            listComments.map((comment, idx) => {
              return (
                <RowTableRejectedComments
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  comment={comment}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog
        className="max-w-4xl"
        show={showDialog}
        onClose={closeDialog}
      >
        <Column className="divide-y">
          {comments !== null && (
            <Row className="divide-x ">
              <Column className="w-full max-w-lg max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
                <Row className="gap-2.5 sticky top-0 bg-white p-6">
                  <Avatar />
                  <Column className="flex-1 my-auto">
                    <Caption className="font-bold font-manrope text-gray-800">
                      {comments.nama}
                    </Caption>
                    <Row>
                      <Caption>{comments.id_user}</Caption>
                      <DotSeparated />
                      <Caption>{timeAgo}</Caption>
                    </Row>
                  </Column>
                </Row>
                <Column className="px-6 gap-3 pb-6">
                  <Subtitle className="font-semibold">
                    {comments.title}
                  </Subtitle>
                  <Column className="text-base inter">
                    {HTMLParse(comments.body, options)}
                  </Column>
                </Column>
              </Column>
              <Column className="w-full max-w-sm min-h-[45rem] max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
                <Row className="gap-2.5 px-4 py-4 bg-white fixed top-0 z-10 border-b">
                  <Card className="rounded-full border-none bg-indigo-50 p-0 h-8 w-8 justify-center items-center">
                    <TbMessageCircle className="h-5 w-5 text-indigo-700" />
                  </Card>
                  <Subtitle className="font-semibold my-auto">
                    Komentar
                  </Subtitle>
                </Row>
                <Column className="px-4 pt-16 flex-col-reverse h-full overflow-y-auto scrollbar-hide scroll-smooth">
                  {userComment &&
                    userComment.map((comment, idx) => (
                      <div className="py-4">
                        <CCommentsRecognition
                          key={idx}
                          comment={comment}
                          action={30}
                          onUndo={undoRejectCurrentComment}
                          onDelete={deleteCurrentComment}
                        />
                      </div>
                    ))}
                </Column>
              </Column>
            </Row>
          )}
        </Column>
        <IconButton
          onClick={closeDialog}
          className="absolute top-1 right-1 z-10"
        >
          <TbX className="h-5 w-5" />
        </IconButton>
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
}

export default TableRejectedComments;
