import React from 'react'
import Cookies from 'universal-cookie'
import { identifyUserKey, loginRoute, mitra, refreshTokenKey, tokenApiKey } from '../shared/variable'
import jwtDecode from 'jwt-decode'
import { Navigate, Outlet } from 'react-router-dom'
import NotFound from '../pages/notfound'

const MitPrivateRoutes = () => {

    const cookies = new Cookies()

    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    const identifyUser = localStorage.getItem(identifyUserKey)
    const tokenApi = localStorage.getItem(tokenApiKey)

    let decodeCookies;
    let decodeLocal;

    if (tokenCookies != null) {
        decodeCookies = jwtDecode(tokenCookies)
    }
    if (tokenApi != null) {
        decodeLocal = jwtDecode(tokenApi)
    }

    return (
        tokenCookies !== null ? (identifyUser == mitra && decodeCookies.kode == mitra && decodeLocal.kode == mitra && decodeCookies.mitra != null && decodeLocal.mitra != null) ? <Outlet /> : <NotFound /> : <Navigate to={loginRoute} />
    )

}

export default MitPrivateRoutes