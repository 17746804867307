import React from 'react'
import { Outlet } from 'react-router-dom'
import AlmProfileSidebar from '../../../components/sidebar/alm_profile_sidebar'
import { Column } from '../../../shared/ui'

function AlmProfilePage() {
    return (
        <Column className={'w-full'}>
            <AlmProfileSidebar />
            <Column className={'w-full pt-20 pb-16 sm:pt-24 lg:pt-32 xl:pl-[18rem]'}>
                <Outlet />
            </Column>
        </Column>
    )
}

export default AlmProfilePage