import jwtDecode from "jwt-decode"
import Cookies from "universal-cookie"
import { refreshTokenKey, tokenApiKey } from "./variable"

export const getCurrentUser = () => {
    const cookies = new Cookies()
    const tokenCookies = cookies.get(refreshTokenKey) ?? null
    const token = localStorage.getItem(tokenApiKey) ?? null
    if (tokenCookies !== null) {
        if (token !== null) {
            return jwtDecode(token)
        }
    } else {
        return {
            name: null,
            username: null
        }
    }
}