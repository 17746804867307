import { useEffect, useState } from "react";
import {
  AlertDialog,
  ButtonHover,
  Card,
  Column,
  Form,
  Headline1,
  Headline3,
  LoadingView,
  OutlineInput,
  PrimaryButton,
  Row,
  Text,
  TextButton,
  TransitionPage,
} from "../shared/ui";
import bguty from "../images/bguty.jpeg";
import simfora from "../images/logo2.png";
import { TbAlertTriangle } from "react-icons/tb";
import { useCountDown } from "../hooks/useCountDown";
import { changePasswordController } from "../controllers/alumni_controllers";
import toast from "react-hot-toast";
import { countDownForgotKey } from "../shared/variable";
import "../../src/index.css";

const ForgotPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errMessageLimiter, setErrMessageLimiter] = useState(null);
  const [step, setStep] = useState(1);
  const [startCountDown, setStartCountDown] = useState(false);
  const { timeLeft, formatTime } = useCountDown({
    initialTime: startCountDown ? 900 : 0,
    text: "Kirim Verifikasi",
    storageKey: countDownForgotKey,
  });
  const [formData, setFormData] = useState({
    email: "",
    nim: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    const res = await changePasswordController(formData);
    if (res.status) {
      setLoading(true);
      setStartCountDown(false);
      setTimeout(() => {
        setStep(2);
      }, 200);
      toast.success("Berhasil Mengirim Verifikasi");
    } else if (res.message === "limiter") {
      setLoading(false);
      setStartCountDown(true);
      setErrorMessage(null);
      setErrMessageLimiter(
        "Request akses anda sudah lebih dari 3 kali, harap tunggu 15 menit lagi"
      );
      setShowDialog(true);
      return;
    } else {
      setLoading(false);
      setErrMessageLimiter(null);
      setErrorMessage(res.message);
      setShowDialog(true);
      return;
    }
  };

  useEffect(() => {
    if (!startCountDown) {
      setStartCountDown(false);
    }
  }, [startCountDown]);

  return (
    <>
      <TransitionPage>
        <div className="min-h-screen fixed w-full">
          <Row>
            <div className="w-full hidden lg:flex ">
              <img src={bguty} className="object-cover" />
            </div>
            <div className="w-full lg:max-w-2xl">
              <Column className="h-screen  ">
                <Column className="max-w-lg mx-auto my-auto p-6 gap-y-6">
                  <img src={simfora} className="w-44" />
                  <Column className="gap-y-2">
                    <Headline1 className="font-semibold">
                      Lupa Kata Sandi Akun{" "}
                      <span className="text-indigo-600">Alumni</span>
                    </Headline1>
                  </Column>
                  {step === 1 && (
                    <Column className="gap-y-10 pb-14">
                      <Form onSubmit={handleSubmitChangePassword}>
                        <Column className="relative gap-y-8">
                          <Text>
                            Silahkan masukkan email dan NIM untuk memverifikasi
                            akun anda.
                          </Text>
                          <OutlineInput
                            id="email"
                            className="py-3"
                            inputWidth="w-full"
                            bgColor="bg-gray-100"
                            label="Email"
                            placeholder="Masukkan email"
                            maxLength={30}
                            togglePass={true}
                            required={true}
                            defaultValue={formData.email}
                            onChange={handleInputChange}
                            name="email"
                          />
                          <OutlineInput
                            id="nim"
                            type="number"
                            className="no-spinner py-3"
                            inputWidth="w-full"
                            bgColor="bg-gray-100"
                            label="NIM"
                            placeholder="Masukkan NIM"
                            maxLength={20}
                            togglePass={true}
                            required={true}
                            defaultValue={formData.nim}
                            onChange={handleInputChange}
                            onKeyDown
                            name="nim"
                          />
                          <ButtonHover className="w-full mt-2">
                            <PrimaryButton
                              color="indigo"
                              type="submit"
                              className="w-full py-3"
                              disabled={timeLeft > 0}
                            >
                              {loading && <LoadingView />}
                              {loading
                                ? "Mengirim Verifikasi..."
                                : `${formatTime(timeLeft)}`}
                            </PrimaryButton>
                          </ButtonHover>
                        </Column>
                      </Form>
                    </Column>
                  )}
                  {step === 2 && (
                    <Column className="gap-y-10 pb-14 mt-10">
                      <Form>
                        <Column className="relative gap-y-8">
                          <Text>
                            Silahkan cek{" "}
                            <span className="text-indigo-600 font-medium">
                              <a
                                href="https://mail.google.com/"
                                target="_blank"
                              >
                                email
                              </a>
                            </span>{" "}
                            anda untuk melakukan verifikasi ganti kata sandi...
                          </Text>
                        </Column>
                      </Form>
                    </Column>
                  )}
                </Column>
              </Column>
            </div>
          </Row>
        </div>
      </TransitionPage>

      <AlertDialog
        className="max-w-xs"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <Column className="divide-y divide-gray-200">
          <Column className="px-5 py-10 items-center gap-8">
            <Card className="p-1.5 rounded-2xl bg-orange-50/50 border-orange-200/50">
              <TbAlertTriangle className="h-14 w-14 stroke-1.5 text-orange-600" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Terjadi Kesalahan</Headline3>
              <Text className="text-center">{errorMessage}</Text>
              {errMessageLimiter && (
                <Text className="text-center">{errMessageLimiter}</Text>
              )}
            </Column>
          </Column>
          <TextButton
            onClick={() => {
              setShowDialog(false);
            }}
            className="w-full py-4 text-base hover:bg-gray-50/50"
          >
            Tutup
          </TextButton>
        </Column>
      </AlertDialog>
    </>
  );
};

export default ForgotPasswordPage;
