import React from 'react'
import { Column, Row, ShimmerAnimation, ShimmerItem } from '../../shared/ui'

function SAlmCareer() {
  return (
    <ShimmerAnimation>
      <Column className={'gap-y-5 sm:gap-y-8'}>
        {Array.from({ length: 5 }, (_, idx) => {
          return (
            <Column
              key={idx}
              className={'p-4 gap-y-8 bg-white border-y border-gray-200 transition-all duration-200 sm:p-8 sm:border-x sm:shadow-sm'}>
              <Row className={'gap-x-3 items-center'}>
                <ShimmerItem className={'w-16 h-16 rounded-xl'} />
                <Column className='flex-1 gap-y-2'>
                  <ShimmerItem className={'w-[80%] h-10 rounded-xl'} />
                  <ShimmerItem className={'w-64 h-6 rounded-xl'} />
                </Column>
              </Row>
              <Column className={'gap-y-2'}>
                <ShimmerItem className={'w-32 h-6 rounded-xl'} />
                <ShimmerItem className={'w-44 h-6 rounded-xl'} />
                <ShimmerItem className={'w-56 h-6 rounded-xl'} />
              </Column>
              <Column className={'gap-y-2'}>
                <ShimmerItem className={'w-full h-6 rounded-xl'} />
                <ShimmerItem className={'w-[70%] h-6 rounded-xl'} />
              </Column>
              <Row className={'justify-between items-center'}>
                <ShimmerItem className={'w-48 h-5 rounded-xl'} />
                <ShimmerItem className={'w-32 h-11 rounded-full'} />
              </Row>
            </Column>
          )
        })}
      </Column>
    </ShimmerAnimation>
  )
}

export default SAlmCareer