import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./App.css";
import RouterApp from "./routes";
import {
  careerRoute,
  detailEventRoute,
  detailRecognitionRoute,
  eventRoute,
  landingRoute,
  loginRoute,
  notFoundRoute,
  recognitionRoute,
  registerMitraRoute,
  successRegMitraRoute,
} from "./shared/variable";
import AlumniLayout from "./layouts/alumni_layout";
import LandingPage from "./pages/landing";
import RecognitionPage from "./pages/recognition";
import DetailRecognitionPage from "./pages/detail_recognition";
import CareerAlumni from "./pages/career";
import EventPage from "./pages/event";
import DetailEventPage from "./pages/detail_event";
import LoginPage from "./pages/login";
import { router } from "./shared/route";
import toast, { Toaster } from "react-hot-toast";

function App() {
  // const beamsClient = new PusherPushNotifications.Client({
  //   instanceId: process.env.REACT_APP_PUSHER_APP_INSTANCE_ID,
  // })

  // beamsClient
  //   .start()
  //   .then((beamsClient) => beamsClient.getDeviceId())
  //   .then((deviceId) =>
  //     console.log("Successfully registered with Beams. Device ID:", deviceId)
  //   )
  //   .then(() => beamsClient.addDeviceInterest('hello'))
  //   .then(() => beamsClient.getDeviceInterests())
  //   .then((interests) => console.log('Current interests:', interests))
  //   .catch(console.error);

  return (
    <RecoilRoot>
      <RouterProvider router={router} />
      <Toaster />
    </RecoilRoot>
  );
}

export default App;
