import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  TbArrowRight,
  TbClockHour3,
  TbMapPin,
  TbUserCircle,
  TbWallet,
  TbX,
} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { acceptedLowongan } from "../../atoms";
import { getLowonganController } from "../../controllers/apicontrollers";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Card,
  Column,
  Headline2,
  HorizontalDivider,
  Image,
  PrimaryButton,
  Row,
  SecondaryButton,
  Subtitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TagView,
  Text,
  Title,
} from "../../shared/ui";
import { mitCareerRoute } from "../../shared/variable";
import RowTableAcceptedLowongan from "./rows/row_accepted_lowongan";

const TableAcceptedLowongan = ({ onDoneLoadData, action = 10 }) => {
  const location = useLocation();
  const navigateTo = useNavigate();
  const [listLowongan, setListLowongan] = useRecoilState(acceptedLowongan);
  const [currentLowongan, setCurrentLowongan] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sortTable, setShortTable] = useState(null);
  const [limitRow, setLimitRow] = useState(10);
  const [totalRow, setTotalRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);

  const getLowongan = async () => {
    let path = location.pathname;
    const length = path.split("/").length;
    const username = path.split("/")[length - 2];
    const props = {
      action: action,
      username: username,
      approve: "ACCEPTED",
      limit: limitRow,
      page: currentPage,
      search: searchQuery,
      order: sortTable !== null ? sortTable.key : null,
      asc: sortTable !== null ? sortTable.type : null,
    };
    const result = await getLowonganController(props);
    if (result.status) {
      setListLowongan(result.data);
      setTotalRow(result.totalRows);
      setTotalPage(result.totalPage);
    }
    onDoneLoadData(true);
  };

  const onShowDialog = (value) => {
    setShowDialog(true);
    setCurrentLowongan(value);
  };

  const onChangeShowNumber = (value) => {
    setCurrentPage(1);
    setLimitRow(value);
  };

  const onChangeQuerySearch = (value) => {
    setCurrentPage(1);
    setSearchQuery(value);
  };

  const onChangePageNumber = (value) => {
    setCurrentPage(value);
  };

  const onShortableValue = (value) => {
    setCurrentPage(1);
    setShortTable(value);
  };

  const applyLowongan = () => {
    setShowDialog(false);
    // navigateTo(action === 10 ? `${location.pathname}/${currentLowongan.id_lowongan}` : `${mitCareerRoute}/${currentLowongan.id_lowongan}`)
    navigateTo(`${mitCareerRoute}/${currentLowongan.id_lowongan}`);
  };

  useEffect(() => {
    getLowongan();
  }, [searchQuery, sortTable, limitRow, currentPage]);

  return (
    <>
      <Card className="w-full p-0">
        <Column>
          <Column className="px-5 py-4 gap-0.5">
            <Title>Lowongan Disetujui</Title>
            <Text>
              Semua data Lowongan yang sudah disetujui oleh Bidang Alumni
              Universitas Teknologi Yogyakarta
            </Text>
          </Column>
          <HorizontalDivider className="mt-0 mb-0" />
          <div className="p-5">
            <Table
              className="border-[1px] border-gray-200"
              limitRow={limitRow}
              totalRow={totalRow}
              totalPage={totalPage}
              trigerPagination={sortTable}
              onChangeShowNumber={onChangeShowNumber}
              onChangeQuerySearch={onChangeQuerySearch}
              onChangePageNumber={onChangePageNumber}
            >
              <TableHead>
                <TableRow className="bg-slate-100/50 border-b-[1px] border-b-gray-200">
                  <TableCell className="font-semibold font-manrope">
                    No.
                  </TableCell>
                  <TableCell className="font-semibold font-manrope">
                    Posisi
                  </TableCell>
                  <TableCell className="font-semibold font-manrope w-[25%]">
                    Deskripsi
                  </TableCell>
                  <TableCell className="font-semibold font-manrope">
                    Jumlah Pelamar
                  </TableCell>
                  <TableCell className="font-semibold font-manrope">
                    Dibuka
                  </TableCell>
                  <TableCell className="font-semibold font-manrope">
                    Ditutup
                  </TableCell>
                  <TableCell className="font-semibold font-manrope px-3 text-center">
                    Detail
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="divide-y-[1px] divide-gray-200">
                {listLowongan.length > 0 ? (
                  listLowongan.map((lowongan, idx) => {
                    return (
                      <RowTableAcceptedLowongan
                        key={idx}
                        idx={idx}
                        action={action}
                        limitRow={limitRow}
                        currentPage={currentPage}
                        lowongan={lowongan}
                        onShowDialog={onShowDialog}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      className="text-center text-gray-500"
                    >
                      No data to display
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </Column>
      </Card>
      <AlertDialog
        className="max-w-6xl"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        {currentLowongan !== null && (
          <Column>
            <Row
              className={`w-full divide-x-[1px] divide-gray-200 flex-col md:flex-row`}
            >
              <Column className="max-w-lg my-auto mx-auto px-8 py-8 ">
                <Card className="w-full my-auto rounded-lg relative p-0 overflow-hidden bg-slate-50">
                  <Image
                    src={currentLowongan.flyer}
                    className="aspect-[3/4]"
                    imgClasName="aspect-[3/4] object-cover"
                    hoverScale={false}
                  />
                </Card>
              </Column>
              <Column className="px-8 py-8 gap-y-8 max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth ">
                <Column className="gap-y-3">
                  <Headline2>{currentLowongan.posisi}</Headline2>
                  <Row className="gap-x-3 gap-y-3 flex-wrap">
                    <TagView
                      color="purple"
                      className="gap-x-2 py-2 pl-2.5 pr-4 rounded-lg"
                      leadingIcon={<TbClockHour3 className="h-5 w-5" />}
                      label={currentLowongan.jenis}
                    />
                    <TagView
                      color="rose"
                      className="gap-x-2 py-2 pl-2.5 pr-4 rounded-lg"
                      leadingIcon={<TbWallet className="h-5 w-5" />}
                      label={currentLowongan.gaji}
                    />
                    <TagView
                      color="blue"
                      className="gap-x-2 py-2 pl-2.5 pr-4 rounded-lg"
                      leadingIcon={<TbMapPin className="h-5 w-5" />}
                      label={currentLowongan.kota}
                    />
                  </Row>
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">
                    Deskripsi Pekerjaan:
                  </Subtitle>
                  <div
                    className="text-gray-800 font-inter text-base"
                    dangerouslySetInnerHTML={{
                      __html: currentLowongan.jobdesc
                        .replaceAll(
                          "<ul>",
                          '<ul class="list-disc space-y-2 pl-5">'
                        )
                        .replaceAll(
                          "<ol>",
                          '<ol class="list-decimal pl-6 space-y-2">'
                        )
                        .replaceAll("undefined", ""),
                    }}
                  />
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">Persyaratan:</Subtitle>
                  <div
                    className="text-gray-800 font-inter text-base"
                    dangerouslySetInnerHTML={{
                      __html: currentLowongan.persyaratan
                        .replaceAll(
                          "<ul>",
                          '<ul class="list-disc space-y-2 pl-5">'
                        )
                        .replaceAll(
                          "<ol>",
                          '<ol class="list-decimal pl-6 space-y-2">'
                        )
                        .replaceAll("undefined", ""),
                    }}
                  />
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">Ketentuan:</Subtitle>
                  <div
                    className="text-gray-800 font-inter text-base"
                    dangerouslySetInnerHTML={{
                      __html: currentLowongan.ketentuan
                        .replaceAll(
                          "<ul>",
                          '<ul class="list-disc space-y-2 pl-5">'
                        )
                        .replaceAll(
                          "<ol>",
                          '<ol class="list-decimal pl-6 space-y-2">'
                        )
                        .replaceAll("undefined", ""),
                    }}
                  />
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className=" font-semibold">Waktu:</Subtitle>
                  <Row className="gap-x-5">
                    <Card className="w-full py-3 px-4 bg-gray-50">
                      <Column className="gap-y-1">
                        <Caption>Lowongan dibuka mulai:</Caption>
                        <Text className="font-inter">
                          {moment(currentLowongan.berlaku_mulai).format(
                            "DD MMMM YYYY"
                          )}
                        </Text>
                      </Column>
                    </Card>
                    <div className="flex h-6 aspect-square rounded-full bg-gray-700 my-auto justify-center items-center">
                      <TbArrowRight className="h-5 w-5 text-white" />
                    </div>
                    <Card className="w-full py-3 px-4 bg-gray-50">
                      <Column className="gap-y-1">
                        <Caption>Lowongan ditutup pada:</Caption>
                        <Text className="font-inter">
                          {moment(currentLowongan.berlaku_hingga).format(
                            "DD MMMM YYYY"
                          )}
                        </Text>
                      </Column>
                    </Card>
                  </Row>
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">Keterangan:</Subtitle>
                  <Text>
                    {currentLowongan.keterangan?.length > 0
                      ? currentLowongan.keterangan
                      : "Tidak ada keterangan"}
                  </Text>
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">Kontak:</Subtitle>
                  <Text>{currentLowongan.contact_email}</Text>
                </Column>
              </Column>
            </Row>
            <HorizontalDivider removeMargin />
            <Row className="gap-x-3 justify-end px-8 pt-6 pb-8">
              {action === 20 && (
                <ButtonHover>
                  <PrimaryButton
                    color="indigo"
                    leadingIcon={<TbUserCircle className="h-5 w-5 my-auto" />}
                    className="pr-5"
                    onClick={applyLowongan}
                  >
                    Daftar Pelamar
                  </PrimaryButton>
                </ButtonHover>
              )}
              <ButtonHover>
                <SecondaryButton
                  leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                  className="pr-5"
                  onClick={() => setShowDialog(false)}
                >
                  Tutup
                </SecondaryButton>
              </ButtonHover>
            </Row>
          </Column>
        )}
      </AlertDialog>
    </>
  );
};

export default TableAcceptedLowongan;
