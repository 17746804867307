import MitraLayout from "../../layouts/mitra_layout";
import {
  ButtonHover,
  Column,
  Headline1,
  PrimaryButton,
  Row,
  TabPanel,
  Tabs,
  ToasterView,
  TransitionPage,
} from "../../shared/ui";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { mitAddCareerRoute } from "../../shared/variable";
import { useRecoilState } from "recoil";
import { currentIndexTabLowongan } from "../../atoms";
import { Suspense, lazy, useState } from "react";
import { TbChecks, TbRefresh, TbX } from "react-icons/tb";
import STable from "../../components/shimmer/stable";

const TableAcceptedLowongan = lazy(() =>
  import("../../components/tabels/accepted_lowongan")
);
const TableProcessedLowongan = lazy(() =>
  import("../../components/tabels/processed_lowongan")
);
const TableRejectedLowongan = lazy(() =>
  import("../../components/tabels/rejected_lowongan")
);

const MitCareerPage = () => {
  const navigateTo = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useRecoilState(
    currentIndexTabLowongan
  );
  const [showProgress, setShowProgress] = useState(true);

  const onChangeTabIndex = (index) => {
    setShowProgress(true);
    setCurrentTabIndex(index);
  };

  const navigatePage = () => {
    navigateTo(mitAddCareerRoute);
  };

  const onDoneLoadData = (value) => {
    setShowProgress(false);
  };

  return (
    <>
      <Column className={"p-6"}>
        <TransitionPage>
          <Column className="gap-y-5">
            <Row className="flex-col gap-5 sm:flex-row sm:justify-between">
              <Headline1>Lowongan</Headline1>
              <ButtonHover>
                <PrimaryButton
                  leadingIcon={<BiPlus className="h-5 w-5 my-auto" />}
                  className="w-fit pr-5"
                  color="indigo"
                  onClick={navigatePage}
                >
                  Buat Lowongan
                </PrimaryButton>
              </ButtonHover>
            </Row>
            <Tabs
              selectedTabIdx={currentTabIndex}
              onChangeTabIndex={onChangeTabIndex}
            >
              <TabPanel
                icon={<TbChecks className="h-5 w-5" />}
                title="Disetujui"
                textActiveColor="text-green-700"
              >
                <Suspense
                  fallback={
                    <STable
                      column={8}
                      listWidth={[
                        "w-12",
                        "w-32",
                        "w-72",
                        "w-20",
                        "w-20",
                        "w-20",
                        "w-16",
                      ]}
                      listAlign={[
                        "justify-start",
                        "justify-start",
                        "justify-start",
                        "justify-end",
                        "justify-end",
                        "justify-end",
                        "justify-end",
                      ]}
                    />
                  }
                >
                  <TableAcceptedLowongan
                    onDoneLoadData={onDoneLoadData}
                    action={20}
                  />
                </Suspense>
              </TabPanel>
              <TabPanel
                icon={<TbRefresh className="h-5 w-5" />}
                title="Menunggu"
                textActiveColor="text-blue-700"
              >
                <Suspense
                  fallback={
                    <STable
                      column={8}
                      listWidth={[
                        "w-12",
                        "w-32",
                        "w-72",
                        "w-32",
                        "w-20",
                        "w-16",
                      ]}
                      listAlign={[
                        "justify-start",
                        "justify-start",
                        "justify-start",
                        "justify-start",
                        "justify-center",
                        "justify-end",
                      ]}
                    />
                  }
                >
                  <TableProcessedLowongan
                    onDoneLoadData={onDoneLoadData}
                    action={20}
                  />
                </Suspense>
              </TabPanel>
              <TabPanel
                icon={<TbX className="h-5 w-5" />}
                title="Ditolak"
                textActiveColor="text-red-700"
              >
                <Suspense
                  fallback={
                    <STable
                      column={8}
                      listWidth={[
                        "w-12",
                        "w-32",
                        "w-72",
                        "w-32",
                        "w-20",
                        "w-16",
                      ]}
                      listAlign={[
                        "justify-start",
                        "justify-start",
                        "justify-start",
                        "justify-start",
                        "justify-center",
                        "justify-end",
                      ]}
                    />
                  }
                >
                  <TableRejectedLowongan
                    onDoneLoadData={onDoneLoadData}
                    action={20}
                  />
                </Suspense>
              </TabPanel>
            </Tabs>
          </Column>
        </TransitionPage>
        <ToasterView />
      </Column>
    </>
  );
};

export default MitCareerPage;
