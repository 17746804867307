import { axios_get} from "../services/apiservice";
import { gListConsultationApiUrl} from "../shared/variable";

//? GET -- LIST KONSULTASI
export const getConsultationController = async (action) => {
    const header = {}
    const params = {
        approve: 'ACCEPTED',
        status: action === 10 ? 'ONGOING' : action === 20 ? 'PENDING' : 'COMPLETE',
    }
    const result = await axios_get(gListConsultationApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}