import React from 'react'
import { TbBrandWhatsapp, TbCheck, TbHome } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { ButtonHover, Caption, Card, Column, FullScreen, Headline2, Row, Subtitle, TertiaryButton } from '../shared/ui'
import { landingRoute } from '../shared/variable'

const SuccessRegMitraPage = () => {

    const navigateTo = useNavigate()

    return (
        <>
            <FullScreen className='flex justify-center items-center'>
                <Card className='w-full max-w-4xl p-12'>
                    <Column className='gap-y-8'>
                        <Column className='gap-y-5'>
                            <Card className='rounded-full bg-green-600'>
                                <TbCheck className='h-10 w-10 text-white' />
                            </Card>
                            <Headline2>
                                Berhasil Mendaftar
                            </Headline2>
                        </Column>
                        <Column className='gap-y-3'>
                            <Subtitle className='font-inter font-normal'>
                                Halo, terima kasih sudah mendaftar sebagai Mitra UTY
                            </Subtitle>
                            <Subtitle className='font-inter font-normal'>
                                Selanjutnya, kami akan melakukan Verifikasi pada pendaftaran Kemitraan yang Anda ajukan berdasarkan Dokumen Legalitas yang sudah disertakan dalam proses pendaftaran. Jika Anda membutuhkan bantuan atau informasi lebih lanjut tentang Kemitraan UTY dapat menghubungi Kontak Person.
                            </Subtitle>
                        </Column>
                        <Caption className='font-inter font-normal'>
                            Kami akan mengirim perkembangan proses verifikasi pendaftaran Kemitraan Anda melalui Email.
                        </Caption>
                        <Row className='gap-x-3 mt-3 justify-end items-center'>
                            <ButtonHover>
                                <TertiaryButton onClick={() => navigateTo(landingRoute, { replace: true })} color='indigo' leadingIcon={<TbHome className='h-5 w-5 my-auto' />}>
                                    Beranda
                                </TertiaryButton>
                            </ButtonHover>
                            <a className='flex flex-row gap-x-2 px-4 py-2.5 font-manrope font-semibold transition-all duration-200 cursor-pointer rounded-lg bg-indigo-600 text-white hover:bg-indigo-700 hover:-translate-y-1' href='whatsapp://send/?phone=628561236465&text=Halo&type=phone_number&app_absent=0' target='_blank' rel='noreferrer'>
                                <TbBrandWhatsapp className='h-5 w-5 my-auto' /> Kontak Kami
                            </a>
                        </Row>
                    </Column>
                </Card>
            </FullScreen>
        </>
    )
}

export default SuccessRegMitraPage 