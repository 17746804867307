import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Column } from "../../../components/interfaces/block";
import { Card } from "../../../components/interfaces/card";
import { SearchInput } from "../../../components/interfaces/input";
import { BodyText, Headline2Text } from "../../../components/interfaces/text";
import {
  Pagination,
  PaginationOption,
  useDebounce,
} from "../../../components/interfaces/utility";
import { getRecognitionController } from "../../../controllers/admin_controllers";
import TableRejectedRecognition from "./tables/table_rejected_recognition";

function AdmRejectedRecognitionPage() {
  const result = useLoaderData();

  const [listRecognition, setListRecognition] = useState([]);
  const [limitRow, setLimitRow] = useState(10);
  const [totalRow, setTotalRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [currentSort, setCurrentSort] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [stsGetLoader, setStsGetLoader] = useState(false);

  const debounceSearch = useDebounce({ value: searchQuery, delay: 1000 });

  const getRejectedRecognitionFromLoader = () => {
    if (result.status) {
      setListRecognition(result.data);
      setTotalRow(result.totalRows);
      setTotalPage(result.totalPage);
    }
    setStsGetLoader(true);
  };

  const regetRejectedRecognition = async () => {
    const result = await getAdmRejectedRecognition({
      search: searchQuery,
      limit: limitRow,
      page: currentPage,
      order: currentSort !== null ? currentSort.key : null,
      asc: currentSort !== null ? currentSort.type : null,
    });
    if (result.status) {
      setListRecognition(result.data);
      setTotalRow(result.totalRows);
      setTotalPage(result.totalPage);
    }
  };

  const onHandlePaginationOption = (value) => {
    setLimitRow(value);
    setCurrentPage(1);
  };

  const onHandleSearch = (event) => {
    event.preventDefault();
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null);
    setCurrentPage(1);
    if (currentSort !== null) {
      setCurrentSort(null);
    }
  };

  const onHandlePagination = (value) => {
    setCurrentPage(value);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
  };

  useEffect(() => {
    getRejectedRecognitionFromLoader();
  }, []);

  useEffect(() => {
    if (stsGetLoader) {
      regetRejectedRecognition();
    }
  }, [debounceSearch, limitRow, currentPage, currentSort]);

  return (
    <Column className={"p-4 gap-y-5 sm:p-8"}>
      <Column className={"gap-y-2"}>
        <Headline2Text className={"font-semibold"}>
          Berita Ditolak
        </Headline2Text>
        <BodyText>
          Semua data berita alumni yang tidak mendapatkan verifikasi dari Bidang
          Alumni.
        </BodyText>
      </Column>
      <Card className={"w-full"}>
        <Column className={"gap-y-5"}>
          <Column className="gap-x-4 gap-y-2 md:flex-row md:justify-between">
            <PaginationOption
              initial={limitRow}
              onChange={onHandlePaginationOption}
            />
            <SearchInput
              defaultValue={searchQuery}
              placeholder="Cari berita"
              onChange={onHandleSearch}
            />
          </Column>
          <TableRejectedRecognition
            limit={limitRow}
            page={currentPage}
            listRecognition={listRecognition}
            onShort={onHandleSort}
            setListRecognition={setListRecognition}
          />
          {listRecognition.length > 0 && (
            <Pagination
              limitRow={limitRow}
              totalRow={totalRow}
              totalPage={totalPage}
              triger={currentSort !== null && currentSort.key}
              onChange={onHandlePagination}
            />
          )}
        </Column>
      </Card>
    </Column>
  );
}

export default AdmRejectedRecognitionPage;

export const getAdmRejectedRecognition = async ({
  status = "REJECTED",
  search = null,
  limit = 10,
  page = 1,
  order = null,
  asc = null,
}) => {
  const props = {
    status: status,
    search: search,
    limit: limit,
    page: page,
    order: order,
    asc: asc,
  };
  const result = await getRecognitionController(props);
  return result;
};
