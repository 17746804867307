import moment from 'moment';
import React from 'react'
import { TableCell, TableRow, Text, Tooltip } from '../../../shared/ui';

const RowTableSaranTracerStudi = ({ saran, idx, currentPage = 1, limitRow = 1 }) => {
    return (
        <TableRow className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50 cursor-pointer`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                <Text>
                    {saran.nimhsmsmh}
                </Text>
            </TableCell>
            <TableCell>
                <Tooltip message={saran.nmmhsmsmh}>
                    <Text className='capitalize line-clamp-2'>
                        {saran.nmmhsmsmh}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Text className='capitalize line-clamp-2 text-center'>
                    {saran.jenjang}
                </Text>
            </TableCell>
            <TableCell>
                <Tooltip message={saran.prodi}>
                    <Text className='capitalize line-clamp-2'>
                        {saran.prodi}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip message={saran.saran}>
                    <Text className='capitalize line-clamp-2'>
                        {saran.saran}
                    </Text>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}

export default RowTableSaranTracerStudi
