import React from "react";
import { getAdmRekapPenilaianRespondenController } from "../../../../controllers/admin_controllers";
import { useEffect } from "react";
import { useState } from "react";
import { Card } from "../../../../components/interfaces/card";
import { Column, Row } from "../../../../components/interfaces/block";
import {
  OverlineText,
} from "../../../../components/interfaces/text";
import Chart from "react-apexcharts";

function ChartRekapitulasiPenilaianComponents({ year, Labels, series }) {
  const [seriesLocal, setSeriesLocal] = useState([]);
  const [labelLocal, setLabelLocal] = useState([]);
  const colors = ["#5A8F7B", "#2196F3", "#F86F03", "#F44336"];
  const legendColors = [
    "bg-[#5A8F7B]",
    "bg-[#2196F3]",
    "bg-[#F86F03]",
    "bg-[#F44336]",
  ];

  const getOvResponPenilaian = async () => {
    const props = {
      tahun: year.value,
    };
    const result = await getAdmRekapPenilaianRespondenController(props);
    if (result.status) {
      setLabelLocal(result.label_penilaian);
      setSeriesLocal(result.data_total);
    }
  };
  useEffect(() => {
    if (year.value != null) {
      getOvResponPenilaian();
    }
  }, [year]);
  return (
      <Column>
          <Column>
            <div>
              <Chart
                height={300}
                type="donut"
                series={year.value != null ? seriesLocal : series}
                options={{
                  chart: {
                    width: 720,
                    height: 720,
                    type: "donut",
                    fontFamily: "inter",
                  },
                  labels: year.value != null ? labelLocal : Labels,
                  dataLabels: {
                    enabled: true,
                    fontSize: "12px",
                  },
                  colors: colors,
                  legend: {
                    show: false,
                    position: "bottom",
                    offsetX: 0,
                  },
                }}
              />
            </div>
            <ChartLegend
              labels={year.value != null ? labelLocal : Labels}
              colors={legendColors}
            />
          </Column>
      </Column>
  );
}

export default ChartRekapitulasiPenilaianComponents;

export const ChartLegend = ({ labels, colors }) => {
  return (
    <Row className={"px-6 pb-6 gap-x-3 gap-y-2 flex-wrap justify-center"}>
      {labels.map((label, idx) => {
        return (
          <Row key={idx} className={"w-fit gap-1.5"}>
            <Card
              className={`${colors[idx]} border-none p-1.5 rounded-full my-auto`}
            />
            <OverlineText className={"my-auto text-gray-800 font-normal"}>
              {label}
            </OverlineText>
          </Row>
        );
      })}
    </Row>
  );
};
