import React from 'react'
import { Headline1 } from '../../shared/ui'
import DesignLayout from '../../layouts/design_layout'

const DesignUI = () => {
    return (
        <DesignLayout currentPage='/design'>
            <Headline1>
                Design Components
            </Headline1>
        </DesignLayout>
    )
}

export default DesignUI
