import moment from 'moment'
import { useEffect, useState } from 'react'
import { TbArrowForward, TbTrash } from 'react-icons/tb'
import { Avatar, Caption, Column, Overline, Row, TextButton } from '../../../shared/ui'

function ItemChat({ action, chat, konName, almName, replyChat, onReply, onDelete }) {

  const [showNew, setShowNew] = useState(false)

  useEffect(() => {
    if (chat.created_at !== null && ((action === 10 && chat.from_who === 4) || (action === 20 && chat.from_who === 2))) {
      const timeNow = moment()
      const lastChat = moment(chat.created_at);
      const diff = timeNow.diff(lastChat);
      const diffDuration = moment.duration(diff);

      if (diffDuration.hours() < 1 && diffDuration.minutes() <= 10) {
        setShowNew(true)
      } else {
        if (showNew) {
          setShowNew(false)
        }
      }
    } else {
      if (showNew) {
        setShowNew(false)
      }
    }
  }, [chat])

  return (
    <Column className={'px-4 py-3 gap-y-3 sm:px-6 sm:py-5'}>
      <Row className={'gap-x-2.5'}>
        <Avatar className={'h-8 w-8 translate-y-[3px]'} />
        <Column className={'flex-1 gap-y-0.5'}>
          <Caption className={'font-medium text-gray-800'}>
            {chat.from_who === 4 ? konName ?? 'Konselor' : almName ?? 'Alumni'}
          </Caption>
          <Overline className={'font-medium'}>
            {moment(chat.created_at).format('DD/MM/YYYY HH.mm')} WIB
          </Overline>
        </Column>
        {
          showNew && <Overline className={'translate-y-[1px] font-medium text-pink-600'}>
            BARU
          </Overline>
        }
      </Row>
      {replyChat !== null && <Column className={'px-4 py-2 border-l-[3px] gap-y-1 rounded-l-sm border-l-gray-500 bg-gray-50 rounded-md'}>
        <Overline className={'font-medium text-gray-800'}>
          Mambalas
        </Overline>
        <Overline className={'line-clamp-2 font-normal whitespace-pre-line'}>
          {replyChat.chat}
        </Overline>
      </Column>}
      <Caption className={'text-gray-800 whitespace-pre-line'}>
        {chat.chat}
      </Caption>
      <Row className={'gap-x-4 items-center justify-between'}>
        <TextButton
          leadingIcon={
            <TbArrowForward className={'h-4 w-4 my-auto'} />
          }
          className={'text-sm hover:text-indigo-600'}
          onClick={() => onReply(chat)}>
          Balas
        </TextButton>
        {((action === 10 && chat.from_who === 2) || (action === 20 && chat.from_who === 4)) &&
          <TextButton
            leadingIcon={
              <TbTrash className={'h-4 w-4 my-auto'} />
            }
            className={'text-sm hover:text-red-600'}
            onClick={() => onDelete(chat)}>
            Hapus
          </TextButton>
        }
      </Row>
    </Column>
  )
}

export default ItemChat