import React from 'react'
import { ShimmerAnimation, ShimmerItem } from '../../shared/ui'

const SAlmDetailLikeRecognition = () => {
    return (
        <ShimmerAnimation className='my-3'>
            <ShimmerItem className='w-full h-20 rounded-xl' />
        </ShimmerAnimation>
    )
}

export default SAlmDetailLikeRecognition