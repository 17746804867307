import React, { useEffect } from 'react'
import { TbBookmark, TbStack2 } from 'react-icons/tb'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { savedRecognition } from '../../../atoms'
import ItemSavedOrTagRecognition from '../../../components/list/item/item_savedortag_recognition'
import { getSavedRecognitionController } from '../../../controllers/alumni_controllers'
import { ButtonHover, Column, Headline3, TertiaryButton, Text } from '../../../shared/ui'
import { recognitionRoute } from '../../../shared/variable'

function AlmSavedProfilePage() {

  const result = useLoaderData()

  const navigateTo = useNavigate()

  const [listSavedRecognition, setListSavedRecognition] = useRecoilState(savedRecognition)

  const getSavedFromLoader = () => {
    if (result.status) {
      setListSavedRecognition(result.data)
    }
  }

  useEffect(() => {
    getSavedFromLoader()
  }, [])

  return (
    <Column className='w-full max-w-4xl mx-auto gap-y-5 sm:gap-y-8 sm:px-14'>
      {
        listSavedRecognition.length > 0 ?
          listSavedRecognition.map((saved, idx) => {
            return <div className='mx-4 sm:mx-0'><ItemSavedOrTagRecognition
              key={idx}
              recognition={saved} /></div>
          })
          : <Column className={'items-center gap-y-6 px-4 py-16 bg-white border-y lg:border-x lg:border-gray-200'}>
            <div className={'p-4 bg-gray-50 rounded-full'}>
              <TbBookmark className={'h-20 w-20 text-gray-300 stroke-1.5'} />
            </div>
            <Column className={'items-center gap-y-2'}>
              <Headline3 className={'text-gray-800'}>
                Berita Tersimpan
              </Headline3>
              <Text className={'text-center text-gray-500'}>
                Saat ini belum terdapat berita alumni yang Anda simpan
              </Text>
            </Column>
            <ButtonHover>
              <TertiaryButton
                pills
                color='indigo'
                className={'pl-5 pr-6'}
                leadingIcon={
                  <TbStack2 className={'h-5 w-5 my-auto'} />
                }
                onClick={() => navigateTo(recognitionRoute)}>
                Eksplor Berita
              </TertiaryButton>
            </ButtonHover>
          </Column>
      }
    </Column>
  )
}

export default AlmSavedProfilePage

export const getSavedRecognition = async () => {
  const result = await getSavedRecognitionController()
  return result
}