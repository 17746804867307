import React from 'react'
import { Card, Column, GridCol, Row, ShimmerAnimation, ShimmerItem } from '../../shared/ui'

const SAdmOvRecognition = () => {
    return (
        <GridCol className='grid-cols-1 gap-5 xl:grid-cols-4'>
            <Card className='w-full h-full px-5 py-5'>
                <ShimmerAnimation>
                    <Column className='gap-3'>
                        <ShimmerItem className='h-5 w-44' />
                        <Row className='justify-between'>
                            <ShimmerItem className='h-12 w-24 rounded-lg' />
                            <ShimmerItem className='h-7 w-16 mt-auto' />
                        </Row>
                    </Column>
                </ShimmerAnimation>
            </Card>
            <Card className='w-full h-full px-5 py-5'>
                <ShimmerAnimation>
                    <Column className='gap-3'>
                        <ShimmerItem className='h-5 w-44' />
                        <Row className='justify-between'>
                            <ShimmerItem className='h-12 w-24 rounded-lg' />
                            <ShimmerItem className='h-7 w-16 mt-auto' />
                        </Row>
                    </Column>
                </ShimmerAnimation>
            </Card>
            <Card className='w-full h-full px-5 py-5'>
                <ShimmerAnimation>
                    <Column className='gap-3'>
                        <ShimmerItem className='h-5 w-44' />
                        <Row className='justify-between'>
                            <ShimmerItem className='h-12 w-24 rounded-lg' />
                            <ShimmerItem className='h-7 w-16 mt-auto' />
                        </Row>
                    </Column>
                </ShimmerAnimation>
            </Card>
            <Card className='w-full h-full px-5 py-5'>
                <ShimmerAnimation>
                    <Column className='gap-3'>
                        <ShimmerItem className='h-5 w-44' />
                        <Row className='justify-between'>
                            <ShimmerItem className='h-12 w-24 rounded-lg' />
                            <ShimmerItem className='h-7 w-16 mt-auto' />
                        </Row>
                    </Column>
                </ShimmerAnimation>
            </Card>
        </GridCol>
    )
}

export default SAdmOvRecognition
