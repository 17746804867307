import HTMLParse from "html-react-parser";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableProcessedRecognition from "./rows/row_processed_recognition";
import {
  AlertDialog,
  Avatar,
  Caption,
  Column,
  IconButton,
  LoadingDialog,
  PrimaryButton,
  Row,
  SecondaryButton,
  ShimmerAnimation,
  ShimmerItem,
  Subtitle,
  Text,
  Title,
} from "../../../../shared/ui";
import { TbCheck, TbTrash, TbX } from "react-icons/tb";
import {
  approveRecognition,
  deleteRecognition,
  getFullNameUser,
  rejectRecognition,
} from "../../../../controllers/apicontrollers";
import toast from "react-hot-toast";
import WListImageRecognition from "../../../../components/wlist_image_recognition";

function TableProcessedRecognition({
  listRecognition,
  setListRecognition,
  page,
  limit,
  onShort,
  action = 10,
}) {
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setuser] = useState(null);
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [images, setImages] = useState(null);
  const [rasio, setRasio] = useState(null);
  const [idRekognisi, setIdRekognisi] = useState(null);
  const [recognition, setRecognition] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [tags, setTags] = useState([]);

  const filterRecognition = () => {
    const updateRecognition = listRecognition.filter(
      (rec) => rec.id_rekognisi !== recognition.id_rekognisi
    );
    setListRecognition(updateRecognition);
  };

  const openDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const onHandleClick = (recognition) => {
    setShowDialog(true);
    setuser(recognition.id_user);
    setTitle(recognition.title);
    setBody(recognition.body);
    setRasio(recognition.ratio);
    setImages(recognition.images);
    setIdRekognisi(recognition.id_rekognisi);
    setRecognition(recognition);
    setAvatar(recognition.avatar);
    setTags(recognition.tags);
  };

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  const approveCurrentRecognition = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await approveRecognition(idRekognisi);
    if (result.status) {
      setTimeout(() => {
        filterRecognition();
        setLoading(false);
        toast.success("Approved rekognisi berhasil");
      }, 200);
    }
  };

  const rejectCurrentRecognition = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await rejectRecognition(recognition.id_rekognisi);
    if (result.status) {
      setTimeout(() => {
        filterRecognition();
        setLoading(false);
        toast.success("Rejected rekognisi berhasil");
      }, 200);
    }
  };

  const deleteCurrentRecognition = async () => {
    setShowDeleteDialog(false);
    setLoading(true);
    const result = await deleteRecognition(recognition.id_rekognisi);
    if (result.status) {
      setTimeout(() => {
        filterRecognition();
        setLoading(false);
        toast.success("Hapus rekognisi berhasil");
      }, 200);
    }
  };

  const [fullName, setFullName] = useState(null);

  const getFullNameOfUser = async () => {
    const result = await getFullNameUser(
      recognition.id_identify,
      recognition.id_user
    );
    if (result.status) {
      setFullName(result.data);
    }
  };

  useEffect(() => {
    getFullNameOfUser();
  }, [recognition]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Penulis</TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="title"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Judul Berita
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="created_at"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Pengajuan
            </TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listRecognition.length > 0 ? (
            listRecognition.map((recognition, idx) => {
              return (
                <RowTableProcessedRecognition
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  recognition={recognition}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog
        className="max-w-lg"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <Column className="divide-y">
          <Column className="max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
            <Row className="gap-2.5 px-6 pt-6 pb-5 sticky top-0 bg-white z-10">
              <Avatar src={avatar} />
              <Column className="flex-1">
                <Caption className="w-fit font-bold jakarta text-gray-800">
                  {recognition && recognition.id_user ? (
                    fullName
                  ) : (
                    <ShimmerAnimation>
                      <ShimmerItem className={"w-32 h-4"} />
                    </ShimmerAnimation>
                  )}
                </Caption>

                <Caption>{user}</Caption>
              </Column>
            </Row>
            <Column className="gap-3 px-6 pb-10">
              <WListImageRecognition
                images={images}
                aspectRatio={rasio}
                action={20}
              />
              <Subtitle className="font-semibold">{title}</Subtitle>
              <Column className="text-base inter">
                {body && typeof body === "string"
                  ? HTMLParse(body, options)
                  : null}
              </Column>
              {recognition !== null ? (
                <Caption className="w-fit font-bold jakarta text-gray-800">
                  <span className="font-normal inter">Diposting oleh </span>
                  <Caption className="font-bold font-manrope text-gray-800">
                    {fullName}
                  </Caption>
                  {recognition.tags.length > 0 && (
                    <span className="font-normal inter"> bersama </span>
                  )}
                  {recognition.tags.length > 0 &&
                    tags.map(
                      (tag, idx) => idx === 0 && <span>{tag.nama}</span>
                    )}
                  {recognition.tags.length > 1 && <span>{" dan "}</span>}
                  {recognition.tags.length > 1 && (
                    <span>{recognition.tags.length - 1} lainnya</span>
                  )}
                </Caption>
              ) : (
                <ShimmerAnimation>
                  <ShimmerItem className="h-4 w-32 mb-1" />
                </ShimmerAnimation>
              )}
            </Column>
          </Column>

          {action === 10 ? (
            <Row className="gap-3 justify-end px-5 py-5">
              <SecondaryButton
                className="w-fit pr-5"
                color="red"
                onClick={rejectCurrentRecognition}
              >
                <TbX className="h-5 w-5" /> Reject
              </SecondaryButton>
              <PrimaryButton
                className="w-fit pr-5"
                color="indigo"
                onClick={approveCurrentRecognition}
              >
                <TbCheck className="h-5 w-5" /> Approve
              </PrimaryButton>
            </Row>
          ) : (
            <Row className="gap-3 justify-end px-5 py-5">
              <SecondaryButton
                className="w-fit pr-5"
                onClick={() => setShowDialog(false)}
              >
                <TbX className="h-5 w-5" /> Tutup
              </SecondaryButton>
              <PrimaryButton
                className="w-fit pr-5 flex-shrink-0"
                color="red"
                onClick={openDeleteDialog}
              >
                <TbTrash className="h-5 w-5" /> Hapus Rekognisi
              </PrimaryButton>
            </Row>
          )}
        </Column>
        <IconButton
          onClick={() => setShowDialog(false)}
          className="absolute top-1 right-1 z-10"
        >
          <TbX className="h-5 w-5" />
        </IconButton>
      </AlertDialog>
      <AlertDialog
        show={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <Column className="divide-y">
          <Column className="gap-5 p-6">
            <Column className="gap-1">
              <Title className="font-bold">Hapus Rekognisi</Title>

              <Text>
                Apakah anda yakin ingin menghapus rekognisi{" "}
                <span className="font-medium text-indigo-700">{title}</span>?
              </Text>
            </Column>
          </Column>
          <Row className="gap-3 justify-end px-6 py-5">
            <SecondaryButton
              className="w-fit pr-5"
              onClick={() => setShowDeleteDialog(false)}
            >
              <TbX className="h-5 w-5" /> Batal
            </SecondaryButton>
            <PrimaryButton
              className="w-fit pr-5"
              color="red"
              onClick={deleteCurrentRecognition}
            >
              <TbTrash className="h-5 w-5" /> Ya, Hapus
            </PrimaryButton>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
}

export default TableProcessedRecognition;
