import { useEffect, useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { Column, GridCol, Row } from "../../../components/interfaces/block";
import { Card } from "../../../components/interfaces/card";
import { SearchInput, SelectInput } from "../../../components/interfaces/input";
import { BodyText, CaptionText, Headline2Text, TitleText } from "../../../components/interfaces/text";
import { Pagination, PaginationOption } from "../../../components/interfaces/utility";
import { getExportExitSurveyController, getInputExitSurveyController } from "../../../controllers/admin_controllers";
import { getFilterTracerController } from "../../../controllers/global_controllers";
import useDownloadExcel from "../../../hooks/useDownloadExcel";
import { ButtonHover, DotSeparated } from "../../../shared/ui";
import ChartJenisPerusahaan from "./charts/chart_jenis_perusahaan";
import ChartPosisiWirausaha from "./charts/chart_posisi_wirausaha";
import ChartStatusLulusan from "./charts/chart_status_lulusan";
import ChartTingkatPerusahaan from "./charts/chart_tingkat_perusahaan";
import TableInputExitSurvey from "./tables/table_input_exit_survey";
import TableMetodeCariKerja from "./tables/table_metode_cari_kerja";
import TableProsesCariKerja from "./tables/table_proses_cari_kerja";

function AdmExitSurveyPage({ binaryData }) {

  const [currentProdi, setCurrentProdi] = useState({ label: 'Semua Program Studi', value: null })
  const [currentStatus, setCurrentStatus] = useState({ label: 'Semua Status', value: null })
  const [currentWisuda, setCurrentWisuda] = useState({ label: 'Semua Periode', value: null })

  const [filterProdi, setFilterProdi] = useState([])
  const [filterWisuda, setFilterWisuda] = useState([])
  const [filterStatus, setFilterStatus] = useState([
    {
      label: 'Semua Status',
      value: null,
    },
    {
      label: 'Bekerja',
      value: 1,
    },
    {
      label: 'Wiraswasta',
      value: 3,
    },
    {
      label: 'Belum Bekerja',
      value: 2,
    },
  ])

  const [listExitSurvey, setListExitSurvey] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [limitRow, setLimitRow] = useState(10);
  const [searchQuery, setSearchQuery] = useState(null)
  const [totalRow, setTotalRow] = useState(null)
  const [totalPage, setTotalPage] = useState(null)

  const fileName = `Data Exit Survey ${currentProdi.label} ${currentWisuda.label}`
  const downloadExcelFill = useDownloadExcel({ fileName });

  const getInputExitSurvey = async () => {
    const res = await getInputExitSurveyController({
      page: currentPage,
      limit: limitRow,
      search: searchQuery,
      prodi: currentProdi.value,
      status: currentStatus.value,
      tglwisuda: currentWisuda.value,
    });
    if (res.status) {
      setListExitSurvey(res.data?.data || [])
      setTotalRow(res.data?.totalRows || 1)
      setTotalPage(res.data?.totalPage || 1)
    } else {
      setListExitSurvey([])
    }
  };

  const getMasterFilter = async () => {
    const res = await getFilterTracerController()
    if (res.status) {
      const prodi = res.data.prodi
      const wisuda = res.data.tgl_wisuda

      const listProdi = []
      const listWisuda = []

      wisuda.map((tgl) => {
        const t = {
          label: tgl,
          value: tgl
        }
        listWisuda.unshift(t)
      })
      listWisuda.unshift({ label: 'Semua Periode', value: null })

      prodi.map((prodi) => {
        const p = {
          label: prodi.label,
          value: prodi.kode_prodi
        }
        listProdi.unshift(p)
      })
      listProdi.unshift({ label: 'Semua Program Studi', value: null })

      setFilterProdi(listProdi)
      setFilterWisuda(listWisuda)
    }
  }

  const onHandlePaginationOption = (value) => {
    setLimitRow(value)
    setCurrentPage(1)
  }

  const onHandleSearch = (event) => {
    event.preventDefault()
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null)
    setCurrentPage(1)
  }

  const onHandlePagination = (value) => {
    setCurrentPage(value)
  }

  const onChangeStatus = (value) => {
    setCurrentStatus(value)
  }

  const onChangeProdi = (value) => {
    setCurrentProdi(value)
  }

  const onChangeWisuda = (value) => {
    setCurrentWisuda(value)
  }

  const exportExcel = async () => {
    const res = await getExportExitSurveyController({
      prodi: currentProdi.value,
      status: currentStatus.value,
      tglwisuda: currentWisuda.value,
    });
    downloadExcelFill(res);
  };

  useEffect(() => {
    getMasterFilter()
    getInputExitSurvey()
  }, [currentPage, limitRow, searchQuery, currentProdi, currentStatus, currentWisuda])

  return (
    <Column className={"p-4 gap-y-5 sm:p-8"}>
      <Column className={'gap-y-2 flex-1'}>
        <Headline2Text>
          Exit Survey
        </Headline2Text>
        <BodyText>
          Data Exit Survey dari {currentProdi.value === null ? 'Seluruh Program Studi' : `Prodi ${currentProdi.label}`} {currentWisuda.value === null ? 'pada Semua Tanggal Wisuda' : `pada Tanggal Wisuda ${currentWisuda.value}`}.
        </BodyText>
      </Column>
      <Row className="gap-3">
        <div>
          <SelectInput
            width='w-full sm:w-80 bg-white'
            initial={currentProdi}
            options={filterProdi}
            onChange={onChangeProdi}
            zindex='z-[22]' />
        </div>
        <div>
          <SelectInput
            width='w-full sm:w-52 bg-white'
            initial={currentWisuda}
            options={filterWisuda}
            onChange={onChangeWisuda}
            zindex='z-[21]' />
        </div>
      </Row>
      <Card className={'w-full'}>
        <Column className={'gap-y-5'}>
          <Row className="gap-x-5">
            <Column className="gap-y-1">
              <TitleText>
                Rekap Data Exit Survey
              </TitleText>
              <Row>
                <CaptionText>
                  {
                    currentProdi.label
                  }
                </CaptionText>
                <DotSeparated />
                <CaptionText>
                  {
                    currentWisuda.label
                  }
                </CaptionText>
              </Row>
            </Column>
            <ButtonHover>
              <button
                onClick={() => exportExcel(binaryData)}
                className="flex border rounded-md px-4 py-2 gap-x-2 bg-gray-50 "
              >
                Download
                <SiMicrosoftexcel className="w-6 h-6 text-gray-500" />
              </button>
            </ButtonHover>
          </Row>
          <div>
            <SelectInput
              width='w-full sm:w-52 bg-white'
              initial={currentStatus}
              options={filterStatus}
              onChange={onChangeStatus}
              zindex='z-20' />
          </div>
          <Column className='gap-x-4 gap-y-2 md:flex-row md:justify-between'>
            <PaginationOption
              initial={limitRow}
              onChange={onHandlePaginationOption} />
            <SearchInput
              defaultValue={searchQuery}
              placeholder='Cari alumni'
              onChange={onHandleSearch} />
          </Column>
          <TableInputExitSurvey
            limit={limitRow}
            page={currentPage}
            listExitSurvey={listExitSurvey} />
          {listExitSurvey.length > 0 && <Pagination
            limitRow={limitRow}
            totalRow={totalRow}
            totalPage={totalPage}
            onChange={onHandlePagination} />}
        </Column>
      </Card>
      <div>
        <GridCol className={"grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5"}>
          <ChartStatusLulusan prodi={currentProdi} wisuda={currentWisuda} />
          <ChartJenisPerusahaan prodi={currentProdi} wisuda={currentWisuda} />
          <ChartTingkatPerusahaan prodi={currentProdi} wisuda={currentWisuda} />
          <ChartPosisiWirausaha prodi={currentProdi} wisuda={currentWisuda} />
        </GridCol>
      </div>
      <TableMetodeCariKerja prodi={currentProdi} wisuda={currentWisuda} />
      <TableProsesCariKerja prodi={currentProdi} wisuda={currentWisuda} />
    </Column>
  )
}

export default AdmExitSurveyPage;