import { useState } from "react";
import {
  Column,
  Headline2,
  TabPanel,
  Tabs,
  TransitionPage,
} from "../../shared/ui";
import { useRecoilState } from "recoil";
import { currentIndexTabTracer } from "../../atoms";
import { TbChartDonut2, TbClipboardList, TbSchool } from "react-icons/tb";
import TableRekapKuesionerTracer from "../../components/tabels/rekap_kuesioner_tracer";
import TableRekapWisudaTracer from "../../components/tabels/rekap_wisuda_tracer";
import OvTracer from "../../components/overview/ov_tracer";
import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";

const ProTracerPage = () => {
  const [currentTabIndex, setCurrentTabIndex] = useRecoilState(
    currentIndexTabTracer
  );
  const [showProgress, setShowProgress] = useState(true);
  const [filterTahun, setFilterTahun] = useState([]);
  const [currentYear, setCurrentYear] = useState({
    label: "Semua tahun",
    value: null,
  });

  const result = useLoaderData()

  const onChangeTabIndex = (index) => {
    setShowProgress(true);
    setCurrentTabIndex(index);
  };

  const onDoneLoadData = (value) => {
    setShowProgress(false);
  };

  return (
    <TransitionPage>
      <Column className="gap-5">
        <Headline2>Tracer Studi</Headline2>
        <Tabs
          selectedTabIdx={currentTabIndex}
          onChangeTabIndex={onChangeTabIndex}
        >
          <TabPanel
            icon={<TbChartDonut2 className="h-5 w-5" />}
            title="Overview"
          >
            <OvTracer onDoneLoadData={onDoneLoadData} action={20} />
          </TabPanel>
          <TabPanel
            icon={<TbClipboardList className="h-5 w-5" />}
            title="Kuesioner"
          >
            <TableRekapKuesionerTracer
              onDoneLoadData={onDoneLoadData}
              action={20}
            />
          </TabPanel>
          <TabPanel icon={<TbSchool className="h-5 w-5" />} title="Wisuda">
            <TableRekapWisudaTracer
              onDoneLoadData={onDoneLoadData}
              action={20}
            />
          </TabPanel>
        </Tabs>
      </Column>
    </TransitionPage>
  );
};

export default ProTracerPage;
