import { useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableRejectedConsultation from "./rows/row_rejected_consultation";
import {
  AlertDialog,
  Caption,
  Card,
  Column,
  Headline3,
  ListTileView,
  Text,
  TextButton,
} from "../../../../shared/ui";
import { TbMessageCircleOff } from "react-icons/tb";

function TableRejectedConsultation({ listConsultation, page, limit, onShort }) {
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [fullName, setFullName] = useState(null);
  const [consultation, setConsultation] = useState({});
  const [konselorName, setKonselorName] = useState(null);

  const onHandleClick = (mitra) => {
    setConsultation(mitra);
    setShowDialog(true);
    console.log(mitra);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Alumni</TableHeadCell>
            <TableHeadCell>Topik</TableHeadCell>
            <TableHeadCell>Subjek</TableHeadCell>
            <TableHeadCell>Pengajuan</TableHeadCell>
            <TableHeadCell>Diproses</TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listConsultation.length > 0 ? (
            listConsultation.map((consultation, idx) => {
              return (
                <RowTableRejectedConsultation
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  consultation={consultation}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog show={showDialog} onClose={() => setShowDialog(false)}>
        <Column className="divide-y divide-gray-200">
          <Column className="px-5 pt-10 pb-8 items-center gap-8">
            <Card className="p-1.5 rounded-2xl bg-red-50/50 border-red-200/50 shadow-none">
              <TbMessageCircleOff className="h-14 w-14 stroke-1.5 text-red-600" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Konsultasi Ditolak</Headline3>
              <Text className="text-center">
                Sesi konsultasi yang diajukan oleh{" "}
                <span className="font-medium">{fullName}</span> ditolak oleh{" "}
                <span className="font-medium">
                  {consultation.respon === null ? "Admin Alumni" : "Konselor"}
                </span>
              </Text>
            </Column>
            <Column className="gap-5">
              <Card className="w-full max-h-32 py-3 overflow-y-auto scrollbar-hide shadow-none">
                <Column className="gap-0.5">
                  <Caption>Topik Konsultasi:</Caption>
                  <Text className="w-fit whitespace-pre-line">
                    {consultation.topik}
                  </Text>
                </Column>
              </Card>
              <Card className="w-full p-0 shadow-none">
                <Column className="divide-y-[1px] divide-gray-200">
                  <ListTileView
                    className="py-3"
                    label="Alumni"
                    value={consultation.nama_alumni}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  {konselorName !== null && (
                    <ListTileView
                      className="py-3"
                      label="Konselor"
                      value={konselorName}
                      direction="flex-col"
                      valueClassName="text-base font-normal"
                    />
                  )}
                  <ListTileView
                    className="py-3"
                    label="Tipe Konsultasi"
                    value={consultation.public ? "Publik" : "Tertutup"}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Pengajuan"
                    value={moment(consultation.created_at).format(
                      "DD MMMM YYYY HH.mm"
                    )}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                </Column>
              </Card>
              <Card className="w-full p-0 shadow-none">
                <ListTileView
                  className="py-3"
                  label="Diproses"
                  value={moment(consultation.updated_at).format(
                    "DD MMMM YYYY HH.mm"
                  )}
                  direction="flex-col"
                  valueClassName="text-base font-normal"
                />
              </Card>
            </Column>
          </Column>
          <TextButton
            onClick={() => setShowDialog(false)}
            className="w-full py-4 text-base hover:bg-slate-50/50"
          >
            Tutup
          </TextButton>
        </Column>
      </AlertDialog>
    </>
  );
}

export default TableRejectedConsultation;
