import React, { useEffect, useState } from "react";
import WListNotification from "../../../components/wlist_notification";
import {
  getNotificationsController,
  readNotificationsControllers,
} from "../../../controllers/global_controllers";
import { useLoaderData } from "react-router-dom";

function AdmNotificationPage() {
  const result = useLoaderData();

  const [readNotification, setReadNotification] = useState([]);
  const [unreadNotification, setUnreadNotification] = useState([]);

  const getNotificationFromLoader = () => {
    if (result.status) {
      const listNotification = [...result.data];
      const unread = listNotification.filter(
        (notif) => notif.is_read === false
      );
      const read = listNotification.filter((notif) => notif.is_read === true);
      setUnreadNotification(unread);
      setReadNotification(read);
    }
  };

  const onReadNotification = async (action, notif) => {
    const result = await readNotificationsControllers(notif.id_notifikasi);
    if (result.status) {
      if (action === 10) {
        const readTemp = [...readNotification, notif];
        const newUnread = unreadNotification.filter(
          (unread) => unread.id_notifikasi !== notif.id_notifikasi
        );
        const sortRead = readTemp.sort(
          (a, b) => b.id_notifikasi - a.id_notifikasi
        );
        setUnreadNotification(newUnread);
        setReadNotification(sortRead);
      }
    }
  };

  useEffect(() => {
    getNotificationFromLoader();
  }, []);

  return (
    <div className="space-y-6 mt-6">
      <WListNotification
        action={10}
        listNotification={unreadNotification}
        onReadNotification={onReadNotification}
      />
      <WListNotification
        action={20}
        listNotification={readNotification}
        onReadNotification={onReadNotification}
      />
    </div>
  );
}

export default AdmNotificationPage;

export const getAdmNotifications = async () => {
  const result = await getNotificationsController();
  return result;
};
