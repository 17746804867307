import React from 'react'
import { Column, Headline2 } from '../../shared/ui'

const KonDashboardPage = () => {
    return (
        <Column className={'w-full p-4 gap-y-5 sm:gap-y-8 sm:p-10'}>
            <Headline2>
                Dashboard
            </Headline2>
        </Column>
    )
}

export default KonDashboardPage
