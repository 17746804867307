import React from 'react'
import ItemConsultation from './item/item_consultation'

function ListConsultation({ action, consultation }) {
  return (
    consultation.map((consul, idx) => {
      return (
        <ItemConsultation
          key={idx}
          action={action}
          consultation={consul} />
      )
    })
  )
}

export default ListConsultation