import { SimpleAlert, Headline1, Column, Subtitle, GridCol } from '../../shared/ui'
import DesignLayout from '../../layouts/design_layout'
import { HiBellAlert, HiCheckCircle, HiExclamationCircle, HiExclamationTriangle, HiInformationCircle } from 'react-icons/hi2'

const AlertUI = () => {

    // let bottomRef = useRef()

    // const scollToBottom = () => {
    //     bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    // }

    return (
        <DesignLayout currentPage='/design/alert'>
            <Headline1 className='mb-8'>
                Alerts
            </Headline1>
            {/* <PrimaryButton onClick={scollToBottom} className='mb-8'>Scoll Bottom</PrimaryButton> */}
            <GridCol className='grid-cols-1 gap-x-12 gap-y-16 mb-20 lg:grid-cols-2'>
                <Column className='gap-3'>
                    <Subtitle className='font-semibold'>Default alert</Subtitle>
                    <SimpleAlert type='info'>
                        <span className='font-semibold'>Info alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='success'>
                        <span className='font-semibold'>Success alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='warning'>
                        <span className='font-semibold'>Warning alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='error'>
                        <span className='font-semibold'>Error alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert>
                        <span className='font-semibold'>Default alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                </Column>
                <Column className='gap-3'>
                    <Subtitle className='font-semibold'>Alert with border</Subtitle>
                    <SimpleAlert type='info' bordered={true}>
                        <span className='font-semibold'>Info alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='success' bordered={true}>
                        <span className='font-semibold'>Success alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='warning' bordered={true}>
                        <span className='font-semibold'>Warning alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='error' bordered={true}>
                        <span className='font-semibold'>Error alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert bordered={true}>
                        <span className='font-semibold'>Default alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                </Column>
                <Column className='gap-3'>
                    <Subtitle className='font-semibold'>Alert with left border</Subtitle>
                    <SimpleAlert type='info' borderLeft={true}>
                        <span className='font-semibold'>Info alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='success' borderLeft={true}>
                        <span className='font-semibold'>Success alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='warning' borderLeft={true}>
                        <span className='font-semibold'>Warning alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='error' borderLeft={true}>
                        <span className='font-semibold'>Error alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert borderLeft={true}>
                        <span className='font-semibold'>Default alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                </Column>
                <Column className='gap-3'>
                    <Subtitle className='font-semibold'>Alert with left border and bordered</Subtitle>
                    <SimpleAlert type='info' borderLeft={true} bordered={true}>
                        <span className='font-semibold'>Info alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='success' borderLeft={true} bordered={true}>
                        <span className='font-semibold'>Success alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='warning' borderLeft={true} bordered={true}>
                        <span className='font-semibold'>Warning alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='error' borderLeft={true} bordered={true}>
                        <span className='font-semibold'>Error alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert borderLeft={true} bordered={true}>
                        <span className='font-semibold'>Default alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                </Column>
                <Column className='gap-3'>
                    <Subtitle className='font-semibold'>Alert with icon</Subtitle>
                    <SimpleAlert type='info' icon={<HiInformationCircle className='h-6 w-6 text-blue-800' />}>
                        <span className='font-semibold'>Info alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='success' icon={<HiCheckCircle className='h-6 w-6 text-green-800' />}>
                        <span className='font-semibold'>Success alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='warning' icon={<HiExclamationTriangle className='h-6 w-6 text-orange-800' />}>
                        <span className='font-semibold'>Warning alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='error' icon={<HiExclamationCircle className='h-6 w-6 text-red-800' />}>
                        <span className='font-semibold'>Error alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert icon={<HiBellAlert className='h-6 w-6 text-gray-800' />}>
                        <span className='font-semibold'>Default alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                </Column>
                <Column className='gap-3'>
                    <Subtitle className='font-semibold'>Alert with close button</Subtitle>
                    <SimpleAlert type='info' showCloseBtn={true}>
                        <span className='font-semibold'>Info alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='success' showCloseBtn={true}>
                        <span className='font-semibold'>Success alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='warning' showCloseBtn={true}>
                        <span className='font-semibold'>Warning alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='error' showCloseBtn={true}>
                        <span className='font-semibold'>Error alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert showCloseBtn={true}>
                        <span className='font-semibold'>Default alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                </Column>
                <Column className='gap-3'>
                    <Subtitle className='font-semibold'>Alert with icon and close button</Subtitle>
                    <SimpleAlert type='info' icon={<HiInformationCircle className='h-6 w-6 text-blue-800' />} showCloseBtn={true}>
                        <span className='font-semibold'>Info alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='success' icon={<HiCheckCircle className='h-6 w-6 text-green-800' />} showCloseBtn={true}>
                        <span className='font-semibold'>Success alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='warning' icon={<HiExclamationTriangle className='h-6 w-6 text-orange-800' />} showCloseBtn={true}>
                        <span className='font-semibold'>Warning alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='error' icon={<HiExclamationCircle className='h-6 w-6 text-red-800' />} showCloseBtn={true}>
                        <span className='font-semibold'>Error alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert icon={<HiBellAlert className='h-6 w-6 text-gray-800' />} showCloseBtn={true}>
                        <span className='font-semibold'>Default alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                </Column>
                <Column className='gap-3'>
                    <Subtitle className='font-semibold'>Alert with icon, bordered and close button</Subtitle>
                    <SimpleAlert type='info' icon={<HiInformationCircle className='h-6 w-6 text-blue-800' />} bordered={true} showCloseBtn={true}>
                        <span className='font-semibold'>Info alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='success' icon={<HiCheckCircle className='h-6 w-6 text-green-800' />} bordered={true} showCloseBtn={true}>
                        <span className='font-semibold'>Success alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='warning' icon={<HiExclamationTriangle className='h-6 w-6 text-orange-800' />} bordered={true} showCloseBtn={true}>
                        <span className='font-semibold'>Warning alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert type='error' icon={<HiExclamationCircle className='h-6 w-6 text-red-800' />} bordered={true} showCloseBtn={true}>
                        <span className='font-semibold'>Error alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                    <SimpleAlert icon={<HiBellAlert className='h-6 w-6 text-gray-800' />} bordered={true} showCloseBtn={true}>
                        <span className='font-semibold'>Default alert!</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SimpleAlert>
                </Column>
            </GridCol>
        </DesignLayout>
    )
}

export default AlertUI
