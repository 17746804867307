import React, { useEffect, useState } from 'react'
import { GridCol } from '../../shared/ui'
import { getEventController } from '../../controllers/apicontrollers'
import ItemEvent from './item/item_event'
import { gLandingEvent } from '../../atoms'
import { useRecoilState } from 'recoil'

const ListEventLanding = () => {

    const [listEvent, setListEvent] = useRecoilState(gLandingEvent)

    const getEvent = async () => {
        const props = {
            action: 20,
            page: 1,
            limit: 3,
        }
        const result = await getEventController(props)
        if (result.status) {
            setListEvent(result.data)
        }
    }

    useEffect(() => {
        getEvent()
    }, [])

    return (
        <GridCol className='grid-cols-1 auto-rows-fr gap-0 sm:grid-cols-3 sm:gap-x-8 sm:gap-y-16'>
            {
                listEvent.map((event, idx) => {
                    return <ItemEvent key={idx} event={event} />
                })
            }
        </GridCol>
    )
}

export default ListEventLanding
