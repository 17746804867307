import { useLoaderData } from "react-router-dom";
import {
  getNotificationsController,
  readNotificationsControllers,
} from "../../controllers/global_controllers";
import WListNotification from "../../components/wlist_notification";
import { Column, Headline3, Text } from "../../shared/ui";
import { TbBell } from "react-icons/tb";
import { useEffect, useState } from "react";

function MitNotificationPage() {
  const result = useLoaderData();
  const [readNotification, setReadNotification] = useState([]);
  const [unreadNotification, setUnreadNotification] = useState([]);

  const getNotificationFromLoader = () => {
    if (result.status) {
      const listNotification = [...result.data];
      const unread = listNotification.filter(
        (notif) => notif.is_read === false
      );
      const read = listNotification.filter((notif) => notif.is_read === true);
      setUnreadNotification(unread);
      setReadNotification(read);
    }
  };

  const onReadNotification = async (action, notif) => {
    const result = await readNotificationsControllers(notif.id_notifikasi);
    if (result.status) {
      if (action === 10) {
        const readTemp = [...readNotification, notif];
        const newUnread = unreadNotification.filter(
          (unread) => unread.id_notifikasi !== notif.id_notifikasi
        );
        const sortRead = readTemp.sort(
          (a, b) => b.id_notifikasi - a.id_notifikasi
        );
        setUnreadNotification(newUnread);
        setReadNotification(sortRead);
      }
    }
  };

  useEffect(() => {
    getNotificationFromLoader();
  }, []);
  return (
    <>
      <Column className={"py-6"}>
        {unreadNotification.length > 0 || readNotification.length > 0 ? (
          <div className="space-y-6">
            <WListNotification
              action={10}
              listNotification={unreadNotification}
              onReadNotification={onReadNotification}
            />
            <WListNotification
              action={20}
              listNotification={readNotification}
              onReadNotification={onReadNotification}
            />
          </div>
        ) : (
          <Column
            className={"w-full max-w-4xl mx-auto gap-y-5 sm:gap-y-8 sm:px-14"}
          >
            <Column
              className={
                "items-center gap-y-6 px-4 py-16 bg-white border-y lg:border-x lg:border-gray-200"
              }
            >
              <div className={"p-4 bg-gray-50 rounded-full"}>
                <TbBell className={"h-20 w-20 text-gray-300 stroke-1.5"} />
              </div>
              <Column className={"items-center gap-y-2"}>
                <Headline3 className={"text-gray-800"}>Notifikasi</Headline3>
                <Text className={"text-center text-gray-500"}>
                  Saat ini belum ada notifikasi yang muncul
                </Text>
              </Column>
            </Column>
          </Column>
        )}
      </Column>
    </>
  );
}

export default MitNotificationPage;

export const getNotificationsMitra = async () => {
  const result = await getNotificationsController();
  return result;
};
