import { Transition } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { TbAlertTriangle, TbBriefcase, TbChecks, TbProgress, TbSearch, TbX } from 'react-icons/tb'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { applyCareer } from '../../../atoms'
import ItemApplyCareer from '../../../components/list/item/item_apply_career'
import WDetailCareer from '../../../components/other/w_detail_career'
import { getApplyLowonganController } from '../../../controllers/alumni_controllers'
import { AlertDialog, ButtonHover, Caption, Column, GridCol, Headline3, Image, LargeHeadline, Row, TertiaryButton, Text } from '../../../shared/ui'
import { careerRoute } from '../../../shared/variable'

function AlmApplyProfilePage() {

  const result = useLoaderData()

  const navigateTo = useNavigate()

  const [listApply, setListApply] = useRecoilState(applyCareer)
  const [currentApply, setCurrentApply] = useState(null)
  const [sumAccepted, setSumAccepted] = useState(0)
  const [sumProcessed, setSumProcessed] = useState(0)
  const [sumRejected, setSumRejected] = useState(0)
  const [showAction, setShowAction] = useState(0)
  const [showNote, setShowNote] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  const getApplyFromLoader = () => {
    if (result.status) {
      const applyTemp = [...result.data]
      if (applyTemp.length > 0) {
        const accepted = applyTemp.filter((apply) => apply.acc_mitra === 'ACCEPTED')
        const processed = applyTemp.filter((apply) => apply.acc_mitra === 'PROCESSED')
        const rejected = applyTemp.filter((apply) => apply.acc_mitra === 'REJECTED')
        setSumAccepted(accepted.length)
        setSumProcessed(processed.length)
        setSumRejected(rejected.length)
      }
      setListApply(applyTemp)
    }
  }

  const onShowDetail = (apply) => {
    setCurrentApply(apply)
    setShowDetail(true)
  }

  const onShowNote = (action, apply) => {
    setShowAction(action)
    setCurrentApply(apply)
    setShowNote(true)
  }

  useEffect(() => {
    getApplyFromLoader()
  }, [])

  return (
    <>
      <Column className='w-full max-w-4xl mx-auto gap-y-5 sm:gap-y-8 sm:px-14'>
        <GridCol className={'gap-x-5 gap-y-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'}>
          <Column className={'px-5 py-4 gap-y-4 bg-white border-y sm:shadow-sm sm:px-8 sm:py-7 sm:gap-y-5 sm:border-x'}>
            <Text>
              Lolos Seleksi*
            </Text>
            <Row className={'justify-between items-center'}>
              <LargeHeadline className={'font-inter font-normal'}>
                {sumAccepted}
              </LargeHeadline>
              <TbChecks className={'h-8 w-8 stroke-1.5 text-green-600'} />
            </Row>
          </Column>
          <Column className={'px-5 py-4 gap-y-4 bg-white border-y sm:shadow-sm sm:px-8 sm:py-7 sm:gap-y-5 sm:border-x'}>
            <Text>
              Menunggu Diproses
            </Text>
            <Row className={'justify-between items-center'}>
              <LargeHeadline className={'font-inter font-normal'}>
                {sumProcessed}
              </LargeHeadline>
              <TbProgress className={'h-8 w-8 stroke-1.5 text-blue-600'} />
            </Row>
          </Column>
          <Column className={'px-5 py-4 gap-y-4 bg-white border-y sm:shadow-sm sm:px-8 sm:py-7 sm:gap-y-5 sm:border-x sm:col-span-2 lg:col-span-1'}>
            <Text>
              Tidak Lolos Seleksi*
            </Text>
            <Row className={'justify-between items-center'}>
              <LargeHeadline className={'font-inter font-normal'}>
                {sumRejected}
              </LargeHeadline>
              <TbAlertTriangle className={'h-8 w-8 stroke-1.5 text-red-600'} />
            </Row>
          </Column>
        </GridCol>
        {
          listApply.length > 0 ? listApply.map((apply, idx) => {
            return (
              <ItemApplyCareer
                key={idx}
                career={apply}
                onShowNote={onShowNote}
                onShowDetail={onShowDetail} />
            )
          }) : <Column className={'items-center gap-y-6 px-4 py-16 bg-white border-y lg:border-x lg:border-gray-200'}>
            <div className={'p-4 bg-gray-50 rounded-full'}>
              <TbBriefcase className={'h-20 w-20 text-gray-300 stroke-1.5'} />
            </div>
            <Column className={'items-center gap-y-2'}>
              <Headline3 className={'text-gray-800'}>
                Apply Lowongan
              </Headline3>
              <Text className={'text-center text-gray-500'}>
                Saat ini Anda belum mendaftar/apply lowongan pekerjaan
              </Text>
            </Column>
            <ButtonHover>
              <TertiaryButton
                pills
                color='indigo'
                className={'pl-5 pr-6'}
                leadingIcon={
                  <TbSearch className={'h-5 w-5 my-auto'} />
                }
                onClick={() => navigateTo(careerRoute)}>
                Cari Lowongan
              </TertiaryButton>
            </ButtonHover>
          </Column>
        }
      </Column>
      <Transition
        show={showDetail}
        className={'fixed inset-0 border-none z-30 bg-black/40'}
        enter='transition ease-in-out duration-200'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition ease-in-out duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        onClick={() => setShowDetail(false)} />
      <Transition
        show={showDetail}
        className={'fixed w-full max-w-3xl inset-y-0 right-0 z-30 bg-white border-none'}
        enter='transition ease-in-out duration-200'
        enterFrom='translate-x-full opacity-0'
        enterTo='translate-x-0 opacity-100'
        leave='transition ease-in-out duration-200'
        leaveFrom='translate-x-0 opacity-100'
        leaveTo='translate-x-full opacity-0'>
        {
          currentApply !== null && <WDetailCareer
            action={20}
            career={currentApply}
            onClose={() => setShowDetail(false)} />
        }
      </Transition>
      <AlertDialog
        show={showNote}
        className={'max-w-2xl'}
        onClose={() => setShowNote(false)}>
        {currentApply !== null && <Column>
          <Column className={'gap-y-8 p-5 sm:p-8'}>
            <Row className={'gap-x-3 items-center'}>
              <Image
                src={currentApply.logo}
                className={'p-1 border rounded-xl'}
                imgClasName={'w-16 aspect-square bg-white'}
                hoverScale={false} />
              <Column className='gap-y-1'>
                <Headline3 className={'hover:text-indigo-600'}>
                  {currentApply.posisi}
                </Headline3>
                <Text>
                  {currentApply.nama_mitra}
                </Text>
              </Column>
            </Row>
            <Column className={'divide-y-2 border border-gray-200 rounded-lg overflow-hidden'}>
              <Caption className={'px-4 py-2 font-medium bg-gray-50 uppercase translate-y-[1px] sm:px-6'}>
                Informasi
              </Caption>
              <Text className={'p-4 whitespace-pre-line text-justify sm:p-6'}>
                {showAction === 10 ? currentApply.informasi : currentApply.alasan}
              </Text>
            </Column>
          </Column>
          <Row className={'gap-x-5 justify-end px-5 pb-5 sm:px-8 sm:pb-8'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowNote(false)}>
                Tutup
              </TertiaryButton>
            </ButtonHover>
          </Row>
        </Column>}
      </AlertDialog>
    </>
  )
}

export default AlmApplyProfilePage

export const getApplyLowongan = async () => {
  const result = await getApplyLowonganController()
  return result
}