import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowSaranTracer from "./rows/row_saran_tracer";
import {
  AlertDialog,
  Caption,
  Card,
  Column,
  Headline3,
  ListTileView,
  Text,
  TextButton,
} from "../../../../shared/ui";
import { TbMessageCircleOff, TbMessages } from "react-icons/tb";

function TablesaranTracer({ listSaran, page, limit, onShort }) {
  const [currentSort, setCurrentSort] = useState(null);

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };
  const [nama, setNama] = useState(null);
  const [npm, setNpm] = useState(null);
  const [prodi, setProdi] = useState(null);
  const [saran, setSaran] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [jenjang, setJenjang] = useState(null);
  const onHandleClick = (saran) => {
    setNama(saran.nmmhsmsmh);
    setNpm(saran.nimhsmsmh);
    setProdi(saran.prodi);
    setSaran(saran.saran);
    setJenjang(saran.jenjang);
    setShowDialog(true);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="nimhsmsmh"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              NPM
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="nmmhsmsmh"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Nama Lengkap
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="jenjang"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Jenjang
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="prodi"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Program Studi
            </TableHeadCell>
            <TableHeadCell>Saran</TableHeadCell>
            <TableHeadCell>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listSaran.length > 0 ? (
            listSaran.map((saran, idx) => {
              return (
                <RowSaranTracer
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  saran={saran}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog show={showDialog} onClose={() => setShowDialog(false)}>
        <Column className="divide-y divide-gray-200">
          <Column className="px-5 pt-10 pb-8 items-center gap-8">
            <Card className="p-1.5 rounded-2xl bg-red-50/50 border-red-200/50 shadow-none">
              <TbMessages className="h-14 w-14 stroke-1.5 text-red-600" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Saran</Headline3>
              <Text className="text-center">
                Saran/masukan alumni oleh{" "}
                <span className="font-medium">{nama}</span>
              </Text>
            </Column>
            <Column className="gap-5">
              <Card className="w-full p-0 shadow-none">
                <Column className="divide-y-[1px] divide-gray-200">
                  <ListTileView
                    className="py-3"
                    label="Alumni"
                    value={nama}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="NPM"
                    value={npm}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Jenjang"
                    value={jenjang}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Prodi"
                    value={prodi}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Saran"
                    value={saran}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                </Column>
              </Card>
            </Column>
          </Column>
          <TextButton
            onClick={() => setShowDialog(false)}
            className="w-full py-4 text-base hover:bg-slate-50/50"
          >
            Tutup
          </TextButton>
        </Column>
      </AlertDialog>
    </>
  );
}

export default TablesaranTracer;
