import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from "../../../../components/interfaces/table";
import RowInputExitSurvey from "./rows/row_input_exit_survey";

const TableInputExitSurvey = ({ page, limit, listExitSurvey }) => {
  return <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell>
          No
        </TableHeadCell>
        <TableHeadCell>
          NPM
        </TableHeadCell>
        <TableHeadCell>
          Nama Lengkap
        </TableHeadCell>
        <TableHeadCell>
          Jenjang
        </TableHeadCell>
        <TableHeadCell>
          Program Studi
        </TableHeadCell>
        <TableHeadCell>
          Status
        </TableHeadCell>
        <TableHeadCell>
          Tanggal Wisuda
        </TableHeadCell>
        <TableHeadCell>
          Tanggal Input
        </TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        listExitSurvey.length > 0 ? (
          listExitSurvey.map((survey, idx) => {
            return (
              <RowInputExitSurvey
                key={idx}
                idx={idx}
                page={page}
                limit={limit}
                survey={survey}
              />
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={8} className="text-center text-gray-500">
              No data to display
            </TableCell>
          </TableRow>
        )
      }
    </TableBody>
  </Table>
}

export default TableInputExitSurvey;