import { TbCertificate, TbPencil } from "react-icons/tb";
import {
  ButtonHover,
  Caption,
  Card,
  Column,
  Headline1,
  Headline3,
  HorizontalDivider,
  PrimaryButton,
  Row,
  ShimmerAnimation,
  ShimmerItem,
  Subtitle,
  Text,
  Title,
  TransitionPage,
} from "../../shared/ui";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkUserFilledTracer } from "../../controllers/apicontrollers";
import { getCurrentUser } from "../../shared/user";
import { mhsInputExitRoute, notFoundRoute } from "../../shared/variable";

import moment from "moment";

export default function MhsExitSurveyPage() {
  const { name, prodi } = getCurrentUser();
  const navigate = useNavigate();

  // const getExitSurvey = async () => {
  //   const result = await getCekExitSurveyController();
  //   setExitSurvey(result);
  // };
  const [exitSurvey, setExitSurvey] = useState([]);
  const [akreditasiData, setAkreditasiData] = useState([]);
  const getAkreditasi = async () => {
    const response = await checkUserFilledTracer();
    setAkreditasiData(response.sertifikat);
  };

  useEffect(() => {
    getAkreditasi();
  }, []);

  const [sertificateAvailable, setSertificateAvailable] = useState(null);
  const dateAvailable = exitSurvey?.data?.date_input;

  useEffect(() => {
    if (dateAvailable) {
      const sixMonth = new Date();
      sixMonth.setMonth(sixMonth.getMonth() - 6);

      const fillDate = new Date(dateAvailable);
      if (fillDate >= sixMonth) {
        setSertificateAvailable(true);
      }
    }
  }, [dateAvailable]);

  return (
    <TransitionPage>
      {/* exit survey */}
      <div
        className={`flex max-w-7xl mx-auto lg:px-6  py-0 ${
          exitSurvey && exitSurvey?.data?.date_input !== null
            ? "mt-12 lg:mt-20 mb-0 lg:mb-12"
            : "mt-12 lg:mt-20 mb-0 "
        } lg:min-h-screen`}
      >
        <Card className="w-full p-0 rounded-none lg:rounded-xl lg:border-gray-200 lg:my-auto lg:shadow-lg lg:shadow-gray-200 ">
          <Column className="divide-y-[1px] lg:divide-x-[1px] lg:divide-y-0 lg:flex lg:flex-row">
            <Column>
              {/* tracer studi */}
              <Column className="gap-y-2 p-8 ">
                <Headline1 className="font-semibold">Exit Survey</Headline1>
                <Subtitle className="font-inter font-normal">
                  Selamat dan terima kasih telah menyelesaikan studi Anda!
                  Sebelum melanjutkan ke tahap berikutnya, kami mohon kesediaan
                  Anda untuk mengisi survei singkat ini.
                  <Subtitle>
                    Survei ini bertujuan untuk meningkatkan pengalaman wisuda
                    dan layanan kampus bagi lulusan di masa depan. Setelah
                    mengisi survei, silahkan akses website{" "}
                    <span className="text-indigo-600 font-bold">
                      <Link to="https://wisuda.uty.ac.id/" target="_blank">
                        Wisuda UTY
                      </Link>
                    </span>{" "}
                    untuk mendapatkan QR code yang diperlukan sebagai syarat
                    pengambilan toga wisuda.
                  </Subtitle>
                </Subtitle>
              </Column>

              <HorizontalDivider removeMargin />

              {/* sudah isi tracer */}
              <Column className="p-8 gap-y-8 ">
                <Caption
                  className={`${
                    exitSurvey?.data?.date_input !== null
                      ? "text-emerald-600"
                      : "text-orange-600"
                  } font-medium`}
                >
                  {exitSurvey?.data?.date_input !== null
                    ? "SUDAH MENGISI EXIT SURVEY"
                    : "BELUM MENGISI EXIT SURVEY"}
                </Caption>
                <Column className="gap-y-2">
                  <Headline3>Halo, {name}</Headline3>
                  <Text>
                    {exitSurvey ? (
                      exitSurvey?.data?.date_input !== null ? (
                        "Terima kasih sudah mengisi Exit Survey"
                      ) : (
                        "Anda belum mengisi Exit Survey! Yuk isi sekarang"
                      )
                    ) : (
                      <ShimmerAnimation>
                        <ShimmerItem className="h-4 w-52" />
                      </ShimmerAnimation>
                    )}
                  </Text>
                </Column>
              </Column>

              <Column>
                <Column className="px-8 py-8 mt-auto gap-y-5">
                  {exitSurvey?.data?.date_input === null && (
                    <ButtonHover>
                      <PrimaryButton
                        onClick={() => navigate(mhsInputExitRoute)}
                        color="indigo"
                        leadingIcon={<TbPencil className="h-5 w-5 my-auto" />}
                        className="pr-5"
                      >
                        Isi Exit Survey
                      </PrimaryButton>
                    </ButtonHover>
                  )}

                  <Caption className="text-gray-500">
                    {exitSurvey?.data?.date_input !== null &&
                      `Diisi pada tanggal ${moment(
                        exitSurvey?.data?.date_input
                      ).format("DD MMM YYYY")}`}
                  </Caption>
                </Column>
              </Column>
            </Column>

            {/* sertifikasi akreditasi */}
            <Column
              className={`max-w-xl overflow-y-auto scrollbar-hide scroll-smooth gap-y-8 ${
                exitSurvey?.data?.date_input !== null
                  ? "h-[90vh] lg:h-[77vh] xl:h-[88vh]"
                  : "h-fit"
              }`}
            >
              {sertificateAvailable && exitSurvey?.data?.date_input !== null ? (
                <Column className="gap-y-6 ">
                  <Column className="px-8 pt-8 gap-y-2">
                    <TbCertificate className="h-8 w-8 stroke-1.5 text-amber-400" />
                    <Title>Sertifikat Akreditasi</Title>
                    <Text>
                      Berikut daftar sertifikat akreditasi yang dapat anda unduh
                      pada halaman exit survey maupun pada menu profil halaman
                      sertifikat.
                    </Text>
                  </Column>
                  <Column className="divide-y-[1px] divide-gray-200">
                    <Caption className="px-8 pb-3">
                      Akreditasi Institusi
                    </Caption>

                    <Column>
                      {akreditasiData.map((sertifikat, idx) => {
                        return (
                          <Column>
                            <Row key={idx} className="gap-x-4 px-8 py-3">
                              <Column>
                                <Text className="font-semibold font-manrope">
                                  {sertifikat.tahun == "2024"
                                    ? "Surat Keterangan Akreditasi Universitas"
                                    : "Universitas Teknologi Yogyakarta"}
                                </Text>
                                <Caption>
                                  Akreditasi Tahun {sertifikat.tahun}
                                </Caption>
                              </Column>
                              <ButtonHover className="my-auto">
                                <a
                                  href={sertifikat.name}
                                  target="_blank"
                                  download
                                  className="tertiary-btn py-2 hover:border-indigo-500"
                                >
                                  Unduh
                                </a>
                              </ButtonHover>
                            </Row>
                            {idx < akreditasiData.length - 1 && (
                              <HorizontalDivider className="my-0 ml-8" />
                            )}
                          </Column>
                        );
                      })}
                    </Column>
                  </Column>
                  <Column className="divide-y-[1px] divide-gray-200">
                    <Caption className="px-8 pb-3">
                      Akreditasi Program Studi
                    </Caption>
                    <Column className="mb-5">
                      {akreditasiData.map((sertifikat, idx) => {
                        return (
                          <Column>
                            <Row key={idx} className="gap-x-4 px-8 py-3">
                              <Column>
                                <Text className="font-semibold font-manrope">
                                  {prodi}
                                </Text>
                                <Caption>
                                  Akreditasi Tahun {sertifikat.tahun}
                                </Caption>
                              </Column>
                              <ButtonHover className="my-auto">
                                <a
                                  href={sertifikat.name}
                                  target="_blank"
                                  download
                                  className="tertiary-btn py-2 hover:border-indigo-500"
                                >
                                  Unduh
                                </a>
                              </ButtonHover>
                            </Row>
                            {idx < akreditasiData.length - 1 && (
                              <HorizontalDivider className="my-0 ml-8" />
                            )}
                          </Column>
                        );
                      })}
                    </Column>
                  </Column>
                </Column>
              ) : (
                <Column className="sm:h-[80vh] px-8 py-8 gap-y-2">
                  <TbCertificate className="h-8 w-8 stroke-1.5 text-amber-400" />
                  <Title>Sertifikat Akreditasi</Title>
                  <Text>
                    Sertifikat Aktreditasi Institusi dan Program Studi dapat
                    diunduh pada halaman Exit Survey dan menu profil halaman
                    Sertifikat setelah Anda berpartisipasi mengisi Exit Survey.
                  </Text>
                </Column>
              )}
            </Column>
          </Column>
        </Card>
      </div>
    </TransitionPage>
  );
}
