import React, { useEffect, useState } from 'react'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { getFullNameUser } from '../../../../../controllers/apicontrollers'
import { getViewDisplay } from '../../../../../shared/lib'
import { ShimmerAnimation, ShimmerItem } from '../../../../../shared/ui'

function RowTableRecapRecognition({ idx, rekap }) {

  const [fullName, setFullName] = useState(null)

  const getFullNameOfUser = async () => {
    const result = await getFullNameUser(rekap.id_identify, rekap.id_user)
    if (result.status) {
      setFullName(result.data)
    }
  }

  useEffect(() => {
    getFullNameOfUser()
  }, [])

  return (
    <TableRow striped>
      <TableCell>
        {(idx + 1)}
      </TableCell>
      <TableCell>
        {
          fullName ? <BodyText className={'line-clamp-2'}>
            {fullName}
          </BodyText> : <ShimmerAnimation>
            <ShimmerItem className='h-5 w-full' />
          </ShimmerAnimation>
        }
      </TableCell>
      <TableCell>
        {getViewDisplay(rekap.posts)}
      </TableCell>
      <TableCell>
        {getViewDisplay(rekap.likes)}
      </TableCell>
      <TableCell>
        {getViewDisplay(rekap.views)}
      </TableCell>
    </TableRow>
  )
}

export default RowTableRecapRecognition