import { TableCell, TableRow } from "../../../../../components/interfaces/table"
import { BodyText } from "../../../../../components/interfaces/text"

function RowWisudaTracer({ idx, wisuda, page = 1, limit = 10 }) {
  return (
    <TableRow striped>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell >
        {wisuda.nama_jenjang}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {wisuda.nama_jurusan}
        </BodyText>
      </TableCell>
      <TableCell className={'text-center'}>
        {wisuda.lulus}
      </TableCell>
      <TableCell className={'text-center'}>
        {wisuda.isi_tracer}
      </TableCell>
      <TableCell className={'text-center'}>
        {parseFloat(wisuda.persentase).toFixed(1)}%
      </TableCell>
    </TableRow>
  )
}

export default RowWisudaTracer