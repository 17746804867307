import React, { useEffect, useState } from 'react'
import { Caption, Card, Column, Headline1 } from '../../shared/ui'
import { TbBriefcase, TbWallet } from 'react-icons/tb'
import { getDataRataDapatKerjaOvTracer, getDataRataPendapatanOvTracer } from '../../controllers/apicontrollers'

const CNumRata2WaktuOverviewTracer = ({ onDoneLoadData, tahun, prodi }) => {

    const [title, setTitle] = useState('')
    const [waktu, setWaktu] = useState('0')

    const getRataDapatKerjaTracer = async () => {
        const props = {
            tahun: tahun.value,
            prodi: prodi.value
        }
        const result = await getDataRataDapatKerjaOvTracer(props)
        if (result.status) {
            setTitle(result.title)
            setWaktu(result.data)
        }
        onDoneLoadData(40)
    }

    useEffect(() => {
        getRataDapatKerjaTracer()
    }, [tahun, prodi])

    return (
        <Card className='w-full h-full'>
            <Column className='gap-5 h-full'>
                <Column className='gap-3'>
                    <Card className='p-2 bg-pink-50 border-none rounded-full'>
                        <TbBriefcase className='h-7 w-7 text-pink-700 stroke-1.5' />
                    </Card>
                    <Caption className='font-medium'>
                        {title}
                    </Caption>
                </Column>
                <Headline1 className='font-medium archivo my-auto'>
                    {waktu}
                </Headline1>
            </Column>
        </Card>
    )
}

export default CNumRata2WaktuOverviewTracer
