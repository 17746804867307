import { Suspense, lazy } from 'react'
import { TbChecks, TbRefresh, TbX } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { currentIndexTabPloting } from '../../atoms'
import STable from '../../components/shimmer/stable'
import { Column, Headline2, TabPanel, Tabs } from '../../shared/ui'

const WTablePlotingKonselor = lazy(() => import('../../components/wtable_ploting_konselor'))

const KonPlotingPage = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabPloting)

    const onChangeTabIndex = (index) => {
        setCurrentTabIndex(index)
    }

    return (
        <Column className='gap-5'>
            <Headline2>
                Ploting Konsultasi
            </Headline2>
            <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                <TabPanel icon={<TbChecks className='h-5 w-5' />} title='Accepted' textActiveColor='text-green-700'>
                    <Suspense fallback={<STable column={7} listWidth={['w-12', 'w-32', 'w-72', 'w-20', 'w-20', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end', 'justify-end']} />}>
                        <WTablePlotingKonselor />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbRefresh className='h-5 w-5' />} title='Waiting' textActiveColor='text-blue-700'>
                    <Suspense fallback={<STable column={7} listWidth={['w-12', 'w-32', 'w-72', 'w-20', 'w-20', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end', 'justify-end']} />}>
                        <WTablePlotingKonselor />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbX className='h-5 w-5' />} title='Rejected' textActiveColor='text-red-700'>
                    <Suspense fallback={<STable column={7} listWidth={['w-12', 'w-32', 'w-72', 'w-20', 'w-20', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end', 'justify-end']} />}>
                        <WTablePlotingKonselor />
                    </Suspense>
                </TabPanel>
            </Tabs>
        </Column>
    )
}

export default KonPlotingPage
