import React, { useEffect, useState } from 'react'
import { getSaranTracerStudiController } from '../../controllers/apicontrollers'
import { Card, Column, DotSeparated, Row, SelectView, Table, TableBody, TableCell, TableHead, TableRow, Text, Title } from '../../shared/ui'
import { useRecoilState } from 'recoil'
import { masterFilterOvTracer } from '../../atoms'
import RowTableSaranTracerStudi from './rows/row_saran_tracer_studi'

const TableSaranTracerStudi = ({ onDoneLoadData }) => {

    const [listFilter, setListFilter] = useRecoilState(masterFilterOvTracer)
    const [listSaran, setListSaran] = useState([])
    const [currentYear, setCurrentYear] = useState({ label: 'Semua Tahun', value: null })
    const [currentProdi, setCurrentProdi] = useState({ label: 'Semua Program Studi', value: null })
    const [searchQuery, setSearchQuery] = useState(null)
    const [sortTable, setShortTable] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)

    const getSaranTracerStudi = async () => {
        const props = {
            limit: limitRow,
            page: currentPage,
            search: searchQuery,
            order: sortTable !== null ? sortTable.key : null,
            asc: sortTable !== null ? sortTable.type : null,
            tahun: currentYear.value,
            prodi: currentProdi.value,
        }
        const result = await getSaranTracerStudiController(props)
        if (result.status) {
            setListSaran(result.data)
            setTotalRow(result.totalRows)
            setTotalPage(result.totalPage)
        }
        onDoneLoadData(true)
    }

    const onChangeYear = (value) => {
        setCurrentYear(value)
    }

    const onChangeProdi = (value) => {
        setCurrentProdi(value)
    }

    const onChangeShowNumber = (value) => {
        setCurrentPage(1)
        setLimitRow(value)
    }

    const onChangeQuerySearch = (value) => {
        setCurrentPage(1)
        setSearchQuery(value)
    }

    const onChangePageNumber = (value) => {
        setCurrentPage(value)
    }

    const onShortableValue = (value) => {
        setCurrentPage(1)
        setShortTable(value)
    }

    useEffect(() => {
        getSaranTracerStudi()
    }, [searchQuery, sortTable, limitRow, currentPage, currentYear.value, currentProdi.value])

    return (
        <Card className='w-full p-0'>
            <Column className='divide-y-[1px] divide-gray-200'>
                <Row className='flex-col sm:flex-row gap-4 px-5 py-4 justify-between'>
                    <Column className='gap-0.5 my-auto'>
                        <Title>
                            Daftar Saran Alumni
                        </Title>
                        <Row className='flex-wrap'>
                            <Text>
                                {currentYear.value === null ? 'Semua Tahun' : currentYear.value}
                            </Text>
                            <DotSeparated />
                            <Text>
                                {currentProdi.label}
                            </Text>
                        </Row>
                    </Column>
                    {/* <ButtonHover className='my-auto'>
                        <TertiaryButton color='indigo' className='py-2 pl-3 pr-4' leadingIcon={<TbDownload className='h-5 w-5 my-auto' />}>
                            Unduh
                        </TertiaryButton>
                    </ButtonHover> */}
                </Row>
                <Column className='gap-3 my-auto p-5 md:flex-row'>
                    <SelectView width='w-44' initial={currentYear} options={listFilter.tahun} onChangeOptions={onChangeYear} zindex='z-20' bgColor='bg-slate-50' />
                    <SelectView width='w-full sm:w-[21rem]' initial={currentProdi} options={listFilter.prodi} onChangeOptions={onChangeProdi} zindex='z-20' bgColor='bg-slate-50' />
                </Column>
                <div className='p-5'>
                    <Table
                        className='border-[1px] border-gray-200'
                        limitRow={limitRow}
                        totalRow={totalRow}
                        totalPage={totalPage}
                        trigerPagination={sortTable}
                        onChangeShowNumber={onChangeShowNumber}
                        onChangeQuerySearch={onChangeQuerySearch}
                        onChangePageNumber={onChangePageNumber}>
                        <TableHead>
                            <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                <TableCell className='font-semibold font-manrope w-[5%]'>
                                    No.
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='nimhsmsmh' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    NPM
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='nmmhsmsmh' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Nama Lengkap
                                </TableCell>
                                <TableCell className='font-semibold font-manrope text-center' sortable sortkey='jenjang' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Jenjang
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='prodi' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Program Studi
                                </TableCell>
                                <TableCell className='font-semibold font-manrope w-[40%]'>
                                    Saran
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='divide-y-[1px] divide-gray-200'>
                            {listSaran.length > 0 ? listSaran.map((saran, idx) => {
                                return (
                                    <RowTableSaranTracerStudi
                                        key={idx}
                                        idx={idx}
                                        saran={saran}
                                        currentPage={currentPage}
                                        limitRow={limitRow} />
                                )
                            }) : <TableRow>
                                <TableCell colSpan={6} className='text-center text-gray-500'>
                                    No data to display
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
            </Column>
        </Card>
    )
}

export default TableSaranTracerStudi
