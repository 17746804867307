import Randomstring from 'randomstring'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { TbChecks, TbCircleDashed, TbInfoCircle, TbMessageCircle, TbMessageCircleOff, TbSend, TbTrash, TbUserCircle, TbX } from 'react-icons/tb'
import { deleteChatKonsultasi, finishKonsultasi, getChatKonsultasi, sendChatKonsultasi } from '../controllers/apicontrollers'
import { AlertDialog, ButtonHover, Caption, Card, Column, Form, FullScHeight, GridCol, Headline3, IconButton, LargeHeadline, LoadingDialog, OutlineTextArea, Row, Subtitle, TertiaryButton, Text, TextButton } from '../shared/ui'
import { getCurrentUser } from '../shared/user'
import CKonBubbleMessage from './cards/ckon_bubble_message'

const WKonChats = ({ currentMessage = null, channel = null, onApprove, onFinish, onUpdateMessage }) => {

    const messageRef = useRef()
    const [insert, setInsert] = useState(null)
    const [listChats, setListChats] = useState([])
    const [newMessage, setNewMessage] = useState(null)
    const [replyMessage, setReplyMessage] = useState(null)
    const [deleteMessage, setDeleteMessage] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [showDialogDone, setShowDialogDone] = useState(false)
    const [loading, setLoading] = useState(false)
    const [hideTopic, setHideTopic] = useState(false)

    const listRate = [
        { rate: 1, label: 'Sangat Tidak Puas' },
        { rate: 2, label: 'Tidak Puas' },
        { rate: 3, label: 'Cukup Puas' },
        { rate: 4, label: 'Puas' },
        { rate: 5, label: 'Sangat Puas' }
    ]

    const onSubmitForm = (event) => {
        event.preventDefault();
        const message = event.target.message.value;
        if (message.length > 0) {
            saveMessage(message)
        }
    }

    const enterKeyPressed = (event) => {
        if (event.keyCode == 13 && event.shiftKey == false) {
            event.preventDefault()
            const message = event.target.value
            if (message.length > 0) {
                saveMessage(message)
            }
        }
    }

    const saveMessage = async (message) => {
        const result = await sendChatKonsultasi(currentMessage.id_konsultasi, message, replyMessage !== null ? replyMessage.id_chat_konsultasi : null)
        if (result.status) {
            insertNewMessage(10, result.data)
        }
    }

    const insertNewMessage = (type, data) => {
        const listChatsTemp = listChats
        const newMessage = {
            chat: data.chat,
            created_at: data.created_at,
            from_who: data.from_who,
            id_chat_konsultasi: data.id_chat_konsultasi,
            id_konsultasi: currentMessage.id_konsultasi,
            replay_to: data.replay_to,
            updated_at: data.created_at,
        }
        listChatsTemp.unshift(newMessage)
        setListChats(listChatsTemp)
        setInsert(Randomstring.generate(6))
        onUpdateMessage({
            id: currentMessage.id_konsultasi,
            message: data.chat,
            date: data.created_at
        })
        if (type === 10) {
            messageRef.current.value = ''
            if (replyMessage !== null) {
                setReplyMessage(null)
            }
        } else {
            setNewMessage(null)
        }
    }

    const listenPusher = () => {
        const { username, kode } = getCurrentUser()
        channel.bind(`${kode}_${username}`, function (data) {
            setNewMessage(data)
        })
    }

    const getAllMessage = async () => {
        const result = await getChatKonsultasi(currentMessage.id_konsultasi)
        if (result.status) {
            setListChats(result.data)
        }
    }

    const onChangeReplyMessage = (message) => {
        setReplyMessage(message)
        messageRef.current?.focus()
    }

    const onChangeDeleteMessage = async (message) => {
        setDeleteMessage(message)
        setShowDialog(true)
    }

    const reqDeleteMessage = async () => {
        setShowDialog(false)
        setLoading(true)
        const result = await deleteChatKonsultasi(deleteMessage.id_chat_konsultasi)
        if (result.status) {
            setTimeout(() => {
                const chatTemp = listChats.filter((chat) => chat !== deleteMessage)
                setListChats(chatTemp)
                setDeleteMessage(null)
                setLoading(false)
                toast(result.message)
            }, 200)
        } else {
            setTimeout(() => {
                setLoading(false)
                toast(result.message)
            }, 200)
        }
    }

    const reqFinishConsultation = async () => {
        setShowDialogDone(false)
        setLoading(true)
        const result = await finishKonsultasi(currentMessage.id_konsultasi)
        if (result.status) {
            setTimeout(() => {
                onFinish()
                setLoading(false)
                toast(result.message)
            }, 200)
        } else {
            setTimeout(() => {
                setLoading(false)
                toast(result.message)
            }, 200)
        }
    }

    const findReplyMessage = (id) => {
        return listChats.find((chat) => {
            return chat.id_chat_konsultasi === id
        })
    }

    useEffect(() => { }, [insert])

    useEffect(() => {
        if (newMessage !== null) {
            if (newMessage.username === currentMessage.id_user) {
                insertNewMessage(20, newMessage)
            } else {
                setNewMessage(null)
            }
        }
    }, [newMessage])

    useEffect(() => {
        if (currentMessage !== null && currentMessage.respon !== null && currentMessage.respon !== false) {
            if (currentMessage.status === 'ONGOING') {
                listenPusher()
            }
            getAllMessage()
            setHideTopic(false)
            if (replyMessage !== null) {
                setReplyMessage(null)
            }
            messageRef.current?.focus()
        }
    }, [currentMessage])

    return (
        <>
            {currentMessage !== null ? <FullScHeight className='w-full relative bg-slate-50 hidden md:flex md:flex-col'>
                <Card className={`w-full px-4 py-3 rounded-none ${!hideTopic ? 'border-none' : 'border-x-0 border-t-0'}`}>
                    <Row className='gap-x-3 items-center'>
                        <div className='flex h-10 w-10 rounded-full justify-center items-center bg-gray-700'>
                            <Text className='text-white uppercase font-medium text-center'>
                                {currentMessage.nama_alumni !== null ? currentMessage.nama_alumni.substring(0, 2) : currentMessage.subject !== null ? currentMessage.subject.substring(0, 2) : 'SK'}
                            </Text>
                        </div>
                        <Column className='my-auto flex-1'>
                            <Text className='font-manrope font-semibold uppercase'>
                                {currentMessage !== null ? currentMessage.nama_alumni : 'Nama Alumni'}
                            </Text>
                            <Caption className='line-clamp-1'>
                                {currentMessage.subject ?? 'Subject Konsultasi'}
                            </Caption>
                        </Column>
                        {currentMessage.status === 'ONGOING' && <ButtonHover className='h-fit'>
                            <TertiaryButton color='red' onClick={() => { setShowDialogDone(true) }} className='pr-5' leadingIcon={<TbMessageCircleOff className='h-5 w-5 my-auto' />}>
                                Akhiri Konsultasi
                            </TertiaryButton>
                        </ButtonHover>}
                    </Row>
                </Card>
                {!hideTopic && <Row className='bg-yellow-200 px-6 py-3 gap-2'>
                    <Caption className='text-gray-800 line-clamp-1 flex-1 my-auto'>
                        <span className='font-semibold'>Topik:</span> {currentMessage.topik}
                    </Caption>
                    <IconButton onClick={() => { setHideTopic(true) }} color='custom' className='p-0.5 my-auto text-gray-500 hover:text-gray-800'>
                        <TbX className='h-4 w-4' />
                    </IconButton>
                </Row>}
                <Column className={`flex overflow-y-auto scrollbar-hide ${currentMessage.respon !== null && currentMessage.respon !== false ? 'flex-col-reverse h-full' : 'h-full items-center'}`}>
                    {currentMessage.status === 'COMPLETE' && <Column className='gap-y-2 pb-8'>
                        <Card className='max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full'>
                            <Row className='w-fit gap-x-2'>
                                <TbChecks className='h-5 w-5 text-green-600 y-auto' />
                                <Caption>
                                    Sesi konsultasi selesai
                                </Caption>
                            </Row>
                        </Card>
                    </Column>}
                    {currentMessage.respon !== null && currentMessage.respon !== false && listChats.map((chat, idx) => {
                        let margin = 'mb-5'
                        let reply = null
                        if (idx === 0) {
                            margin = 'mb-4'
                        } else if (idx > 0) {
                            if (chat.from_who === listChats[idx - 1].from_who) {
                                if (idx === (listChats.length - 1)) {
                                    margin = 'mb-0.5 mt-5'
                                } else {
                                    margin = 'mb-0.5'
                                }
                            } else {
                                if (idx === (listChats.length - 1)) {
                                    margin = 'my-5'
                                } else {
                                    margin = 'mb-5'
                                }
                            }
                        }
                        if (chat.replay_to !== null) {
                            reply = findReplyMessage(chat.replay_to) ?? null
                        }
                        return (
                            <CKonBubbleMessage
                                key={idx}
                                chat={chat}
                                margin={margin}
                                replyMessage={reply}
                                enableAction={currentMessage.status === 'ONGOING' ? true : false}
                                onReply={onChangeReplyMessage}
                                onDelete={onChangeDeleteMessage} />
                        )
                    })}
                    {currentMessage.respon === null ? <Card className='w-full max-w-sm my-auto rounded-xl p-0 shadow-xl shadow-gray-200'>
                        <Column className='gap-y-8 p-8'>
                            <Column className='gap-y-2 items-center'>
                                <Row className='-space-x-5 w-fit mb-3'>
                                    <div className='flex h-14 w-14 rounded-full border-2 border-white justify-center items-center bg-gray-700'>
                                        <Text className='text-white uppercase font-medium'>
                                            NA
                                        </Text>
                                    </div>
                                    <div className='flex h-14 w-14 rounded-full border-2 border-white justify-center items-center bg-gray-700'>
                                        <Text className='text-white uppercase font-medium'>
                                            {currentMessage.nama_alumni !== null ? currentMessage.nama_alumni.substring(0, 2) : currentMessage.subject !== null ? currentMessage.subject.substring(0, 2) : 'SK'}
                                        </Text>
                                    </div>
                                </Row>
                                <Headline3 className='text-center'>
                                    Konfirmasi Konsultasi
                                </Headline3>
                                <Text className='text-center'>
                                    Konfirmasi kesediaan Anda menjadi konselor pada sesi konsultasi bersama <span className='font-semibold'>{currentMessage.fullName}</span>
                                </Text>
                            </Column>
                            <Column className='gap-y-2'>
                                <Caption>
                                    Topik Konsultasi:
                                </Caption>
                                <Card className='w-full max-h-[20vh] py-3 px-4 border-gray-200/50 bg-slate-50 overflow-y-auto scrollbar-hide'>
                                    <Caption className='text-gray-800 whitespace-pre-line'>
                                        {currentMessage.topik}
                                    </Caption>
                                </Card>
                            </Column>
                        </Column>
                        <GridCol className='gap-0 divide-x border-t border-gray-200 divide-gray-200'>
                            <TextButton onClick={() => onApprove(10)} color='red' className='w-full py-4 text-base hover:bg-red-50/50'>
                                Tidak Bersedia
                            </TextButton>
                            <TextButton onClick={() => onApprove(20)} color='indigo' className={`w-full py-4 text-base hover:bg-indigo-50/50`}>
                                Mulai Sesi
                            </TextButton>
                        </GridCol>
                    </Card> : currentMessage.respon ? <Column className='gap-y-2 py-5'>
                        <Card className='max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full'>
                            <Row className='w-fit gap-x-2'>
                                <TbUserCircle className='h-5 w-5 text-blue-500 my-auto' />
                                <Caption>
                                    Bidang Alumni menunjuk Anda sebagai Konselor
                                </Caption>
                            </Row>
                        </Card>
                        <Card className='max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full'>
                            <Row className='w-fit gap-x-2'>
                                <TbChecks className='h-5 w-5 text-green-600 my-auto' />
                                <Caption>
                                    Anda menerima sesi konsultasi bersama {currentMessage.nama_alumni}
                                </Caption>
                            </Row>
                        </Card>
                        <Card className='max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full'>
                            <Row className='w-fit gap-x-2'>
                                <TbCircleDashed className='h-5 w-5 text-blue-500 my-auto' />
                                <Caption>
                                    Sesi konsultasi berlangsung
                                </Caption>
                            </Row>
                        </Card>
                    </Column> : <Column className='gap-1.5 py-5'>
                        <Card className='max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full'>
                            <Row className='w-fit gap-x-2'>
                                <TbUserCircle className='h-5 w-5 text-blue-500 my-auto' />
                                <Caption>
                                    Bidang Alumni menunjuk Anda sebagai Konselor
                                </Caption>
                            </Row>
                        </Card>
                        <Card className='max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full'>
                            <Row className='w-fit gap-x-2'>
                                <TbMessageCircleOff className='h-5 w-5 text-red-600 my-auto' />
                                <Caption>
                                    Anda menolak sesi konsultasi bersama {currentMessage.nama_alumni}
                                </Caption>
                            </Row>
                        </Card>
                    </Column>}
                </Column>
                {currentMessage.respon !== null && currentMessage.respon !== false && (currentMessage.status === 'ONGOING' || currentMessage.status === 'COMPLETE') ? <Column className='w-full bg-white border-t-[1px] px-4 py-4 gap-y-4 z-10'>
                    {replyMessage !== null && <Row className='gap-x-4'>
                        <Column className='border-l-[3px] border-l-yellow-500 px-5 rounded-l-sm'>
                            <Caption className='font-medium line-clamp-1 text-yellow-600'>
                                Membalas {replyMessage.from_who === 10 ? currentMessage.nama_alumni : 'Pesan Saya'}
                            </Caption>
                            <Caption className='line-clamp-1 whitespace-pre-line'>
                                {replyMessage.chat}
                            </Caption>
                        </Column>
                        <IconButton onClick={() => onChangeReplyMessage(null)} className='my-auto'>
                            <TbX className='h-5 w-5 text-red-600' />
                        </IconButton>
                    </Row>}
                    <Form onSubmit={onSubmitForm}>
                        <Row className='gap-x-3 items-end'>
                            <OutlineTextArea
                                id='message'
                                refs={messageRef}
                                inputWidth='w-full'
                                className='rounded-xl'
                                maxRows={6}
                                disabled={currentMessage.status !== 'ONGOING'}
                                bgColor='bg-slate-50'
                                onKeyDown={enterKeyPressed}
                                placeholder='Tulis pesan' />
                            {currentMessage.acc_bidal === 'ACCEPTED' && currentMessage.status === 'ONGOING' ? <ButtonHover>
                                <IconButton type='submit' color='custom' className='bg-gradient-to-br from-blue-600  to-indigo-600 text-white -translate-y-[0.17rem] hover:bg-indigo-700'>
                                    <TbSend className='h-6 w-6 rotate-45 -translate-x-0.5 stroke-1.5' />
                                </IconButton>
                            </ButtonHover> : <IconButton disabled className='-translate-y-[0.17rem]'>
                                <TbSend className='h-6 w-6 rotate-45 -translate-x-0.5 stroke-1.5' />
                            </IconButton>}
                        </Row>
                    </Form>
                </Column> : currentMessage.respon === null && currentMessage.respon !== false && currentMessage.status === 'PENDING' && <Card className='w-full px-6 py-5 rounded-none border-t-[1px] border-x-0 border-b-0'>
                    <Row className='gap-x-3 items-center'>
                        <TbInfoCircle className='h-5 w-5 my-auto text-gray-500' />
                        <Caption>
                            Sesi konsultasi akan dimulai saat Anda memulai sesi konsultasi sebagai konselor pada konsultasi yang diajukan.
                        </Caption>
                    </Row>
                </Card>}
            </FullScHeight > : <FullScHeight className='w-full bg-slate-50 hidden md:flex md:flex-col'>
                <Column className='h-full items-center'>
                    <Column className='max-w-xl h-fit items-center p-8 gap-12 my-auto'>
                        <Card className='rounded-3xl p-2'>
                            <TbMessageCircle className='h-20 w-20 stroke-1.5 text-indigo-600' />
                        </Card>
                        <Column className='gap-3'>
                            <LargeHeadline className='text-center font-semibold'>
                                Konsultasi Karir
                            </LargeHeadline>
                            <Subtitle className='text-center font-normal font-inter'>
                                Halo Konselor Konsultasi, selamat datang dilayanan Konsultasi Karir yang siap membimbing dan membantu Alumni dalam Sesi Konsultasi
                            </Subtitle>
                        </Column>
                        <Caption className='text-center mb-16'>
                            Segala konten layanan Konsultasi Karir telah diatur dalam Ketentuan Privasi dan Ketentuan Pengguna. Pengguna layanan ini wajib patuh kepada ketentuan yang berlaku.
                        </Caption>
                    </Column>
                </Column>
            </FullScHeight>}
            <AlertDialog className='max-w-xs' show={showDialog} onClose={() => { setShowDialog(false) }}>
                <Column>
                    <Column className='px-5 pt-10 pb-5 items-center gap-8'>
                        <Card className={`p-1.5 rounded-2xl bg-red-50/50 border-red-200/50`}>
                            <TbTrash className='h-14 w-14 text-red-600 stroke-1.5' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Hapus Pesan
                            </Headline3>
                            <Text className='text-center'>
                                Pesan akan dihapus pada sesi konsultasi dan mungkin sudah ada yang melihat sebelumnya
                            </Text>
                        </Column>
                    </Column>
                    <GridCol className='gap-0 divide-x border-t border-gray-200 divide-gray-200'>
                        <TextButton onClick={() => { setShowDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                            Batal
                        </TextButton>
                        <TextButton onClick={reqDeleteMessage} color='red' className={`w-full py-4 text-base hover:bg-red-50/50`}>
                            Hapus
                        </TextButton>
                    </GridCol>
                </Column>
            </AlertDialog>
            <AlertDialog className='max-w-xs' show={showDialogDone} onClose={() => { setShowDialogDone(false) }}>
                <Column>
                    <Column className='px-5 pt-10 pb-5 items-center gap-8'>
                        <Card className={`p-1.5 rounded-2xl bg-red-50/50 border-red-200/50`}>
                            <TbMessageCircleOff className='h-14 w-14 text-red-600 stroke-1.5' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Akhiri Konsultasi
                            </Headline3>
                            <Text className='text-center'>
                                Konselor dan alumni tidak bisa mengirim pesan setelah sesi konsultasi ditutup
                            </Text>
                        </Column>
                    </Column>
                    <GridCol className='gap-0 divide-x border-t border-gray-200 divide-gray-200'>
                        <TextButton onClick={() => { setShowDialogDone(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                            Batal
                        </TextButton>
                        <TextButton onClick={reqFinishConsultation} color='red' className={`w-full py-4 text-base hover:bg-red-50/50`}>
                            Akhiri
                        </TextButton>
                    </GridCol>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loading} message='Sedang memproses...' />
        </>
    )
}

export default WKonChats
