import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableLokerCareer from "./rows/row_loker_career";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Column,
  Headline3,
  HorizontalDivider,
  Image,
  Row,
  SecondaryButton,
  Text,
} from "../../../../shared/ui";
import {
  TbArrowUpRight,
  TbBrandWhatsapp,
  TbCertificate,
  TbMail,
  TbMapPin,
  TbPhone,
  TbUserCircle,
  TbX,
} from "react-icons/tb";

function TableLokerCareer({ listLowongan, page, limit, onShort }) {
  const [currentSort, setCurrentSort] = useState(null);
  const [lowongan, setLowongan] = useState({});
  const [showDialog, setShowDialog] = useState(false);

  const onHandleClick = (recognition) => {
    setLowongan(recognition);
    setShowDialog(true);
    console.log("recognition :", recognition);
  };

  // const onHandleSort = (value) => {
  //   setCurrentSort(value);
  //   onShort(value);
  // };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Logo Mitra</TableHeadCell>
            <TableHeadCell>Nama Mitra</TableHeadCell>
            <TableHeadCell>Nama PIC</TableHeadCell>
            <TableHeadCell>Total Lowongan</TableHeadCell>
            <TableHeadCell>Belum Diproses</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listLowongan.length > 0 ? (
            listLowongan.map((lowongan, idx) => {
              return (
                <RowTableLokerCareer
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  lowongan={lowongan}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

export default TableLokerCareer;
