import React from 'react'
import { Column, Row, ShimmerAnimation, ShimmerItem } from '../../shared/ui'

function SAlmRecommendation() {
  return (
    <ShimmerAnimation>
      <ShimmerItem className={'w-72 h-8 mx-3 mb-2 rounded-xl'} />
      {Array.from({ length: 5 }, (_, idx) => {
        return (
          <Row
            key={idx}
            className={'px-3 py-3 gap-x-4 items-center'}>
            <ShimmerItem className={'w-full h-full max-w-[4.5rem] aspect-square rounded-lg'} />
            <Column className={'gap-y-3'}>
              <ShimmerItem className={'w-full h-6 rounded-xl'} />
              <ShimmerItem className={'w-36 h-5 rounded-xl'} />
            </Column>
          </Row>
        )
      })}
    </ShimmerAnimation>
  )
}

export default SAlmRecommendation