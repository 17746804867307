import { createContext, useEffect, useState } from "react";
import { setupMonth, setupTahun } from "../../shared/ta";
import { postSurveiController } from "../../controllers/global_controllers";
import { getMasterProdiController } from "../../controllers/admin_controllers";

export const KuesionerContext = createContext();

export const KuesionerProvider = ({ children }) => {
  const [selectedEtika, setSelectedEtika] = useState("");
  const [selectedKeahlian, setSelectedKeahlian] = useState("");
  const [selectedBahasa, setSelectedBahasa] = useState("");
  const [selectedInformasi, setSelectedInformasi] = useState("");
  const [selectedKomunikasi, setSelectedKomunikasi] = useState("");
  const [selectedKerjasama, setSelectedKerjasama] = useState("");
  const [selectedPengembangan, setSelectedPengembangan] = useState("");
  const [selectedKesiapan, setSelectedKesiapan] = useState("");
  const [optionProdi, setOptionProdi] = useState([]);
  const [optionTahun, setOptionTahun] = useState([]);
  const [optionBulan, setOptionBulan] = useState([]);
  const [initProdi, setInitProdi] = useState({
    label: "Pilih Program Studi",
    value: null,
  });
  const [initTahun, setInitTahun] = useState({
    label: "Pilih tahun",
    value: null,
  });
  const [initBulan, setInitBulan] = useState({
    label: "Pilih bulan",
    value: null,
  });
  const [step, setStep] = useState(1);
  const [showDialogInput, setShowDialogInput] = useState(false);
  const [showDialogSubmitFailed, setShowDialogSubmitFailed] = useState(false);
  const [showDialogSubmitSuccess, setShowDialogSubmitSuccess] = useState(false);
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [captcaInput, setCaptchaInput] = useState("");
  const [captchaMessage, setCaptchaMessage] = useState("");
  const [formData, setFormData] = useState({
    pengisi_nama: "",
    pengisi_perusahaan: "",
    pengisi_alamat: "",
    pengisi_jabatan: "",
    pengisi_email: "",
    mhs_nama: "",
    mhs_prodi: "",
    mhs_kerja: "",
    mhs_jabatan: "",
    s_etika: "",
    s_keahlian: "",
    s_asing: "",
    s_teknologi: "",
    s_komunikasi: "",
    s_kerjasama: "",
    s_pengembangan: "",
    s_terjun: "",
    m_keunggulan: "",
    m_kelemahan: "",
    m_dikuasai: "",
    m_saran: "",
  });

  const closeDialogInput = () => {
    setShowDialogInput();
  };

  const closeDialogSubmitFailed = () => {
    setShowDialogSubmitFailed();
  };

  const closeDialogSubmitSuccess = () => {
    setShowDialogSubmitSuccess();
  };

  const regenerateCaptca = () => {
    setNum1(Math.floor(Math.random() * 10));
    setNum2(Math.floor(Math.random() * 10));
    setCaptchaInput("");
    setCaptchaMessage("");
  };

  const option = [
    { label: "Sangat Baik", value: "sangat_baik" },
    { label: "Baik", value: "baik" },
    { label: "Cukup", value: "cukup" },
    { label: "Kurang", value: "kurang" },
  ];

  const handleSelectChange = (e, fieldName) => {
    if (fieldName === "mhs_prodi") {
      setInitProdi(e);
      setFormData({
        ...formData,
        mhs_prodi: e.value.toString(),
      });
    } else if (fieldName === "mhs_kerja_bulan") {
      setInitBulan(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: e.label + " " + (initTahun.value || ""),
      }));
    } else if (fieldName === "mhs_kerja_tahun") {
      setInitTahun(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: (initBulan.label || "") + "" + e.value,
      }));
    }
  };

  const handleRadioChange = (value, radioName) => {
    if (radioName === "s_etika") {
      setSelectedEtika(value);
      setFormData({ ...formData, s_etika: value });
    } else if (radioName === "s_keahlian") {
      setSelectedKeahlian(value);
      setFormData({ ...formData, s_keahlian: value });
    } else if (radioName === "s_asing") {
      setSelectedBahasa(value);
      setFormData({ ...formData, s_asing: value });
    } else if (radioName === "s_teknologi") {
      setSelectedInformasi(value);
      setFormData({ ...formData, s_teknologi: value });
    } else if (radioName === "s_komunikasi") {
      setSelectedKomunikasi(value);
      setFormData({ ...formData, s_komunikasi: value });
    } else if (radioName === "s_kerjasama") {
      setSelectedKerjasama(value);
      setFormData({ ...formData, s_kerjasama: value });
    } else if (radioName === "s_pengembangan") {
      setSelectedPengembangan(value);
      setFormData({ ...formData, s_pengembangan: value });
    } else if (radioName === "s_terjun") {
      setSelectedKesiapan(value);
      setFormData({ ...formData, s_terjun: value });
    }
  };

  const getDataProdi = async () => {
    try {
      const dataProdi = await getMasterProdiController();
      const formattedOptions = dataProdi.data.map((prodi) => ({
        label: `${prodi.jenjang} - ${prodi.prodi}`,
        value: prodi.kode_prodi,
      }));
      setOptionProdi(formattedOptions);
    } catch (error) {
      console.error("error get data prodi", error.message);
    }
  };

  useEffect(() => {
    getDataProdi();
    setOptionTahun(setupTahun({ start: 2003 }));
    setOptionBulan(setupMonth({ start: "Januari" }));
  }, []);

  // err input
  const [errNamaPengisi, setErrNamaPengisi] = useState(null);
  const [errNamaPerusahaan, setErrNamaPerusahaan] = useState(null);
  const [errAlamatPerusahaan, setErrAlamatPerusahaan] = useState(null);
  const [errJabatanPengisi, setErrJabatanPengisi] = useState(null);
  const [errEmailPengisi, setErrEmailPengisi] = useState(null);
  const [errMhsNama, setErrMhsNama] = useState(null);
  const [errMhsJabatan, setErrMhsJabatan] = useState(null);
  const [errEtika, setErrEtika] = useState(null);
  const [errKeahlian, setErrKeahlian] = useState(null);
  const [errAsing, setErrAsing] = useState(null);
  const [errTeknologi, setErrTeknologi] = useState(null);
  const [errKomunikasi, setErrKomunikasi] = useState(null);
  const [errKerjasama, setErrKerjasama] = useState(null);
  const [errPengembangan, setErrPengembangan] = useState(null);
  const [errTerjun, setErrTerjun] = useState(null);
  const [errKeunggulan, setErrKeunggulan] = useState(null);
  const [errKelemahan, setErrKelemahan] = useState(null);
  const [errDikuasai, setErrDikuasai] = useState(null);
  const [errSaran, setErrSaran] = useState(null);

  // err Length
  const [errLengthNamaPengisi, setErrLengthNamaPengisi] = useState(null);
  const [errLengthNamaPerusahaan, setErrLengthNamaPerusahaan] = useState(null);
  const [errLengthAlamatPerusahaan, setErrLengthAlamatPerusahaan] =
    useState(null);
  const [errLengthJabatanPengisi, setErrLengthJabatanPengisi] = useState(null);
  const [errLengthEmailPengisi, setErrLengthEmailPengisi] = useState(null);
  const [errLenghtMhsNama, setErrLenghtMhsNama] = useState(null);
  const [errLenghtMhsJabatan, setErrLenghtMhsJabatan] = useState(null);
  const [errLenghtKeunggulan, setErrLenghtKeunggulan] = useState(null);
  const [errLenghtKelemahan, setErrLenghtKelemahan] = useState(null);
  const [errLenghtDikuasai, setErrLenghtDikuasai] = useState(null);
  const [errLenghtSaran, setErrLenghtSaran] = useState(null);

  const nextStep = () => {
    if (step === 2) {
      // err input
      if (!formData.pengisi_nama) {
        setErrNamaPengisi("Nama pengisi tidak boleh kosong");
        return;
      } else if (formData.pengisi_nama.length !== null) {
        setErrNamaPengisi();
      }

      if (!formData.pengisi_perusahaan) {
        setErrNamaPerusahaan("Nama perusahaan/instansi tidak boleh kosong");
        return;
      } else if (formData.pengisi_perusahaan !== null) {
        setErrNamaPerusahaan();
      }

      if (!formData.pengisi_alamat) {
        setErrAlamatPerusahaan("Alamat perusahaan/instansi tidak boleh kosong");
        return;
      } else if (formData.pengisi_alamat !== null) {
        setErrAlamatPerusahaan();
      }

      if (!formData.pengisi_jabatan) {
        setErrJabatanPengisi("Posisi jabatan pengisi tidak boleh kosong");
        return;
      } else if (formData.pengisi_jabatan !== null) {
        setErrJabatanPengisi();
      }

      if (!formData.pengisi_email) {
        setErrEmailPengisi("Email pengisi tidak boleh kosong");
        return;
      } else if (formData.pengisi_email !== null) {
        setErrEmailPengisi();
      }

      //  err length
      if (formData.pengisi_nama.length < 4) {
        setErrLengthNamaPengisi(
          "Inputan nama pengisi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_nama.length >= 4) {
        setErrLengthNamaPengisi(null);
      }

      if (formData.pengisi_perusahaan.length < 4) {
        setErrLengthNamaPerusahaan(
          "Inputan nama perusahaan/instansi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_perusahaan.length >= 4) {
        setErrLengthNamaPerusahaan(null);
      }

      if (formData.pengisi_alamat.length < 4) {
        setErrLengthAlamatPerusahaan(
          "Inputan alamat perusahaan/instansi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_alamat.length >= 4) {
        setErrLengthAlamatPerusahaan(null);
      }

      if (formData.pengisi_jabatan.length < 4) {
        setErrLengthJabatanPengisi(
          "Inputan posisi jabatan pengisi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_jabatan.length >= 4) {
        setErrLengthJabatanPengisi(null);
      }

      if (formData.pengisi_email.length < 4) {
        setErrLengthEmailPengisi(
          "Inputan email pengisi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_email.length >= 4) {
        setErrLengthEmailPengisi(null);
      }
    }

    if (step === 3) {
      if (!formData.mhs_nama) {
        setErrMhsNama("Nama lulusan tidak boleh kosong");
        return;
      } else if (formData.mhs_nama !== null) {
        setErrMhsNama(null);
      }

      if (!formData.mhs_jabatan) {
        setErrMhsJabatan("Jabatan/posisi lulusan tidak boleh kosong");
        return;
      } else if (formData.mhs_jabatan !== null) {
        setErrMhsJabatan(null);
      }

      if (formData.mhs_nama.length < 4) {
        setErrLenghtMhsNama("Inputan nama lulusan harus lebih dari 3 karakter");
        return;
      } else if (formData.mhs_nama.length <= 4) {
        setErrLenghtMhsNama(null);
      }

      if (formData.mhs_jabatan.length < 4) {
        setErrLenghtMhsJabatan(
          "Inputan Jabatan/posisi lulusan harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.mhs_jabatan.length <= 4) {
        setErrLenghtMhsJabatan(null);
      }
    }

    if (step === 4) {
      // err input
      if (!formData.s_etika) {
        setErrEtika("Sikap/etika tidak boleh kosong");
        return;
      } else if (formData.s_etika !== null) {
        setErrEtika(null);
      }

      if (!formData.s_keahlian) {
        setErrKeahlian("Keahlian tidak boleh kosong");
        return;
      } else if (formData.s_keahlian !== null) {
        setErrKeahlian(null);
      }

      if (!formData.s_asing) {
        setErrAsing("Kemampuan berbahasa asing tidak boleh kosong");
        return;
      } else if (formData.s_asing !== null) {
        setErrAsing(null);
      }

      if (!formData.s_teknologi) {
        setErrTeknologi("Penggunaan teknologi tidak boleh kosong");
        return;
      } else if (formData.s_teknologi !== null) {
        setErrTeknologi(null);
      }

      if (!formData.s_komunikasi) {
        setErrKomunikasi("Kemampuan berkomunikasi tidak boleh kosong");
        return;
      } else if (formData.s_komunikasi !== null) {
        setErrKomunikasi(null);
      }

      if (!formData.s_kerjasama) {
        setErrKerjasama("Kemampuan kerjasama tidak boleh kosong");
        return;
      } else if (formData.s_kerjasama !== null) {
        setErrKerjasama(null);
      }

      if (!formData.s_pengembangan) {
        setErrPengembangan("Kemampuan pengembangan diri tidak boleh kosong");
        return;
      } else if (formData.s_pengembangan !== null) {
        setErrPengembangan(null);
      }

      if (!formData.s_pengembangan) {
        setErrPengembangan("Kemampuan pengembangan diri tidak boleh kosong");
        return;
      } else if (formData.s_pengembangan !== null) {
        setErrPengembangan(null);
      }

      if (!formData.s_terjun) {
        setErrTerjun("Kesiapan terjun dimasyarakat tidak boleh kosong");
        return;
      } else if (formData.s_terjun !== null) {
        setErrTerjun(null);
      }
    }

    if (step === 5) {
      //  err input
      if (!formData.m_keunggulan) {
        setErrKeunggulan("Keunggulan tidak boleh kosong");
        return;
      } else if (formData.m_keunggulan !== null) {
        setErrKeunggulan(null);
      }
      if (!formData.m_kelemahan) {
        setErrKelemahan("Kelemahan/kekurangan tidak boleh kosong");
        return;
      } else if (formData.m_kelemahan !== null) {
        setErrKelemahan(null);
      }
      if (!formData.m_dikuasai) {
        setErrDikuasai("Keterampilan/kemampuan/kompetensi tidak boleh kosong");
        return;
      } else if (formData.m_dikuasai !== null) {
        setErrDikuasai(null);
      }
      if (!formData.m_saran) {
        setErrSaran("Saran tidak boleh kosong");
        return;
      } else if (formData.m_saran !== null) {
        setErrSaran(null);
      }

      // err lenght
      if (formData.m_keunggulan.length < 4) {
        setErrLenghtKeunggulan(
          "Inputan Keunggulan lulusan harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.m_keunggulan.length <= 4) {
        setErrKeunggulan(null);
      }

      if (formData.m_kelemahan.length < 4) {
        setErrLenghtKelemahan(
          "Inputan kelemahan lulusan harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.m_kelemahan.length <= 4) {
        setErrKelemahan(null);
      }

      if (formData.m_dikuasai.length < 4) {
        setErrLenghtDikuasai(
          "Inputan keterampilan/kemampuan/kompetensi lulusan harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.m_dikuasai.length <= 4) {
        setErrLenghtDikuasai(null);
      }

      if (formData.m_saran.length < 4) {
        setErrLenghtSaran("Inputan saran lulusan harus lebih dari 3 karakter");
        return;
      } else if (formData.m_saran.length <= 4) {
        setErrLenghtSaran(null);
      }
    }

    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const backSubmit = () => {
    setStep(2);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [message, setMessage] = useState(null);
  const [showDialogCaptcha, setShowDialogCaptca] = useState(false);

  const closeDialogCaptcha = () => {
    setShowDialogCaptca();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await postSurveiController(formData);

      if (parseInt(captcaInput) !== num1 + num2) {
        setShowDialogCaptca(true);
        regenerateCaptca();
        return;
      }

      if (!result.status) {
        setMessage(result.message);
        setShowDialogSubmitFailed(true);
        return;
      }

      setMessage(result.message);
      setShowDialogSubmitSuccess(true);

      setFormData({
        pengisi_nama: "",
        pengisi_perusahaan: "",
        pengisi_alamat: "",
        pengisi_jabatan: "",
        pengisi_email: "",
        mhs_nama: "",
        mhs_prodi: "",
        mhs_kerja: "",
        mhs_jabatan: "",
        s_etika: setSelectedEtika(),
        s_keahlian: setSelectedKeahlian(),
        s_asing: setSelectedBahasa(),
        s_teknologi: setSelectedInformasi(),
        s_komunikasi: setSelectedKomunikasi(),
        s_kerjasama: setSelectedKerjasama(),
        s_pengembangan: setSelectedPengembangan(),
        s_terjun: setSelectedKeahlian(),
        m_keunggulan: "",
        m_kelemahan: "",
        m_dikuasai: "",
        m_saran: "",
      });

      setTimeout(() => {
        setStep(1);
        setShowDialogSubmitSuccess();
      }, 5000);
    } catch (error) {
      console.error("error add data", error.message);
    }
  };
  return (
    <KuesionerContext.Provider
      value={{
        selectedEtika,
        selectedKeahlian,
        selectedBahasa,
        selectedInformasi,
        selectedKomunikasi,
        selectedKerjasama,
        selectedPengembangan,
        selectedKesiapan,
        optionProdi,
        optionTahun,
        optionBulan,
        initProdi,
        initTahun,
        initBulan,
        step,
        formData,
        option,
        handleSelectChange,
        handleRadioChange,
        nextStep,
        previousStep,
        handleInputChange,
        handleSubmit,
        showDialogInput,
        closeDialogInput,
        showDialogSubmitFailed,
        showDialogSubmitSuccess,
        closeDialogSubmitFailed,
        closeDialogSubmitSuccess,
        message,
        backSubmit,
        num1,
        num2,
        captcaInput,
        setCaptchaInput,
        showDialogCaptcha,
        closeDialogCaptcha,
        errNamaPengisi,
        errNamaPerusahaan,
        errAlamatPerusahaan,
        errJabatanPengisi,
        errEmailPengisi,
        errMhsNama,
        errMhsJabatan,
        errEtika,
        errKeahlian,
        errAsing,
        errTeknologi,
        errKomunikasi,
        errKerjasama,
        errPengembangan,
        errTerjun,
        errKeunggulan,
        errKelemahan,
        errDikuasai,
        errSaran,
        errLengthNamaPengisi,
        errLengthNamaPerusahaan,
        errLengthAlamatPerusahaan,
        errLengthJabatanPengisi,
        errLengthEmailPengisi,
        errLenghtMhsNama,
        errLenghtMhsJabatan,
        errLenghtKeunggulan,
        errLenghtKelemahan,
        errLenghtKelemahan,
        errLenghtDikuasai,
        errLenghtSaran,
      }}
    >
      {children}
    </KuesionerContext.Provider>
  );
};
