import React, { useEffect, useState } from 'react'
import { Column } from '../../../../components/interfaces/block'
import { Card } from '../../../../components/interfaces/card'
import { SelectInput } from '../../../../components/interfaces/input'
import { CaptionText, TitleText } from '../../../../components/interfaces/text'
import { getOvGrafikIsiTracerController } from '../../../../controllers/admin_controllers'
import { setupTahun } from '../../../../shared/ta'
import { AreaChart } from '../../../../shared/ui'

function ChartIsiTracer({ series }) {

  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']

  const [currentYear, setCurrentYear] = useState({ label: new Date().getFullYear(), value: new Date().getFullYear() })
  const [filterTahun, setFilterTahun] = useState([])
  const [locSeries, setLocSeries] = useState([])
  const [useLocal, setUseLocal] = useState(false)

  const getOvGrafikIsiTracer = async () => {
    const props = {
      tahun: currentYear.value,
    }
    const result = await getOvGrafikIsiTracerController(props)
    if (result.status) {
      setLocSeries(result.data)
      setUseLocal(true)
    }
  }

  const onChangeYear = (value) => {
    setCurrentYear(value)
  }

  useEffect(() => {
    setFilterTahun(setupTahun)
  }, [])

  useEffect(() => {
    getOvGrafikIsiTracer()
  }, [currentYear])

  return (
    <Card className={'w-full p-0'}>
      <Column className={'gap-y-2'}>
        <Column className={'p-4 gap-5 sm:p-6 sm:flex-row'}>
          <Column className={'gap-y-1'}>
            <TitleText>
              Grafik Sebaran Responden Tracer Studi
            </TitleText>
            <CaptionText>
              Jumlah almuni yang mengisi Tracer Studi
            </CaptionText>
          </Column>
          <div>
            <SelectInput
              width='w-full sm:w-24'
              initial={currentYear}
              options={filterTahun}
              onChange={onChangeYear}
              zindex='z-10' />
          </div>
        </Column>
        <div className={'px-3 pb-0'}>
          <AreaChart
            height={300}
            lineWidth={2.3}
            series={useLocal ? locSeries : series}
            label={labels}
            listColors={['#2196F3']} />
        </div>
      </Column>
    </Card>
  )
}

export default ChartIsiTracer