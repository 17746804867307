import React, { useEffect, useState } from "react";
import { TbPlus } from "react-icons/tb";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Column } from "../../../components/interfaces/block";
import { Card } from "../../../components/interfaces/card";
import { SearchInput } from "../../../components/interfaces/input";
import { BodyText, Headline2Text } from "../../../components/interfaces/text";
import {
  Pagination,
  PaginationOption,
  useDebounce,
} from "../../../components/interfaces/utility";
import { getLokerByMitraController } from "../../../controllers/admin_controllers";
import { ButtonHover, PrimaryButton } from "../../../shared/ui";
import TableLokerCareer from "./tables/table_loker_career";
import AdmAddCareerPage from "./adm_add_career";
import { admAddCareerRoute, recognitionRoute } from "../../../shared/variable";

function AdmLokerCareerPage() {
  const result = useLoaderData();

  const navigateTo = useNavigate();

  const [listLowongan, setListLowongan] = useState([]);
  const [limitRow, setLimitRow] = useState(10);
  const [totalRow, setTotalRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [currentSort, setCurrentSort] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [stsGetLoader, setStsGetLoader] = useState(false);

  const debounceSearch = useDebounce({ value: searchQuery, delay: 1000 });

  const getLokerByMitraFromLoader = () => {
    if (result.status) {
      setListLowongan(result.data);
      setTotalRow(result.totalRows);
      setTotalPage(result.totalPage);
    }
    setStsGetLoader(true);
  };

  const regetLokerByMitra = async () => {
    const result = await getAdmLokerByMitra({
      search: searchQuery,
      limit: limitRow,
      page: currentPage,
      order: currentSort !== null ? currentSort.key : null,
      asc: currentSort !== null ? currentSort.type : null,
    });
    if (result.status) {
      setListLowongan(result.data);
      setTotalRow(result.totalRows);
      setTotalPage(result.totalPage);
    }
  };

  const onHandlePaginationOption = (value) => {
    setLimitRow(value);
    setCurrentPage(1);
  };

  const onHandleSearch = (event) => {
    event.preventDefault();
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null);
    setCurrentPage(1);
    if (currentSort !== null) {
      setCurrentSort(null);
    }
  };

  const onHandlePagination = (value) => {
    setCurrentPage(value);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
  };

  useEffect(() => {
    getLokerByMitraFromLoader();
  }, []);

  useEffect(() => {
    if (stsGetLoader) {
      regetLokerByMitra();
    }
  }, [debounceSearch, limitRow, currentPage, currentSort]);

  return (
    <Column className={"p-4 gap-y-5 sm:p-8"}>
      <Column className={"gap-x-4 gap-y-2 md:flex-row"}>
        <Column className={"gap-y-2 flex-1"}>
          <Headline2Text className={"font-semibold"}>
            Lowongan Kerja
          </Headline2Text>
          <BodyText>
            Semua data lowongan pekerjaan yang dikelompokan berdasarkan Mitra
            UTY.
          </BodyText>
        </Column>
        <ButtonHover>
          <PrimaryButton
            color="indigo"
            className={"pr-6"}
            leadingIcon={<TbPlus className="h-5 w-5 my-auto" />}
            onClick={() => navigateTo(admAddCareerRoute)}
          >
            Buat Lowongan
          </PrimaryButton>
        </ButtonHover>
      </Column>
      <Card className={"w-full"}>
        <Column className={"gap-y-5"}>
          <Column className="gap-x-4 gap-y-2 md:flex-row md:justify-between">
            <PaginationOption
              initial={limitRow}
              onChange={onHandlePaginationOption}
            />
            <SearchInput
              defaultValue={searchQuery}
              placeholder="Cari lowongan"
              onChange={onHandleSearch}
            />
          </Column>
          <TableLokerCareer
            limit={limitRow}
            page={currentPage}
            listLowongan={listLowongan}
            onShort={onHandleSort}
          />
          {listLowongan.length > 0 && (
            <Pagination
              limitRow={limitRow}
              totalRow={totalRow}
              totalPage={totalPage}
              triger={currentSort !== null && currentSort.key}
              onChange={onHandlePagination}
            />
          )}
        </Column>
      </Card>
    </Column>
  );
}

export default AdmLokerCareerPage;

export const getAdmLokerByMitra = async ({
  status = "ACCEPTED",
  search = null,
  limit = 10,
  page = 1,
  order = null,
  asc = null,
}) => {
  const props = {
    status: status,
    search: search,
    limit: limit,
    page: page,
    order: order,
    asc: asc,
  };
  const result = await getLokerByMitraController(props);
  return result;
};
