import React from 'react'
import { IconButton, Row, TableCell, TableRow, TagView, Text, Tooltip } from '../../../shared/ui'
import moment from 'moment'
import { TbArrowRight, TbChecks, TbRefresh, TbUserCheck, TbUserCircle } from 'react-icons/tb'

const RowTableAcceptedLowongan = ({ lowongan, idx, onShowDialog, action = 10, currentPage = 1, limitRow = 1 }) => {

    const regex = /(<([^>]+)>)/gi;
    const jobdesc = lowongan.jobdesc.replace('\r\n', '').replace(regex, '');

    return (
        <TableRow onClick={() => onShowDialog(lowongan)} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50 cursor-pointer`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                <Tooltip message={lowongan.posisi}>
                    <Text className='line-clamp-2'>
                        {lowongan.posisi}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip message={jobdesc.replaceAll('&nbsp;', ' ')}>
                    <Text className='line-clamp-2'>
                        {jobdesc.replaceAll('&nbsp;', ' ')}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                {lowongan.total_pelamar} Pelamar
            </TableCell>
            <TableCell>
                {moment(lowongan.berlaku_mulai).format('DD MMM YYYY')}
            </TableCell>
            <TableCell>
                {moment(lowongan.berlaku_hingga).format('DD MMM YYYY')}
            </TableCell>
            <TableCell className='px-3 flex justify-center'>
                <IconButton className='p-1.5' bordered={true} color='indigo' onClick={() => onShowDialog(lowongan)}>
                    <TbArrowRight className='h-5 w-5' />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default RowTableAcceptedLowongan