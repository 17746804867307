import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { TbMoodCry, TbPlus, TbUserCircle, TbX } from 'react-icons/tb'
import { getMasterKonselor, saveNewKonselor, searchKonselor } from '../../controllers/apicontrollers'
import { AlertDialog, ButtonHover, Caption, Card, Column, GridCol, Headline3, HorizontalDivider, IconButton, LoadingDialog, OutlineInput, Row, Table, TableBody, TableCell, TableHead, TableRow, TertiaryButton, Text, TextButton, Title } from '../../shared/ui'
import CAdmKonselor from '../cards/cadm_konselor'
import RowTablePlotingKonselor from './rows/row_ploting_konselor'

const TablePlotingKonselor = ({ onDoneLoadData }) => {

    const [listKonselor, setListKonselor] = useState([])
    const [searchQuery, setSearchQuery] = useState(null)
    const [sortTable, setShortTable] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [listSearch, setListSearch] = useState([])
    const [listSelected, setListSelected] = useState([])
    const [loading, setLoading] = useState(false)

    const getDataMasterKonselor = async () => {
        const props = {
            limit: limitRow,
            page: currentPage,
            search: searchQuery,
            order: sortTable !== null ? sortTable.key : null,
            asc: sortTable !== null ? sortTable.type : null
        }
        const result = await getMasterKonselor(props)
        if (result.status) {
            setListKonselor(result.data)
            setTotalRow(result.totalRows)
            setTotalPage(result.totalPage)
        }
        onDoneLoadData(true)
    }

    const onChangeShowNumber = (value) => {
        setCurrentPage(1)
        setLimitRow(value)
    }

    const onChangeQuerySearch = (value) => {
        setCurrentPage(1)
        setSearchQuery(value)
    }

    const onChangePageNumber = (value) => {
        setCurrentPage(value)
    }

    const onShortableValue = (value) => {
        setCurrentPage(1)
        setShortTable(value)
    }

    const showDialogKonselor = () => {
        setListSearch([])
        setListSelected([])
        getSearchKonselor('')
        setShowDialog(true)
    }

    const onChangeInput = async (event) => {
        event.preventDefault();
        const query = event.target.value
        getSearchKonselor(query)
    }

    const getSearchKonselor = async (query) => {
        const result = await searchKonselor(query)
        if (result.status) {
            setListSearch(result.data)
        }
    }

    const chooseKonselor = (konselor) => {
        const currentSelected = [...listSelected]
        const searchKonselor = currentSelected.filter((selected) => selected.nik === konselor.nik)
        if (searchKonselor.length > 0) {
            const newSelected = currentSelected.filter((selected) => selected.nik !== konselor.nik)
            setListSelected(newSelected)
        } else {
            currentSelected.unshift(konselor)
            setListSelected(currentSelected)
        }
    }

    const saveKonselor = async () => {
        setShowDialog(false)
        setLoading(true)
        const listNik = []
        if (listSelected.length > 0) {
            listSelected.map((selected) => {
                listNik.push(selected.nik)
            })
        }
        const result = await saveNewKonselor(listNik)
        if (result.status) {
            setTimeout(() => {
                getDataMasterKonselor()
                setLoading(false)
                toast(result.message)
            }, 200)
        } else {
            setTimeout(() => {
                setLoading(false)
                toast(result.message)
            }, 200)
        }
    }

    useEffect(() => {
        getDataMasterKonselor()
    }, [searchQuery, sortTable, limitRow, currentPage])

    return (
        <>
            <Card className='w-full border-gray-200 p-0'>
                <Column>
                    <Column className='px-5 py-4'>
                        <Row className='justify-between'>
                            <Column className='gap-0.5'>
                                <Title>
                                    Data Konselor
                                </Title>
                                <Text>
                                    Daftar Dosen atau Mitra yang diberikan tugas sebagai Konselor pada Sesi Konsultasi
                                </Text>
                            </Column>
                            <ButtonHover className='my-auto'>
                                <TertiaryButton color='indigo' onClick={showDialogKonselor} className='h-fit my-auto py-2 pl-3 pr-4' leadingIcon={<TbPlus className='h-5 w-5 my-auto' />}>
                                    Tambah
                                </TertiaryButton>
                            </ButtonHover>
                        </Row>
                    </Column>
                    <HorizontalDivider className='mt-0 mb-0' />
                    <div className='p-5'>
                        <Table
                            className='border-[1px] border-gray-200'
                            limitRow={limitRow}
                            totalRow={totalRow}
                            totalPage={totalPage}
                            trigerPagination={sortTable}
                            onChangeShowNumber={onChangeShowNumber}
                            onChangeQuerySearch={onChangeQuerySearch}
                            onChangePageNumber={onChangePageNumber}>
                            <TableHead>
                                <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                    <TableCell className='font-semibold font-manrope'>
                                        No.
                                    </TableCell>
                                    <TableCell className='font-semibold font-manrope' sortable={false} sortkey='nik' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                        NIK
                                    </TableCell>
                                    <TableCell className='font-semibold font-manrope' sortable={false} sortkey='nama' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                        Nama Konselor
                                    </TableCell>
                                    <TableCell className='font-semibold font-manrope' sortable={false} sortkey='berlangsung' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                        Sesi Aktif
                                    </TableCell>
                                    <TableCell className='font-semibold font-manrope' sortable={false} sortkey='selesai' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                        Sesi Selesai
                                    </TableCell>
                                    <TableCell className='font-semibold font-manrope' sortable={false} sortkey='ditolak' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                        Menolak Sesi
                                    </TableCell>
                                    <TableCell className='font-semibold font-manrope' sortable={false} sortkey='rating' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                        Rating
                                    </TableCell>
                                    <TableCell className='font-semibold font-manrope px-3 text-center'>
                                        Detail
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='divide-y-[1px] divide-gray-200'>
                                {listKonselor.length > 0 ? listKonselor.map((konselor, idx) => {
                                    return <RowTablePlotingKonselor
                                        key={idx}
                                        idx={idx}
                                        limitRow={limitRow}
                                        currentPage={currentPage}
                                        konselor={konselor} />
                                }) : <TableRow>
                                    <TableCell colSpan={8} className='text-center text-gray-500'>
                                        No data to display
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                </Column>
            </Card>
            <AlertDialog className='max-w-sm' show={showDialog} onClose={() => setShowDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 pt-10 pb-8 gap-8 items-center'>
                        <Card className='p-1.5 rounded-2xl bg-indigo-50/50 border-indigo-200/50'>
                            <TbUserCircle className='h-14 w-14 stroke-1.5 text-indigo-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Konselor Baru
                            </Headline3>
                            <Text className='text-center'>
                                Tambahkan konselor baru untuk mengisi sesi konsultasi bersama alumni
                            </Text>
                        </Column>
                    </Column>
                    {listSelected.length > 0 && <Column className='pt-3 pb-5 gap-1.5'>
                        <Caption className='px-5 text-gray-800 font-medium'>
                            {listSelected.length} Konselor terpilih:
                        </Caption>
                        <Row className='gap-2 overflow-x-auto scrollbar-hide px-5'>
                            {listSelected.map((selected, idx) => {
                                return (
                                    <Row className='w-fit gap-2 shrink-0 bg-gray-100/50 border border-gray-200 pl-2.5 pr-2 py-1.5 rounded-lg'>
                                        <Caption key={idx} className='shrink-0 text-gray-800'>
                                            {selected.nama}
                                        </Caption>
                                        <IconButton onClick={() => chooseKonselor(selected)} className='p-0.5 my-auto' color='red'>
                                            <TbX className='h-3 w-3' />
                                        </IconButton>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>}
                    <Column className='px-5 py-6 gap-4'>
                        <OutlineInput id='query' inputWidth='w-full' placeholder='Cari nik atau nama' onChange={onChangeInput} />
                        {listSearch.length > 0 ? <Card className='w-full p-0 overflow-hidden'>
                            <Column className='max-h-72 divide-y overflow-y-auto scrollbar-hide'>
                                {listSearch.map((konselor, idx) => {
                                    return (
                                        <CAdmKonselor key={idx} konselor={konselor} listSelected={listSelected} onClick={() => chooseKonselor(konselor)} />
                                    )
                                })}
                            </Column>
                        </Card> : <Column className='items-center gap-2'>
                            <TbMoodCry className='h-12 w-12 stroke-1.5 text-gray-500' />
                            <Caption className='text-center'>
                                Hasil pencarian tidak ditemukan
                            </Caption>
                        </Column>}
                    </Column>
                    <GridCol className='gap-0 divide-x divide-gray-200'>
                        <TextButton onClick={() => { setShowDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                            Batal
                        </TextButton>
                        <TextButton disabled={listSelected.length > 0 ? false : true} onClick={saveKonselor} color='indigo' className='w-full py-4 text-base hover:bg-indigo-50/50'>
                            Simpan
                        </TextButton>
                    </GridCol>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loading} message='Menyimpan konselor...' />
        </>
    )
}

export default TablePlotingKonselor
