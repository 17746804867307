import moment from 'moment'
import React from 'react'
import { TbBriefcase, TbProgress, TbUserCircle } from 'react-icons/tb'
import { Column, GridCol, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { BodyText, CaptionText, Headline2Text, LargeHeadlineText, TitleText } from '../../../components/interfaces/text'
import { getViewDisplay } from '../../../shared/lib'
import { AreaChart, DotSeparated } from '../../../shared/ui'

function AdmOverviewCareerPage() {

  const dateNow = Date.now()

  const listColors = ['#818DF8', '#4ADE80', '#FB923C']

  const listOverview = [
    {
      icon: <TbBriefcase className={'h-6 w-6 text-indigo-500'} />,
      label: 'Total Lowongan',
      value: 34,
    },
    {
      icon: <TbUserCircle className={'h-6 w-6 text-green-500'} />,
      label: 'Total Pelamar',
      value: 15,
    },
    {
      icon: <TbProgress className={'h-6 w-6 text-orange-500'} />,
      label: 'Menunggu Verifikasi',
      value: 4,
    },
  ]

  const listSeries = [
    {
      name: 'Lowongan',
      data: [
        [dateNow, 4],
        [moment(dateNow).add(1, 'days'), 5],
        [moment(dateNow).add(2, 'days'), 5],
        [moment(dateNow).add(3, 'days'), 3],
        [moment(dateNow).add(4, 'days'), 1],
        [moment(dateNow).add(5, 'days'), 0],
        [moment(dateNow).add(6, 'days'), 2],
        [moment(dateNow).add(7, 'days'), 0],
        [moment(dateNow).add(8, 'days'), 1],
        [moment(dateNow).add(9, 'days'), 1],
        [moment(dateNow).add(10, 'days'), 3],
      ]
    },
    {
      name: 'Pelamar',
      data: [
        [dateNow, 4],
        [moment(dateNow).add(1, 'days'), 0],
        [moment(dateNow).add(2, 'days'), 1],
        [moment(dateNow).add(3, 'days'), 0],
        [moment(dateNow).add(4, 'days'), 3],
        [moment(dateNow).add(5, 'days'), 2],
        [moment(dateNow).add(6, 'days'), 0],
        [moment(dateNow).add(7, 'days'), 1],
        [moment(dateNow).add(8, 'days'), 0],
        [moment(dateNow).add(9, 'days'), 1],
        [moment(dateNow).add(10, 'days'), 1],
      ]
    },
    {
      name: 'Menunggu Verifikasi',
      data: [
        [dateNow, 15],
        [moment(dateNow).add(1, 'days'), 14],
        [moment(dateNow).add(2, 'days'), 8],
        [moment(dateNow).add(3, 'days'), 2],
        [moment(dateNow).add(4, 'days'), 5],
        [moment(dateNow).add(5, 'days'), 9],
        [moment(dateNow).add(6, 'days'), 17],
        [moment(dateNow).add(7, 'days'), 4],
        [moment(dateNow).add(8, 'days'), 9],
        [moment(dateNow).add(9, 'days'), 6],
        [moment(dateNow).add(10, 'days'), 4],
      ]
    }
  ]

  return (
    <Column className={'p-4 gap-y-5 sm:p-8'}>
      <Headline2Text className={'font-semibold'}>
        Ringkasan Karir
      </Headline2Text>
      <GridCol className='grid-cols-1 gap-x-5 gap-y-5 sm:grid-cols-2 xl:grid-cols-3'>
        {
          listOverview.map((overview, idx) => {
            return (
              <Card
                key={idx}
                className={'w-full sm:p-6'}>
                <Column className={'gap-y-5'}>
                  <Row className={'gap-x-2 justify-between'}>
                    <BodyText className={'font-medium text-gray-500'}>
                      {overview.label}
                    </BodyText>
                    {overview.icon}
                  </Row>
                  <LargeHeadlineText className='font-normal font-inter'>
                    {getViewDisplay(overview.value)}
                  </LargeHeadlineText>
                </Column>
              </Card>
            )
          })
        }
      </GridCol>
      <Card className={'w-full p-0'}>
        <Column className={'gap-y-2'}>
          <Column className={'p-4 gap-y-1 sm:p-6'}>
            <TitleText>
              Aktivitas Karir
            </TitleText>
            <Row>
              {listSeries.map((series, idx) => {
                return <Row key={idx} className='w-fit'>
                  <CaptionText>
                    {series.name}
                  </CaptionText>
                  {idx < (listSeries.length - 1) && <DotSeparated />}
                </Row>
              })}
            </Row>
          </Column>
          <div className={'px-3 pb-6'}>
            <AreaChart
              height={300}
              type='datetime'
              lineWidth={2.3}
              series={listSeries}
              listColors={listColors} />
          </div>
        </Column>
      </Card>
    </Column>
  )
}

export default AdmOverviewCareerPage