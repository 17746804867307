import React from 'react'
import { Card, Column, DotSeparated, Row, ShimmerAnimation, ShimmerItem } from '../../shared/ui'

const SChartOvTracer = ({ action = 10 }) => {
    return (
        <Card className='w-full'>
            <ShimmerAnimation>
                <Column className='gap-6'>
                    <Row className='justify-between'>
                        <Column className='gap-2'>
                            <ShimmerItem className='h-6 w-44' />
                            {action === 10 ? <ShimmerItem className='h-4 w-24' /> : <Row>
                                <ShimmerItem className='h-4 w-24' />
                                <DotSeparated />
                                <ShimmerItem className='h-4 w-24' />
                            </Row>}
                        </Column>
                        <ShimmerItem className='h-9 w-24 rounded-lg' />
                    </Row>
                    <ShimmerItem className='h-52 w-full rounded-lg' />
                </Column>
            </ShimmerAnimation>
        </Card>
    )
}

export default SChartOvTracer
