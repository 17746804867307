import React from 'react'
import { TbArrowRight, TbArrowUpRight, TbProgress } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { ButtonHover, IconButton, Image, TagView } from '../../../../../shared/ui'

function RowTableProcessedMitra({ idx, mitra, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow
      striped
      onClick={() => handleClick(mitra)}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <Image
          src={mitra.logo}
          className='aspect-square max-w-[3rem]'
          imgClasName='aspect-square object-scale-down mix-blend-multiply'
          hoverScale={false}
          removeBgColor />
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {mitra.nama_mitra}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='line-clamp-2'>
          {mitra.deskripsi}
        </BodyText>
      </TableCell>
      <TableCell>
        <ButtonHover>
          <a
            onClick={(event) => event.stopPropagation()}
            className='flex flex-row items-center gap-x-0.5 tertiary-btn py-1 pl-2.5 pr-4 rounded-full hover:border-blue-500'
            href={mitra.legalitas}
            target='_blank'
            rel='noreferrer'>
            <TbArrowUpRight className='h-5 w-5' /> Buka
          </a>
        </ButtonHover>
      </TableCell>
      <TableCell>
        <TagView
          className={'mx-auto'}
          leadingIcon={
            <TbProgress className={'h-4 w-4'} />
          }
          label='Menunggu'
          color='orange' />
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            className='p-1.5'
            color='indigo'
            bordered
            onClick={() => handleClick(mitra)}>
            <TbArrowRight className='h-5 w-5' />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableProcessedMitra