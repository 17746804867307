import React from 'react'
import { BiImages } from 'react-icons/bi'
import { CgSpinner } from 'react-icons/cg'
import { Caption, Card, GridCol, Image, Row } from '../shared/ui'

const WGridImageRecognition = ({ images = [], aspectRatio, action = 10 }) => {
    return (
        <div className='relative'>
            <GridCol className='rounded-xl overflow-hidden gap-1 border border-gray-200'>
                {Array.from({ length: images.length <= 4 ? images.length : 4 }, (_, idx) => {
                    return (
                        <div key={idx} className={`${(images.length === 3 || images.length === 1) && idx === 0 && 'col-span-2'} bg-slate-50`}>
                            {!images[idx].loading ? <Image src={action === 10 ? URL.createObjectURL(images[idx].file) : images[idx].image_name} imgClasName={`w-full ${aspectRatio}`} hoverScale={false} /> :
                                <Card className={`w-full ${aspectRatio} bg-slate-50 border-none`}>
                                    <CgSpinner className='h-7 w-7 my-auto mx-auto animate-spin text-indigo-700' />
                                </Card>}
                        </div>
                    )
                })}
            </GridCol>
            {images.length > 4 && <Row className='absolute w-fit right-2 bottom-2 bg-black/60 gap-1.5 backdrop-blur-xl pl-2 pr-3 py-1 rounded-lg'>
                <BiImages className='h-4 w-4 text-white my-auto' />
                <Caption className='text-white'>
                    {images.length - 4} lainnya
                </Caption>
            </Row>}
        </div>
    )
}

export default WGridImageRecognition
