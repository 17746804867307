import { createContext, useEffect, useState } from "react";
import { setupMonth, setupTahun } from "../../shared/ta";
import { postSurveiController } from "../../controllers/global_controllers";
import { getMasterProdiController } from "../../controllers/admin_controllers";

export const KuesionerContext = createContext();

export const KuesionerProvider = ({ children }) => {
  const [selectedEtika, setSelectedEtika] = useState("");
  const [selectedKeahlian, setSelectedKeahlian] = useState("");
  const [selectedBahasa, setSelectedBahasa] = useState("");
  const [selectedInformasi, setSelectedInformasi] = useState("");
  const [selectedKomunikasi, setSelectedKomunikasi] = useState("");
  const [selectedKerjasama, setSelectedKerjasama] = useState("");
  const [selectedPengembangan, setSelectedPengembangan] = useState("");
  const [selectedKesiapan, setSelectedKesiapan] = useState("");
  const [optionProdi, setOptionProdi] = useState([]);
  const [optionTahun, setOptionTahun] = useState([]);
  const [optionBulan, setOptionBulan] = useState([]);
  const [initProdi, setInitProdi] = useState({
    label: "Pilih Program Studi",
    value: null,
  });
  const [initTahun, setInitTahun] = useState({
    label: "Pilih tahun",
    value: null,
  });
  const [initBulan, setInitBulan] = useState({
    label: "Pilih bulan",
    value: null,
  });
  const [step, setStep] = useState(1);
  const [showDialogInput, setShowDialogInput] = useState(false);
  const [showDialogSubmitFailed, setShowDialogSubmitFailed] = useState(false);
  const [showDialogSubmitSuccess, setShowDialogSubmitSuccess] = useState(false);
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [captcaInput, setCaptchaInput] = useState("");
  const [captchaMessage, setCaptchaMessage] = useState("");
  const [formData, setFormData] = useState({
    pengisi_nama: "",
    pengisi_perusahaan: "",
    pengisi_alamat: "",
    pengisi_jabatan: "",
    pengisi_email: "",
    mhs_nama: "",
    mhs_prodi: "",
    mhs_kerja: "",
    mhs_jabatan: "",
    s_etika: "",
    s_keahlian: "",
    s_asing: "",
    s_teknologi: "",
    s_komunikasi: "",
    s_kerjasama: "",
    s_pengembangan: "",
    s_terjun: "",
    m_keunggulan: "",
    m_kelemahan: "",
    m_dikuasai: "",
    m_saran: "",
  });

  const closeDialogInput = () => {
    setShowDialogInput();
  };

  const closeDialogSubmitFailed = () => {
    setShowDialogSubmitFailed();
  };

  const closeDialogSubmitSuccess = () => {
    setShowDialogSubmitSuccess();
  };

  const regenerateCaptca = () => {
    setNum1(Math.floor(Math.random() * 10));
    setNum2(Math.floor(Math.random() * 10));
    setCaptchaInput("");
    setCaptchaMessage("");
  };

  const option = [
    { label: "Sangat Baik", value: "sangat_baik" },
    { label: "Baik", value: "baik" },
    { label: "Cukup", value: "cukup" },
    { label: "Kurang", value: "kurang" },
  ];

  const handleSelectChange = (e, fieldName) => {
    if (fieldName === "mhs_prodi") {
      setInitProdi(e);
      setFormData({
        ...formData,
        mhs_prodi: e.value.toString(),
      });
    } else if (fieldName === "mhs_kerja_bulan") {
      setInitBulan(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: e.label + " " + (initTahun.value || ""),
      }));
    } else if (fieldName === "mhs_kerja_tahun") {
      setInitTahun(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: (initBulan.label || "") + "" + e.value,
      }));
    }
  };

  const handleRadioChange = (value, radioName) => {
    if (radioName === "s_etika") {
      setSelectedEtika(value);
      setFormData({ ...formData, s_etika: value });
    } else if (radioName === "s_keahlian") {
      setSelectedKeahlian(value);
      setFormData({ ...formData, s_keahlian: value });
    } else if (radioName === "s_asing") {
      setSelectedBahasa(value);
      setFormData({ ...formData, s_asing: value });
    } else if (radioName === "s_teknologi") {
      setSelectedInformasi(value);
      setFormData({ ...formData, s_teknologi: value });
    } else if (radioName === "s_komunikasi") {
      setSelectedKomunikasi(value);
      setFormData({ ...formData, s_komunikasi: value });
    } else if (radioName === "s_kerjasama") {
      setSelectedKerjasama(value);
      setFormData({ ...formData, s_kerjasama: value });
    } else if (radioName === "s_pengembangan") {
      setSelectedPengembangan(value);
      setFormData({ ...formData, s_pengembangan: value });
    } else if (radioName === "s_terjun") {
      setSelectedKesiapan(value);
      setFormData({ ...formData, s_terjun: value });
    }
  };

  const getDataProdi = async () => {
    try {
      const dataProdi = await getMasterProdiController();
      const formattedOptions = dataProdi.data.map((prodi) => ({
        label: `${prodi.jenjang} - ${prodi.prodi}`,
        value: prodi.kode_prodi,
      }));
      setOptionProdi(formattedOptions);
    } catch (error) {
      console.error("error get data prodi", error.message);
    }
  };

  useEffect(() => {
    getDataProdi();
    setOptionTahun(setupTahun({ start: 2003 }));
    setOptionBulan(setupMonth({ start: "Januari" }));
  }, []);

  const nextStep = () => {
    if (step === 2) {
      if (
        !formData.pengisi_nama ||
        !formData.pengisi_perusahaan ||
        !formData.pengisi_alamat ||
        !formData.pengisi_jabatan ||
        !formData.pengisi_email
      ) {
        setShowDialogInput(true);
        return;
      }
    }

    if (step === 3) {
      if (!formData.mhs_nama || !formData.mhs_jabatan) {
        setShowDialogInput(true);
        return;
      }
    }

    if (step === 4) {
      if (
        !formData.s_etika ||
        !formData.s_keahlian ||
        !formData.s_asing ||
        !formData.s_teknologi ||
        !formData.s_komunikasi ||
        !formData.s_kerjasama ||
        !formData.s_pengembangan ||
        !formData.s_terjun
      ) {
        setShowDialogInput(true);
        return;
      }
    }

    if (step === 5) {
      if (
        !formData.m_keunggulan ||
        !formData.m_kelemahan ||
        !formData.m_dikuasai ||
        !formData.m_saran
      ) {
        setShowDialogInput(true);
        return;
      }
    }

    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const backSubmit = () => {
    setStep(2);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [message, setMessage] = useState(null);

  const submitCaptca = (event) => {
    event.preventDefault();

    if (parseInt(captcaInput) !== num1 + num2) {
      setCaptchaMessage();
      console.log("captcha salah");
      // regenerateCaptca();
      return;
    }

    console.log("Form submitted successfully!");
  };

  const [showDialogCaptcha, setShowDialogCaptca] = useState(false);

  const closeDialogCaptcha = () => {
    setShowDialogCaptca();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await postSurveiController(formData);

      if (parseInt(captcaInput) !== num1 + num2) {
        setShowDialogCaptca(true);
        regenerateCaptca();
        return;
      }

      if (!result.status) {
        setMessage(result.message);
        setShowDialogSubmitFailed(true);
        return;
      }

      setMessage(result.message);
      setShowDialogSubmitSuccess(true);

      setFormData({
        pengisi_nama: "",
        pengisi_perusahaan: "",
        pengisi_alamat: "",
        pengisi_jabatan: "",
        pengisi_email: "",
        mhs_nama: "",
        mhs_prodi: "",
        mhs_kerja: "",
        mhs_jabatan: "",
        s_etika: setSelectedEtika(),
        s_keahlian: setSelectedKeahlian(),
        s_asing: setSelectedBahasa(),
        s_teknologi: setSelectedInformasi(),
        s_komunikasi: setSelectedKomunikasi(),
        s_kerjasama: setSelectedKerjasama(),
        s_pengembangan: setSelectedPengembangan(),
        s_terjun: setSelectedKeahlian(),
        m_keunggulan: "",
        m_kelemahan: "",
        m_dikuasai: "",
        m_saran: "",
      });

      setTimeout(() => {
        setStep(1);
        setShowDialogSubmitSuccess();
      }, 5000);
    } catch (error) {
      console.error("error add data", error.message);
    }
  };
  return (
    <KuesionerContext.Provider
      value={{
        selectedEtika,
        selectedKeahlian,
        selectedBahasa,
        selectedInformasi,
        selectedKomunikasi,
        selectedKerjasama,
        selectedPengembangan,
        selectedKesiapan,
        optionProdi,
        optionTahun,
        optionBulan,
        initProdi,
        initTahun,
        initBulan,
        step,
        formData,
        option,
        handleSelectChange,
        handleRadioChange,
        nextStep,
        previousStep,
        handleInputChange,
        handleSubmit,
        showDialogInput,
        closeDialogInput,
        showDialogSubmitFailed,
        showDialogSubmitSuccess,
        closeDialogSubmitFailed,
        closeDialogSubmitSuccess,
        message,
        backSubmit,
        num1,
        num2,
        captcaInput,
        setCaptchaInput,
        showDialogCaptcha,
        closeDialogCaptcha,
      }}
    >
      {children}
    </KuesionerContext.Provider>
  );
};
