import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { dateRangeKuesionerTracer, rekapKuesionerTracer } from '../../atoms'
import { getRekapKuesionerTracerController } from '../../controllers/apicontrollers'
import { ButtonHover, Card, Column, HorizontalDivider, OutlineInput, Row, SimpleAlert, Table, TableBody, TableCell, TableHead, TableRow, TertiaryButton, Text, Title } from '../../shared/ui'
import moment from 'moment'
import { TbArrowRight, TbDownload } from 'react-icons/tb'
import RowTableRekapKuesionerTracer from './rows/row_rekap_kuesioner_tracer'

const TableRekapKuesionerTracer = ({ onDoneLoadData, action = 10 }) => {

    const [listKuesioner, setListKuesioner] = useRecoilState(rekapKuesionerTracer)
    const [searchQuery, setSearchQuery] = useState(null)
    const [sortTable, setShortTable] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [dateRange, setDateRange] = useRecoilState(dateRangeKuesionerTracer)

    const getRekapKuesionerTracer = async (start, end) => {
        const props = {
            action: action,
            limit: limitRow,
            page: currentPage,
            search: searchQuery,
            order: sortTable !== null ? sortTable.key : null,
            asc: sortTable !== null ? sortTable.type : null,
            start: start,
            end: end
        }
        const result = await getRekapKuesionerTracerController(props)
        if (result.status) {
            setListKuesioner(result.data)
            setTotalRow(result.totalRows)
            setTotalPage(result.totalPage)
        }
        onDoneLoadData(true)
    }

    const onChangeShowNumber = (value) => {
        setCurrentPage(1)
        setLimitRow(value)
    }

    const onChangeQuerySearch = (value) => {
        setCurrentPage(1)
        setSearchQuery(value)
    }

    const onChangePageNumber = (value) => {
        setCurrentPage(value)
    }

    const onShortableValue = (value) => {
        setCurrentPage(1)
        setShortTable(value)
    }

    const onChangeInput = (result) => {
        if (result['action'] === 10) {
            setDateRange({
                start: result['value'],
                end: dateRange.end,
            })
            validateDate(result['value'], dateRange.end)
        } else {
            setDateRange({
                start: dateRange.start,
                end: result['value'],
            })
            validateDate(dateRange.start, result['value'])
        }
    }

    const validateDate = (start, end) => {
        if (start.length > 0 && end.length > 0) {
            if (new Date(end).getTime() >= new Date(start).getTime()) {
                getRekapKuesionerTracer(start, end)
            }
        }
    }

    useEffect(() => {
        getRekapKuesionerTracer(dateRange.start, dateRange.end)
    }, [searchQuery, sortTable, limitRow, currentPage])

    return (
        <Card className='w-full p-0'>
            <Column>
                <Row className='flex-col sm:flex-row gap-4 px-5 py-4 justify-between'>
                    <Column className='gap-0.5 my-auto'>
                        <Title>
                            Data Kuesioner
                        </Title>
                        <Row className='flex-wrap gap-y-1 gap-x-2'>
                            <Text>
                                {moment(dateRange.start).format('DD MMM YYYY')}
                            </Text>
                            <TbArrowRight className='h-4 w-4 my-auto text-gray-500' />
                            <Text>
                                {moment(dateRange.end).format('DD MMM YYYY')}
                            </Text>
                        </Row>
                    </Column>
                    <Row className='w-fit gap-3 my-auto'>
                        <OutlineInput
                            action={10}
                            id='start'
                            type='date'
                            maxDate={dateRange.end}
                            className='appearance-none h-[2.55rem] px-3'
                            bgColor='bg-slate-50'
                            placeholder='dd/mm/yyyy'
                            onChange={onChangeInput}
                            defaultValue={dateRange.start}
                            required />
                        <TbArrowRight className='h-5 w-5 my-auto text-gray-500' />
                        <OutlineInput
                            action={20}
                            id='end'
                            type='date'
                            minDate={dateRange.start}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            className='appearance-none h-[2.55rem] px-3'
                            bgColor='bg-slate-50'
                            placeholder='dd/mm/yyyy'
                            onChange={onChangeInput}
                            defaultValue={dateRange.end}
                            required />
                        {/* <ButtonHover className='my-auto'>
                            <TertiaryButton color='indigo' className='py-2 pl-3 pr-4' leadingIcon={<TbDownload className='h-5 w-5 my-auto' />}>
                                Unduh
                            </TertiaryButton>
                        </ButtonHover> */}
                    </Row>
                </Row>
                <SimpleAlert type='warning' className='rounded-none' borderLeft showCloseBtn>
                    Menampilkan daftar alumni yang sudah mengisi Tracer Studi pada rentang tanggal {moment(dateRange.start).format('DD MMM YYYY')} s.d {moment(dateRange.end).format('DD MMM YYYY')}.
                </SimpleAlert>
                <HorizontalDivider className='mt-0 mb-0' />
                <div className='p-5'>
                    <Table
                        className='border-[1px] border-gray-200'
                        limitRow={limitRow}
                        totalRow={totalRow}
                        totalPage={totalPage}
                        trigerPagination={sortTable}
                        onChangeShowNumber={onChangeShowNumber}
                        onChangeQuerySearch={onChangeQuerySearch}
                        onChangePageNumber={onChangePageNumber}>
                        <TableHead>
                            <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                <TableCell className='font-semibold font-manrope w-[5%]'>
                                    No.
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='nim' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    NPM
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='nama' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Nama Lengkap
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='nama_jenjang' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Jenjang
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='nama_jurusan' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Program Studi
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='created' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Tanggal Isi
                                </TableCell>
                                <TableCell className='font-semibold font-manrope' sortable sortkey='tgllulus' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Tanggal Lulus
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='divide-y-[1px] divide-gray-200'>
                            {listKuesioner.length > 0 ? listKuesioner.map((kuesioner, idx) => {
                                return (
                                    <RowTableRekapKuesionerTracer
                                        key={idx}
                                        idx={idx}
                                        kuesioner={kuesioner}
                                        currentPage={currentPage}
                                        limitRow={limitRow} />
                                )
                            }) : <TableRow>
                                <TableCell colSpan={7} className='text-center text-gray-500'>
                                    No data to display
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
            </Column>
        </Card>
    )
}

export default TableRekapKuesionerTracer
