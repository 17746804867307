import { useEffect, useRef, useState } from "react";
import { TbAlertTriangle, TbBrandWhatsapp } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import AlmFooter from "../components/alm_footer";
import HeroLandingMobile from "../components/hero/mobile/hero_landing";
import HeroLandingWeb from "../components/hero/web/hero_landing";
import ListEventLanding from "../components/list/list_event_landing";
import ListRecognitionLanding from "../components/list/list_recognition_landing";
import {
  AlertDialog,
  ButtonGradient,
  ButtonHover,
  Card,
  Column,
  Headline1,
  Headline3,
  LargeHeadline,
  PrimaryButton,
  Row,
  TertiaryButton,
  Text,
  TextButton,
} from "../shared/ui";
import { eventRoute, recognitionRoute } from "../shared/variable";
import axios from "axios";

function LandingPage() {
  const mitraRef = useRef(null);
  const navigateTo = useNavigate();
  const [showProgress, setShowProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(true);

  const onClickMitra = () => {
    mitraRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const videoId = "qKo0Jlao5ec";
  // useEffect(() => {
  //   const fetchVideoTitle = async () => {
  //     try {
  //       const API_KEY = "AIzaSyD_ZshZw7_x6qD14WsvBAOXelDOIjwxpMA";
  //       const response = await axios.get(
  //         `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet`
  //       );

  //       setTitle(response.data.items[0]?.snippet?.title || "No Title Found");
  //       setDescription(
  //         response.data.items[0]?.snippet?.description || "No Title Found"
  //       );
  //     } catch (error) {
  //       console.error("Error fetching video title:", error);
  //       setTitle("Failed to load title");
  //     }
  //   };

  //   fetchVideoTitle();
  // }, [videoId]);

  return (
    <>
      <Column className="gap-y-4">
        <Column>
          <HeroLandingMobile onClickMitra={onClickMitra} />
          <HeroLandingWeb onClickMitra={onClickMitra} />
        </Column>
        <Column className="px-4 py-16 sm:py-28 bg-white">
          <Column className="max-w-7xl mx-auto gap-5 sm:gap-8">
            <Headline1 className="sm:hidden font-semibold">
              Berita Alumni
            </Headline1>
            <Row className="hidden sm:flex justify-between">
              <LargeHeadline className="font-semibold">
                Berita Alumni
              </LargeHeadline>
              <ButtonHover>
                <TertiaryButton
                  onClick={() => navigateTo(recognitionRoute)}
                  color="indigo"
                  className="px-8 py-3.5 rounded-full"
                >
                  Berita Lainnya
                </TertiaryButton>
              </ButtonHover>
            </Row>
            <ListRecognitionLanding />
            <ButtonHover className="sm:hidden">
              <TertiaryButton
                onClick={() => navigateTo(recognitionRoute)}
                color="indigo"
                className="px-8 py-3.5 rounded-full"
              >
                Berita Lainnya
              </TertiaryButton>
            </ButtonHover>
          </Column>
        </Column>
        <Column className="px-4 py-16 sm:py-28 bg-white">
          <Column className="max-w-7xl mx-auto gap-5 sm:gap-8">
            <Headline1 className="sm:hidden font-semibold">Event</Headline1>
            <Row className="hidden sm:flex justify-between">
              <LargeHeadline className="font-semibold">Event</LargeHeadline>
              <ButtonHover>
                <TertiaryButton
                  onClick={() => navigateTo(eventRoute)}
                  color="indigo"
                  className="px-8 py-3.5 rounded-full"
                >
                  Event Lainnya
                </TertiaryButton>
              </ButtonHover>
            </Row>
            <ListEventLanding />
            <ButtonHover className="sm:hidden">
              <TertiaryButton
                onClick={() => navigateTo(eventRoute)}
                color="indigo"
                className="px-8 py-3.5 rounded-full"
              >
                Event Lainnya
              </TertiaryButton>
            </ButtonHover>
          </Column>
        </Column>
        {/* <Column>
                    <GridCol className='auto-rows-fr gap-x-4 gap-y-4'>
                        <Card className='w-full h-full flex-1 px-16 py-14 rounded-none border-none'>
                            <Column className='h-full items-center gap-y-14'>
                                <Column className='items-center gap-y-8'>
                                    <Card className='rounded-[1.3rem] p-2.5 bg-green-50 border-green-300'>
                                        <TbBriefcase className='h-14 w-14 stroke-1.5 text-green-600' />
                                    </Card>
                                    <Column className='items-center gap-y-5'>
                                        <LargeHeadline className='text-center font-semibold'>
                                            Lowongan Kerja
                                        </LargeHeadline>
                                        <Title className='font-normal font-inter text-center text-slate-700'>
                                            Berbagai informasi lowongan terpilih kami bagikan kepada Alumni Universitas Teknologi Yogyakarta. Semua Alumni Universitas Teknologi Yogyakarta akan mendapatkan Fasilitas Melamar Pekerjaan secara langsung ke Perusahaan Mitra UTY.
                                        </Title>
                                    </Column>
                                </Column>
                                <ButtonHover className='mt-auto'>
                                    <TertiaryButton onClick={() => navigateTo(careerRoute)} color='green' leadingIcon={<TbSearch className='h-5 w-5 my-auto' />} className='pl-6 pr-7'>
                                        Cari Lowongan
                                    </TertiaryButton>
                                </ButtonHover>
                            </Column>
                        </Card>
                        <Card className='w-full h-full flex-1 px-16 py-14 rounded-none border-none'>
                            <Column className='h-full items-center gap-y-14'>
                                <Column className='items-center gap-y-8'>
                                    <Card className='rounded-[1.3rem] p-2.5 bg-indigo-50 border-indigo-300'>
                                        <TbMessageCircle className='h-14 w-14 stroke-1.5 text-indigo-600' />
                                    </Card>
                                    <Column className='items-center gap-y-5'>
                                        <LargeHeadline className='text-center font-semibold'>
                                            Konsultasi Karier
                                        </LargeHeadline>
                                        <Title className='font-normal font-inter text-center text-slate-700'>
                                            Universitas Teknologi Yogyakarta berkomitmen untuk mengantarkan alumninya sukses dalam dunia karier yang mereka pilih. Selain karier, kami juga memfasilitasi alumni untuk berkonsultasi mengenai studi lanjut dan wirausaha dengan dukungan konselor pilihan.
                                        </Title>
                                    </Column>
                                </Column>
                                <ButtonHover className='mt-auto'>
                                    <TertiaryButton onClick={() => navigateTo(almConsultationRoute)} color='indigo' leadingIcon={<TbMessageCircle className='h-5 w-5 my-auto' />} className='pl-6 pr-7'>
                                        Mulai Konsultasi
                                    </TertiaryButton>
                                </ButtonHover>
                            </Column>
                        </Card>
                    </GridCol>
                </Column>
                <Column>
                    <GridCol className='auto-rows-fr gap-x-4 gap-y-4'>
                        <Card className='w-full h-full flex-1 px-16 py-14 rounded-none border-none'>
                            <Column className='h-full items-center gap-y-14'>
                                <Column className='items-center gap-y-8'>
                                    <Card className='rounded-[1.3rem] p-2.5 bg-orange-50 border-orange-300'>
                                        <TbReport className='h-14 w-14 stroke-1.5 text-orange-600' />
                                    </Card>
                                    <Column className='items-center gap-y-5'>
                                        <LargeHeadline className='text-center font-semibold'>
                                            Tracer Study
                                        </LargeHeadline>
                                        <Title className='font-normal font-inter text-center text-slate-700'>
                                            Guna mengetahui kontribusi yang diberikan oleh alumni UTY bagi bangsa dan negera serta memetakan daya saing alumni dalam dunia kerja dan industri, diperlukan penelusuran alumni setelah lulus secara komprehensif, terstruktur, dan berkesinambungan melalui Tracer Study.
                                        </Title>
                                    </Column>
                                </Column>
                                <ButtonHover className='mt-auto'>
                                    <TertiaryButton onClick={() => navigateTo(almTracerRoute)} color='orange' leadingIcon={<TbPencil className='h-5 w-5 my-auto' />} className='pl-6 pr-7'>
                                        Isi Tracer Study
                                    </TertiaryButton>
                                </ButtonHover>
                            </Column>
                        </Card>
                        <Card className='w-full h-full flex-1 px-16 py-14 rounded-none border-none'>
                            <Column className='h-full items-center gap-y-14'>
                                <Column className='items-center gap-y-8'>
                                    <Card className='rounded-[1.3rem] p-2.5 bg-pink-50 border-pink-300'>
                                        <TbHeartHandshake className='h-14 w-14 stroke-1.5 text-pink-600 translate-y-0.5' />
                                    </Card>
                                    <Column className='items-center gap-y-5'>
                                        <LargeHeadline className='text-center font-semibold'>
                                            Business to Business
                                        </LargeHeadline>
                                        <Title className='font-normal font-inter text-center text-slate-700'>
                                            Business to Business dimaksudkan untuk memfasilitasi sesama Alumni UTY dalam mengembangkan bisnis satu sama lain. B2B berperan sebagai “Narahubung” antara Alumni yang membutuhkan informasi produk/jasa dengan	 Alumni yang dapat memenuhi kebutuhan bisnis tersebut.
                                        </Title>
                                    </Column>
                                </Column>
                                <ButtonHover className='mt-auto'>
                                    <TertiaryButton onClick={() => navigateTo(almBusinessRoute)} color='pink' leadingIcon={<TbHeartHandshake className='h-5 w-5 my-auto' />} className='pl-6 pr-7'>
                                        Mulai Business
                                    </TertiaryButton>
                                </ButtonHover>
                            </Column>
                        </Card>
                    </GridCol>
                </Column>
                <Column refId={mitraRef}>
                    <GridCol className='auto-rows-fr grid-cols-1 gap-x-4 gap-y-4'>
                        <Card className='w-full h-full flex-1 px-16 py-14 rounded-none border-none'>
                            <Column className='h-full items-center gap-y-14'>
                                <Column className='items-center gap-y-8'>
                                    <Card className='rounded-[1.3rem] p-2.5 bg-purple-50 border-purple-300'>
                                        <TbBuildingCommunity className='h-14 w-14 stroke-1.5 text-purple-600' />
                                    </Card>
                                    <Column className='items-center gap-y-5 max-w-4xl'>
                                        <LargeHeadline className='text-center font-semibold'>
                                            Kemitraan UTY
                                        </LargeHeadline>
                                        <Title className='font-normal font-inter text-center text-slate-700'>
                                            Kemitraan Perusahaan dengan Kampus UTY bertujuan untuk memberikan akses eksklusif ke informasi lowongan pekerjaan terkini yang dibuka oleh Perusahaan Mitra. Informasi lowongan dapat mencakup pekerjaan penuh waktu, paruh waktu, proyek khusus dan lainnya. Alumni dapat menjelajahi dan melamar pekerjaan yang sesuai dengan minat dan kualifikasi.
                                        </Title>
                                    </Column>
                                </Column>
                                <GridCol className='w-full max-w-5xl gap-x-8'>
                                    <Card className='w-full p-8 bg-purple-50'>
                                        <Column className='gap-y-8'>
                                            <Column className='gap-y-2'>
                                                <Title>
                                                    Dashboard Mitra
                                                </Title>
                                                <Text>
                                                    Perusahaan yang sudah bermitra dengan Universitas Teknologi Yogyakarta dapat masuk ke Dashboard Mitra dengan klik tombol Masuk Dashboard.
                                                </Text>
                                            </Column>
                                            <ButtonHover>
                                                <PrimaryButton onClick={() => navigateTo(loginRoute, { state: { action: 20 } })} leadingIcon={<TbLogin className='h-5 w-5 my-auto' />} color='purple' className='pl-6 pr-7'>
                                                    Masuk Dashboard
                                                </PrimaryButton>
                                            </ButtonHover>
                                        </Column>
                                    </Card>
                                    <Card className='w-full p-8 bg-gray-50'>
                                        <Column className='gap-y-8'>
                                            <Column className='gap-y-2'>
                                                <Title>
                                                    Mendaftar Mitra UTY
                                                </Title>
                                                <Text>
                                                    Perusahaan Anda belum bermitra dengan Universitas Teknologi Yogyakarta? Yuk daftarkan perusahaan Anda sebagai Mitra UTY dengan klik tombol Daftar Mitra.
                                                </Text>
                                            </Column>
                                            <ButtonHover>
                                                <TertiaryButton onClick={() => navigateTo(registerMitraRoute)} leadingIcon={<TbClipboardText className='h-5 w-5 my-auto' />} color='purple' className='pl-6 pr-7'>
                                                    Daftar Mitra
                                                </TertiaryButton>
                                            </ButtonHover>
                                        </Column>
                                    </Card>
                                </GridCol>
                            </Column>
                        </Card>
                    </GridCol>
                </Column> */}

        <AlmFooter />
      </Column>
      <a
        href="whatsapp://send/?phone=628561236465&text=Halo&type=phone_number&app_absent=0"
        target="_blank"
        rel="noreferrer"
        className="fixed bottom-5 right-5 z-10 transition-all duration-200 cursor-pointer rounded-full bg-green-500 text-white p-3 hover:bg-green-600 hover:-translate-y-1"
      >
        <TbBrandWhatsapp className="h-8 w-8 stroke-1.5" />
      </a>
      {/* <AlertDialog
        className="max-w-3xl"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <Column className="divide-y divide-gray-200">
          <Column className="px-5 py-5 items-center gap-8">
            <Column className="gap-y-5">
              <iframe
                className="h-96 w-full rounded-xl"
                src={`https://www.youtube.com/embed/${videoId}?playlist=qKo0Jlao5ec&loop=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>{" "}
              <Column className="space-y-1">
                <Headline3 className="text-center">{title}</Headline3>
                <Text className="text-center line-clamp-2">{description}</Text>
              </Column>
              <Column className="items-center">
                <ButtonHover>
                  <Link to="https://pmb.uty.ac.id/" target="_blank">
                    <PrimaryButton
                      color="orange"
                      className="w-full rounded-full py-3"
                    >
                      Gabung Dengan Kami
                    </PrimaryButton>
                  </Link>
                </ButtonHover>
              </Column>
            </Column>
          </Column>
        </Column>
      </AlertDialog> */}
    </>
  );
}

export default LandingPage;
