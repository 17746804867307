import moment from 'moment'
import React from 'react'
import { getViewDisplay } from '../../shared/lib'
import { Caption, Column, DotSeparated, Headline1, HorizontalDivider, Image, Row, ShareFBButton, ShareTelegramButton, ShareWAButton, ShareXButton, Text, Title } from '../../shared/ui'
import SAlmDetailEvent from '../shimmer/salm_detail_event'

function WDetailEvent({ event }) {
  return (
    event !== null ? <Column className={'gap-y-8'}>
      <Column className={'gap-y-5'}>
        <Headline1 className={'font-semibold'}>
          {event.title}
        </Headline1>
        <Caption>
          {moment(event.created_at).format('DD/MM/YYYY HH.mm')} WIB
        </Caption>
        <Image
          src={event.images[0].image_name}
          className={'rounded-xl border border-gray-200'}
          imgClasName={`w-full ${event.rasio}`}
          hoverScale={false} />
        <div
          className={'text-gray-800 font-inter text-justify text-base mt-2'}
          dangerouslySetInnerHTML={{
            __html: event.body.replaceAll('<ul>', '<ul class="list-disc space-y-2 pl-5">')
              .replaceAll('<ol>', '<ol class="list-decimal pl-6 space-y-2">')
              .replaceAll('<img src=', '<img style="width: 100%; height: auto; border-radius: 0.75rem; border-width: 1px;" loading="lazy" src=')
              .replaceAll('undefined', null)
          }} />
      </Column>
      <Column className={'gap-y-5'}>
        <Column className={'gap-y-2'}>
          <Row className={'gap-x-1 items-center'}>
            <Text>
              Diposting oleh
            </Text>
            <Text className={'font-semibold font-manrope capitalize'}>
              Admin Alumni
            </Text>
          </Row>
          <Row className={'flex-wrap gap-y-2 items-center'}>
            <Caption>
              {moment(event.created_at).format('DD/MM/YYYY HH.mm')} WIB
            </Caption>
            <DotSeparated />
            <Caption>
              {getViewDisplay(event.viewer ?? 0)}x dilihat
            </Caption>
          </Row>
        </Column>
        <HorizontalDivider className='my-4' />
        <Title>
          Bagikan Berita
        </Title>
        <Row className='gap-x-3'>
          <ShareFBButton location={window.location.href} />
          <ShareXButton location={window.location.href} />
          <ShareWAButton location={window.location.href} />
          <ShareTelegramButton location={window.location.href} />
        </Row>
      </Column>
    </Column> : <SAlmDetailEvent />
  )
}

export default WDetailEvent