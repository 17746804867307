import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { HiBookmark, HiOutlineBookmark } from 'react-icons/hi2';
import { TbHeart, TbHeartFilled, TbMessageCircle2, TbSend } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { checkLikedRecognitionController, checkSavedRecognitionController, likeRecognitionController, saveRecognitionController } from '../../../controllers/alumni_controllers';
import { getFullNameUser } from '../../../controllers/apicontrollers';
import { getViewDisplay } from '../../../shared/lib';
import { Caption, Column, DotSeparated, Headline3, IconButton, Row, ShimmerAnimation, ShimmerItem, Text } from '../../../shared/ui';
import { recognitionRoute, toastDuration } from '../../../shared/variable';
import WGridImageRecognition from '../../wgrid_image_recognition';
import WListImageRecognition from '../../wlist_image_recognition';

function ItemRecognition({ recognition, isLogin, onComment, onShare }) {

    const navigateTo = useNavigate()

    const [fullName, setFullName] = useState(null)
    const [tagsFullName, setTagsFullName] = useState(null)
    const [meLiked, setMeLiked] = useState(false)
    const [meSaved, setMeSaved] = useState(false)
    const [sumLike, setSumLike] = useState(recognition.likes)
    const [showToast, setShowToast] = useState(false)

    const getFullNameOfUser = async () => {
        const result = await getFullNameUser(recognition.id_identify, recognition.id_user)
        if (result.status) {
            setFullName(result.data)
        }
    }

    const getFullNameOfTagUser = async () => {
        const result = await getFullNameUser(recognition.tags[0].id_identify, recognition.tags[0].id_user)
        if (result.status) {
            setTagsFullName(result.data.toLowerCase())
        }
    }

    const checkLikedRecognition = async () => {
        if (isLogin) {
            const result = await checkLikedRecognitionController(recognition.id_rekognisi)
            if (result.status) {
                setMeLiked(result.data)
            }
        }
    }

    const checkSavedRecognition = async () => {
        if (isLogin) {
            const result = await checkSavedRecognitionController(recognition.id_rekognisi)
            if (result.status) {
                setMeSaved(result.data)
            }
        }
    }

    const onClickRecognition = (event) => {
        event.stopPropagation();
        navigateTo(`${recognitionRoute}/${recognition.slug}`)
    }

    const onClickComment = (event) => {
        event.stopPropagation();
        if (isLogin) {
            onComment(recognition)
        } else {
            if (!showToast) {
                setShowToast(true)
                toast('Silakan masuk ke Akun Anda!')
                setTimeout(() => {
                    setShowToast(false)
                }, (toastDuration + 500))
            }
        }
    }

    const onClickShare = (event) => {
        event.stopPropagation();
        onShare(recognition)
    }

    const onClickLike = async (event) => {
        event.stopPropagation();
        if (isLogin) {
            if (meLiked) {
                decrementLike()
                const props = {
                    action: 20,
                    id: recognition.id_rekognisi,
                }
                const result = await likeRecognitionController(props)
                if (!result.status) {
                    incrementLike()
                    toast.error(result.message)
                } else {
                    toast.success(result.message)
                }
            } else {
                incrementLike()
                const props = {
                    action: 10,
                    id: recognition.id_rekognisi,
                }
                const result = await likeRecognitionController(props)
                if (!result.status) {
                    decrementLike()
                    toast.error(result.message)
                } else {
                    toast.success(result.message)
                }
            }
        } else {
            if (!showToast) {
                setShowToast(true)
                toast('Silakan masuk ke Akun Anda!')
                setTimeout(() => {
                    setShowToast(false)
                }, (toastDuration + 500))
            }
        }
    }

    const incrementLike = () => {
        const currentLike = sumLike + 1
        setSumLike(currentLike)
        setMeLiked(true)
    }

    const decrementLike = () => {
        const currentLike = sumLike - 1
        setSumLike(currentLike)
        setMeLiked(false)
    }

    const onClickSave = async (event) => {
        event.stopPropagation();
        if (isLogin) {
            if (meSaved) {
                const props = {
                    action: 20,
                    id: recognition.id_rekognisi,
                }
                const result = await saveRecognitionController(props)
                if (result.status) {
                    setMeSaved(false)
                    toast.success(result.message)
                } else {
                    toast.error(result.message)
                }
            } else {
                const props = {
                    action: 10,
                    id: recognition.id_rekognisi,
                }
                const result = await saveRecognitionController(props)
                if (result.status) {
                    setMeSaved(true)
                    toast.success(result.message)
                } else {
                    toast.error(result.message)
                }
            }
        } else {
            if (!showToast) {
                setShowToast(true)
                toast('Silakan masuk ke Akun Anda!')
                setTimeout(() => {
                    setShowToast(false)
                }, (toastDuration + 500))
            }
        }
    }

    useEffect(() => {
        getFullNameOfUser()
        checkLikedRecognition()
        checkSavedRecognition()
        if (recognition.tags.length > 0) {
            getFullNameOfTagUser()
        }
    }, [])

    return (
        <Column
            className={'cursor-pointer bg-white px-4 py-6 gap-y-8 border-y border-gray-200 transition-all duration-200 sm:p-8 sm:shadow-sm border-x lg:hover:shadow-md'}
            onClick={onClickRecognition}>
            <Column className={'gap-y-5'}>
                <Column className={'gap-y-3'}>
                    <Headline3 className={'transition-all duration-200 cursor-pointer hover:text-indigo-600'}>
                        {recognition.title}
                    </Headline3>
                    <Caption>
                        {moment(recognition.created_at).format('DD/MM/YYYY HH.mm')} WIB
                    </Caption>
                </Column>
                {recognition.images.length > 0 ? recognition.view === 'list' ?
                    <WListImageRecognition images={recognition.images} aspectRatio={recognition.rasio} action={20} /> :
                    <WGridImageRecognition images={recognition.images} aspectRatio={recognition.rasio} action={20} /> : null}
                <div
                    className={'text-gray-800 font-inter text-justify text-base mt-2'}
                    dangerouslySetInnerHTML={{
                        __html: recognition.body.split('\n')[0].replaceAll('</p>', ' <span style="font-weight: 500; color: #4F46E5;">Baca selengkapnya</span></p>')
                    }} />
            </Column>
            <Column className={'gap-y-5'}>
                <Row className={'gap-x-2 sm:gap-x-2.5'}>
                    <IconButton
                        className={'transition-all duration-200 p-0 group hover:bg-transparent hover:-translate-y-1'}
                        onClick={onClickLike}>
                        {meLiked ? <TbHeartFilled className={'h-8 w-8 text-pink-600 stroke-1.5'} /> :
                            <TbHeart className={'h-8 w-8 text-gray-600 stroke-1.5 group-hover:text-pink-600'} />}
                    </IconButton>
                    <IconButton
                        className={'transition-all duration-200 p-0 ml-1 group hover:bg-transparent hover:-translate-y-1'}
                        onClick={onClickComment}>
                        <TbMessageCircle2 className={'h-8 w-8 text-gray-600 stroke-1.5 group-hover:text-orange-600'} />
                    </IconButton>
                    <IconButton
                        className={'transition-all duration-200 p-0 group hover:bg-transparent hover:-translate-y-1'}
                        onClick={onClickShare}>
                        <TbSend className={'h-8 w-8 text-gray-600 rotate-12 stroke-1.5 group-hover:text-blue-600'} />
                    </IconButton>
                    <IconButton
                        className={'transition-all duration-200 p-0 group hover:bg-transparent hover:-translate-y-1 ml-auto'}
                        onClick={onClickSave}>
                        {meSaved ? <HiBookmark className={'h-8 w-8 text-indigo-600 stroke-1.5'} /> :
                            <HiOutlineBookmark className={'h-8 w-8 text-gray-600 stroke-1.5 group-hover:text-indigo-600'} />}
                    </IconButton>
                </Row>
                <Column className={'gap-y-2'}>
                    <Row className={'gap-x-1 flex-wrap items-center'}>
                        <Text>
                            Diposting oleh
                        </Text>
                        {fullName !== null ? <Text className={'font-semibold font-manrope capitalize'}>
                            {fullName}
                        </Text> : <ShimmerAnimation>
                            <ShimmerItem className={'h-4 w-28 rounded-md'} />
                        </ShimmerAnimation>}
                        {recognition.tags.length > 0 && <Text>
                            bersama
                        </Text>}
                        {recognition.tags.length > 0 && <div>
                            {tagsFullName !== null ? <Text className={'font-semibold font-manrope capitalize'}>
                                {tagsFullName}
                            </Text> : <ShimmerAnimation>
                                <ShimmerItem className={'h-4 w-28 rounded-md'} />
                            </ShimmerAnimation>}
                        </div>}
                        {recognition.tags.length > 1 && <Text>
                            dan <span className={'font-semibold font-manrope capitalize'}>
                                {recognition.tags.length - 1} lainnya
                            </span>
                        </Text>}
                    </Row>
                    <Row className={'flex-wrap gap-y-2 items-center'}>
                        <Caption>
                            {getViewDisplay(recognition.viewer ?? 0)}x dilihat
                        </Caption>
                        <DotSeparated />
                        {sumLike > 0 && <Row className={'w-fit'}>
                            <Caption>
                                {getViewDisplay(sumLike)} menyukai
                            </Caption>
                            <DotSeparated />
                        </Row>}
                        <Caption>
                            {recognition.comments > 0 ? getViewDisplay(recognition.comments ?? 0) : 'Belum ada '} komentar
                        </Caption>
                    </Row>
                </Column>
            </Column>
        </Column>
    )
}

export default ItemRecognition
