import React from "react";
import { TbArrowRight } from "react-icons/tb";
import { Row } from "../../../../../components/interfaces/block";
import {
  TableCell,
  TableRow,
} from "../../../../../components/interfaces/table";
import { BodyText } from "../../../../../components/interfaces/text";
import { ButtonHover, IconButton, Image } from "../../../../../shared/ui";
import { useNavigate } from "react-router-dom";
import { admCareerRoute } from "../../../../../shared/variable";

function RowTableLokerCareer({
  idx,
  lowongan,
  page = 1,
  limit = 10,
  handleClick,
}) {
  const navigateTo = useNavigate();
  return (
    <TableRow
      striped
      onClick={() =>
        navigateTo(`${admCareerRoute}/${lowongan.username}/lowongan`)
      }
    >
      <TableCell>{limit * (page - 1) + (idx + 1)}</TableCell>
      <TableCell>
        <Image
          src={lowongan.logo}
          className="aspect-square max-w-[3rem]"
          imgClasName="aspect-square object-scale-down mix-blend-multiply"
          hoverScale={false}
          removeBgColor
        />
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{lowongan.nama_mitra}</BodyText>
      </TableCell>
      <TableCell>{lowongan.pic_name}</TableCell>
      <TableCell
        className={`${lowongan.total_lowongan === 0 && "text-gray-500"}`}
      >
        {lowongan.total_lowongan > 0
          ? `${lowongan.total_lowongan} Lowongan`
          : "Belum tersedia"}
      </TableCell>
      <TableCell
        className={`${lowongan.total_lowongan_belum === 0 && "text-gray-500"}`}
      >
        <Row className={"gap-x-2 items-center"}>
          {lowongan.total_lowongan_belum > 0 && (
            <span className={"flex h-2.5 w-2.5"}>
              <span
                className={
                  "animate-ping absolute inline-flex h-2.5 w-2.5 rounded-full bg-red-400 opacity-75"
                }
              ></span>
              <span
                className={
                  "relative inline-flex rounded-full h-2.5 w-2.5 bg-red-500 border border-white"
                }
              ></span>
            </span>
          )}
          {lowongan.total_lowongan_belum > 0
            ? `${lowongan.total_lowongan_belum} Lowongan`
            : "Tidak ada"}
        </Row>
      </TableCell>
      <TableCell>
        <ButtonHover className={"mx-auto"}>
          <IconButton
            className="p-1.5"
            color="indigo"
            bordered
            onClick={() =>
              navigateTo(`${admCareerRoute}/${lowongan.username}/lowongan`)
            }
          >
            <TbArrowRight className="h-5 w-5" />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  );
}

export default RowTableLokerCareer;
