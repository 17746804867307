import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { BarChart, Card, Column, GridCol, Overline, Row, SelectView, SimpleAlert, Table, TableBody, TableCell, TableHead, TableRow, Text, Title } from '../../shared/ui';

const OvTracerIPT = ({ onDoneLoadData, action = 10 }) => {

    const [currentYear, setCurrentYear] = useState({ label: '2023', value: 1 })
    const listYear = [
        { label: '2023', value: 1 },
        { label: '2022', value: 2 },
        { label: '2021', value: 3 },
        { label: '2020', value: 4 },
        { label: '2019', value: 5 },
    ]

    const data2019 = [
        {
            name: 'Status Lulusan Saat Ini',
            chart: [
                {
                    name: 'Jumlah',
                    data: [
                        607,
                        49,
                        79,
                        9,
                        55,
                    ]
                },
            ],
            label: [
                'Bekerja (full time/part time)',
                'Wiraswasta',
                'Melanjutkan Pendidikan',
                'Tidak Kerja tetapi sedang mencari',
                'Belum memungkinkan bekerja',
            ],
            chartcolor: [
                '#068FFF',
                '#4A55A2',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#4A55A2]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Rata-Rata Penghasilan Lulusan',
            value: 'Rp.3.150.740',
            chart: [
                {
                    name: 'Rata-Rata Penghasilan',
                    data: [
                        4150130,
                        3130457,
                        2670305,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        621,
                        29,
                        19,
                        18,
                        122,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        77.72,
                        3.63,
                        2.38,
                        1.00,
                        15.27,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            chartcolor: [
                '#068FFF',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
                '#9C0F48',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
                'bg-[#9C0F48]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        6,
                        2,
                        0,
                        0,
                        2,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        563,
                        17,
                        16,
                        5,
                        108,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        52,
                        10,
                        3,
                        3,
                        12,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            tooltip: [
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Lulusan Mendapatkan Kerja <= 6 Bulan',
            chart: [
                543,
                256
            ],
            label: [
                'Ya',
                'Tidak',
            ],
            chartcolor: [
                '#068FFF',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Masa Tunggu Lulusan',
            value: 2.96,
            chart: [
                {
                    name: 'Rata-Rata Masa Tunggu',
                    data: [
                        1,
                        4.62,
                        3.27,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Jenis Perusahaan Tempat Lulusan Bekerja',
            chart: [
                {
                    name: 'Total',
                    data: [
                        63,
                        32,
                        27,
                        78,
                        473,
                        39,
                        87,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        8,
                        4,
                        3,
                        10,
                        59,
                        5,
                        11,
                    ]
                },
            ],
            label: [
                'Instansi pemerintah',
                'BUMN/BUMD',
                'Institusi/Organisasi Multilateral',
                'Organisasi non-profit/Lembaga Swadaya Masyarakat',
                'Perusahaan swasta',
                'Wiraswasta/perusahaan sendiri',
                'Lainnya, tuliskan:',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#FF55BB',
                '#F86F03',
                '#6A5495',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#FF55BB]',
                'bg-[#F86F03]',
                'bg-[#6A5495]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bila Berwiraswasta, Apa Posisi/Jabatan Lulusan',
            chart: [
                16,
                7,
                3,
                13,
            ],
            label: [
                'Founder',
                'Co-Founder',
                'Staff',
                'Freelance/Kerja Lepas'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                351,
                347,
                101,
            ],
            label: [
                'Lokal/Wilayah/Berwirausaha tidak Berizin',
                'Nasional/Berwirausaha Berizin',
                'Multinasional/Internasional',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                {
                    name: 'Magister',
                    data: [
                        1,
                        8,
                        0,
                    ]
                },
                {
                    name: 'Sarjana',
                    data: [
                        281,
                        331,
                        98,
                    ]
                },
                {
                    name: 'Diploma Tiga',
                    data: [
                        69,
                        8,
                        3,
                    ]
                },
            ],
            label: [
                ['Lokal/Wilayah/', 'Berwirausaha', 'tidak Berizin'],
                ['Nasional/Berwirausaha', 'Berizin'],
                'Multinasional/Internasional',
            ],
            tooltip: [
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Tingkat Pendidikan Apa yang Paling Tepat/Sesuai untuk Pekerjaan Lulusan',
            chart: [
                67,
                464,
                223,
                45,
            ],
            label: [
                'Setingkat Lebih Tinggi',
                'Tingkat yang Sama',
                'Setingkat Lebih Rendah',
                'Tidak Perlu Pendidikan Tinggi'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Sumber Dana dalam Pembiayaan Kuliah',
            chart: [
                {
                    name: 'Total',
                    data: [
                        743,
                        9,
                        34,
                        7,
                        6,
                        0,
                        0,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        93,
                        1,
                        4,
                        1,
                        1,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                'Biaya Sendiri/Keluarga',
                'Beasiswa ADIK',
                'Beasiswa BIDIKMISI',
                'Beasiswa PPA',
                'Beasiswa AFIRMASI',
                'Beasiswa Perusahaan/Swasta',
                'Lainnya, tuliskan:'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#5A8F7B',
                '#7900FF',
                '#F86F03',
                '#FF55BB',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#5A8F7B]',
                'bg-[#7900FF]',
                'bg-[#F86F03]',
                'bg-[#FF55BB]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Penguasaan Kompetensi Lulusan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 3,
                    val2: 3,
                    val3: 10,
                    val4: 42,
                    val5: 43
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 3,
                    val2: 8,
                    val3: 23,
                    val4: 53,
                    val5: 12
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 3,
                    val2: 14,
                    val3: 43,
                    val4: 24,
                    val5: 16
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 2,
                    val2: 2,
                    val3: 9,
                    val4: 38,
                    val5: 49
                },
                {
                    label: 'Komunikasi',
                    val1: 3,
                    val2: 7,
                    val3: 15,
                    val4: 41,
                    val5: 34
                },
                {
                    label: 'Kerja sama tim',
                    val1: 3,
                    val2: 6,
                    val3: 12,
                    val4: 41,
                    val5: 38
                },
                {
                    label: 'Pengembangan diri',
                    val1: 3,
                    val2: 6,
                    val3: 12,
                    val4: 42,
                    val5: 38
                },
            ]
        },
        {
            name: 'Tingkat Kompetensi yang Diperlukan dalam Pekerjaan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 2,
                    val2: 3,
                    val3: 11,
                    val4: 27,
                    val5: 58
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 2,
                    val2: 5,
                    val3: 24,
                    val4: 30,
                    val5: 38
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 2,
                    val2: 10,
                    val3: 24,
                    val4: 44,
                    val5: 20
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 2,
                    val2: 2,
                    val3: 11,
                    val4: 28,
                    val5: 57
                },
                {
                    label: 'Komunikasi',
                    val1: 2,
                    val2: 1,
                    val3: 8,
                    val4: 31,
                    val5: 58
                },
                {
                    label: 'Kerja sama tim',
                    val1: 3,
                    val2: 2,
                    val3: 8,
                    val4: 35,
                    val5: 52
                },
                {
                    label: 'Pengembangan diri',
                    val1: 2,
                    val2: 2,
                    val3: 9,
                    val4: 34,
                    val5: 53
                },
            ]
        },
        {
            name: 'Seberapa Besar Penekanan pada Metode Pembelajaran di bawah ini Dilaksanakan oleh Program Studi',
            head: [
                'Metode',
                'Sangat Besar',
                'Besar',
                'Cukup Besar',
                'Kurang',
                'Tidak Sama Sekali'
            ],
            row: [
                {
                    label: 'Perkuliahan',
                    val1: 25,
                    val2: 42,
                    val3: 27,
                    val4: 6,
                    val5: 1
                },
                {
                    label: 'Demonstrasi',
                    val1: 10,
                    val2: 29,
                    val3: 40,
                    val4: 17,
                    val5: 4
                },
                {
                    label: 'Partisipasi dalam proyek riset ',
                    val1: 19,
                    val2: 26,
                    val3: 32,
                    val4: 19,
                    val5: 4
                },
                {
                    label: 'Magang',
                    val1: 26,
                    val2: 29,
                    val3: 27,
                    val4: 14,
                    val5: 4
                },
                {
                    label: 'Praktikum',
                    val1: 32,
                    val2: 33,
                    val3: 23,
                    val4: 9,
                    val5: 3
                },
                {
                    label: 'Kerja Lapangan',
                    val1: 30,
                    val2: 27,
                    val3: 23,
                    val4: 15,
                    val5: 5
                },
                {
                    label: 'Diskusi',
                    val1: 32,
                    val2: 25,
                    val3: 33,
                    val4: 8,
                    val5: 1
                },
            ]
        },
        {
            name: 'Kapan Lulusan Mulai Mencari Pekerjaan',
            chart: [
                502,
                283,
                14,
            ],
            label: [
                'Sebelum Lulus',
                'Setelah Lulus',
                'Tidak Mencari Kerja'
            ],
            chartcolor: [
                '#2196F3',
                '#7A9D54',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bagaimana Lulusan Mencari Pekerjaan',
            head: [
                'Info Lowongan Kerja',
                'Persentase',
            ],
            row: [
                {
                    label: 'Iklan di koran , majalah, Brosur',
                    value: 12.56,
                },
                {
                    label: 'Melamar ke perusahaan tanpa mengetahui lowongan yg ada',
                    value: 5.45,
                },
                {
                    label: 'Pergi ke bursa /Pameran kerja',
                    value: 4.98,
                },
                {
                    label: 'Mencari lewat internet / iklan online / milis',
                    value: 29.05,
                },
                {
                    label: 'Dihubungi oleh perusahaan',
                    value: 3.25,
                },
                {
                    label: 'Menghubungi Kemenakertrans',
                    value: 1.68,
                },
                {
                    label: 'Menghubungi agen tenaga kerja komersial/swasta',
                    value: 3.78,
                },
                {
                    label: 'Memperoleh informasi dari pusat /kantor pengembangan karir fakultas/universitas',
                    value: 2.45,
                },
                {
                    label: 'Menghubungi kantor kemahasiswaan/hubungan alumni',
                    value: 7.90,
                },
                {
                    label: 'Membangun jejaring (network) sejak masa kuliah',
                    value: 3.63,
                },
                {
                    label: 'Melalui relasi (misalnya dosen, orang tua, saudara, teman, dll)',
                    value: 18.75,
                },
                {
                    label: 'Membangun bisnis sendiri',
                    value: 2.50,
                },
                {
                    label: 'Melalui penempatan kerja atau magang',
                    value: 2.73,
                },
                {
                    label: 'Bekerja di tempat yang sama dengan tempat kerja semasa kuliah',
                    value: 1.29,
                },
            ]
        },
        {
            name: 'Proses Lulusan Mencari Pekerjaan',
            head: [
                'Instrumen',
                'Rerata jumlah instansi',
            ],
            row: [
                {
                    label: 'Berapa perusahaan/instansi/institusi yang sudah anda lamar sebelum anda memperoleh pekerjaan pertama?  (Rata - rata)',
                    value: 43,
                },
                {
                    label: 'Berapa banyak perusahaan atau instansi yang merespon lamaran anda?',
                    value: 9,
                },
                {
                    label: 'Berapa banyak instansi dan perusahaan yang mengundang anda untuk wawancara?',
                    value: 10,
                },
            ]
        },
        {
            name: 'Apakah Lulusan Aktif Mencari Pekerjaan dalam 4 Minggu Terakhir',
            chart: [
                478,
                78,
                38,
                134,
                71
            ],
            label: [
                'Tidak',
                'Tidak, tapi saya sedang menunggu hasil lamaran kerja',
                'Ya, saya akan mulai bekerja dalam 2 minggu ke depan',
                'Ya, tapi saya belum pasti akan bekerja dalam 2 minggu ke depan',
                'Lainnya'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Jika menurut Anda pekerjaan Anda saat ini tidak sesuai dengan pendidikan Anda, mengapa Anda mengambilnya?',
            head: [
                'Instrumen',
                'Persentase',
            ],
            row: [
                {
                    label: 'Pertanyaan tidak sesuai, pekerjaan saya sekarang sudah sesuai dengan pendidikan saya',
                    value: 9.95,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 8.81,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 8.35,
                },
                {
                    label: 'Saya lebih suka bekerja di area pekerjaan yang tidak ada hubungannya dengan pendidikan saya',
                    value: 7.23,
                },
                {
                    label: 'Saya dipromosikan ke posisi yang kurang berhubungan dengan pendidikan saya dibanding posisi sebelumnya',
                    value: 9.63,
                },
                {
                    label: 'Saya dapat memeroleh pendapatan yang lebih tinggi di pekerjaan ini',
                    value: 7.35,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih aman/terjamin/secure',
                    value: 7.62,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih menarik',
                    value: 7.63,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih memungkinkan saya mengambil pekerjaan tambahan/jadwal yang fleksibel, dll',
                    value: 7.35,
                },
                {
                    label: 'Pekerjaan saya saat ini lokasinya lebih dekat dari rumah saya',
                    value: 5.20,
                },
                {
                    label: 'Pekerjaan saya saat ini dapat lebih menjamin kebutuhan keluarga saya',
                    value: 7.53,
                },
                {
                    label: 'Pada awal meniti karir ini, saya harus menerima pekerjaan yang tidak berhubungan dengan pendidikan saya',
                    value: 5.21,
                },
                {
                    label: 'Lainnya',
                    value: 8.13,
                },
            ]
        },
        {
            name: 'Kategori Bidang Pekerjaan Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        20.03,
                        11.85,
                        10.93,
                        6.95,
                        3.25,
                        2.70,
                        44.29
                    ]
                },
            ],
            label: [
                'Teknologi',
                'Konstruksi dan Manufaktur',
                'Pendidikan',
                'Wirausaha',
                'Instansi Pemerintah',
                'Perbankan',
                'Lainnya'
            ],
            chartcolor: [
                '#3F51B5',
                '#6A5495',
                '#526D82',
                '#EA906C',
                '#AE4CCF',
                '#088395',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#6A5495]',
                'bg-[#526D82]',
                'bg-[#EA906C]',
                'bg-[#AE4CCF]',
                'bg-[#088395]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Grafik Sebaran Responden Tracer Studi',
            chart: [
                {
                    name: 'Total Responden',
                    data: [
                        57,
                        9,
                        7,
                        7,
                        123,
                        74,
                        87,
                        23,
                        108,
                        11,
                        15,
                        27,
                        47,
                        33,
                        41,
                        9,
                        112,
                        9,
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S1 Akuntansi',
                'S1 Manajemen',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
                'Program Magister'
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        83.33,
                        84.94,
                        82.22,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
    ]

    const data2020 = [
        {
            name: 'Status Lulusan Saat Ini',
            chart: [
                {
                    name: 'Jumlah',
                    data: [
                        617,
                        82,
                        9,
                        59,
                        51,
                    ]
                },
            ],
            label: [
                'Bekerja (full time/part time)',
                'Wiraswasta',
                'Melanjutkan Pendidikan',
                'Tidak Kerja tetapi sedang mencari',
                'Belum memungkinkan bekerja',
            ],
            chartcolor: [
                '#068FFF',
                '#4A55A2',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#4A55A2]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Rata-Rata Penghasilan Lulusan',
            value: 'Rp.3.230.075',
            chart: [
                {
                    name: 'Rata-Rata Penghasilan',
                    data: [
                        4230535,
                        3194485,
                        2995000,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        646,
                        21,
                        15,
                        10,
                        126,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        78.97,
                        2.57,
                        1.83,
                        1.22,
                        15.40,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            chartcolor: [
                '#068FFF',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
                '#9C0F48',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
                'bg-[#9C0F48]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        7,
                        2,
                        0,
                        1,
                        2,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        579,
                        12,
                        11,
                        6,
                        108,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        60,
                        7,
                        4,
                        3,
                        16,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            tooltip: [
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Lulusan Mendapatkan Kerja <= 6 Bulan',
            chart: [
                550,
                268
            ],
            label: [
                'Ya',
                'Tidak',
            ],
            chartcolor: [
                '#068FFF',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Masa Tunggu Lulusan',
            value: 2.91,
            chart: [
                {
                    name: 'Rata-Rata Masa Tunggu',
                    data: [
                        1,
                        4.57,
                        3.15,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Jenis Perusahaan Tempat Lulusan Bekerja',
            chart: [
                {
                    name: 'Total',
                    data: [
                        65,
                        30,
                        25,
                        87,
                        477,
                        43,
                        91,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        8,
                        4,
                        3,
                        11,
                        58,
                        5,
                        11,
                    ]
                },
            ],
            label: [
                'Instansi pemerintah',
                'BUMN/BUMD',
                'Institusi/Organisasi Multilateral',
                'Organisasi non-profit/Lembaga Swadaya Masyarakat',
                'Perusahaan swasta',
                'Wiraswasta/perusahaan sendiri',
                'Lainnya, tuliskan:',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#FF55BB',
                '#F86F03',
                '#6A5495',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#FF55BB]',
                'bg-[#F86F03]',
                'bg-[#6A5495]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bila Berwiraswasta, Apa Posisi/Jabatan Lulusan',
            chart: [
                23,
                8,
                2,
                10,
            ],
            label: [
                'Founder',
                'Co-Founder',
                'Staff',
                'Freelance/Kerja Lepas'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                357,
                356,
                105,
            ],
            label: [
                'Lokal/Wilayah/Berwirausaha tidak Berizin',
                'Nasional/Berwirausaha Berizin',
                'Multinasional/Internasional',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                {
                    name: 'Magister',
                    data: [
                        0,
                        12,
                        0,
                    ]
                },
                {
                    name: 'Sarjana',
                    data: [
                        283,
                        333,
                        100,
                    ]
                },
                {
                    name: 'Diploma Tiga',
                    data: [
                        74,
                        11,
                        5,
                    ]
                },
            ],
            label: [
                ['Lokal/Wilayah/', 'Berwirausaha', 'tidak Berizin'],
                ['Nasional/Berwirausaha', 'Berizin'],
                'Multinasional/Internasional',
            ],
            tooltip: [
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Tingkat Pendidikan Apa yang Paling Tepat/Sesuai untuk Pekerjaan Lulusan',
            chart: [
                57,
                475,
                234,
                52,
            ],
            label: [
                'Setingkat Lebih Tinggi',
                'Tingkat yang Sama',
                'Setingkat Lebih Rendah',
                'Tidak Perlu Pendidikan Tinggi'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Sumber Dana dalam Pembiayaan Kuliah',
            chart: [
                {
                    name: 'Total',
                    data: [
                        756,
                        13,
                        37,
                        5,
                        7,
                        0,
                        0,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        92,
                        2,
                        5,
                        1,
                        1,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                'Biaya Sendiri/Keluarga',
                'Beasiswa ADIK',
                'Beasiswa BIDIKMISI',
                'Beasiswa PPA',
                'Beasiswa AFIRMASI',
                'Beasiswa Perusahaan/Swasta',
                'Lainnya, tuliskan:'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#5A8F7B',
                '#7900FF',
                '#F86F03',
                '#FF55BB',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#5A8F7B]',
                'bg-[#7900FF]',
                'bg-[#F86F03]',
                'bg-[#FF55BB]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Penguasaan Kompetensi Lulusan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 3,
                    val2: 3,
                    val3: 10,
                    val4: 42,
                    val5: 43
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 3,
                    val2: 8,
                    val3: 23,
                    val4: 53,
                    val5: 13
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 3,
                    val2: 14,
                    val3: 48,
                    val4: 23,
                    val5: 12
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 2,
                    val2: 2,
                    val3: 9,
                    val4: 38,
                    val5: 50
                },
                {
                    label: 'Komunikasi',
                    val1: 3,
                    val2: 6,
                    val3: 15,
                    val4: 40,
                    val5: 37
                },
                {
                    label: 'Kerja sama tim',
                    val1: 3,
                    val2: 6,
                    val3: 12,
                    val4: 40,
                    val5: 39
                },
                {
                    label: 'Pengembangan diri',
                    val1: 3,
                    val2: 6,
                    val3: 11,
                    val4: 42,
                    val5: 39
                },
            ]
        },
        {
            name: 'Tingkat Kompetensi yang Diperlukan dalam Pekerjaan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 2,
                    val2: 2,
                    val3: 11,
                    val4: 24,
                    val5: 61
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 2,
                    val2: 5,
                    val3: 24,
                    val4: 32,
                    val5: 37
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 2,
                    val2: 10,
                    val3: 23,
                    val4: 45,
                    val5: 20
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 2,
                    val2: 2,
                    val3: 10,
                    val4: 24,
                    val5: 61
                },
                {
                    label: 'Komunikasi',
                    val1: 2,
                    val2: 1,
                    val3: 7,
                    val4: 27,
                    val5: 62
                },
                {
                    label: 'Kerja sama tim',
                    val1: 2,
                    val2: 1,
                    val3: 6,
                    val4: 32,
                    val5: 58
                },
                {
                    label: 'Pengembangan diri',
                    val1: 2,
                    val2: 1,
                    val3: 9,
                    val4: 31,
                    val5: 56
                },
            ]
        },
        {
            name: 'Seberapa Besar Penekanan pada Metode Pembelajaran di bawah ini Dilaksanakan oleh Program Studi',
            head: [
                'Metode',
                'Sangat Besar',
                'Besar',
                'Cukup Besar',
                'Kurang',
                'Tidak Sama Sekali'
            ],
            row: [
                {
                    label: 'Perkuliahan',
                    val1: 25,
                    val2: 42,
                    val3: 26,
                    val4: 6,
                    val5: 1
                },
                {
                    label: 'Demonstrasi',
                    val1: 8,
                    val2: 32,
                    val3: 39,
                    val4: 16,
                    val5: 4
                },
                {
                    label: 'Partisipasi dalam proyek riset ',
                    val1: 15,
                    val2: 31,
                    val3: 32,
                    val4: 18,
                    val5: 4
                },
                {
                    label: 'Magang',
                    val1: 28,
                    val2: 28,
                    val3: 27,
                    val4: 13,
                    val5: 4
                },
                {
                    label: 'Praktikum',
                    val1: 31,
                    val2: 35,
                    val3: 22,
                    val4: 8,
                    val5: 3
                },
                {
                    label: 'Kerja Lapangan',
                    val1: 30,
                    val2: 28,
                    val3: 23,
                    val4: 14,
                    val5: 5
                },
                {
                    label: 'Diskusi',
                    val1: 32,
                    val2: 27,
                    val3: 32,
                    val4: 7,
                    val5: 1
                },
            ]
        },
        {
            name: 'Kapan Lulusan Mulai Mencari Pekerjaan',
            chart: [
                512,
                278,
                28,
            ],
            label: [
                'Sebelum Lulus',
                'Setelah Lulus',
                'Tidak Mencari Kerja'
            ],
            chartcolor: [
                '#2196F3',
                '#7A9D54',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bagaimana Lulusan Mencari Pekerjaan',
            head: [
                'Info Lowongan Kerja',
                'Persentase',
            ],
            row: [
                {
                    label: 'Iklan di koran , majalah, Brosur',
                    value: 11.61,
                },
                {
                    label: 'Melamar ke perusahaan tanpa mengetahui lowongan yg ada',
                    value: 5.62,
                },
                {
                    label: 'Pergi ke bursa /Pameran kerja',
                    value: 5.75,
                },
                {
                    label: 'Mencari lewat internet / iklan online / milis',
                    value: 28.73,
                },
                {
                    label: 'Dihubungi oleh perusahaan',
                    value: 3.42,
                },
                {
                    label: 'Menghubungi Kemenakertrans',
                    value: 1.59,
                },
                {
                    label: 'Menghubungi agen tenaga kerja komersial/swasta',
                    value: 3.91,
                },
                {
                    label: 'Memperoleh informasi dari pusat /kantor pengembangan karir fakultas/universitas',
                    value: 2.57,
                },
                {
                    label: 'Menghubungi kantor kemahasiswaan/hubungan alumni',
                    value: 8.19,
                },
                {
                    label: 'Membangun jejaring (network) sejak masa kuliah',
                    value: 3.42,
                },
                {
                    label: 'Melalui relasi (misalnya dosen, orang tua, saudara, teman, dll)',
                    value: 17.73,
                },
                {
                    label: 'Membangun bisnis sendiri',
                    value: 3.06,
                },
                {
                    label: 'Melalui penempatan kerja atau magang',
                    value: 2.93,
                },
                {
                    label: 'Bekerja di tempat yang sama dengan tempat kerja semasa kuliah',
                    value: 1.47,
                },
            ]
        },
        {
            name: 'Proses Lulusan Mencari Pekerjaan',
            head: [
                'Instrumen',
                'Rerata jumlah instansi',
            ],
            row: [
                {
                    label: 'Berapa perusahaan/instansi/institusi yang sudah anda lamar sebelum anda memperoleh pekerjaan pertama?  (Rata - rata)',
                    value: 48,
                },
                {
                    label: 'Berapa banyak perusahaan atau instansi yang merespon lamaran anda?',
                    value: 11,
                },
                {
                    label: 'Berapa banyak instansi dan perusahaan yang mengundang anda untuk wawancara?',
                    value: 10,
                },
            ]
        },
        {
            name: 'Apakah Lulusan Aktif Mencari Pekerjaan dalam 4 Minggu Terakhir',
            chart: [
                458,
                82,
                49,
                164,
                65
            ],
            label: [
                'Tidak',
                'Tidak, tapi saya sedang menunggu hasil lamaran kerja',
                'Ya, saya akan mulai bekerja dalam 2 minggu ke depan',
                'Ya, tapi saya belum pasti akan bekerja dalam 2 minggu ke depan',
                'Lainnya'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Jika menurut Anda pekerjaan Anda saat ini tidak sesuai dengan pendidikan Anda, mengapa Anda mengambilnya?',
            head: [
                'Instrumen',
                'Persentase',
            ],
            row: [
                {
                    label: 'Pertanyaan tidak sesuai, pekerjaan saya sekarang sudah sesuai dengan pendidikan saya',
                    value: 8.09,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 9.56,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 7.35,
                },
                {
                    label: 'Saya lebih suka bekerja di area pekerjaan yang tidak ada hubungannya dengan pendidikan saya',
                    value: 7.35,
                },
                {
                    label: 'Saya dipromosikan ke posisi yang kurang berhubungan dengan pendidikan saya dibanding posisi sebelumnya',
                    value: 9.56,
                },
                {
                    label: 'Saya dapat memeroleh pendapatan yang lebih tinggi di pekerjaan ini',
                    value: 7.35,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih aman/terjamin/secure',
                    value: 6.62,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih menarik',
                    value: 6.62,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih memungkinkan saya mengambil pekerjaan tambahan/jadwal yang fleksibel, dll',
                    value: 7.35,
                },
                {
                    label: 'Pekerjaan saya saat ini lokasinya lebih dekat dari rumah saya',
                    value: 8.09,
                },
                {
                    label: 'Pekerjaan saya saat ini dapat lebih menjamin kebutuhan keluarga saya',
                    value: 7.35,
                },
                {
                    label: 'Pada awal meniti karir ini, saya harus menerima pekerjaan yang tidak berhubungan dengan pendidikan saya',
                    value: 6.62,
                },
                {
                    label: 'Lainnya',
                    value: 8.09,
                },
            ]
        },
        {
            name: 'Kategori Bidang Pekerjaan Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        20.25,
                        12.55,
                        11.30,
                        7.55,
                        3.05,
                        2.20,
                        43.10
                    ]
                },
            ],
            label: [
                'Teknologi',
                'Konstruksi dan Manufaktur',
                'Pendidikan',
                'Wirausaha',
                'Instansi Pemerintah',
                'Perbankan',
                'Lainnya'
            ],
            chartcolor: [
                '#3F51B5',
                '#6A5495',
                '#526D82',
                '#EA906C',
                '#AE4CCF',
                '#088395',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#6A5495]',
                'bg-[#526D82]',
                'bg-[#EA906C]',
                'bg-[#AE4CCF]',
                'bg-[#088395]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Grafik Sebaran Responden Tracer Studi',
            chart: [
                {
                    name: 'Total Responden',
                    data: [
                        61,
                        11,
                        10,
                        8,
                        131,
                        78,
                        99,
                        26,
                        104,
                        14,
                        11,
                        23,
                        54,
                        27,
                        35,
                        10,
                        104,
                        12,
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S1 Akuntansi',
                'S1 Manajemen',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
                'Program Magister'
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        83.33,
                        84.94,
                        82.22,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
    ]

    const data2021 = [
        {
            name: 'Status Lulusan Saat Ini',
            chart: [
                {
                    name: 'Jumlah',
                    data: [
                        566,
                        76,
                        11,
                        49,
                        34,
                    ]
                },
            ],
            label: [
                'Bekerja (full time/part time)',
                'Belum memungkinkan bekerja',
                'Wiraswasta',
                'Melanjutkan Pendidikan',
                'Tidak Kerja tetapi sedang mencari',
            ],
            chartcolor: [
                '#068FFF',
                '#4A55A2',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#4A55A2]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Rata-Rata Penghasilan Lulusan',
            value: 'Rp.3.212.593',
            chart: [
                {
                    name: 'Rata-Rata Penghasilan',
                    data: [
                        4500000,
                        3204208,
                        3166038,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        514,
                        53,
                        22,
                        15,
                        132,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        69.84,
                        7.20,
                        2.99,
                        2.04,
                        17.93,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            chartcolor: [
                '#068FFF',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
                '#9C0F48',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
                'bg-[#9C0F48]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        4,
                        1,
                        1,
                        0,
                        0,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        489,
                        42,
                        18,
                        11,
                        128,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        21,
                        10,
                        3,
                        4,
                        4,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            tooltip: [
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Lulusan Mendapatkan Kerja <= 6 Bulan',
            chart: [
                515,
                221,
            ],
            label: [
                'Ya',
                'Tidak',
            ],
            chartcolor: [
                '#068FFF',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Masa Tunggu Lulusan',
            value: 2.81,
            chart: [
                {
                    name: 'Rata-Rata Masa Tunggu',
                    data: [
                        1,
                        4.24,
                        3.18,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Jenis Perusahaan Tempat Lulusan Bekerja',
            chart: [
                {
                    name: 'Total',
                    data: [
                        65,
                        30,
                        25,
                        65,
                        435,
                        34,
                        82,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        9,
                        4,
                        3,
                        9,
                        59,
                        5,
                        11,
                    ]
                },
            ],
            label: [
                'Instansi pemerintah',
                'BUMN/BUMD',
                'Institusi/Organisasi Multilateral',
                'Organisasi non-profit/Lembaga Swadaya Masyarakat',
                'Perusahaan swasta',
                'Wiraswasta/perusahaan sendiri',
                'Lainnya, tuliskan:',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#FF55BB',
                '#F86F03',
                '#6A5495',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#FF55BB]',
                'bg-[#F86F03]',
                'bg-[#6A5495]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bila Berwiraswasta, Apa Posisi/Jabatan Lulusan',
            chart: [
                15,
                4,
                2,
                13,
            ],
            label: [
                'Founder',
                'Co-Founder',
                'Staff',
                'Freelance/Kerja Lepas'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                184,
                450,
                102,
            ],
            label: [
                'Lokal/Wilayah/Berwirausaha tidak Berizin',
                'Nasional/Berwirausaha Berizin',
                'Multinasional/Internasional',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                {
                    name: 'Magister',
                    data: [
                        0,
                        6,
                        0,
                    ]
                },
                {
                    name: 'Sarjana',
                    data: [
                        157,
                        453,
                        96,
                    ]
                },
                {
                    name: 'Diploma Tiga',
                    data: [
                        27,
                        9,
                        6,
                    ]
                },
            ],
            label: [
                'Lokal/Wilayah/Berwirausaha tidak Berizin',
                'Nasional/Berwirausaha Berizin',
                'Multinasional/Internasional',
            ],
            tooltip: [
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Tingkat Pendidikan Apa yang Paling Tepat/Sesuai untuk Pekerjaan Lulusan',
            chart: [
                67,
                436,
                198,
                35,
            ],
            label: [
                'Setingkat Lebih Tinggi',
                'Tingkat yang Sama',
                'Setingkat Lebih Rendah',
                'Tidak Perlu Pendidikan Tinggi'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Sumber Dana dalam Pembiayaan Kuliah',
            chart: [
                {
                    name: 'Total',
                    data: [
                        687,
                        4,
                        32,
                        5,
                        8,
                        0,
                        0,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        93,
                        1,
                        4,
                        1,
                        1,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                'Biaya Sendiri / Keluarga',
                'Beasiswa ADIK',
                'Beasiswa BIDIKMISI',
                'Beasiswa PPA',
                'Beasiswa AFIRMASI',
                'Beasiswa Perusahaan/Swasta',
                'Lainnya, tuliskan:'
            ],
            chartcolor: [
                '#3F51B5',
                '#FF9800',
                '#2196F3',
                '#4CAF50',
                '#FF9800',
                '#2196F3',
                '#4CAF50',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
                'bg-[#2196F3]',
                'bg-[#4CAF50]',
                'bg-[#FF9800]',
                'bg-[#2196F3]',
                'bg-[#4CAF50]',
            ],
        },
        {
            name: 'Penguasaan Kompetensi Lulusan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 2,
                    val2: 3,
                    val3: 9,
                    val4: 42,
                    val5: 44
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 2,
                    val2: 5,
                    val3: 24,
                    val4: 54,
                    val5: 14
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 2,
                    val2: 13,
                    val3: 48,
                    val4: 24,
                    val5: 12
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 1,
                    val2: 2,
                    val3: 8,
                    val4: 47,
                    val5: 42
                },
                {
                    label: 'Komunikasi',
                    val1: 2,
                    val2: 3,
                    val3: 16,
                    val4: 41,
                    val5: 38
                },
                {
                    label: 'Kerja sama tim',
                    val1: 2,
                    val2: 3,
                    val3: 13,
                    val4: 41,
                    val5: 40
                },
                {
                    label: 'Pengembangan diri',
                    val1: 2,
                    val2: 4,
                    val3: 12,
                    val4: 43,
                    val5: 40
                },
            ]
        },
        {
            name: 'Tingkat Kompetensi yang Diperlukan dalam Pekerjaan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 2,
                    val2: 2,
                    val3: 9,
                    val4: 25,
                    val5: 62
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 2,
                    val2: 5,
                    val3: 23,
                    val4: 32,
                    val5: 38
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 2,
                    val2: 11,
                    val3: 20,
                    val4: 46,
                    val5: 21
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 2,
                    val2: 2,
                    val3: 8,
                    val4: 26,
                    val5: 63
                },
                {
                    label: 'Komunikasi',
                    val1: 2,
                    val2: 1,
                    val3: 6,
                    val4: 27,
                    val5: 63
                },
                {
                    label: 'Kerja sama tim',
                    val1: 2,
                    val2: 1,
                    val3: 5,
                    val4: 33,
                    val5: 59
                },
                {
                    label: 'Pengembangan diri',
                    val1: 2,
                    val2: 1,
                    val3: 8,
                    val4: 31,
                    val5: 57
                },
            ]
        },
        {
            name: 'Seberapa Besar Penekanan pada Metode Pembelajaran di bawah ini Dilaksanakan oleh Program Studi',
            head: [
                'Metode',
                'Sangat Besar',
                'Besar',
                'Cukup Besar',
                'Kurang',
                'Tidak Sama Sekali'
            ],
            row: [
                {
                    label: 'Perkuliahan',
                    val1: 26,
                    val2: 43,
                    val3: 27,
                    val4: 3,
                    val5: 1
                },
                {
                    label: 'Demonstrasi',
                    val1: 9,
                    val2: 33,
                    val3: 40,
                    val4: 14,
                    val5: 4
                },
                {
                    label: 'Partisipasi dalam proyek riset ',
                    val1: 15,
                    val2: 32,
                    val3: 31,
                    val4: 18,
                    val5: 3
                },
                {
                    label: 'Magang',
                    val1: 29,
                    val2: 29,
                    val3: 26,
                    val4: 13,
                    val5: 4
                },
                {
                    label: 'Praktikum',
                    val1: 32,
                    val2: 36,
                    val3: 21,
                    val4: 9,
                    val5: 2
                },
                {
                    label: 'Kerja Lapangan',
                    val1: 31,
                    val2: 29,
                    val3: 21,
                    val4: 14,
                    val5: 5
                },
                {
                    label: 'Diskusi',
                    val1: 33,
                    val2: 28,
                    val3: 34,
                    val4: 4,
                    val5: 1
                },
            ]
        },
        {
            name: 'Kapan Lulusan Mulai Mencari Pekerjaan',
            chart: [
                479,
                239,
                18,
            ],
            label: [
                'Sebelum Lulus',
                'Setelah Lulus',
                'Tidak Mencari Kerja'
            ],
            chartcolor: [
                '#2196F3',
                '#7A9D54',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bagaimana Lulusan Mencari Pekerjaan',
            head: [
                'Info Lowongan Kerja',
                'Persentase',
            ],
            row: [
                {
                    label: 'Iklan di koran , majalah, Brosur',
                    value: 12.09,
                },
                {
                    label: 'Melamar ke perusahaan tanpa mengetahui lowongan yg ada',
                    value: 5.30,
                },
                {
                    label: 'Pergi ke bursa /Pameran kerja',
                    value: 5.03,
                },
                {
                    label: 'Mencari lewat internet / iklan online / milis',
                    value: 28.67,
                },
                {
                    label: 'Dihubungi oleh perusahaan',
                    value: 3.80,
                },
                {
                    label: 'Menghubungi Kemenakertrans',
                    value: 1.77,
                },
                {
                    label: 'Menghubungi agen tenaga kerja komersial/swasta',
                    value: 2.99,
                },
                {
                    label: 'Memperoleh informasi dari pusat/kantor pengembangan karir fakultas/universitas',
                    value: 3.13,
                },
                {
                    label: 'Menghubungi kantor kemahasiswaan/hubungan alumni',
                    value: 9.10,
                },
                {
                    label: 'Membangun jejaring (network) sejak masa kuliah',
                    value: 3.80,
                },
                {
                    label: 'Melalui relasi (misalnya dosen, orang tua, saudara, teman, dll)',
                    value: 16.71,
                },
                {
                    label: 'Membangun bisnis sendiri',
                    value: 3.13,
                },
                {
                    label: 'Melalui penempatan kerja atau magang',
                    value: 2.99,
                },
                {
                    label: 'Bekerja di tempat yang sama dengan tempat kerja semasa kuliah',
                    value: 1.49,
                },
            ]
        },
        {
            name: 'Proses Lulusan Mencari Pekerjaan',
            head: [
                'Instrumen',
                'Rerata jumlah instansi',
            ],
            row: [
                {
                    label: 'Berapa perusahaan/instansi/institusi yang sudah anda lamar sebelum anda memperoleh pekerjaan pertama?  (Rata - rata)',
                    value: 43,
                },
                {
                    label: 'Berapa banyak perusahaan atau instansi yang merespon lamaran anda?',
                    value: 12,
                },
                {
                    label: 'Berapa banyak instansi dan perusahaan yang mengundang anda untuk wawancara?',
                    value: 11,
                },
            ]
        },
        {
            name: 'Apakah Lulusan Aktif Mencari Pekerjaan dalam 4 Minggu Terakhir',
            chart: [
                403,
                79,
                50,
                146,
                58
            ],
            label: [
                'Tidak',
                'Tidak, tapi saya sedang menunggu hasil lamaran kerja',
                'Ya, saya akan mulai bekerja dalam 2 minggu ke depan',
                'Ya, tapi saya belum pasti akan bekerja dalam 2 minggu ke depan',
                'Lainnya'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Jika menurut Anda pekerjaan Anda saat ini tidak sesuai dengan pendidikan Anda, mengapa Anda mengambilnya?',
            head: [
                'Instrumen',
                'Persentase',
            ],
            row: [
                {
                    label: 'Pertanyaan tidak sesuai, pekerjaan saya sekarang sudah sesuai dengan pendidikan saya',
                    value: 10.14,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 7.25,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 8.70,
                },
                {
                    label: 'Saya lebih suka bekerja di area pekerjaan yang tidak ada hubungannya dengan pendidikan saya',
                    value: 7.25,
                },
                {
                    label: 'Saya dipromosikan ke posisi yang kurang berhubungan dengan pendidikan saya dibanding posisi sebelumnya',
                    value: 7.25,
                },
                {
                    label: 'Saya dapat memeroleh pendapatan yang lebih tinggi di pekerjaan ini',
                    value: 5.80,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih aman/terjamin/secure',
                    value: 7.25,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih menarik',
                    value: 5.80,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih memungkinkan saya mengambil pekerjaan tambahan/jadwal yang fleksibel, dll',
                    value: 7.25,
                },
                {
                    label: 'Pekerjaan saya saat ini lokasinya lebih dekat dari rumah saya',
                    value: 8.70,
                },
                {
                    label: 'Pekerjaan saya saat ini dapat lebih menjamin kebutuhan keluarga saya',
                    value: 7.25,
                },
                {
                    label: 'Pada awal meniti karir ini, saya harus menerima pekerjaan yang tidak berhubungan dengan pendidikan saya',
                    value: 7.25,
                },
                {
                    label: 'Lainnya',
                    value: 10.14,
                },
            ]
        },
        {
            name: 'Kategori Bidang Pekerjaan Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        22.25,
                        13.86,
                        15.32,
                        7.30,
                        3.12,
                        2.65,
                        35.50
                    ]
                },
            ],
            label: [
                'Teknologi',
                'Konstruksi dan Manufaktur',
                'Pendidikan',
                'Wirausaha',
                'Instansi Pemerintah',
                'Perbankan',
                'Lainnya'
            ],
            chartcolor: [
                '#4CAF50',
                '#F44336',
                '#2196F3',
                '#3F51B5',
                '#FF9800',
                '#3F51B5',
                '#FF9800',
            ],
            legendcolor: [
                'bg-[#4CAF50]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
            ],
        },
        {
            name: 'Grafik Sebaran Responden Tracer Studi',
            chart: [
                {
                    name: 'Total Responden',
                    data: [
                        10,
                        6,
                        21,
                        5,
                        131,
                        109,
                        77,
                        7,
                        112,
                        7,
                        7,
                        17,
                        30,
                        25,
                        50,
                        33,
                        7,
                        76,
                        6,
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S1 Akuntansi',
                'S1 Manajemen',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
                'Program Magister'
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        100,
                        81.42,
                        90.48,
                    ]
                },
            ],
            label: [
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
    ]

    const data2022 = [
        {
            name: 'Status Lulusan Saat Ini',
            chart: [
                {
                    name: 'Jumlah',
                    data: [
                        838,
                        124,
                        16,
                        48,
                        10,
                    ]
                },
            ],
            label: [
                'Bekerja (full time/part time)',
                'Belum memungkinkan bekerja',
                'Wiraswasta',
                'Melanjutkan Pendidikan',
                'Tidak Kerja tetapi sedang mencari',
            ],
            chartcolor: [
                '#068FFF',
                '#4A55A2',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#4A55A2]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Rata-Rata Penghasilan Lulusan',
            value: 'Rp.3.364.189',
            chart: [
                {
                    name: 'Rata-Rata Penghasilan',
                    data: [
                        8300000,
                        4635174,
                        3319706,
                        3308203,
                    ]
                },
            ],
            label: [
                'Doktor/Doktor Terapan/Subspesialis',
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        463,
                        325,
                        70,
                        13,
                        165,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        44.69,
                        31.37,
                        6.76,
                        1.25,
                        15.93,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            chartcolor: [
                '#068FFF',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
                '#9C0F48',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
                'bg-[#9C0F48]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        4,
                        0,
                        0,
                        0,
                        0,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        19,
                        0,
                        0,
                        0,
                        2,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        386,
                        291,
                        55,
                        8,
                        143,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        54,
                        34,
                        15,
                        5,
                        20,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            tooltip: [
                'Doktor',
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Lulusan Mendapatkan Kerja <= 6 Bulan',
            chart: [
                749,
                287,
            ],
            label: [
                'Ya',
                'Tidak',
            ],
            chartcolor: [
                '#068FFF',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Masa Tunggu Lulusan',
            value: 2.35,
            chart: [
                {
                    name: 'Rata-Rata Masa Tunggu',
                    data: [
                        0,
                        1,
                        4.76,
                        3.61,
                    ]
                },
            ],
            label: [
                'Doktor/Doktor Terapan/Subspesialis',
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Jenis Perusahaan Tempat Lulusan Bekerja',
            chart: [
                {
                    name: 'Total',
                    data: [
                        119,
                        67,
                        24,
                        11,
                        698,
                        66,
                        51,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        11,
                        6,
                        2,
                        1,
                        67,
                        6,
                        5,
                    ]
                },
            ],
            label: [
                'Instansi pemerintah',
                'BUMN/BUMD',
                'Institusi/Organisasi Multilateral',
                'Organisasi non-profit/Lembaga Swadaya Masyarakat',
                'Perusahaan swasta',
                'Wiraswasta/perusahaan sendiri',
                'Lainnya, tuliskan:',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#FF55BB',
                '#F86F03',
                '#6A5495',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#FF55BB]',
                'bg-[#F86F03]',
                'bg-[#6A5495]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bila Berwiraswasta, Apa Posisi/Jabatan Lulusan',
            chart: [
                38,
                9,
                4,
                15,
            ],
            label: [
                'Founder',
                'Co-Founder',
                'Staff',
                'Freelance/Kerja Lepas'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                185,
                706,
                145,
            ],
            label: [
                'Lokal/Wilayah/Berwirausaha tidak Berizin',
                'Nasional/Berwirausaha Berizin',
                'Multinasional/Internasional',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                {
                    name: 'Doktor',
                    data: [
                        0,
                        2,
                        2,
                    ]
                },
                {
                    name: 'Magister',
                    data: [
                        0,
                        21,
                        0,
                    ]
                },
                {
                    name: 'Sarjana',
                    data: [
                        124,
                        641,
                        118,
                    ]
                },
                {
                    name: 'Diploma Tiga',
                    data: [
                        61,
                        42,
                        25,
                    ]
                },
            ],
            label: [
                'Lokal/Wilayah/Berwirausaha tidak Berizin',
                'Nasional/Berwirausaha Berizin',
                'Multinasional/Internasional',
            ],
            tooltip: [
                'Doktor',
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Tingkat Pendidikan Apa yang Paling Tepat/Sesuai untuk Pekerjaan Lulusan',
            chart: [
                122,
                808,
                84,
                22,
            ],
            label: [
                'Setingkat Lebih Tinggi',
                'Tingkat yang Sama',
                'Setingkat Lebih Rendah',
                'Tidak Perlu Pendidikan Tinggi'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Sumber Dana dalam Pembiayaan Kuliah',
            chart: [
                {
                    name: 'Total',
                    data: [
                        991,
                        8,
                        15,
                        11,
                        11,
                        0,
                        0,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        95.66,
                        0.77,
                        1.45,
                        1.06,
                        1.06,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                'Biaya Sendiri / Keluarga',
                'Beasiswa ADIK',
                'Beasiswa BIDIKMISI',
                'Beasiswa PPA',
                'Beasiswa AFIRMASI',
                'Beasiswa Perusahaan/Swasta',
                'Lainnya, tuliskan:'
            ],
            chartcolor: [
                '#3F51B5',
                '#FF9800',
                '#2196F3',
                '#4CAF50',
                '#FF9800',
                '#2196F3',
                '#4CAF50',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
                'bg-[#2196F3]',
                'bg-[#4CAF50]',
                'bg-[#FF9800]',
                'bg-[#2196F3]',
                'bg-[#4CAF50]',
            ],
        },
        {
            name: 'Penguasaan Kompetensi Lulusan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 1,
                    val2: 1,
                    val3: 7,
                    val4: 44,
                    val5: 46
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 1,
                    val2: 3,
                    val3: 23,
                    val4: 57,
                    val5: 15
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 1,
                    val2: 9,
                    val3: 45,
                    val4: 31,
                    val5: 13
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 1,
                    val2: 1,
                    val3: 6,
                    val4: 47,
                    val5: 46
                },
                {
                    label: 'Komunikasi',
                    val1: 1,
                    val2: 1,
                    val3: 13,
                    val4: 46,
                    val5: 39
                },
                {
                    label: 'Kerja sama tim',
                    val1: 1,
                    val2: 2,
                    val3: 12,
                    val4: 44,
                    val5: 41
                },
                {
                    label: 'Pengembangan diri',
                    val1: 1,
                    val2: 2,
                    val3: 11,
                    val4: 45,
                    val5: 41
                },
            ]
        },
        {
            name: 'Tingkat Kompetensi yang Diperlukan dalam Pekerjaan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 1,
                    val2: 1,
                    val3: 7,
                    val4: 27,
                    val5: 64
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 1,
                    val2: 4,
                    val3: 16,
                    val4: 40,
                    val5: 39
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 1,
                    val2: 8,
                    val3: 32,
                    val4: 36,
                    val5: 22
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 1,
                    val2: 1,
                    val3: 7,
                    val4: 29,
                    val5: 62
                },
                {
                    label: 'Komunikasi',
                    val1: 1,
                    val2: 1,
                    val3: 4,
                    val4: 30,
                    val5: 64
                },
                {
                    label: 'Kerja sama tim',
                    val1: 1,
                    val2: 1,
                    val3: 4,
                    val4: 34,
                    val5: 60
                },
                {
                    label: 'Pengembangan diri',
                    val1: 1,
                    val2: 1,
                    val3: 5,
                    val4: 34,
                    val5: 58
                },
            ]
        },
        {
            name: 'Seberapa Besar Penekanan pada Metode Pembelajaran di bawah ini Dilaksanakan oleh Program Studi',
            head: [
                'Metode',
                'Sangat Besar',
                'Besar',
                'Cukup Besar',
                'Kurang',
                'Tidak Sama Sekali'
            ],
            row: [
                {
                    label: 'Perkuliahan',
                    val1: 27,
                    val2: 44,
                    val3: 27,
                    val4: 2,
                    val5: 0
                },
                {
                    label: 'Demonstrasi',
                    val1: 10,
                    val2: 34,
                    val3: 39,
                    val4: 13,
                    val5: 3
                },
                {
                    label: 'Partisipasi dalam proyek riset ',
                    val1: 16,
                    val2: 33,
                    val3: 31,
                    val4: 17,
                    val5: 2
                },
                {
                    label: 'Magang',
                    val1: 30,
                    val2: 30,
                    val3: 25,
                    val4: 12,
                    val5: 3
                },
                {
                    label: 'Praktikum',
                    val1: 33,
                    val2: 37,
                    val3: 21,
                    val4: 8,
                    val5: 1
                },
                {
                    label: 'Kerja Lapangan',
                    val1: 32,
                    val2: 30,
                    val3: 21,
                    val4: 13,
                    val5: 4
                },
                {
                    label: 'Diskusi',
                    val1: 34,
                    val2: 39,
                    val3: 24,
                    val4: 3,
                    val5: 0
                },
            ]
        },
        {
            name: 'Kapan Lulusan Mulai Mencari Pekerjaan',
            chart: [
                689,
                339,
                8,
            ],
            label: [
                'Sebelum Lulus',
                'Setelah Lulus',
                'Tidak Mencari Kerja'
            ],
            chartcolor: [
                '#2196F3',
                '#7A9D54',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bagaimana Lulusan Mencari Pekerjaan',
            head: [
                'Info Lowongan Kerja',
                'Persentase',
            ],
            row: [
                {
                    label: 'Iklan di koran , majalah, Brosur',
                    value: 9.56,
                },
                {
                    label: 'Melamar ke perusahaan tanpa mengetahui lowongan yg ada',
                    value: 3.76,
                },
                {
                    label: 'Pergi ke bursa /Pameran kerja',
                    value: 3.57,
                },
                {
                    label: 'Mencari lewat internet / iklan online / milis',
                    value: 29.83,
                },
                {
                    label: 'Dihubungi oleh perusahaan',
                    value: 4.63,
                },
                {
                    label: 'Menghubungi Kemenakertrans',
                    value: 0.87,
                },
                {
                    label: 'Menghubungi agen tenaga kerja komersial/swasta',
                    value: 2.12,
                },
                {
                    label: 'Memperoleh informasi dari pusat/kantor pengembangan karir fakultas/universitas',
                    value: 3.57,
                },
                {
                    label: 'Menghubungi kantor kemahasiswaan/hubungan alumni',
                    value: 10.23,
                },
                {
                    label: 'Membangun jejaring (network) sejak masa kuliah',
                    value: 5.02,
                },
                {
                    label: 'Melalui relasi (misalnya dosen, orang tua, saudara, teman, dll)',
                    value: 18.05,
                },
                {
                    label: 'Membangun bisnis sendiri',
                    value: 3.67,
                },
                {
                    label: 'Melalui penempatan kerja atau magang',
                    value: 3.57,
                },
                {
                    label: 'Bekerja di tempat yang sama dengan tempat kerja semasa kuliah',
                    value: 1.54,
                },
            ]
        },
        {
            name: 'Proses Lulusan Mencari Pekerjaan',
            head: [
                'Instrumen',
                'Rerata jumlah instansi',
            ],
            row: [
                {
                    label: 'Berapa perusahaan/instansi/institusi yang sudah anda lamar sebelum anda memperoleh pekerjaan pertama?  (Rata - rata)',
                    value: 45,
                },
                {
                    label: 'Berapa banyak perusahaan atau instansi yang merespon lamaran anda?',
                    value: 14,
                },
                {
                    label: 'Berapa banyak instansi dan perusahaan yang mengundang anda untuk wawancara?',
                    value: 12,
                },
            ]
        },
        {
            name: 'Apakah Lulusan Aktif Mencari Pekerjaan dalam 4 Minggu Terakhir',
            chart: [
                576,
                107,
                88,
                199,
                66
            ],
            label: [
                'Tidak',
                'Tidak, tapi saya sedang menunggu hasil lamaran kerja',
                'Ya, saya akan mulai bekerja dalam 2 minggu ke depan',
                'Ya, tapi saya belum pasti akan bekerja dalam 2 minggu ke depan',
                'Lainnya'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Jika menurut Anda pekerjaan Anda saat ini tidak sesuai dengan pendidikan Anda, mengapa Anda mengambilnya?',
            head: [
                'Instrumen',
                'Persentase',
            ],
            row: [
                {
                    label: 'Pertanyaan tidak sesuai, pekerjaan saya sekarang sudah sesuai dengan pendidikan saya',
                    value: 6.82,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 8.33,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 8.33,
                },
                {
                    label: 'Saya lebih suka bekerja di area pekerjaan yang tidak ada hubungannya dengan pendidikan saya',
                    value: 9.09,
                },
                {
                    label: 'Saya dipromosikan ke posisi yang kurang berhubungan dengan pendidikan saya dibanding posisi sebelumnya',
                    value: 6.82,
                },
                {
                    label: 'Saya dapat memeroleh pendapatan yang lebih tinggi di pekerjaan ini',
                    value: 8.33,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih aman/terjamin/secure',
                    value: 6.06,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih menarik',
                    value: 6.82,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih memungkinkan saya mengambil pekerjaan tambahan/jadwal yang fleksibel, dll',
                    value: 9.09,
                },
                {
                    label: 'Pekerjaan saya saat ini lokasinya lebih dekat dari rumah saya',
                    value: 6.82,
                },
                {
                    label: 'Pekerjaan saya saat ini dapat lebih menjamin kebutuhan keluarga saya',
                    value: 8.33,
                },
                {
                    label: 'Pada awal meniti karir ini, saya harus menerima pekerjaan yang tidak berhubungan dengan pendidikan saya',
                    value: 6.82,
                },
                {
                    label: 'Lainnya',
                    value: 8.33,
                },
            ]
        },
        {
            name: 'Kategori Bidang Pekerjaan Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        23.40,
                        12.54,
                        9.64,
                        7.82,
                        3.57,
                        2.68,
                        40.25
                    ]
                },
            ],
            label: [
                'Teknologi',
                'Konstruksi dan Manufaktur',
                'Pendidikan',
                'Wirausaha',
                'Instansi Pemerintah',
                'Perbankan',
                'Lainnya'
            ],
            chartcolor: [
                '#4CAF50',
                '#F44336',
                '#2196F3',
                '#3F51B5',
                '#FF9800',
                '#3F51B5',
                '#FF9800',
            ],
            legendcolor: [
                'bg-[#4CAF50]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
            ],
        },
        {
            name: 'Grafik Sebaran Responden Tracer Studi',
            chart: [
                {
                    name: 'Total Responden',
                    data: [
                        62,
                        8,
                        21,
                        37,
                        163,
                        120,
                        9,
                        7,
                        9,
                        210,
                        5,
                        10,
                        44,
                        26,
                        35,
                        71,
                        40,
                        134,
                        21,
                        4,
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Ilmu Hubungan Internasional',
                'S1 Ilmu Komunikasi',
                'S1 Informatika',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Perencanaan Wilayah dan Kota',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Sipil',
                'Program Magister',
                'S3 Ilmu Manajemen'
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
                '#EA906C',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
                'bg-[#EA906C]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        100,
                        90.48,
                        83.76,
                        84.38,
                    ]
                },
            ],
            label: [
                'Doktor/Doktor Terapan/Subspesialis',
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
    ]

    const data2023 = [
        {
            name: 'Status Lulusan Saat Ini',
            chart: [
                {
                    name: 'Jumlah',
                    data: [
                        876,
                        127,
                        27,
                        52,
                        10,
                    ]
                },
            ],
            label: [
                'Bekerja (full time/part time)',
                'Belum memungkinkan bekerja',
                'Wiraswasta',
                'Melanjutkan Pendidikan',
                'Tidak Kerja tetapi sedang mencari',
            ],
            chartcolor: [
                '#068FFF',
                '#4A55A2',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#4A55A2]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Rata-Rata Penghasilan Lulusan',
            value: 'Rp.3.380.450',
            chart: [
                {
                    name: 'Rata-Rata Penghasilan',
                    data: [
                        8650000,
                        5000000,
                        3450125,
                        3308203,
                    ]
                },
            ],
            label: [
                'Doktor/Doktor Terapan/Subspesialis',
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        485,
                        329,
                        87,
                        31,
                        160,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        44.41,
                        30.13,
                        7.97,
                        2.84,
                        14.65,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            chartcolor: [
                '#068FFF',
                '#7AA874',
                '#FF9800',
                '#F24C3D',
                '#9C0F48',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#7AA874]',
                'bg-[#FF9800]',
                'bg-[#F24C3D]',
                'bg-[#9C0F48]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Responden',
                    data: [
                        9,
                        0,
                        0,
                        0,
                        0,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        13,
                        1,
                        0,
                        0,
                        1,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        427,
                        313,
                        76,
                        27,
                        148,
                    ]
                },
                {
                    name: 'Responden',
                    data: [
                        36,
                        15,
                        11,
                        4,
                        11,
                    ]
                },
            ],
            label: [
                'Sangat Erat',
                'Erat',
                'Cukup Erat',
                'Kurang Erat',
                ['Tidak', 'Sama Sekali'],
            ],
            tooltip: [
                'Doktor',
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Lulusan Mendapatkan Kerja <= 6 Bulan',
            chart: [
                808,
                284,
            ],
            label: [
                'Ya',
                'Tidak',
            ],
            chartcolor: [
                '#068FFF',
                '#F24C3D',
            ],
            legendcolor: [
                'bg-[#068FFF]',
                'bg-[#F24C3D]',
            ],
        },
        {
            name: 'Masa Tunggu Lulusan',
            value: 2.29,
            chart: [
                {
                    name: 'Rata-Rata Masa Tunggu',
                    data: [
                        0,
                        1,
                        4.63,
                        3.52,
                    ]
                },
            ],
            label: [
                'Doktor/Doktor Terapan/Subspesialis',
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Jenis Perusahaan Tempat Lulusan Bekerja',
            chart: [
                {
                    name: 'Total',
                    data: [
                        115,
                        114,
                        24,
                        11,
                        732,
                        69,
                        51,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        10.53,
                        10.44,
                        2.20,
                        1.01,
                        67.03,
                        6.32,
                        4.67,
                    ]
                },
            ],
            label: [
                'Instansi pemerintah',
                'BUMN/BUMD',
                'Institusi/Organisasi Multilateral',
                'Organisasi non-profit/Lembaga Swadaya Masyarakat',
                'Perusahaan swasta',
                'Wiraswasta/perusahaan sendiri',
                'Lainnya, tuliskan:',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#FF55BB',
                '#F86F03',
                '#6A5495',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#FF55BB]',
                'bg-[#F86F03]',
                'bg-[#6A5495]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bila Berwiraswasta, Apa Posisi/Jabatan Lulusan',
            chart: [
                37,
                8,
                4,
                20,
            ],
            label: [
                'Founder',
                'Co-Founder',
                'Staff',
                'Freelance/Kerja Lepas'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                164,
                778,
                150,
            ],
            label: [
                'Lokal/Wilayah/Berwirausaha tidak Berizin',
                'Nasional/Berwirausaha Berizin',
                'Multinasional/Internasional',
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
            ],
        },
        {
            name: 'Tingkat Kerja Lulusan',
            chart: [
                {
                    name: 'Doktor',
                    data: [
                        0,
                        7,
                        2,
                    ]
                },
                {
                    name: 'Magister',
                    data: [
                        0,
                        15,
                        0,
                    ]
                },
                {
                    name: 'Sarjana',
                    data: [
                        132,
                        723,
                        127,
                    ]
                },
                {
                    name: 'Diploma Tiga',
                    data: [
                        32,
                        24,
                        21,
                    ]
                },
            ],
            label: [
                'Lokal/Wilayah/Berwirausaha tidak Berizin',
                'Nasional/Berwirausaha Berizin',
                'Multinasional/Internasional',
            ],
            tooltip: [
                'Doktor',
                'Magister',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
        {
            name: 'Tingkat Pendidikan Apa yang Paling Tepat/Sesuai untuk Pekerjaan Lulusan',
            chart: [
                120,
                893,
                61,
                18,
            ],
            label: [
                'Setingkat Lebih Tinggi',
                'Tingkat yang Sama',
                'Setingkat Lebih Rendah',
                'Tidak Perlu Pendidikan Tinggi'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
            ],
        },
        {
            name: 'Sumber Dana dalam Pembiayaan Kuliah',
            chart: [
                {
                    name: 'Total',
                    data: [
                        1039,
                        9,
                        15,
                        17,
                        12,
                        0,
                        0,
                    ]
                },
            ],
            chart2: [
                {
                    name: 'Persentase',
                    data: [
                        95.15,
                        0.82,
                        1.37,
                        1.56,
                        1.10,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                'Biaya Sendiri / Keluarga',
                'Beasiswa ADIK',
                'Beasiswa BIDIKMISI',
                'Beasiswa PPA',
                'Beasiswa AFIRMASI',
                'Beasiswa Perusahaan/Swasta',
                'Lainnya, tuliskan:'
            ],
            chartcolor: [
                '#3F51B5',
                '#FF9800',
                '#2196F3',
                '#4CAF50',
                '#FF9800',
                '#2196F3',
                '#4CAF50',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
                'bg-[#2196F3]',
                'bg-[#4CAF50]',
                'bg-[#FF9800]',
                'bg-[#2196F3]',
                'bg-[#4CAF50]',
            ],
        },
        {
            name: 'Penguasaan Kompetensi Lulusan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 1,
                    val2: 1,
                    val3: 7,
                    val4: 41,
                    val5: 50
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 1,
                    val2: 3,
                    val3: 22,
                    val4: 41,
                    val5: 33
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 1,
                    val2: 9,
                    val3: 42,
                    val4: 26,
                    val5: 21
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 1,
                    val2: 1,
                    val3: 6,
                    val4: 45,
                    val5: 47
                },
                {
                    label: 'Komunikasi',
                    val1: 1,
                    val2: 1,
                    val3: 12,
                    val4: 43,
                    val5: 43
                },
                {
                    label: 'Kerja sama tim',
                    val1: 1,
                    val2: 2,
                    val3: 11,
                    val4: 43,
                    val5: 43
                },
                {
                    label: 'Pengembangan diri',
                    val1: 1,
                    val2: 2,
                    val3: 10,
                    val4: 43,
                    val5: 39
                },
            ]
        },
        {
            name: 'Tingkat Kompetensi yang Diperlukan dalam Pekerjaan',
            head: [
                'Kompetensi',
                'Sangat Rendah',
                'Rendah',
                'Sedang',
                'Tinggi',
                'Sangat Tinggi'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 1,
                    val2: 1,
                    val3: 6,
                    val4: 38,
                    val5: 54
                },
                {
                    label: 'Keahlian berdasarkan bidang ilmu',
                    val1: 1,
                    val2: 3,
                    val3: 15,
                    val4: 39,
                    val5: 41
                },
                {
                    label: 'Bahasa Inggris',
                    val1: 1,
                    val2: 8,
                    val3: 31,
                    val4: 39,
                    val5: 21
                },
                {
                    label: 'Penggunaan Teknologi Informasi',
                    val1: 1,
                    val2: 1,
                    val3: 7,
                    val4: 30,
                    val5: 61
                },
                {
                    label: 'Komunikasi',
                    val1: 1,
                    val2: 1,
                    val3: 4,
                    val4: 45,
                    val5: 50
                },
                {
                    label: 'Kerja sama tim',
                    val1: 1,
                    val2: 1,
                    val3: 3,
                    val4: 46,
                    val5: 49
                },
                {
                    label: 'Pengembangan diri',
                    val1: 1,
                    val2: 1,
                    val3: 5,
                    val4: 47,
                    val5: 46
                },
            ]
        },
        {
            name: 'Seberapa Besar Penekanan pada Metode Pembelajaran di bawah ini Dilaksanakan oleh Program Studi',
            head: [
                'Metode',
                'Sangat Besar',
                'Besar',
                'Cukup Besar',
                'Kurang',
                'Tidak Sama Sekali'
            ],
            row: [
                {
                    label: 'Perkuliahan',
                    val1: 25,
                    val2: 47,
                    val3: 25,
                    val4: 2,
                    val5: 0
                },
                {
                    label: 'Demonstrasi',
                    val1: 11,
                    val2: 36,
                    val3: 37,
                    val4: 13,
                    val5: 3
                },
                {
                    label: 'Partisipasi dalam proyek riset ',
                    val1: 21,
                    val2: 30,
                    val3: 29,
                    val4: 16,
                    val5: 2
                },
                {
                    label: 'Magang',
                    val1: 33,
                    val2: 30,
                    val3: 24,
                    val4: 11,
                    val5: 3
                },
                {
                    label: 'Praktikum',
                    val1: 34,
                    val2: 38,
                    val3: 20,
                    val4: 8,
                    val5: 1
                },
                {
                    label: 'Kerja Lapangan',
                    val1: 32,
                    val2: 32,
                    val3: 20,
                    val4: 13,
                    val5: 4
                },
                {
                    label: 'Diskusi',
                    val1: 32,
                    val2: 42,
                    val3: 23,
                    val4: 3,
                    val5: 0
                },
            ]
        },
        {
            name: 'Kapan Lulusan Mulai Mencari Pekerjaan',
            chart: [
                730,
                353,
                9,
            ],
            label: [
                'Sebelum Lulus',
                'Setelah Lulus',
                'Tidak Mencari Kerja'
            ],
            chartcolor: [
                '#2196F3',
                '#7A9D54',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Bagaimana Lulusan Mencari Pekerjaan',
            head: [
                'Info Lowongan Kerja',
                'Persentase',
            ],
            row: [
                {
                    label: 'Iklan di koran , majalah, Brosur',
                    value: 10.71,
                },
                {
                    label: 'Melamar ke perusahaan tanpa mengetahui lowongan yg ada',
                    value: 3.94,
                },
                {
                    label: 'Pergi ke bursa /Pameran kerja',
                    value: 3.39,
                },
                {
                    label: 'Mencari lewat internet / iklan online / milis',
                    value: 29.21,
                },
                {
                    label: 'Dihubungi oleh perusahaan',
                    value: 4.85,
                },
                {
                    label: 'Menghubungi Kemenakertrans',
                    value: 1.10,
                },
                {
                    label: 'Menghubungi agen tenaga kerja komersial/swasta',
                    value: 2.20,
                },
                {
                    label: 'Memperoleh informasi dari pusat/kantor pengembangan karir fakultas/universitas',
                    value: 3.85,
                },
                {
                    label: 'Menghubungi kantor kemahasiswaan/hubungan alumni',
                    value: 10.35,
                },
                {
                    label: 'Membangun jejaring (network) sejak masa kuliah',
                    value: 5.22,
                },
                {
                    label: 'Melalui relasi (misalnya dosen, orang tua, saudara, teman, dll)',
                    value: 16.67,
                },
                {
                    label: 'Membangun bisnis sendiri',
                    value: 3.66,
                },
                {
                    label: 'Melalui penempatan kerja atau magang',
                    value: 3.57,
                },
                {
                    label: 'Bekerja di tempat yang sama dengan tempat kerja semasa kuliah',
                    value: 1.28,
                },
            ]
        },
        {
            name: 'Proses Lulusan Mencari Pekerjaan',
            head: [
                'Instrumen',
                'Rerata jumlah instansi',
            ],
            row: [
                {
                    label: 'Berapa perusahaan/instansi/institusi yang sudah anda lamar sebelum anda memperoleh pekerjaan pertama?  (Rata - rata)',
                    value: 47,
                },
                {
                    label: 'Berapa banyak perusahaan atau instansi yang merespon lamaran anda?',
                    value: 16,
                },
                {
                    label: 'Berapa banyak instansi dan perusahaan yang mengundang anda untuk wawancara?',
                    value: 13,
                },
            ]
        },
        {
            name: 'Apakah Lulusan Aktif Mencari Pekerjaan dalam 4 Minggu Terakhir',
            chart: [
                785,
                108,
                91,
                108,
                66
            ],
            label: [
                'Tidak',
                'Tidak, tapi saya sedang menunggu hasil lamaran kerja',
                'Ya, saya akan mulai bekerja dalam 2 minggu ke depan',
                'Ya, tapi saya belum pasti akan bekerja dalam 2 minggu ke depan',
                'Lainnya'
            ],
            chartcolor: [
                '#3F51B5',
                '#2196F3',
                '#7A9D54',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#3F51B5]',
                'bg-[#2196F3]',
                'bg-[#7A9D54]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Jika menurut Anda pekerjaan Anda saat ini tidak sesuai dengan pendidikan Anda, mengapa Anda mengambilnya?',
            head: [
                'Instrumen',
                'Persentase',
            ],
            row: [
                {
                    label: 'Pertanyaan tidak sesuai, pekerjaan saya sekarang sudah sesuai dengan pendidikan saya',
                    value: 8,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 9,
                },
                {
                    label: 'Saya belum mendapatkan pekerjaan yang lebih sesuai',
                    value: 9,
                },
                {
                    label: 'Saya lebih suka bekerja di area pekerjaan yang tidak ada hubungannya dengan pendidikan saya',
                    value: 8,
                },
                {
                    label: 'Saya dipromosikan ke posisi yang kurang berhubungan dengan pendidikan saya dibanding posisi sebelumnya',
                    value: 7,
                },
                {
                    label: 'Saya dapat memeroleh pendapatan yang lebih tinggi di pekerjaan ini',
                    value: 6,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih aman/terjamin/secure',
                    value: 6,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih menarik',
                    value: 7,
                },
                {
                    label: 'Pekerjaan saya saat ini lebih memungkinkan saya mengambil pekerjaan tambahan/jadwal yang fleksibel, dll',
                    value: 9,
                },
                {
                    label: 'Pekerjaan saya saat ini lokasinya lebih dekat dari rumah saya',
                    value: 7,
                },
                {
                    label: 'Pekerjaan saya saat ini dapat lebih menjamin kebutuhan keluarga saya',
                    value: 8,
                },
                {
                    label: 'Pada awal meniti karir ini, saya harus menerima pekerjaan yang tidak berhubungan dengan pendidikan saya',
                    value: 7,
                },
                {
                    label: 'Lainnya',
                    value: 9,
                },
            ]
        },
        {
            name: 'Kategori Bidang Pekerjaan Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        24.20,
                        13.23,
                        9.54,
                        7.94,
                        3.05,
                        3.02,
                        39.02
                    ]
                },
            ],
            label: [
                'Teknologi',
                'Konstruksi dan Manufaktur',
                'Pendidikan',
                'Wirausaha',
                'Instansi Pemerintah',
                'Perbankan',
                'Lainnya'
            ],
            chartcolor: [
                '#4CAF50',
                '#F44336',
                '#2196F3',
                '#3F51B5',
                '#FF9800',
                '#3F51B5',
                '#FF9800',
            ],
            legendcolor: [
                'bg-[#4CAF50]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
                'bg-[#3F51B5]',
                'bg-[#FF9800]',
            ],
        },
        {
            name: 'Grafik Sebaran Responden Tracer Studi',
            chart: [
                {
                    name: 'Total Responden',
                    data: [
                        33,
                        4,
                        19,
                        21,
                        143,
                        75,
                        11,
                        9,
                        3,
                        167,
                        4,
                        20,
                        14,
                        46,
                        62,
                        54,
                        30,
                        129,
                        15,
                        9,
                        161,
                        53,
                        10,
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Ilmu Hubungan Internasional',
                'S1 Ilmu Komunikasi',
                'S1 Informatika',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Perencanaan Wilayah dan Kota',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Sipil',
                'Program Magister',
                'S3 Ilmu Manajemen',
                'S1 Manajemen',
                'S1 ',
                'S1 Teknik Komputer',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
                '#EA906C',
                '#2155CD',
                '#FF6000',
                '#5F685A',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
                'bg-[#EA906C]',
                'bg-[#2155CD]',
                'bg-[#FF6000]',
                'bg-[#5F685A]',
            ],
        },
        {
            name: 'Kesesuaian Bidang Kerja Lulusan',
            chart: [
                {
                    name: 'Persentase',
                    data: [
                        100,
                        93.33,
                        85.07,
                        85.71,
                    ]
                },
            ],
            label: [
                'Doktor/Doktor Terapan/Subspesialis',
                'Magister/Magister Terapan/Spesialis',
                'Sarjana',
                'Diploma Tiga',
            ],
            chartcolor: [
                '#4A55A2',
                '#FF9800',
                '#068FFF',
                '#7AA874',
            ],
            legendcolor: [
                'bg-[#4A55A2]',
                'bg-[#FF9800]',
                'bg-[#068FFF]',
                'bg-[#7AA874]',
            ],
        },
    ]

    const [currentData, setCurrentData] = useState(data2020)

    const onChangeYear = (year) => {
        setCurrentYear(year)
        switch (year.value) {
            case 1:
                setCurrentData(data2023)
                break;
            case 2:
                setCurrentData(data2022)
                break;
            case 3:
                setCurrentData(data2021)
                break;
            case 4:
                setCurrentData(data2020)
                break;
            case 5:
                setCurrentData(data2019)
                break;
            default:
                setCurrentData(data2023)
                break;
        }
    }

    const exportTracerStudi = () => {
        FileSaver.saveAs(
            `https://files.uty.ac.id/sialumni/export/rekap_tracer_studi_${currentYear.label}.xlsx`,
        );
    }

    const setupDataTracer = () => {
        setTimeout(() => {
            onDoneLoadData(true)
        }, 500)
    }

    useEffect(() => {
        setupDataTracer()
    }, [])

    return (
        <Column className='gap-5'>
            <Row className='justify-between gap-5'>
                <SelectView width='w-full sm:w-44' initial={currentYear} options={listYear} onChangeOptions={onChangeYear} zindex='z-10' />
                {/* <ButtonHover className='my-auto'>
                    <PrimaryButton onClick={exportTracerStudi} color='indigo' className='py-2 pr-5' leadingIcon={<TbDownload className='h-5 w-5 my-auto' />}>
                        Unduh
                    </PrimaryButton>
                </ButtonHover> */}
            </Row>
            <GridCol className='gap-8'>
                <Card className='w-full h-full p-0 col-span-2'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[21].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='pr-2 pl-1 pt-1'>
                            <BarChart series={currentData[21].chart} height={300} listColors={currentData[21].chartcolor} tooltip={currentData[21].label} />
                        </div>
                        <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                            {currentData[21].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[21].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[0].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='pr-2 pl-1 pt-1'>
                            <BarChart series={currentData[0].chart} height={300} listColors={currentData[0].chartcolor} tooltip={currentData[0].label} />
                        </div>
                        <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                            {currentData[0].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[0].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[1].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <SimpleAlert type='info' className='rounded-none mb-5' borderLeft showCloseBtn>
                            Rata-Rata Penghasilan Lulusan: <span className='font-semibold'>{currentData[1].value}</span>
                        </SimpleAlert>
                        <div className='pr-2 pl-1 pt-1'>
                            <BarChart series={currentData[1].chart} height={300} listColors={currentData[1].chartcolor} tooltip={currentData[1].label} formatRupiah />
                        </div>
                        <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                            {currentData[1].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[1].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0 col-span-2'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[2].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <GridCol className='divide-x-[1px] divide-gray-200 gap-0'>
                            <Column>
                                <div className='pl-1 pt-1'>
                                    <BarChart series={currentData[3].chart} height={310} listColors={currentData[3].chartcolor} showLegend={false} label={currentData[3].label} tooltip={currentData[3].tooltip} groupChart pb={0} />
                                </div>
                                <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                                    {currentData[3].tooltip.map((label, idx) => {
                                        return (
                                            <Row key={idx} className='w-fit gap-1.5'>
                                                <Card className={`${currentData[3].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                                <Overline className='my-auto text-gray-800 font-normal'>
                                                    {label}
                                                </Overline>
                                            </Row>
                                        )
                                    })}
                                </Row>
                            </Column>
                            {/* <Column>
                                <div className='pr-2 pl-1 pt-1'>
                                    <BarChart series={currentData[2].chart2} height={300} listColors={currentData[2].chartcolor} tooltip={currentData[2].label} formatPercent />
                                </div>
                                <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                                    {currentData[2].label.map((label, idx) => {
                                        return (
                                            <Row key={idx} className='w-fit gap-1.5'>
                                                <Card className={`${currentData[2].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                                <Overline className='my-auto text-gray-800 font-normal'>
                                                    {label}
                                                </Overline>
                                            </Row>
                                        )
                                    })}
                                </Row>
                            </Column> */}
                            <Column>
                                <div className='pr-2 pl-1 pt-1'>
                                    <BarChart series={currentData[22].chart} height={300} listColors={currentData[22].chartcolor} showLegend={false} tooltip={currentData[22].label} formatPercent />
                                </div>
                                <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                                    {currentData[22].label.map((label, idx) => {
                                        return (
                                            <Row key={idx} className='w-fit gap-1.5'>
                                                <Card className={`${currentData[22].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                                <Overline className='my-auto text-gray-800 font-normal'>
                                                    {label}
                                                </Overline>
                                            </Row>
                                        )
                                    })}
                                </Row>
                            </Column>
                        </GridCol>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[4].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='my-auto pb-4'>
                            <Chart
                                height={300}
                                type="donut"
                                series={currentData[4].chart}
                                options={{
                                    chart: {
                                        width: 720,
                                        height: 720,
                                        type: 'donut',
                                        fontFamily: 'inter',
                                    },
                                    labels: currentData[4].label,
                                    dataLabels: {
                                        enabled: true,
                                        fontSize: '12px',
                                    },
                                    colors: currentData[4].chartcolor,
                                    legend: {
                                        show: false,
                                        position: 'bottom',
                                        offsetX: 0,
                                    }
                                }} />
                        </div>
                        <div className='gap-x-3 gap-y-1 flex flex-row flex-wrap justify-center pb-5 px-4'>
                            {currentData[4].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[4].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </div>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[5].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <SimpleAlert type='info' className='rounded-none mb-5' borderLeft showCloseBtn>
                            Rata-Rata Keseluruhan Masa Tunggu Alumni: <span className='font-semibold'>{currentData[5].value} Bulan</span>
                        </SimpleAlert>
                        <div className='pr-2 pl-1 pt-1'>
                            <BarChart series={currentData[5].chart} height={300} listColors={currentData[5].chartcolor} tooltip={currentData[5].label} tooltipSuffix=' Bulan' />
                        </div>
                        <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                            {currentData[5].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[5].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[6].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='pr-2 pl-1 pt-1'>
                            <BarChart series={currentData[6].chart2} height={300} listColors={currentData[6].chartcolor} tooltip={currentData[6].label} formatPercent />
                        </div>
                        <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                            {currentData[6].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[6].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[7].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='my-auto pb-4'>
                            <Chart
                                height={300}
                                type="donut"
                                series={currentData[7].chart}
                                options={{
                                    chart: {
                                        width: 720,
                                        height: 720,
                                        type: 'donut',
                                        fontFamily: 'inter',
                                    },
                                    labels: currentData[7].label,
                                    dataLabels: {
                                        enabled: true,
                                        fontSize: '12px',
                                    },
                                    colors: currentData[7].chartcolor,
                                    legend: {
                                        show: false,
                                        position: 'bottom',
                                        offsetX: 0,
                                    }
                                }} />
                        </div>
                        <div className='gap-x-3 gap-y-1 flex flex-row flex-wrap justify-center pb-5 px-4'>
                            {currentData[7].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[7].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </div>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0 col-span-2'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[8].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <GridCol className='divide-x-[1px] divide-gray-200 gap-0'>
                            <Column>
                                <div className='my-auto pb-4'>
                                    <Chart
                                        height={300}
                                        type="donut"
                                        series={currentData[8].chart}
                                        options={{
                                            chart: {
                                                width: 720,
                                                height: 720,
                                                type: 'donut',
                                                fontFamily: 'inter',
                                            },
                                            labels: currentData[8].label,
                                            dataLabels: {
                                                enabled: true,
                                                fontSize: '12px',
                                            },
                                            colors: currentData[8].chartcolor,
                                            legend: {
                                                show: false,
                                                position: 'bottom',
                                                offsetX: 0,
                                            }
                                        }} />
                                </div>
                                <div className='gap-x-3 gap-y-1 flex flex-row flex-wrap justify-center pb-5 px-4'>
                                    {currentData[8].label.map((label, idx) => {
                                        return (
                                            <Row key={idx} className='w-fit gap-1.5'>
                                                <Card className={`${currentData[8].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                                <Overline className='my-auto text-gray-800 font-normal'>
                                                    {label}
                                                </Overline>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </Column>
                            <Column>
                                <div className='pr-2 pl-1 pt-1'>
                                    <BarChart series={currentData[9].chart} height={350} listColors={currentData[9].chartcolor} showLegend={false} label={currentData[9].label} tooltip={currentData[9].tooltip} groupChart pb={30} />
                                </div>
                                <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                                    {currentData[9].tooltip.map((label, idx) => {
                                        return (
                                            <Row key={idx} className='w-fit gap-1.5'>
                                                <Card className={`${currentData[9].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                                <Overline className='my-auto text-gray-800 font-normal'>
                                                    {label}
                                                </Overline>
                                            </Row>
                                        )
                                    })}
                                </Row>
                            </Column>
                        </GridCol>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[10].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='my-auto pb-4'>
                            <Chart
                                height={300}
                                type="donut"
                                series={currentData[10].chart}
                                options={{
                                    chart: {
                                        width: 720,
                                        height: 720,
                                        type: 'donut',
                                        fontFamily: 'inter',
                                    },
                                    labels: currentData[10].label,
                                    dataLabels: {
                                        enabled: true,
                                        fontSize: '12px',
                                    },
                                    colors: currentData[10].chartcolor,
                                    legend: {
                                        show: false,
                                        position: 'bottom',
                                        offsetX: 0,
                                    }
                                }} />
                        </div>
                        <div className='gap-x-3 gap-y-1 flex flex-row flex-wrap justify-center pb-5 px-4'>
                            {currentData[10].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[10].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </div>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[11].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='pr-2 pl-1 pt-1'>
                            <BarChart series={currentData[11].chart2} height={300} listColors={currentData[11].chartcolor} formatPercent />
                        </div>
                        <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                            {currentData[11].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[11].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>
                </Card>
                <Card className='w-full h-full col-span-2'>
                    <Column className='gap-5'>
                        <Column>
                            <Title>
                                {currentData[12].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <Table className='border-[1px] border-gray-200' showPaginationAndSearch={false}>
                            <TableHead>
                                <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                    <TableCell className='font-semibold font-manrope'>
                                        No
                                    </TableCell>
                                    {currentData[12].head.map((head, idx) => {
                                        return (
                                            <TableCell key={idx} className='font-semibold font-manrope'>
                                                {head}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody className='divide-y-[1px] divide-gray-200'>
                                {currentData[12].row.map((row, idx) => {
                                    return (
                                        <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50`}>
                                            <TableCell>
                                                {(idx + 1)}
                                            </TableCell>
                                            <TableCell>
                                                {row.label}
                                            </TableCell>
                                            <TableCell>
                                                {row.val1}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val2}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val3}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val4}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val5}%
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Column>
                </Card>
                <Card className='w-full h-full col-span-2'>
                    <Column className='gap-5'>
                        <Column>
                            <Title>
                                {currentData[13].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <Table className='border-[1px] border-gray-200' showPaginationAndSearch={false}>
                            <TableHead>
                                <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                    <TableCell className='font-semibold font-manrope'>
                                        No
                                    </TableCell>
                                    {currentData[13].head.map((head, idx) => {
                                        return (
                                            <TableCell key={idx} className='font-semibold font-manrope'>
                                                {head}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody className='divide-y-[1px] divide-gray-200'>
                                {currentData[13].row.map((row, idx) => {
                                    return (
                                        <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50`}>
                                            <TableCell>
                                                {(idx + 1)}
                                            </TableCell>
                                            <TableCell>
                                                {row.label}
                                            </TableCell>
                                            <TableCell>
                                                {row.val1}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val2}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val3}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val4}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val5}%
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Column>
                </Card>
                <Card className='w-full h-full col-span-2'>
                    <Column className='gap-5'>
                        <Column>
                            <Title>
                                {currentData[14].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <Table className='border-[1px] border-gray-200' showPaginationAndSearch={false}>
                            <TableHead>
                                <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                    <TableCell className='font-semibold font-manrope'>
                                        No
                                    </TableCell>
                                    {currentData[14].head.map((head, idx) => {
                                        return (
                                            <TableCell key={idx} className='font-semibold font-manrope'>
                                                {head}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody className='divide-y-[1px] divide-gray-200'>
                                {currentData[14].row.map((row, idx) => {
                                    return (
                                        <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50`}>
                                            <TableCell>
                                                {(idx + 1)}
                                            </TableCell>
                                            <TableCell>
                                                {row.label}
                                            </TableCell>
                                            <TableCell>
                                                {row.val1}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val2}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val3}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val4}%
                                            </TableCell>
                                            <TableCell>
                                                {row.val5}%
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[15].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='my-auto pb-4'>
                            <Chart
                                height={300}
                                type="donut"
                                series={currentData[15].chart}
                                options={{
                                    chart: {
                                        width: 720,
                                        height: 720,
                                        type: 'donut',
                                        fontFamily: 'inter',
                                    },
                                    labels: currentData[15].label,
                                    dataLabels: {
                                        enabled: true,
                                        fontSize: '12px',
                                    },
                                    colors: currentData[15].chartcolor,
                                    legend: {
                                        show: false,
                                        position: 'bottom',
                                        offsetX: 0,
                                    }
                                }} />
                        </div>
                        <div className='gap-x-3 gap-y-1 flex flex-row flex-wrap justify-center pb-5 px-4'>
                            {currentData[15].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[15].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </div>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[18].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='my-auto pb-4'>
                            <Chart
                                height={300}
                                type="donut"
                                series={currentData[18].chart}
                                options={{
                                    chart: {
                                        width: 720,
                                        height: 720,
                                        type: 'donut',
                                        fontFamily: 'inter',
                                    },
                                    labels: currentData[18].label,
                                    dataLabels: {
                                        enabled: true,
                                        fontSize: '12px',
                                    },
                                    colors: currentData[18].chartcolor,
                                    legend: {
                                        show: false,
                                        position: 'bottom',
                                        offsetX: 0,
                                    }
                                }} />
                        </div>
                        <div className='gap-x-3 gap-y-1 flex flex-row flex-wrap justify-center pb-5 px-4'>
                            {currentData[18].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[18].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </div>
                    </Column>
                </Card>
                <Card className='w-full h-full col-span-2'>
                    <Column className='gap-5'>
                        <Column>
                            <Title>
                                {currentData[16].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <Table className='border-[1px] border-gray-200' showPaginationAndSearch={false}>
                            <TableHead>
                                <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                    <TableCell className='font-semibold font-manrope'>
                                        No
                                    </TableCell>
                                    {currentData[16].head.map((head, idx) => {
                                        return (
                                            <TableCell key={idx} className='font-semibold font-manrope'>
                                                {head}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody className='divide-y-[1px] divide-gray-200'>
                                {currentData[16].row.map((row, idx) => {
                                    return (
                                        <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50`}>
                                            <TableCell>
                                                {(idx + 1)}
                                            </TableCell>
                                            <TableCell>
                                                {row.label}
                                            </TableCell>
                                            <TableCell>
                                                {row.value}%
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Column>
                </Card>
                <Card className='w-full h-full col-span-2'>
                    <Column className='gap-5'>
                        <Column>
                            <Title>
                                {currentData[17].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <Table className='border-[1px] border-gray-200' showPaginationAndSearch={false}>
                            <TableHead>
                                <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                    <TableCell className='font-semibold font-manrope'>
                                        No
                                    </TableCell>
                                    {currentData[17].head.map((head, idx) => {
                                        return (
                                            <TableCell key={idx} className='font-semibold font-manrope'>
                                                {head}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody className='divide-y-[1px] divide-gray-200'>
                                {currentData[17].row.map((row, idx) => {
                                    return (
                                        <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50`}>
                                            <TableCell>
                                                {(idx + 1)}
                                            </TableCell>
                                            <TableCell>
                                                {row.label}
                                            </TableCell>
                                            <TableCell>
                                                {row.value}%
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Column>
                </Card>
                <Card className='w-full h-full col-span-2'>
                    <Column className='gap-5'>
                        <Column>
                            <Title>
                                {currentData[19].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <Table className='border-[1px] border-gray-200' showPaginationAndSearch={false}>
                            <TableHead>
                                <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                    <TableCell className='font-semibold font-manrope'>
                                        No
                                    </TableCell>
                                    {currentData[19].head.map((head, idx) => {
                                        return (
                                            <TableCell key={idx} className='font-semibold font-manrope'>
                                                {head}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody className='divide-y-[1px] divide-gray-200'>
                                {currentData[19].row.map((row, idx) => {
                                    return (
                                        <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50`}>
                                            <TableCell>
                                                {(idx + 1)}
                                            </TableCell>
                                            <TableCell>
                                                {row.label}
                                            </TableCell>
                                            <TableCell>
                                                {row.value}%
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0 col-span-2'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[20].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='pr-2 pl-1 pt-1'>
                            <BarChart series={currentData[20].chart} height={300} listColors={currentData[20].chartcolor} tooltip={currentData[20].label} formatPercent />
                        </div>
                        <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                            {currentData[20].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[20].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>
                </Card>
            </GridCol>
        </Column>
    )
}

export default OvTracerIPT
