import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import {
  refreshTokenApiUrl,
  refreshTokenKey,
  resetTokenKey,
  tokenApiKey,
} from "../shared/variable";

const getToken = async () => {
  const cookies = new Cookies();
  const currentDate = new Date();
  const tokenCookies = cookies.get(refreshTokenKey) ?? null;
  const token = localStorage.getItem(tokenApiKey) ?? null;
  if (tokenCookies !== null) {
    if (token !== null) {
      const auth = jwtDecode(token);
      const { exp, username } = auth;
      if (exp * 1000 < currentDate.getTime()) {
        const { data } = await axios.get(
          `/api${refreshTokenApiUrl}=${username}`
        );
        localStorage.setItem(tokenApiKey, JSON.stringify(data.token));
        return data.token;
      }
      return token;
    }
  }
};

const getResetToken = () => {
  const token = localStorage.getItem(resetTokenKey);
  if (token !== null) {
    return token;
  }
};

export const axios_post = async (
  url,
  body,
  useHeader = false,
  header,
  params
) => {
  const token = await getToken();

  let requestHeader;

  if (useHeader) {
    const staticHeader = {
      Authorization: `Bearer ${token.replaceAll('"', "")}`,
    };
    requestHeader = { ...staticHeader, ...header };
  }

  try {
    const result = await axios.post(
      `/api${url}`,
      body,
      useHeader && {
        headers: requestHeader,
        params: params,
      }
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return {
        status: false,
        message: "Something went wrong!",
      };
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error.response.data;
  }
};

export const axios_post_reset = async (
  url,
  body,
  useHeader = false,
  header,
  params
) => {
  const token = await getResetToken();

  let requestHeader;

  if (useHeader) {
    const staticHeader = {
      Authorization: `Bearer ${token.replaceAll('"', "")}`,
    };
    requestHeader = { ...staticHeader, ...header };
  }

  try {
    const result = await axios.post(
      `/api${url}`,
      body,
      useHeader && {
        headers: requestHeader,
        params: params,
      }
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return {
        status: false,
        message: "Something went wrong!",
      };
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error.response.data;
  }
};

export const axios_put = async (
  url,
  body,
  useHeader = false,
  header,
  params
) => {
  const token = await getToken();

  let requestHeader;

  if (useHeader) {
    const staticHeader = {
      Authorization: `Bearer ${token.replaceAll('"', "")}`,
    };
    requestHeader = { ...staticHeader, ...header };
  }

  try {
    const result = await axios.put(
      `/api${url}`,
      body,
      useHeader && {
        headers: requestHeader,
        params: params,
      }
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return {
        status: false,
        message: "Something went wrong!",
      };
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error.response.data;
  }
};

export const axios_get = async (url, header, params, responseType = "json") => {
  const token = (await getToken()) ?? "";

  const staticHeader = {
    Authorization: `Bearer ${token.replaceAll('"', "")}`,
  };

  const requestHeader = { ...staticHeader, ...header };

  try {
    const result = await axios.get(`/api${url}`, {
      headers: requestHeader,
      params: params,
      responseType: responseType,
    });
    if (result.status === 200) {
      return result.data;
    } else {
      return {
        status: false,
        message: "Something went wrong!",
      };
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error.response.data;
  }
};

export const axios_delete = async ({ url, body = {} }) => {
  const token = await getToken();

  const staticHeader = {
    Authorization: `Bearer ${token.replaceAll('"', "")}`,
  };

  try {
    const result = await axios.delete(`/api${url}`, {
      headers: staticHeader,
      data: body,
    });
    if (result.status === 200) {
      return result.data;
    } else {
      return {
        status: false,
        message: "Something went wrong!",
      };
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error.response.data;
  }
};
