import React, { useEffect, useState } from 'react'
import { TbCircle, TbCircleCheckFilled } from 'react-icons/tb'
import { Column, Row } from '../../../../components/interfaces/block'
import { BodyText, CaptionText } from '../../../../components/interfaces/text'

function ItemCounselor({ counselor, initial, onClick }) {

  const [selected, setSelected] = useState(false)

  const onHandleSelected = () => {
    setSelected(!selected)
    onClick(counselor)
  }

  useEffect(() => {
    setSelected(initial)
  }, [initial])

  return (
    <Row
      className={`px-4 py-2 items-center cursor-pointer border-l-[3px] transition-all ${selected ? 'border-l-indigo-500' : 'border-l-transparent hover:bg-gray-100'}`}
      onClick={onHandleSelected}>
      <Column className={'flex-1'}>
        <BodyText className={'capitalize line-clamp-1'}>
          {counselor.nama.toLowerCase()}
        </BodyText>
        <CaptionText>
          {counselor.nik}
        </CaptionText>
      </Column>
      {
        selected ? <TbCircleCheckFilled className={'h-5 w-5 text-indigo-600'} /> :
          <TbCircle className={'h-5 w-5 text-gray-400'} />}
    </Row>
  )
}

export default ItemCounselor