import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { TbTrash, TbX } from 'react-icons/tb'
import { Column, Row } from '../../../../components/interfaces/block'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import { BodyText, Headline3Text } from '../../../../components/interfaces/text'
import { deleteCertificateController } from '../../../../controllers/admin_controllers'
import { AlertDialog, ButtonHover, LoadingDialog, PrimaryButton, TertiaryButton } from '../../../../shared/ui'
import RowTableUnivCertificate from './rows/row_univ_certificate'

function TableUnivCertificate({ listCertificate, page, limit, onShort, onRefresh }) {

  const [currentCertif, setCurrentCertif] = useState(null)
  const [currentSort, setCurrentSort] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const onHandleClick = (certificate) => {
    setCurrentCertif(certificate)
    setShowDialog(true)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  const deleteCertificate = async () => {
    setShowDialog(false)
    setLoading(true)

    const props = {
      action: 20,
      tahun: currentCertif.tahun,
    }
    const result = await deleteCertificateController(props)
    if (result.status) {
      onRefresh(true)
      setTimeout(() => {
        setLoading(false)
        setCurrentCertif(null)
        toast.success(result.message)
      }, 200)
    } else {
      setTimeout(() => {
        setLoading(false)
        toast.error(result.message)
      }, 200)
    }
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              No
            </TableHeadCell>
            <TableHeadCell>
              Institusi
            </TableHeadCell>
            <TableHeadCell>
              Sertifikat
            </TableHeadCell>
            <TableHeadCell>
              Tahun
            </TableHeadCell>
            <TableHeadCell>
              Waktu Unggah
            </TableHeadCell>
            <TableHeadCell className={'text-center'}>
              Aksi
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listCertificate.length > 0 ? listCertificate.map((certificate, idx) => {
            return (
              <RowTableUnivCertificate
                key={idx}
                idx={idx}
                page={page}
                limit={limit}
                certificate={certificate}
                handleClick={onHandleClick} />
            )
          }) : <TableRow>
            <TableCell colSpan={5} className='text-center text-gray-500'>
              No data to display
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
      <AlertDialog
        show={showDialog}
        className={'max-w-md'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3Text>
              Hapus Serifikat
            </Headline3Text>
            {currentCertif && <BodyText>
              Apakah anda yakin ingin menghapus Sertifikat Akreditasi Institusi tahun <span className={'font-medium text-red-600'}>{currentCertif.tahun}</span>?
            </BodyText>}
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='red'
                className='pl-5 pr-6'
                leadingIcon={
                  <TbTrash className='h-5 w-5 my-auto' />
                }
                onClick={deleteCertificate}>
                Hapus
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Menghapus sertifikat...' />
    </>
  )
}

export default TableUnivCertificate