import { useEffect, useState } from "react";
import { TbArrowDown, TbArrowUp, TbArrowsSort } from "react-icons/tb";
import { Row } from "./block";

export const Table = ({ id, refId, children, className = null }) => {
  return (
    <div className={`table-style-new ${className}`}>
      <table id={id} ref={refId} className={`w-full`}>
        {children}
      </table>
    </div>
  );
};

export const TableHead = ({ id, refId, children, className = null }) => {
  return (
    <thead id={id} ref={refId} className={`table-head-style-new ${className}`}>
      {children}
    </thead>
  );
};

export const TableBody = ({ id, refId, children, className = null }) => {
  return (
    <tbody id={id} ref={refId} className={`table-body-style-new ${className}`}>
      {children}
    </tbody>
  );
};

export const TableRow = ({
  id,
  refId,
  children,
  className = null,
  striped = false,
  onClick = null,
}) => {
  return (
    <tr
      id={id}
      ref={refId}
      className={`table-row ${striped && "even:bg-gray-50 odd:bg-white"} ${
        onClick !== null && "cursor-pointer hover:bg-gray-100"
      } ${className}`}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};

export const TableHeadCell = ({
  id,
  refId,
  colSpan,
  children,
  className = null,
  sortable = false,
  sortkey = null,
  currentSortKey = null,
  onShortable = null,
}) => {
  const [shortType, setShortType] = useState(null);

  const handleSortable = () => {
    if (shortType === null) {
      onShortable({
        key: sortkey,
        type: "ASC",
      });
      setShortType("ASC");
    } else if (shortType === "DESC") {
      onShortable({
        key: sortkey,
        type: "ASC",
      });
      setShortType("ASC");
    } else {
      onShortable({
        key: sortkey,
        type: "DESC",
      });
      setShortType("DESC");
    }
  };

  useEffect(() => {
    if (sortkey !== currentSortKey) {
      setShortType(null);
    }
  }, [currentSortKey]);

  useEffect(() => {}, [shortType]);

  return (
    <th
      id={id}
      ref={refId}
      colSpan={colSpan}
      className={`table-cell-style-new font-medium group ${
        sortable && "cursor-pointer hover:text-indigo-600"
      } ${className}`}
      onClick={sortable ? handleSortable : null}
    >
      {sortable ? (
        <Row className="gap-x-2">
          {children}
          <div className="flex items-center ml-auto">
            {shortType === "ASC" ? (
              <TbArrowUp className="h-4 w-4 text-indigo-700" />
            ) : shortType === "DESC" ? (
              <TbArrowDown className="h-4 w-4 text-indigo-700" />
            ) : (
              <TbArrowsSort className="h-4 w-4 text-gray-500 group-hover:text-indigo-600" />
            )}
          </div>
        </Row>
      ) : (
        children
      )}
    </th>
  );
};

export const TableHeadCell2 = ({
  id,
  refId,
  colSpan,
  children,
  className = null,
  sortable = false,
  sortkey = null,
  currentSortKey = null,
  onShortable = null,
}) => {
  const [shortType, setShortType] = useState(null);

  const handleSortable = () => {
    if (shortType === null) {
      onShortable({
        key: sortkey,
        type: "DESC",
      });
      setShortType("DESC");
    } else if (shortType === "ASC") {
      onShortable({
        key: sortkey,
        type: "DESC",
      });
      setShortType("DESC");
    } else {
      onShortable({
        key: sortkey,
        type: "ASC",
      });
      setShortType("ASC");
    }
  };

  useEffect(() => {
    if (sortkey !== currentSortKey) {
      setShortType(null);
    }
  }, [currentSortKey]);

  useEffect(() => {}, [shortType]);

  return (
    <th
      id={id}
      ref={refId}
      colSpan={colSpan}
      className={`table-cell-style-new font-medium group ${
        sortable && "cursor-pointer hover:text-indigo-600"
      } ${className}`}
      onClick={sortable ? handleSortable : null}
    >
      {sortable ? (
        <Row className="gap-x-2">
          {children}
          <div className="flex items-center ml-auto">
            {shortType === "ASC" ? (
              <TbArrowUp className="h-4 w-4 text-indigo-700" />
            ) : shortType === "DESC" ? (
              <TbArrowDown className="h-4 w-4 text-indigo-700" />
            ) : (
              <TbArrowsSort className="h-4 w-4 text-gray-500 group-hover:text-indigo-600" />
            )}
          </div>
        </Row>
      ) : (
        children
      )}
    </th>
  );
};

export const TableCell = ({
  id,
  refId,
  colSpan,
  children,
  className = null,
}) => {
  return (
    <th
      id={id}
      ref={refId}
      colSpan={colSpan}
      className={`table-cell-style-new ${className}`}
    >
      {children}
    </th>
  );
};
