import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbAlertTriangle, TbPencil, TbPlus, TbSend, TbUpload, TbX } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { aLegalitasB2B, aOpsiLegalitasB2B } from '../atoms'
import { getLegalitasB2BController, saveB2BController, uploadLegalitasB2BController } from '../controllers/alumni_controllers'
import { AlertDialog, ButtonHover, Caption, Card, Column, Form, Headline3, LoadingDialog, OutlineInput, PrimaryButton, Row, SecondaryButton, SelectView, Text, TextButton, TextEditor } from '../shared/ui'

const WAlmB2bCreate = ({ onSaved }) => {

    const [listLegalitas, setListLegalitas] = useRecoilState(aLegalitasB2B)
    const [opsiLegalitas, setOpsiLegalitas] = useRecoilState(aOpsiLegalitasB2B)
    const [initialType, setInitialType] = useState({ label: 'Pilih Jenis Postingan', value: null })
    const [initialCategory, setInitialCategory] = useState({ label: 'Pilih Kategori', value: null })
    const [initialLegalitas, setInitialLegalitas] = useState({ label: 'Pilih Legalitas', value: null })
    const [showUploadDialog, setShowUploadDialog] = useState(false)
    const [showLargeDialog, setShowLargeDialog] = useState(false)
    const [currentDoc, setCurrentDoc] = useState(null)
    const [errorDoc, setErrorDoc] = useState(null)
    const [loading, setLoading] = useState(false)

    const opsiType = [{ label: 'Produk', value: 'PRODUK' }, { label: 'Jasa', value: 'JASA' },]
    const opsiCategory = [{ label: 'Kebutuhan', value: 'KEBUTUHAN' }, { label: 'Penawaran', value: 'PENAWARAN' },]

    const getLegalitasB2B = async () => {
        const result = await getLegalitasB2BController()
        if (result.status) {
            const listLegalitasTemp = [...result.data]
            if (listLegalitasTemp.length > 0) {
                const legalitasTemp = []
                listLegalitasTemp.map((legalitas) => {
                    const legal = {
                        label: legalitas.nama_usaha,
                        value: legalitas.id_siu,
                    }
                    legalitasTemp.push(legal)
                })
                setOpsiLegalitas(legalitasTemp)
            }
            setListLegalitas(listLegalitasTemp)
        }
    }

    const onSubmitForm = async (event) => {
        event.preventDefault()
        const title = event.target.title.value
        const desc = event.target.desc.value

        if (title && initialType.value !== null && initialCategory.value !== null && desc && initialLegalitas.value !== null) {
            setLoading(true)
            const props = {
                title: title,
                type: initialType.value,
                category: initialCategory.value,
                desc: desc,
                legalitas: [initialLegalitas.value]
            }
            const result = await saveB2BController(props)
            if (result.status) {
                setTimeout(() => {
                    onSaved()
                    setLoading(false)
                    toast.success(result.message)
                }, 200)
            } else {
                setTimeout(() => {
                    setLoading(false)
                    toast.error(result.message)
                }, 200)
            }
        }
    }

    const onChangeDocument = (event) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const size = file.size

            if ((size / 1024) <= 2048) {
                setCurrentDoc(file)
            } else {
                setCurrentDoc(null)
                setShowLargeDialog(true)
            }
        }
    }

    const onSubmitFormLegalitas = async (event) => {
        event.preventDefault()
        const name = event.target.name.value
        const type = event.target.type.value
        const doc = event.target.doc.value

        if (name && type && doc) {
            setShowUploadDialog(false)
            setLoading(true)
            const props = {
                name: name,
                type: type,
                doc: currentDoc
            }
            const result = await uploadLegalitasB2BController(props)
            if (result.status) {
                setTimeout(() => {
                    getLegalitasB2B()
                    setLoading(false)
                    toast.success(result.message)
                }, 200)
            } else {
                setTimeout(() => {
                    setLoading(false)
                    toast.error(result.message)
                }, 200)
            }
        }
    }

    const chooseDocument = (action) => {
        setCurrentDoc(null)
        setErrorDoc(null)
        setShowUploadDialog(true)
    }

    useEffect(() => {
        getLegalitasB2B()
    }, [])

    return (
        <>
            <Card className='w-full p-8 shadow-lg shadow-gray-200'>
                <Form onSubmit={onSubmitForm}>
                    <Column className='gap-y-8'>
                        <Column className='gap-y-1'>
                            <Headline3>
                                Buat Postingan Baru
                            </Headline3>
                            <Text>
                                Lengkapi semua isian formulir untuk membuat postingan baru
                            </Text>
                        </Column>
                        <OutlineInput
                            id='title'
                            className='py-3'
                            inputWidth='w-full'
                            label='Judul Postingan'
                            bgColor='bg-slate-50'
                            placeholder='Masukkan judul postingan'
                            helperText='Contoh: Mencari Supli Batu Bara'
                            required />
                        <Row className='gap-x-8'>
                            <SelectView
                                width='w-full'
                                label='Jenis Postingan'
                                helperText='Pilih salah satu'
                                initial={initialType}
                                options={opsiType}
                                bgColor='bg-slate-50'
                                py='py-3'
                                pl='pl-4'
                                pr='pr-3'
                                zindex='z-20'
                                required
                                onChangeOptions={(value) => setInitialType(value)} />
                            <SelectView
                                width='w-full'
                                label='Kategori Postingan'
                                helperText='Pilih salah satu'
                                initial={initialCategory}
                                options={opsiCategory}
                                bgColor='bg-slate-50'
                                py='py-3'
                                pl='pl-4'
                                pr='pr-3'
                                required
                                onChangeOptions={(value) => setInitialCategory(value)} />
                        </Row>
                        <TextEditor height={300} id='desc' label='Deskripsi Postingan' showMenuBar={false} required />
                        {listLegalitas.length > 0 ? <SelectView
                            width='w-full'
                            label='Legalitas Usaha'
                            helperText='Pilih salah satu'
                            initial={initialLegalitas}
                            options={opsiLegalitas}
                            bgColor='bg-slate-50'
                            py='py-3'
                            pl='pl-4'
                            pr='pr-3'
                            required
                            onChangeOptions={(value) => setInitialLegalitas(value)} /> : <Column className='w-full gap-1.5 '>
                            <label className='caption'>
                                Dokumen Legalitas <span className='text-red-500'>*</span>
                            </label>
                            <Card onClick={chooseDocument} className='w-full flex flex-row items-center gap-x-3 px-4 py-3 bg-slate-50 rounded-lg border-[1px] cursor-pointer'>
                                <Row className='gap-x-3 items-center'>
                                    <div className='p-1 bg-indigo-100 rounded-full border border-indigo-200'>
                                        <TbPlus className='h-5 w-5 translate-x-[0.2px] text-indigo-600' />
                                    </div>
                                    <Text className='text-gray-400'>
                                        Tambah Dokumen Legalitas
                                    </Text>
                                </Row>
                            </Card>
                        </Column>}
                        <Row className='gap-x-3'>
                            <ButtonHover>
                                <PrimaryButton color='indigo' type='submit' leadingIcon={<TbSend className='h-5 w-5 rotate-12 my-auto' />} className='pr-5'>
                                    Kirim Postingan
                                </PrimaryButton>
                            </ButtonHover>
                        </Row>
                    </Column>
                </Form>
            </Card>
            <AlertDialog className='max-w-2xl' show={showUploadDialog} onClose={() => setShowUploadDialog(false)}>
                <Form onSubmit={onSubmitFormLegalitas}>
                    <Column className='p-8 gap-y-8'>
                        <Column className='gap-y-1'>
                            <Headline3>
                                Legalitas Usaha
                            </Headline3>
                            <Text>
                                Legalitas yang diunggah akan disimpan dan dapat digunakan sebagai pendukung Legalitas Postingan Business to Business
                            </Text>
                        </Column>
                        <OutlineInput
                            id='name'
                            className='py-3'
                            inputWidth='w-full'
                            label='Nama Usaha'
                            placeholder='Masukkan nama usaha'
                            helperText='Contoh: GMEDIA Digital Service Provider'
                            required />
                        <OutlineInput
                            id='type'
                            className='py-3'
                            inputWidth='w-full'
                            label='Jenis Usaha'
                            placeholder='Masukkan jenis usaha'
                            helperText='Contoh: Service Provider'
                            required />
                        <Column className='w-full gap-1.5'>
                            <label className='caption'>
                                Dokumen Legalitas Usaha <span className='text-red-500'>*</span>
                            </label>
                            <input
                                id='doc'
                                type='file'
                                className='hidden'
                                onChange={onChangeDocument}
                                accept='image/*,application/pdf' />
                            <label htmlFor="doc" className={`w-full flex flex-row items-center gap-x-3 px-4 py-3 bg-white rounded-lg border-[1px] cursor-pointer ${errorDoc && 'border-red-300'}`}>
                                <div className='p-1 bg-indigo-100 rounded-full border border-indigo-200'>
                                    {currentDoc ? <TbPencil className='h-5 w-5 translate-x-[0.2px] text-indigo-600' /> : <TbPlus className='h-5 w-5 translate-x-[0.2px] text-indigo-600' />}
                                </div>
                                {currentDoc ? <span className='text-gray-800 line-clamp-1'>
                                    {currentDoc.name}
                                </span> : <span className='text-gray-400'>
                                    Pilih Dokumen Legalitas
                                </span>}
                            </label>
                            <Caption className={`px-4 ${errorDoc !== null && 'text-red-600'}`}>
                                {errorDoc !== null ? errorDoc : ' Maksimal ukuran Dokumen tidak boleh lebih dari 2MB'}
                            </Caption>
                        </Column>
                        <Row className='gap-x-3 justify-end'>
                            <ButtonHover>
                                <SecondaryButton onClick={() => setShowUploadDialog(false)} className='pr-5' leadingIcon={<TbX className='h-5 w-5 my-auto' />}>
                                    Batal
                                </SecondaryButton>
                            </ButtonHover>
                            <ButtonHover>
                                <PrimaryButton type='submit' className='pr-5' color='indigo' leadingIcon={<TbUpload className='h-5 w-5 my-auto' />}>
                                    Unggah
                                </PrimaryButton>
                            </ButtonHover>
                        </Row>
                    </Column>
                </Form>
            </AlertDialog>
            <AlertDialog className='max-w-xs' show={showLargeDialog} onClose={() => setShowLargeDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 pt-10 pb-8 gap-8 items-center'>
                        <Card className='p-1.5 rounded-2xl bg-orange-50/50 border-orange-200/50'>
                            <TbAlertTriangle className='h-14 w-14 stroke-1.5 text-orange-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Perhatian!
                            </Headline3>
                            <Text className='text-center'>
                                Maksimal ukuran Dokumen tidak boleh lebih dari 2MB
                            </Text>
                        </Column>
                    </Column>
                    <TextButton onClick={() => { setShowLargeDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                        Tutup
                    </TextButton>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loading} message='Menyimpan legalitas...' />
        </>
    )
}

export default WAlmB2bCreate