import React, { useEffect } from 'react'
import { useLoaderData } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { readNotifications, unreadNotifications } from '../../atoms'
import { getNotificationsController, readNotificationsControllers } from '../../controllers/global_controllers'

function KonNotificationPage() {

  const result = useLoaderData()

  const [readNotification, setReadNotification] = useRecoilState(readNotifications)
  const [unreadNotification, setUnreadNotification] = useRecoilState(unreadNotifications)

  const getNotificationFromLoader = () => {
    if (result.status) {
      const listNotification = [...result.data]
      const unread = listNotification.filter((notif) => notif.is_read === false)
      const read = listNotification.filter((notif) => notif.is_read === true)
      setUnreadNotification(unread)
      setReadNotification(read)
    }
  }

  const onReadNotifications = async (action, notif) => {
    const result = await readNotificationsControllers(notif.id_notifikasi)
    if (result.status) {
      if (action === 10) {
        const readTemp = [...readNotification, notif]
        const newUnread = unreadNotification.filter((unread) => unread.id_notifikasi !== notif.id_notifikasi)
        const sortRead = readTemp.sort((a, b) => b.id_notifikasi - a.id_notifikasi)
        setUnreadNotification(newUnread)
        setReadNotification(sortRead)
      }
    }
  }

  useEffect(() => {
    getNotificationFromLoader()
  }, [])

  return (
    <></>
  )
}

export default KonNotificationPage

export const getKonNotifications = async () => {
  const result = await getNotificationsController()
  return result
}