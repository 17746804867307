import React, { useContext } from "react";
import { KuesionerContext } from ".";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Column,
  Form,
  Headline2,
  HorizontalDivider,
  InputRadioButtonGrid,
  InputTextArea,
  OutlineInput,
  PrimaryButton,
  Row,
  SelectInput,
  TertiaryButton,
  Text,
  Title,
  TransitionPage,
} from "../../shared/ui";
import {
  TbChecks,
  TbChevronsLeft,
  TbChevronsRight,
  TbCircleArrowLeftFilled,
  TbCircleArrowRightFilled,
  TbX,
} from "react-icons/tb";
import imgGraduation from "../../images/graduation.jpg";
import logoUty from "../../images/logo2.png";
import logoUty2 from "../../images/logo.png";
import {
  BodyText,
  CaptionText,
  Headline3Text,
} from "../../components/interfaces/text";

const KuesionerPageLayout = () => {
  const {
    selectedEtika,
    selectedKeahlian,
    selectedBahasa,
    selectedInformasi,
    selectedKomunikasi,
    selectedKerjasama,
    selectedPengembangan,
    selectedKesiapan,
    optionProdi,
    optionTahun,
    optionBulan,
    initProdi,
    initTahun,
    initBulan,
    step,
    formData,
    option,
    handleSelectChange,
    handleRadioChange,
    nextStep,
    previousStep,
    handleInputChange,
    handleSubmit,
    showDialogInput,
    closeDialogInput,
    showDialogSubmitFailed,
    showDialogSubmitSuccess,
    closeDialogSubmitFailed,
    closeDialogSubmitSuccess,
    message,
    num1,
    num2,
    captcaInput,
    setCaptchaInput,
    backSubmit,
    showDialogCaptcha,
    closeDialogCaptcha,
  } = useContext(KuesionerContext);

  return (
    <>
      <div className="grid md:grid-cols-3 h-screen ">
        <div className=" bg-gradient-to-t from-blue-800 to-cyan-300 hidden md:inline h-screen">
          <div className="relative ">
            <div className="relative">
              <img
                src={imgGraduation}
                alt="uty"
                className="min-h-screen opacity-50 z-10 "
              />
              <img
                src={logoUty}
                alt="logoUty"
                className="absolute top-6 left-6 w-auto h-10 z-20"
              />
            </div>
            <div className="absolute top-0 left-0 h-screen">
              <div className="flex justify-end items-end p-6 space-y-10 h-screen">
                <div className="space-y-4 mb-10">
                  <div>
                    <Headline2 className={"text-white"}>
                      Kuesioner Pengguna
                    </Headline2>
                    <Headline2 className={"text-white"}>Lulusan</Headline2>
                  </div>

                  <Text className={"text-white"}>
                    Selamat datang pada halaman form kuesioner pengguna lulusan.
                    Form kuesioner ini digunakan untuk mengumpulkan data lulusan
                    dari Universitas Teknologi Yogyakarta.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Column className="md:col-span-2 bg-none h-screen overflow-y-auto scrollbar-hide ">
          <Form onSubmit={handleSubmit}>
            {step === 1 && (
              <TransitionPage>
                <Column className="min-h-screen py-6 px-6 md:px-16 space-y-4">
                  <div className="flex justify-center">
                    <img src={logoUty2} alt="logo" className="h-28" />
                  </div>
                  <Column className="px-6">
                    <hr className="w-full h-[0.1rem] bg-gradient-to-r from-white via-slate-600 to-white my-4" />
                  </Column>
                  <Column>
                    <Text className="font-semibold">Kepada Yth. </Text>
                    <Text>Pengguna Lulusan UTY</Text>
                  </Column>

                  <Text className="text-justify">
                    Dalam rangka meningkatkan kualitas lulusan Universitas
                    Teknologi Yogyakarta, kami melakukan survey terhadap
                    pengguna lulusan sebagai salah satu indikator evaluasi
                    perbaikan Universitas Teknologi Yogyakarta. Kami sangat
                    mengharapkan kesediaan Bapak/Ibu untuk dapat memberikan
                    penilaian terhadap lulusan Universitas Teknologi Yogyakarta
                    yang bekerja pada instansi atau perusahaan yang Bapak/Ibu
                    pimpin dengan cara mengisi kuisioner pengguna lulusan
                    Universitas Teknologi Yogyakarta berikut ini.
                  </Text>
                  <Text className="text-justify">
                    Penilaian ini akan menjadi umpan balik bagi kami dalam
                    melakukan evaluasi terhadap lulusan Universitas Teknologi
                    Yogyakarta. Penilaian dan masukan yang Bapak/Ibu berikan
                    sangat berharga bagi kami dan kami akan menjaga kerahasiaan
                    informasi yang diberikan tersebut.
                  </Text>
                  <Text className="text-justify">
                    Atas nama Rektor Universitas Teknologi Yogyakarta, kami
                    mengucapkan terima kasih yang sebesar-besarnya atas
                    kesediaan Bapak/Ibu meluangkan waktu sejenak untuk
                    berpartisapasi dalam pengisian survey pengguna lulusan
                    Universitas Teknologi Yogyakarta.
                  </Text>
                  <Column>
                    <Text>Hormat Kami</Text>
                    <Text>Tim Tracer Study</Text>
                    <Text className="font-semibold">
                      Universitas Teknologi Yogyakarta
                    </Text>
                  </Column>

                  <Row className="flex justify-end items-center flex-1">
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"red"}
                        trailingIconLeft={
                          <TbChevronsLeft className="w-6 h-6" />
                        }
                        trailingIcon={<TbChevronsRight className="w-6 h-6" />}
                      >
                        Mulai
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 2 && (
              <TransitionPage>
                <Column className="min-h-screen p-6 ">
                  <Column className={"h-fit bg-white rounded-xl border"}>
                    <Column className={"px-6 py-4 space-y-1"}>
                      <Title>Identitas Pengisi Perusahaan</Title>
                      <CaptionText>Lengkapi data pengisi</CaptionText>
                    </Column>

                    <HorizontalDivider removeMargin />
                    <Column className={"p-6 space-y-6 pb-9"}>
                      <OutlineInput
                        label="Nama Pengisi"
                        labelClassName={"text-gray-600"}
                        placeholder="Masukkan nama pengisi"
                        name="pengisi_nama"
                        defaultValue={formData.pengisi_nama}
                        onChange={handleInputChange}
                        inputWidth={"w-full"}
                        required
                      />
                      <OutlineInput
                        label="Nama Perusahaan/Instansi"
                        labelClassName={"text-gray-600"}
                        placeholder="Masukkan nama Perusahaan/Instansi"
                        name="pengisi_perusahaan"
                        defaultValue={formData.pengisi_perusahaan}
                        onChange={handleInputChange}
                        inputWidth={"w-full"}
                        required
                      />
                      <OutlineInput
                        label="Alamat Perusahaan/Instansi"
                        labelClassName={"text-gray-600"}
                        placeholder="Masukkan Alamat Perusahaan/Instansi"
                        name="pengisi_alamat"
                        defaultValue={formData.pengisi_alamat}
                        onChange={handleInputChange}
                        inputWidth={"w-full"}
                        required
                      />
                      <OutlineInput
                        label="Posisi Jabatan Pengisi"
                        labelClassName={"text-gray-600"}
                        placeholder="Masukkan posisi jabatan pengisi"
                        name="pengisi_jabatan"
                        defaultValue={formData.pengisi_jabatan}
                        onChange={handleInputChange}
                        inputWidth={"w-full"}
                        required
                      />
                      <OutlineInput
                        label="Email Pengisi"
                        labelClassName={"text-gray-600"}
                        placeholder="Masukkan email pengisi"
                        name="pengisi_email"
                        defaultValue={formData.pengisi_email}
                        onChange={handleInputChange}
                        inputWidth={"w-full"}
                        required
                      />
                    </Column>
                  </Column>
                  <Row className="flex justify-between mt-6">
                    <ButtonHover>
                      <TertiaryButton
                        onClick={previousStep}
                        color={"blue"}
                        className={"bg-white"}
                        leadingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 3 && (
              <TransitionPage>
                <Column className="min-h-screen p-6">
                  <Column className={"h-fit bg-white rounded-xl border"}>
                    <Column className={"px-6 py-4 space-y-1"}>
                      <Title>Identitas Lulusan UTY</Title>
                      <CaptionText>Lengkapi data lulusan</CaptionText>
                    </Column>

                    <HorizontalDivider removeMargin />
                    <Column className={"p-6 space-y-6 pb-9"}>
                      <OutlineInput
                        label="Nama Lulusan UTY yang dinilai"
                        labelClassName={"text-gray-600"}
                        placeholder="Masukkan nama lulusan UTY"
                        name="mhs_nama"
                        defaultValue={formData.mhs_nama}
                        onChange={handleInputChange}
                        inputWidth={"w-full"}
                        required
                      />
                      <Column className="z-20 ">
                        <SelectInput
                          label={
                            <>
                              <span>
                                Program studi lulusan UTY yang dinilai {"("}
                                <span className="italic">opsional</span>
                                {")"}
                              </span>
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          initial={initProdi}
                          name="mhs_prodi"
                          onChange={(prodi) =>
                            handleSelectChange(prodi, "mhs_prodi")
                          }
                          btnClassName={"py-2.5 pl-4 pr-3"}
                          options={optionProdi}
                          background={"bg-gray-50"}
                        />
                      </Column>

                      <Column className="grid md:grid-cols-4 lg:grid-cols-3  gap-x-4 gap-y-3 md:gap-y-0 z-10">
                        <Column className="md:col-span-2 z-10">
                          <SelectInput
                            label={
                              <>
                                <span>
                                  Tanggal masuk perusahaan/instansi {"("}
                                  <span className="italic">opsional</span>
                                  {")"}
                                </span>
                              </>
                            }
                            background={"bg-gray-50"}
                            labelClassName={"text-gray-600"}
                            width={"w-full"}
                            initial={initBulan}
                            name="mhs_kerja_bulan"
                            onChange={(bulan) =>
                              handleSelectChange(bulan, "mhs_kerja_bulan")
                            }
                            btnClassName={"py-2.5 pl-4 pr-3"}
                            maxHeight="max-h-36"
                            options={optionBulan}
                          />
                        </Column>
                        <Column
                          className={
                            "md:col-span-2 lg:col-span-1 z-0  md:mt-[46px]  lg:mt-[26px]"
                          }
                        >
                          <SelectInput
                            width={"w-full"}
                            initial={initTahun}
                            name="mhs_kerja_tahun"
                            onChange={(tahun) =>
                              handleSelectChange(tahun, "mhs_kerja_tahun")
                            }
                            btnClassName={"py-2.5 pl-4 pr-3"}
                            options={optionTahun}
                            background={"bg-gray-50"}
                          />
                        </Column>
                      </Column>

                      <OutlineInput
                        label="Jabatan/Posisi lulusan UTY yang dinilai"
                        labelClassName={"text-gray-600"}
                        placeholder="Masukkan jabatan lulusan UTY"
                        name="mhs_jabatan"
                        defaultValue={formData.mhs_jabatan}
                        onChange={handleInputChange}
                        inputWidth={"w-full"}
                        required
                      />
                    </Column>
                  </Column>
                  <Row className="flex justify-between mt-6">
                    <ButtonHover>
                      <TertiaryButton
                        onClick={previousStep}
                        color={"blue"}
                        className={"bg-white"}
                        leadingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 4 && (
              <TransitionPage>
                <Column className="min-h-screen p-6">
                  <Column className={"h-fit bg-white rounded-xl border"}>
                    <Column className={"px-6 py-4 space-y-1"}>
                      <Title>Kemampuan Lulusan UTY</Title>
                      <CaptionText>Lengkapi data lulusan</CaptionText>
                    </Column>

                    <HorizontalDivider removeMargin />
                    <Column className={"p-6 space-y-6 pb-9"}>
                      <InputRadioButtonGrid
                        label={
                          <>
                            Bagaimana{" "}
                            <span className="font-bold">sikap/etika</span>{" "}
                            lulusan UTY yang bekerja di tempat Bapak/Ibu?
                          </>
                        }
                        labelClassName={"text-gray-600"}
                        checkedValue={selectedEtika}
                        id="s_etika"
                        name="s_etika"
                        value={option.value}
                        onChange={(etika) =>
                          handleRadioChange(etika, "s_etika")
                        }
                        radioOptions={option}
                        required
                      />
                      <InputRadioButtonGrid
                        label={
                          <>
                            Bagaimana{" "}
                            <span className="font-bold">keahlian</span> lulusan
                            UTY yang bekerja di tempat Bapak/Ibu berdasarkan
                            bidang ilmunya?
                          </>
                        }
                        labelClassName={"text-gray-600"}
                        checkedValue={selectedKeahlian}
                        id="s_keahlian"
                        name="s_keahlian"
                        onChange={(keahlian) =>
                          handleRadioChange(keahlian, "s_keahlian")
                        }
                        radioOptions={option}
                        required
                      />
                      <InputRadioButtonGrid
                        label={
                          <>
                            Bagaimana kemampuan{" "}
                            <span className="font-bold">berbahasa asing</span>{" "}
                            lulusan UTY yang bekerja di tempat Bapak/Ibu?
                          </>
                        }
                        labelClassName={"text-gray-600"}
                        checkedValue={selectedBahasa}
                        id="s_asing"
                        name="s_asing"
                        onChange={(asing) =>
                          handleRadioChange(asing, "s_asing")
                        }
                        radioOptions={option}
                        required
                      />
                      <InputRadioButtonGrid
                        label={
                          <>
                            Bagaimana kemampuan{" "}
                            <span className="font-bold">
                              penggunaan teknologi informasi
                            </span>{" "}
                            lulusan UTY yang bekerja di tempat Bapak/Ibu?
                          </>
                        }
                        labelClassName={"text-gray-600"}
                        checkedValue={selectedInformasi}
                        id="s_teknologi"
                        name="s_teknologi"
                        onChange={(teknologi) =>
                          handleRadioChange(teknologi, "s_teknologi")
                        }
                        radioOptions={option}
                        required
                      />
                      <InputRadioButtonGrid
                        label={
                          <>
                            Bagaimana kemampuan{" "}
                            <span className="font-bold">berkomunikasi</span>{" "}
                            lulusan UTY yang bekerja di tempat Bapak/Ibu?
                          </>
                        }
                        labelClassName={"text-gray-600"}
                        checkedValue={selectedKomunikasi}
                        id="s_komunikasi"
                        name="s_komunikasi"
                        onChange={(komunikasi) =>
                          handleRadioChange(komunikasi, "s_komunikasi")
                        }
                        radioOptions={option}
                        required
                      />
                      <InputRadioButtonGrid
                        label={
                          <>
                            Bagaimana kemampuan{" "}
                            <span className="font-bold">kerjasama</span> lulusan
                            UTY yang bekerja di tempat Bapak/Ibu?
                          </>
                        }
                        labelClassName={"text-gray-600"}
                        checkedValue={selectedKerjasama}
                        id="s_kerjasama"
                        name="s_kerjasama"
                        onChange={(kerjasama) =>
                          handleRadioChange(kerjasama, "s_kerjasama")
                        }
                        radioOptions={option}
                        required
                      />
                      <InputRadioButtonGrid
                        label={
                          <>
                            Bagaimana kemampuan{" "}
                            <span className="font-bold">pengembangan diri</span>{" "}
                            lulusan UTY yang bekerja di tempat Bapak/Ibu?
                          </>
                        }
                        labelClassName={"text-gray-600"}
                        checkedValue={selectedPengembangan}
                        id="s_pengembangan"
                        name="s_pengembangan"
                        onChange={(pengembangan) =>
                          handleRadioChange(pengembangan, "s_pengembangan")
                        }
                        radioOptions={option}
                        required
                      />
                      <InputRadioButtonGrid
                        label={
                          <>
                            Bagaimana{" "}
                            <span className="font-bold">
                              kesiapan terjun di masyarakat
                            </span>{" "}
                            lulusan UTY yang bekerja di tempat Bapak/Ibu?
                          </>
                        }
                        labelClassName={"text-gray-600"}
                        checkedValue={selectedKesiapan}
                        id="s_terjun"
                        name="s_terjun"
                        onChange={(terjun) =>
                          handleRadioChange(terjun, "s_terjun")
                        }
                        radioOptions={option}
                        required
                      />
                    </Column>
                  </Column>
                  <Row className="flex justify-between mt-6 ">
                    <ButtonHover>
                      <TertiaryButton
                        onClick={previousStep}
                        color={"blue"}
                        className={"bg-white"}
                        leadingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        // type={"submit"}
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 5 && (
              <TransitionPage>
                <Column className="min-h-screen p-6">
                  <Column className={"h-fit bg-white rounded-xl border"}>
                    <Column className={"px-6 py-4 space-y-1"}>
                      <Title>
                        Keunggulan, Kekurangan, Tantangan dan Saran Lulusan UTY
                      </Title>
                      <CaptionText>Lengkapi data lulusan</CaptionText>
                    </Column>

                    <HorizontalDivider removeMargin />
                    <Column className={"p-6 space-y-6 pb-9"}>
                      <InputTextArea
                        label="Menurut Bapak/Ibu, Apa keunggulan dari lulusan Universitas Teknologi Yogyakarta?"
                        labelClassName={"text-gray-600"}
                        name="m_keunggulan"
                        value={formData.m_keunggulan}
                        onChange={handleInputChange}
                        required
                      />
                      <InputTextArea
                        label="Menurut Bapak/Ibu, Apa kelemahan/kekurangan dari lulusan Universitas Teknologi Yogyakarta?"
                        labelClassName={"text-gray-600"}
                        name="m_kelemahan"
                        value={formData.m_kelemahan}
                        onChange={handleInputChange}
                        required
                      />
                      <InputTextArea
                        label="Mengingat berkembangnya tantangan kerja terbaru di lembaga Bapak/Ibu, keterampilan/kemampuan/kompetensi apakah yang perlu diberikan dan dikuasai oleh lulusan Universitas Teknologi Yogyakarta?"
                        labelClassName={"text-gray-600"}
                        name="m_dikuasai"
                        value={formData.m_dikuasai}
                        onChange={handleInputChange}
                        required
                      />
                      <InputTextArea
                        label="Apa harapan, Saran, dan/atau masukan Bapak/Ibu terhadap lulusan Universitas Teknologi Yogyakarta?"
                        labelClassName={"text-gray-600"}
                        name="m_saran"
                        value={formData.m_saran}
                        onChange={handleInputChange}
                        required
                      />
                    </Column>
                  </Column>
                  <Row className="flex justify-between mt-6 ">
                    <ButtonHover>
                      <TertiaryButton
                        onClick={previousStep}
                        color={"blue"}
                        className={"bg-white"}
                        leadingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 6 && (
              <TransitionPage>
                <Column className={"min-h-screen p-6"}>
                  <Column className={"bg-white rounded-xl border "}>
                    <Column className={"px-6 py-4"}>
                      <Title>Konfirmasi Form Pengguna Lulusan UTY</Title>
                    </Column>
                    <HorizontalDivider removeMargin />
                    <Column className={"px-6 py-4 space-y-6 pb-10"}>
                      <Text>
                        Silahkan tekan tombol{" "}
                        <span className="font-semibold">Submit</span> jika data
                        yang diisikan dirasa sudah benar. Tekan tombol{" "}
                        <span className="font-semibold">Back</span> jika data
                        yang diisi masih kurang sesuai.
                      </Text>
                      <Column
                        className={
                          "space-y-2 border shadow-none w-fit  hover:shadow-md rounded-xl"
                        }
                      >
                        <Column className={"px-4 py-2"}>
                          <Title>Captcha</Title>
                          <Caption>
                            Silahkan inputkan captcha sebelum melakukan submit
                            form
                          </Caption>
                        </Column>
                        <HorizontalDivider removeMargin />
                        <Column className={"px-4 py-2 space-y-1  pb-5"}>
                          <Text>{`${num1} + ${num2} berapa?`}</Text>
                          <OutlineInput
                            type="text"
                            inputWidth={"w-full"}
                            placeholder="Masukkan captcha"
                            value={captcaInput}
                            onChange={(e) => setCaptchaInput(e.target.value)}
                          />
                        </Column>
                      </Column>
                      <div className="flex gap-x-10">
                        <ButtonHover>
                          <PrimaryButton
                            onClick={backSubmit}
                            color={"orange"}
                            trailingIconLeft={
                              <TbCircleArrowLeftFilled className="w-6 h-6" />
                            }
                          >
                            Back
                          </PrimaryButton>
                        </ButtonHover>
                        <ButtonHover>
                          <PrimaryButton
                            className={"w-full"}
                            type={"submit"}
                            color={"green"}
                            trailingIcon={<TbChecks className="w-6 h-6" />}
                          >
                            Submit
                          </PrimaryButton>
                        </ButtonHover>
                      </div>
                    </Column>
                  </Column>
                </Column>
              </TransitionPage>
            )}
          </Form>
        </Column>
      </div>

      <AlertDialog show={showDialogCaptcha} onClose={closeDialogCaptcha}>
        <Column className={"p-5 gap-y-8 sm:p-8"}>
          <Column className="gap-y-2">
            <Headline3Text>Captcha Salah</Headline3Text>
            <BodyText>Silahkan masukkan captca dengan benar</BodyText>
          </Column>
          <Row className={"gap-x-3 justify-end"}>
            <ButtonHover>
              <PrimaryButton
                pills
                color={"red"}
                className="pl-5 pr-6"
                onClick={closeDialogCaptcha}
                trailingIconLeft={<TbX className="w-6 h-6" />}
              >
                tutup
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>

      <AlertDialog show={showDialogInput} onClose={closeDialogInput}>
        <Column className={"p-5 gap-y-8 sm:p-8"}>
          <Column className="gap-y-2">
            <Headline3Text>Data Tidak Lengkap !!</Headline3Text>
            <BodyText>
              Silahkan lengkapi inputan data yang wajib diisi pada form{" "}
              <span className={"font-medium"}>Pengguna Lulusan UTY</span>
            </BodyText>
          </Column>
          <Row className={"gap-x-3 justify-end"}>
            <ButtonHover>
              <PrimaryButton
                pills
                color={"red"}
                className="pl-5 pr-6"
                onClick={closeDialogInput}
                trailingIconLeft={<TbX className="w-6 h-6" />}
              >
                tutup
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>

      <AlertDialog
        show={showDialogSubmitFailed}
        onClose={closeDialogSubmitFailed}
      >
        <Column className={"p-5 gap-y-8 sm:p-8"}>
          <Column className="gap-y-2">
            <Headline3Text className={"text-center"}>{message}</Headline3Text>
            <BodyText>
              Silahkan cek inputan form. Inputan harus lebih dari 3 karakter.
              Silahkan ulangi untuk melakukan inputan yang masih kurang
            </BodyText>
          </Column>
          <Row className={"gap-x-3 justify-end"}>
            <ButtonHover>
              <PrimaryButton
                pills
                color={"red"}
                className="pl-5 pr-6"
                onClick={closeDialogSubmitFailed}
                trailingIconLeft={<TbX className="w-6 h-6" />}
              >
                tutup
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>

      <AlertDialog
        show={showDialogSubmitSuccess}
        onClose={closeDialogSubmitSuccess}
      >
        <Column className={"p-5 gap-y-8 sm:p-8"}>
          <Column className="gap-y-2">
            <Headline3Text>Berhasil mengirim data kuesioner</Headline3Text>
            <BodyText>
              Terima kasih sudah mengisi dan mengirim kuesioner{" "}
              <span className={"font-medium"}>Pengguna Lulusan UTY</span>
            </BodyText>
          </Column>
          <Row className={"gap-x-3 justify-end"}>
            <ButtonHover>
              <PrimaryButton
                pills
                color={"red"}
                className="pl-5 pr-6"
                onClick={closeDialogSubmitSuccess}
                trailingIconLeft={<TbX className="w-6 h-6" />}
              >
                tutup
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
    </>
  );
};

export default KuesionerPageLayout;
