import html2canvas from "html2canvas-pro";
import { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { BsImage } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { Column, Row } from "../../../../components/interfaces/block";
import { Card } from "../../../../components/interfaces/card";
import { CaptionText, OverlineText, TitleText } from "../../../../components/interfaces/text";
import { getExportPosisiWirausahaExitSurveyController, getPosisiWirausahaExitSurveyController } from "../../../../controllers/admin_controllers";
import useDownloadExcel from "../../../../hooks/useDownloadExcel";
import { ButtonHover, DotSeparated } from "../../../../shared/ui";

function ChartPosisiWirausaha({ prodi, wisuda, binaryData }) {

  const chartRef = useRef(null);

  const [chartTitle, setChartTitle] = useState("Posisi/Jabatan Jika Berwirausaha")
  const [chartSeries, setChartSeries] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  const fileName = `Posisi Wirausaha - ${prodi.label} - ${wisuda.label} (Exit Survey)`
  const downloadExcelFill = useDownloadExcel({ fileName });

  const colors = ["#4CAF50", "#2196F3", "#3F51B5", "#FF9800"];

  const legendColors = [
    "bg-[#4CAF50]",
    "bg-[#2196F3]",
    "bg-[#3F51B5]",
    "bg-[#FF9800]",
  ];

  const getPosisiWirausahaExitSurvey = async () => {
    const res = await getPosisiWirausahaExitSurveyController({
      prodi: prodi.value,
      tglwisuda: wisuda.value,
    });
    if (res.status) {
      setChartTitle(res.data?.title)
      setChartSeries(res.data?.rekap)
      setChartLabels(res.data?.label)
    }
  };

  const exportExcel = async () => {
    const res = await getExportPosisiWirausahaExitSurveyController({
      prodi: prodi.value,
      tglwisuda: wisuda.value,
    });
    downloadExcelFill(res);
  };

  const exportImage = async () => {
    if (!chartRef.current) return
    try {
      const format = "image/jpeg"
      const backgroundColor = "white"
      const extension = "jpg"
      const scale = 3

      const canvas = await html2canvas(chartRef.current, { backgroundColor, scale })
      const imageUrl = canvas.toDataURL(format)

      const link = document.createElement("a")
      link.href = imageUrl
      link.download = `${fileName.toLowerCase()}.${extension}`
      link.click()
    } catch (error) {
      console.error("Error capturing the chart:", error)
    }
  };

  useEffect(() => {
    getPosisiWirausahaExitSurvey()
  }, [prodi, wisuda])

  return (
    <Card refId={chartRef} className={"w-full h-full p-0"}>
      <Column>
        <Column className={"p-6 gap-y-1"}>
          <Row className="gap-x-5">
            <Column className="gap-y-1">
              <TitleText>
                {chartTitle}
              </TitleText>
              <Row>
                <CaptionText>
                  {
                    prodi.label
                  }
                </CaptionText>
                <DotSeparated />
                <CaptionText>
                  {
                    wisuda.label
                  }
                </CaptionText>
              </Row>
            </Column>
            <ButtonHover>
              <button onClick={() => exportExcel(binaryData)}>
                <SiMicrosoftexcel className={`w-6 h-6 text-gray-500`} />
              </button>
            </ButtonHover>
            <ButtonHover>
              <button onClick={exportImage}>
                <BsImage className={`w-6 h-6 text-gray-500`} />
              </button>
            </ButtonHover>
          </Row>
        </Column>
        <div className="my-auto pb-6">
          <Chart
            height={250}
            type="donut"
            series={chartSeries}
            options={{
              chart: {
                width: 720,
                height: 720,
                type: "donut",
                fontFamily: "archivo",
              },
              labels: chartLabels,
              dataLabels: {
                enabled: true,
                fontSize: "12px",
              },
              colors: colors,
              legend: {
                show: false,
                position: "bottom",
                offsetX: 0,
              },
            }}
          />
        </div>
        <ChartLegend
          labels={chartLabels}
          colors={legendColors}
        />
      </Column>
    </Card>
  )
}

export default ChartPosisiWirausaha;

export const ChartLegend = ({ labels, colors }) => {
  return (
    <Row className={"px-6 pb-6 gap-x-3 gap-y-2 flex-wrap justify-center"}>
      {labels.map((label, idx) => {
        return (
          <Row key={idx} className={"w-fit gap-1.5"}>
            <Card
              className={`${colors[idx]} border-none p-1.5 rounded-full my-auto`}
            />
            <OverlineText className={"my-auto text-gray-800 font-normal"}>
              {label}
            </OverlineText>
          </Row>
        );
      })}
    </Row>
  );
};