import moment from "moment";
import React from "react";
import {
  TableCell,
  TableRow,
} from "../../../../../components/interfaces/table";
import { BodyText } from "../../../../../components/interfaces/text";

const RowFillKuesionerTracer = ({ idx, kuesioner, page = 1, limit = 10 }) => {
  return (
    <TableRow
      striped
      className={`${
        idx % 2 === 1 ? "bg-slate-50" : "bg-white"
      } hover:bg-slate-100/100 cursor-default`}
    >
      <TableCell>{limit * (page - 1) + (idx + 1)}</TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{kuesioner.nim}</BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2 capitalize"}>
          {kuesioner.nama.toLowerCase()}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{kuesioner.nama_jenjang}</BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{kuesioner.nama_jurusan}</BodyText>
      </TableCell>
      <TableCell>{moment(kuesioner.tgllulus).format("DD MMM YYYY")}</TableCell>
      <TableCell>{moment(kuesioner.created).format("DD MMM YYYY")}</TableCell>
    </TableRow>
  );
};
export default RowFillKuesionerTracer;
