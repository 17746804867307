import React, { useEffect, useState } from "react";
import {
  TbBellFilled,
  TbChevronLeft,
  TbChevronRight,
  TbMenu2,
} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { getNotificationsController } from "../../controllers/global_controllers";
import { Appbar, IconButton, Row, TextButton } from "../../shared/ui";
import {
  admNotificationRoute,
  konNotificationRoute,
} from "../../shared/variable";

function AdmAppBar({ onShowSidebar }) {
  const location = useLocation();
  const navigateTo = useNavigate();

  const [sumNewNotif, setSumNewNotif] = useState(0);

  const getNotifications = async () => {
    const result = await getNotificationsController();
    if (result.status) {
      const listNotification = [...result.data];
      const unread = listNotification.filter(
        (notif) => notif.is_read === false
      );
      setSumNewNotif(unread.length);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Appbar
      className={
        "border-b border-gray-200 px-2 z-30 bg-white/90 backdrop-blur-xl sm:px-4 lg:justify-start"
      }
    >
      <IconButton onClick={onShowSidebar}>
        <TbMenu2 className={"h-6 w-6"} />
      </IconButton>
      <Row className={"w-fit justify-between lg:w-full lg:ml-[15.5rem]"}>
        <Row className={"w-fit gap-x-1 hidden lg:flex"}>
          <TextButton
            className={`p-2 rounded-full h-fit hover:bg-gray-50`}
            disabled={location.key !== "default" ? false : true}
            onClick={() => navigateTo(-1)}
          >
            <TbChevronLeft className={"h-6 w-6"} />
          </TextButton>
          <TextButton
            className={`p-2 rounded-full h-fit hover:bg-gray-50`}
            onClick={() => navigateTo(1)}
          >
            <TbChevronRight className={"h-6 w-6"} />
          </TextButton>
        </Row>
        <div className="relative">
          {sumNewNotif > 0 && (
            <span className={"absolute top-2 right-2 flex h-2.5 w-2.5"}>
              <span
                className={
                  "animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
                }
              />
              <span
                className={
                  "relative inline-flex rounded-full h-2.5 w-2.5 bg-red-500 border-[1.5px] border-white"
                }
              />
            </span>
          )}
          <IconButton
            color="custom"
            className={"text-gray-800 hover:text-indigo-600 hover:bg-gray-50"}
            onClick={() => navigateTo(admNotificationRoute)}
          >
            <TbBellFilled className={"h-6 w-6"} />
          </IconButton>
        </div>
      </Row>
    </Appbar>
  );
}

export default AdmAppBar;
