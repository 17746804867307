import React, { useEffect, useState } from "react";

import {
  getMasterProdiController,
  getOvGrafikStatusTracerController,
} from "../controllers/admin_controllers";
import {
  ButtonHover,
  Column,
  Form,
  Headline2,
  Headline3,
  InputRadioButtonGrid,
  InputTextArea,
  OutlineInput,
  PrimaryButton,
  Row,
  SelectInput,
  Text,
  TransitionPage,
} from "../shared/ui";
import {
  TbChecks,
  TbChevronsLeft,
  TbChevronsRight,
  TbCircleArrowLeftFilled,
  TbCircleArrowRightFilled,
} from "react-icons/tb";

import logoUty2 from "../images/logo.png";
import logoUty from "../images/logo2.png";
import imgGraduation from "../images/graduation.jpg";
import { postSurveiController } from "../controllers/global_controllers";
import { setupMonth, setupTahun } from "../shared/ta";

const TestPagee = ({}) => {
  const [kerja, setKerja] = useState(null);
  const [tahun, setTahun] = useState(null);
  const [dataF, setDataF] = useState(null);
  const [labelKategori, setlabelKategori] = useState([]);
  const [labelPenilaian, setLabelPenilaian] = useState([]);

  useEffect(() => {
    const getKompetensi = async () => {
      try {
        const dataKompetensi = await getOvGrafikStatusTracerController(tahun);
        if (dataKompetensi.status) {
          setKerja(dataKompetensi);
          setDataF(dataKompetensi.data);
          setlabelKategori(dataKompetensi.label_kategori);
          setLabelPenilaian(dataKompetensi.label_penilaian);
        }
        console.log("data kerja", dataKompetensi);
      } catch (error) {
        console.error("error", error.message);
      }
    };
    getKompetensi();
  }, [tahun]);

  const handleChangeTahun = (e) => {
    setTahun(e.target.value);
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [showProgress, setShowProgress] = useState(true);

  const onDoneLoadData = (value) => {
    setShowProgress(false);
  };

  const onChangeTabIndex = (index) => {
    setShowProgress(true);
    setCurrentTabIndex(index);
  };

  const [selectedEtika, setSelectedEtika] = useState("");
  const [selectedKeahlian, setSelectedKeahlian] = useState("");
  const [selectedBahasa, setSelectedBahasa] = useState("");
  const [selectedInformasi, setSelectedInformasi] = useState("");
  const [selectedKomunikasi, setSelectedKomunikasi] = useState("");
  const [selectedKerjasama, setSelectedKerjasama] = useState("");
  const [selectedPengembangan, setSelectedPengembangan] = useState("");
  const [selectedKesiapan, setSelectedKesiapan] = useState("");
  const [messageError, setMessageError] = useState(null);
  const [optionProdi, setOptionProdi] = useState([]);
  const [optionTahun, setOptionTahun] = useState([]);
  const [optionBulan, setOptionBulan] = useState([]);
  const [initProdi, setInitProdi] = useState({
    label: "Pilih Program Studi",
    value: null,
  });
  const [initTahun, setInitTahun] = useState({
    label: "Pilih tahun",
    value: null,
  });
  const [initBulan, setInitBulan] = useState({
    label: "Pilih bulan",
    value: null,
  });
  const [step, setStep] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({});

  const option = [
    { label: "Sangat Baik", value: "sangat_baik" },
    { label: "Baik", value: "baik" },
    { label: "Cukup", value: "cukup" },
    { label: "Kurang", value: "kurang" },
  ];

  const handleSelectChange = (e, fieldName) => {
    if (fieldName === "mhs_prodi") {
      setInitProdi(e);
      setFormData({
        ...formData,
        mhs_prodi: e.value.toString(),
      });
    } else if (fieldName === "mhs_kerja_bulan") {
      setInitBulan(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: e.label + " " + (initTahun.value || ""),
      }));
    } else if (fieldName === "mhs_kerja_tahun") {
      setInitTahun(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: (initBulan.label || "") + "" + e.value,
      }));
    }
  };

  const handleRadioChange = (value, radioName) => {
    if (radioName === "s_etika") {
      setSelectedEtika(value);
      setFormData({ ...formData, s_etika: value });
    } else if (radioName === "s_keahlian") {
      setSelectedKeahlian(value);
      setFormData({ ...formData, s_keahlian: value });
    } else if (radioName === "s_asing") {
      setSelectedBahasa(value);
      setFormData({ ...formData, s_asing: value });
    } else if (radioName === "s_teknologi") {
      setSelectedInformasi(value);
      setFormData({ ...formData, s_teknologi: value });
    } else if (radioName === "s_komunikasi") {
      setSelectedKomunikasi(value);
      setFormData({ ...formData, s_komunikasi: value });
    } else if (radioName === "s_kerjasama") {
      setSelectedKerjasama(value);
      setFormData({ ...formData, s_kerjasama: value });
    } else if (radioName === "s_pengembangan") {
      setSelectedPengembangan(value);
      setFormData({ ...formData, s_pengembangan: value });
    } else if (radioName === "s_terjun") {
      setSelectedKesiapan(value);
      setFormData({ ...formData, s_terjun: value });
    }
  };

  const getDataProdi = async () => {
    try {
      const dataProdi = await getMasterProdiController();
      const formattedOptions = dataProdi.data.map((prodi) => ({
        label: `${prodi.jenjang} - ${prodi.prodi}`,
        value: prodi.kode_prodi,
      }));
      setOptionProdi(formattedOptions);
    } catch (error) {
      console.error("error get data prodi", error.message);
    }
  };

  useEffect(() => {
    getDataProdi();
    setOptionTahun(setupTahun({ start: 2003 }));
    setOptionBulan(setupMonth({ start: "Januari" }));
  }, []);

  const nextStep = () => {
    if (step === 2) {
      if (
        !formData.pengisi_nama ||
        !formData.pengisi_perusahaan ||
        !formData.pengisi_alamat ||
        !formData.pengisi_jabatan ||
        !formData.pengisi_email
      ) {
        // alert("data tidak boleh kosong");
        return;
      }
    }

    if (step === 3) {
      if (!formData.mhs_nama || !formData.mhs_jabatan) {
        // alert("data tidak boleh kosong");
        return;
      }
    }

    if (step === 4) {
      if (
        !formData.s_etika ||
        !formData.s_keahlian ||
        !formData.s_asing ||
        !formData.s_teknologi ||
        !formData.s_komunikasi ||
        !formData.s_kerjasama ||
        !formData.s_pengembangan ||
        !formData.s_terjun
      ) {
        // alert("data tidak boleh kosong");
        return;
      }
    }

    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await postSurveiController(formData);
      setMessageError(data.message);
    } catch (error) {
      console.error("error add data", error.message);
    }
  };

  return (
    <>
      <div className="grid md:grid-cols-3 h-screen ">
        <div className=" bg-gradient-to-t from-blue-800 to-cyan-300 hidden md:inline h-screen">
          <div className="relative ">
            <div className="relative">
              <img
                src={imgGraduation}
                alt="uty"
                className="min-h-screen opacity-50 z-10 "
              />
              <img
                src={logoUty}
                alt="logoUty"
                className="absolute top-6 left-6 w-auto h-10 z-20"
              />
            </div>
            <div className="absolute top-0 left-0 h-screen">
              <div className="flex justify-end items-end p-6 space-y-10 h-screen">
                <div className="space-y-4 mb-10">
                  <div>
                    <Headline2 className={"text-white"}>
                      Kuesioner Pengguna
                    </Headline2>
                    <Headline2 className={"text-white"}>Lulusan</Headline2>
                  </div>

                  <Text className={"text-white"}>
                    Selamat datang pada halaman form kuesioner pengguna lulusan.
                    Form kuesioner ini digunakan untuk mengumpulkan data lulusan
                    dari Universitas Teknologi Yogyakarta.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Column className="md:col-span-2 bg-none h-screen overflow-y-auto scrollbar-hide ">
          <Form onSubmit={handleSubmit}>
            {step === 1 && (
              <TransitionPage>
                <Column className="py-6 px-6 md:px-16 space-y-4">
                  <div className="flex justify-center">
                    <img src={logoUty2} alt="logo" className="h-28" />
                  </div>
                  <Column className="px-6">
                    <hr className="w-full h-[0.1rem] bg-gradient-to-r from-white via-slate-600 to-white  my-4" />
                  </Column>
                  <Column>
                    <Text className="font-semibold">Kepada Yth. </Text>
                    <Text>Pengguna Lulusan UTY</Text>
                  </Column>

                  <Text className="text-justify">
                    Dalam rangka meningkatkan kualitas lulusan Universitas
                    Teknologi Yogyakarta, kami melakukan survey terhadap
                    pengguna lulusan sebagai salah satu indikator evaluasi
                    perbaikan Universitas Teknologi Yogyakarta. Kami sangat
                    mengharapkan kesediaan Bapak/Ibu untuk dapat memberikan
                    penilaian terhadap lulusan Universitas Teknologi Yogyakarta
                    yang bekerja pada instansi atau perusahaan yang Bapak/Ibu
                    pimpin dengan cara mengisi kuisioner pengguna lulusan
                    Universitas Teknologi Yogyakarta berikut ini.
                  </Text>
                  <Text className="text-justify">
                    Penilaian ini akan menjadi umpan balik bagi kami dalam
                    melakukan evaluasi terhadap lulusan Universitas Teknologi
                    Yogyakarta. Penilaian dan masukan yang Bapak/Ibu berikan
                    sangat berharga bagi kami dan kami akan menjaga kerahasiaan
                    informasi yang diberikan tersebut.
                  </Text>
                  <Text className="text-justify">
                    Atas nama Rektor Universitas Teknologi Yogyakarta, kami
                    mengucapkan terima kasih yang sebesar-besarnya atas
                    kesediaan Bapak/Ibu meluangkan waktu sejenak untuk
                    berpartisapasi dalam pengisian survey pengguna lulusan
                    Universitas Teknologi Yogyakarta.
                  </Text>
                  <Column>
                    <Text>Hormat Kami</Text>
                    <Text>Tim Tracer Study </Text>
                    <Text className="font-semibold">
                      Universitas Teknologi Yogyakarta
                    </Text>
                  </Column>

                  <Row className="flex justify-end">
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"red"}
                        trailingIconLeft={
                          <TbChevronsLeft className="w-6 h-6" />
                        }
                        trailingIcon={<TbChevronsRight className="w-6 h-6" />}
                      >
                        Mulai
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 2 && (
              <TransitionPage>
                <Column className="py-6 px-6 md:px-16 space-y-6">
                  <Headline3>Perusahaan</Headline3>
                  <OutlineInput
                    // prefixClassName={"ml-2"}
                    // prefix={"t"}
                    label="Nama Pengisi"
                    placeholder="Masukkan nama pengisi"
                    className={"bg-white"}
                    name="pengisi_nama"
                    defaultValue={formData.pengisi_nama}
                    onChange={handleInputChange}
                    inputWidth={"w-full"}
                    required
                  />
                  <OutlineInput
                    label="Nama Perusahaan/Instansi"
                    placeholder="Masukkan nama Perusahaan/Instansi"
                    className={"bg-white"}
                    name="pengisi_perusahaan"
                    defaultValue={formData.pengisi_perusahaan}
                    onChange={handleInputChange}
                    inputWidth={"w-full"}
                    required
                  />
                  <OutlineInput
                    label="Alamat Perusahaan/Instansi"
                    placeholder="Masukkan Alamat Perusahaan/Instansi"
                    className={"bg-white"}
                    name="pengisi_alamat"
                    defaultValue={formData.pengisi_alamat}
                    onChange={handleInputChange}
                    inputWidth={"w-full"}
                    required
                  />
                  <OutlineInput
                    label="Posisi Jabatan Pengisi"
                    placeholder="Masukkan posisi jabatan pengisi"
                    className={"bg-white"}
                    name="pengisi_jabatan"
                    defaultValue={formData.pengisi_jabatan}
                    onChange={handleInputChange}
                    inputWidth={"w-full"}
                    required
                  />
                  <OutlineInput
                    label="Email pengisi"
                    placeholder="Masukkan email pengisi"
                    className={"bg-white"}
                    name="pengisi_email"
                    defaultValue={formData.pengisi_email}
                    onChange={handleInputChange}
                    inputWidth={"w-full"}
                    required
                  />

                  <Row className="flex justify-between ">
                    <ButtonHover>
                      <PrimaryButton
                        onClick={previousStep}
                        color={"blue"}
                        trailingIconLeft={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </PrimaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        type={"submit"}
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 3 && (
              <TransitionPage>
                <Column className="py-6 px-6 md:px-16 space-y-6">
                  <Headline3>Lulusan UTY</Headline3>
                  <OutlineInput
                    label="Nama Lulusan UTY yang dinilai"
                    placeholder="Masukkan nama lulusan UTY"
                    className={"bg-white py-2.5"}
                    name="mhs_nama"
                    defaultValue={formData.mhs_nama}
                    onChange={handleInputChange}
                    inputWidth={"w-full"}
                    required
                  />
                  <Column className="z-50 ">
                    <SelectInput
                      label={"Program studi lulusan UTY yang dinilai"}
                      initial={initProdi}
                      name="mhs_prodi"
                      onChange={(prodi) =>
                        handleSelectChange(prodi, "mhs_prodi")
                      }
                      btnClassName={"py-2.5 pl-4 pr-3"}
                      options={optionProdi}
                    />
                  </Column>

                  <Column className="grid md:grid-cols-4 lg:grid-cols-3  gap-x-4 gap-y-3 md:gap-y-0 z-10">
                    <Column className="md:col-span-2 z-10">
                      <SelectInput
                        label={
                          <>
                            <span>
                              Tanggal masuk perusahaan/instansi {"("}
                              <span className="italic">opsional</span>
                              {")"}
                            </span>
                          </>
                        }
                        width={"w-full"}
                        initial={initBulan}
                        name="mhs_kerja_bulan"
                        onChange={(bulan) =>
                          handleSelectChange(bulan, "mhs_kerja_bulan")
                        }
                        btnClassName={"py-2.5 pl-4 pr-3"}
                        maxHeight="max-h-36"
                        options={optionBulan}
                      />
                    </Column>
                    <Column
                      className={
                        "md:col-span-2 lg:col-span-1 z-0  md:mt-[46px]  lg:mt-[26px]"
                      }
                    >
                      <SelectInput
                        width={"w-full"}
                        initial={initTahun}
                        name="mhs_kerja_tahun"
                        onChange={(tahun) =>
                          handleSelectChange(tahun, "mhs_kerja_tahun")
                        }
                        btnClassName={"py-2.5 pl-4 pr-3"}
                        maxHeight="max-h-36"
                        options={optionTahun}
                      />
                    </Column>
                  </Column>

                  <OutlineInput
                    label="Jabatan/Posisi lulusan UTY yang dinilai"
                    placeholder="Masukkan jabatan lulusan UTY"
                    className={"bg-white"}
                    name="mhs_jabatan"
                    defaultValue={formData.mhs_jabatan}
                    onChange={handleInputChange}
                    inputWidth={"w-full"}
                    required
                  />

                  <Row className="flex justify-between ">
                    <ButtonHover>
                      <PrimaryButton
                        onClick={previousStep}
                        color={"blue"}
                        trailingIconLeft={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </PrimaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        type={"submit"}
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 4 && (
              <TransitionPage>
                <Column className="py-6 px-6 md:px-16 space-y-6">
                  <div>
                    <Headline3>Kemampuan Lulusan UTY</Headline3>
                  </div>
                  <InputRadioButtonGrid
                    label={
                      <>
                        Bagaimana <span className="font-bold">sikap/etika</span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedEtika}
                    id="s_etika"
                    name="s_etika"
                    value={option.value}
                    onChange={(etika) => handleRadioChange(etika, "s_etika")}
                    radioOptions={option}
                    required
                  />
                  <InputRadioButtonGrid
                    label={
                      <>
                        Bagaimana <span className="font-bold">keahlian</span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu berdasarkan
                        bidang ilmunya?
                      </>
                    }
                    checkedValue={selectedKeahlian}
                    id="s_keahlian"
                    name="s_keahlian"
                    onChange={(keahlian) =>
                      handleRadioChange(keahlian, "s_keahlian")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButtonGrid
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">berbahasa asing</span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedBahasa}
                    id="s_asing"
                    name="s_asing"
                    onChange={(asing) => handleRadioChange(asing, "s_asing")}
                    radioOptions={option}
                    required
                  />
                  <InputRadioButtonGrid
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">
                          penggunaan teknologi informasi
                        </span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedInformasi}
                    id="s_teknologi"
                    name="s_teknologi"
                    onChange={(teknologi) =>
                      handleRadioChange(teknologi, "s_teknologi")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButtonGrid
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">berkomunikasi</span> lulusan
                        UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedKomunikasi}
                    id="s_komunikasi"
                    name="s_komunikasi"
                    onChange={(komunikasi) =>
                      handleRadioChange(komunikasi, "s_komunikasi")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButtonGrid
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">kerjasama</span> lulusan UTY
                        yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedKerjasama}
                    id="s_kerjasama"
                    name="s_kerjasama"
                    onChange={(kerjasama) =>
                      handleRadioChange(kerjasama, "s_kerjasama")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButtonGrid
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">pengembangan diri</span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedPengembangan}
                    id="s_pengembangan"
                    name="s_pengembangan"
                    onChange={(pengembangan) =>
                      handleRadioChange(pengembangan, "s_pengembangan")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButtonGrid
                    label={
                      <>
                        Bagaimana{" "}
                        <span className="font-bold">
                          kesiapan terjun di masyarakat
                        </span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedKesiapan}
                    id="s_terjun"
                    name="s_terjun"
                    onChange={(terjun) => handleRadioChange(terjun, "s_terjun")}
                    radioOptions={option}
                    required
                  />
                  <Row className="flex justify-between ">
                    <ButtonHover>
                      <PrimaryButton
                        onClick={previousStep}
                        color={"blue"}
                        trailingIconLeft={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </PrimaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        type={"submit"}
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 5 && (
              <TransitionPage>
                <div className="py-6 px-6 md:px-16 space-y-6">
                  <div>
                    <Headline3>Harapan </Headline3>
                  </div>
                  <InputTextArea
                    label="Menurut Bapak/Ibu, Apa keunggulan dari lulusan Universitas Teknologi Yogyakarta?"
                    name="m_keunggulan"
                    value={formData.m_keunggulan}
                    onChange={handleInputChange}
                  />
                  <InputTextArea
                    label="Menurut Bapak/Ibu, Apa kelemahan/kekurangan dari lulusan Universitas Teknologi Yogyakarta?"
                    name="m_kelemahan"
                    value={formData.m_kelemahan}
                    onChange={handleInputChange}
                  />
                  <InputTextArea
                    label="Mengingat berkembangnya tantangan kerja terbaru di lembaga Bapak/Ibu, keterampilan/kemampuan/kompetensi apakah yang perlu diberikan dan dikuasai oleh lulusan Universitas Teknologi Yogyakarta?"
                    name="m_dikuasai"
                    value={formData.m_dikuasai}
                    onChange={handleInputChange}
                  />
                  <InputTextArea
                    label="Apa harapan, Saran, dan/atau masukan Bapak/Ibu terhadap lulusan Universitas Teknologi Yogyakarta?"
                    name="m_saran"
                    value={formData.m_saran}
                    onChange={handleInputChange}
                  />
                  <div className="flex justify-between ">
                    <ButtonHover>
                      <PrimaryButton
                        onClick={previousStep}
                        color={"blue"}
                        trailingIconLeft={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </PrimaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        // onClick={nextStep}
                        type={"submit"}
                        color={"green"}
                        trailingIcon={<TbChecks className="w-6 h-6" />}
                      >
                        Submit
                      </PrimaryButton>
                    </ButtonHover>
                  </div>
                </div>
              </TransitionPage>
            )}

            {step === 6 && (
              <TransitionPage>
                <div className="py-6 px-6 md:px-16 space-y-6">
                  <Text>
                    Terima kasih sudah mengisi kuesioner pengguna lulusan UTY
                  </Text>
                  <div className="flex justify-between ">
                    {/* <ButtonHover>
                      <PrimaryButton
                        onClick={() => setStep(step == 2)}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </PrimaryButton>
                    </ButtonHover> */}
                  </div>
                </div>
              </TransitionPage>
            )}
          </Form>
        </Column>
      </div>

      {/* <div>
        <Tabs
          selectedTabIdx={currentTabIndex}
          onChangeTabIndex={onChangeTabIndex}
        >
          <TabPanel
            icon={<TbChartDonut2 className="h-5 w-5" />}
            title="Ringkasan"
          >
            <OvTracerIPT onDoneLoadData={onDoneLoadData} />
          </TabPanel>
          <TabPanel
            icon={<TbClipboardList className="h-5 w-5" />}
            title="Kuesioner"
          >
            <TableRekapKuesionerTracer onDoneLoadData={onDoneLoadData} />
          </TabPanel>
          <TabPanel icon={<TbSchool className="h-5 w-5" />} title="Wisuda">
            <TableRekapWisudaTracer onDoneLoadData={onDoneLoadData} />
          </TabPanel>
          <TabPanel icon={<TbMessages className="h-5 w-5" />} title="Saran">
            <TableSaranTracerStudi onDoneLoadData={onDoneLoadData} />
          </TabPanel>
        </Tabs>

        <div>
          <label>Pilih Tahun: </label>
          <input
            type="number"
            value={tahun}
            onChange={handleChangeTahun}
            min="2000"
            max={new Date().getFullYear()}
          />
        </div>

        {dataF && (
          <div>
            {Object.entries(dataF).map(([key, values], idx) => (
              <div key={idx}>
                <h3>{labelKategori[idx]}</h3>
                <ul>
                  {values.map((value, i) => (
                    <li key={i}>{value}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        {labelPenilaian.map((data, idx) => (
          <div key={idx}>
            <p>{data}</p>
          </div>
        ))}
        {labelKategori.map((data, idx) => (
          <div key={idx}>
            <p>{data}</p>
          </div>
        ))}
      </div> */}

      {/* <div className="bg-violet-500 w-1/1"></div> */}
    </>
  );
};

export default TestPagee;
