export const setupTahunAjaran = ({ start = 2015 }) => {
    const year = []
    const current = new Date().getFullYear()
    for (let i = start; i < current; i++) {
        const ta = `${i}/${i + 1}`
        year.unshift({
            label: ta,
            value: ta,
        })
    }
    return year
}

export const setupTahun = ({ start = 2015 }) => {
    const year = []
    const current = new Date().getFullYear()
    for (let i = start; i <= current; i++) {
        year.unshift({
            label: i,
            value: i,
        })
    }
    return year
}

export const setupMonth = ({ useAllMonth = false }) => {
    const month = []
    const monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    if (useAllMonth) {
        month.push({
            label: 'Semua Bulan',
            value: 0,
        })
    }
    for (let i = 0; i < 12; i++) {
        month.push({
            label: monthName[i],
            value: (i + 1),
        })
    }
    return month
}