import React from 'react'
import { Card, Column, Row, ShimmerAnimation, ShimmerItem } from '../../shared/ui'

const SPieOvTracer = () => {
    return (
        <Card className='w-full h-full p-5'>
            <ShimmerAnimation className='h-full'>
                <Column className='gap-6 items-center h-full'>
                    <ShimmerItem className='h-6 w-[65%]' />
                    <Column className='items-center gap-8 my-auto'>
                        <ShimmerItem className='h-48 aspect-square w-fit rounded-full' />
                        <Row className='w-fit gap-3'>
                            <ShimmerItem className='h-4 w-24' />
                            <ShimmerItem className='h-4 w-24' />
                            <ShimmerItem className='h-4 w-24' />
                        </Row>
                    </Column>
                </Column>
            </ShimmerAnimation>
        </Card>
    )
}

export default SPieOvTracer
