import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Card,
  Column,
  Form,
  Headline1,
  Headline3,
  Image,
  LoadingDialog,
  OutlineInput,
  OutlineTextArea,
  Overline,
  PrimaryButton,
  Row,
  SelectView,
  TabPanel,
  Tabs,
  Text,
  TextButton,
  TextEditor,
  TransitionPage,
} from "../../../shared/ui";
import {
  TbAlertTriangle,
  TbArrowRight,
  TbChecks,
  TbPhotoPlus,
  TbRefresh,
  TbUpload,
  TbX,
} from "react-icons/tb";
import Cookies from "universal-cookie";
import { refreshTokenKey } from "../../../shared/variable";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  getFilterCareerController,
  saveLowonganController,
} from "../../../controllers/apicontrollers";
import STable from "../../../components/shimmer/stable";
import TableAcceptedLowongan from "../../../components/tabels/accepted_lowongan";
import { useRecoilState } from "recoil";
import { currentIndexTabLowongan } from "../../../atoms";
import SAdmAddLowongan from "../../../components/shimmer/sadm_add_lowongan";

const FormMitraTerdaftar = lazy(() =>
  import("../../../pages/admin/karir/form/adm_lowongan_mitra_terdaftar")
);
const FormMitraTidakTerdaftar = lazy(() =>
  import("../../../pages/admin/karir/form/adm_lowongan_mitra_tidak_terdaftar")
);

const AdmAddCareerPage = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [showProgress, setShowProgress] = useState(true);

  const onChangeTabIndex = (index) => {
    setShowProgress(true);
    setCurrentTabIndex(index);
  };

  const onDoneLoadData = (value) => {
    setShowProgress(false);
  };

  return (
    <>
      <Column className="gap-y-5 p-4 sm:p-8">
        <Headline1>Tambah Lowongan</Headline1>
        <Tabs
          selectedTabIdx={currentTabIndex}
          onChangeTabIndex={onChangeTabIndex}
        >
          <TabPanel
            icon={<TbChecks className="h-5 w-5" />}
            title="Mitra Terdaftar"
            textActiveColor="text-green-700"
          >
            <Suspense fallback={<SAdmAddLowongan />}>
              <FormMitraTerdaftar />
            </Suspense>
          </TabPanel>
          <TabPanel
            icon={<TbX className="h-5 w-5" />}
            title="Mitra Tidak Terdaftar"
            textActiveColor="text-red-700"
          >
            <Suspense fallback={<SAdmAddLowongan logo />}>
              <FormMitraTidakTerdaftar />
            </Suspense>
          </TabPanel>
        </Tabs>
      </Column>
    </>
  );
};

export default AdmAddCareerPage;
