import { Suspense, lazy, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { masterFilterOvTracer } from "../../atoms";
import { Column, GridCol, GridRow, Row, SelectView } from "../../shared/ui";
import CNumRata2GajiOverviewTracer from "../cards/cnum_rata2gaji_ov_tracer";
import CNumRata2WaktuOverviewTracer from "../cards/cnum_rata2waktu_ov_tracer";
import SChartOvTracer from "../shimmer/sadm_chart_ov_tracer";
import SPieOvTracer from "../shimmer/sadm_pie_ov_tracer";
import WTableTingkatKomOvTracer from "../wtable_tingkatkom_ov_tracer";
import WTableTingkatKerjaOvTracer from "../wtable_tingkatkerja_ov_tracer";
import WTableMetodeBelajarOvTracer from "../wtable_metodebelajar_ov_tracer";
import WTableCCKerjaOvTracer from "../wtable_cckerja_ov_tracer";
import WTableBedaKerjaOvTracer from "../wtable_bedakerja_ov_tracer";
import WTableProCariKerjaOvTracer from "../wtable_procarikerja_ov_tracer";
import { getCurrentUser } from "../../shared/user";
import { getFilterPenggunaLulusanController } from "../../controllers/admin_controllers";
import { useLoaderData } from "react-router-dom";
import { getDataMasterFilterOvTracer } from "../../controllers/apicontrollers";

const ChartOvTracer = lazy(() => import("../chart/chart_ov_tracer"));
const CChartStatusOverviewTracer = lazy(() =>
  import("../cards/cchart_status_ov_tracer")
);
const CChartPerusahaanOverviewTracer = lazy(() =>
  import("../cards/cchart_perusahaan_ov_tracer")
);
const CChartBiayaOverviewTracer = lazy(() =>
  import("../cards/cchart_biaya_ov_tracer")
);
const CChartKerja6BlnOverviewTracer = lazy(() =>
  import("../cards/cchart_kerja6bln_ov_tracer")
);
const CChartHubKerjaOverviewTracer = lazy(() =>
  import("../cards/cchart_hubkerja_ov_tracer")
);
const CChartPosisiWiraOverviewTracer = lazy(() =>
  import("../cards/cchart_posisiwira_ov_tracer")
);
const CChartTingkatWiraOverviewTracer = lazy(() =>
  import("../cards/cchart_tingkatwira_ov_tracer")
);
const CChartTingkatPendOverviewTracer = lazy(() =>
  import("../cards/cchart_tingkatpend_ov_tracer")
);
const CChartCariKerjaOverviewTracer = lazy(() =>
  import("../cards/cchart_carikerja_ov_tracer")
);
const CChartAktifCariKerjaOverviewTracer = lazy(() =>
  import("../cards/cchart_aktifcarikerja_ov_tracer")
);
const CChartCaraCariKerjaOverviewTracer = lazy(() =>
  import("../cards/cchart_caracarikerja_ov_tracer")
);

const OvTracer = ({ onDoneLoadData, action = 10 }) => {
  const result = useLoaderData();
  const listFilter = useRecoilValue(masterFilterOvTracer);
  const [currentYear, setCurrentYear] = useState({
    label: "Semua Tahun",
    value: null,
  });
  const [currentProdi, setCurrentProdi] = useState({
    label: "Semua Program Studi",
    value: null,
  });
  const [getOverview, setGetOverview] = useState(false);
  const [getStatusOv, setGetStatusOv] = useState(false);
  const [getGajiOv, setGetGajiOv] = useState(false);
  const [getWaktuOv, setGetWaktuOv] = useState(false);
  const [getKerjaOv, setGetKerjaOv] = useState(false);
  const [getHubkerjaOv, setGetHubkerjaOv] = useState(false);
  const [getPerusahaanOv, setGetPerusahaanOv] = useState(false);
  const [getPosWiraOv, setGetPosWiraOv] = useState(false);
  const [getTingkatWiraOv, setGetTingkatWiraOv] = useState(false);
  const [getTingkatPendOv, setGetTingkatPendOv] = useState(false);
  const [getSumberBiayaOv, setGetSumberBiayaOv] = useState(false);
  const [getTingkatKomOv, setGetTingkatKomOv] = useState(false);
  const [getTingkatKerjaOv, setGetTingkatKerjaOv] = useState(false);
  const [getMetBelajarOv, setGetMetBelajarOv] = useState(false);
  const [getCariKerjaOv, setGetCariKerjaOv] = useState(false);
  const [getBedaKerjaOv, setGetBedaKerjaOv] = useState(false);
  const [filterTahun, setFilterTahun] = useState([]);

  const getOvLoader = () => {
    if (result.filter.status === "fulfilled") {
      const res = result.filter.value;
    }
  };

  useEffect(() => {
    const getYear = async () => {
      try {
        const res = await getDataMasterFilterOvTracer();
        if (res.status) {
          const tahun = res.data.tahun;
          const listTahun = [];

          tahun.map((tahun) => {
            const t = {
              label: tahun,
              value: tahun,
            };

            listTahun.unshift(t);
          });
          listTahun.unshift({ label: "Semua Tahun", value: null });
          setFilterTahun(listTahun);
        }
      } catch (error) {
        console.error("Error while fetching data");
      }
    };
    getYear();
  }, []);

  const onChangeYear = (value) => {
    setCurrentYear(value);
  };

  const onChangeProdi = (value) => {
    setCurrentProdi(value);
  };

  const statusLoadData = (value) => {
    switch (value) {
      case 10:
        setGetOverview(true);
        break;
      case 20:
        setGetStatusOv(true);
        break;
      case 30:
        setGetGajiOv(true);
        break;
      case 40:
        setGetWaktuOv(true);
        break;
      case 50:
        setGetKerjaOv(true);
        break;
      case 60:
        setGetHubkerjaOv(true);
        break;
      case 70:
        setGetPerusahaanOv(true);
        break;
      case 80:
        setGetPosWiraOv(true);
        break;
      case 90:
        setGetTingkatWiraOv(true);
        break;
      case 100:
        setGetTingkatPendOv(true);
        break;
      case 110:
        setGetSumberBiayaOv(true);
        break;
      case 120:
        setGetTingkatKomOv(true);
        break;
      case 130:
        setGetTingkatKerjaOv(true);
        break;
      case 140:
        setGetMetBelajarOv(true);
        break;
      case 150:
        setGetCariKerjaOv(true);
        break;
    }
  };

  useEffect(() => {
    if (
      getOverview &&
      getStatusOv &&
      getGajiOv &&
      getWaktuOv &&
      getKerjaOv &&
      getHubkerjaOv &&
      getPerusahaanOv &&
      getPosWiraOv &&
      getTingkatWiraOv &&
      getTingkatPendOv &&
      getSumberBiayaOv &&
      getTingkatKomOv &&
      getTingkatKerjaOv &&
      getMetBelajarOv &&
      getCariKerjaOv
    ) {
      onDoneLoadData(true);
      getOvLoader();
    }
  }, [
    getOverview,
    getStatusOv,
    getGajiOv,
    getWaktuOv,
    getKerjaOv,
    getHubkerjaOv,
    getPerusahaanOv,
    getPosWiraOv,
    getTingkatWiraOv,
    getTingkatPendOv,
    getSumberBiayaOv,
    getTingkatKomOv,
    getTingkatKerjaOv,
    getMetBelajarOv,
    getCariKerjaOv,
  ]);

  useEffect(() => {
    if (action === 20) {
      const { kode_prodi, jenjang, prodi } = getCurrentUser();
      const curProdi = { label: `${jenjang} - ${prodi}`, value: kode_prodi };
      setCurrentProdi(curProdi);
    }
  }, []);

  return (
    <Column className="gap-5">
      <Suspense fallback={<SChartOvTracer />}>
        <ChartOvTracer action={action} onDoneLoadData={statusLoadData} />
      </Suspense>
      <Row className="gap-3 flex-col sm:flex-row">
        {/* Filter Tahun */}
        {action !== 20 && (
          <SelectView
            width="w-full sm:w-80"
            initial={currentProdi}
            options={listFilter.prodi}
            onChangeOptions={onChangeProdi}
            zindex="z-20"
          />
        )}
        <SelectView
          width="w-full sm:w-40"
          initial={currentYear}
          options={filterTahun}
          onChangeOptions={onChangeYear}
          zindex="z-10"
        />
      </Row>
      <Suspense fallback={<SChartOvTracer action={20} />}>
        <CChartStatusOverviewTracer
          prodi={currentProdi}
          tahun={currentYear}
          onDoneLoadData={statusLoadData}
        />
      </Suspense>
      <div>
        <GridCol className="grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5">
          <div className="h-full col-auto sm:col-span-2 xl:col-auto">
            <GridRow className="h-full gap-5">
              <CNumRata2GajiOverviewTracer
                prodi={currentProdi}
                tahun={currentYear}
                onDoneLoadData={statusLoadData}
              />
              <CNumRata2WaktuOverviewTracer
                prodi={currentProdi}
                tahun={currentYear}
                onDoneLoadData={statusLoadData}
              />
            </GridRow>
          </div>
          <Suspense fallback={<SPieOvTracer />}>
            <CChartKerja6BlnOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
              onDoneLoadData={statusLoadData}
            />
          </Suspense>
          <Suspense fallback={<SPieOvTracer />}>
            <CChartHubKerjaOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
              onDoneLoadData={statusLoadData}
            />
          </Suspense>
        </GridCol>
      </div>
      <Suspense fallback={<SChartOvTracer action={20} />}>
        <CChartPerusahaanOverviewTracer
          prodi={currentProdi}
          tahun={currentYear}
          onDoneLoadData={statusLoadData}
        />
      </Suspense>
      <div>
        <GridCol className="grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5">
          <Suspense fallback={<SPieOvTracer />}>
            <CChartPosisiWiraOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
              onDoneLoadData={statusLoadData}
            />
          </Suspense>
          <Suspense fallback={<SPieOvTracer />}>
            <CChartTingkatWiraOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
              onDoneLoadData={statusLoadData}
            />
          </Suspense>
          <Suspense fallback={<SPieOvTracer />}>
            <CChartTingkatPendOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
              onDoneLoadData={statusLoadData}
            />
          </Suspense>
        </GridCol>
      </div>
      <Suspense fallback={<SChartOvTracer action={20} />}>
        <CChartBiayaOverviewTracer
          prodi={currentProdi}
          tahun={currentYear}
          onDoneLoadData={statusLoadData}
        />
      </Suspense>
      <WTableTingkatKomOvTracer
        prodi={currentProdi}
        tahun={currentYear}
        onDoneLoadData={statusLoadData}
      />
      <WTableTingkatKerjaOvTracer
        prodi={currentProdi}
        tahun={currentYear}
        onDoneLoadData={statusLoadData}
      />
      <WTableMetodeBelajarOvTracer
        prodi={currentProdi}
        tahun={currentYear}
        onDoneLoadData={statusLoadData}
      />
      <div>
        <GridCol className="grid-cols-1 xl:grid-cols-2  gap-5">
          <Suspense fallback={<SPieOvTracer />}>
            <CChartCariKerjaOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
              onDoneLoadData={statusLoadData}
            />
          </Suspense>
          <Suspense fallback={<SPieOvTracer />}>
            <CChartAktifCariKerjaOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
              onDoneLoadData={statusLoadData}
            />
          </Suspense>
          {/* <Suspense fallback={<SPieOvTracer />}>
            <CChartCaraCariKerjaOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
              onDoneLoadData={statusLoadData}
            />
          </Suspense> */}
        </GridCol>
      </div>
      <WTableCCKerjaOvTracer
        prodi={currentProdi}
        tahun={currentYear}
        onDoneLoadData={statusLoadData}
      />
      <WTableProCariKerjaOvTracer
        prodi={currentProdi}
        tahun={currentYear}
        onDoneLoadData={statusLoadData}
      />
      <WTableBedaKerjaOvTracer
        prodi={currentProdi}
        tahun={currentYear}
        onDoneLoadData={statusLoadData}
      />
    </Column>
  );
};

export default OvTracer;

export const getProdOvTracer = async () => {
  const [filter] = await Promise.allSettled([
    getFilterPenggunaLulusanController(),
  ]);
  return {
    filter: filter,
  };
};
