import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import RowWisudaTracer from './rows/row_wisuda_tracer'

function TableWisudaTracer({ listWisuda, oviewWisuda, page, limit }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            No
          </TableHeadCell>
          <TableHeadCell>
            Jenjang
          </TableHeadCell>
          <TableHeadCell>
            Program Studi
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Jumlah Lulusan
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Jumlah Mengisi Tracer
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Persentase
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listWisuda.length > 0 ? listWisuda.map((wisuda, idx) => {
          return (
            <RowWisudaTracer
              key={idx}
              idx={idx}
              page={page}
              limit={limit}
              wisuda={wisuda} />
          )
        }) : <TableRow>
          <TableCell colSpan={5} className='text-center text-gray-500'>
            No data to display
          </TableCell>
        </TableRow>}
        {listWisuda.length > 0 && <TableRow striped>
          <TableCell colSpan={3} className={'text-center font-semibold'}>
            Total
          </TableCell>
          {oviewWisuda.map((oview, idx) => {
            return (
              <TableCell key={idx} className={'text-center font-semibold'}>
                {oview.value}
              </TableCell>
            )
          })}
        </TableRow>}
      </TableBody>
    </Table>
  )
}

export default TableWisudaTracer