import { useCallback } from "react";
import * as XLSX from "xlsx";

const useDownloadExcel = ({ fileName }) => {
  const downloadExcel = useCallback(
    (binaryData) => {
      const workbook = XLSX.read(binaryData, { type: "array" });
      const excelData = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelData], { type: "aplication/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.xlsx`;
      link.click();
    },
    [fileName]
  );
  return downloadExcel;
};

export default useDownloadExcel;
