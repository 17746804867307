import React, { useContext, useEffect, useState } from "react";
import { KuesionerContext } from ".";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Card,
  Column,
  Form,
  Headline2,
  HorizontalDivider,
  InputRadioButton,
  InputRadioButtonGrid,
  InputTextArea,
  OutlineInput,
  PrimaryButton,
  RadioButton,
  Row,
  SelectInput,
  TertiaryButton,
  Text,
  Title,
  TransitionPage,
} from "../../shared/ui";
import {
  TbChecks,
  TbChevronsLeft,
  TbChevronsRight,
  TbCircleArrowLeftFilled,
  TbCircleArrowRightFilled,
  TbX,
} from "react-icons/tb";
import imgGraduation from "../../images/graduation.jpg";
import logoUty from "../../images/logo2.png";
import logoUty2 from "../../images/logo.png";
import {
  BodyText,
  CaptionText,
  Headline3Text,
  OverlineText,
} from "../../components/interfaces/text";
import { getMasterProdiController } from "../../controllers/admin_controllers";
import { setupMonth, setupTahun } from "../../shared/ta";
import { postSurveiController } from "../../controllers/global_controllers";
import toast from "react-hot-toast";

const KuesionerPageLayout = () => {
  const [selectedEtika, setSelectedEtika] = useState("");
  const [selectedKeahlian, setSelectedKeahlian] = useState("");
  const [selectedBahasa, setSelectedBahasa] = useState("");
  const [selectedInformasi, setSelectedInformasi] = useState("");
  const [selectedKomunikasi, setSelectedKomunikasi] = useState("");
  const [selectedKerjasama, setSelectedKerjasama] = useState("");
  const [selectedPengembangan, setSelectedPengembangan] = useState("");
  const [selectedKesiapan, setSelectedKesiapan] = useState("");
  const [optionProdi, setOptionProdi] = useState([]);
  const [optionTahun, setOptionTahun] = useState([]);
  const [optionBulan, setOptionBulan] = useState([]);
  const [initProdi, setInitProdi] = useState({
    label: "Pilih Program Studi",
    value: null,
  });
  const [initTahun, setInitTahun] = useState({
    label: "Pilih tahun",
    value: null,
  });
  const [initBulan, setInitBulan] = useState({
    label: "Pilih bulan",
    value: null,
  });
  const [step, setStep] = useState(1);
  const [showDialogInput, setShowDialogInput] = useState(false);
  const [showDialogSubmitFailed, setShowDialogSubmitFailed] = useState(false);
  const [showDialogSubmitSuccess, setShowDialogSubmitSuccess] = useState(false);
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [captcaInput, setCaptchaInput] = useState("");
  const [captchaMessage, setCaptchaMessage] = useState("");
  const [formData, setFormData] = useState({
    pengisi_nama: "",
    pengisi_perusahaan: "",
    pengisi_alamat: "",
    pengisi_jabatan: "",
    pengisi_email: "",
    mhs_nama: "",
    mhs_prodi: "",
    mhs_kerja: "",
    mhs_jabatan: "",
    s_etika: "",
    s_keahlian: "",
    s_asing: "",
    s_teknologi: "",
    s_komunikasi: "",
    s_kerjasama: "",
    s_pengembangan: "",
    s_terjun: "",
    m_keunggulan: "",
    m_kelemahan: "",
    m_dikuasai: "",
    m_saran: "",
  });

  const closeDialogInput = () => {
    setShowDialogInput();
  };

  const closeDialogSubmitFailed = () => {
    setShowDialogSubmitFailed();
  };

  const closeDialogSubmitSuccess = () => {
    setShowDialogSubmitSuccess();
  };

  const regenerateCaptca = () => {
    setNum1(Math.floor(Math.random() * 10));
    setNum2(Math.floor(Math.random() * 10));
    setCaptchaInput("");
    setCaptchaMessage("");
  };

  const option = [
    { label: "Sangat Baik", value: "sangat_baik" },
    { label: "Baik", value: "baik" },
    { label: "Cukup", value: "cukup" },
    { label: "Kurang", value: "kurang" },
  ];

  const handleSelectChange = (e, fieldName) => {
    if (fieldName === "mhs_prodi") {
      setInitProdi(e);
      setFormData({
        ...formData,
        mhs_prodi: e.value.toString(),
      });
    } else if (fieldName === "mhs_kerja_bulan") {
      setInitBulan(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: e.label + " " + (initTahun.value || ""),
      }));
    } else if (fieldName === "mhs_kerja_tahun") {
      setInitTahun(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: (initBulan.label || "") + "" + e.value,
      }));
    }
  };

  const handleRadioChange = (value, radioName) => {
    if (radioName === "s_etika") {
      setSelectedEtika(value);
      setFormData({ ...formData, s_etika: value });
    } else if (radioName === "s_keahlian") {
      setSelectedKeahlian(value);
      setFormData({ ...formData, s_keahlian: value });
    } else if (radioName === "s_asing") {
      setSelectedBahasa(value);
      setFormData({ ...formData, s_asing: value });
    } else if (radioName === "s_teknologi") {
      setSelectedInformasi(value);
      setFormData({ ...formData, s_teknologi: value });
    } else if (radioName === "s_komunikasi") {
      setSelectedKomunikasi(value);
      setFormData({ ...formData, s_komunikasi: value });
    } else if (radioName === "s_kerjasama") {
      setSelectedKerjasama(value);
      setFormData({ ...formData, s_kerjasama: value });
    } else if (radioName === "s_pengembangan") {
      setSelectedPengembangan(value);
      setFormData({ ...formData, s_pengembangan: value });
    } else if (radioName === "s_terjun") {
      setSelectedKesiapan(value);
      setFormData({ ...formData, s_terjun: value });
    }
  };

  const getDataProdi = async () => {
    try {
      const dataProdi = await getMasterProdiController();
      const formattedOptions = dataProdi.data.map((prodi) => ({
        label: `${prodi.jenjang} - ${prodi.prodi}`,
        value: prodi.kode_prodi,
      }));
      setOptionProdi(formattedOptions);
    } catch (error) {
      console.error("error get data prodi", error.message);
    }
  };

  useEffect(() => {
    getDataProdi();
    setOptionTahun(setupTahun({ start: 2003 }));
    setOptionBulan(setupMonth({ start: "Januari" }));
  }, []);

  // err input
  const [errNamaPengisi, setErrNamaPengisi] = useState(null);
  const [errNamaPerusahaan, setErrNamaPerusahaan] = useState(null);
  const [errAlamatPerusahaan, setErrAlamatPerusahaan] = useState(null);
  const [errJabatanPengisi, setErrJabatanPengisi] = useState(null);
  const [errEmailPengisi, setErrEmailPengisi] = useState(null);
  const [errMhsNama, setErrMhsNama] = useState(null);
  const [errMhsJabatan, setErrMhsJabatan] = useState(null);
  const [errEtika, setErrEtika] = useState(null);
  const [errKeahlian, setErrKeahlian] = useState(null);
  const [errAsing, setErrAsing] = useState(null);
  const [errTeknologi, setErrTeknologi] = useState(null);
  const [errKomunikasi, setErrKomunikasi] = useState(null);
  const [errKerjasama, setErrKerjasama] = useState(null);
  const [errPengembangan, setErrPengembangan] = useState(null);
  const [errTerjun, setErrTerjun] = useState(null);
  const [errKeunggulan, setErrKeunggulan] = useState(null);
  const [errKelemahan, setErrKelemahan] = useState(null);
  const [errDikuasai, setErrDikuasai] = useState(null);
  const [errSaran, setErrSaran] = useState(null);
  const [errWrongCaptcha, setErrWrongCaptcha] = useState(null);
  const [errEmptyCaptcha, setErrEmptyCaptcha] = useState(null);

  // err Length
  const [errLengthNamaPengisi, setErrLengthNamaPengisi] = useState(null);
  const [errLengthNamaPerusahaan, setErrLengthNamaPerusahaan] = useState(null);
  const [errLengthAlamatPerusahaan, setErrLengthAlamatPerusahaan] =
    useState(null);
  const [errLengthJabatanPengisi, setErrLengthJabatanPengisi] = useState(null);
  const [errLengthEmailPengisi, setErrLengthEmailPengisi] = useState(null);
  const [errLenghtMhsNama, setErrLenghtMhsNama] = useState(null);
  const [errLenghtMhsJabatan, setErrLenghtMhsJabatan] = useState(null);
  const [errLenghtKeunggulan, setErrLenghtKeunggulan] = useState(null);
  const [errLenghtKelemahan, setErrLenghtKelemahan] = useState(null);
  const [errLenghtDikuasai, setErrLenghtDikuasai] = useState(null);
  const [errLenghtSaran, setErrLenghtSaran] = useState(null);

  const nextStep = () => {
    if (step === 2) {
      // err input
      if (!formData.pengisi_nama) {
        setErrNamaPengisi("Nama pengisi tidak boleh kosong");
        return;
      } else if (formData.pengisi_nama.length !== null) {
        setErrNamaPengisi();
      }

      if (!formData.pengisi_perusahaan) {
        setErrNamaPerusahaan("Nama perusahaan/instansi tidak boleh kosong");
        return;
      } else if (formData.pengisi_perusahaan !== null) {
        setErrNamaPerusahaan();
      }

      if (!formData.pengisi_alamat) {
        setErrAlamatPerusahaan("Alamat perusahaan/instansi tidak boleh kosong");
        return;
      } else if (formData.pengisi_alamat !== null) {
        setErrAlamatPerusahaan();
      }

      if (!formData.pengisi_jabatan) {
        setErrJabatanPengisi("Posisi jabatan pengisi tidak boleh kosong");
        return;
      } else if (formData.pengisi_jabatan !== null) {
        setErrJabatanPengisi();
      }

      if (!formData.pengisi_email) {
        setErrEmailPengisi("Email pengisi tidak boleh kosong");
        return;
      } else if (formData.pengisi_email !== null) {
        setErrEmailPengisi();
      }

      //  err length
      if (formData.pengisi_nama.length < 4) {
        setErrLengthNamaPengisi(
          "Inputan nama pengisi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_nama.length >= 4) {
        setErrLengthNamaPengisi(null);
      }

      if (formData.pengisi_perusahaan.length < 4) {
        setErrLengthNamaPerusahaan(
          "Inputan nama perusahaan/instansi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_perusahaan.length >= 4) {
        setErrLengthNamaPerusahaan(null);
      }

      if (formData.pengisi_alamat.length < 4) {
        setErrLengthAlamatPerusahaan(
          "Inputan alamat perusahaan/instansi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_alamat.length >= 4) {
        setErrLengthAlamatPerusahaan(null);
      }

      if (formData.pengisi_jabatan.length < 4) {
        setErrLengthJabatanPengisi(
          "Inputan posisi jabatan pengisi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_jabatan.length >= 4) {
        setErrLengthJabatanPengisi(null);
      }

      if (formData.pengisi_email.length < 4) {
        setErrLengthEmailPengisi(
          "Inputan email pengisi harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.pengisi_email.length >= 4) {
        setErrLengthEmailPengisi(null);
      }
    }

    if (step === 3) {
      if (!formData.mhs_nama) {
        setErrMhsNama("Nama lulusan tidak boleh kosong");
        return;
      } else if (formData.mhs_nama !== null) {
        setErrMhsNama(null);
      }

      if (!formData.mhs_jabatan) {
        setErrMhsJabatan("Jabatan/posisi lulusan tidak boleh kosong");
        return;
      } else if (formData.mhs_jabatan !== null) {
        setErrMhsJabatan(null);
      }

      if (formData.mhs_nama.length < 4) {
        setErrLenghtMhsNama("Inputan nama lulusan harus lebih dari 3 karakter");
        return;
      } else if (formData.mhs_nama.length <= 4) {
        setErrLenghtMhsNama(null);
      }

      if (formData.mhs_jabatan.length < 4) {
        setErrLenghtMhsJabatan(
          "Inputan Jabatan/posisi lulusan harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.mhs_jabatan.length <= 4) {
        setErrLenghtMhsJabatan(null);
      }
    }

    if (step === 4) {
      // err input
      if (!formData.s_etika) {
        setErrEtika("Sikap/etika tidak boleh kosong");
        return;
      } else if (formData.s_etika !== null) {
        setErrEtika(null);
      }

      if (!formData.s_keahlian) {
        setErrKeahlian("Keahlian tidak boleh kosong");
        return;
      } else if (formData.s_keahlian !== null) {
        setErrKeahlian(null);
      }

      if (!formData.s_asing) {
        setErrAsing("Kemampuan berbahasa asing tidak boleh kosong");
        return;
      } else if (formData.s_asing !== null) {
        setErrAsing(null);
      }

      if (!formData.s_teknologi) {
        setErrTeknologi("Penggunaan teknologi tidak boleh kosong");
        return;
      } else if (formData.s_teknologi !== null) {
        setErrTeknologi(null);
      }

      if (!formData.s_komunikasi) {
        setErrKomunikasi("Kemampuan berkomunikasi tidak boleh kosong");
        return;
      } else if (formData.s_komunikasi !== null) {
        setErrKomunikasi(null);
      }

      if (!formData.s_kerjasama) {
        setErrKerjasama("Kemampuan kerjasama tidak boleh kosong");
        return;
      } else if (formData.s_kerjasama !== null) {
        setErrKerjasama(null);
      }

      if (!formData.s_pengembangan) {
        setErrPengembangan("Kemampuan pengembangan diri tidak boleh kosong");
        return;
      } else if (formData.s_pengembangan !== null) {
        setErrPengembangan(null);
      }

      if (!formData.s_pengembangan) {
        setErrPengembangan("Kemampuan pengembangan diri tidak boleh kosong");
        return;
      } else if (formData.s_pengembangan !== null) {
        setErrPengembangan(null);
      }

      if (!formData.s_terjun) {
        setErrTerjun("Kesiapan terjun dimasyarakat tidak boleh kosong");
        return;
      } else if (formData.s_terjun !== null) {
        setErrTerjun(null);
      }
    }

    if (step === 5) {
      //  err input
      if (!formData.m_keunggulan) {
        setErrKeunggulan("Keunggulan tidak boleh kosong");
        return;
      } else if (formData.m_keunggulan !== null) {
        setErrKeunggulan(null);
      }
      if (!formData.m_kelemahan) {
        setErrKelemahan("Kelemahan/kekurangan tidak boleh kosong");
        return;
      } else if (formData.m_kelemahan !== null) {
        setErrKelemahan(null);
      }
      if (!formData.m_dikuasai) {
        setErrDikuasai("Keterampilan/kemampuan/kompetensi tidak boleh kosong");
        return;
      } else if (formData.m_dikuasai !== null) {
        setErrDikuasai(null);
      }
      if (!formData.m_saran) {
        setErrSaran("Saran tidak boleh kosong");
        return;
      } else if (formData.m_saran !== null) {
        setErrSaran(null);
      }

      // err lenght
      if (formData.m_keunggulan.length < 4) {
        setErrLenghtKeunggulan(
          "Inputan Keunggulan lulusan harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.m_keunggulan.length <= 4) {
        setErrKeunggulan(null);
      }

      if (formData.m_kelemahan.length < 4) {
        setErrLenghtKelemahan(
          "Inputan kelemahan lulusan harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.m_kelemahan.length <= 4) {
        setErrKelemahan(null);
      }

      if (formData.m_dikuasai.length < 4) {
        setErrLenghtDikuasai(
          "Inputan keterampilan/kemampuan/kompetensi lulusan harus lebih dari 3 karakter"
        );
        return;
      } else if (formData.m_dikuasai.length <= 4) {
        setErrLenghtDikuasai(null);
      }

      if (formData.m_saran.length < 4) {
        setErrLenghtSaran("Inputan saran lulusan harus lebih dari 3 karakter");
        return;
      } else if (formData.m_saran.length <= 4) {
        setErrLenghtSaran(null);
      }
    }

    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const backSubmit = () => {
    setStep(2);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await postSurveiController(formData);

    if (!captcaInput || captcaInput.length < 0 || captcaInput === null) {
      setErrEmptyCaptcha("Captcha tidak boleh kosong ");
      setErrWrongCaptcha(null);
      regenerateCaptca();
      return;
    }

    if (parseInt(captcaInput) !== num1 + num2) {
      setErrWrongCaptcha("Captcha salah, silahkan input dengan benar!");
      setErrEmptyCaptcha(null);
      regenerateCaptca();
      return;
    }

    if (!result.status) {
      setShowDialogSubmitFailed(true);
      return;
    }

    toast.success("Berhasil mngirim kuesioner");

    setFormData({
      pengisi_nama: "",
      pengisi_perusahaan: "",
      pengisi_alamat: "",
      pengisi_jabatan: "",
      pengisi_email: "",
      mhs_nama: "",
      mhs_prodi: "",
      mhs_kerja: "",
      mhs_jabatan: "",
      s_etika: setSelectedEtika(),
      s_keahlian: setSelectedKeahlian(),
      s_asing: setSelectedBahasa(),
      s_teknologi: setSelectedInformasi(),
      s_komunikasi: setSelectedKomunikasi(),
      s_kerjasama: setSelectedKerjasama(),
      s_pengembangan: setSelectedPengembangan(),
      s_terjun: setSelectedKeahlian(),
      m_keunggulan: "",
      m_kelemahan: "",
      m_dikuasai: "",
      m_saran: "",
    });

    setTimeout(() => {
      setStep(1);
    }, 2000);
  };

  return (
    <>
      <div className="grid md:grid-cols-3 h-screen ">
        <div className=" bg-gradient-to-t from-blue-800 to-cyan-300 hidden md:inline h-screen">
          <div className="relative ">
            <div className="relative">
              <img
                src={imgGraduation}
                alt="uty"
                className="min-h-screen opacity-50 z-10 "
              />
              <img
                src={logoUty}
                alt="logoUty"
                className="absolute top-6 left-6 w-auto h-10 z-20"
              />
            </div>
            <div className="absolute top-0 left-0 h-screen">
              <div className="flex justify-end items-end p-6 space-y-10 h-screen">
                <div className="space-y-4 mb-10">
                  <div>
                    <Headline2 className={"text-white"}>
                      Kuesioner Pengguna
                    </Headline2>
                    <Headline2 className={"text-white"}>Lulusan</Headline2>
                  </div>

                  <Text className={"text-white"}>
                    Selamat datang pada halaman form kuesioner pengguna lulusan.
                    Form kuesioner ini digunakan untuk mengumpulkan data lulusan
                    dari Universitas Teknologi Yogyakarta.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Column className="md:col-span-2 bg-none h-screen overflow-y-auto scrollbar-hide ">
          <Form onSubmit={handleSubmit}>
            {step === 1 && (
              <TransitionPage horizontal={true}>
                <Column className="min-h-screen py-6 px-6 md:px-16 space-y-4">
                  <div className="flex justify-center">
                    <img src={logoUty2} alt="logo" className="h-28" />
                  </div>
                  <Column className="px-6">
                    <hr className="w-full h-[0.1rem] bg-gradient-to-r from-white via-slate-600 to-white my-4" />
                  </Column>
                  <Column>
                    <Text className="font-semibold">Kepada Yth. </Text>
                    <Text>Pengguna Lulusan UTY</Text>
                  </Column>

                  <Text className="text-justify">
                    Dalam rangka meningkatkan kualitas lulusan Universitas
                    Teknologi Yogyakarta, kami melakukan survey terhadap
                    pengguna lulusan sebagai salah satu indikator evaluasi
                    perbaikan Universitas Teknologi Yogyakarta. Kami sangat
                    mengharapkan kesediaan Bapak/Ibu untuk dapat memberikan
                    penilaian terhadap lulusan Universitas Teknologi Yogyakarta
                    yang bekerja pada instansi atau perusahaan yang Bapak/Ibu
                    pimpin dengan cara mengisi kuisioner pengguna lulusan
                    Universitas Teknologi Yogyakarta berikut ini.
                  </Text>
                  <Text className="text-justify">
                    Penilaian ini akan menjadi umpan balik bagi kami dalam
                    melakukan evaluasi terhadap lulusan Universitas Teknologi
                    Yogyakarta. Penilaian dan masukan yang Bapak/Ibu berikan
                    sangat berharga bagi kami dan kami akan menjaga kerahasiaan
                    informasi yang diberikan tersebut.
                  </Text>
                  <Text className="text-justify">
                    Atas nama Rektor Universitas Teknologi Yogyakarta, kami
                    mengucapkan terima kasih yang sebesar-besarnya atas
                    kesediaan Bapak/Ibu meluangkan waktu sejenak untuk
                    berpartisapasi dalam pengisian survey pengguna lulusan
                    Universitas Teknologi Yogyakarta.
                  </Text>
                  <Column>
                    <Text>Hormat Kami</Text>
                    <Text>Tim Tracer Study</Text>
                    <Text className="font-semibold">
                      Universitas Teknologi Yogyakarta
                    </Text>
                  </Column>

                  <Row className="flex justify-end items-center flex-1">
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"red"}
                        trailingIconLeft={
                          <TbChevronsLeft className="w-6 h-6" />
                        }
                        trailingIcon={<TbChevronsRight className="w-6 h-6" />}
                      >
                        Mulai
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 2 && (
              <TransitionPage horizontal={true}>
                <Column className="min-h-screen p-6 ">
                  <Column className={"h-fit bg-white rounded-xl border"}>
                    <Column className={"px-6 py-4 space-y-1"}>
                      <Title>Identitas Pengisi Perusahaan</Title>
                      <CaptionText>Lengkapi data pengisi</CaptionText>
                    </Column>

                    <HorizontalDivider removeMargin />
                    <Column className={"p-6 space-y-6 pb-9"}>
                      <Column>
                        <OutlineInput
                          id="pengisi_nama"
                          label="Nama Pengisi"
                          labelClassName={"text-gray-600"}
                          placeholder="Masukkan nama pengisi"
                          name="pengisi_nama"
                          defaultValue={formData.pengisi_nama}
                          onChange={handleInputChange}
                          inputWidth={"w-full"}
                          required
                        />
                        {errNamaPengisi ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errNamaPengisi}
                          </OverlineText>
                        ) : errLengthNamaPengisi ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLengthNamaPengisi}
                          </OverlineText>
                        ) : null}
                      </Column>

                      <Column>
                        <OutlineInput
                          id="pengisi_perusahaan"
                          label="Nama Perusahaan/Instansi"
                          labelClassName={"text-gray-600"}
                          placeholder="Masukkan nama Perusahaan/Instansi"
                          name="pengisi_perusahaan"
                          defaultValue={formData.pengisi_perusahaan}
                          onChange={handleInputChange}
                          inputWidth={"w-full"}
                          required
                        />
                        {errNamaPerusahaan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errNamaPerusahaan}
                          </OverlineText>
                        ) : errLengthNamaPerusahaan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLengthNamaPerusahaan}
                          </OverlineText>
                        ) : null}
                      </Column>

                      <Column>
                        <OutlineInput
                          id="pengisi_alamat"
                          label="Alamat Perusahaan/Instansi"
                          labelClassName={"text-gray-600"}
                          placeholder="Masukkan Alamat Perusahaan/Instansi"
                          name="pengisi_alamat"
                          defaultValue={formData.pengisi_alamat}
                          onChange={handleInputChange}
                          inputWidth={"w-full"}
                          required
                        />
                        {errAlamatPerusahaan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errAlamatPerusahaan}
                          </OverlineText>
                        ) : errLengthAlamatPerusahaan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLengthAlamatPerusahaan}
                          </OverlineText>
                        ) : null}
                      </Column>

                      <Column>
                        <OutlineInput
                          id="pengisi_jabatan"
                          label="Posisi Jabatan Pengisi"
                          labelClassName={"text-gray-600"}
                          placeholder="Masukkan posisi jabatan pengisi"
                          name="pengisi_jabatan"
                          defaultValue={formData.pengisi_jabatan}
                          onChange={handleInputChange}
                          inputWidth={"w-full"}
                          required
                        />
                        {errJabatanPengisi ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errJabatanPengisi}
                          </OverlineText>
                        ) : errLengthJabatanPengisi ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLengthJabatanPengisi}
                          </OverlineText>
                        ) : null}
                      </Column>

                      <Column>
                        <OutlineInput
                          id="pengisi_email"
                          label="Email Pengisi"
                          labelClassName={"text-gray-600"}
                          placeholder="Masukkan email pengisi"
                          name="pengisi_email"
                          defaultValue={formData.pengisi_email}
                          onChange={handleInputChange}
                          inputWidth={"w-full"}
                          required
                        />
                        {errEmailPengisi ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errEmailPengisi}
                          </OverlineText>
                        ) : errLengthEmailPengisi ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLengthEmailPengisi}
                          </OverlineText>
                        ) : null}
                      </Column>
                    </Column>
                  </Column>
                  <Row className="flex justify-between mt-6">
                    <ButtonHover>
                      <TertiaryButton
                        onClick={previousStep}
                        color={"blue"}
                        leadingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 3 && (
              <TransitionPage horizontal={true}>
                <Column className="min-h-screen p-6">
                  <Column className={"h-fit bg-white rounded-xl border"}>
                    <Column className={"px-6 py-4 space-y-1"}>
                      <Title>Identitas Lulusan UTY</Title>
                      <CaptionText>Lengkapi data lulusan</CaptionText>
                    </Column>

                    <HorizontalDivider removeMargin />
                    <Column className={"p-6 space-y-6 pb-9"}>
                      <Column>
                        <OutlineInput
                          id="mhs_nama"
                          label="Nama Lulusan UTY yang dinilai"
                          labelClassName={"text-gray-600"}
                          placeholder="Masukkan nama lulusan UTY"
                          name="mhs_nama"
                          defaultValue={formData.mhs_nama}
                          onChange={handleInputChange}
                          inputWidth={"w-full"}
                          required
                        />
                        {errMhsNama ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errMhsNama}
                          </OverlineText>
                        ) : errLenghtMhsNama ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLenghtMhsNama}
                          </OverlineText>
                        ) : null}
                      </Column>

                      <Column className="z-20 ">
                        <SelectInput
                          label={
                            <>
                              <span>
                                Program studi lulusan UTY yang dinilai {"("}
                                <span className="italic">opsional</span>
                                {")"}
                              </span>
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          initial={initProdi}
                          name="mhs_prodi"
                          onChange={(prodi) =>
                            handleSelectChange(prodi, "mhs_prodi")
                          }
                          btnClassName={"py-2.5 pl-4 pr-3"}
                          options={optionProdi}
                          background={"bg-gray-50"}
                        />
                      </Column>

                      <Column className="grid md:grid-cols-4 lg:grid-cols-3  gap-x-4 gap-y-3 md:gap-y-0 z-10">
                        <Column className="md:col-span-2 z-10">
                          <SelectInput
                            label={
                              <>
                                <span>
                                  Tanggal masuk perusahaan/instansi {"("}
                                  <span className="italic">opsional</span>
                                  {")"}
                                </span>
                              </>
                            }
                            background={"bg-gray-50"}
                            labelClassName={"text-gray-600"}
                            width={"w-full"}
                            initial={initBulan}
                            name="mhs_kerja_bulan"
                            onChange={(bulan) =>
                              handleSelectChange(bulan, "mhs_kerja_bulan")
                            }
                            btnClassName={"py-2.5 pl-4 pr-3"}
                            maxHeight="max-h-36"
                            options={optionBulan}
                          />
                        </Column>
                        <Column
                          className={
                            "md:col-span-2 lg:col-span-1 z-0  md:mt-[46px]  lg:mt-[26px]"
                          }
                        >
                          <SelectInput
                            width={"w-full"}
                            initial={initTahun}
                            name="mhs_kerja_tahun"
                            onChange={(tahun) =>
                              handleSelectChange(tahun, "mhs_kerja_tahun")
                            }
                            btnClassName={"py-2.5 pl-4 pr-3"}
                            options={optionTahun}
                            background={"bg-gray-50"}
                          />
                        </Column>
                      </Column>
                      <Column>
                        <OutlineInput
                          id="mhs_jabatan"
                          label="Jabatan/Posisi lulusan UTY yang dinilai"
                          labelClassName={"text-gray-600"}
                          placeholder="Masukkan jabatan lulusan UTY"
                          name="mhs_jabatan"
                          defaultValue={formData.mhs_jabatan}
                          onChange={handleInputChange}
                          inputWidth={"w-full"}
                          required
                        />
                        {errMhsJabatan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errMhsJabatan}
                          </OverlineText>
                        ) : errLenghtMhsJabatan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLenghtMhsJabatan}
                          </OverlineText>
                        ) : null}
                      </Column>
                    </Column>
                  </Column>
                  <Row className="flex justify-between mt-6">
                    <ButtonHover>
                      <TertiaryButton
                        onClick={previousStep}
                        color={"blue"}
                        leadingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 4 && (
              <TransitionPage horizontal={true}>
                <Column className="min-h-screen p-6">
                  <Column className={"h-fit bg-white rounded-xl border"}>
                    <Column className={"px-6 py-4 space-y-1"}>
                      <Title>Kemampuan Lulusan UTY</Title>
                      <CaptionText>Lengkapi data lulusan</CaptionText>
                    </Column>

                    <HorizontalDivider removeMargin />
                    <Column className={"p-6 space-y-6 pb-9"}>
                      {/* <Column className="gap-1.5">
                        <Text className="font-manrope font-medium">
                          Bagaimana sikap/etika lulusan UTY yang bekerja
                          ditempat Bapak/Ibu?{" "}
                          <span className="text-red-600">*</span>
                        </Text>
                        <Card className="w-full p-0 overflow-hidden shadow-none">
                          <Column className="divide-y">
                            <RadioButton
                              id="s_etika_1"
                              name="s_etika"
                              value="Sangat Baik"
                              label="Sangat Baik"
                              onChange={(value) =>
                                handleRadioChange(value, "s_etika")
                              }
                              checked={formData.s_etika === "Sangat Baik"}
                              className="w-full pl-3 hover:bg-slate-50"
                            />
                            <RadioButton
                              id="s_etika_2"
                              name="s_etika"
                              value="Baik"
                              label="Baik"
                              onChange={(value) =>
                                handleRadioChange(value, "s_etika")
                              }
                              checked={formData.s_etika === "Baik"}
                              className="w-full pl-3 hover:bg-slate-50"
                            />
                            <RadioButton
                              id="s_etika_3"
                              name="s_etika"
                              value="Cukup"
                              label="Cukup"
                              onChange={(value) =>
                                handleRadioChange(value, "s_etika")
                              }
                              checked={formData.s_etika === "Cukup"}
                              className="w-full pl-3 hover:bg-slate-50"
                            />
                            <RadioButton
                              id="s_etika_4"
                              name="s_etika"
                              value="Kurang"
                              label="Kurang"
                              onChange={(value) =>
                                handleRadioChange(value, "s_etika")
                              }
                              checked={formData.s_etika === "Kurang"}
                              className="w-full pl-3 hover:bg-slate-50"
                            />
                          </Column>
                        </Card>
                      </Column> */}

                      <Column>
                        <InputRadioButtonGrid
                          label={
                            <>
                              Bagaimana{" "}
                              <span className="font-bold">sikap/etika</span>{" "}
                              lulusan UTY yang bekerja di tempat Bapak/Ibu?
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          checkedValue={selectedEtika}
                          id="s_etika"
                          name="s_etika"
                          value={option.value}
                          onChange={(etika) =>
                            handleRadioChange(etika, "s_etika")
                          }
                          radioOptions={option}
                          required
                        />
                        {errEtika && (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errEtika}
                          </OverlineText>
                        )}
                      </Column>
                      <Column>
                        <InputRadioButtonGrid
                          label={
                            <>
                              Bagaimana{" "}
                              <span className="font-bold">keahlian</span>{" "}
                              lulusan UTY yang bekerja di tempat Bapak/Ibu
                              berdasarkan bidang ilmunya?
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          checkedValue={selectedKeahlian}
                          id="s_keahlian"
                          name="s_keahlian"
                          onChange={(keahlian) =>
                            handleRadioChange(keahlian, "s_keahlian")
                          }
                          radioOptions={option}
                          required
                        />
                        {errKeahlian && (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errKeahlian}
                          </OverlineText>
                        )}
                      </Column>

                      <Column>
                        <InputRadioButtonGrid
                          label={
                            <>
                              Bagaimana kemampuan{" "}
                              <span className="font-bold">berbahasa asing</span>{" "}
                              lulusan UTY yang bekerja di tempat Bapak/Ibu?
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          checkedValue={selectedBahasa}
                          id="s_asing"
                          name="s_asing"
                          onChange={(asing) =>
                            handleRadioChange(asing, "s_asing")
                          }
                          radioOptions={option}
                          required
                        />
                        {errAsing && (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errAsing}
                          </OverlineText>
                        )}
                      </Column>

                      <Column>
                        <InputRadioButtonGrid
                          label={
                            <>
                              Bagaimana kemampuan{" "}
                              <span className="font-bold">
                                penggunaan teknologi informasi
                              </span>{" "}
                              lulusan UTY yang bekerja di tempat Bapak/Ibu?
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          checkedValue={selectedInformasi}
                          id="s_teknologi"
                          name="s_teknologi"
                          onChange={(teknologi) =>
                            handleRadioChange(teknologi, "s_teknologi")
                          }
                          radioOptions={option}
                          required
                        />
                        {errTeknologi && (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errTeknologi}
                          </OverlineText>
                        )}
                      </Column>

                      <Column>
                        <InputRadioButtonGrid
                          label={
                            <>
                              Bagaimana kemampuan{" "}
                              <span className="font-bold">berkomunikasi</span>{" "}
                              lulusan UTY yang bekerja di tempat Bapak/Ibu?
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          checkedValue={selectedKomunikasi}
                          id="s_komunikasi"
                          name="s_komunikasi"
                          onChange={(komunikasi) =>
                            handleRadioChange(komunikasi, "s_komunikasi")
                          }
                          radioOptions={option}
                          required
                        />
                        {errKomunikasi && (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errKomunikasi}
                          </OverlineText>
                        )}
                      </Column>

                      <Column>
                        <InputRadioButtonGrid
                          label={
                            <>
                              Bagaimana kemampuan{" "}
                              <span className="font-bold">kerjasama</span>{" "}
                              lulusan UTY yang bekerja di tempat Bapak/Ibu?
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          checkedValue={selectedKerjasama}
                          id="s_kerjasama"
                          name="s_kerjasama"
                          onChange={(kerjasama) =>
                            handleRadioChange(kerjasama, "s_kerjasama")
                          }
                          radioOptions={option}
                          required
                        />
                        {errKerjasama && (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errKerjasama}
                          </OverlineText>
                        )}
                      </Column>

                      <Column>
                        <InputRadioButtonGrid
                          label={
                            <>
                              Bagaimana kemampuan{" "}
                              <span className="font-bold">
                                pengembangan diri
                              </span>{" "}
                              lulusan UTY yang bekerja di tempat Bapak/Ibu?
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          checkedValue={selectedPengembangan}
                          id="s_pengembangan"
                          name="s_pengembangan"
                          onChange={(pengembangan) =>
                            handleRadioChange(pengembangan, "s_pengembangan")
                          }
                          radioOptions={option}
                          required
                        />
                        {errPengembangan && (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errPengembangan}
                          </OverlineText>
                        )}
                      </Column>

                      <Column>
                        <InputRadioButtonGrid
                          label={
                            <>
                              Bagaimana{" "}
                              <span className="font-bold">
                                kesiapan terjun di masyarakat
                              </span>{" "}
                              lulusan UTY yang bekerja di tempat Bapak/Ibu?
                            </>
                          }
                          labelClassName={"text-gray-600"}
                          checkedValue={selectedKesiapan}
                          id="s_terjun"
                          name="s_terjun"
                          onChange={(terjun) =>
                            handleRadioChange(terjun, "s_terjun")
                          }
                          radioOptions={option}
                          required
                        />
                        {errTerjun && (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errTerjun}
                          </OverlineText>
                        )}
                      </Column>
                    </Column>
                  </Column>
                  <Row className="flex justify-between mt-6 ">
                    <ButtonHover>
                      <TertiaryButton
                        onClick={previousStep}
                        color={"blue"}
                        leadingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 5 && (
              <TransitionPage horizontal={true}>
                <Column className="min-h-screen p-6">
                  <Column className={"h-fit bg-white rounded-xl border"}>
                    <Column className={"px-6 py-4 space-y-1"}>
                      <Title>
                        Keunggulan, Kekurangan, Tantangan dan Saran Lulusan UTY
                      </Title>
                      <CaptionText>Lengkapi data lulusan</CaptionText>
                    </Column>

                    <HorizontalDivider removeMargin />
                    <Column className={"p-6 space-y-6 pb-9"}>
                      <Column>
                        <InputTextArea
                          label="Menurut Bapak/Ibu, Apa keunggulan dari lulusan Universitas Teknologi Yogyakarta?"
                          labelClassName={"text-gray-600"}
                          name="m_keunggulan"
                          value={formData.m_keunggulan}
                          onChange={handleInputChange}
                          required
                        />
                        {errKeunggulan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errKeunggulan}
                          </OverlineText>
                        ) : errLenghtKeunggulan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLenghtKeunggulan}
                          </OverlineText>
                        ) : null}
                      </Column>

                      <Column>
                        <InputTextArea
                          label="Menurut Bapak/Ibu, Apa kelemahan/kekurangan dari lulusan Universitas Teknologi Yogyakarta?"
                          labelClassName={"text-gray-600"}
                          name="m_kelemahan"
                          value={formData.m_kelemahan}
                          onChange={handleInputChange}
                          required
                        />
                        {errKelemahan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errKelemahan}
                          </OverlineText>
                        ) : errLenghtKelemahan ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLenghtKelemahan}
                          </OverlineText>
                        ) : null}
                      </Column>
                      <Column>
                        <InputTextArea
                          label="Mengingat berkembangnya tantangan kerja terbaru di lembaga Bapak/Ibu, keterampilan/kemampuan/kompetensi apakah yang perlu diberikan dan dikuasai oleh lulusan Universitas Teknologi Yogyakarta?"
                          labelClassName={"text-gray-600"}
                          name="m_dikuasai"
                          value={formData.m_dikuasai}
                          onChange={handleInputChange}
                          required
                        />
                        {errDikuasai ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errDikuasai}
                          </OverlineText>
                        ) : errLenghtDikuasai ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLenghtDikuasai}
                          </OverlineText>
                        ) : null}
                      </Column>
                      <Column>
                        <InputTextArea
                          label="Apa harapan, Saran, dan/atau masukan Bapak/Ibu terhadap lulusan Universitas Teknologi Yogyakarta?"
                          labelClassName={"text-gray-600"}
                          name="m_saran"
                          value={formData.m_saran}
                          onChange={handleInputChange}
                          required
                        />
                        {errSaran ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errSaran}
                          </OverlineText>
                        ) : errLenghtSaran ? (
                          <OverlineText className="text-red-600 px-4 mt-2">
                            {errLenghtSaran}
                          </OverlineText>
                        ) : null}
                      </Column>
                    </Column>
                  </Column>
                  <Row className="flex justify-between mt-6 ">
                    <ButtonHover>
                      <TertiaryButton
                        onClick={previousStep}
                        color={"blue"}
                        leadingIcon={
                          <TbCircleArrowLeftFilled className="w-6 h-6" />
                        }
                      >
                        Back
                      </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                      <PrimaryButton
                        onClick={nextStep}
                        color={"blue"}
                        trailingIcon={
                          <TbCircleArrowRightFilled className="w-6 h-6" />
                        }
                      >
                        Next
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </TransitionPage>
            )}

            {step === 6 && (
              <TransitionPage horizontal={true}>
                <Column className={"min-h-screen p-6"}>
                  <Column className={"bg-white rounded-xl border "}>
                    <Column className={"px-6 py-4"}>
                      <Title>Konfirmasi Form Pengguna Lulusan UTY</Title>
                    </Column>
                    <HorizontalDivider removeMargin />
                    <Column className={"px-6 py-4 space-y-6 pb-10"}>
                      <Text>
                        Silahkan tekan tombol{" "}
                        <span className="font-semibold">Submit</span> jika data
                        yang diisikan dirasa sudah benar. Tekan tombol{" "}
                        <span className="font-semibold">Back</span> jika data
                        yang diisi masih kurang sesuai.
                      </Text>
                      <Column
                        className={
                          "space-y-2 border shadow-none w-fit  hover:shadow-md rounded-xl"
                        }
                      >
                        <Column className={"px-4 py-2"}>
                          <Title>Captcha</Title>
                          <Caption>
                            Silahkan inputkan captcha sebelum melakukan submit
                            form
                          </Caption>
                        </Column>
                        <HorizontalDivider removeMargin />
                        <Column className={"px-4 py-2 space-y-1  pb-5"}>
                          <Text>{`${num1} + ${num2} berapa?`}</Text>
                          <OutlineInput
                            type="number"
                            inputWidth={"w-full"}
                            placeholder="Masukkan captcha"
                            value={captcaInput}
                            onChange={(e) => setCaptchaInput(e.target.value)}
                            className="no-spinner"
                            onKeyDown
                          />
                          {errEmptyCaptcha ? (
                            <OverlineText className="text-red-600 px-4 mt-2">
                              {errEmptyCaptcha}
                            </OverlineText>
                          ) : errWrongCaptcha ? (
                            <OverlineText className="text-red-600 px-4 mt-2">
                              {errWrongCaptcha}
                            </OverlineText>
                          ) : null}
                        </Column>
                      </Column>
                      <div className="flex gap-x-10">
                        <ButtonHover>
                          <PrimaryButton
                            onClick={backSubmit}
                            color={"orange"}
                            trailingIconLeft={
                              <TbCircleArrowLeftFilled className="w-6 h-6" />
                            }
                          >
                            Back
                          </PrimaryButton>
                        </ButtonHover>
                        <ButtonHover>
                          <PrimaryButton
                            className={"w-full"}
                            type={"submit"}
                            color={"green"}
                            trailingIcon={<TbChecks className="w-6 h-6" />}
                          >
                            Submit
                          </PrimaryButton>
                        </ButtonHover>
                      </div>
                    </Column>
                  </Column>
                </Column>
              </TransitionPage>
            )}
          </Form>
        </Column>
      </div>
    </>
  );
};

export default KuesionerPageLayout;
