import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { TbSend, TbX } from 'react-icons/tb'
import Cookies from 'universal-cookie'
import { saveCommentRecognitionController } from '../../controllers/alumni_controllers'
import { AlertDialog, ButtonHover, Caption, Column, Form, Headline3, OutlineTextArea, PrimaryButton, Row, ShareFBButton, ShareTelegramButton, ShareWAButton, ShareXButton, TertiaryButton, Text } from '../../shared/ui'
import { refreshTokenKey } from '../../shared/variable'
import ItemRecognition from './item/item_recognition'

function ListRecognition({ recognitions }) {

    const cookies = new Cookies()
    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    const [currentRecognition, setCurrentRecognition] = useState(null)
    const [showDialogComment, setShowDialogComment] = useState(false)
    const [showDialogShare, setShowDialogShare] = useState(false)

    const onComment = (recognition) => {
        setCurrentRecognition(recognition)
        setShowDialogComment(true)
    }

    const onShare = (recognition) => {
        setCurrentRecognition(recognition)
        setShowDialogShare(true)
    }

    const saveComment = async (event) => {
        event.preventDefault();
        const comment = event.target.comment.value ?? null
        if (comment !== null) {
            setShowDialogComment(false)
            const props = {
                id: currentRecognition.id_rekognisi,
                comment: comment,
            }
            const result = await saveCommentRecognitionController(props)
            if (result.status) {
                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        }
    }

    return (
        <>
            {
                recognitions.map((rec, idx) => {
                    return (
                        <div className='mx-4 sm:mx-0'>
                        <ItemRecognition
                            key={idx}
                            recognition={rec}
                            isLogin={tokenCookies !== null ? true : false}
                            onComment={onComment}
                            onShare={onShare} />
                        </div>
                    )
                })
            }
            <AlertDialog className={'max-w-xl'}
                show={showDialogComment}
                onClose={() => setShowDialogComment(false)}>
                <Form onSubmit={saveComment}>
                    <Column className={'p-5 sm:p-8 gap-y-5'}>
                        <Column className={'gap-y-2'}>
                            <Headline3>
                                Komentar
                            </Headline3>
                            {currentRecognition !== null && <Text>
                                Berikan komentar Anda untuk berita
                                <span className={'font-medium ml-1'}>
                                    {currentRecognition.title}
                                </span>.
                            </Text>}
                        </Column>
                        <Column className={'gap-y-2'}>
                            <OutlineTextArea
                                id='comment'
                                className={'py-4'}
                                inputWidth={'w-full'}
                                initialRow={5}
                                placeholder='Tulis komentar disini'
                                bgColor={'bg-gray-50'}
                                required />
                            <Caption>
                                Setiap komentar yang Anda berikan akan melalui proses Verifikasi untuk memenuhi Ketentuan dan Kebijakan Privasi yang berlaku.
                            </Caption>
                        </Column>
                        <Row className={'gap-x-3 justify-between'}>
                            <ButtonHover>
                                <TertiaryButton
                                    pills
                                    leadingIcon={
                                        <TbX className='h-5 w-5 my-auto' />
                                    }
                                    className={'pl-5 pr-6'}
                                    onClick={() => setShowDialogComment(false)}>
                                    Batal
                                </TertiaryButton>
                            </ButtonHover>
                            <ButtonHover>
                                <PrimaryButton
                                    pills
                                    type='submit'
                                    className='pl-5 pr-6'
                                    color='indigo'
                                    leadingIcon={
                                        <TbSend className={'h-5 w-5 rotate-12 my-auto'} />
                                    }>
                                    Kirim
                                </PrimaryButton>
                            </ButtonHover>
                        </Row>
                    </Column>
                </Form>
            </AlertDialog>
            <AlertDialog
                className={'max-w-lg'}
                show={showDialogShare}
                onClose={() => setShowDialogShare(false)}>
                <Column className={'p-5 sm:p-8 gap-y-5'}>
                    <Column className={'gap-y-2'}>
                        <Headline3>
                            Bagikan
                        </Headline3>
                        {currentRecognition !== null && <Text>
                            Bagikan berita
                            <span className={'font-medium ml-1'}>
                                {currentRecognition.title}
                            </span>.
                        </Text>}
                    </Column>
                    {currentRecognition !== null && <Row className={'gap-x-3 pb-1'}>
                        <ShareFBButton location={`${window.location.href}/${currentRecognition.slug}`} />
                        <ShareXButton location={`${window.location.href}/${currentRecognition.slug}`} />
                        <ShareWAButton location={`${window.location.href}/${currentRecognition.slug}`} />
                        <ShareTelegramButton location={`${window.location.href}/${currentRecognition.slug}`} />
                    </Row>}
                    <Row className={'justify-end'}>
                        <ButtonHover>
                            <TertiaryButton
                                pills
                                leadingIcon={
                                    <TbX className='h-5 w-5 my-auto' />
                                }
                                className={'pl-5 pr-6'}
                                onClick={() => setShowDialogShare(false)}>
                                Batal
                            </TertiaryButton>
                        </ButtonHover>
                    </Row>
                </Column>
            </AlertDialog>
        </>
    )
}

export default ListRecognition