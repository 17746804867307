import React from 'react'
import { TableCell, TableRow, Text, Tooltip } from '../../../shared/ui'
import moment from 'moment'

const RowTableRekapKuesionerTracer = ({ kuesioner, idx, currentPage = 1, limitRow = 1 }) => {
    return (
        <TableRow className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50 cursor-pointer`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                {kuesioner.nim}
            </TableCell>
            <TableCell>
                <Tooltip message={kuesioner.nama}>
                    <Text className='line-clamp-2'>
                        {kuesioner.nama}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                {kuesioner.nama_jenjang}
            </TableCell>
            <TableCell>
                <Tooltip message={kuesioner.nama_jurusan}>
                    <Text className='line-clamp-2'>
                        {kuesioner.nama_jurusan}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                {moment(kuesioner.created).format('DD MMM YYYY')}
            </TableCell>
            <TableCell>
                {moment(kuesioner.tgllulus).format('DD MMM YYYY')}
            </TableCell>
        </TableRow>
    )
}

export default RowTableRekapKuesionerTracer
