import React, { Suspense, lazy, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Column, GridCol } from "../../../components/interfaces/block";
import {
  SelectInput,
  SelectInput2,
} from "../../../components/interfaces/input";
import { Headline2Text } from "../../../components/interfaces/text";
import SPieOvTracer from "../../../components/shimmer/sadm_pie_ov_tracer";
import WTableBedaKerjaOvTracer from "../../../components/wtable_bedakerja_ov_tracer";
import WTableCCKerjaOvTracer from "../../../components/wtable_cckerja_ov_tracer";
import WTableMetodeBelajarOvTracer from "../../../components/wtable_metodebelajar_ov_tracer";
import WTableProCariKerjaOvTracer from "../../../components/wtable_procarikerja_ov_tracer";
import WTableTingkatKerjaOvTracer from "../../../components/wtable_tingkatkerja_ov_tracer";
import WTableTingkatKomOvTracer from "../../../components/wtable_tingkatkom_ov_tracer";
import {
  getOv6BulanDapatKerjaTracerController,
  getOvGrafikIsiTracerController,
  getOvGrafikStatusTracerController,
  getOvHubStudiKerjaTracerController,
  getOvJenisPerusahaanTracerController,
  getOvPosisiWiraTracerController,
  getOvRataDapatKerjaTracerController,
  getOvRataPendapatanTracerController,
  getOvSumberBiayaTracerController,
  getOvTingkatPendTracerController,
  getOvTingkatWiraTracerController,
} from "../../../controllers/admin_controllers";
import { getFilterTracerController } from "../../../controllers/global_controllers";
import CardRataDapatKerja from "./cards/card_rata_dapat_kerja";
import CardRataPenghasilan from "./cards/card_rata_penghasilan";
import Char6BulanKerja from "./charts/chart_6bulan_kerja";
import ChartHubEratStudiKerja from "./charts/chart_hub_erat_studi_kerja";
import ChartIsiTracer from "./charts/chart_isi_tracer";
import ChatJenisPerusahaan from "./charts/chart_jenis_perusahaan";
import ChartPosisiWirausaha from "./charts/chart_posisi_wirausaha";
import CharStatusLulusan from "./charts/chart_status_lulusan";
import ChartSumberBiaya from "./charts/chart_sumber_biaya";
import ChartTingkatPendKerja from "./charts/chart_tingkat_pend_kerja";
import ChartTingkatWirausaha from "./charts/chart_tingkat_wirausaha";
import {
  HorizontalDivider,
  Overline,
  Row,
  TabPanel,
  Tabs,
  Title,
} from "../../../shared/ui";
import {
  TbBuilding,
  TbBuildingSkyscraper,
  TbChecks,
  TbX,
} from "react-icons/tb";
import moment from "moment";

const CChartCariKerjaOverviewTracer = lazy(() =>
  import("../../../components/cards/cchart_carikerja_ov_tracer")
);
const CChartAktifCariKerjaOverviewTracer = lazy(() =>
  import("../../../components/cards/cchart_aktifcarikerja_ov_tracer")
);

function AdmOverviewTracerPage() {
  const result = useLoaderData();

  let currentYear = new Date().getFullYear();
  let listDateStartAkademik = [];
  let listDateEndAkademik = [];

  for (let year = currentYear; year >= 2015; year--) {
    listDateStartAkademik.push(year);
  }

  for (let year = currentYear; year >= 2016; year--) {
    listDateEndAkademik.push(year);
  }

  const [currentDateStart, setCurrentDateStart] = useState({
    label: "Tahun Ajaran",
    value: null,
  });

  const [currentDateEnd, setCurrentDateEnd] = useState({
    label: "Tahun Ajaran",
    value: null,
  });

  const [currentProdi, setCurrentProdi] = useState({
    label: "Semua Program Studi",
    value: null,
  });

  const [errorInput, setErrorInput] = useState(null);

  const [filterProdi, setFilterProdi] = useState([]);
  const [filterTahun, setFilterTahun] = useState([]);
  const [isiTracer, setIsiTracer] = useState([]);

  const [statusTracer, setStatusTracer] = useState([]);
  const [labelStatusTracer, setLabelStatusTracer] = useState([]);
  const [rasioStatusTracer, setRasioStatusTracer] = useState(0);

  const [kurang6Bulan, setKurang6Bulan] = useState([]);
  const [labelKurang6Bulan, setLabelKurang6Bulan] = useState([]);

  const [rataPendapatan, setRataPendapatan] = useState(0);

  const [rataDapatKerja, setRataDapatKerja] = useState(0);

  const [hubEratKerja, setHubEratKerja] = useState([]);
  const [labelHubEratKerja, setLabelHubEratKerja] = useState([]);

  const [jenisPerusahaan, setJenisPerusahaan] = useState([]);
  const [labelJenisPerusahaan, setLabelJenisPerusahaan] = useState([]);

  const [posisiWirausaha, setPosisiWirausaha] = useState([]);
  const [labelPosisiWirausaha, setLabelPosisiWirausaha] = useState([]);

  const [tingkatWirausaha, setTingkatWirausaha] = useState([]);
  const [labelTingkatWirausaha, setLabelTingkatWirausaha] = useState([]);

  const [tingkatPendKerja, setTingkatPendKerja] = useState([]);
  const [labelTingkatPendKerja, setLabelTingkatPendKerja] = useState([]);

  const [sumberBiaya, setSumberBiaya] = useState([]);
  const [labelSumberBiaya, setLabelSumberBiaya] = useState([]);

  const getOverviewFromLoader = () => {
    if (result.filter.status === "fulfilled") {
      const res = result.filter.value;
      if (res.status) {
        // prodi
        const prodi = res.data.prodi;
        const listProdi = prodi.map((prodi) => ({
          label: prodi.label,
          value: prodi.kode_prodi,
        }));
        listProdi.unshift({ label: "Semua Program Studi", value: null });
        setFilterProdi(listProdi);

        // tahun
        const tahun = res.data.tahun;
        const listTahun = tahun.map((thn) => ({
          label: thn,
          value: thn,
        }));
        listTahun.unshift({ label: "Semua Tahun", value: null });
        setFilterTahun(listTahun);
      }
    }

    if (result.ovStatus.status === "fulfilled") {
      const res = result.ovStatus.value;
      if (res.status) {
        const rasio = res.data[0].data.reduce((sum, value) => sum + value, 0);
        setRasioStatusTracer(rasio);
        setStatusTracer(res.data);
        setLabelStatusTracer(res.labels);
      }
    }

    if (result.ov6Bulan.status === "fulfilled") {
      const res = result.ov6Bulan.value;
      if (res.status) {
        setKurang6Bulan(res.data);
        setLabelKurang6Bulan(res.labels);
      }
    }

    if (result.ovPendapatan.status === "fulfilled") {
      const res = result.ovPendapatan.value;
      if (res.status) {
        setRataPendapatan(res.data);
      }
    }

    if (result.ovDapatKerja.status === "fulfilled") {
      const res = result.ovDapatKerja.value;
      if (res.status) {
        setRataDapatKerja(res.data);
      }
    }

    if (result.ovHubStudiKerja.status === "fulfilled") {
      const res = result.ovHubStudiKerja.value;
      if (res.status) {
        setHubEratKerja(res.data);
        setLabelHubEratKerja(res.labels);
      }
    }

    if (result.ovPerusahaan.status === "fulfilled") {
      const res = result.ovPerusahaan.value;
      if (res.status) {
        setJenisPerusahaan(res.data);
        setLabelJenisPerusahaan(res.labels);
      }
    }

    if (result.ovPosisiWira.status === "fulfilled") {
      const res = result.ovPosisiWira.value;
      if (res.status) {
        setPosisiWirausaha(res.data);
        setLabelPosisiWirausaha(res.labels);
      }
    }

    if (result.ovTingkatWira.status === "fulfilled") {
      const res = result.ovTingkatWira.value;
      if (res.status) {
        setTingkatWirausaha(res.data);
        setLabelTingkatWirausaha(res.labels);
      }
    }

    if (result.ovTingkatPend.status === "fulfilled") {
      const res = result.ovTingkatPend.value;
      if (res.status) {
        setTingkatPendKerja(res.data);
        setLabelTingkatPendKerja(res.labels);
      }
    }

    if (result.sumberBiaya.status === "fulfilled") {
      const res = result.sumberBiaya.value;
      if (res.status) {
        setSumberBiaya(res.data);
        setLabelSumberBiaya(res.labels);
      }
    }
  };

  const onChangeYear = (value) => {
    if (currentTabIndex === 0) {
      setCurrentDateStart({
        label: `${value} - ${
          listDateEndAkademik[listDateStartAkademik.indexOf(value)]
        }`,
        value: `${value}-09`,
      });
      setCurrentDateEnd({
        label: `${value} - ${
          listDateEndAkademik[listDateStartAkademik.indexOf(value)]
        }`,
        value: `${parseInt(value) + 1}-08`,
      });
    } else {
      setCurrentDateStart({
        label: `${value} - ${
          listDateEndAkademik[listDateStartAkademik.indexOf(value)]
        }`,
        value: `${value}-01`,
      });
      setCurrentDateEnd({
        label: `${value} - ${
          listDateEndAkademik[listDateStartAkademik.indexOf(value)]
        }`,
        value: `${parseInt(value)}-12`,
      });
    }
  };

  const onChangeProdi = (value) => {
    setCurrentProdi(value);
  };

  useEffect(() => {
    getOverviewFromLoader();
  }, []);

  const [currentTabIndex, setCurrentTabIndex] = useState(null);
  const onChangeTabIndex = (index) => {
    setCurrentTabIndex(index);
    if (currentTabIndex === 0) {
      setCurrentDateStart({
        label: "Semua Tahun",
        value: null,
      });
      setCurrentDateEnd({
        label: "Semua Tahun",
        value: null,
      });
      setCurrentProdi({
        label: "Pilih Prodi",
        value: null,
      });
    } else if (currentTabIndex === 1) {
      setCurrentDateStart({
        label: "Semua Tahun",
        value: null,
      });
      setCurrentDateEnd({
        label: "Semua Tahun",
        value: null,
      });
      setCurrentProdi({
        label: "Pilih Prodi",
        value: null,
      });
    }
  };

  return (
    <Column className={"p-4 gap-y-5 sm:p-8"}>
      <Headline2Text className={"font-semibold"}>
        Ringkasan Tracer - Update
      </Headline2Text>
      <ChartIsiTracer series={isiTracer} />
      <Column className={"mt-6 gap-y-4"}>
        <Tabs
          selectedTabIdx={currentTabIndex}
          onChangeTabIndex={onChangeTabIndex}
        >
          <TabPanel
            icon={<TbBuilding className="h-5 w-5 " />}
            title="Akademik"
            textActiveColor="text-blue-700"
          >
            <Suspense>
              <Row className={"flex-col lg:flex-row gap-y-4 "}>
                <Title className={"w-full mt-2"}>
                  Data tracer study{" "}
                  {currentDateStart.value && currentDateEnd.value && (
                    <span>
                      kalender <span> Akademik</span>
                    </span>
                  )}
                  {currentProdi.value !== null ? (
                    <span>
                      {" "}
                      <span>program studi</span>{" "}
                      <span>{currentProdi.label}</span>{" "}
                    </span>
                  ) : null}
                  {currentDateStart.value !== null &&
                  currentDateEnd.value !== null ? (
                    <span>
                      {" "}
                      <span>tahun</span>{" "}
                      <span>
                        {`${moment(currentDateStart.value).format(
                          "YYYY"
                        )}/${moment(currentDateEnd.value).format("YYYY")}`}
                      </span>{" "}
                    </span>
                  ) : null}
                </Title>
                <Row className="gap-x-4 gap-y-4 md:gap-y-0 lg:justify-end flex-col md:flex-row">
                  <div>
                    <SelectInput
                      width="w-full md:w-80"
                      initial={currentProdi}
                      options={filterProdi}
                      onChange={onChangeProdi}
                      btnClassName={`bg-white ${
                        currentDateStart.value === null ||
                        currentDateEnd.value === null
                          ? "cursor-not-allowed"
                          : null
                      }`}
                      zindex="z-30"
                      disabled={
                        currentDateStart.value === null ||
                        currentDateEnd.value === null
                      }
                    />
                    <Overline className={"text-red-600 mt-2"}>
                      {currentDateStart.value === null ||
                      currentDateEnd.value === null
                        ? "Pilih tahun dahulu"
                        : null}
                    </Overline>
                  </div>
                  <div>
                    <SelectInput2
                      width="w-full sm:w-48"
                      options={
                        currentTabIndex === 0
                          ? listDateStartAkademik
                              .filter((year) => year !== 2024)
                              .sort((a, b) => b - a)
                              .map((start, index) => ({
                                label: `${start}/${
                                  listDateEndAkademik.sort((a, b) => b - a)[
                                    index
                                  ]
                                }`,
                                value: start,
                              }))
                          : listDateStartAkademik.map((start, index) => ({
                              label: `${start}/${listDateEndAkademik[index]}`,
                              value: null,
                            }))
                      }
                      onChange={(selectedOption) =>
                        onChangeYear(selectedOption.value)
                      }
                      btnClassName={"bg-white"}
                      zindex="z-20"
                    />
                  </div>
                </Row>
              </Row>
            </Suspense>
          </TabPanel>
          <TabPanel
            icon={<TbBuildingSkyscraper className="h-5 w-5" />}
            title="Kemdikbud"
            textActiveColor="text-green-700"
          >
            <Suspense>
              <Row className={"flex-col lg:flex-row gap-y-4"}>
                <Title className={"w-full mt-2"}>
                  Data tracer study{" "}
                  {currentDateStart.value && currentDateEnd.value && (
                    <span>
                      kalender <span> Kemdikbud</span>
                    </span>
                  )}
                  {currentProdi.value !== null ? (
                    <span>
                      {" "}
                      <span>program studi</span>{" "}
                      <span>{currentProdi.label}</span>{" "}
                    </span>
                  ) : null}
                  {currentDateStart.value !== null &&
                  (currentDateEnd.value !== null) !== null ? (
                    <span>
                      {" "}
                      <span>tahun</span>{" "}
                      <span>
                        {moment(currentDateStart.value).format("YYYY")}
                      </span>{" "}
                    </span>
                  ) : null}
                </Title>
                <Row className="gap-x-4 gap-y-4 md:gap-y-0 lg:justify-end flex-col md:flex-row">
                  <div>
                    <SelectInput
                      width="w-full sm:w-80"
                      initial={currentProdi}
                      options={filterProdi}
                      onChange={onChangeProdi}
                      btnClassName={`bg-white ${
                        currentDateStart.value === null ||
                        currentDateEnd.value === null
                          ? "cursor-not-allowed"
                          : null
                      }`}
                      zindex="z-30"
                      disabled={
                        currentDateStart.value === null ||
                        currentDateEnd.value === null
                      }
                    />
                    <Overline className={"text-red-600 mt-2"}>
                      {currentDateStart.value === null ||
                      currentDateEnd.value === null
                        ? "Pilih tahun dahulu"
                        : null}
                    </Overline>
                  </div>
                  <div>
                    <SelectInput2
                      width="w-full sm:w-48"
                      options={listDateStartAkademik
                        .sort((a, b) => b - a)
                        .map((start, index) => ({
                          label: `${listDateStartAkademik[index]}`,
                          value: start,
                        }))}
                      onChange={(selectedOption) =>
                        onChangeYear(selectedOption.value)
                      }
                      btnClassName={"bg-white"}
                      zindex="z-20"
                    />
                  </div>
                </Row>
              </Row>
            </Suspense>
          </TabPanel>
        </Tabs>
      </Column>

      <div>
        <GridCol className={"grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5"}>
          <CharStatusLulusan
            prodi={currentProdi}
            currentDateStart={currentDateStart}
            currentDateEnd={currentDateEnd}
            series={statusTracer}
            labels={labelStatusTracer}
            rasio={rasioStatusTracer}
            currentTabIndex={currentTabIndex}
          />
          <Char6BulanKerja
            prodi={currentProdi}
            currentDateStart={currentDateStart}
            currentDateEnd={currentDateEnd}
            series={kurang6Bulan}
            labels={labelKurang6Bulan}
            currentTabIndex={currentTabIndex}
          />
          <Column className={"h-full gap-5"}>
            <CardRataPenghasilan
              prodi={currentProdi}
              currentDateStart={currentDateStart}
              currentDateEnd={currentDateEnd}
              takehome={rataPendapatan}
              currentTabIndex={currentTabIndex}
            />
            <CardRataDapatKerja
              prodi={currentProdi}
              currentDateStart={currentDateStart}
              currentDateEnd={currentDateEnd}
              duration={rataDapatKerja}
              currentTabIndex={currentTabIndex}
            />
          </Column>
          <ChartHubEratStudiKerja
            prodi={currentProdi}
            currentDateStart={currentDateStart}
            currentDateEnd={currentDateEnd}
            series={hubEratKerja}
            labels={labelHubEratKerja}
            currentTabIndex={currentTabIndex}
          />
          <ChatJenisPerusahaan
            prodi={currentProdi}
            currentDateStart={currentDateStart}
            currentDateEnd={currentDateEnd}
            series={jenisPerusahaan}
            labels={labelJenisPerusahaan}
            currentTabIndex={currentTabIndex}
          />
          <ChartPosisiWirausaha
            prodi={currentProdi}
            currentDateStart={currentDateStart}
            currentDateEnd={currentDateEnd}
            series={posisiWirausaha}
            labels={labelPosisiWirausaha}
            currentTabIndex={currentTabIndex}
          />
          <ChartTingkatWirausaha
            prodi={currentProdi}
            currentDateStart={currentDateStart}
            currentDateEnd={currentDateEnd}
            series={tingkatWirausaha}
            labels={labelTingkatWirausaha}
            currentTabIndex={currentTabIndex}
          />
          <ChartTingkatPendKerja
            prodi={currentProdi}
            currentDateStart={currentDateStart}
            currentDateEnd={currentDateEnd}
            series={tingkatPendKerja}
            labels={labelTingkatPendKerja}
            currentTabIndex={currentTabIndex}
          />
          <ChartSumberBiaya
            prodi={currentProdi}
            currentDateStart={currentDateStart}
            currentDateEnd={currentDateEnd}
            series={sumberBiaya}
            labels={labelSumberBiaya}
            currentTabIndex={currentTabIndex}
          />
        </GridCol>
      </div>
      <WTableTingkatKomOvTracer
        prodi={currentProdi}
        currentDateStart={currentDateStart}
        currentDateEnd={currentDateEnd}
        currentTabIndex={currentTabIndex}
      />
      <WTableTingkatKerjaOvTracer
        prodi={currentProdi}
        currentDateStart={currentDateStart}
        currentDateEnd={currentDateEnd}
        currentTabIndex={currentTabIndex}
      />
      <WTableMetodeBelajarOvTracer
        prodi={currentProdi}
        currentDateStart={currentDateStart}
        currentDateEnd={currentDateEnd}
        currentTabIndex={currentTabIndex}
      />
      <div>
        <GridCol className="grid-cols-1 sm:grid-cols-2  gap-5">
          <Suspense fallback={<SPieOvTracer />}>
            <CChartCariKerjaOverviewTracer
              prodi={currentProdi}
              currentDateStart={currentDateStart}
              currentDateEnd={currentDateEnd}
              currentTabIndex={currentTabIndex}
            />
          </Suspense>
          <Suspense fallback={<SPieOvTracer />}>
            <CChartAktifCariKerjaOverviewTracer
              prodi={currentProdi}
              currentDateStart={currentDateStart}
              currentDateEnd={currentDateEnd}
              currentTabIndex={currentTabIndex}
            />
          </Suspense>
        </GridCol>
      </div>
      <WTableCCKerjaOvTracer
        prodi={currentProdi}
        currentDateStart={currentDateStart}
        currentDateEnd={currentDateEnd}
        currentTabIndex={currentTabIndex}
      />
      <WTableProCariKerjaOvTracer
        prodi={currentProdi}
        currentDateStart={currentDateStart}
        currentDateEnd={currentDateEnd}
        currentTabIndex={currentTabIndex}
      />
      <WTableBedaKerjaOvTracer
        prodi={currentProdi}
        currentDateStart={currentDateStart}
        currentDateEnd={currentDateEnd}
        currentTabIndex={currentTabIndex}
      />
    </Column>
  );
}

export default AdmOverviewTracerPage;

export const getAdmOviewTracer = async () => {
  const [
    filter,
    ovStatus,
    ov6Bulan,
    ovPendapatan,
    ovDapatKerja,
    ovHubStudiKerja,
    ovPerusahaan,
    ovPosisiWira,
    ovTingkatWira,
    ovTingkatPend,
    sumberBiaya,
  ] = await Promise.allSettled([
    getFilterTracerController(),
    getOvGrafikStatusTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOv6BulanDapatKerjaTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOvRataPendapatanTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOvRataDapatKerjaTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOvHubStudiKerjaTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOvJenisPerusahaanTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOvPosisiWiraTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOvTingkatWiraTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOvTingkatPendTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
    getOvSumberBiayaTracerController({
      date_start: null,
      date_end: null,
      prodi: null,
    }),
  ]);
  return {
    filter: filter,
    ovStatus: ovStatus,
    ov6Bulan: ov6Bulan,
    ovPendapatan: ovPendapatan,
    ovDapatKerja: ovDapatKerja,
    ovHubStudiKerja: ovHubStudiKerja,
    ovPerusahaan: ovPerusahaan,
    ovPosisiWira: ovPosisiWira,
    ovTingkatWira: ovTingkatWira,
    ovTingkatPend: ovTingkatPend,
    sumberBiaya: sumberBiaya,
  };
};
