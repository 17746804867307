import { useLoaderData } from "react-router-dom";
import { getCommentsController } from "../../../controllers/admin_controllers";
import { useEffect, useState } from "react";
import { useDebounce } from "../../../components/interfaces/utility";
import { Card, Column, Pagination } from "../../../shared/ui";
import { BodyText, Headline2Text } from "../../../components/interfaces/text";
import TableAcceptedComments from "./tables/table_accepted_comments";

function AdmAcceptedCommentsPage() {
  const result = useLoaderData();

  const [listComments, setListComments] = useState([]);
  const [limitRow, setlimitRow] = useState(10);
  const [totalRow, setTotalRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [currentSort, setCurrentSort] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [stsGetLoader, setStsGetLoader] = useState(false);

  const debounceSearch = useDebounce({ value: searchQuery, delay: 100 });

  const getAcceptedCommnetsFromLoader = () => {
    if (result.status) {
      setListComments(result.data);
      setTotalRow(result.totalRows);
      setTotalPage(result.totalPage);
    }
    setStsGetLoader(true);
  };

  const regetAcceptedComments = async () => {
    const result = await getAdmAcceptedComments({
      search: searchQuery,
      limit: limitRow,
      page: currentPage,
      order: currentSort !== null ? currentSort.key : null,
      asc: currentSort !== null ? currentSort.type : null,
    });
    if (result.status) {
      setListComments(result.data);
      setTotalRow(result.totalRow);
      setTotalPage(result.totalPage);
    }
  };

  const onHandlePagination = (value) => {
    setCurrentPage(value);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
  };

  useEffect(() => {
    getAcceptedCommnetsFromLoader();
  }, []);

  useEffect(() => {
    if (stsGetLoader) {
      regetAcceptedComments();
    }
  }, [debounceSearch, limitRow, currentPage, currentSort]);

  return (
    <Column className={"p-4 gap-y-5 sm:p-8"}>
      <Column className={"gap-y-2"}>
        <Headline2Text className={"font-semibold"}>
          Komentar Terverifikasi
        </Headline2Text>
        <BodyText>
          Semua data komentar pada berita alumni yang terverifikasi dari Bidang
          Alumni.
        </BodyText>
      </Column>
      <Card className={"w-full"}>
        <Column className={"gap-y-5"}>
          <TableAcceptedComments
            limit={limitRow}
            page={currentPage}
            listComments={listComments}
            onShort={onHandleSort}
          />
          {listComments.length > 0 && (
            <Pagination
              limitRow={limitRow}
              totalRow={totalRow}
              totalPage={totalPage}
              triger={currentSort !== null && currentSort.key}
              onChange={onHandlePagination}
            />
          )}
        </Column>
      </Card>
    </Column>
  );
}
export default AdmAcceptedCommentsPage;

export const getAdmAcceptedComments = async ({
  status = "ACCEPTED",
  search = null,
  limit = 10,
  page = 1,
  order = null,
  asc = null,
}) => {
  const props = {
    status: status,
    search: search,
    limit: limit,
    page: page,
    order: order,
    asc: asc,
  };
  const result = await getCommentsController(props);
  return result;
};
