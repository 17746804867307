import React, { useEffect, useState } from "react";
import { getDataTingkatKompetensiOvTracer } from "../controllers/apicontrollers";
import {
  ButtonHover,
  Caption,
  Card,
  Column,
  DotSeparated,
  Row,
  Subtitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "../shared/ui";
import { TbFileTypeCsv } from "react-icons/tb";
import { TitleText } from "./interfaces/text";
import moment from "moment";
import useDownloadExcel from "../hooks/useDownloadExcel";
import { getExcelDataKompetensiDikuasaiController } from "../controllers/admin_controllers";

const WTableTingkatKomOvTracer = ({
  currentDateStart,
  currentDateEnd,
  prodi,
  currentTabIndex,
  binaryData,
}) => {
  const [title, setTitle] = useState(null);
  const [data, setData] = useState(null);
  const [labelKategori, setlabelKategori] = useState([]);
  const [labelPenilaian, setLabelPenilaian] = useState([]);
  const [sortTable, setShortTable] = useState(null);

  const getDataTingkatKompetensi = async () => {
    const props = {
      date_start: currentDateStart.value,
      date_end: currentDateEnd.value,
      prodi: prodi.value,
      order: sortTable !== null ? sortTable.key : null,
      asc: sortTable !== null ? sortTable.type : null,
    };
    const result = await getDataTingkatKompetensiOvTracer(props);
    if (result.status) {
      setTitle(result.message);
      setData(result.data);
      setlabelKategori(result.label_kategori);
      setLabelPenilaian(result.label_penilaian);
    }
  };

  const onShortableValue = (value) => {
    setShortTable(value);
  };

  useEffect(() => {
    getDataTingkatKompetensi();
  }, [sortTable, currentDateStart, currentDateEnd, prodi]);

  // Download excel
  const fileName = `Tingkat Kompetensi Yang Dikuasai Alumni_${
    currentTabIndex === 0
      ? currentDateStart.value !== null || currentDateEnd.value !== null
        ? `${moment(currentDateStart.value).format("YYYY")}_${moment(
            currentDateEnd.value
          ).format("YYYY")}`
        : "Semua Tahun"
      : currentTabIndex === 1
      ? currentDateStart.value !== null || currentDateEnd.value !== null
        ? moment(currentDateStart.value).format("YYYY")
        : "Semua Tahun"
      : "Semua Tahun"
  }_${prodi.value === null ? "Semua Program Studi" : prodi.label}`;

  const downloadExcel = useDownloadExcel({ fileName });

  const handleDownloadExcel = async () => {
    const res = await getExcelDataKompetensiDikuasaiController({
      date_start: currentDateStart.value,
      date_end: currentDateEnd.value,
      prodi: prodi.value,
    });
    downloadExcel(res);
  };

  return (
    <Card className="w-full p-0">
      <Column>
        <Column className="px-4 py-3  md:flex-row md:justify-between space-y-3 md:space-y-0">
          <Column className={"gap-1"}>
            <Row>
              <Column>
                <TitleText>Tingkat Kompetensi Yang Dikuasai Alumni</TitleText>
              </Column>
            </Row>
            <Row className="flex-wrap gap-y-1">
              <Caption>{prodi.value ? prodi.label : "Semua Prodi"}</Caption>
              <DotSeparated />
              <Caption>
                {currentTabIndex === 0
                  ? currentDateStart.value && currentDateEnd.value
                    ? `Tahun ${moment(currentDateStart.value).format(
                        "YYYY"
                      )}/${moment(currentDateEnd.value).format("YYYY")}`
                    : "Semua Tahun"
                  : currentDateStart.value && currentDateEnd.value
                  ? `${moment(currentDateStart.value).format("YYYY")}`
                  : "Semua Tahun"}
              </Caption>
            </Row>
          </Column>

          <ButtonHover>
            <button
              onClick={() => handleDownloadExcel(binaryData)}
              className="flex border rounded-md px-4 py-2 gap-x-2  bg-gray-50 text-gray-500"
            >
              Download
              <TbFileTypeCsv className="w-6 h-6" />
            </button>
          </ButtonHover>
        </Column>

        <div className="p-4">
          <Table
            className="border border-gray-200"
            trigerPagination={sortTable}
            showPaginationAndSearch={false}
          >
            <TableHead>
              <TableRow className="bg-slate-100/50 border-b border-b-gray-200">
                <TableCell className="font-semibold jakarta">No</TableCell>
                <TableCell className="font-semibold jakarta">
                  Kompetensi
                </TableCell>
                {labelPenilaian.map((label, idx) => (
                  <TableCell key={idx} className="font-semibold jakarta">
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="divide-y divide-gray-200">
              {data &&
                Object.entries(data).map(([key, values], idx) => (
                  <TableRow
                    key={idx}
                    className={`${
                      idx % 2 === 1 ? "bg-slate-50" : "bg-white"
                    } hover:bg-slate-100/100 cursor-default`}
                  >
                    <TableCell className="text-center">{idx + 1}</TableCell>
                    <TableCell>
                      <Caption className="line-clamp-1 text-gray-800 ">
                        {labelKategori[idx]}
                      </Caption>
                    </TableCell>
                    {values.map((value, i) => (
                      <TableCell key={i} className="text-center">
                        <Caption className="line-clamp-1 text-gray-800 ">
                          {value}
                          {"%"}
                        </Caption>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Column>
    </Card>
  );
};

export default WTableTingkatKomOvTracer;
