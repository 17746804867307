import React, { Suspense, lazy, useState } from 'react'
import { TbAlertTriangle, TbChecks, TbRefresh } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { currentIndexTabMitra } from '../../atoms'
import STable from '../../components/shimmer/stable'
import { Column, Headline1, Row, TabPanel, Tabs } from '../../shared/ui'

const TableAcceptedMitra = lazy(() => import('../../components/tabels/accepted_mitra'))
const TableProcessedMitra = lazy(() => import('../../components/tabels/processed_mitra'))
const TableRejectedMitra = lazy(() => import('../../components/tabels/rejected_mitra'))

const AdmMitraPage = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabMitra)
    const [showProgress, setShowProgress] = useState(true)

    const onChangeTabIndex = (index) => {
        setShowProgress(true)
        setCurrentTabIndex(index)
    }

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    return (
        <Column className='gap-y-5'>
            <Row className='flex-col gap-5 sm:flex-row sm:justify-between'>
                <Headline1>
                    Daftar Mitra UTY
                </Headline1>
                {/* <ButtonHover>
                                <PrimaryButton className='pr-5' leadingIcon={<BiPlus className='h-5 w-5 my-auto' />} color='indigo'>
                                    Tambah Mitra
                                </PrimaryButton>
                            </ButtonHover> */}
            </Row>
            <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                <TabPanel icon={<TbChecks className='h-5 w-5' />} title='Disetujui' textActiveColor='text-green-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableAcceptedMitra onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbRefresh className='h-5 w-5' />} title='Menunggu' textActiveColor='text-blue-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableProcessedMitra onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbAlertTriangle className='h-5 w-5' />} title='Ditolak' textActiveColor='text-red-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableRejectedMitra onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
            </Tabs>
        </Column>
    )
}

export default AdmMitraPage