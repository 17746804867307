import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbAlertTriangle, TbCircleCheck, TbRefresh, TbShieldCheck, TbTrash, TbX } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { aMyB2B } from '../atoms'
import { approveAppliedB2BController, deleteB2BController, getMyB2BController } from '../controllers/alumni_controllers'
import { AlertDialog, ButtonHover, Caption, Card, Column, DotSeparated, GridCol, Headline3, LargeHeadline, LoadingDialog, PrimaryButton, Row, SecondaryButton, TagView, Text } from '../shared/ui'
import CAlmBusiness from './cards/calm_business'
import TableAppltB2B from './tabels/apply_b2b'

const WAlmB2BMyPost = ({ typePost, categoryPost }) => {

    const [listBusiness, setListBusiness] = useRecoilState(aMyB2B)
    const [currentBusiness, setCurrentBusiness] = useState(null)
    const [currentApply, setCurrentApply] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [sumComplete, setSumComplete] = useState(0)
    const [sumOngoing, setSumOngoing] = useState(0)
    const [sumPending, setSumPending] = useState(0)
    const [sumReject, setSumReject] = useState(0)
    const [showDialog, setShowDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showApproveDialog, setShowApproveDialog] = useState({ show: false, action: 0 })
    const [loading, setLoading] = useState(false)

    const getMyB2B = async () => {
        const props = {
            page: currentPage,
            limit: limitRow,
            type: typePost !== 'ALL' ? typePost : null,
            category: categoryPost !== 'ALL' ? categoryPost : null
        }
        const result = await getMyB2BController(props)
        if (result.status) {
            const listBusinessTemp = [...result.data]
            if (listBusinessTemp.length > 0) {
                const complete = listBusinessTemp.filter((b2b) => b2b.status === 'COMPLETE')
                const ongoing = listBusinessTemp.filter((b2b) => b2b.status === 'ONGOING')
                const pending = listBusinessTemp.filter((b2b) => b2b.status === 'PENDING')
                const reject = listBusinessTemp.filter((b2b) => b2b.acc_bidal === 'REJECTED')
                setSumComplete(complete.length)
                setSumOngoing(ongoing.length)
                setSumPending(pending.length)
                setSumReject(reject.length)
            }
            setListBusiness(listBusinessTemp)
        }
    }

    const onShowDialog = (post) => {
        setCurrentBusiness(post)
        setShowDialog(true)
    }

    const onDeleteBusiness = (post) => {
        setCurrentBusiness(post)
        setShowDeleteDialog(true)
    }

    const deleteBusiness = async () => {
        setShowDeleteDialog(false)
        setLoading(true)
        const result = await deleteB2BController(currentBusiness.slug)
        if (result.status) {
            setTimeout(() => {
                getMyB2B()
                setLoading(false)
                toast.success(result.message)
            }, 200)
        } else {
            setTimeout(() => {
                setLoading(false)
                toast.error(result.message)
            }, 200)
        }
    }

    const onApprove = (apply) => {
        setShowApproveDialog({ show: true, action: apply.action })
        setCurrentApply(apply.data)
    }

    const approveAppliedB2B = async () => {
        setShowApproveDialog({ show: false, action: showApproveDialog.action })
        setLoading(true)
        const props = {
            id: currentBusiness.slug,
            iduser: currentApply.id_user,
            approve: showApproveDialog.action === 10 ? 'ACCEPTED' : 'REJECTED'
        }
        const result = await approveAppliedB2BController(props)
        if (result.status) {
            setTimeout(() => {
                const newState = currentBusiness.respons.map(respon => {
                    if (respon.id_user === currentApply.id_user) {
                        return { ...respon, acc_ts: showApproveDialog.action === 10 ? 'ACCEPTED' : 'REJECTED' };
                    }
                    return respon;
                })
                setCurrentBusiness({ ...currentBusiness, respons: newState })
                setLoading(false)
                toast.success(result.message)
            }, 200)
        } else {
            setTimeout(() => {
                setLoading(false)
                toast.error(result.message)
            }, 200)
        }
    }

    useEffect(() => {
        getMyB2B()
    }, [typePost, categoryPost])

    return (
        <>
            <Column className='gap-y-5'>
                <GridCol className='gap-x-5 gap-y-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4'>
                    <Card className='w-full shadow-lg shadow-gray-200'>
                        <Column className='gap-y-1.5'>
                            <Text>
                                Postingan Selesai
                            </Text>
                            <Row className='justify-between items-center'>
                                <LargeHeadline className='font-inter font-normal'>
                                    {sumComplete}
                                </LargeHeadline>
                                <TbCircleCheck className='h-8 w-8 stroke-1.5 text-emerald-600' />
                            </Row>
                        </Column>
                    </Card>
                    <Card className='w-full shadow-lg shadow-gray-200'>
                        <Column className='gap-y-1.5'>
                            <Text>
                                Postingan Aktif
                            </Text>
                            <Row className='justify-between items-center'>
                                <LargeHeadline className='font-inter font-normal'>
                                    {sumOngoing}
                                </LargeHeadline>
                                <TbShieldCheck className='h-8 w-8 stroke-1.5 text-blue-600' />
                            </Row>
                        </Column>
                    </Card>
                    <Card className='w-full shadow-lg shadow-gray-200'>
                        <Column className='gap-y-1.5'>
                            <Text>
                                Postingan Belum Aktif
                            </Text>
                            <Row className='justify-between items-center'>
                                <LargeHeadline className='font-inter font-normal'>
                                    {sumPending}
                                </LargeHeadline>
                                <TbRefresh className='h-8 w-8 stroke-1.5 text-orange-600' />
                            </Row>
                        </Column>
                    </Card>
                    <Card className='w-full shadow-lg shadow-gray-200'>
                        <Column className='gap-y-1.5'>
                            <Text>
                                Postingan Ditolak
                            </Text>
                            <Row className='justify-between items-center'>
                                <LargeHeadline className='font-inter font-normal'>
                                    {sumReject}
                                </LargeHeadline>
                                <TbAlertTriangle className='h-8 w-8 stroke-1.5 text-red-600' />
                            </Row>
                        </Column>
                    </Card>
                </GridCol>
                <Column className='gap-y-8'>
                    {listBusiness.map((business, idx) => {
                        return (
                            <CAlmBusiness key={idx} action={20} business={business} onShowDialog={onShowDialog} onDeletePost={onDeleteBusiness} />
                        )
                    })}
                </Column>
            </Column>
            <AlertDialog className='max-w-full' show={showDialog} onClose={() => setShowDialog(false)}>
                {currentBusiness !== null && <Column className='p-8 gap-y-8'>
                    <Row className='gap-x-2 items-center justify-between'>
                        <Column className='gap-y-1'>
                            <Headline3>
                                {currentBusiness.title}
                            </Headline3>
                            <Row>
                                <Caption>
                                    Dibuat {moment(currentBusiness.created).format('DD MMMM YYYY HH.mm')}
                                </Caption>
                                <DotSeparated />
                                <Caption>
                                    {currentBusiness.respons.length} Tertarik
                                </Caption>
                            </Row>
                        </Column>
                        <Row className='w-fit gap-x-2'>
                            <TagView color='blue' className='rounded-lg' label={currentBusiness.jenis} />
                            <TagView color='indigo' className='rounded-lg' label={currentBusiness.kategori} />
                        </Row>
                    </Row>
                    <TableAppltB2B listApply={currentBusiness.respons} onApprove={onApprove} />
                    <Row className='gap-x-5 justify-end'>
                        <ButtonHover>
                            <SecondaryButton className='pr-5' onClick={() => setShowDialog(false)} leadingIcon={<TbX className='h-5 w-5 my-auto' />}>
                                Tutup
                            </SecondaryButton>
                        </ButtonHover>
                    </Row>
                </Column>}
            </AlertDialog>
            <AlertDialog className='max-w-lg' show={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
                <Column className='p-8 gap-y-8'>
                    <Column className='gap-y-3'>
                        <Headline3>
                            Hapus Postingan
                        </Headline3>
                        {currentBusiness !== null && <Text>
                            Apakah anda yakin ingin menghapus postingan <span className='font-medium'>{currentBusiness.title}</span>?
                        </Text>}
                    </Column>
                    <Row className='gap-x-5 justify-end'>
                        <ButtonHover>
                            <SecondaryButton className='pr-5' onClick={() => setShowDeleteDialog(false)} leadingIcon={<TbX className='h-5 w-5 my-auto' />}>
                                Batal
                            </SecondaryButton>
                        </ButtonHover>
                        <ButtonHover>
                            <PrimaryButton className='pr-5' color='red' onClick={deleteBusiness} leadingIcon={<TbTrash className='h-5 w-5 my-auto' />}>
                                Hapus
                            </PrimaryButton>
                        </ButtonHover>
                    </Row>
                </Column>
            </AlertDialog>
            <AlertDialog className='max-w-lg' show={showApproveDialog.show} onClose={() => setShowApproveDialog({ show: false, action: showApproveDialog.action })}>
                <Column className='p-8 gap-y-8'>
                    <Column className='gap-y-3'>
                        <Headline3>
                            {showApproveDialog.action === 10 ? 'Pilih Kandidat' : 'Tolak Kandidat'}
                        </Headline3>
                        {currentApply !== null && <Text>
                            {showApproveDialog.action === 10 ? 'Apakah anda yakin ingin memilih ' : 'Apakah anda yakin ingin menolak '} <span className='font-medium'>{currentApply.nama_alumni}</span> sebagai kandidat?
                        </Text>}
                    </Column>
                    <Row className='gap-x-5 justify-end'>
                        <ButtonHover>
                            <SecondaryButton className='pr-5' onClick={() => setShowApproveDialog({ show: false, action: showApproveDialog.action })} leadingIcon={<TbX className='h-5 w-5 my-auto' />}>
                                Batal
                            </SecondaryButton>
                        </ButtonHover>
                        <ButtonHover>
                            <PrimaryButton className='pr-5' color={showApproveDialog.action === 10 ? 'indigo' : 'red'} onClick={approveAppliedB2B} leadingIcon={showApproveDialog.action === 10 ? <TbCircleCheck className='h-5 w-5 my-auto' /> : <TbAlertTriangle className='h-5 w-5 my-auto' />}>
                                {showApproveDialog.action === 10 ? 'Pilih' : 'Tolak'}
                            </PrimaryButton>
                        </ButtonHover>
                    </Row>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loading} message='Sedang memproses...' />
        </>
    )
}

export default WAlmB2BMyPost