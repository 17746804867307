import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadCell2,
  TableRow,
} from "../../../../components/interfaces/table";
import RowFillKuesionerTracer from "./rows/row_fill_kuesioner_tracer";

const TableFillKuesionerTracer = ({
  listFillKuesioner,
  page,
  limit,
  onShort,
}) => {
  const [currentSort, setCurrentSort] = useState(null);

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell2>No</TableHeadCell2>
          <TableHeadCell2
            sortable
            sortkey="nim"
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}
          >
            NPM
          </TableHeadCell2>
          <TableHeadCell2
            sortable
            sortkey="nama"
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}
          >
            Nama Lengkap
          </TableHeadCell2>
          <TableHeadCell2>Jenjang</TableHeadCell2>
          <TableHeadCell2>Program Studi</TableHeadCell2>
          <TableHeadCell2>Tanggal Lulus</TableHeadCell2>
          <TableHeadCell2
            sortable
            sortkey="created"
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}
          >
            Tanggal Mengisi
          </TableHeadCell2>
        </TableRow>
      </TableHead>
      <TableBody>
        {listFillKuesioner.length > 0 ? (
          listFillKuesioner.map((kuesioner, idx) => {
            return (
              <RowFillKuesionerTracer
                key={idx}
                idx={idx}
                page={page}
                limit={limit}
                kuesioner={kuesioner}
              />
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={7} className="text-center text-gray-500">
              No data to display
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
export default TableFillKuesionerTracer;
