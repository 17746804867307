import { Suspense, lazy, useState } from 'react'
import { TbAlertTriangle, TbChecks, TbRefresh } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { currentIndexTabLegalitas } from '../../atoms'
import STable from '../../components/shimmer/stable'
import { Column, Headline1, TabPanel, Tabs } from '../../shared/ui'

const TableAcceptedLegalitas = lazy(() => import('../../components/tabels/accepted_legalitas'))
const TableProcessedLegalitas = lazy(() => import('../../components/tabels/processed_legalitas'))
const TableRejectedLegalitas = lazy(() => import('../../components/tabels/rejected_legalitas'))

const AdmLegalitasBusinessPage = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabLegalitas)
    const [showProgress, setShowProgress] = useState(true)

    const onChangeTabIndex = (index) => {
        setShowProgress(true)
        setCurrentTabIndex(index)
    }

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    return (
        <Column className='p-4 gap-y-5 sm:p-8'>
            <Headline1>
                Legalitas Usaha
            </Headline1>
            <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                <TabPanel icon={<TbChecks className='h-5 w-5' />} title='Disetujui' textActiveColor='text-green-700'>
                    <Suspense fallback={<STable column={7} listWidth={['w-12', 'w-32', 'w-72', 'w-20', 'w-20', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end', 'justify-end']} />}>
                        <TableAcceptedLegalitas onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbRefresh className='h-5 w-5' />} title='Menunggu' textActiveColor='text-blue-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableProcessedLegalitas onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbAlertTriangle className='h-5 w-5' />} title='Ditolak' textActiveColor='text-red-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableRejectedLegalitas onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
            </Tabs>
        </Column>
    )
}

export default AdmLegalitasBusinessPage