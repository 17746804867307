import React, { lazy, Suspense, useState } from "react";
import {
  TbChartDonut2,
  TbChecks,
  TbPlus,
  TbRefresh,
  TbX,
} from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentIndexTabRecognition } from "../../atoms";
import STable from "../../components/shimmer/stable";
import {
  ButtonHover,
  Column,
  Headline2,
  PrimaryButton,
  Row,
  TabPanel,
  Tabs,
  TransitionPage,
} from "../../shared/ui";

import { proAddRecognitionRoute } from "../../shared/variable";
import OvRecognition from "../../components/overview/ov_recognition";

const TableAcceptedRecognition = lazy(() =>
  import("../../components/tabels/accepted_recognition")
);
const TableProcessedRecognition = lazy(() =>
  import("../../components/tabels/processed_recognition")
);
const TableRejectedRecognition = lazy(() =>
  import("../../components/tabels/rejected_recognition")
);

const ProRecognitionPage = () => {
  const navigateTo = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useRecoilState(
    currentIndexTabRecognition
  );
  const [showProgress, setShowProgress] = useState(true);

  const onChangeTabIndex = (index) => {
    setShowProgress(true);
    setCurrentTabIndex(index);
  };

  const navigatePage = () => {
    navigateTo(proAddRecognitionRoute);
  };

  const onDoneLoadData = (value) => {
    setShowProgress(false);
  };

  return (
    <TransitionPage>
      <Column className="gap-5">
        <Row className="justify-between">
          <Headline2>Berita Alumni</Headline2>
          <ButtonHover>
            <PrimaryButton
              leadingIcon={<TbPlus className="h-5 w-5 my-auto" />}
              className="w-fit pr-6"
              color="indigo"
              onClick={navigatePage}
            >
              Buat Berita
            </PrimaryButton>
          </ButtonHover>
        </Row>
        <Tabs
          selectedTabIdx={currentTabIndex}
          onChangeTabIndex={onChangeTabIndex}
        >
          <TabPanel
            icon={<TbChartDonut2 className="h-5 w-5" />}
            title="Overview"
          >
            <OvRecognition onDoneLoadData={onDoneLoadData} action={20} />
          </TabPanel>
          <TabPanel
            icon={<TbChecks className="h-5 w-5" />}
            title="Accepted"
            textActiveColor="text-green-700"
          >
            <Suspense
              fallback={
                <STable
                  column={7}
                  listWidth={[
                    "w-12",
                    "w-32",
                    "w-72",
                    "w-20",
                    "w-20",
                    "w-20",
                    "w-16",
                  ]}
                  listAlign={[
                    "justify-start",
                    "justify-start",
                    "justify-start",
                    "justify-end",
                    "justify-end",
                    "justify-end",
                    "justify-end",
                  ]}
                />
              }
            >
              <TableAcceptedRecognition
                onDoneLoadData={onDoneLoadData}
                action={20}
              />
            </Suspense>
          </TabPanel>
          <TabPanel
            icon={<TbRefresh className="h-5 w-5" />}
            title="Waiting"
            textActiveColor="text-orange-700"
          >
            <Suspense
              fallback={
                <STable
                  column={6}
                  listWidth={["w-12", "w-32", "w-72", "w-32", "w-20", "w-16"]}
                  listAlign={[
                    "justify-start",
                    "justify-start",
                    "justify-start",
                    "justify-start",
                    "justify-center",
                    "justify-end",
                  ]}
                />
              }
            >
              <TableProcessedRecognition
                onDoneLoadData={onDoneLoadData}
                action={20}
              />
            </Suspense>
          </TabPanel>
          <TabPanel
            icon={<TbX className="h-5 w-5" />}
            title="Rejected"
            textActiveColor="text-red-700"
          >
            <Suspense
              fallback={
                <STable
                  column={6}
                  listWidth={["w-12", "w-32", "w-72", "w-32", "w-20", "w-16"]}
                  listAlign={[
                    "justify-start",
                    "justify-start",
                    "justify-start",
                    "justify-start",
                    "justify-center",
                    "justify-end",
                  ]}
                />
              }
            >
              <TableRejectedRecognition
                onDoneLoadData={onDoneLoadData}
                action={20}
              />
            </Suspense>
          </TabPanel>
        </Tabs>
      </Column>
    </TransitionPage>
  );
};

export default ProRecognitionPage;
