import React from "react";
import {
  Column,
  DotSeparated,
  HorizontalDivider,
  Row,
  Subtitle,
  Text,
  TextButton,
} from "../shared/ui";
import simfora from "../images/simfora_black.png";
import {
  TbBriefcase,
  TbCalendar,
  TbCalendarEvent,
  TbHeartHandshake,
  TbInfoHexagon,
  TbMail,
  TbMessageCircle,
  TbReport,
  TbShield,
  TbStack2,
  TbUserShield,
  TbWorld,
  TbWorldWww,
} from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {
  almBusinessRoute,
  almConsultationRoute,
  almTracerRoute,
  careerRoute,
  eventRoute,
  recognitionRoute,
} from "../shared/variable";

const AlmFooter = () => {
  const navigateTo = useNavigate();

  return (
    <Column className=" mt-auto bg-white px-4">
      <Column className="max-w-7xl sm:flex-row mx-auto pt-16 md:pt-24 pb-16 gap-10 justify-around">
        <Column className="max-w-sm gap-8 ">
          <img src={simfora} className="w-44" />
          <Subtitle className="text-slate-700">
            Sistem Informasi Alumni (Simfora) adalah Portal Layanan dan Karier
            untuk Alumni Univeristas Teknologi Yogyakarta
          </Subtitle>
          <Text>© 2023 Simfora. All rights reserved.</Text>
        </Column>
        <Column className="w-fit gap-2.5">
          <TextButton
            leadingIcon={<TbStack2 className="h-5 w-5 my-auto" />}
            onClick={() => navigateTo(recognitionRoute)}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Berita
          </TextButton>
          <TextButton
            leadingIcon={<TbCalendarEvent className="h-5 w-5 my-auto" />}
            onClick={() => navigateTo(eventRoute)}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Event
          </TextButton>
          <TextButton
            leadingIcon={<TbBriefcase className="h-5 w-5 my-auto" />}
            onClick={() => navigateTo(careerRoute)}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Lowongan
          </TextButton>
          <TextButton
            leadingIcon={<TbMessageCircle className="h-5 w-5 my-auto" />}
            onClick={() => navigateTo(almConsultationRoute)}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Konsultasi
          </TextButton>
          <TextButton
            leadingIcon={<TbReport className="h-5 w-5 my-auto" />}
            onClick={() => navigateTo(almTracerRoute)}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Tracer Study
          </TextButton>
          <TextButton
            leadingIcon={<TbHeartHandshake className="h-5 w-5 my-auto" />}
            onClick={() => navigateTo(almBusinessRoute)}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Business to Business
          </TextButton>
        </Column>
        <Column className="w-fit gap-2.5">
          <TextButton
            leadingIcon={<TbShield className="h-5 w-5 my-auto" />}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Kebijakan Privasi
          </TextButton>
          <TextButton
            leadingIcon={<TbUserShield className="h-5 w-5 my-auto" />}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Ketentuan Pengguna
          </TextButton>
          <TextButton
            leadingIcon={<TbInfoHexagon className="h-5 w-5 my-auto" />}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Tentang Kami
          </TextButton>
          <TextButton
            leadingIcon={<TbMail className="h-5 w-5 my-auto" />}
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
          >
            Kontak Kami
          </TextButton>
        </Column>
      </Column>
      <Column className="max-w-7xl mx-auto">
        <HorizontalDivider />
        <Row className="pt-5 pb-6">
          <Text className="flex-1 font-manrope font-medium text-slate-700">
            Dikembangkan oleh Universitas Teknologi Yogykarta
          </Text>
          <TextButton
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
            leadingIcon={
              <TbWorldWww className="h-5 w-5 stroke-1.5 translate-y-0.5" />
            }
          >
            www.uty.ac.id
          </TextButton>
          <DotSeparated />
          <TextButton
            color="custom"
            className="font-medium text-slate-700 hover:text-indigo-700"
            leadingIcon={
              <TbWorld className="h-5 w-5 stroke-1.5 translate-y-0.5" />
            }
          >
            pmb.uty.ac.id
          </TextButton>
        </Row>
      </Column>
    </Column>
  );
};

export default AlmFooter;
