import { useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableAcceptedConsultation from "./rows/row_accepted_consultation";
import {
  AlertDialog,
  Avatar,
  Caption,
  Card,
  Column,
  Headline3,
  ListTileView,
  Row,
  Text,
  TextButton,
} from "../../../../shared/ui";
import avatar1 from "../../../../images/avatar1.png";
import avatar3 from "../../../../images/avatar3.png";
import { TbStarFilled } from "react-icons/tb";

function TableAcceptedConsultation({ listConsultation, page, limit, onShort }) {
  // const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [fullName, setFullName] = useState(null);
  const [konselorName, setKonselorName] = useState(null);
  const [consultation, setConsultation] = useState({});

  const listRate = [
    { rate: 1, label: "Sangat Tidak Puas" },
    { rate: 2, label: "Tidak Puas" },
    { rate: 3, label: "Cukup Puas" },
    { rate: 4, label: "Puas" },
    { rate: 5, label: "Sangat Puas" },
  ];

  const onShowDialog = (value) => {
    // setShowDialog(true);
    setConsultation(value.con);
    setFullName(value.fullName);
    setKonselorName(value.konselorName);
  };

  const onHandleClick = (mitra) => {
    setShowDialog(true);
    setConsultation(mitra);
    console.log("daftar konsultasi :", mitra);
  };

  // const onHandleSort = (value) => {
  //   setCurrentSort(value);
  //   onShort(value);
  // };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Alumni</TableHeadCell>
            <TableHeadCell>Topik</TableHeadCell>
            <TableHeadCell>Subjek</TableHeadCell>
            <TableHeadCell>Konselor</TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listConsultation.length > 0 ? (
            listConsultation.map((consultation, idx) => {
              return (
                <RowTableAcceptedConsultation
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  consultation={consultation}
                  handleClick={onHandleClick}
                  onShowDialog={onShowDialog}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog show={showDialog} onClose={() => setShowDialog(false)}>
        <Column className="divide-y-[1px] divide-gray-200">
          <Column className="px-5 pt-10 pb-8 items-center gap-8">
            <Row className="-space-x-5 w-fit">
              <Avatar
                className="h-14 w-14 border-2 border-white"
                src={avatar1}
              />
              <Avatar
                className="h-14 w-14 border-2 border-white"
                src={avatar3}
              />
            </Row>
            <Column className="gap-2">
              <Headline3 className="text-center">Konsultasi Karir</Headline3>
              <Text className="text-center">
                Sesi konsultasi{" "}
                <span className="font-manrope font-semibold">{fullName}</span>{" "}
                bersama{" "}
                <span className="font-manrope font-semibold">
                  {konselorName}
                </span>
              </Text>
            </Column>
            <Column className="gap-5">
              <Card className="w-full max-h-32 py-3 overflow-y-auto scrollbar-hide shadow-none">
                <Column className="gap-0.5">
                  <Caption>Topik Konsultasi:</Caption>
                  <Text className="w-fit whitespace-pre-line">
                    {consultation.topik}
                  </Text>
                </Column>
              </Card>
              <Card className="w-full p-0 shadow-none">
                <Column className="divide-y-[1px] divide-gray-200">
                  <ListTileView
                    className="py-3"
                    label="Alumni"
                    value={consultation.nama_alumni}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Konselor"
                    value={consultation.nama_konselor}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Tipe Konsultasi"
                    value={consultation.public ? "Publik" : "Tertutup"}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Pengajuan"
                    value={moment(consultation.created_at).format(
                      "DD MMMM YYYY HH.mm"
                    )}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                </Column>
              </Card>
              {consultation.status === "COMPLETE" && (
                <Card className="w-full shadow-none">
                  <Column className="gap-0.5">
                    <Caption>Penilaian Alumni</Caption>
                    <Row className="gap-2.5">
                      <Headline3 className="my-auto">
                        {parseFloat(consultation.rating).toFixed(1)}
                      </Headline3>
                      <Row className="w-fit my-auto">
                        {listRate.map((rate, idx) => {
                          return (
                            <TbStarFilled
                              key={idx}
                              className={`h-5 w-5 my-auto ${
                                consultation.rating >= rate.rate
                                  ? "text-amber-400"
                                  : "text-gray-200"
                              }`}
                            />
                          );
                        })}
                      </Row>
                    </Row>
                  </Column>
                </Card>
              )}
              <Card className="w-full p-0 shadow-none">
                <ListTileView
                  className="py-3"
                  label="Status"
                  value={
                    consultation.status === "COMPLETE"
                      ? "Sesi konsultasi selesai"
                      : consultation.status === "PENDING"
                      ? "Menunggu ACC dari konselor"
                      : "Sesi konsultasi berlangsung"
                  }
                  direction="flex-col"
                  valueClassName="text-base font-normal"
                />
              </Card>
            </Column>
          </Column>
          <TextButton
            onClick={() => setShowDialog(false)}
            className="w-full py-4 text-base hover:bg-slate-50/50"
          >
            Tutup
          </TextButton>
        </Column>
      </AlertDialog>
    </>
  );
}

export default TableAcceptedConsultation;
