import React from "react";
import CNotifications from "./cards/cnotifications";
import { Column, Overline } from "../shared/ui";

const WListNotification = ({
  action,
  listNotification,
  onReadNotification,
}) => {
  return (
    listNotification.length > 0 && (
      <Column className="gap-y-2 px-6">
        <Overline className="pl-2 font-medium uppercase">
          {action === 10 ? "Belum Dibaca" : "Sudah Dibaca"}
        </Overline>
        {listNotification.map((notif, idx) => {
          return (
            <CNotifications
              key={idx}
              notif={notif}
              action={action}
              onReadNotification={onReadNotification}
            />
          );
        })}
      </Column>
    )
  );
};

export default WListNotification;
