import React, { useState } from 'react'
import { TbBell, TbCategory, TbLogout2, TbMessage, TbMessageCheck, TbMessagePlus, TbX } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { resetRecoilState } from '../../atoms'
import { logoutAccountController } from '../../controllers/global_controllers'
import { AlertDialog, Avatar, ButtonHover, Caption, Column, Headline3, LoadingDialog, PrimaryButton, Row, Sidebar, SidebarHeader, SidebarMenu, Subtitle, TertiaryButton, Text, TextButton } from '../../shared/ui'
import { getCurrentUser } from '../../shared/user'
import { konCompleteConsultationRoute, konConsultationRoute, konNotificationRoute, konPendingConsultationRoute, konRootRoute, loginRoute } from '../../shared/variable'

function KonSidebar({ showOnMobile }) {

  const location = useLocation()
  const navigateTo = useNavigate()

  const { name, username } = getCurrentUser()

  const [resetRecoil, setResetRecoil] = useRecoilState(resetRecoilState)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const logoutAccount = async () => {
    setShowDialog(false)
    setLoading(true)
    const result = await logoutAccountController()
    if (result) {
      setTimeout(() => {
        localStorage.clear()
        setResetRecoil(true)
        setLoading(false)
        navigateTo(loginRoute, { replace: true })
      }, 300)
    }
  }

  return (
    <>
      <Sidebar
        showOnMobile={showOnMobile}>
        <SidebarMenu className={'p-4 gap-y-3.5 pb-36'}>
          <SidebarHeader className={'px-6'}>
            <Column className={'gap-y-5 py-3 items-center'}>
              <Avatar className={'h-20 w-20'} />
              <Column className={'gap-y-2'}>
                <Subtitle className={'font-semibold text-center line-clamp-1'}>
                  {name ?? 'Konselor'}
                </Subtitle>
                <Caption className={'text-center'}>
                  {username ?? 'konselor'}
                </Caption>
              </Column>
            </Column>
          </SidebarHeader>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbCategory className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === konRootRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(konRootRoute)}>
            Dashboard
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbMessage className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === konConsultationRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(konConsultationRoute)}>
            Konsultasi Aktif
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbMessagePlus className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === konPendingConsultationRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(konPendingConsultationRoute)}>
            Pengajuan Konsultasi
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbMessageCheck className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === konCompleteConsultationRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(konCompleteConsultationRoute)}>
            Konsultasi Selesai
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbBell className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === konNotificationRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(konNotificationRoute)}>
            Notifikasi
          </TextButton>
        </SidebarMenu>
        <Column className={'absolute bottom-0 inset-x-0 p-4 bg-white border-t border-gray-200'}>
          <ButtonHover className={'w-full'}>
            <PrimaryButton
              pills
              color='red'
              className={'w-full'}
              leadingIcon={
                <TbLogout2 className={'h-5 w-5 my-auto'} />
              }
              onClick={() => setShowDialog(true)}>
              Keluar Akun
            </PrimaryButton>
          </ButtonHover>
        </Column>
      </Sidebar>
      <AlertDialog
        show={showDialog}
        className={'max-w-md'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3>
              Keluar Akun
            </Headline3>
            <Text>
              Apakah anda yakin ingin keluar dari Simfora UTY?
            </Text>
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='red'
                className='pl-5 pr-6'
                leadingIcon={
                  <TbLogout2 className='h-5 w-5 my-auto' />
                }
                onClick={logoutAccount}>
                Keluar
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Keluar dari akun...' />
    </>
  )
}

export default KonSidebar