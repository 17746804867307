import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Column, FullLargeHeadline, FullScreen, Headline2, Subtitle } from '../shared/ui'
import { landingRoute } from '../shared/variable'

const NotFoundPage = () => {

    const navigateTo = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigateTo(landingRoute)
        }, 2000)
    }, [])

    return (
        <FullScreen className='flex'>
            <Column className='w-fit mx-auto my-auto text-center gap-5 px-4 py-4'>
                <FullLargeHeadline className='archivo text-gray-300 mb-5'>
                    404
                </FullLargeHeadline>
                <Headline2 className='text-gray-800'>
                    Page Not Found
                </Headline2>
                <Column className='mb-20'>
                    <Subtitle className='font-normal inter text-gray-500'>
                        This page doesn't exist or was removed!
                    </Subtitle>
                    <Subtitle className='font-normal inter text-gray-500'>
                        We suggest you back to home.
                    </Subtitle>
                </Column>
            </Column>
        </FullScreen>
    )
}

export default NotFoundPage
