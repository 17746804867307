import React from 'react'
import { TbArrowRight, TbStarFilled } from 'react-icons/tb'
import { ButtonHover, Caption, Card, IconButton, Row, TableCell, TableRow } from '../../../shared/ui'

const RowTablePlotingKonselor = ({ konselor, idx, currentPage = 1, limitRow = 1 }) => {

    const listRate = [
        { rate: 1, label: 'Sangat Tidak Puas' },
        { rate: 2, label: 'Tidak Puas' },
        { rate: 3, label: 'Cukup Puas' },
        { rate: 4, label: 'Puas' },
        { rate: 5, label: 'Sangat Puas' }
    ]

    return (
        <TableRow className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50 cursor-pointer`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                {konselor.nik}
            </TableCell>
            <TableCell>
                {konselor.nama}
            </TableCell>
            <TableCell>
                {konselor.berlangsung} Sesi
            </TableCell>
            <TableCell>
                {konselor.selesai} Sesi
            </TableCell>
            <TableCell>
                {konselor.ditolak} Sesi
            </TableCell>
            <TableCell>
                <Card className='px-2.5 py-0.5 rounded-full'>
                    <Row className='w-fit gap-1'>
                        <Caption className='my-auto font-medium text-gray-800'>
                            {parseFloat(konselor.rating ?? '0').toFixed(1)}
                        </Caption>
                        <Row className='w-fit my-auto'>
                            {listRate.map((rate, idx) => {
                                return (
                                    <TbStarFilled key={idx} className={`h-3 w-3 my-auto ${konselor.rating >= rate.rate ? 'text-amber-400' : 'text-gray-200'}`} />
                                )
                            })}
                        </Row>
                    </Row>
                </Card>
            </TableCell>
            <TableCell className='px-3 flex justify-center'>
                <ButtonHover className='mx-auto w-fit'>
                    <IconButton className='p-1.5' bordered={true} color='indigo'>
                        <TbArrowRight className='h-5 w-5' />
                    </IconButton>
                </ButtonHover>
            </TableCell>
        </TableRow>
    )
}

export default RowTablePlotingKonselor