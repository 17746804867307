import React, { useEffect, useState } from 'react'
import CHistory from './cards/chistory'
import { getPlotingKonselor } from '../controllers/apicontrollers'
import { Caption, Column, Subtitle, Title } from '../shared/ui'
import { TbMessageCircle } from 'react-icons/tb'

const WKonHistoryConsultation = ({ action, currentMessage, onSelectedMessage, updateMessage, reqUpdate }) => {

    const [listHistory, setListHistory] = useState([])

    const getAllHistory = async () => {
        const result = await getPlotingKonselor(action)
        if (result.status) {
            setListHistory(result.data)
        }
    }

    const onClickMessage = (message) => {
        onSelectedMessage(message)
    }

    useEffect(() => {
        getAllHistory()
    }, [reqUpdate])

    useEffect(() => {
        if (updateMessage !== null) {
            const newState = listHistory.map(history => {
                if (history.id_konsultasi === updateMessage.id) {
                    return { ...history, last_chat: updateMessage.message, last_chat_time: updateMessage.date };
                }
                return history;
            })
            setListHistory(newState)
        }
    }, [updateMessage])

    return listHistory.length > 0 ? (
        <Column>
            {listHistory.map((history, idx) => {
                return (
                    <CHistory action={20} key={idx} history={history} currentId={currentMessage.id_konsultasi} onClick={onClickMessage} />
                )
            })}
        </Column>
    ) : (
        <Column className='items-center pt-32 pb-24 gap-y-4'>
            <TbMessageCircle className='h-24 w-24 stroke-1.2 text-gray-300' />
            <Column className='items-center gap-y-1'>
                <Title className='font-semibold text-center'>
                    {action === 10 ? 'Tidak ada Riwayat Konsultasi' : action === 20 ? 'Menunggu Konfirmasi' : 'Menolak Konsultasi'}
                </Title>
                <Caption className='text-center px-8'>
                    {action === 10 ? 'Tidak ada riwayat konsultasi dengan alumni' : action === 20 ? 'Tidak ada permintaan konsultasi yang belum dikonfirmasi kalau kepanjangan gimana' : 'Tidak ada permintaan konsultasi yang ditolak'}
                </Caption>
            </Column>
        </Column>
    )
}

export default WKonHistoryConsultation
