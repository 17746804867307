import React from "react";
import {
  TableCell,
  TableRow,
} from "../../../../../components/interfaces/table";
import { BodyText } from "../../../../../components/interfaces/text";
import { ButtonHover, IconButton } from "../../../../../shared/ui";
import { TbArrowRight } from "react-icons/tb";

function RowSaranTracer({ idx, saran, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow striped onClick={() => handleClick(saran)}>
      <TableCell>{limit * (page - 1) + (idx + 1)}</TableCell>
      <TableCell>{saran.nimhsmsmh}</TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2 capitalize"}>
          {saran.nmmhsmsmh.toLowerCase()}
        </BodyText>
      </TableCell>
      <TableCell>{saran.jenjang}</TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{saran.prodi}</BodyText>
      </TableCell>
      <TableCell className={"w-[30%]"}>
        <BodyText className={"line-clamp-2"}>{saran.saran}</BodyText>
      </TableCell>
      <TableCell>
        <ButtonHover className={"mx-auto"}>
          <IconButton
            className="p-1.5"
            color="indigo"
            bordered
            onClick={() => handleClick(saran)}
          >
            <TbArrowRight className="h-5 w-5" />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  );
}

export default RowSaranTracer;
