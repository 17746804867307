import React, { Suspense, lazy, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { TbAlertTriangle, TbChartDonut2, TbChecks, TbRefresh } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { currentIndexTabRecognition } from '../../atoms';
import OvRecognition from '../../components/overview/ov_recognition';
import STable from '../../components/shimmer/stable';
import { ButtonHover, Column, Headline1, PrimaryButton, Row, TabPanel, Tabs } from '../../shared/ui';
import { admAddRecognitionRoute } from '../../shared/variable';

const TableAcceptedRecognition = lazy(() => import('../../components/tabels/accepted_recognition'))
const TableProcessedRecognition = lazy(() => import('../../components/tabels/processed_recognition'))
const TableRejectedRecognition = lazy(() => import('../../components/tabels/rejected_recognition'))

const AdmRecognitionPage = () => {

    const navigateTo = useNavigate()
    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabRecognition)
    const [showProgress, setShowProgress] = useState(true)

    const onChangeTabIndex = (index) => {
        setShowProgress(true)
        setCurrentTabIndex(index)
    }

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    return (
        <Column className='gap-5'>
            <Row className='flex-col gap-5 sm:flex-row sm:justify-between'>
                <Headline1>
                    Berita Alumni
                </Headline1>
                <ButtonHover>
                    <PrimaryButton leadingIcon={<BiPlus className='h-5 w-5 my-auto' />} className='pr-5' color='indigo' onClick={() => navigateTo(admAddRecognitionRoute)}>
                        Buat Berita
                    </PrimaryButton>
                </ButtonHover>
            </Row>
            <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                <TabPanel icon={<TbChartDonut2 className='h-5 w-5' />} title='Ringkasan'>
                    <OvRecognition onDoneLoadData={onDoneLoadData} />
                </TabPanel>
                <TabPanel icon={<TbChecks className='h-5 w-5' />} title='Disetujui' textActiveColor='text-green-700'>
                    <Suspense fallback={<STable column={7} listWidth={['w-12', 'w-32', 'w-72', 'w-20', 'w-20', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end', 'justify-end']} />}>
                        <TableAcceptedRecognition onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbRefresh className='h-5 w-5' />} title='Menunggu' textActiveColor='text-blue-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableProcessedRecognition onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbAlertTriangle className='h-5 w-5' />} title='Ditolak' textActiveColor='text-red-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableRejectedRecognition onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
            </Tabs>
        </Column>
    )
}

export default AdmRecognitionPage
