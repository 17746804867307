import React from 'react'
import { TbArrowRight, TbArrowUpRight } from 'react-icons/tb'
import { ButtonHover, IconButton, TableCell, TableRow, Text, Tooltip } from '../../../shared/ui'

const RowTableEvent = ({ event, idx, currentPage = 1, limitRow = 1, onShowDialog }) => {
    return (
        <TableRow onClick={() => onShowDialog(event)} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50 cursor-pointer`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                <ButtonHover>
                    <a className='flex flex-row gap-x-2 tertiary-btn py-1.5 pl-2.5 pr-4 hover:border-blue-500' href={event.images[0].image_name} target='_blank' rel='noreferrer'>
                        <TbArrowUpRight className='h-5 w-5 my-auto' /> Gambar
                    </a>
                </ButtonHover>
            </TableCell>
            <TableCell>
                <Tooltip message={event.title}>
                    <Text className='line-clamp-2'>
                        {event.title}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip message={event.body}>
                    <div className='text-gray-800 font-inter text-base line-clamp-2'
                        dangerouslySetInnerHTML={{ __html: event.body.split('\n')[0] }} />
                </Tooltip>
            </TableCell>
            <TableCell>
                {event.jenis_event === 'ALUMNI' ? 'Event Alumni' : 'Event Karir'}
            </TableCell>
            <TableCell className='px-3 flex justify-center'>
                <ButtonHover>
                    <IconButton className='p-1.5' bordered={true} color='indigo' onClick={() => onShowDialog(event)}>
                        <TbArrowRight className='h-5 w-5' />
                    </IconButton>
                </ButtonHover>
            </TableCell>
        </TableRow>
    )
}

export default RowTableEvent