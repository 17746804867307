import moment from 'moment'
import React from 'react'
import { TbArrowUpRight, TbTrash } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { ButtonHover, IconButton } from '../../../../../shared/ui'

function RowTableProdiCertificate({ idx, certificate, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow striped>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        {certificate.jenjang}
      </TableCell>
      <TableCell>
        {certificate.prodi}
      </TableCell>
      <TableCell>
        <ButtonHover>
          <a
            onClick={(event) => event.stopPropagation()}
            className='flex flex-row items-center gap-x-0.5 tertiary-btn py-1 pl-2.5 pr-4 rounded-full hover:border-blue-500'
            href={certificate.name}
            target='_blank'
            rel='noreferrer'>
            <TbArrowUpRight className='h-5 w-5' /> Buka
          </a>
        </ButtonHover>
      </TableCell>
      <TableCell>
        {certificate.tahun}
      </TableCell>
      <TableCell>
        {moment(certificate.created_at).format('DD MMM YYYY HH.mm')}
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            bordered
            color='red'
            className={'p-1.5'}
            onClick={() => handleClick(certificate)}>
            <TbTrash className={'h-5 w-5'} />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableProdiCertificate