import moment from 'moment'
import React from 'react'
import { TbAlertTriangle, TbArrowUpRight, TbChecks, TbProgress } from 'react-icons/tb'
import { ButtonHover, Caption, Column, Headline3, Image, PrimaryButton, Row, Text, TextButton } from '../../../shared/ui'

function ItemApplyCareer({ career, onShowNote, onShowDetail }) {
  return (
    <Column className={'divide-y divide-gray-200 bg-white border-y group transition-all duration-200 sm:border-x lg:border-gray-200 lg:shadow-sm lg:hover:shadow-md'}>
      <Row className={'px-4 py-3 gap-x-2 items-center sm:px-8'}>
        {career.acc_mitra === 'ACCEPTED' ? <TbChecks className={'h-6 w-6 text-green-600'} /> :
          career.acc_mitra === 'PROCESSED' ? <TbProgress className={'h-6 w-6 text-blue-600'} /> :
            <TbAlertTriangle className={'h-6 w-6 text-red-600'} />}
        <Caption className={`font-medium uppercase translate-y-[1px]
          ${career.acc_mitra === 'ACCEPTED' ? 'text-green-600' :
            career.acc_mitra === 'PROCESSED' ? 'text-blue-600' :
              'text-red-600'}`}>
          {career.acc_mitra === 'ACCEPTED' ? 'Lolos Seleksi Administrasi' :
            career.acc_mitra === 'PROCESSED' ? 'Menunggu Diproses' :
              'Tidak Lolos Selesksi'}
        </Caption>
      </Row>
      <Column className={'p-4 gap-y-6 xl:p-8'}>
        <Row className={'gap-x-3 items-center'}>
          <Image
            src={career.logo}
            className={'p-1 border rounded-xl'}
            imgClasName={'w-16 aspect-square bg-white'}
            hoverScale={false} />
          <Column className='gap-y-1'>
            <Headline3 className={'hover:text-indigo-600'}>
              {career.posisi}
            </Headline3>
            <Text>
              {career.nama_mitra}
            </Text>
          </Column>
        </Row>
        <Column className={'gap-y-2'}>
          <Text className={'font-medium'}>
            {career.jenis}
          </Text>
          <Text>
            <span className={'font-medium'}>Gaji: </span>
            {career.gaji}
          </Text>
          <Text>
            <span className={'font-medium'}>Lokasi: </span>
            {career.kota}
          </Text>
        </Column>
        <Text className={'line-clamp-2'}>
          {career.deskripsi}
        </Text>
        {
          career.informasi && <Column className={'px-5 py-4 gap-y-2 border border-gray-200 border-l-4 border-l-gray-600 rounded-md transition-all duration-200 group-hover:border-l-green-600'}>
            <Caption className={'font-medium text-gray-800'}>
              {career.nama_mitra}
            </Caption>
            <Caption className={'text-gray-800 whitespace-pre-line line-clamp-3'}>
              {career.informasi}
            </Caption>
            <TextButton
              className={'text-sm hover:text-indigo-600'}
              onClick={() => onShowNote(10, career)}>
              Info Selengkapnya
            </TextButton>
          </Column>
        }
        {
          career.alasan && <Column className={'px-5 py-4 gap-y-2 border border-gray-200 border-l-4 border-l-gray-600 rounded-md transition-all duration-200 group-hover:border-l-red-600'}>
            <Caption className={'font-medium text-gray-800'}>
              {career.nama_mitra}
            </Caption>
            <Caption className={'text-gray-800 whitespace-pre-line line-clamp-3'}>
              {career.alasan}
            </Caption>
            <TextButton
              className={'text-sm hover:text-indigo-600'}
              onClick={() => onShowNote(20, career)}>
              Info Selengkapnya
            </TextButton>
          </Column>
        }
        <Row className={'justify-between items-center'}>
          <Caption>
            Apply pada {moment(career.created_at).format('DD/MM/YYYY HH.mm')} WIB
          </Caption>
          <ButtonHover>
            <PrimaryButton
              pills
              color='indigo'
              className='pl-5 pr-6'
              leadingIcon={
                <TbArrowUpRight className='h-5 w-5 my-auto' />
              }
              onClick={() => onShowDetail(career)}>
              Detail
            </PrimaryButton>
          </ButtonHover>
        </Row>
      </Column>
    </Column>
  )
}

export default ItemApplyCareer