import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { TbMessageQuestion, TbMessageShare } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { ButtonHover, Caption, Column, DotSeparated, Headline3, Overline, PrimaryButton, Row, Text, TextButton } from '../../../shared/ui'
import { almConsultationRoute, konConsultationRoute } from '../../../shared/variable'

function ItemConsultation({ action, consultation }) {

  const navigateTo = useNavigate()

  const [showNew, setShowNew] = useState(false)

  const onClickConsultation = (event) => {
    event.stopPropagation();
    navigateTo(
      `${action === 10 ? almConsultationRoute : konConsultationRoute}/${consultation.slug}`,
      {
        state: {
          id_konsultasi: consultation.id_konsultasi,
          slug: consultation.slug,
          subjek: consultation.subjek,
          topik: consultation.topik,
          publik: consultation.publik,
          nama_alumni: consultation.nama_alumni,
          nama_konselor: consultation.nama_konselor,
          permasalahan: consultation.permasalahan,
          status: consultation.status,
          acc_bidal: consultation.acc_bidal,
          created_at: consultation.created_at
        }
      }
    )
  }

  useEffect(() => {
    if (consultation.last_chat_time !== null && ((action === 10 && consultation.last_chat_from === 4) || (action === 20 && consultation.last_chat_from === 2))) {
      const timeNow = moment()
      const lastChat = moment(consultation.last_chat_time);
      const diff = timeNow.diff(lastChat);
      const diffDuration = moment.duration(diff);

      if (diffDuration.hours() < 1 && diffDuration.minutes() <= 10) {
        setShowNew(true)
      } else {
        if (showNew) {
          setShowNew(false)
        }
      }
    } else {
      if (showNew) {
        setShowNew(false)
      }
    }
  }, [])

  return (
    <Column
      className={'bg-white divide-y divide-gray-200 border-y border-gray-200 cursor-pointer transition-all duration-200 group sm:shadow-sm lg:border-x lg:hover:shadow-md'}
      onClick={onClickConsultation}>
      <Column className={'p-4 gap-y-6 sm:p-8'}>
        <Column className={'gap-y-2'}>
          <Headline3 className={'hover:text-indigo-600'}>
            {consultation.subjek}
          </Headline3>
          <Row>
            {action === 20 && <Row className={'w-fit'}>
              <Caption className={'font-medium'}>
                {consultation.nama_alumni}
              </Caption>
              <DotSeparated />
            </Row>}
            <Caption className={'font-medium'}>
              {consultation.topik}
            </Caption>
            <DotSeparated />
            <Caption className={'font-medium text-blue-600'}>
              {(consultation.publik === 0 || !consultation.publik) ? 'Private' : 'Public'}
            </Caption>
          </Row>
        </Column>
        <Text className={'whitespace-pre-line line-clamp-[10]'}>
          {consultation.permasalahan}
        </Text>
        {
          (consultation.acc_bidal === 'ACCEPTED' && consultation.status !== 'PENDING') ? <Column className={'px-5 py-4 gap-y-2.5 border border-gray-200 border-l-4 border-l-gray-600 rounded-md transition-all duration-200 group-hover:border-l-indigo-600'}>
            <Row className={'items-center'}>
              <Caption className={'font-medium text-gray-800'}>
                {consultation.last_chat_from === 4 ? 'Konselor' : 'Alumni'}
              </Caption>
              <DotSeparated />
              <Overline className={'translate-y-[1px]'}>
                {moment(consultation.last_chat_time).format('DD/MM/YYYY HH.mm')} WIB
              </Overline>
            </Row>
            <Caption className={'text-gray-800 whitespace-pre-line line-clamp-3'}>
              {consultation.last_chat}
            </Caption>
            <Row className={'items-center justify-between'}>
              <TextButton
                className={'text-sm hover:text-indigo-600'}>
                Selengkapnya
              </TextButton>
              {
                showNew && <Overline className={'translate-y-[1px] font-medium text-pink-600'}>
                  BARU
                </Overline>
              }
            </Row>
          </Column> :
            consultation.acc_bidal === 'REJECTED' && <Column className={'px-5 py-4 gap-y-2 border border-gray-200 border-l-4 border-l-gray-600 rounded-md transition-all duration-200 group-hover:border-l-red-600'}>
              <Column className={'gap-x-4 gap-y-1 justify-between sm:flex-row'}>
                <Caption className={'font-medium text-gray-800'}>
                  Info Verifikasi
                </Caption>
                <Overline>
                  {moment(consultation.updated_at).format('DD/MM/YYYY HH.mm')} WIB
                </Overline>
              </Column>
              <Caption className={'text-gray-800 whitespace-pre-line line-clamp-3'}>
                {consultation.alasan_tolak}
              </Caption>
            </Column>
        }
        {(consultation.acc_bidal === 'ACCEPTED' && ((action === 10 && consultation.status === 'ONGOING') || (action === 20 && consultation.status !== 'COMPLETE'))) && <ButtonHover>
          <PrimaryButton
            pills
            color='indigo'
            className={'pl-5 pr-6'}
            leadingIcon={
              consultation.status === 'ONGOING' ?
                <TbMessageShare className={'h-5 w-5 my-auto'} /> :
                <TbMessageQuestion className={'h-5 w-5 my-auto'} />
            }>
            {consultation.status === 'ONGOING' ? 'Balas' : 'Jawab'}
          </PrimaryButton>
        </ButtonHover>}
      </Column>
      <Column className={'px-4 py-3 gap-x-3 gap-y-1 justify-between sm:flex-row sm:px-8 sm:items-center'}>
        <Caption className={`font-medium uppercase
          ${(consultation.acc_bidal === 'ACCEPTED' && consultation.status === 'ONGOING') ? 'text-blue-600' :
            (consultation.acc_bidal === 'ACCEPTED' && consultation.status === 'PENDING') ? 'text-orange-600' :
              (consultation.acc_bidal === 'ACCEPTED' && consultation.status === 'COMPLETE') ? 'text-green-600' :
                consultation.acc_bidal === 'PROCESSED' ? 'text-orange-600' :
                  'text-red-600'}`}>
          {(consultation.acc_bidal === 'ACCEPTED' && consultation.status === 'ONGOING') ? 'Konsultasi Berlangsung' :
            (consultation.acc_bidal === 'ACCEPTED' && consultation.status === 'PENDING') ? 'Menunggu Jawaban Konselor' :
              (consultation.acc_bidal === 'ACCEPTED' && consultation.status === 'COMPLETE') ? 'Konsultasi Selesai' :
                consultation.acc_bidal === 'PROCESSED' ? 'Menunggu Verifikasi Admin' :
                  'Konsultasi Ditolak'}
        </Caption>
        <Caption>
          {moment(consultation.created_at).format('DD/MM/YYYY HH.mm')} WIB
        </Caption>
      </Column>
    </Column>
  )
}

export default ItemConsultation