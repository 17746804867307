import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FullScreen, Headline3, Sidebar, SidebarHeader, SidebarItem, SidebarMenu, Text } from '../shared/ui'

const DesignLayout = ({ children, currentPage }) => {

    const navigate = useNavigate()
    const [showSideMobile, setShowSideMobile] = useState(false)

    const onClickMenu = (destination) => {
        navigate(destination)
    }

    const changeShowSideMobile = (value) => {
        setShowSideMobile(value)
    }

    return (
        <>
            <FullScreen>
                <Sidebar showSideMobile={showSideMobile} hideSideMobile={changeShowSideMobile}>
                    <SidebarHeader>
                        <Headline3 className='font-bold'>
                            UI Design
                        </Headline3>
                        <Text>
                            All custom components for better user interface
                        </Text>
                    </SidebarHeader>
                    <SidebarMenu>
                        <SidebarItem id='/design/alert' onClick={() => onClickMenu('/design/alert')} active={currentPage} title='Alerts' />
                        <SidebarItem id='/design/button' onClick={() => onClickMenu('/design/button')} active={currentPage} title='Buttons' />
                    </SidebarMenu>
                </Sidebar>
                <FullScreen className='overflow-y-auto scrollbar-hide lg:pl-64'>
                    <div className='py-6 px-4 lg:px-7'>
                        {children}
                    </div>
                </FullScreen>
            </FullScreen>
        </>
    )
}

export default DesignLayout
