import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { getFullNameUser } from "../controllers/apicontrollers";
import {
  Caption,
  Card,
  Column,
  DotSeparated,
  FullScHeight,
  Image,
  Row,
  Title,
} from "../shared/ui";
import { tokenApiKey } from "../shared/variable";

const WPreviewEvent = ({ images, title, body, ratio }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [fullName, setFullName] = useState(null);
  const [closePreview, setClosePreview] = useState(false);

  const getCurrentUser = () => {
    const user = jwtDecode(localStorage.getItem(tokenApiKey));
    getFullNameOfUser(user.kode, user.username);
  };

  const getFullNameOfUser = async (identify, username) => {
    const result = await getFullNameUser(identify, username);
    if (result.status) {
      setFullName(result.data);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  console.log("data title:", title);
  console.log("data body:", body);

  return (
    <FullScHeight
      close={closePreview}
      className="md:fixed right-0 top-10 bg-white border rounded-lg w-full md:w-[38%] xl:w-[30%]  mt-10 md:mt-0 z-20 border-l-[1px] overflow-y-auto overscroll-y-contain scrollbar-hide scroll-smooth"
    >
      <Column className="gap-8 ">
        <Row className="sticky top-0 bg-white border-b-[1px] z-10 px-8 pt-6 pb-5">
          <Title>Pratinjau Event</Title>
        </Row>
        <div className="px-8 mb-8 ">
          <Card className="w-full p-8 mb-10">
            <Column className="gap-y-5">
              {images !== null ? (
                <Image
                  src={URL.createObjectURL(images)}
                  className="rounded-xl"
                  imgClasName={`w-full ${ratio}`}
                  hoverScale={false}
                />
              ) : (
                <div className={`w-full ${ratio} bg-slate-50 rounded-lg`} />
              )}
              {title !== "" ? (
                <Title>{title}</Title>
              ) : (
                <div className="w-[80%] h-8 bg-slate-50 rounded-full" />
              )}
              {body !== "" ? (
                <div
                  className="text-gray-800 font-inter text-base"
                  dangerouslySetInnerHTML={{ __html: body }}
                />
              ) : (
                <Column className="gap-1.5">
                  <div className="w-full h-5 bg-slate-50 rounded-full" />
                  <div className="w-full h-5 bg-slate-50 rounded-full" />
                  <div className="w-full h-5 bg-slate-50 rounded-full" />
                  <div className="w-[55%] h-5 bg-slate-50 rounded-full" />
                </Column>
              )}
            </Column>
            <Row className={"mt-4"}>
              <Caption>21 Menyukai</Caption>
              <DotSeparated />
              <Caption>20x Dilihat</Caption>
              <DotSeparated />
              <Caption>4 Komentar</Caption>
            </Row>
          </Card>
        </div>
      </Column>
    </FullScHeight>
  );
};

export default WPreviewEvent;
