import React, { useEffect, useRef, useState } from 'react';
import { TbClipboardText } from 'react-icons/tb';
import { Caption, Card, CheckboxButton, Column, HorizontalDivider, OutlineInput, OutlineTextArea, Overline, RadioButton, Row, Text, Title } from '../shared/ui';

const WAlmKueTiga = ({ reqCheck, onCheck, answer }) => {

    const refCariBlmLulus = useRef(null);
    const refCariSdhLulus = useRef(null);
    const refCariLainnya = useRef(null);
    const refAktifCari = useRef(null);
    const refSesuaiLainnya = useRef(null);

    const [currentPerkuliahan, setCurrentPerkuliahan] = useState(-1)
    const [currentDemonstrasi, setCurrentDemonstrasi] = useState(-1)
    const [currentPRiset, setCurrentPRiset] = useState(-1)
    const [currentMagang, setCurrentMagang] = useState(-1)
    const [currentPraktik, setCurrentPraktik] = useState(-1)
    const [currentKLapangan, setCurrentKLapangan] = useState(-1)
    const [currentDiskusi, setCurrentDiskusi] = useState(-1)
    const [currentCariKerja, setCurrentCariKerja] = useState(-1)
    const [currentAktifCariKerja, setCurrentAktifCariKerja] = useState(-1)

    const [cari1, setCari1] = useState(false)
    const [cari2, setCari2] = useState(false)
    const [cari3, setCari3] = useState(false)
    const [cari4, setCari4] = useState(false)
    const [cari5, setCari5] = useState(false)
    const [cari6, setCari6] = useState(false)
    const [cari7, setCari7] = useState(false)
    const [cari8, setCari8] = useState(false)
    const [cari9, setCari9] = useState(false)
    const [cari10, setCari10] = useState(false)
    const [cari11, setCari11] = useState(false)
    const [cari12, setCari12] = useState(false)
    const [cari13, setCari13] = useState(false)
    const [cari14, setCari14] = useState(false)
    const [cari15, setCari15] = useState(false)
    const [alasan1, setAlasan1] = useState(false)
    const [alasan2, setAlasan2] = useState(false)
    const [alasan3, setAlasan3] = useState(false)
    const [alasan4, setAlasan4] = useState(false)
    const [alasan5, setAlasan5] = useState(false)
    const [alasan6, setAlasan6] = useState(false)
    const [alasan7, setAlasan7] = useState(false)
    const [alasan8, setAlasan8] = useState(false)
    const [alasan9, setAlasan9] = useState(false)
    const [alasan10, setAlasan10] = useState(false)
    const [alasan11, setAlasan11] = useState(false)
    const [alasan12, setAlasan12] = useState(false)
    const [alasan13, setAlasan13] = useState(false)

    const [cariBlmLulus, setCariBlmLulus] = useState(null)
    const [cariSdhLulus, setCariSdhLulus] = useState(null)
    const [lainCariKerja, setLainCariKerja] = useState(null)
    const [lainAktifCariKerja, setLainAktifCariKerja] = useState(null)
    const [lainSesuaiKerja, setLainSesuaiKerja] = useState(null)

    const [jmlLamarInput, setJmlLamarInput] = useState(null)
    const [jmlResponInput, setJmlResponInput] = useState(null)
    const [jmlWawaInput, setJmlWawaInput] = useState(null)

    const [saran, setSaran] = useState(null)

    const GetAnswerQuestion = (kode) => {
        const value = answer[kode]
        return value
    }

    const onChangeRadio = (result) => {
        switch (result['action']) {
            case 10:
                setCurrentPerkuliahan(parseInt(result['value']))
                break;
            case 20:
                setCurrentDemonstrasi(parseInt(result['value']))
                break;
            case 30:
                setCurrentPRiset(parseInt(result['value']))
                break;
            case 40:
                setCurrentMagang(parseInt(result['value']))
                break;
            case 50:
                setCurrentPraktik(parseInt(result['value']))
                break;
            case 60:
                setCurrentKLapangan(parseInt(result['value']))
                break;
            case 70:
                setCurrentDiskusi(parseInt(result['value']))
                break;
            case 80:
                setCurrentCariKerja(parseInt(result['value']))
                if (parseInt(result['value']) === 1) {
                    refCariSdhLulus.current.value = '';
                    setCariSdhLulus(null)
                } else if (parseInt(result['value']) === 2) {
                    refCariBlmLulus.current.value = '';
                    setCariBlmLulus(null)
                } else {
                    refCariBlmLulus.current.value = '';
                    refCariSdhLulus.current.value = '';
                    setCariSdhLulus(null)
                    setCariBlmLulus(null)
                }
                break;
            case 90:
                setCurrentAktifCariKerja(parseInt(result['value']))
                if (parseInt(result['value']) !== 5) {
                    refAktifCari.current.value = ''
                    setLainAktifCariKerja(null)
                }
                break;
        }
    }

    const onChangeCheckbox = (result) => {
        switch (result['action']) {
            case 10:
                setCari1(result['value'])
                break;
            case 20:
                setCari2(result['value'])
                break;
            case 30:
                setCari3(result['value'])
                break;
            case 40:
                setCari4(result['value'])
                break;
            case 50:
                setCari5(result['value'])
                break;
            case 60:
                setCari6(result['value'])
                break;
            case 70:
                setCari7(result['value'])
                break;
            case 80:
                setCari8(result['value'])
                break;
            case 90:
                setCari9(result['value'])
                break;
            case 100:
                setCari10(result['value'])
                break;
            case 110:
                setCari11(result['value'])
                break;
            case 120:
                setCari12(result['value'])
                break;
            case 130:
                setCari13(result['value'])
                break;
            case 140:
                setCari14(result['value'])
                break;
            case 150:
                setCari15(result['value'])
                if (!result['value']) {
                    refCariLainnya.current.value = ''
                    setLainCariKerja(null)
                }
                break;
            case 160:
                setAlasan1(result['value'])
                break;
            case 170:
                setAlasan2(result['value'])
                break;
            case 180:
                setAlasan3(result['value'])
                break;
            case 190:
                setAlasan4(result['value'])
                break;
            case 200:
                setAlasan5(result['value'])
                break;
            case 210:
                setAlasan6(result['value'])
                break;
            case 220:
                setAlasan7(result['value'])
                break;
            case 230:
                setAlasan8(result['value'])
                break;
            case 240:
                setAlasan9(result['value'])
                break;
            case 250:
                setAlasan10(result['value'])
                break;
            case 260:
                setAlasan11(result['value'])
                break;
            case 270:
                setAlasan12(result['value'])
                break;
            case 280:
                setAlasan13(result['value'])
                if (!result['value']) {
                    refSesuaiLainnya.current.value = ''
                    setLainSesuaiKerja(null)
                }
                break;
        }
    }

    const onChangeInput = (result) => {
        switch (result['action']) {
            case 10:
                setCariBlmLulus(result['value'])
                break;
            case 20:
                setCariSdhLulus(result['value'])
                break;
            case 30:
                setLainCariKerja(result['value'])
                break;
            case 40:
                setJmlLamarInput(result['value'])
                break;
            case 50:
                setJmlResponInput(result['value'])
                break;
            case 60:
                setJmlWawaInput(result['value'])
                break;
            case 70:
                setLainAktifCariKerja(result['value'])
                break;
            case 80:
                setLainSesuaiKerja(result['value'])
                break;
        }
    }

    const onChangeTextArea = (event) => {
        event.preventDefault();
        const { value } = event.target
        setSaran(value)
    }

    const verifyInputQuestion = () => {
        let status = true
        if (currentCariKerja === 1) {
            if (cariBlmLulus === null) {
                status = false
            }
        } else if (currentCariKerja === 2) {
            if (cariSdhLulus === null) {
                status = false
            }
        }

        if (cari15) {
            if (lainCariKerja === null) {
                status = false
            }
        }

        if (currentAktifCariKerja === 5) {
            if (lainAktifCariKerja === null) {
                status = false
            }
        }

        if (alasan13) {
            if (lainSesuaiKerja === null) {
                status = false
            }
        }
        return status
    }

    useEffect(() => {
        setCurrentPerkuliahan(GetAnswerQuestion('f21'))
        setCurrentDemonstrasi(GetAnswerQuestion('f22'))
        setCurrentPRiset(GetAnswerQuestion('f23'))
        setCurrentMagang(GetAnswerQuestion('f24'))
        setCurrentPraktik(GetAnswerQuestion('f25'))
        setCurrentKLapangan(GetAnswerQuestion('f26'))
        setCurrentDiskusi(GetAnswerQuestion('f27'))
        setCurrentCariKerja(GetAnswerQuestion('f301'))
        setCurrentAktifCariKerja(GetAnswerQuestion('f1001'))

        setCari1(GetAnswerQuestion('f401'))
        setCari2(GetAnswerQuestion('f402'))
        setCari3(GetAnswerQuestion('f403'))
        setCari4(GetAnswerQuestion('f404'))
        setCari5(GetAnswerQuestion('f405'))
        setCari6(GetAnswerQuestion('f406'))
        setCari7(GetAnswerQuestion('f407'))
        setCari8(GetAnswerQuestion('f408'))
        setCari9(GetAnswerQuestion('f409'))
        setCari10(GetAnswerQuestion('f410'))
        setCari11(GetAnswerQuestion('f411'))
        setCari12(GetAnswerQuestion('f412'))
        setCari13(GetAnswerQuestion('f413'))
        setCari14(GetAnswerQuestion('f414'))
        setCari15(GetAnswerQuestion('f415'))
        setAlasan1(GetAnswerQuestion('f1601'))
        setAlasan2(GetAnswerQuestion('f1602'))
        setAlasan3(GetAnswerQuestion('f1603'))
        setAlasan4(GetAnswerQuestion('f1604'))
        setAlasan5(GetAnswerQuestion('f1605'))
        setAlasan6(GetAnswerQuestion('f1606'))
        setAlasan7(GetAnswerQuestion('f1607'))
        setAlasan8(GetAnswerQuestion('f1608'))
        setAlasan9(GetAnswerQuestion('f1609'))
        setAlasan10(GetAnswerQuestion('f1610'))
        setAlasan11(GetAnswerQuestion('f1611'))
        setAlasan12(GetAnswerQuestion('f1612'))
        setAlasan13(GetAnswerQuestion('f1613'))

        setCariBlmLulus(GetAnswerQuestion('f302'))
        setCariSdhLulus(GetAnswerQuestion('f303'))
        setLainCariKerja(GetAnswerQuestion('f416'))
        setLainAktifCariKerja(GetAnswerQuestion('f1002'))
        setLainSesuaiKerja(GetAnswerQuestion('f1614'))

        setJmlLamarInput(GetAnswerQuestion('f6'))
        setJmlResponInput(GetAnswerQuestion('f7'))
        setJmlWawaInput(GetAnswerQuestion('f7a'))

        setSaran(GetAnswerQuestion('saran'))
    }, [])

    useEffect(() => {
        if (reqCheck) {
            const status = verifyInputQuestion()
            const result = {
                'status': status,
                'action': 30,
                'value': {
                    'f21': currentPerkuliahan === -1 ? null : currentPerkuliahan,
                    'f22': currentDemonstrasi === -1 ? null : currentDemonstrasi,
                    'f23': currentPRiset === -1 ? null : currentPRiset,
                    'f24': currentMagang === -1 ? null : currentMagang,
                    'f25': currentPraktik === -1 ? null : currentPraktik,
                    'f26': currentKLapangan === -1 ? null : currentKLapangan,
                    'f27': currentDiskusi === -1 ? null : currentDiskusi,
                    'f301': currentCariKerja === -1 ? null : currentCariKerja,
                    'f302': cariBlmLulus,
                    'f303': cariSdhLulus,
                    'f401': cari1,
                    'f402': cari2,
                    'f403': cari3,
                    'f404': cari4,
                    'f405': cari5,
                    'f406': cari6,
                    'f407': cari7,
                    'f408': cari8,
                    'f409': cari9,
                    'f410': cari10,
                    'f411': cari11,
                    'f412': cari12,
                    'f413': cari13,
                    'f414': cari14,
                    'f415': cari15,
                    'f416': lainCariKerja,
                    'f6': jmlLamarInput,
                    'f7': jmlResponInput,
                    'f7a': jmlWawaInput,
                    'f1001': currentAktifCariKerja === -1 ? null : currentAktifCariKerja,
                    'f1002': lainAktifCariKerja,
                    'f1601': alasan1,
                    'f1602': alasan2,
                    'f1603': alasan3,
                    'f1604': alasan4,
                    'f1605': alasan5,
                    'f1606': alasan6,
                    'f1607': alasan7,
                    'f1608': alasan8,
                    'f1609': alasan9,
                    'f1610': alasan10,
                    'f1611': alasan11,
                    'f1612': alasan12,
                    'f1613': alasan13,
                    'f1614': lainSesuaiKerja,
                    'saran': saran,
                }
            }
            onCheck(result)
        }
    }, [reqCheck])

    return (
        <Card className='w-full p-0'>
            <Column>
                <Row className='px-4 py-3 sm:px-8 sm:py-5'>
                    <Column className='gap-0.5 my-auto'>
                        <Title>
                            Kuesioner Opsional
                        </Title>
                        <Caption>
                            Isi kuesioner yang diperlukan
                        </Caption>
                    </Column>
                    <TbClipboardText className='hidden sm:flex h-14 w-14 my-auto stroke-1.5 text-indigo-300' />
                </Row>
                <HorizontalDivider className='mt-0 mb-0' />
                <Column className='px-4 py-3 sm:px-8 sm:py-6 gap-6 mb-4'>
                    <Column className='gap-3'>
                        <Text className='font-manrope font-semibold'>
                            Menurut Anda seberapa besar penekanan pada metode pembelajaran dibawah ini dilaksanakan di program studi anda?
                        </Text>
                        <Column className='gap-1.5'>
                            <Caption className='w-full my-auto text-gray-800'>
                                1. Perkuliahan
                            </Caption>
                            <Card className='w-full p-0 overflow-hidden shadow-none'>
                                <Column className='divide-y'>
                                    <RadioButton
                                        action={10}
                                        onChange={onChangeRadio}
                                        id='f21-1'
                                        name='f21'
                                        value={1}
                                        checked={currentPerkuliahan === 1}
                                        label='Sangat Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={10}
                                        onChange={onChangeRadio}
                                        id='f21-2'
                                        name='f21'
                                        value={2}
                                        checked={currentPerkuliahan === 2}
                                        label='Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={10}
                                        onChange={onChangeRadio}
                                        id='f21-3'
                                        name='f21'
                                        value={3}
                                        checked={currentPerkuliahan === 3}
                                        label='Cukup Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={10}
                                        onChange={onChangeRadio}
                                        id='f21-4'
                                        name='f21'
                                        value={4}
                                        checked={currentPerkuliahan === 4}
                                        label='Kurang'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={10}
                                        onChange={onChangeRadio}
                                        id='f21-5'
                                        name='f21'
                                        value={5}
                                        checked={currentPerkuliahan === 5}
                                        label='Tidak Sama Sekali'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                </Column>
                            </Card>
                        </Column>
                        <Column className='gap-1.5'>
                            <Caption className='w-full my-auto text-gray-800'>
                                2. Demonstrasi
                            </Caption>
                            <Card className='w-full p-0 overflow-hidden shadow-none'>
                                <Column className='divide-y'>
                                    <RadioButton
                                        action={20}
                                        onChange={onChangeRadio}
                                        id='f22-1'
                                        name='f22'
                                        value={1}
                                        checked={currentDemonstrasi === 1}
                                        label='Sangat Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={20}
                                        onChange={onChangeRadio}
                                        id='f22-2'
                                        name='f22'
                                        value={2}
                                        checked={currentDemonstrasi === 2}
                                        label='Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={20}
                                        onChange={onChangeRadio}
                                        id='f22-3'
                                        name='f22'
                                        value={3}
                                        checked={currentDemonstrasi === 3}
                                        label='Cukup Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={20}
                                        onChange={onChangeRadio}
                                        id='f22-4'
                                        name='f22'
                                        value={4}
                                        checked={currentDemonstrasi === 4}
                                        label='Kurang'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={20}
                                        onChange={onChangeRadio}
                                        id='f22-5'
                                        name='f22'
                                        value={5}
                                        checked={currentDemonstrasi === 5}
                                        label='Tidak Sama Sekali'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                </Column>
                            </Card>
                        </Column>
                        <Column className='gap-1.5'>
                            <Caption className='w-full my-auto text-gray-800'>
                                3. Partisipasi dalam proyek riset
                            </Caption>
                            <Card className='w-full p-0 overflow-hidden shadow-none'>
                                <Column className='divide-y'>
                                    <RadioButton
                                        action={30}
                                        onChange={onChangeRadio}
                                        id='f23-1'
                                        name='f23'
                                        value={1}
                                        checked={currentPRiset === 1}
                                        label='Sangat Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={30}
                                        onChange={onChangeRadio}
                                        id='f23-2'
                                        name='f23'
                                        value={2}
                                        checked={currentPRiset === 2}
                                        label='Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={30}
                                        onChange={onChangeRadio}
                                        id='f23-3'
                                        name='f23'
                                        value={3}
                                        checked={currentPRiset === 3}
                                        label='Cukup Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={30}
                                        onChange={onChangeRadio}
                                        id='f23-4'
                                        name='f23'
                                        value={4}
                                        checked={currentPRiset === 4}
                                        label='Kurang'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={30}
                                        onChange={onChangeRadio}
                                        id='f23-5'
                                        name='f23'
                                        value={5}
                                        checked={currentPRiset === 5}
                                        label='Tidak Sama Sekali'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                </Column>
                            </Card>
                        </Column>
                        <Column className='gap-1.5'>
                            <Caption className='w-full my-auto text-gray-800'>
                                4. Magang
                            </Caption>
                            <Card className='w-full p-0 overflow-hidden shadow-none'>
                                <Column className='divide-y'>
                                    <RadioButton
                                        action={40}
                                        onChange={onChangeRadio}
                                        id='f24-1'
                                        name='f24'
                                        value={1}
                                        checked={currentMagang === 1}
                                        label='Sangat Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={40}
                                        onChange={onChangeRadio}
                                        id='f24-2'
                                        name='f24'
                                        value={2}
                                        checked={currentMagang === 2}
                                        label='Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={40}
                                        onChange={onChangeRadio}
                                        id='f24-3'
                                        name='f24'
                                        value={3}
                                        checked={currentMagang === 3}
                                        label='Cukup Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={40}
                                        onChange={onChangeRadio}
                                        id='f24-4'
                                        name='f24'
                                        value={4}
                                        checked={currentMagang === 4}
                                        label='Kurang'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={40}
                                        onChange={onChangeRadio}
                                        id='f24-5'
                                        name='f24'
                                        value={5}
                                        checked={currentMagang === 5}
                                        label='Tidak Sama Sekali'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                </Column>
                            </Card>
                        </Column>
                        <Column className='gap-1.5'>
                            <Caption className='w-full my-auto text-gray-800'>
                                5. Praktikum
                            </Caption>
                            <Card className='w-full p-0 overflow-hidden shadow-none'>
                                <Column className='divide-y'>
                                    <RadioButton
                                        action={50}
                                        onChange={onChangeRadio}
                                        id='f25-1'
                                        name='f25'
                                        value={1}
                                        checked={currentPraktik === 1}
                                        label='Sangat Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={50}
                                        onChange={onChangeRadio}
                                        id='f25-2'
                                        name='f25'
                                        value={2}
                                        checked={currentPraktik === 2}
                                        label='Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={50}
                                        onChange={onChangeRadio}
                                        id='f25-3'
                                        name='f25'
                                        value={3}
                                        checked={currentPraktik === 3}
                                        label='Cukup Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={50}
                                        onChange={onChangeRadio}
                                        id='f25-4'
                                        name='f25'
                                        value={4}
                                        checked={currentPraktik === 4}
                                        label='Kurang'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={50}
                                        onChange={onChangeRadio}
                                        id='f25-5'
                                        name='f25'
                                        value={5}
                                        checked={currentPraktik === 5}
                                        label='Tidak Sama Sekali'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                </Column>
                            </Card>
                        </Column>
                        <Column className='gap-1.5'>
                            <Caption className='w-full my-auto text-gray-800'>
                                6. Kerja Lapangan
                            </Caption>
                            <Card className='w-full p-0 overflow-hidden shadow-none'>
                                <Column className='divide-y'>
                                    <RadioButton
                                        action={60}
                                        onChange={onChangeRadio}
                                        id='f26-1'
                                        name='f26'
                                        value={1}
                                        checked={currentKLapangan === 1}
                                        label='Sangat Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={60}
                                        onChange={onChangeRadio}
                                        id='f26-2'
                                        name='f26'
                                        value={2}
                                        checked={currentKLapangan === 2}
                                        label='Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={60}
                                        onChange={onChangeRadio}
                                        id='f26-3'
                                        name='f26'
                                        value={3}
                                        checked={currentKLapangan === 3}
                                        label='Cukup Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={60}
                                        onChange={onChangeRadio}
                                        id='f26-4'
                                        name='f26'
                                        value={4}
                                        checked={currentKLapangan === 4}
                                        label='Kurang'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={60}
                                        onChange={onChangeRadio}
                                        id='f26-5'
                                        name='f26'
                                        value={5}
                                        checked={currentKLapangan === 5}
                                        label='Tidak Sama Sekali'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                </Column>
                            </Card>
                        </Column>
                        <Column className='gap-1.5'>
                            <Caption className='w-full my-auto text-gray-800'>
                                7. Diskusi
                            </Caption>
                            <Card className='w-full p-0 overflow-hidden shadow-none'>
                                <Column className='divide-y'>
                                    <RadioButton
                                        action={70}
                                        onChange={onChangeRadio}
                                        id='f27-1'
                                        name='f27'
                                        value={1}
                                        checked={currentDiskusi === 1}
                                        label='Sangat Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={70}
                                        onChange={onChangeRadio}
                                        id='f27-2'
                                        name='f27'
                                        value={2}
                                        checked={currentDiskusi === 2}
                                        label='Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={70}
                                        onChange={onChangeRadio}
                                        id='f27-3'
                                        name='f27'
                                        value={3}
                                        checked={currentDiskusi === 3}
                                        label='Cukup Besar'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={70}
                                        onChange={onChangeRadio}
                                        id='f27-4'
                                        name='f27'
                                        value={4}
                                        checked={currentDiskusi === 4}
                                        label='Kurang'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <RadioButton
                                        action={70}
                                        onChange={onChangeRadio}
                                        id='f27-5'
                                        name='f27'
                                        value={5}
                                        checked={currentDiskusi === 5}
                                        label='Tidak Sama Sekali'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                </Column>
                            </Card>
                        </Column>
                    </Column>
                    <Column className='gap-3'>
                        <Column className='gap-1.5'>
                            <Text className='font-manrope font-semibold'>
                                Kapan anda mulai mencari pekerjaan?
                            </Text>
                            <Overline className='text-indigo-700'>
                                Mohon pekerjaan sambilan tidak dimasukkan
                            </Overline>
                        </Column>
                        <Card className='w-full p-0 overflow-hidden shadow-none'>
                            <Column className='divide-y'>
                                <div className='relative'>
                                    <RadioButton
                                        action={80}
                                        onChange={onChangeRadio}
                                        id='f301-1'
                                        name='f301'
                                        value={1}
                                        checked={currentCariKerja === 1}
                                        label='Kira-kira'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <label htmlFor='f301-1' className='row gap-3 absolute inset-y-0 my-auto pl-[6.7rem] sm:pl-28 cursor-pointer'>
                                        <div className='my-auto w-[50%] sm:w-[20%]'>
                                            <OutlineInput
                                                refs={refCariBlmLulus}
                                                action={10}
                                                id='f302'
                                                type='text'
                                                inputWidth='w-full'
                                                className='rounded-md p-1.5 text-center'
                                                placeholder=''
                                                defaultValue={cariBlmLulus}
                                                maxLength={2}
                                                onChange={onChangeInput}
                                                onKeyDown={(event) => {
                                                    if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                                                        if (!/^[0-9\b]+$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }
                                                }}
                                                required={currentCariKerja === 1}
                                                disabled={currentCariKerja !== 1} />
                                        </div>
                                        <Overline className='w-full text-gray-800 sm:caption sm:text-gray-800 my-auto'>
                                            Bulan sebelum lulus
                                        </Overline>
                                    </label>
                                </div>
                                <div className='relative'>
                                    <RadioButton
                                        action={80}
                                        onChange={onChangeRadio}
                                        id='f301-2'
                                        name='f301'
                                        value={2}
                                        checked={currentCariKerja === 2}
                                        label='Kira-kira'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <label htmlFor='f301-2' className='row gap-3 absolute inset-y-0 my-auto pl-[6.7rem] sm:pl-28 cursor-pointer'>
                                        <div className='my-auto w-[50%] sm:w-[20%]'>
                                            <OutlineInput
                                                refs={refCariSdhLulus}
                                                action={20}
                                                id='f303'
                                                type='text'
                                                inputWidth='w-full'
                                                className='rounded-md p-1.5 text-center'
                                                placeholder=''
                                                defaultValue={cariSdhLulus}
                                                maxLength={2}
                                                onChange={onChangeInput}
                                                onKeyDown={(event) => {
                                                    if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                                                        if (!/^[0-9\b]+$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }
                                                }}
                                                required={currentCariKerja === 2}
                                                disabled={currentCariKerja !== 2} />
                                        </div>
                                        <Overline className='w-full text-gray-800 sm:caption sm:text-gray-800 my-auto'>
                                            Bulan sesudah lulus
                                        </Overline>
                                    </label>
                                </div>
                                <RadioButton
                                    action={80}
                                    onChange={onChangeRadio}
                                    id='f301-3'
                                    name='f301'
                                    value={3}
                                    checked={currentCariKerja === 3}
                                    label='Saya tidak mencari kerja'
                                    className='w-full pl-3 hover:bg-slate-50' />
                            </Column>
                        </Card>
                    </Column>
                    <Column className='gap-3'>
                        <Column className='gap-1.5'>
                            <Text className='font-manrope font-semibold'>
                                Bagaimana anda mencari pekerjaan tersebut?
                            </Text>
                            <Overline className='text-indigo-700'>
                                Jawaban bisa lebih dari satu
                            </Overline>
                        </Column>
                        <Card className='w-full p-0 overflow-hidden shadow-none'>
                            <Column className='divide-y'>
                                <CheckboxButton
                                    action={10}
                                    onChange={onChangeCheckbox}
                                    id='f401'
                                    label='Melalui iklan di koran/majalah, brosur.'
                                    checked={cari1}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={20}
                                    onChange={onChangeCheckbox}
                                    id='f402'
                                    label='Melamar ke perusahaan tanpa mengetahui lowongan yang ada.'
                                    checked={cari2}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={30}
                                    onChange={onChangeCheckbox}
                                    id='f403'
                                    label='Pergi ke bursa/pameran kerja.'
                                    checked={cari3}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={40}
                                    onChange={onChangeCheckbox}
                                    id='f404'
                                    label='Mencari lewat internet/iklan online/milis.'
                                    checked={cari4}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={50}
                                    onChange={onChangeCheckbox}
                                    id='f405'
                                    label='Dihubungi oleh perusahaan.'
                                    checked={cari5}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={60}
                                    onChange={onChangeCheckbox}
                                    id='f406'
                                    label='Menghubungi Kemenakertrans.'
                                    checked={cari6}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={70}
                                    onChange={onChangeCheckbox}
                                    id='f407'
                                    label='Menghubungi agen tenaga kerja komersial/swasta.'
                                    checked={cari7}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={80}
                                    onChange={onChangeCheckbox}
                                    id='f408'
                                    label='Memeroleh informasi dari pusat/kantor pengembangan karir fakultas/universitas.'
                                    checked={cari8}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={90}
                                    onChange={onChangeCheckbox}
                                    id='f409'
                                    label='Menghubungi kantor kemahasiswaan/hubungan alumni.'
                                    checked={cari9}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={100}
                                    onChange={onChangeCheckbox}
                                    id='f410'
                                    label='Membangun jejaring (network) sejak masih kuliah.'
                                    checked={cari10}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={110}
                                    onChange={onChangeCheckbox}
                                    id='f411'
                                    label='Melalui relasi (misalnya dosen, orang tua, saudara, teman, dll.)'
                                    checked={cari11}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={120}
                                    onChange={onChangeCheckbox}
                                    id='f412'
                                    label='Membangun bisnis sendiri.'
                                    checked={cari12}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={130}
                                    onChange={onChangeCheckbox}
                                    id='f413'
                                    label='Melalui penempatan kerja atau magang.'
                                    checked={cari13}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={140}
                                    onChange={onChangeCheckbox}
                                    id='f414'
                                    label='Bekerja di tempat yang sama dengan tempat kerja semasa kuliah.'
                                    checked={cari14}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <Column>
                                    <CheckboxButton
                                        action={150}
                                        onChange={onChangeCheckbox}
                                        id='f415'
                                        label='Lainnya'
                                        checked={cari15}
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <div className={`p-4 border-t ${cari15 ? 'flex' : 'hidden'}`}>
                                        <OutlineInput
                                            refs={refCariLainnya}
                                            action={30}
                                            id='f416'
                                            type='text'
                                            inputWidth='w-full'
                                            className='rounded-md'
                                            label='Tuliskan lainnya'
                                            labelClassName='text-gray-800'
                                            placeholder='Jawaban anda'
                                            onChange={onChangeInput}
                                            defaultValue={lainCariKerja}
                                            maxLength={50}
                                            required={cari15} />
                                    </div>
                                </Column>
                            </Column>
                        </Card>
                    </Column>
                    <Column className='gap-1.5'>
                        <Text className='font-manrope font-semibold'>
                            Berapa perusahan/instansi/institusi yang sudah anda lamar (lewat surat atau e-mail) sebelum anda memeroleh pekerjaan pertama?
                        </Text>
                        <Row className='column sm:row gap-x-3 gap-y-1.5'>
                            <div className='w-full sm:w-[30%]'>
                                <OutlineInput
                                    action={40}
                                    id='f6'
                                    type='text'
                                    inputWidth='w-full'
                                    className='rounded-md'
                                    placeholder='Jawaban anda'
                                    onChange={onChangeInput}
                                    onKeyDown={(event) => {
                                        if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                                            if (!/^[0-9\b]+$/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                    defaultValue={jmlLamarInput}
                                    maxLength={2} />
                            </div>
                            <Caption className='my-auto text-gray-800'>
                                Perusahaan/instansi/institusi.
                            </Caption>
                        </Row>
                    </Column>
                    <Column className='gap-1.5'>
                        <Text className='font-manrope font-semibold'>
                            Berapa banyak perusahan/instansi/institusi yang merespons lamaran anda?
                        </Text>
                        <Row className='column sm:row gap-x-3 gap-y-1.5'>
                            <div className='w-full sm:w-[30%]'>
                                <OutlineInput
                                    action={50}
                                    id='f7'
                                    type='text'
                                    inputWidth='w-full'
                                    className='rounded-md'
                                    placeholder='Jawaban anda'
                                    onChange={onChangeInput}
                                    onKeyDown={(event) => {
                                        if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                                            if (!/^[0-9\b]+$/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                    defaultValue={jmlResponInput}
                                    maxLength={2} />
                            </div>
                            <Caption className='my-auto text-gray-800'>
                                Perusahaan/instansi/institusi.
                            </Caption>
                        </Row>
                    </Column>
                    <Column className='gap-1.5'>
                        <Text className='font-manrope font-semibold'>
                            Berapa banyak perusahan/instansi/institusi yang mengundang anda untuk wawancara?
                        </Text>
                        <Row className='column sm:row gap-x-3 gap-y-1.5'>
                            <div className='w-full sm:w-[30%]'>
                                <OutlineInput
                                    action={60}
                                    id='f7a'
                                    type='text'
                                    inputWidth='w-full'
                                    className='rounded-md'
                                    placeholder='Jawaban anda'
                                    onChange={onChangeInput}
                                    onKeyDown={(event) => {
                                        if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                                            if (!/^[0-9\b]+$/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                    defaultValue={jmlWawaInput}
                                    maxLength={2} />
                            </div>
                            <Caption className='my-auto text-gray-800'>
                                Perusahaan/instansi/institusi.
                            </Caption>
                        </Row>
                    </Column>
                    <Column className='gap-1.5'>
                        <Text className='font-manrope font-semibold'>
                            Apakah anda aktif mencari pekerjaan dalam 4 minggu terakhir?
                        </Text>
                        <Card className='w-full p-0 overflow-hidden shadow-none'>
                            <Column className='divide-y'>
                                <RadioButton
                                    action={90}
                                    onChange={onChangeRadio}
                                    id='f1001-3'
                                    name='f1001'
                                    value={1}
                                    checked={currentAktifCariKerja === 1}
                                    label='Tidak.'
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <RadioButton
                                    action={90}
                                    onChange={onChangeRadio}
                                    id='f1001-4'
                                    name='f1001'
                                    value={2}
                                    checked={currentAktifCariKerja === 2}
                                    label='Tidak, tapi saya sedang menunggu hasil lamaran kerja.'
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <RadioButton
                                    action={90}
                                    onChange={onChangeRadio}
                                    id='f1001-5'
                                    name='f1001'
                                    value={3}
                                    checked={currentAktifCariKerja === 3}
                                    label='Ya, saya akan mulai bekerja dalam 2 minggu ke depan.'
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <RadioButton
                                    action={90}
                                    onChange={onChangeRadio}
                                    id='f1001-6'
                                    name='f1001'
                                    value={4}
                                    checked={currentAktifCariKerja === 4}
                                    label='Ya, tapi saya belum pasti akan bekerja dalam 2 minggu ke depan.'
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <Column>
                                    <RadioButton
                                        action={90}
                                        onChange={onChangeRadio}
                                        id='f1001-7'
                                        name='f1001'
                                        value={5}
                                        checked={currentAktifCariKerja === 5}
                                        label='Lainnya'
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <div className={`p-4 border-t ${currentAktifCariKerja === 5 ? 'flex' : 'hidden'}`}>
                                        <OutlineInput
                                            refs={refAktifCari}
                                            action={70}
                                            id='f1002'
                                            type='text'
                                            inputWidth='w-full'
                                            className='rounded-md'
                                            label='Tuliskan lainnya'
                                            labelClassName='text-gray-800'
                                            placeholder='Jawaban anda'
                                            onChange={onChangeInput}
                                            defaultValue={lainAktifCariKerja}
                                            maxLength={50}
                                            required={currentAktifCariKerja === 5} />
                                    </div>
                                </Column>
                            </Column>
                        </Card>
                    </Column>
                    <Column className='gap-3'>
                        <Column className='gap-1.5'>
                            <Text className='font-manrope font-semibold'>
                                Jika menurut anda pekerjaan anda saat ini tidak sesuai dengan pendidikan anda, mengapa anda mengambilnya? Jawaban bisa lebih dari satu.
                            </Text>
                            <Overline className='text-indigo-700'>
                                Jawaban bisa lebih dari satu
                            </Overline>
                        </Column>
                        <Card className='w-full p-0 overflow-hidden shadow-none'>
                            <Column className='divide-y'>
                                <CheckboxButton
                                    action={160}
                                    onChange={onChangeCheckbox}
                                    id='f1601'
                                    label='Pertanyaan tidak sesuai, pekerjaan saya sekarang sudah sesuai dengan pendidikan saya.'
                                    checked={alasan1}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={170}
                                    onChange={onChangeCheckbox}
                                    id='f1602'
                                    label='Saya belum mendapatkan pekerjaan yang lebih sesuai.'
                                    checked={alasan2}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={180}
                                    onChange={onChangeCheckbox}
                                    id='f1603'
                                    label='Di pekerjaan ini saya memeroleh prospek karir yang baik.'
                                    checked={alasan3}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={190}
                                    onChange={onChangeCheckbox}
                                    id='f1604'
                                    label='Saya lebih suka bekerja di area pekerjaan yang tidak ada hubungannya dengan pendidikan saya.'
                                    checked={alasan4}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={200}
                                    onChange={onChangeCheckbox}
                                    id='f1605'
                                    label='Saya dipromosikan ke posisi yang kurang berhubungan dengan pendidikan saya dibanding posisi sebelumnya.'
                                    checked={alasan5}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={210}
                                    onChange={onChangeCheckbox}
                                    id='f1606'
                                    label='Saya dapat memeroleh pendapatan yang lebih tinggi di pekerjaan ini.'
                                    checked={alasan6}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={220}
                                    onChange={onChangeCheckbox}
                                    id='f1607'
                                    label='Pekerjaan saya saat ini lebih aman/terjamin/secure.'
                                    checked={alasan7}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={230}
                                    onChange={onChangeCheckbox}
                                    id='f1608'
                                    label='Pekerjaan saya saat ini lebih menarik.'
                                    checked={alasan8}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={240}
                                    onChange={onChangeCheckbox}
                                    id='f1609'
                                    label='Pekerjaan saya saat ini lebih memungkinkan saya mengambil pekerjaan tambahan/jadwal yang fleksibel, dll.'
                                    checked={alasan9}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={250}
                                    onChange={onChangeCheckbox}
                                    id='f1610'
                                    label='Pekerjaan saya saat ini lokasinya lebih dekat dari rumah saya.'
                                    checked={alasan10}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={260}
                                    onChange={onChangeCheckbox}
                                    id='f1611'
                                    label='Pekerjaan saya saat ini dapat lebih menjamin kebutuhan keluarga saya.'
                                    checked={alasan11}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <CheckboxButton
                                    action={270}
                                    onChange={onChangeCheckbox}
                                    id='f1612'
                                    label='Pada awal meniti karir ini, saya harus menerima pekerjaan yang tidak berhubungan dengan pendidikan saya.'
                                    checked={alasan12}
                                    className='w-full pl-3 hover:bg-slate-50' />
                                <Column>
                                    <CheckboxButton
                                        action={280}
                                        onChange={onChangeCheckbox}
                                        id='f1613'
                                        label='Lainnya'
                                        checked={alasan13}
                                        className='w-full pl-3 hover:bg-slate-50' />
                                    <div className={`p-4 border-t ${alasan13 ? 'flex' : 'hidden'}`}>
                                        <OutlineInput
                                            refs={refSesuaiLainnya}
                                            action={80}
                                            id='f1614'
                                            type='text'
                                            inputWidth='w-full'
                                            className='rounded-md'
                                            label='Tuliskan lainnya'
                                            labelClassName='text-gray-800'
                                            placeholder='Jawaban anda'
                                            onChange={onChangeInput}
                                            defaultValue={lainSesuaiKerja}
                                            maxLength={50}
                                            required={alasan13} />
                                    </div>
                                </Column>
                            </Column>
                        </Card>
                    </Column>
                    <Column className='gap-1.5'>
                        <Text className='font-manrope font-semibold'>
                            Saran untuk materi / content / mata kuliah yang perlu ditambahkan untuk meningkatkan daya saing lulusan UTY di dunia bisnis/kerja atau usulan lain yang diperlukan demi perbaikan dan penyempurnaan penyelenggaraan pendidikan pada program studi di UTY?
                        </Text>
                        <OutlineTextArea
                            id='saran'
                            inputWidth='w-full'
                            className='rounded-md'
                            placeholder='Berikan saran berupa uraian'
                            onChange={onChangeTextArea}
                            maxLength={150}
                            initialRow={5}
                            maxRows={6}
                            defaultValue={saran} />
                    </Column>
                </Column>
            </Column>
        </Card>
    )
}

export default WAlmKueTiga
