import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { TbSend } from 'react-icons/tb';
import { Form } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { saveCommentRecognitionController } from '../../controllers/alumni_controllers';
import { ButtonHover, Caption, Column, LoadingView, OutlineTextArea, PrimaryButton, Title } from '../../shared/ui';
import { refreshTokenKey, toastDuration } from '../../shared/variable';

function WCommentDetailrecognition({ id }) {

  const cookies = new Cookies()

  const commentRef = useRef()

  const tokenCookies = cookies.get(refreshTokenKey) ?? null

  const [loading, setLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const saveComment = async (event) => {
    event.preventDefault();
    const comment = event.target.comment.value ?? null
    if (comment !== null && tokenCookies !== null) {
      setLoading(true)
      const props = {
        id: id,
        comment: comment,
      }
      const result = await saveCommentRecognitionController(props)
      if (result.status) {
        setTimeout(() => {
          commentRef.current.value = ''
          toast.success(result.message)
          setLoading(false)
        }, 1000)
      } else {
        setTimeout(() => {
          toast.error(result.message)
          setLoading(false)
        }, 1000)
      }
    } else {
      if (!showToast) {
        setShowToast(true)
        toast('Silakan masuk ke Akun Anda!')
        setTimeout(() => {
          setShowToast(false)
        }, (toastDuration + 500))
      }
    }
  }

  return (
    <Column>
      <Form onSubmit={saveComment}>
        <Column className={'gap-y-3'}>
          <Title>
            Berikan Komentar
          </Title>
          <Column className={'gap-y-2'}>
            <OutlineTextArea
              id='comment'
              refs={commentRef}
              className={'py-4'}
              inputWidth={'w-full'}
              bgColor={'bg-white'}
              initialRow={5}
              placeholder='Tulis komentar'
              disabled={tokenCookies === null}
              required />
            <Caption>
              Setiap komentar yang Anda berikan akan melalui proses Verifikasi untuk memenuhi Ketentuan dan Kebijakan Privasi yang berlaku.
            </Caption>
          </Column>
          <ButtonHover className={'flex-shrink-0 mt-2'}>
            <PrimaryButton
              pills
              type='submit'
              className={'pl-5 pr-6'}
              color='indigo'
              disabled={tokenCookies === null || loading}
              leadingIcon={loading ? <LoadingView /> : <TbSend className={'h-5 w-5 rotate-12 my-auto'} />}>
              {loading ? 'Mengirim...' : 'Kirim'}
            </PrimaryButton>
          </ButtonHover>
        </Column>
      </Form>
    </Column>
  )
}

export default WCommentDetailrecognition