import { TbCertificate, TbStack2 } from "react-icons/tb";
import {
  ButtonHover,
  Caption,
  Column,
  Headline3,
  HorizontalDivider,
  Row,
  TertiaryButton,
  Text,
  Title,
} from "../../../shared/ui";
import { useEffect, useState } from "react";
import { checkUserFilledTracer } from "../../../controllers/apicontrollers";
import { getCekExitSurveyController } from "../../../controllers/alumni_controllers";
import { getCurrentUser } from "../../../shared/user";
import { useNavigate } from "react-router-dom";
import { almTracerRoute, mhsExitRoute } from "../../../shared/variable";

export default function AlmCertificateProfilePage() {
  // inisialisasi state
  const navigateTo = useNavigate();
  const { prodi, exit_survey } = getCurrentUser();
  const [exitSurvey, setExitSurvey] = useState(null);
  const [tracerStudy, setTracerStudy] = useState(null);
  const [akreditasiData, setAkreditasiData] = useState([]);
  const [sertificateAvailable, setSertificateAvailable] = useState(null);
  const dateAvailable = exitSurvey?.data?.date_input;

  //  get data sudah mengisi exit survey apa belum
  const getExitSurveyData = async () => {
    const result = await getCekExitSurveyController();
    setExitSurvey(result);
  };

  //   get data sertifikasi akreditasi
  const getAkreditasiData = async () => {
    const response = await checkUserFilledTracer();
    setAkreditasiData(response.sertifikat);
    setTracerStudy(response.data);
  };

  useEffect(() => {
    // render data cek sudah mengisi exit survei
    getExitSurveyData();

    // render data sertifikat akreditasi
    getAkreditasiData();

    // Render data sertifikasi jika kurang dari 6 bulan dari input survey
    if (dateAvailable) {
      const sixMonth = new Date();
      sixMonth.setMonth(sixMonth.getMonth() - 6);

      const fillDate = new Date(dateAvailable);
      if (fillDate >= sixMonth) {
        setSertificateAvailable(true);
      }
    }
  }, [dateAvailable]);

  return (
    <Column className="w-full max-w-4xl mx-auto gap-y-5 sm:gap-y-8 sm:px-14">
      {exit_survey && exitSurvey?.data?.date_input !== null ? (
        <Column
          className={
            "p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x"
          }
        >
          <Column className={"gap-y-2"}>
            <Title className={"font-semibold"}>Sertifikat Akreditasi</Title>
          </Column>
          <Column
            className={`max-full overflow-y-auto scrollbar-hide scroll-smooth gap-y-8 h-fit`}
          >
            <Column className="gap-y-6 ">
              <Column className="divide-y-[1px] divide-gray-200 mt-4">
                <Caption className="px-8 pb-3">Akreditasi Institusi</Caption>
                <Column>
                  {akreditasiData.map((sertifikat, idx) => {
                    return (
                      <Column>
                        <Row key={idx} className="gap-x-4 px-8 py-3">
                          <Column>
                            <Text className="font-semibold font-manrope">
                              {sertifikat.tahun == "2024"
                                ? "Surat Keterangan Akreditasi Universitas"
                                : "Universitas Teknologi Yogyakarta"}
                            </Text>
                            <Caption>
                              Akreditasi Tahun {sertifikat.tahun}
                            </Caption>
                          </Column>
                          <ButtonHover className="my-auto">
                            <a
                              href={sertifikat.name}
                              target="_blank"
                              download
                              className="tertiary-btn py-2 hover:border-indigo-500"
                            >
                              Unduh
                            </a>
                          </ButtonHover>
                        </Row>
                        {idx < akreditasiData.length - 1 && (
                          <HorizontalDivider className="my-0 ml-8" />
                        )}
                      </Column>
                    );
                  })}
                </Column>
              </Column>
              <Column className="divide-y-[1px] divide-gray-200">
                <Caption className="px-8 pb-3">
                  Akreditasi Program Studi
                </Caption>
                <Column className="mb-5">
                  {akreditasiData.map((sertifikat, idx) => {
                    return (
                      <Column>
                        <Row key={idx} className="gap-x-4 px-8 py-3">
                          <Column>
                            <Text className="font-semibold font-manrope">
                              {prodi}
                            </Text>
                            <Caption>
                              Akreditasi Tahun {sertifikat.tahun}
                            </Caption>
                          </Column>
                          <ButtonHover className="my-auto">
                            <a
                              href={sertifikat.name}
                              target="_blank"
                              download
                              className="tertiary-btn py-2 hover:border-indigo-500"
                            >
                              Unduh
                            </a>
                          </ButtonHover>
                        </Row>
                        {idx < akreditasiData.length - 1 && (
                          <HorizontalDivider className="my-0 ml-8" />
                        )}
                      </Column>
                    );
                  })}
                </Column>
              </Column>
            </Column>
          </Column>
        </Column>
      ) : tracerStudy ? (
        <Column
          className={
            "p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x"
          }
        >
          <Column className={"gap-y-2"}>
            <Title className={"font-semibold"}>Sertifikat Akreditasi</Title>
          </Column>
          <Column
            className={`max-full overflow-y-auto scrollbar-hide scroll-smooth gap-y-8 h-fit`}
          >
            <Column className="gap-y-6 ">
              <Column className="divide-y-[1px] divide-gray-200 mt-4">
                <Caption className="px-8 pb-3">Akreditasi Institusi</Caption>
                <Column>
                  {akreditasiData.map((sertifikat, idx) => {
                    return (
                      <Column>
                        <Row key={idx} className="gap-x-4 px-8 py-3">
                          <Column>
                            <Text className="font-semibold font-manrope">
                              {sertifikat.tahun == "2024"
                                ? "Surat Keterangan Akreditasi Universitas"
                                : "Universitas Teknologi Yogyakarta"}
                            </Text>
                            <Caption>
                              Akreditasi Tahun {sertifikat.tahun}
                            </Caption>
                          </Column>
                          <ButtonHover className="my-auto">
                            <a
                              href={sertifikat.name}
                              target="_blank"
                              download
                              className="tertiary-btn py-2 hover:border-indigo-500"
                            >
                              Unduh
                            </a>
                          </ButtonHover>
                        </Row>
                        {idx < akreditasiData.length - 1 && (
                          <HorizontalDivider className="my-0 ml-8" />
                        )}
                      </Column>
                    );
                  })}
                </Column>
              </Column>
              <Column className="divide-y-[1px] divide-gray-200">
                <Caption className="px-8 pb-3">
                  Akreditasi Program Studi
                </Caption>
                <Column className="mb-5">
                  {akreditasiData.map((sertifikat, idx) => {
                    return (
                      <Column>
                        <Row key={idx} className="gap-x-4 px-8 py-3">
                          <Column>
                            <Text className="font-semibold font-manrope">
                              {prodi}
                            </Text>
                            <Caption>
                              Akreditasi Tahun {sertifikat.tahun}
                            </Caption>
                          </Column>
                          <ButtonHover className="my-auto">
                            <a
                              href={sertifikat.name}
                              target="_blank"
                              download
                              className="tertiary-btn py-2 hover:border-indigo-500"
                            >
                              Unduh
                            </a>
                          </ButtonHover>
                        </Row>
                        {idx < akreditasiData.length - 1 && (
                          <HorizontalDivider className="my-0 ml-8" />
                        )}
                      </Column>
                    );
                  })}
                </Column>
              </Column>
            </Column>
          </Column>
        </Column>
      ) : (
        <Column
          className={
            "items-center gap-y-6 px-4 py-16 bg-white border-y lg:border-x lg:border-gray-200"
          }
        >
          <div className={"p-4 bg-gray-50 rounded-full"}>
            <TbCertificate className={"h-20 w-20 text-gray-300 stroke-1.5"} />
          </div>
          <Column className={"items-center gap-y-2"}>
            <Headline3 className={"text-gray-800"}>
              Sertifikat Akreditasi
            </Headline3>
            <Text className={"text-center text-gray-500"}>
              {`Saat ini belum terdapat sertifikat akreditasi silahkan isi ${
                exit_survey
                  ? "exit survey"
                  : tracerStudy === false
                  ? "tracer study"
                  : ""
              }.`}
            </Text>
          </Column>
          <ButtonHover>
            <TertiaryButton
              pills
              color="indigo"
              className={"pl-5 pr-6"}
              leadingIcon={<TbStack2 className={"h-5 w-5 my-auto"} />}
              onClick={() =>
                navigateTo(
                  exit_survey
                    ? mhsExitRoute
                    : tracerStudy === false
                    ? almTracerRoute
                    : null
                )
              }
            >
              {exit_survey && exitSurvey?.data?.date_input !== null
                ? "Isi Exit Survey"
                : "Isi Tracer Study"}
            </TertiaryButton>
          </ButtonHover>
        </Column>
      )}
    </Column>
  );
}
