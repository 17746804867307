import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import {
  searchKonselor,
  saveNewKonselor,
} from "../../../../controllers/apicontrollers";
import RowTableCounselorConsultation from "./rows/row_counselor_consultation";
import {
  AlertDialog,
  Caption,
  Card,
  Column,
  GridCol,
  Headline3,
  IconButton,
  LoadingDialog,
  OutlineInput,
  Row,
  Text,
  TextButton,
} from "../../../../shared/ui";
import { TbMoodCry, TbUserCircle, TbX } from "react-icons/tb";
import CAdmKonselor from "../../../../components/cards/cadm_konselor";
import toast from "react-hot-toast";

function TableCounselorConsultation({ listCounselor, page, limit, onShort }) {
  const [listKonselor, setListKonselor] = useState([]);
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [listSelected, setListSelected] = useState([]);
  const [listSearch, setListSearch] = useState([]);
  const [loading, setLoading] = useState(false);

  const onHandleClick = (mitra) => {
    setShowDialog(false);
    setListKonselor(mitra);
    console.log(mitra);
  };

  const chooseKonselor = (konselor) => {
    const currentSelected = [...listSelected];
    const searchKonselor = currentSelected.filter(
      (selected) => selected.nik === konselor.nik
    );
    if (searchKonselor.length > 0) {
      const newSelected = currentSelected.filter(
        (selected) => selected.nik !== konselor.nik
      );
      setListSelected(newSelected);
    } else {
      currentSelected.unshift(konselor);
      setListSelected(currentSelected);
    }
  };

  const onChangeInput = async (event) => {
    event.preventDefault();
    const query = event.target.value;
    getSearchKonselor(query);
  };

  const getSearchKonselor = async (query) => {
    const result = await searchKonselor(query);
    if (result.status) {
      setListSearch(result.data);
    }
  };

  const saveKonselor = async () => {
    setShowDialog(false);
    setLoading(true);
    const listNik = [];
    if (listSelected.length > 0) {
      listSelected.map((selected) => {
        listNik.push(selected.nik);
      });
    }
    const result = await saveNewKonselor(listNik);
    if (result.status) {
      setTimeout(() => {
        setLoading(false);
        toast(result.message);
      }, 200);
    } else {
      setTimeout(() => {
        setLoading(false);
        toast(result.message);
      }, 200);
    }
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>NIK</TableHeadCell>
            <TableHeadCell>Nama</TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            {/* <TableHeadCell className={"text-center"}>Aksi</TableHeadCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {listCounselor.length > 0 ? (
            listCounselor.map((counselor, idx) => {
              return (
                <RowTableCounselorConsultation
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  counselor={counselor}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog
        className="max-w-sm"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <Column className="divide-y divide-gray-200">
          <Column className="px-5 pt-10 pb-8 gap-8 items-center">
            <Card className="p-1.5 rounded-2xl bg-indigo-50/50 border-indigo-200/50">
              <TbUserCircle className="h-14 w-14 stroke-1.5 text-indigo-600" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Konselor Baru</Headline3>
              <Text className="text-center">
                Tambahkan konselor baru untuk mengisi sesi konsultasi bersama
                alumni
              </Text>
            </Column>
          </Column>
          {listSelected.length > 0 && (
            <Column className="pt-3 pb-5 gap-1.5">
              <Caption className="px-5 text-gray-800 font-medium">
                {listSelected.length} Konselor terpilih:
              </Caption>
              <Row className="gap-2 overflow-x-auto scrollbar-hide px-5">
                {listSelected.map((selected, idx) => {
                  return (
                    <Row className="w-fit gap-2 shrink-0 bg-gray-100/50 border border-gray-200 pl-2.5 pr-2 py-1.5 rounded-lg">
                      <Caption key={idx} className="shrink-0 text-gray-800">
                        {selected.nama}
                      </Caption>
                      <IconButton
                        onClick={() => chooseKonselor(selected)}
                        className="p-0.5 my-auto"
                        color="red"
                      >
                        <TbX className="h-3 w-3" />
                      </IconButton>
                    </Row>
                  );
                })}
              </Row>
            </Column>
          )}
          <Column className="px-5 py-6 gap-4">
            <OutlineInput
              id="query"
              inputWidth="w-full"
              placeholder="Cari nik atau nama"
              onChange={onChangeInput}
            />
            {listSearch.length > 0 ? (
              <Card className="w-full p-0 overflow-hidden">
                <Column className="max-h-72 divide-y overflow-y-auto scrollbar-hide">
                  {listSearch.map((konselor, idx) => {
                    return (
                      <CAdmKonselor
                        key={idx}
                        konselor={konselor}
                        listSelected={listSelected}
                        onClick={() => chooseKonselor(konselor)}
                      />
                    );
                  })}
                </Column>
              </Card>
            ) : (
              <Column className="items-center gap-2">
                <TbMoodCry className="h-12 w-12 stroke-1.5 text-gray-500" />
                <Caption className="text-center">
                  Hasil pencarian tidak ditemukan
                </Caption>
              </Column>
            )}
          </Column>
          <GridCol className="gap-0 divide-x divide-gray-200">
            <TextButton
              onClick={() => {
                setShowDialog(false);
              }}
              className="w-full py-4 text-base hover:bg-gray-50/50"
            >
              Batal
            </TextButton>
            <TextButton
              disabled={listSelected.length > 0 ? false : true}
              onClick={saveKonselor}
              color="indigo"
              className="w-full py-4 text-base hover:bg-indigo-50/50"
            >
              Simpan
            </TextButton>
          </GridCol>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message="Menyimpan konselor..." />
    </>
  );
}

export default TableCounselorConsultation;
