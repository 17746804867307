import { useEffect, useState } from 'react'
import { TbMessageCheck } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import ListConsultation from '../../components/list/list_consultation'
import { getConsultationController } from '../../controllers/konselor_controllers'
import { Column, Headline3, Text } from '../../shared/ui'

function KonCompleteConsultationPage() {

  const result = useLoaderData()

  const [listConsultation, setListConsultation] = useState([])

  const getConsultationFromLoader = () => {
    if (result.status) {
      setListConsultation(result.data)
    }
  }

  useEffect(() => {
    getConsultationFromLoader()
  }, [])

  return (
    <Column className={'w-full max-w-4xl mx-auto py-10 gap-y-5 sm:gap-y-8 sm:px-14'}>
      {
        listConsultation.length > 0 ?
          <ListConsultation
            action={20}
            consultation={listConsultation} /> :
          <Column className={'items-center gap-y-6 px-4 py-16 bg-white border-y lg:border-x lg:border-gray-200'}>
            <div className={'p-4 bg-gray-50 rounded-full'}>
              <TbMessageCheck className={'h-20 w-20 text-gray-300 stroke-1.5'} />
            </div>
            <Column className={'items-center gap-y-2'}>
              <Headline3 className={'text-gray-800'}>
                Konsultasi Selesai
              </Headline3>
              <Text className={'text-center text-gray-500'}>
                Saat ini belum terdapat sesi konsultasi yang sudah berakhir
              </Text>
            </Column>
          </Column>
      }
    </Column>
  )
}

export default KonCompleteConsultationPage

export const getKonCompleteConsultation = async () => {
  const result = await getConsultationController(30)
  return result;
}