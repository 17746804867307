import { axios_delete, axios_get, axios_post } from "../services/apiservice";
import { admB2BApiUrl, admLegalitasB2BApiUrl } from "../shared/apiadmin";
import {
  admCommentsRecognitionApiUrl,
  admConsultationNewApiUrl,
  admCounselorConsultationNewApiUrl,
  admEventApiUrl,
  admExportExitSurveyApiUrl,
  admExportJenisPerusahaanExitSurveyApiUrl,
  admExportMetodeCariKerjaExitSurveyApiUrl,
  admExportPosisiWirausahaExitSurveyApiUrl,
  admExportProsesCariKerjaExitSurveyApiUrl,
  admExportStatusLulusanExitSurveyApiUrl,
  admExportTingkatPerusahaanExitSurveyApiUrl,
  admFilterPenggunaLulusan,
  admGetListMitra,
  admGrafikSebaranResponden,
  admInputExitSurveyApiUrl,
  admJenisPerusahaanExitSurveyApiUrl,
  admMasterKonselorApiUrl,
  admMetodeCariKerjaExitSurveyApiUrl,
  admMitraApiUrl,
  admOvGrafikRecognitionApiUrl,
  admOvGrafikTracerApiUrl,
  admOvRekapRecognitionApiUrl,
  admPosisiWirausahaExitSurveyApiUrl,
  admProsesCariKerjaExitSurveyApiUrl,
  admRecognitionApiUrl,
  admRekapKuesTracerApiUrl,
  admRekapPenilaianKepuasan,
  admRekapWisudaTracerApiUrl,
  admSearchKonselorApiUrl,
  admStatusLulusanExitSurveyApiUrl,
  admSurveiPenggunaApiUrl,
  admTingkatPerusahaanExitSurveyApiUrl,
  admTopicConsultationNewApiUrl,
  convertDataCaraCariKerjaApiUrl,
  convertDataFillKuesionerApiUrl,
  convertDataHubunganBidangStudiApiUrl,
  convertDataJabatanWiraswastaApiUrl,
  convertDataJenisPerusahaanApiUrl,
  convertDataKapanCariKerjaApiUrl,
  convertDataKerja6BulanApiUrl,
  convertDataKerjaTidakSesuaiApiUrl,
  convertDataKompetensiDibutuhkanApiUrl,
  convertDataKompetisiDikuasaiApiUrl,
  convertDataNotFillKuesionerApiUrl,
  convertDataPenekananMetodeBelajarApiUrl,
  convertDataProsesCariKerjaApiUrl,
  convertDataStatusLulusanApiUrl,
  convertDataSumberBiayaKuliahApiUrl,
  convertDataTingkatPendidikanKerjaApiUrl,
  convertDataTingkatTempatKerjaApiUrl,
  gFilterConsultationApiUrl,
  grafikDapatKerjaOvTracerApiUrl,
  grafikHubStudiKerjaOvTracerApiUrl,
  grafikJenisPerusahaanOvTracerApiUrl,
  grafikPosisiWiraOvTracerApiUrl,
  grafikRataPendapatanOvTracerApiUrl,
  grafikStatusOvTracerApiUrl,
  grafikSumberBiayaOvTracerApiUrl,
  grafikTingkatPendOvTracerApiUrl,
  grafikTingkatWiraOvTracerApiUrl,
  masterProdiApiUrl,
  rataDapatKerjaOvTracerApiUrl,
  sertifAkreditasiApiUrl,
  tableFillKuesionerTracerApiUrl,
  tableNotFillKuesionerTracerApiUrl,
  tableSaranTracerStudiApiUrl
} from "../shared/variable";

//? GET -- OVERVIEW REKOGNISI
export const getOverviewRecognitionController = async () => {
  const result = await axios_get(admRecognitionApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW GRAFIK REKOGNISI
export const getGrafikOverviewRecognitionController = async () => {
  const result = await axios_get(admOvGrafikRecognitionApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW REKAP REKOGNISI
export const getRekapOverviewRecognitionController = async () => {
  const result = await axios_get(admOvRekapRecognitionApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- REKOGNISI (ACCEPTED, PROCESSED AND REJECTED)
export const getRecognitionController = async ({
  status = "ACCEPTED",
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const body = {
    approve: status,
  };
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_post(
    admRecognitionApiUrl,
    body,
    true,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- KOMENTAR REKOGNISI (PROCESSED AND REJECTED)
export const getCommentsController = async ({
  status = "PROCESSED",
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve: status,
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admCommentsRecognitionApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- EVENT ALUMNI
export const getEventController = async ({
  status = "ACCEPTED",
  search = null,
  page = 1,
  limit = 10,
}) => {
  const header = {};
  const params = {
    approve: status,
    search,
    page,
    limit,
  };
  const result = await axios_get(admEventApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET - LOWONGAN BERDASARKAN MITRA
export const getLokerByMitraController = async ({
  status = "ACCEPTED",
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve: status,
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admMitraApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- MITRA UTY (ACCEPTED, PROCESSED AND REJECTED)
export const getMitraController = async ({
  status = "ACCEPTED",
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve: status,
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admMitraApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- FILTER KONSULTASI
export const getFilterConsultationController = async () => {
  const result = await axios_get(gFilterConsultationApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- KONSULTASI (ACCEPTED, PROCESSED AND REJECTED)
export const getConsultationController = async ({
  status = null,
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve: status,
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admConsultationNewApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? POST -- APPROVE KONSULTASI (ACCEPTED OR REJECTED)
export const approveConsultationController = async ({
  id,
  approve,
  reason,
}) => {
  const body = {
    id_konsultasi: id,
    approve,
    alasan_tolak: reason,
  };
  const result = await axios_post(admConsultationNewApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- DATA DOSEN / MITRA UNTUK PLOTING KONSELOR
export const searchDosenForCounselorController = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    s: search ?? "",
    limit,
  };
  const result = await axios_get(admSearchKonselorApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- PLOTING KONSELOR KONSULTASI
export const getCounselorConsultationController = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(
    admCounselorConsultationNewApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? POST -- SAVE PLOTING KONSELOR KONSULTASI
export const saveCounselorConsultationController = async ({ nik = [] }) => {
  const body = {
    nik,
  };
  const result = await axios_post(admMasterKonselorApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- PLOTING KONSELOR KONSULTASI BERDASARKAN TOPIK KONSULTASI
export const getTopicConsultationController = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admTopicConsultationNewApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? POST -- SIMPAN PLOTING TOPIK KONSULTASI UNTUK KONSELOR
export const saveTopicConsultationController = async ({ topic, nik = [] }) => {
  const body = {
    id_topik: topic,
    nik,
  };
  const result = await axios_post(admTopicConsultationNewApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? DELETE -- HAPUS PLOTING TOPIK KONSULTASI UNTUK KONSELOR
export const deleteTopicConsultationController = async ({ topic, nik }) => {
  const body = {
    id_topik: topic,
    nik,
  };
  const result = await axios_delete({
    url: admTopicConsultationNewApiUrl,
    body: body,
  });
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW GRAFIK PENGISIAN TRACER STUDI
export const getOvGrafikIsiTracerController = async ({ tahun = null }) => {
  const header = {};
  const params = {
    tahun: tahun,
  };
  const result = await axios_get(admOvGrafikTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

// v2
//? GET -- OVERVIEW STATUS ALUMNI
export const getOvGrafikStatusTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(grafikStatusOvTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW GRAFIK MENDAPATKAN KERJA KURANG DARI 6 BULAN
export const getOv6BulanDapatKerjaTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikDapatKerjaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getOvRataPendapatanTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikRataPendapatanOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW RATA-RATA WAKTU ALUMNI MENDAPATKAN KERJA
export const getOvRataDapatKerjaTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(rataDapatKerjaOvTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW GRAFIK HUBUNGAN BIDANG STUDI DENGAN PEKERJAAN
export const getOvHubStudiKerjaTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikHubStudiKerjaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW GRAFIK JENIS PERUSAHAAN ALUMNI BEKERJA
export const getOvJenisPerusahaanTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikJenisPerusahaanOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW POSISI LULUSAN JIKA BERWIRAUSAHA
export const getOvPosisiWiraTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikPosisiWiraOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW TINGKAT WIRAUSAHA LULUSAN
export const getOvTingkatWiraTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikTingkatWiraOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW KESESUAIAN TINGKAT PENDIDIKAN DAN PEKERJAAN
export const getOvTingkatPendTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikTingkatPendOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- OVERVIEW SUMBER BIAYA KULIAH LULUSAN
export const getOvSumberBiayaTracerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikSumberBiayaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- REKAP KUESIONER TRACER STUDI
export const getKuesionerTracerController = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
  start = null,
  end = null,
}) => {
  const header = {};
  const body = {
    date_start: start,
    date_end: end,
  };
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_post(
    admRekapKuesTracerApiUrl,
    body,
    true,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getInputExitSurveyController = async ({
  page = 1,
  limit = 10,
  search = null,
  prodi = null,
  status = null,
  tglwisuda = null,
}) => {
  const header = {};
  const params = {
    page,
    limit,
    search,
    prodi,
    status,
    tglwisuda,
  };
  const result = await axios_get(
    admInputExitSurveyApiUrl,
    header,
    params
  );
  if (result?.meta?.status && result?.meta?.code === 200) {
    return {
      status: true,
      data: result?.data || [],
    }
  }
  return {
    status: false,
    message: result.message,
  };
}

export const getStatusLulusanExitSurveyController = async ({ prodi = null, tglwisuda = null }) => {
  const header = {};
  const params = { prodi, tglwisuda };
  const result = await axios_get(
    admStatusLulusanExitSurveyApiUrl,
    header,
    params
  );
  if (result?.meta?.status && result?.meta?.code === 200) {
    return {
      status: true,
      data: result?.data || [],
    }
  }
  return {
    status: false,
    message: result.message,
  };
}

export const getJenisPerusahaanExitSurveyController = async ({ prodi = null, tglwisuda = null }) => {
  const header = {};
  const params = { prodi, tglwisuda };
  const result = await axios_get(
    admJenisPerusahaanExitSurveyApiUrl,
    header,
    params
  );
  if (result?.meta?.status && result?.meta?.code === 200) {
    return {
      status: true,
      data: result?.data || [],
    }
  }
  return {
    status: false,
    message: result.message,
  };
}

export const getTingkatPerusahaanExitSurveyController = async ({ prodi = null, tglwisuda = null }) => {
  const header = {};
  const params = { prodi, tglwisuda };
  const result = await axios_get(
    admTingkatPerusahaanExitSurveyApiUrl,
    header,
    params
  );
  if (result?.meta?.status && result?.meta?.code === 200) {
    return {
      status: true,
      data: result?.data || [],
    }
  }
  return {
    status: false,
    message: result.message,
  };
}

export const getPosisiWirausahaExitSurveyController = async ({ prodi = null, tglwisuda = null }) => {
  const header = {};
  const params = { prodi, tglwisuda };
  const result = await axios_get(
    admPosisiWirausahaExitSurveyApiUrl,
    header,
    params
  );
  if (result?.meta?.status && result?.meta?.code === 200) {
    return {
      status: true,
      data: result?.data || [],
    }
  }
  return {
    status: false,
    message: result.message,
  };
}

export const getMetodeCariKerjaExitSurveyController = async ({ prodi = null, tglwisuda = null }) => {
  const header = {};
  const params = { prodi, tglwisuda };
  const result = await axios_get(
    admMetodeCariKerjaExitSurveyApiUrl,
    header,
    params
  );
  if (result?.meta?.status && result?.meta?.code === 200) {
    return {
      status: true,
      data: result?.data || [],
    }
  }
  return {
    status: false,
    message: result.message,
  };
}

export const getProsesCariKerjaExitSurveyController = async ({ prodi = null, tglwisuda = null }) => {
  const header = {};
  const params = { prodi, tglwisuda };
  const result = await axios_get(
    admProsesCariKerjaExitSurveyApiUrl,
    header,
    params
  );
  if (result?.meta?.status && result?.meta?.code === 200) {
    return {
      status: true,
      data: result?.data || [],
    }
  }
  return {
    status: false,
    message: result.message,
  };
}

export const getExportExitSurveyController = async ({
  prodi = null,
  status = null,
  tglwisuda = null,
}) => {
  const header = {};
  const params = {
    prodi,
    status,
    tglwisuda,
  };
  let responseType = "arraybuffer";
  const result = await axios_get(
    admExportExitSurveyApiUrl,
    header,
    params,
    responseType
  );
  return result;
}

export const getExportStatusLulusanExitSurveyController = async ({
  prodi = null,
  tglwisuda = null,
}) => {
  const header = {};
  const params = {
    prodi,
    tglwisuda,
  };
  let responseType = "arraybuffer";
  const result = await axios_get(
    admExportStatusLulusanExitSurveyApiUrl,
    header,
    params,
    responseType
  );
  return result;
}

export const getExportJenisPerusahaanExitSurveyController = async ({
  prodi = null,
  tglwisuda = null,
}) => {
  const header = {};
  const params = {
    prodi,
    tglwisuda,
  };
  let responseType = "arraybuffer";
  const result = await axios_get(
    admExportJenisPerusahaanExitSurveyApiUrl,
    header,
    params,
    responseType
  );
  return result;
}

export const getExportTingkatPerusahaanExitSurveyController = async ({
  prodi = null,
  tglwisuda = null,
}) => {
  const header = {};
  const params = {
    prodi,
    tglwisuda,
  };
  let responseType = "arraybuffer";
  const result = await axios_get(
    admExportTingkatPerusahaanExitSurveyApiUrl,
    header,
    params,
    responseType
  );
  return result;
}

export const getExportPosisiWirausahaExitSurveyController = async ({
  prodi = null,
  tglwisuda = null,
}) => {
  const header = {};
  const params = {
    prodi,
    tglwisuda,
  };
  let responseType = "arraybuffer";
  const result = await axios_get(
    admExportPosisiWirausahaExitSurveyApiUrl,
    header,
    params,
    responseType
  );
  return result;
}

export const getExportMetodeCariKerjaExitSurveyController = async ({
  prodi = null,
  tglwisuda = null,
}) => {
  const header = {};
  const params = {
    prodi,
    tglwisuda,
  };
  let responseType = "arraybuffer";
  const result = await axios_get(
    admExportMetodeCariKerjaExitSurveyApiUrl,
    header,
    params,
    responseType
  );
  return result;
}

export const getExportProsesCariKerjaExitSurveyController = async ({
  prodi = null,
  tglwisuda = null,
}) => {
  const header = {};
  const params = {
    prodi,
    tglwisuda,
  };
  let responseType = "arraybuffer";
  const result = await axios_get(
    admExportProsesCariKerjaExitSurveyApiUrl,
    header,
    params,
    responseType
  );
  return result;
}

export const getFillKuesionerTracerController = async ({
  page = 1,
  limit = 10,
  date_start = null,
  date_end = null,
  prodi = null,
  sorting = null,
  order = null,
}) => {
  const header = {};
  const params = {
    page,
    limit,
    date_start,
    date_end,
    prodi,
    sorting: sorting ? `${sorting.type}` : null,
    order: order ? `${order.key}` : null,
  };
  const result = await axios_get(
    tableFillKuesionerTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getNotFillKuesionerTracerController = async ({
  page = 1,
  limit = 10,
  date_start = null,
  date_end = null,
  prodi = null,
  sorting = null,
  order = null,
}) => {
  const header = {};
  const params = {
    page,
    limit,
    date_start,
    date_end,
    prodi,
    sorting: sorting ? `${sorting.type}` : null,
    order: order ? `${order.key}` : null,
  };
  const result = await axios_get(
    tableNotFillKuesionerTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- REKAP WISUDA TRACER STUDI
export const getWisudaTracerController = async ({
  year = null,
  month = null,
}) => {
  const body = {
    tahun: year,
    bulan: month,
  };
  const result = await axios_post(admRekapWisudaTracerApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- REKAP SARAN TRACER STUDI
export const getSaranTracerController = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
    tahun,
    prodi,
  };
  const result = await axios_get(tableSaranTracerStudiApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- REKAP SURVEI PENGGUNA LULUSAN
export const getSurveiPenggunaController = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
  date_start = null,
  date_end = null,
  mhs_prodi = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
    date_start,
    date_end,
    mhs_prodi,
  };
  const result = await axios_get(admSurveiPenggunaApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- POSTINGAN ALUMNI B2B
export const getB2BController = async ({
  status = "ACCEPTED",
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve: status,
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admB2BApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? POST -- APPROVE POSTINGAN ALUMNI B2B
export const approveB2BController = async ({ id, approve }) => {
  const body = {
    approve: approve,
  };
  const result = await axios_post(`${admB2BApiUrl}/${id}`, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- LEGALITAS USAHA B2B
export const getLegalitasB2BController = async ({
  status = "ACCEPTED",
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve: status,
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admLegalitasB2BApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? POST -- APPROVE LEGALITAS USAHA B2B
export const approveLegalitasB2BController = async ({ id, approve }) => {
  const body = {
    approve: approve,
  };
  const result = await axios_post(`${admLegalitasB2BApiUrl}/${id}`, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET -- SERTIFIKAT AKREDITASI
export const getCertificateController = async ({
  action = 10,
  search = null,
  page = 1,
  limit = 10,
}) => {
  const header = {};
  const params = {
    page,
    limit,
    search,
  };
  const result = await axios_get(
    action === 10 ? sertifAkreditasiApiUrl : `${sertifAkreditasiApiUrl}/univ`,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? POST -- SIMPAN SERTIFIKAT AKREDITASI
export const saveCertificateController = async ({
  action,
  prodi,
  tahun,
  file,
}) => {
  const bodyFromData = new FormData();
  bodyFromData.append("kode_prodi", prodi);
  bodyFromData.append("tahun", tahun);
  bodyFromData.append("file", file);
  const result = await axios_post(
    action === 10 ? sertifAkreditasiApiUrl : `${sertifAkreditasiApiUrl}/univ`,
    bodyFromData,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? DELETE - HAPUS SERTIFIKAT AKREDITASI
export const deleteCertificateController = async ({ action, prodi, tahun }) => {
  const result = await axios_delete({
    url:
      action === 10
        ? `${sertifAkreditasiApiUrl}/${prodi}/${tahun}`
        : `${sertifAkreditasiApiUrl}/${tahun}`,
  });
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getMasterProdiController = async () => {
  const result = await axios_get(masterProdiApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getFilterPenggunaLulusanController = async () => {
  const result = await axios_get(admFilterPenggunaLulusan);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    messages: result.message,
  };
};

export const getAdmSebaranRespondenController = async ({ tahun = null }) => {
  const header = {};
  const params = { tahun: tahun };

  const result = await axios_get(admGrafikSebaranResponden, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getAdmRekapPenilaianRespondenController = async ({
  tahun = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
  };

  const result = await axios_get(admRekapPenilaianKepuasan, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getListMitraController = async () => {
  const result = await axios_get(admGetListMitra);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

// Download excel isi
export const getExcelDataIsiKuesionerController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataFillKuesionerApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excelll
export const getExcelDataNotFillKuesionerController = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start,
    date_end,
    prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataNotFillKuesionerApiUrl,
    header,
    params,
    responseType
  );

  return result;
};

// Download lulusan saat ini
export const getExcelDataLulusanController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataStatusLulusanApiUrl,
    header,
    params,
    responseType
  );

  return result;
};

// Download kerja 6 bulan
export const getExcelDataKerja6BulanController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataKerja6BulanApiUrl,
    header,
    params,
    responseType
  );

  return result;
};

// Download hubungan bidang studi
export const getExcelDataHubunganBidangStudiController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataHubunganBidangStudiApiUrl,
    header,
    params,
    responseType
  );

  return result;
};

// Download excel jenis perusahaan
export const getExcelDataJenisPerusahaanController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataJenisPerusahaanApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel jabatan wiraswasta
export const getExcelDataJabatanWiraswastaController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataJabatanWiraswastaApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel tingkat tempat kerja
export const getExcelDataTingkatTempatKerjaController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataTingkatTempatKerjaApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel tingkat pendidikan kerja
export const getExcelDataTingkatPendidikanKerja = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataTingkatPendidikanKerjaApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel sumber biaya kuliah
export const getExcelDataSumberBiayaKuliah = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";
  const result = await axios_get(
    convertDataSumberBiayaKuliahApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel kompetensi yang dikuasia
export const getExcelDataKompetensiDikuasaiController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataKompetisiDikuasaiApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel kompetensi yang dibutuhkan
export const getExcelDataKompetensiDibutuhkanController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataKompetensiDibutuhkanApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel penekanan metode belajar
export const getExcelDataPenekananMetodeBelajarController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataPenekananMetodeBelajarApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel kapan cari kerja
export const getExcelDataKapanCariKerjaController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataKapanCariKerjaApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel cara cari kerja
export const getExcelDataCaraCariKerjaController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataCaraCariKerjaApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel proses cari kerja
export const getExcelDataProsesCariKerjaController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataProsesCariKerjaApiUrl,
    header,
    params,
    responseType
  );
  return result;
};

// Download excel kerja tidak sesuai
export const getExcelDataKerjaTidakSesuaiController = async ({
  date_start,
  date_end,
  prodi,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  let responseType = "arraybuffer";

  const result = await axios_get(
    convertDataKerjaTidakSesuaiApiUrl,
    header,
    params,
    responseType
  );
  return result;
};
