import React from 'react'
import ItemChat from './item/item_chat'

function ListChatConsultation({ action, chats, konName, almName, onReply, onDelete }) {
  return (
    chats.map((chat, idx) => {
      let replyChat = null
      if (chat.replay_to !== null) {
        replyChat = chats.find((find) => {
          return find.id_chat_konsultasi === chat.replay_to;
        })
      }
      return (
        <ItemChat
          key={idx}
          action={action}
          chat={chat}
          konName={konName}
          almName={almName}
          replyChat={replyChat}
          onReply={onReply}
          onDelete={onDelete} />
      )
    })
  )
}

export default ListChatConsultation