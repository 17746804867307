import React, { useEffect, useState } from 'react'
import CHistory from './cards/chistory'
import { getHistoryConsultation } from '../controllers/apicontrollers'
import { Caption, Column, Subtitle, Title } from '../shared/ui'
import { TbMessageCircle } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { aApproveConsultation, aProcessedConsultation, aRejectedConsultation } from '../atoms'

const WAlmHistoryConsultation = ({ action, currentMessage, onSelectedMessage, updateMessage = null, reqUpdate }) => {

    const [listHistory, setListHistory] = useRecoilState(action === 10 ? aApproveConsultation : action === 20 ? aProcessedConsultation : aRejectedConsultation)

    const getAllHistory = async () => {
        const result = await getHistoryConsultation(action)
        if (result.status) {
            setListHistory(result.data)
        }
    }

    const onClickMessage = (message) => {
        onSelectedMessage(message)
    }

    useEffect(() => {
        getAllHistory()
    }, [reqUpdate])

    useEffect(() => {
        if (updateMessage !== null) {
            const newState = listHistory.map(history => {
                if (history.id_konsultasi === updateMessage.id) {
                    return { ...history, last_chat: updateMessage.message, last_chat_time: updateMessage.date };
                }
                return history;
            })
            setListHistory(newState)
        }
    }, [updateMessage])

    return listHistory.length > 0 ? (
        <Column className='pb-24 overflow-y-auto scrollbar-hide scroll-smooth'>
            {listHistory.map((history, idx) => {
                return (
                    <CHistory action={10} key={idx} history={history} currentId={currentMessage.id_konsultasi} onClick={onClickMessage} />
                )
            })}
        </Column>
    ) : (
        <Column className='items-center pt-32 pb-24 gap-y-4'>
            <TbMessageCircle className='h-24 w-24 stroke-1.2 text-gray-300' />
            <Column className='items-center gap-y-1'>
                <Title className='font-semibold'>
                    Tidak ada Riwayat Konsultasi
                </Title>
                <Caption className='text-center px-8'>
                    Mulai Konsultasi pertama Anda dengan klik tombol <span className='text-xl text-indigo-600'>+</span>
                </Caption>
            </Column>
        </Column>
    )
}

export default WAlmHistoryConsultation
