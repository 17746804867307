import { useState, useEffect, useCallback } from "react";

export const useCountDown = ({
  initialTime = 0,
  text = "Default Text",
  storageKey,
}) => {
  const [timeLeft, setTimeLeft] = useState(() => {
    const storedTime = localStorage.getItem(storageKey);
    return storedTime ? Number(storedTime) : initialTime;
  });

  useEffect(() => {
    if (timeLeft <= 0) {
      localStorage.removeItem(storageKey);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTime = prevTime - 1;
        if (newTime <= 0) {
          clearInterval(intervalId);
          localStorage.removeItem(storageKey);
        } else {
          localStorage.setItem(storageKey, newTime);
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, storageKey]);

  useEffect(() => {
    if (initialTime > 0 && !localStorage.getItem(storageKey)) {
      setTimeLeft(initialTime);
      localStorage.setItem(storageKey, initialTime);
    }
  }, [initialTime, storageKey]);

  const formatTime = useCallback(() => {
    if (timeLeft <= 0) return text;

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `Remaining Time ${minutes}m:${seconds < 10 ? "0" : ""}${seconds}s`;
  }, [timeLeft, text]);

  return { timeLeft, formatTime };
};

export default useCountDown;
