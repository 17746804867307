import React, { useState } from "react";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { FullScreen, ToasterView } from "../shared/ui";
import MitAppBar from "../components/appbar/mit_appbar";
import MitSidebar from "../components/sidebar/mit_sidebar";
import randomstring from "randomstring";

TopBarProgress.config({
  barColors: {
    0: "#B71C1C",
    0.6: "#D50000",
    "1.0": "#E53935",
  },
  barThickness: "3.0",
  shadowColor: "#ffff",
  shadowBlur: "0",
});

const MitraLayout = () => {
  const { state } = useNavigation();
  const [showOnMobile, setShowOnMobile] = useState("0");

  const onShowSidebar = () => {
    setShowOnMobile(randomstring.generate(10));
  };

  return (
    <>
      {state === "loading" && <TopBarProgress />}
      <MitAppBar onShowSidebar={onShowSidebar} />
      <MitSidebar showOnMobile={showOnMobile} />
      <FullScreen className={"relative py-14 xl:pl-72"}>
        <ScrollRestoration
          getKey={(location) => {
            return location.pathname;
          }}
        />
        <Outlet />
      </FullScreen>
      <ToasterView />
    </>
  );
};

export default MitraLayout;
