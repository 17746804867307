import React from "react";
import WListNotification from "../../../components/wlist_notification";
import { useLoaderData } from "react-router-dom";
import { useState } from "react";
import { getNotificationsController, readNotificationsControllers } from "../../../controllers/global_controllers";
import { useEffect } from "react";


function ProdNotificationPage() {

  const result = useLoaderData();

  const[readNotif, setReadNotif]= useState([])
  const[unreadNotif, setUnreadNotif] = useState([])

  const getNotifFromLoader = () =>{
    if(result.status){
        const listNotifications = [...result.data]
        const unread = listNotifications.filter(
          (notif)=> notif.is_read===false
        )
        const read = listNotifications.filter((notif)=> notif.is_read===true)
        setUnreadNotif(unread)
        setReadNotif(read)
    }
  }

  const onReadNotif = async(action, notif)=>{
    const result = await readNotificationsControllers(notif.id_notifikasi);
    if(result.status){
      if(action===10){
        const readTemp = [...readNotif, notif]
        const unreadTemp = unreadNotif.filter(
          (unread) => unread.id_notifikasi !== notif.id_notifikasi
        )
        const sortReadTemp = readTemp.sort((a,b)=> b.id_notifikasi - a.id_notifikasi)
        setReadNotif(sortReadTemp)
        setUnreadNotif(unreadTemp)
      }

    }
  }
  
  useEffect(()=>{
    getNotifFromLoader();
  },[])

  return (
    <div className="space-y-6 mt-6">
     <WListNotification
        action={10}
        listNotification={unreadNotif}
        onReadNotification={onReadNotif}
      />
      <WListNotification
        action={20}
        listNotification={readNotif}
        onReadNotification={onReadNotif}
      />
    </div>
  );
}

export default ProdNotificationPage;
export const getProdNotifications = async () => {
  const result = await getNotificationsController();
  return result;
};

