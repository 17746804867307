import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbFolderOpen, TbPlus, TbSend, TbX } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import { Column, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { SearchInput, SelectInput } from '../../../components/interfaces/input'
import { BodyText, Headline2Text, Headline3Text, LabelText } from '../../../components/interfaces/text'
import { Pagination, PaginationOption, useDebounce } from '../../../components/interfaces/utility'
import { getCertificateController, getMasterProdiController, saveCertificateController } from '../../../controllers/admin_controllers'
import { setupTahun } from '../../../shared/ta'
import { AlertDialog, ButtonHover, LoadingDialog, PrimaryButton, TertiaryButton } from '../../../shared/ui'
import TableProdiCertificate from './tables/table_prodi_certificate'

function AdmProdiCertificatePage() {

  const result = useLoaderData()

  const [listCertificate, setListCertificate] = useState([])
  const [limitRow, setLimitRow] = useState(10)
  const [totalRow, setTotalRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [currentSort, setCurrentSort] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [stsGetLoader, setStsGetLoader] = useState(false)
  const [fileChoosed, setFileChosed] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [loading, setLoading] = useState(false)

  const [initProdi, setInitProdi] = useState({ label: 'Pilih program studi', value: null })
  const [optionProdi, setOptionProdi] = useState([])
  const [initTahun, setInitTahun] = useState({ label: 'Pilih tahun', value: null })
  const [optionTahun, setOptionTahun] = useState([])

  const debounceSearch = useDebounce({ value: searchQuery, delay: 1000 })

  const getProdiCertificateFromLoader = () => {
    if (result.prodi.status === 'fulfilled') {
      const prodi = result.prodi.value
      if (prodi.status) {
        const listProdi = []
        const data = prodi.data
        data.map((item) => {
          const option = {
            label: `${item.jenjang} ${item.prodi}`,
            value: item.kode_prodi
          }
          listProdi.push(option)
        })
        setOptionProdi(listProdi)
      }
    }
    if (result.certif.status === 'fulfilled') {
      const certif = result.certif.value
      if (certif.status) {
        setListCertificate(certif.data)
        setTotalRow(certif.totalRows)
        setTotalPage(certif.totalPage)
      }
    }
    setStsGetLoader(true)
  }

  const regetProdiCertificate = async () => {
    const props = {
      action: 10,
      search: searchQuery,
      limit: limitRow,
      page: currentPage,
      // order: currentSort !== null ? currentSort.key : null,
      // asc: currentSort !== null ? currentSort.type : null
    }
    const result = await getCertificateController(props)
    if (result.status) {
      setListCertificate(result.data)
      setTotalRow(result.totalRows)
      setTotalPage(result.totalPage)
    }
  }

  const onHandlePaginationOption = (value) => {
    setLimitRow(value)
    setCurrentPage(1)
  }

  const onHandleSearch = (event) => {
    event.preventDefault()
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null)
    setCurrentPage(1)
    if (currentSort !== null) {
      setCurrentSort(null)
    }
  }

  const onHandlePagination = (value) => {
    setCurrentPage(value)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
  }

  const onHandleRefresh = () => {
    if (stsGetLoader) {
      regetProdiCertificate()
    }
  }

  const onAddCertificate = () => {
    setInitProdi({ label: 'Pilih program studi', value: null })
    setInitTahun({ label: 'Pilih tahun', value: null })
    setFileChosed(null)
    setShowDialog(true)
  }

  const onChangeFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const size = file.size
      if ((size / 1024) <= 3072) {
        setFileChosed(file)
      } else {
        setShowWarning(true)
      }
    }
  }

  const saveCertificate = async () => {
    setShowDialog(false)
    setLoading(true)

    const result = await saveCertificateController({
      action: 10,
      prodi: initProdi.value,
      tahun: initTahun.value,
      file: fileChoosed
    })
    if (result.status) {
      regetProdiCertificate()
      setTimeout(() => {
        setLoading(false)
        toast.success(result.message)
      }, 200)
    } else {
      setTimeout(() => {
        setLoading(false)
        toast.error(result.message)
      }, 200)
    }
  }

  useEffect(() => {
    getProdiCertificateFromLoader()
    setOptionTahun(setupTahun({ start: 2003 }))
  }, [])

  useEffect(() => {
    if (stsGetLoader) {
      regetProdiCertificate()
    }
  }, [debounceSearch, limitRow, currentPage, currentSort])

  return (
    <>
      <Column className={'p-4 gap-y-5 sm:p-8'}>
        <Column className={'gap-x-4 gap-y-2 md:flex-row md:justify-between'}>
          <Column className={'gap-y-2 flex-1'}>
            <Headline2Text className={'font-semibold'}>
              Sertifikat Program Studi
            </Headline2Text>
            <BodyText>
              Daftar sertifikat akreditasi program studi Universitas Teknologi Yogyakarta berdasarkan tahun akreditasi.
            </BodyText>
          </Column>
          <ButtonHover>
            <PrimaryButton
              color='indigo'
              className={'pr-6'}
              leadingIcon={
                <TbPlus className='h-5 w-5 my-auto' />
              }
              onClick={onAddCertificate}>
              Tambah
            </PrimaryButton>
          </ButtonHover>
        </Column>
        <Card className={'w-full'}>
          <Column className={'gap-y-5'}>
            <Column className='gap-x-4 gap-y-2 md:flex-row md:justify-between'>
              <PaginationOption
                initial={limitRow}
                onChange={onHandlePaginationOption} />
              <SearchInput
                defaultValue={searchQuery}
                placeholder='Cari sertifikat'
                onChange={onHandleSearch} />
            </Column>
            <TableProdiCertificate
              limit={limitRow}
              page={currentPage}
              listCertificate={listCertificate}
              onShort={onHandleSort}
              onRefresh={onHandleRefresh} />
            {listCertificate.length > 0 && <Pagination
              limitRow={limitRow}
              totalRow={totalRow}
              totalPage={totalPage}
              triger={currentSort !== null && currentSort.key}
              onChange={onHandlePagination} />}
          </Column>
        </Card>
      </Column>
      <AlertDialog
        show={showDialog}
        className={'max-w-lg'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3Text>
              Serifikat Akreditasi
            </Headline3Text>
            <BodyText>
              Tambahkan sertifikat akreditasi program studi yang terdapat di Universitas Teknologi Yogyakarta
            </BodyText>
          </Column>
          <Column className={'gap-y-6'}>
            <SelectInput
              width={'w-full'}
              label='Program Studi'
              initial={initProdi}
              options={optionProdi}
              onChange={(value) => setInitProdi(value)}
              btnClassName={'py-2.5 pl-4 pr-3'}
              maxHeight='max-h-36'
              zindex={'z-20'}
              required />
            <SelectInput
              width={'w-full'}
              label='Tahun Akreditasi'
              initial={initTahun}
              options={optionTahun}
              onChange={(value) => setInitTahun(value)}
              btnClassName={'py-2.5 pl-4 pr-3'}
              maxHeight='max-h-36'
              required />
            <Column className='gap-y-1'>
              <LabelText
                htmlForId={'sertif'}
                className={'px-2 text-gray-800'}>
                Sertifikat Akreditasi <span className='text-red-500'>*</span>
              </LabelText>
              <label
                htmlFor='sertif'
                className={'w-full border rounded-lg cursor-pointer hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50'}>
                <Row className={'px-4 py-2.5 gap-x-3 items-center rounded-lg bg-gray-50/50'}>
                  <TbFolderOpen className={'h-5 w-5 text-indigo-600'} />
                  <BodyText className='line-clamp-1'>
                    {fileChoosed !== null ? fileChoosed.name : 'Pilih sertifikat'}
                  </BodyText>
                </Row>
                <input
                  id='sertif'
                  type='file'
                  accept='image/*,application/pdf'
                  onChange={onChangeFile}
                  hidden />
              </label>
              <small className={'px-2 text-gray-500'}>
                Tipe file: JPG/JPEG/PNG/PDF
              </small>
            </Column>
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className={'h-5 w-5 my-auto'} />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='indigo'
                className={'pl-5 pr-6'}
                leadingIcon={
                  <TbSend className={'h-5 w-5 my-auto'} />
                }
                disabled={initProdi.value !== null && initTahun.value !== null && fileChoosed !== null ? false : true}
                onClick={saveCertificate}>
                Simpan
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <AlertDialog
        show={showWarning}
        className={'max-w-md'}
        onClose={() => { setShowWarning(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3Text>
              Peringatan!
            </Headline3Text>
            <BodyText>
              Ukuran file sertifikat akreditasi yang dipilih tidak boleh lebih dari 3MB!
            </BodyText>
          </Column>
          <Row className={'gap-x-3 justify-end'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowWarning(false)}>
                Tutup
              </TertiaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Menyimpan sertifikat...' />
    </>
  )
}

export default AdmProdiCertificatePage

export const getAdmProdiCertificate = async ({ action = 10, search = null, limit = 10, page = 1 }) => {
  const props = {
    action: action,
    search: search,
    limit: limit,
    page: page,
  }
  const [prodi, certificate] = await Promise.allSettled([
    getMasterProdiController(),
    getCertificateController(props),
  ])
  return {
    'prodi': prodi,
    'certif': certificate
  }
}