import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbPlus, TbSend, TbX } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import { Column, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { SearchInput } from '../../../components/interfaces/input'
import { BodyText, CaptionText, Headline2Text, Headline3Text, LabelText } from '../../../components/interfaces/text'
import { Pagination, PaginationOption, useDebounce } from '../../../components/interfaces/utility'
import { getCounselorConsultationController, saveCounselorConsultationController, searchDosenForCounselorController } from '../../../controllers/admin_controllers'
import { AlertDialog, ButtonHover, IconButton, LoadingDialog, PrimaryButton, TertiaryButton } from '../../../shared/ui'
import ItemDosen from './items/item_dosen'
import TableCounselorConsultation from './tables/table_counselor_consultation'

function AdmCounselorConsultationPage() {

  const result = useLoaderData()

  const [listCounselor, setListCounselor] = useState([])
  const [listDosen, setListDosen] = useState([])
  const [listDosenTemp, setListDosenTemp] = useState([])
  const [limitRow, setLimitRow] = useState(10)
  const [totalRow, setTotalRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [currentSort, setCurrentSort] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [searchQuery2, setSearchQuery2] = useState(null)
  const [stsGetLoader, setStsGetLoader] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const debounceSearch = useDebounce({ value: searchQuery, delay: 1000 })
  const debounceSearch2 = useDebounce({ value: searchQuery2, delay: 1000 })

  const getCounselorConsultationFromLoader = () => {
    if (result.counselor.status === 'fulfilled') {
      const counselor = result.counselor.value
      if (counselor.status) {
        setListCounselor(counselor.data)
        setTotalRow(counselor.totalRows)
        setTotalPage(counselor.totalPage)
      }
    }
    if (result.dosen.status === 'fulfilled') {
      const dosen = result.dosen.value
      if (dosen.status) {
        setListDosen(dosen.data)
      }
    }
    setStsGetLoader(true)
  }

  const regetCounselorConsultation = async () => {
    const props = {
      search: searchQuery,
      limit: limitRow,
      page: currentPage,
      order: currentSort !== null ? currentSort.key : null,
      asc: currentSort !== null ? currentSort.type : null
    }
    const result = await getCounselorConsultationController(props)
    if (result.status) {
      setListCounselor(result.data)
      setTotalRow(result.totalRows)
      setTotalPage(result.totalPage)
    }
  }

  const regetDosenConsultation = async () => {
    const props = {
      search: searchQuery2,
      limit: 10,
      page: 1,
      order: null,
      asc: null
    }
    const result = await searchDosenForCounselorController(props)
    if (result.status) {
      setListDosen(result.data)
    }
  }

  const onHandlePaginationOption = (value) => {
    setLimitRow(value)
    setCurrentPage(1)
  }

  const onHandleSearch = (event) => {
    event.preventDefault()
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null)
    setCurrentPage(1)
    if (currentSort !== null) {
      setCurrentSort(null)
    }
  }

  const onHandlePagination = (value) => {
    setCurrentPage(value)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
  }

  const onAddNewCounselor = () => {
    setListDosenTemp([])
    setShowDialog(true)
  }

  const onHandleSearchDosen = (event) => {
    event.preventDefault()
    setSearchQuery2(event.target.value.length > 0 ? event.target.value : null)
  }

  const onHandleDosen = (value) => {
    const find = listDosenTemp.filter((dosen) => dosen.nik === value.nik)
    if (find.length > 0) {
      const newDosen = listDosenTemp.filter((dosen) => dosen.nik !== value.nik)
      setListDosenTemp(newDosen)
    } else {
      setListDosenTemp([...listDosenTemp, value])
    }
  }

  const saveCounselorConsultation = async () => {
    setShowDialog(false)
    setLoading(true)
    const listNik = []
    if (listDosenTemp.length > 0) {
      listDosenTemp.map((selected) => {
        listNik.push(selected.nik)
      })
    }
    const result = await saveCounselorConsultationController({
      nik: listNik
    })
    if (result.status) {
      setTimeout(() => {
        regetCounselorConsultation()
        setLoading(false)
        toast.success(result.message)
      }, 200)
    } else {
      setTimeout(() => {
        setLoading(false)
        toast.error(result.message)
      }, 200)
    }
  }

  useEffect(() => {
    if (!stsGetLoader) {
      getCounselorConsultationFromLoader()
    } else {
      regetDosenConsultation()
    }
  }, [debounceSearch2])

  useEffect(() => {
    if (stsGetLoader) {
      regetCounselorConsultation()
    }
  }, [debounceSearch, limitRow, currentPage, currentSort])

  return (
    <>
      <Column className={'p-4 gap-y-5 sm:p-8'}>
        <Column className={'gap-x-4 gap-y-2 md:flex-row'}>
          <Column className={'gap-y-2 flex-1'}>
            <Headline2Text className={'font-semibold'}>
              Konselor Konsultasi
            </Headline2Text>
            <BodyText>
              Semua data dosen atau mitra yang ditugaskan sebagai konselor oleh Bidang Alumni.
            </BodyText>
          </Column>
          <ButtonHover>
            <PrimaryButton
              color='indigo'
              className={'pr-6'}
              leadingIcon={
                <TbPlus className='h-5 w-5 my-auto' />
              }
              onClick={onAddNewCounselor}>
              Tambah Konselor
            </PrimaryButton>
          </ButtonHover>
        </Column>
        <Card className={'w-full'}>
          <Column className={'gap-y-5'}>
            <Column className='gap-x-4 gap-y-2 md:flex-row md:justify-between'>
              <PaginationOption
                initial={limitRow}
                onChange={onHandlePaginationOption} />
              <SearchInput
                defaultValue={searchQuery}
                placeholder='Cari konselor'
                onChange={onHandleSearch} />
            </Column>
            <TableCounselorConsultation
              limit={limitRow}
              page={currentPage}
              listCounselor={listCounselor}
              onShort={onHandleSort} />
            {listCounselor.length > 0 && <Pagination
              limitRow={limitRow}
              totalRow={totalRow}
              totalPage={totalPage}
              triger={currentSort !== null && currentSort.key}
              onChange={onHandlePagination} />}
          </Column>
        </Card>
      </Column>
      <AlertDialog
        show={showDialog}
        className={'max-w-xl'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3Text>
              Tambah Konselor
            </Headline3Text>
            <BodyText>
              Tambahkan konselor baru untuk menjawab sesi konsultasi bersama alumni Universitas Teknologi Yogyakarta
            </BodyText>
          </Column>
          <Column className={'gap-y-6'}>
            {listDosenTemp.length > 0 && <Column className={'gap-y-1'}>
              <LabelText className={'px-2 text-gray-800'}>
                {listDosenTemp.length} dosen dipilih:
              </LabelText>
              <div className={'w-full h-fit max-h-36 flex flex-wrap gap-x-2 gap-y-2 overflow-y-auto scrollbar-hide'}>
                {
                  listDosenTemp.map((dosen, idx) => {
                    return (
                      <Row
                        key={idx}
                        className={'w-fit shrink-0 gap-x-2 border rounded-full pl-4 pr-2.5 py-2 items-center bg-green-50 border-green-200'}>
                        <CaptionText className={'shrink-0 line-clamp-1 text-green-700 font-medium'}>
                          {dosen.nama}
                        </CaptionText>
                        <IconButton
                          color='custom'
                          className='p-[0.1rem] text-red-500 hover:bg-red-500 hover:text-white'
                          onClick={() => onHandleDosen(dosen)}>
                          <TbX className='h-4 w-4' />
                        </IconButton>
                      </Row>
                    )
                  })
                }
              </div>
            </Column>}
            <Column className={'gap-y-1'}>
              <LabelText className={'px-2 text-gray-800'}>
                Pilih Dosen <span className='text-red-500'>*</span>
              </LabelText>
              <Column className={'gap-y-3'}>
                <SearchInput
                  defaultValue={searchQuery2}
                  placeholder='Cari nik atau nama'
                  onChange={onHandleSearchDosen} />
                {listDosen.length > 0 ? <Column className={'max-h-72 rounded-lg border divide-y bg-gray-50/50 overflow-scroll scrollbar-hide'}>
                  {listDosen.map((dosen, idx) => {
                    return (
                      <ItemDosen
                        key={idx}
                        dosen={dosen}
                        initial={listDosenTemp.find((item) => item.nik === dosen.nik)}
                        onClick={onHandleDosen} />
                    )
                  })}
                </Column> : <div></div>}
              </Column>
            </Column>
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className={'h-5 w-5 my-auto'} />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='indigo'
                className={'pl-5 pr-6'}
                leadingIcon={
                  <TbSend className={'h-5 w-5 my-auto'} />
                }
                disabled={listDosenTemp.length > 0 ? false : true}
                onClick={saveCounselorConsultation}>
                Simpan
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Menyimpan konselor...' />
    </>
  )
}

export default AdmCounselorConsultationPage

export const getAdmCounselorConsultation = async ({ search = null, limit = 10, page = 1, order = null, asc = null }) => {
  const props = {
    search: search,
    limit: limit,
    page: page,
    order: order,
    asc: asc
  }
  const [counselor, dosen] = await Promise.allSettled([
    getCounselorConsultationController(props),
    searchDosenForCounselorController(props)
  ])
  return {
    'counselor': counselor,
    'dosen': dosen,
  }
}