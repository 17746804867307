import React, { Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import AlumniLayout from '../layouts/alumni_layout'
import AdmAddEventPage from '../pages/admin/adm_add_event'
import AdmAddRecognitionPage from '../pages/admin/adm_add_recognition'
import AdmBusinessPage from '../pages/admin/adm_business'
import AdmCareerPage from '../pages/admin/adm_career'
import AdmCommentsPage from '../pages/admin/adm_comments'
import AdmConsultationPage from '../pages/admin/adm_consultation'
import AdmDashboardPage from '../pages/admin/adm_dashboard'
import AdmEventPage from '../pages/admin/adm_event'
import AdmKonselorPage from '../pages/admin/adm_konselor'
import AdmLegalitasBusinessPage from '../pages/admin/adm_legalitas_business'
import AdmLowonganPage from '../pages/admin/adm_lowongan'
import AdmMitraPage from '../pages/admin/adm_mitra'
import AdmPenggunaLulusanPage from '../pages/admin/adm_pengguna_lulusan'
import AdmRecognitionPage from '../pages/admin/adm_recognition'
import AdmTracerPage from '../pages/admin/adm_tracer'
import AdmTracerIPTPage from '../pages/admin/adm_tracer_ipt'
import BusinessAlumni from '../pages/alumni/alm_business'
import ConsultastionAlumni from '../pages/alumni/alm_consultation'
import InputTracerAlumni from '../pages/alumni/alm_input_tracer'
import TracerAlumni from '../pages/alumni/alm_tracer'
import AlmProfile from '../pages/alumni/profile/alm_profile'
import CareerAlumni from '../pages/career'
import DesignUI from '../pages/design'
import AlertUI from '../pages/design/alert'
import ButtonUI from '../pages/design/button'
import DetailEventPage from '../pages/detail_event'
import DetailRecognitionPage from '../pages/detail_recognition'
import EventPage from '../pages/event'
import KonConsultationPage from '../pages/konselor/kon_consultation'
import KonDashboardPage from '../pages/konselor/kon_dashboard'
import KonPlotingPage from '../pages/konselor/kon_ploting'
import LandingPage from '../pages/landing'
import LoginPage from '../pages/login'
import MitAccountPage from '../pages/mitra/mit_account'
import MitAddCareerPage from '../pages/mitra/mit_addcareer'
import MitApplyCareerPage from '../pages/mitra/mit_applycareer'
import MitCareerPage from '../pages/mitra/mit_career'
import MitDashboardPage from '../pages/mitra/mit_dashboard'
import NotFoundPage from '../pages/notfound'
import ProAddRecognitionPage from '../pages/prodi/pro_add_recognition'
import ProDashboardPage from '../pages/prodi/pro_dashboard'
import ProRecognitionPage from '../pages/prodi/pro_recognition'
import ProTracerPage from '../pages/prodi/pro_tracer'
import RecognitionPage from '../pages/recognition'
import RegisterMitraPage from '../pages/register'
import SuccessRegMitraPage from '../pages/success'
import { admAddEventRoute, admAddRecognitionRoute, admApplyCareerRoute, admBusinessRoute, admCareerRoute, admCommentsRoute, admConsultationRoute, admEventRoute, admKonselorRoute, admLegalitasBusinessRoute, admLowonganRoute, admMitraRoute, admPenggunaLulusanRoute, admRecognitionRoute, admRootRoute, admTracerIPTRoute, admTracerRoute, almBusinessRoute, almConsultationRoute, almDetailSavedRecognitionRoute, almDetailTagRecognitionRoute, almInputTracerRoute, almProfileRoute, almTracerRoute, careerRoute, detailEventRoute, detailRecognitionRoute, eventRoute, konConsultationRoute, konPlotingRoute, konRootRoute, landingRoute, loginRoute, mitAccountRoute, mitAddCareerRoute, mitApplyCareerRoute, mitCareerRoute, mitRootRoute, notFoundRoute, proAddRecognitionRoute, proRecognitionRoute, proRootRoute, proTracerRoute, recognitionRoute, registerMitraRoute, successRegMitraRoute } from '../shared/variable'
import AdmPrivateRoutes from './admprivate'
import AlmPrivateRoutes from './almprivate'
import KonPrivateRoutes from './konprivate'
import MitPrivateRoutes from './mitprivate'
import ProPrivateRoutes from './proprivate'

const RouterApp = () => {

    const location = useLocation()

    return (
        <Suspense>
            <Routes key={location.pathname} location={location}>
                <Route exact path={landingRoute} element={<AlumniLayout />}>
                    <Route exact path={landingRoute} element={<LandingPage />} />
                    <Route exact path={recognitionRoute} element={<RecognitionPage />} />
                    <Route exact path={detailRecognitionRoute} element={<DetailRecognitionPage />} />
                    <Route exact path={careerRoute} element={<CareerAlumni />} />
                    <Route exact path={eventRoute} element={<EventPage />} />
                    <Route exact path={detailEventRoute} element={<DetailEventPage />} />
                </Route>
                {/* ALUMNI */}
                <Route element={<AlmPrivateRoutes />}>
                    <Route exact path={almConsultationRoute} element={<ConsultastionAlumni />} />
                    <Route exact path={almTracerRoute} element={<TracerAlumni />} />
                    <Route exact path={almBusinessRoute} element={<BusinessAlumni />} />
                    <Route exact path={almProfileRoute} element={<AlmProfile />} />
                    <Route exact path={almInputTracerRoute} element={<InputTracerAlumni />} />
                    <Route exact path={almDetailSavedRecognitionRoute} element={<DetailRecognitionPage />} />
                    <Route exact path={almDetailTagRecognitionRoute} element={<DetailRecognitionPage />} />
                </Route>
                {/* PRODI */}
                <Route element={<ProPrivateRoutes />}>
                    <Route exact path={proRootRoute} element={<ProDashboardPage />} />
                    <Route exact path={proRecognitionRoute} element={<ProRecognitionPage />} />
                    <Route exact path={proAddRecognitionRoute} element={<ProAddRecognitionPage />} />
                    <Route exact path={proTracerRoute} element={<ProTracerPage />} />
                </Route>
                {/* KONSELOR */}
                <Route element={<KonPrivateRoutes />}>
                    <Route exact path={konRootRoute} element={<KonDashboardPage />} />
                    <Route exact path={konPlotingRoute} element={<KonPlotingPage />} />
                    <Route exact path={konConsultationRoute} element={<KonConsultationPage />} />
                </Route>
                {/* ADMIN */}
                <Route element={<AdmPrivateRoutes />}>
                    <Route exact path={admRootRoute} element={<AdmDashboardPage />} />
                    <Route exact path={admRecognitionRoute} element={<AdmRecognitionPage />} />
                    <Route exact path={admAddRecognitionRoute} element={<AdmAddRecognitionPage />} />
                    <Route exact path={admCommentsRoute} element={<AdmCommentsPage />} />
                    <Route exact path={admEventRoute} element={<AdmEventPage />} />
                    <Route exact path={admAddEventRoute} element={<AdmAddEventPage />} />
                    <Route exact path={admCareerRoute} element={<AdmCareerPage />} />
                    <Route exact path={admApplyCareerRoute} element={<MitApplyCareerPage />} />
                    <Route exact path={admLowonganRoute} element={<AdmLowonganPage />} />
                    <Route exact path={admMitraRoute} element={<AdmMitraPage />} />
                    <Route exact path={admConsultationRoute} element={<AdmConsultationPage />} />
                    <Route exact path={admKonselorRoute} element={<AdmKonselorPage />} />
                    <Route exact path={admTracerRoute} element={<AdmTracerPage />} />
                    <Route exact path={admPenggunaLulusanRoute} element={<AdmPenggunaLulusanPage />} />
                    <Route exact path={admBusinessRoute} element={<AdmBusinessPage />} />
                    <Route exact path={admLegalitasBusinessRoute} element={<AdmLegalitasBusinessPage />} />
                    {/* <Route exact path={admCertificateRoute} element={<AdmCertificatePage />} /> */}
                    <Route exact path={admTracerIPTRoute} element={<AdmTracerIPTPage />} />
                </Route>
                {/* MITRA */}
                <Route element={<MitPrivateRoutes />}>
                    <Route exact path={mitRootRoute} element={<MitDashboardPage />} />
                    <Route exact path={mitCareerRoute} element={<MitCareerPage />} />
                    <Route exact path={mitAddCareerRoute} element={<MitAddCareerPage />} />
                    <Route exact path={mitApplyCareerRoute} element={<MitApplyCareerPage />} />
                    <Route exact path={mitAccountRoute} element={<MitAccountPage />} />
                </Route>
                {/* PUBLIC */}
                <Route exact path={loginRoute} element={<LoginPage />} />
                <Route exact path={notFoundRoute} element={<NotFoundPage />} />
                <Route exact path={registerMitraRoute} element={<RegisterMitraPage />} />
                <Route exact path={successRegMitraRoute} element={<SuccessRegMitraPage />} />
                {/* DESIGN */}
                <Route exact path='/design' element={<DesignUI />} />
                <Route exact path='/design/alert' element={<AlertUI />} />
                <Route exact path='/design/button' element={<ButtonUI />} />
            </Routes>
        </Suspense>
    )
}

export default RouterApp
