import moment from 'moment';
import React, { useEffect } from 'react';
import { BiArrowToBottom, BiArrowToTop } from 'react-icons/bi';
import { AreaChart, Caption, Card, Column, DotSeparated, GridCol, LargeHeadline, Row, ScaleHover, Text, Title } from '../../shared/ui';

const OvCareer = ({ onDoneLoadData }) => {

    const seriesColors = [
        '#00BCD4',
        '#8BC34A',
        '#FFC107',
    ]

    const dateNow = Date.now()

    const overview = [
        {
            label: 'Menunggu Persetujuan',
            value: 0,
            progress: 5,
            progresstype: 20,
            detail: [],
            clickable: false
        },
        {
            label: 'Total Lowongan',
            value: 34,
            progress: 2,
            progresstype: 10,
            detail: [],
            clickable: false
        },
        {
            label: 'Total Peamar',
            value: 15,
            progress: 8,
            progresstype: 10,
            detail: [],
            clickable: false
        },
    ]

    const series = [
        {
            name: 'Persetujuan',
            data: [
                [dateNow, 15],
                [moment(dateNow).add(1, 'days'), 14],
                [moment(dateNow).add(2, 'days'), 8],
                [moment(dateNow).add(3, 'days'), 2],
                [moment(dateNow).add(4, 'days'), 5],
                [moment(dateNow).add(5, 'days'), 9],
                [moment(dateNow).add(6, 'days'), 17],
                [moment(dateNow).add(7, 'days'), 4],
                [moment(dateNow).add(8, 'days'), 9],
                [moment(dateNow).add(9, 'days'), 6],
                [moment(dateNow).add(10, 'days'), 4],
            ]
        },
        {
            name: 'Lowongan',
            data: [
                [dateNow, 4],
                [moment(dateNow).add(1, 'days'), 5],
                [moment(dateNow).add(2, 'days'), 5],
                [moment(dateNow).add(3, 'days'), 3],
                [moment(dateNow).add(4, 'days'), 1],
                [moment(dateNow).add(5, 'days'), 0],
                [moment(dateNow).add(6, 'days'), 2],
                [moment(dateNow).add(7, 'days'), 0],
                [moment(dateNow).add(8, 'days'), 1],
                [moment(dateNow).add(9, 'days'), 1],
                [moment(dateNow).add(10, 'days'), 3],
            ]
        },
        {
            name: 'Pelamar',
            data: [
                [dateNow, 4],
                [moment(dateNow).add(1, 'days'), 0],
                [moment(dateNow).add(2, 'days'), 1],
                [moment(dateNow).add(3, 'days'), 0],
                [moment(dateNow).add(4, 'days'), 3],
                [moment(dateNow).add(5, 'days'), 2],
                [moment(dateNow).add(6, 'days'), 0],
                [moment(dateNow).add(7, 'days'), 1],
                [moment(dateNow).add(8, 'days'), 0],
                [moment(dateNow).add(9, 'days'), 1],
                [moment(dateNow).add(10, 'days'), 1],
            ]
        }
    ]

    useEffect(() => {
        setTimeout(() => {
            onDoneLoadData(true)
        }, 500)
    }, [])

    return (
        <Column className='gap-y-5'>
            <GridCol className='grid-cols-1 gap-5 xl:grid-cols-3'>
                {overview.map((data, idx) => {
                    return <ScaleHover key={idx}>
                        <Card className='w-full h-full p-5'>
                            <Column className='gap-5'>
                                <Text className='font-medium'>
                                    {data.label}
                                </Text>
                                <Row className='justify-between'>
                                    <LargeHeadline className='font-normal font-inter'>
                                        {data.value ?? '0'}
                                    </LargeHeadline>
                                    <Card className='px-2.5 py-1 rounded-full mt-auto'>
                                        <Row className='gap-1'>
                                            {data.progresstype === 10 ? <BiArrowToTop className='h-4 w-4 my-auto text-green-600' /> : <BiArrowToBottom className='h-4 w-4 my-auto text-orange-600' />}
                                            <Caption className='text-gray-800'>
                                                {data.progress}%
                                            </Caption>
                                        </Row>
                                    </Card>
                                </Row>
                            </Column>
                        </Card>
                    </ScaleHover>
                })}
            </GridCol>
            <Card className='w-full h-full px-0 py-4'>
                <Column className='justify-between px-4 pb-5 gap-3 xl:flex-row'>
                    <Column>
                        <Title className='font-semibold my-auto'>
                            Grafik Aktivitas
                        </Title>
                        <Row>
                            {series.map((data, idx) => {
                                return <Row key={idx} className='w-fit'>
                                    <Caption>
                                        {data.name}
                                    </Caption>
                                    {idx < (series.length - 1) && <DotSeparated />}
                                </Row>
                            })}
                        </Row>
                    </Column>
                    {/* <div>
                        <ScaleHover>
                            <TertiaryButton color='blue' className='py-2 pl-3 pr-4' leadingIcon={<BiCloudDownload className='h-5 w-5' />}>
                                Unduh
                            </TertiaryButton>
                        </ScaleHover>
                    </div> */}
                </Column>
                <AreaChart series={series} type='datetime' height={300} lineWidth={3} listColors={seriesColors} />
            </Card>
        </Column>
    )
}

export default OvCareer
