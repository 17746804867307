import React from 'react'
import ItemCommentDetailRecognition from './item/item_comment_recognition'
import { Column, HorizontalDivider, Title } from '../../shared/ui'

function ListCommentDetailRecognition({ comments }) {
  return (
    comments.length > 0 && <Column className={'gap-y-5'}>
      <HorizontalDivider className='my-4' />
      <Title>
        Semua Komentar
      </Title>
      <Column className={'gap-y-10'}>
        {
          comments.map((comment, idx) => {
            return <ItemCommentDetailRecognition key={idx} comment={comment} />
          })
        }
      </Column>
    </Column>
  )
}

export default ListCommentDetailRecognition