import React, { useEffect, useState } from 'react'
import { TbAlertTriangle, TbPhoto, TbPhotoPlus, TbTrash, TbX } from 'react-icons/tb'
import { deleteImgRecognition, uploadImgRecognition } from '../controllers/apicontrollers'
import { AlertDialog, Caption, Card, Column, GridCol, Headline3, IconButton, Image, LoadingDialog, LoadingView, Overline, PrimaryButton, Row, SecondaryButton, Text, TextButton, Title } from '../shared/ui'
import WAspectRatioImage from './waspect_ratio_image'
import WOpsiViewImageRecognition from './wopsiview_image_recognition'

const WInputImageRecognition = ({ onChangeImg, onChangeImgRatio, onChangeImgView }) => {

    const [imageRec, setImageRec] = useState([])
    const [deleteImage, setDeleteImage] = useState(null)
    const [showDeleteImgDialog, setShowDeleteImgDialog] = useState(false)
    const [aspectRatio, setAspectRatio] = useState('aspect-video')
    const [imageView, setImageView] = useState('list')
    const [loading, setLoading] = useState(false)
    const [showLargeDialog, setShowLargeDialog] = useState(false)

    const onChangeImgRec = (event) => {
        const newImage = []
        if (event.target.files) {
            const file = event.target.files[0]
            const size = file.size

            if ((size / 1024) <= 3072) {
                const image = {
                    id: -1,
                    file: file,
                    url: '-',
                    loading: true,
                }
                newImage.push(image)
                if (imageRec.length > 0) {
                    const imageTemp = [...imageRec]
                    const newImageTemp = [...imageTemp, ...newImage]
                    setImageRec(newImageTemp)
                    uploadImageRecognition(file, (newImageTemp.length - 1))
                } else {
                    setImageRec(newImage)
                    uploadImageRecognition(file, 0)
                }
            } else {
                setShowLargeDialog(true)
            }
        }
    }

    const uploadImageRecognition = async (file, idx) => {
        const imageRecTemp = [...imageRec]
        const result = await uploadImgRecognition(file)
        if (result.status) {
            const updateImg = {
                id: result.data.id_img,
                file: file,
                url: result.data.image_name,
                loading: false,
            }
            imageRecTemp[idx] = updateImg
            setImageRec(imageRecTemp)
            onChangeImg(imageRecTemp)
        }
    }

    const updateImageAspectRatio = (ratio) => {
        setAspectRatio(ratio)
        onChangeImgRatio(ratio)
    }

    const updateImageView = (view) => {
        setImageView(view)
        onChangeImgView(view)
    }

    const setSelectedDeleteImage = (img) => {
        setDeleteImage(img)
        setShowDeleteImgDialog(true)
    }

    const deleteImageRec = async () => {
        setShowDeleteImgDialog(false)
        setLoading(true)
        const result = await deleteImgRecognition(deleteImage.id)
        if (result.status) {
            const newImageRec = imageRec.filter((image) => image !== deleteImage)
            setImageRec(newImageRec)
            onChangeImg(newImageRec)
            setTimeout(() => {
                setLoading(false)
            }, 200)
        }
    }

    const closeDeleteImgDialog = () => {
        setShowDeleteImgDialog(false)
    }

    useEffect(() => { }, [imageRec.length])

    return (
        <>
            <Column className='gap-1.5'>
                <Caption>Gambar Berita (opsional)</Caption>
                <Card className='w-full p-0 bg-slate-50'>
                    <Column className='divide-y-[1px] divide-gray-200'>
                        <Row className='gap-4 p-4'>
                            <div className='w-28 h-28 bg-white rounded-lg border-[1px]'>
                                <input id='img' type='file' className='hidden' onChange={onChangeImgRec} onClick={(event) => {
                                    event.currentTarget.value = null
                                }} accept='image/*' />
                                <label htmlFor='img' className='flex flex-col w-28 h-28 gap-3 items-center justify-center cursor-pointer'>
                                    <TbPhotoPlus className='h-7 w-7 text-indigo-400 stroke-1.5' />
                                    <Caption className='text-center new-line'>
                                        {'Tambahkan\nGambar'}
                                    </Caption>
                                </label>
                            </div>
                            {imageRec.length > 0 && <Row className='gap-4 overflow-x-auto scrollbar-hide scroll-smooth rounded-lg'>
                                {imageRec.map((image, idx) => {
                                    return (
                                        <div key={idx} className='relative flex-shrink-0 bg-slate-50 rounded-lg overflow-hidden'>
                                            {!image.loading ? <Image src={URL.createObjectURL(image.file)} imgClasName='w-28 h-28 object-cover rounded-lg' /> :
                                                <Card className='w-28 h-28 bg-slate-100 border-none items-center'>
                                                    <LoadingView className='h-7 w-7 text-indigo-700' />
                                                </Card>}
                                            {!image.loading && <IconButton color='custom' className='absolute top-1.5 right-1.5 p-0 backdrop-blur-sm bg-black/30'
                                                onClick={() => setSelectedDeleteImage(image)}>
                                                <TbX className='h-5 w-5 p-0.5 text-white' />
                                            </IconButton>}
                                        </div>
                                    )
                                })}
                            </Row>}
                        </Row>
                        <GridCol className='p-4 grid-cols-1 gap-4 sm:grid-cols-2'>
                            <Column className='gap-1.5 flex-1'>
                                <Caption>
                                    Rasio Gambar
                                </Caption>
                                <WAspectRatioImage onChange={updateImageAspectRatio} />
                            </Column>
                            <Column className='gap-1.5 flex-1'>
                                <Caption>
                                    Tampilan Gambar
                                </Caption>
                                <WOpsiViewImageRecognition onChange={updateImageView} />
                            </Column>
                        </GridCol>
                    </Column>
                </Card>
            </Column>
            <AlertDialog show={showDeleteImgDialog} onClose={closeDeleteImgDialog}>
                <Column className='divide-y'>
                    <Column className='gap-1 p-6'>
                        <Title className='font-bold'>
                            Hapus Gambar
                        </Title>
                        <Text>
                            Apakah anda yakin ingin menghapus Gambar berikut?
                        </Text>
                        {deleteImage !== null && <Image src={URL.createObjectURL(deleteImage.file)} className='mt-4 rounded-xl' imgClasName={`w-full ${aspectRatio} object-cover`} />}
                    </Column>
                    <Row className='gap-3 justify-end px-6 py-5'>
                        <SecondaryButton className='w-fit pr-5' onClick={closeDeleteImgDialog}>
                            <TbX className='h-5 w-5' /> Batal
                        </SecondaryButton>
                        <PrimaryButton className='w-fit pr-5' color='red' onClick={deleteImageRec}>
                            <TbTrash className='h-5 w-5' /> Ya, Hapus
                        </PrimaryButton>
                    </Row>
                </Column>
            </AlertDialog>
            <AlertDialog className='max-w-xs' show={showLargeDialog} onClose={() => setShowLargeDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 pt-10 pb-8 gap-8 items-center'>
                        <Card className='p-1.5 rounded-2xl bg-orange-50/50 border-orange-200/50'>
                            <TbAlertTriangle className='h-14 w-14 stroke-1.5 text-orange-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Perhatian!
                            </Headline3>
                            <Text className='text-center'>
                                Ukuran file foto atau gambar rekognisi yang dipilih tidak boleh lebih dari 3 MB
                            </Text>
                        </Column>
                    </Column>
                    <TextButton onClick={() => { setShowLargeDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                        Tutup
                    </TextButton>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loading} message='Menghapus gambar...' />
        </>
    )
}

export default WInputImageRecognition
