import React from "react";
import { useState } from "react";
import { Column, Row } from "../../../../components/interfaces/block";
import { Card } from "../../../../components/interfaces/card";
import {
  CaptionText,
  OverlineText,
  TitleText,
} from "../../../../components/interfaces/text";
import { getAdmSebaranRespondenController } from "../../../../controllers/admin_controllers";
import { useEffect } from "react";
import { BarChart } from "../../../../shared/ui";

function ChartGrafikResponden({ year, series, Labels }) {
  const [seriesLocal, setSeriesLocal] = useState([]);
  const [labelLocal, setLabelLocal] = useState([]);

  const colors = [
    "#088395",
    "#7A9D54",
    "#F1C93B",
    "#6A5495",
    "#7900FF",
    "#3F51B5",
    "#6C3428",
    "#F86F03",
    "#526D82",
    "#5A8F7B",
    "#F44336",
    "#2196F3",
    "#99627A",
    "#FF55BB",
    "#9C0F48",
    "#AE4CCF",
    "#61677A",
    "#245953",
    "#5D3891",
    "#FFD700",
    "#00FA9A",
    "#FF4500",
    "#40E0D0",
    "#D2691E",
    "#FF1493",
    "#8B4513",
    "#7FFF00",
  ];

  const legendColors = [
    "bg-[#088395]",
    "bg-[#7A9D54]",
    "bg-[#F1C93B]",
    "bg-[#6A5495]",
    "bg-[#7900FF]",
    "bg-[#3F51B5]",
    "bg-[#6C3428]",
    "bg-[#F86F03]",
    "bg-[#526D82]",
    "bg-[#5A8F7B]",
    "bg-[#F44336]",
    "bg-[#2196F3]",
    "bg-[#99627A]",
    "bg-[#FF55BB]",
    "bg-[#9C0F48]",
    "bg-[#AE4CCF]",
    "bg-[#61677A]",
    "bg-[#245953]",
    "bg-[#5D3891]",
    "bg-[#FFD700]",
    "bg-[#00FA9A]",
    "bg-[#FF4500]",
    "bg-[#40E0D0]",
    "bg-[#D2691E]",
    "bg-[#FF1493]",
    "bg-[#8B4513]",
    "bg-[#7FFF00]",
  ];

  const getOvGrafikRespondenPengguna = async () => {
    const props = {
      tahun: year.value,
    };
    const result = await getAdmSebaranRespondenController(props);
    if (result.status) {
      setLabelLocal(result.label_prodi);
      setSeriesLocal(result.data);
    }
  };
  useEffect(() => {
    if (year.value != null) {
      getOvGrafikRespondenPengguna();
    }
  }, [year]);
  return (
    <Card className="w-full h-full p-0 col-span-2">
      <Column>
        <Column className="p-5">
          <TitleText>Data Sebaran Responden Tracer Pengguna Lulusan</TitleText>
          <Row>
            <CaptionText>
              {year.value ? `Tahun ${year.label}` : year.label}
            </CaptionText>
          </Row>
        </Column>
        <div className={"pr-2 pl-1 pt-1"}>
          <BarChart
            series={year.value != null ? seriesLocal : series}
            height={300}
            listColors={colors}
          />
        </div>
        <ChartLegendPengLulusan
          labels={year.value != null ? labelLocal : Labels}
          colors={legendColors}
        />
      </Column>
    </Card>
  );
}
export default ChartGrafikResponden;

export const ChartLegendPengLulusan = ({ labels, colors }) => {
  return (
    <Row className="px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center">
      {labels.map((label, idx) => {
        return (
          <Row key={idx} className="w-fit gap-1.5">
            <Card
              className={`${colors[idx]} border-none p-1.5 rounded-full my-auto`}
            />
            <OverlineText className="my-auto text-gray-800 font-normal">
              {label}
            </OverlineText>
          </Row>
        );
      })}
    </Row>
  );
};
