import React from 'react'
import { Table, TableBody, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import RowTableRecapRecognition from './rows/row_recap_recognition'

function TableRecapRecognition({ listRekap }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            No
          </TableHeadCell>
          <TableHeadCell>
            Program Studi
          </TableHeadCell>
          <TableHeadCell>
            Total Postingan
          </TableHeadCell>
          <TableHeadCell>
            Total Disukai
          </TableHeadCell>
          <TableHeadCell>
            Total Kunjungan
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listRekap.map((rekap, idx) => {
          return (
            <RowTableRecapRecognition
              key={idx}
              idx={idx}
              rekap={rekap} />
          )
        })}
      </TableBody>
    </Table>
  )
}

export default TableRecapRecognition