import React from "react";
import { Column, Row, ShimmerAnimation, ShimmerItem } from "../../shared/ui";

const SAdmAddLowongan = ({ logo = false }) => {
  return (
    <Row className={"flex-col md:flex-row bg-white rounded-xl p-4"}>
      <Column className={"flex h-auto p-4"}>
        <ShimmerAnimation>
          <ShimmerItem className={"h-[600px] w-full rounded-md"} />
        </ShimmerAnimation>
      </Column>
      <Column className={"h-auto p-4 gap-y-8"}>
        {logo ? (
          <Column className={"gap-y-8"}>
            <Column className={"flex justify-center items-center"}>
              <ShimmerAnimation>
                <ShimmerItem className={"h-32 w-32 rounded-lg"} />
              </ShimmerAnimation>
            </Column>
            <Column>
              <ShimmerAnimation>
                <ShimmerItem className={"h-52 w-full rounded-lg"} />
              </ShimmerAnimation>
            </Column>
          </Column>
        ) : null}
        <Column>
          <ShimmerAnimation>
            <ShimmerItem className={"w-full rounded-lg"} />
          </ShimmerAnimation>
        </Column>
        <Row
          className={
            "justify-between flex-col md:flex-row gap-y-8 md:gap-y-0 gap-x-8 "
          }
        >
          <ShimmerAnimation>
            <ShimmerItem className={"w-full md:w-64 rounded-lg"} />
          </ShimmerAnimation>
          <ShimmerAnimation>
            <ShimmerItem className={"w-full md:w-64 rounded-lg"} />
          </ShimmerAnimation>
        </Row>
        <Column>
          <ShimmerAnimation>
            <ShimmerItem className={"w-full rounded-lg"} />
          </ShimmerAnimation>
        </Column>
        <Column>
          <ShimmerAnimation>
            <ShimmerItem className={"w-full h-20 rounded-lg"} />
          </ShimmerAnimation>
        </Column>
        <Column className={"flex justify-end items-end mt-6"}>
          <ShimmerAnimation>
            <ShimmerItem className={"w-24 rounded-lg"} />
          </ShimmerAnimation>
        </Column>
      </Column>
    </Row>
  );
};

export default SAdmAddLowongan;
