import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import WDetailRecognition from '../components/other/w_detail_recognition';
import SAlmDetailRecognition from '../components/shimmer/salm_detail_recognition';
import SAlmRecommendation from '../components/shimmer/salm_recommendation';
import { getDetailRecognitionController } from '../controllers/global_controllers';
import { Column, HorizontalDivider, ShareFBButton, ShareTelegramButton, ShareWAButton, ShareXButton } from '../shared/ui';

const ListEventRecommendation = lazy(() => import('../components/list/list_event_recommendation'))
const ListRecognitionRecommendation = lazy(() => import('../components/list/list_recognition_recommendation'))

const DetailRecognitionPage = () => {

    const result = useLoaderData()

    const [currentRecognition, setCurrentRecognition] = useState(null)

    const getDetailFromLoader = () => {
        if (result.status) {
            setCurrentRecognition(result.data)
        }
    }

    useEffect(() => {
        getDetailFromLoader()
    }, [])

    return (
        <Column className={'w-full max-w-8xl mx-auto gap-x-5 gap-y-8 pt-20 pb-16 sm:pt-24 lg:pt-32 xl:flex-row'}>
            <Column className={'w-fit hidden fixed gap-x-3 gap-y-3 lg:flex lg:left-10 lg:top-32 xl:sticky'}>
                <ShareFBButton location={window.location.href} />
                <ShareXButton location={window.location.href} />
                <ShareWAButton location={window.location.href} />
                <ShareTelegramButton location={window.location.href} />
            </Column>
            <Column className={'w-full px-4 max-w-4xl mx-auto sm:px-14'}>
                {currentRecognition ? <WDetailRecognition recognition={currentRecognition} /> : <SAlmDetailRecognition />}
            </Column>
            <HorizontalDivider className={'my-4 xl:hidden'} />
            <Column className='w-full max-w-4xl mx-auto gap-y-5 sm:px-14 xl:px-0 xl:max-w-sm xl:gap-y-8 xl:sticky xl:top-32'>
                <Suspense fallback={<SAlmRecommendation />}>
                    <ListRecognitionRecommendation />
                </Suspense>
                <HorizontalDivider className={'my-4'} />
                <Suspense fallback={<SAlmRecommendation />}>
                    <ListEventRecommendation />
                </Suspense>
            </Column>
        </Column>
    )
}

export default DetailRecognitionPage

export const getDetailRecognition = async ({ params }) => {
    const result = await getDetailRecognitionController(params.id)
    return result;
}