import moment from "moment";
import { TableCell, TableRow } from "../../../../../components/interfaces/table";
import { BodyText } from "../../../../../components/interfaces/text";

function RowInputExitSurvey({ idx, survey, page = 1, limit = 10 }) {
  return (
    <TableRow striped>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        {survey.nim}
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2 capitalize"}>
          {survey.nama.toLowerCase()}
        </BodyText>
      </TableCell>
      <TableCell>
        {survey.jenjang}
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>
          {survey.prodi}
        </BodyText>
      </TableCell>
      <TableCell>
        {survey.status}
      </TableCell>
      <TableCell>
        {moment(survey.tgl_wisuda).format("DD MMM YYYY")}
      </TableCell>
      <TableCell>
        {moment(survey.tgl_input).format("DD MMM YYYY")}
      </TableCell>
    </TableRow>
  );
}

export default RowInputExitSurvey;