import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbAlertTriangle, TbX } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { aApplyB2B } from '../atoms'
import { applyB2BController, getApplyB2BController } from '../controllers/alumni_controllers'
import { AlertDialog, ButtonHover, Column, Headline3, LoadingDialog, PrimaryButton, Row, SecondaryButton, Text } from '../shared/ui'
import CAlmBusiness from './cards/calm_business'

const WAlmB2BApply = () => {

  const [listBusiness, setListBusiness] = useRecoilState(aApplyB2B)
  const [currentBusiness, setCurrentBusiness] = useState(null)
  const [limitRow, setLimitRow] = useState(10)
  const [totalRow, setTotalRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const getApplyB2B = async () => {
    const props = {
      page: currentPage,
      limit: limitRow,
    }
    const result = await getApplyB2BController(props)
    if (result.status) {
      setListBusiness(result.data)
    }
  }

  const onCancelApply = (post) => {
    setCurrentBusiness(post)
    setShowDialog(true)
  }

  const cancelApplyBusiness = async () => {
    setShowDialog(false)
    setLoading(true)
    const props = {
      id: currentBusiness.slug,
      type: 'cancel',
      note: 'Saya tidak jadi tertarik dengan postingan Anda di Layanan Business to Business Simfora UTY'
    }
    const result = await applyB2BController(props)
    if (result.status) {
      setTimeout(() => {
        getApplyB2B()
        setLoading(false)
        toast.success(result.message)
      }, 200)
    } else {
      setTimeout(() => {
        setLoading(false)
        toast.error(result.message)
      }, 200)
    }
  }

  useEffect(() => {
    getApplyB2B()
  }, [])

  return (
    <>
      <Column className='gap-y-5'>
        {listBusiness.map((business, idx) => {
          return (
            <CAlmBusiness key={idx} action={30} business={business} onCancelApply={onCancelApply} />
          )
        })}
      </Column>
      <AlertDialog className='max-w-lg' show={showDialog} onClose={() => setShowDialog(false)}>
        <Column className='p-8 gap-y-8'>
          <Column className='gap-y-3'>
            <Headline3>
              Batal Tertarik
            </Headline3>
            {currentBusiness !== null && <Text>
              Apakah anda yakin ingin membatalkan tertarik pada postingan <span className='font-medium'>{currentBusiness.title}</span>?
            </Text>}
          </Column>
          <Row className='gap-x-5 justify-end'>
            <ButtonHover>
              <SecondaryButton className='pr-5' onClick={() => setShowDialog(false)} leadingIcon={<TbX className='h-5 w-5 my-auto' />}>
                Tutup
              </SecondaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton className='pr-5' color='red' onClick={cancelApplyBusiness} leadingIcon={<TbAlertTriangle className='h-5 w-5 my-auto' />}>
                Batalkan
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message='Sedang memproses...' />
    </>
  )
}

export default WAlmB2BApply