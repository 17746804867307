import React from 'react'
import { ButtonHover, IconButton, PrimaryButton, Row, TableCell, TableRow, TagView, TertiaryButton, Text, Tooltip } from '../../../shared/ui'
import { TbAlertTriangle, TbArrowRight, TbArrowUpRight, TbChecks, TbCircleCheck } from 'react-icons/tb'

const RowTableApplyB2B = ({ apply, idx, currentPage = 1, limitRow = 1, onApprove }) => {
    return (
        <TableRow className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                <Tooltip message={apply.nama_alumni}>
                    <Text className='line-clamp-2'>
                        {apply.nama_alumni}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip message={apply.catatan}>
                    <Text className='line-clamp-2'>
                        {apply.catatan}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                {apply.email}
            </TableCell>
            <TableCell>
                {apply.no_wa}
            </TableCell>
            <TableCell className='px-3 flex justify-center'>
                {apply.acc_ts === 'PROCESSED' ? <Row className='gap-x-3 justify-center'>
                    <ButtonHover>
                        <TertiaryButton onClick={() => onApprove({ action: 20, data: apply })} color='red' leadingIcon={<TbAlertTriangle className='h-5 w-5 my-auto' />} className='pr-5'>
                            Tolak
                        </TertiaryButton>
                    </ButtonHover>
                    <ButtonHover>
                        <PrimaryButton onClick={() => onApprove({ action: 10, data: apply })} color='indigo' leadingIcon={<TbCircleCheck className='h-5 w-5 my-auto' />} className='pr-5'>
                            Pilih
                        </PrimaryButton>
                    </ButtonHover>
                </Row> : apply.acc_ts === 'REJECTED' ? <TagView className='mx-auto' leadingIcon={<TbAlertTriangle className='h-4 w-4' />} label='TIDAK TERPILIH' color='red' /> : <TagView className='mx-auto' leadingIcon={<TbCircleCheck className='h-4 w-4' />} label='TERPILIH' color='green' />}
            </TableCell>
        </TableRow>
    )
}

export default RowTableApplyB2B