import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbFolder, TbPencil, TbPlus, TbUpload } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { dataAlumni } from '../../../atoms'
import { getProfileAlumniController, uploadDocumentController } from '../../../controllers/alumni_controllers'
import { AlertDialog, ButtonHover, Caption, Column, Headline3, IconButton, LoadingDialog, PrimaryButton, Row, Text, Title } from '../../../shared/ui'

function AlmDocumentProfilePage() {

  const result = useLoaderData()

  const [alumni, setAlumni] = useRecoilState(dataAlumni)
  const [currentDoc, setCurrentDoc] = useState(null)
  const [actionDoc, setActionDoc] = useState(0)
  const [errorDoc, setErrorDoc] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [extension, setExtension] = useState('.pdf')
  const [loading, setLoading] = useState(false)

  const getDetailFromLoader = () => {
    if (result.status) {
      setAlumni(result.data)
    }
  }

  const chooseDocument = (action) => {
    setActionDoc(action)
    setCurrentDoc(null)
    setErrorDoc(null)
    setShowDialog(true)
  }

  const onChangeDocument = (event) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const size = file.size

      if ((size / 1024) <= 2048) {
        setCurrentDoc(file)
        setExtension(file.name.split('.').pop())
      } else {
        toast.error('Maksimal ukuran file 2MB!')
      }
    }
  }

  const uploadDocument = async () => {
    if (currentDoc !== null) {
      setShowDialog(false)
      setLoading(true)
      const result = await uploadDocumentController(actionDoc, currentDoc)
      if (result.status) {
        setTimeout(() => {
          setLoading(false)
          toast.success(result.message)
          if (actionDoc === 10) {
            setAlumni(alumni => { return { ...alumni, ijazah: `ijazah-${alumni?.nim}.${extension}` } })
          } else if (actionDoc === 20) {
            setAlumni(alumni => { return { ...alumni, transkrip: `transkrip-${alumni?.nim}.${extension}` } })
          } else if (actionDoc === 30) {
            setAlumni(alumni => { return { ...alumni, cv: `cv-${alumni?.nim}.${extension}` } })
          } else {
            setAlumni(alumni => { return { ...alumni, portofolio: `portofolio-${alumni?.nim}.${extension}` } })
          }
        }, 200)
      } else {
        setTimeout(() => {
          setLoading(false)
          toast.error(result.message)
        }, 200)
      }
    } else {
      setErrorDoc(`Pilih file ${actionDoc === 10 ? 'Ijazah' : actionDoc === 20 ? 'Transkrip Nilai' : actionDoc === 30 ? 'Curriculum Vitae' : 'Portofolio'} sebelum mengunggah!`)
    }
  }

  useEffect(() => {
    getDetailFromLoader()
  }, [])

  return (
    <>
      <Column className='w-full max-w-4xl mx-auto gap-y-5 sm:gap-y-8 sm:px-14'>
        <Column className={'p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x'}>
          <Column className={'gap-y-2'}>
            <Title className={'font-semibold'}>
              Dokumen Lowongan
            </Title>
            <Caption>
              Lengkapi dokumen pendukung berikut sebelum mendaftar/apply lowongan pekerjaan
            </Caption>
          </Column>
          <Column className={'gap-y-4'}>
            <Row className={'px-4 py-2 gap-x-5 gap-y-3 transition-all duration-200 rounded-lg items-center hover:bg-gray-50'}>
              <Column className={'gap-y-1'}>
                <Caption>
                  Ijazah
                </Caption>
                <Text
                  className={`w-fit text-base font-normal cursor-pointer ${alumni?.ijazah === null ? 'text-gray-500' : 'hover:text-indigo-600'}`}
                  onClick={alumni?.ijazah ? () => window.open(alumni?.ijazah, '_blank', 'noopener, noreferrer') : null}>
                  {alumni?.ijazah === null ? 'Ijazah belum tersedia' : alumni?.ijazah?.split('/').pop()}
                </Text>
              </Column>
              <IconButton
                bordered
                color='indigo'
                className={'p-1.5'}
                onClick={() => chooseDocument(10)}>
                {
                  alumni?.ijazah ? <TbPencil className='h-6 w-6 my-auto' /> :
                    <TbPlus className='h-6 w-6 my-auto' />
                }
              </IconButton>
            </Row>
            <Row className={'px-4 py-2 gap-x-5 gap-y-3 transition-all duration-200 rounded-lg items-center hover:bg-gray-50'}>
              <Column className={'gap-y-1'}>
                <Caption>
                  Transkrip Nilai
                </Caption>
                <Text
                  className={`w-fit text-base font-normal cursor-pointer ${alumni?.transkrip === null ? 'text-gray-500' : 'hover:text-indigo-600'}`}
                  onClick={alumni?.transkrip ? () => window.open(alumni?.transkrip, '_blank', 'noopener, noreferrer') : null}>
                  {alumni?.transkrip === null ? 'Transkrip belum tersedia' : alumni?.transkrip?.split('/').pop()}
                </Text>
              </Column>
              <IconButton
                bordered
                color='indigo'
                className={'p-1.5'}
                onClick={() => chooseDocument(20)}>
                {
                  alumni?.transkrip ? <TbPencil className='h-6 w-6 my-auto' /> :
                    <TbPlus className='h-6 w-6 my-auto' />
                }
              </IconButton>
            </Row>
            <Row className={'px-4 py-2 gap-x-5 gap-y-3 transition-all duration-200 rounded-lg items-center hover:bg-gray-50'}>
              <Column className={'gap-y-1'}>
                <Caption>
                  Curriculum Vitae
                </Caption>
                <Text
                  className={`w-fit text-base font-normal cursor-pointer ${alumni?.cv === null ? 'text-gray-500' : 'hover:text-indigo-600'}`}
                  onClick={alumni?.cv ? () => window.open(alumni?.cv, '_blank', 'noopener, noreferrer') : null}>
                  {alumni?.cv === null ? 'CV belum tersedia' : alumni?.cv?.split('/').pop()}
                </Text>
              </Column>
              <IconButton
                bordered
                color='indigo'
                className={'p-1.5'}
                onClick={() => chooseDocument(30)}>
                {
                  alumni?.cv ? <TbPencil className='h-6 w-6 my-auto' /> :
                    <TbPlus className='h-6 w-6 my-auto' />
                }
              </IconButton>
            </Row>
          </Column>
        </Column>
        <Column className={'p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x'}>
          <Column className={'gap-y-2'}>
            <Title className={'font-semibold'}>
              Dokumen Business to Business
            </Title>
            <Caption>
              Lengkapi dokumen pendukung berikut sebelum tertarik pada postingan di layanan Business to Business
            </Caption>
          </Column>
          <Row className={'px-4 py-2 gap-x-5 gap-y-3 transition-all duration-200 rounded-lg items-center hover:bg-gray-50'}>
            <Column className={'gap-y-1'}>
              <Caption>
                Portofolio
              </Caption>
              <Text
                className={`w-fit text-base font-normal cursor-pointer ${alumni?.portofolio === null ? 'text-gray-500' : 'hover:text-indigo-600'}`}
                onClick={alumni?.portofolio ? () => window.open(alumni?.portofolio, '_blank', 'noopener, noreferrer') : null}>
                {alumni?.portofolio === null ? 'Portofolio belum tersedia' : alumni?.portofolio?.split('/').pop()}
              </Text>
            </Column>
            <IconButton
              bordered
              color='indigo'
              className={'p-1.5'}
              onClick={() => chooseDocument(40)}>
              {
                alumni?.portofolio ? <TbPencil className='h-6 w-6 my-auto' /> :
                  <TbPlus className='h-6 w-6 my-auto' />
              }
            </IconButton>
          </Row>
        </Column>
      </Column>
      <AlertDialog
        show={showDialog}
        className={'max-w-lg'}
        onClose={() => setShowDialog(false)}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className={'gap-y-3'}>
            <Headline3>
              {actionDoc === 10 ? 'Ijazah' : actionDoc === 20 ? 'Transkrip Nilai' : actionDoc === 30 ? 'Curriculum Vitae' : 'Portofolio'}
            </Headline3>
            <Column className={`border bg-gray-50 rounded-xl ${errorDoc ? 'border-red-200' : 'border-gray-200'}`}>
              <input
                id='document'
                type='file'
                className='hidden'
                accept='image/*,application/pdf'
                onChange={onChangeDocument}
                onClick={(event) => {
                  event.currentTarget.value = null
                }} />
              <label
                htmlFor='document'
                className={'w-full flex flex-col gap-y-2 aspect-video rounded-lg items-center justify-center cursor-pointer'}>
                <TbFolder className={'h-16 w-16 text-gray-300 stroke-1.5'} />
                <Caption className={'px-4 text-center text-base'}>
                  {
                    currentDoc ? currentDoc.name.replaceAll('_', ' ').replaceAll('-', ' ') : 'Pilih Dokumen (PDF/Gambar)'
                  }
                </Caption>
              </label>
            </Column>
            <Caption className={`px-4 ${errorDoc !== null && 'text-red-600'}`}>
              {errorDoc ?? 'Maksimal ukuran file 2MB'}
            </Caption>
          </Column>
          <Row className={'justify-end'}>
            <ButtonHover>
              <PrimaryButton
                pills
                color='indigo'
                className='pl-5 pr-6'
                leadingIcon={
                  <TbUpload className='h-5 w-5 my-auto' />
                }
                onClick={uploadDocument}>
                Unggah
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Mengunggah file...' />
    </>
  )
}

export default AlmDocumentProfilePage

export const getDocumentAlumni = async () => {
  const result = await getProfileAlumniController()
  return result
}