import React from "react";
import {
  AlertDialog,
  Avatar,
  ButtonHover,
  Caption,
  Column,
  HorizontalDivider,
  LoadingDialog,
  PrimaryButton,
  Row,
  Sidebar,
  SidebarHeader,
  SidebarMenu,
  Subtitle,
  TertiaryButton,
  TextButton,
} from "../../shared/ui";
import {
  TbBell,
  TbCategory,
  TbLogout2,
  TbReport,
  TbStack2,
  TbX,
} from "react-icons/tb";
import logo from "../../images/logo.png";
import {
  loginRoute,
  proRecognitionRoute,
  proRootRoute,
  proTracerRoute,
  prodNotificationRoute,
} from "../../shared/variable";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../shared/user";
import { useState } from "react";
import { logoutAccountController } from "../../controllers/global_controllers";
import { resetRecoilState } from "../../atoms";
import { useRecoilState } from "recoil";
import { BodyText, Headline3Text } from "../interfaces/text";

function ProdSideBarComponent({ showOnMobile }) {
  const location = useLocation();
  const navigateTo = useNavigate();
  const { name, username } = getCurrentUser();
  const [resetRecoil, setResetRecoil] = useRecoilState(resetRecoilState);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const logoutAccount = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await logoutAccountController();
    if (result) {
      setTimeout(() => {
        localStorage.clear();
        setResetRecoil(true);
        setLoading(false);
        navigateTo(loginRoute, { replace: true });
      }, 300);
    }
  };

  return (
    <>
      <Sidebar showOnMobile={showOnMobile}>
        <SidebarMenu className="h-fit gap-1 px-3 py-1">
          <SidebarHeader>
            <Column className={"gap-3 py-3 text-center"}>
              <Avatar className={"h-24 w-24 mx-auto"} src={logo} />
              <Column>
                <Subtitle className={"text-gray-800 font-semibold jakarta"}>
                  {name != null ? name : "Admin Prodi"}
                </Subtitle>
                <Caption>
                  {username != null ? username : "Administrator"}
                </Caption>
              </Column>
            </Column>
          </SidebarHeader>
          <TextButton
            align="left"
            color="custom"
            leadingIcon={<TbCategory className={"h-6 w-6"} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${
              location.pathname === proRootRoute && "text-indigo-600 bg-gray-50"
            } hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(proRootRoute)}
          >
            <span className={"line-clamp-1 flex-1 text-left"}>Dashboard</span>
          </TextButton>
          <TextButton
            align="left"
            color="custom"
            leadingIcon={<TbStack2 className={"h-6 w-6"} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${
              location.pathname === proRecognitionRoute &&
              "text-indigo-600 bg-gray-50"
            } hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(proRecognitionRoute)}
          >
            <span className={"line-clamp-1 flex-1 text-left"}>
              Berita Alumni
            </span>
          </TextButton>
          <TextButton
            align="left"
            color="custom"
            leadingIcon={<TbReport className={"h-6 w-6"} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${
              location.pathname === proTracerRoute &&
              "text-indigo-600 bg-gray-50"
            } hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(proTracerRoute)}
          >
            <span className={"line-clamp-1 flex-1 text-left"}>
              Tracer Studi
            </span>
          </TextButton>
          <HorizontalDivider />
          <TextButton
            align="left"
            color="custom"
            leadingIcon={<TbBell className={"h-6 w-6"} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${
              location.pathname.includes(prodNotificationRoute) &&
              "text-indigo-600 bg-gray-50"
            } hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(prodNotificationRoute)}
          >
            Notifikasi
          </TextButton>
        </SidebarMenu>
        <Column
          className={
            "absolute bottom-0 inset-x-0 p-4 bg-white border-t border-gray-200"
          }
        >
          <ButtonHover className={"w-full"}>
            <PrimaryButton
              pills
              color="red"
              className={"w-full"}
              leadingIcon={<TbLogout2 className={"h-5 w-5 my-auto"} />}
              onClick={() => setShowDialog(true)}
            >
              Keluar Akun
            </PrimaryButton>
          </ButtonHover>
        </Column>
      </Sidebar>
      <AlertDialog
        show={showDialog}
        className={"max-w-md"}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <Column className={"p-5 gap-y-8 sm:p-8"}>
          <Column className="gap-y-2">
            <Headline3Text>Keluar Akun</Headline3Text>
            <BodyText>
              Apakah anda yakin ingin keluar dari Simfora UTY?
            </BodyText>
          </Column>
          <Row className={"gap-x-3 justify-between"}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                className={"pl-5 pr-6"}
                onClick={() => setShowDialog(false)}
              >
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color="red"
                className="pl-5 pr-6"
                leadingIcon={<TbLogout2 className="h-5 w-5 my-auto" />}
                onClick={logoutAccount}
              >
                Keluar
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message="Keluar dari akun..." />
    </>
  );
}

export default ProdSideBarComponent;
