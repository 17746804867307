import { useEffect, useState } from "react";
import { Column, Row } from "../../../../components/interfaces/block";
import { Card } from "../../../../components/interfaces/card";
import {
  CaptionText,
  OverlineText,
  TitleText,
} from "../../../../components/interfaces/text";
import {
  getExcelDataLulusanController,
  getOvGrafikStatusTracerController,
} from "../../../../controllers/admin_controllers";
import {
  BarChart,
  ButtonHover,
  DotSeparated,
  SimpleAlert,
} from "../../../../shared/ui";
import { TbFileTypeCsv } from "react-icons/tb";
import moment from "moment";
import useDownloadExcel from "../../../../hooks/useDownloadExcel";

function CharStatusLulusan({
  prodi,
  currentDateStart,
  currentDateEnd,
  series,
  labels,
  rasio,
  currentTabIndex,
  binaryData,
}) {
  const colors = ["#4CAF50", "#F44336", "#2196F3", "#3F51B5", "#FF9800"];

  const legendColors = [
    "bg-[#4CAF50]",
    "bg-[#F44336]",
    "bg-[#2196F3]",
    "bg-[#3F51B5]",
    "bg-[#FF9800]",
  ];

  const [locSeries, setLocSeries] = useState([]);
  const [locLabels, setLocLabels] = useState([]);
  const [locRasio, setLocRasio] = useState(0);

  const getOvGrafikStatusTracer = async () => {
    const props = {
      prodi: prodi.value,
      date_start: currentDateStart.value,
      date_end: currentDateEnd.value,
    };
    const result = await getOvGrafikStatusTracerController(props);
    if (result.status) {
      var rasio = 0;
      result.data[0].data.map((value) => {
        rasio += value;
      });
      setLocRasio(rasio);
      setLocSeries(result.data);
      setLocLabels(result.labels);
    }
  };

  useEffect(() => {
    if (prodi.value != null || (currentDateStart && currentDateEnd)) {
      getOvGrafikStatusTracer();
    }
  }, [prodi, currentDateStart, currentDateEnd]);

  const fileName = `Status Lulusan_${
    currentTabIndex === 0
      ? currentDateStart.value !== null || currentDateEnd.value !== null
        ? `${moment(currentDateStart.value).format("YYYY")}_${moment(
            currentDateEnd.value
          ).format("YYYY")}`
        : "Semua Tahun"
      : currentTabIndex === 1
      ? currentDateStart.value !== null || currentDateEnd.value !== null
        ? moment(currentDateStart.value).format("YYYY")
        : "Semua Tahun"
      : "Semua Tahun"
  }_${prodi.value === null ? "Semua Program Studi" : prodi.label}`;
  const downloadExcel = useDownloadExcel({ fileName });

  const handleDownloadExcel = async () => {
    const res = await getExcelDataLulusanController({
      date_start: currentDateStart.value,
      date_end: currentDateEnd.value,
      prodi: prodi.value,
    });
    downloadExcel(res);
  };

  return (
    <Card className={"w-full h-full p-0"}>
      <Column>
        <Column className={"p-6 gap-y-1"}>
          <Row>
            <Column>
              <TitleText>Status Lulusan Saat Ini</TitleText>
            </Column>
            <Column className={"flex-row w-fit justify-end items-end "}>
              <ButtonHover>
                <button onClick={() => handleDownloadExcel(binaryData)}>
                  <TbFileTypeCsv className={`w-6 h-6 text-gray-500`} />
                </button>
              </ButtonHover>
            </Column>
          </Row>

          <Row>
            <CaptionText>
              {prodi.value ? prodi.label : "Semua Prodi"}
            </CaptionText>
            <DotSeparated />
            <CaptionText>
              {currentTabIndex === 0
                ? currentDateStart.value && currentDateEnd.value
                  ? `Tahun ${moment(currentDateStart.value).format(
                      "YYYY"
                    )}/${moment(currentDateEnd.value).format("YYYY")}`
                  : "Semua Tahun"
                : currentDateStart.value && currentDateEnd.value
                ? `Tahun ${moment(currentDateStart.value).format("YYYY")}`
                : "Semua Tahun"}
            </CaptionText>
          </Row>
        </Column>
        <SimpleAlert
          type="info"
          className="rounded-none mb-5"
          borderLeft
          showCloseBtn
        >
          Total responden:{" "}
          <span className="font-semibold">
            {prodi.value != null || (currentDateStart && currentDateEnd)
              ? locRasio
              : rasio}
          </span>
        </SimpleAlert>
        <div className={"pr-2 pl-1 pt-1"}>
          <BarChart
            series={
              prodi.value != null || (currentDateStart && currentDateEnd)
                ? locSeries
                : series
            }
            height={300}
            listColors={colors}
          />
        </div>
        <ChartLegend
          labels={
            prodi.value != null || (currentDateStart && currentDateEnd)
              ? locLabels
              : labels
          }
          colors={legendColors}
        />
      </Column>
    </Card>
  );
}

export default CharStatusLulusan;

export const ChartLegend = ({ labels, colors }) => {
  return (
    <Row className={"px-6 pb-6 gap-x-3 gap-y-2 flex-wrap justify-center"}>
      {labels.map((label, idx) => {
        return (
          <Row key={idx} className={"w-fit gap-1.5"}>
            <Card
              className={`${colors[idx]} border-none p-1.5 rounded-full my-auto`}
            />
            <OverlineText className={"my-auto text-gray-800 font-normal"}>
              {label}
            </OverlineText>
          </Row>
        );
      })}
    </Row>
  );
};
