import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TbCertificate, TbPencil } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Cookies from "universal-cookie";
import {
  dataTracer,
  indexInputTracer,
  inputTracer,
  inputTracerAction,
  masterPosisiTracer,
  masterProvinsiTracer,
  masterSumBiayaTracer,
  masterTingKerjaTracer,
  sertifAkreditasiInstitusiTracer,
  sertifAkreditasiTracer,
} from "../../atoms";
import {
  checkUserFilledTracer,
  getDataMasterPosisiTracer,
  getDataMasterProvinsiTracer,
  getDataMasterSumBiayaTracer,
  getDataMasterTingKerjaTracer,
  getDataTracerUser,
} from "../../controllers/apicontrollers";
import { convertDateToString } from "../../shared/lib";
import {
  ButtonHover,
  Caption,
  Card,
  Column,
  Headline1,
  Headline3,
  HorizontalDivider,
  PrimaryButton,
  Row,
  ShimmerAnimation,
  ShimmerItem,
  Subtitle,
  Text,
  Title,
} from "../../shared/ui";
import { almTracerRoute, refreshTokenKey } from "../../shared/variable";

function AlmTracerPage() {
  let decodeCookies;

  const cookies = new Cookies();
  const tokenCookies = cookies.get(refreshTokenKey) ?? null;

  if (tokenCookies != null) {
    decodeCookies = jwtDecode(tokenCookies);
  }

  const navigateTo = useNavigate();
  const [tracer, setTracer] = useRecoilState(dataTracer);
  const [stsInputTracer, setStsInputTracer] = useRecoilState(inputTracer);
  const [listSertifikat, setListSertifikat] = useRecoilState(
    sertifAkreditasiTracer
  );
  const [listSertifInstitusi, setListSertifInstitusi] = useRecoilState(
    sertifAkreditasiInstitusiTracer
  );
  const [masterProvinsi, setMasterProvinsi] =
    useRecoilState(masterProvinsiTracer);
  const [masterTingKerja, setMasterTingKerja] = useRecoilState(
    masterTingKerjaTracer
  );
  const [masterPosisi, setMasterPosisi] = useRecoilState(masterPosisiTracer);
  const [masterSumBiaya, setMasterSumBiaya] =
    useRecoilState(masterSumBiayaTracer);
  const [inputAction, setInputAction] = useRecoilState(inputTracerAction);
  const [currentIndex, setCurrentIndex] = useRecoilState(indexInputTracer);
  const [showProgress, setShowProgress] = useState(true);
  const [createdAt, setCreatedAt] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);

  const checkTracerStudi = async () => {
    const result = await checkUserFilledTracer();
    if (result.status) {
      setStsInputTracer(result.data);
      setListSertifikat(result.sertifikat);
      setListSertifInstitusi(result.institusi);
      await getDataTracerAlumni(result.data);
    }
  };

  const getDataTracerAlumni = async (stsInput) => {
    const result = await getDataTracerUser();
    if (result.status) {
      if (stsInput) {
        const created = convertDateToString({
          date: result.data.created,
          utc: true,
        });
        const updated = convertDateToString({
          date: result.data.update,
          utc: true,
        });
        const createdDate = Date.parse(created);
        const updatedDate = Date.parse(updated);
        setCreatedAt(moment(createdDate).format("DD MMMM YYYY HH.mm"));
        setUpdatedAt(moment(updatedDate).format("DD MMMM YYYY HH.mm"));
        setTracer(result.data);
      } else {
        setTracer(result.template);
      }
    }
  };

  const getMasterProvinsi = async () => {
    const result = await getDataMasterProvinsiTracer();
    if (result.status) {
      setMasterProvinsi(result.data);
    }
  };

  const getMasterTingkatKerja = async () => {
    const result = await getDataMasterTingKerjaTracer();
    if (result.status) {
      setMasterTingKerja(result.data);
    }
  };

  const getMasterPosisi = async () => {
    const result = await getDataMasterPosisiTracer();
    if (result.status) {
      setMasterPosisi(result.data);
    }
  };

  const getMasterSumBiaya = async () => {
    const result = await getDataMasterSumBiayaTracer();
    if (result.status) {
      setMasterSumBiaya(result.data);
    }
  };

  const getData = async () => {
    await checkTracerStudi();
    await getMasterProvinsi();
    await getMasterTingkatKerja();
    await getMasterPosisi();
    await getMasterSumBiaya();
    setShowProgress(false);
  };

  const inputTracerStudy = () => {
    setCurrentIndex(0);
    setInputAction(stsInputTracer ? 20 : 10);
    navigateTo(`${almTracerRoute}/${stsInputTracer ? "update" : "input"}`);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex   max-w-7xl mx-auto lg:px-6  py-0 mt-12 lg:mt-32 mb-0 lg:mb-12 lg:min-h-screen  ">
      {stsInputTracer !== null ? (
        <Card className="w-full p-0 rounded-none lg:rounded-xl lg:border-gray-200 lg:my-auto lg:shadow-lg lg:shadow-gray-200 ">
          <Column className="divide-y-[1px] lg:divide-x-[1px] lg:divide-y-0 lg:flex lg:flex-row">
            <Column>
              {/* tracer studi */}
              <Column className="gap-y-2 p-8 ">
                <Headline1 className="font-semibold">Tracer Study</Headline1>
                <Subtitle className="font-inter font-normal">
                  Guna mengetahui kontribusi yang diberikan oleh alumni UTY bagi
                  bangsa dan negara serta memetakan daya saing alumni dalam
                  dunia kerja dan industri, diperlukan penelusuran alumni secara
                  komprehensif, terstruktur, dan berkesinambungan melalui Tracer
                  Study. Kegiatan ini juga dimaksudkan untuk mendapatkan umpan
                  balik dari alumni UTY yang sudah berkiprah di masyarakat.
                  Umpan balik dari alumni UTY akan memberikan masukan dan
                  kontribusi positif kepada Universitas Teknologi Yogykarta
                  dalam menyelenggarakan pendidikan yang berkualitas dan
                  menyiapkan SDM unggul.
                </Subtitle>
              </Column>

              <HorizontalDivider removeMargin />

              {/* sudah isi tracer */}
              <Column className="p-8 gap-y-8 ">
                <Caption
                  className={`${
                    stsInputTracer ? "text-emerald-600" : "text-orange-600"
                  } font-medium`}
                >
                  {stsInputTracer
                    ? "SUDAH MENGISI TRACER STUDY"
                    : "BELUM MENGISI TRACER STUDY"}
                </Caption>
                <Column className="gap-y-2">
                  <Headline3>Halo, {decodeCookies.name}</Headline3>
                  <Text>
                    {stsInputTracer
                      ? "Terima kasih sudah berpartisipasi dalam mengisi Tracer Study"
                      : "Anda belum berpartisipasi dalam mengisi Tracer Study loh! Yuk isi sekarang"}
                  </Text>
                </Column>
              </Column>

              {/* update tracer */}
              <Column>
                <Column className="px-8 py-8 mt-auto gap-y-5">
                  <ButtonHover>
                    <PrimaryButton
                      onClick={inputTracerStudy}
                      color="indigo"
                      leadingIcon={<TbPencil className="h-5 w-5 my-auto" />}
                      className="pr-5"
                    >
                      {stsInputTracer
                        ? "Perbarui Tracer Study"
                        : "Isi Tracer Study"}
                    </PrimaryButton>
                  </ButtonHover>
                  <Caption className="text-gray-500">
                    {stsInputTracer
                      ? `Terakhir diperbarui ${updatedAt}`
                      : "Belum terdapat Riwayat pengisian Tracer Study"}
                  </Caption>
                </Column>
              </Column>
            </Column>

            {/* sertifikasi akreditasi */}
            <Column className="max-w-xl overflow-y-auto scrollbar-hide scroll-smooth gap-y-8 h-[90vh] lg:h-[113vh] xl:h-[100vh]">
              {stsInputTracer ? (
                <Column className="gap-y-6 ">
                  <Column className="px-8 pt-8 gap-y-2">
                    <TbCertificate className="h-8 w-8 stroke-1.5 text-amber-400" />
                    <Title>Sertifikat Akreditasi</Title>
                  </Column>
                  <Column className="divide-y-[1px] divide-gray-200">
                    <Caption className="px-8 pb-3">
                      Akreditasi Institusi
                    </Caption>
                    <Column>
                      {listSertifInstitusi.map((sertifikat, idx) => {
                        return (
                          <Column>
                            <Row key={idx} className="gap-x-4 px-8 py-3">
                              <Column>
                                <Text className="font-semibold font-manrope">
                                  {sertifikat.tahun == "2024"
                                    ? "Surat Keterangan Akreditasi Universitas"
                                    : "Universitas Teknologi Yogyakarta"}
                                </Text>
                                <Caption>
                                  Akreditasi Tahun {sertifikat.tahun}
                                </Caption>
                              </Column>
                              <ButtonHover className="my-auto">
                                <a
                                  href={sertifikat.name}
                                  target="_blank"
                                  download
                                  className="tertiary-btn py-2 hover:border-indigo-500"
                                >
                                  Unduh
                                </a>
                              </ButtonHover>
                            </Row>
                            {idx < listSertifInstitusi.length - 1 && (
                              <HorizontalDivider className="my-0 ml-8" />
                            )}
                          </Column>
                        );
                      })}
                    </Column>
                  </Column>
                  <Column className="divide-y-[1px] divide-gray-200">
                    <Caption className="px-8 pb-3">
                      Akreditasi Program Studi
                    </Caption>
                    <Column className="mb-5">
                      {listSertifikat.map((sertifikat, idx) => {
                        return (
                          <Column>
                            <Row key={idx} className="gap-x-4 px-8 py-3">
                              <Column>
                                <Text className="font-semibold font-manrope">
                                  {decodeCookies.prodi}
                                </Text>
                                <Caption>
                                  Akreditasi Tahun {sertifikat.tahun}
                                </Caption>
                              </Column>
                              <ButtonHover className="my-auto">
                                <a
                                  href={sertifikat.name}
                                  target="_blank"
                                  download
                                  className="tertiary-btn py-2 hover:border-indigo-500"
                                >
                                  Unduh
                                </a>
                              </ButtonHover>
                            </Row>
                            {idx < listSertifikat.length - 1 && (
                              <HorizontalDivider className="my-0 ml-8" />
                            )}
                          </Column>
                        );
                      })}
                    </Column>
                  </Column>
                </Column>
              ) : (
                <Column className="sm:h-[80vh] px-8 py-8 gap-y-2">
                  <TbCertificate className="h-8 w-8 stroke-1.5 text-amber-400" />
                  <Title>Sertifikat Akreditasi</Title>
                  <Text>
                    Sertifikat Aktreditasi Institusi dan Program Studi dapat
                    diunduh setelah Anda berpartisipasi mengisi Tracer Study.
                  </Text>
                </Column>
              )}
            </Column>
          </Column>
        </Card>
      ) : (
        <ShimmerAnimation className="w-full max-w-7xl my-auto">
          <ShimmerItem className="w-full sm:h-[80vh] rounded-xl" />
        </ShimmerAnimation>
      )}
    </div>
  );
}

export default AlmTracerPage;
