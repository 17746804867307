import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbAlertTriangle, TbPlus, TbX } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import Cookies from 'universal-cookie'
import { aB2B } from '../atoms'
import { applyB2BController, getB2BController } from '../controllers/alumni_controllers'
import { AlertDialog, ButtonHover, Card, Column, Headline1, Headline3, LoadingDialog, PrimaryButton, Row, SecondaryButton, Subtitle, Text } from '../shared/ui'
import { refreshTokenKey } from '../shared/variable'
import CAlmBusiness from './cards/calm_business'

const WAlmB2BPost = ({ typePost, categoryPost, onAddPost, onApplyPost, onChangeIndex }) => {

    let decodeCookies;

    const cookies = new Cookies()
    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    if (tokenCookies != null) {
        decodeCookies = jwtDecode(tokenCookies)
    }

    const [listBusiness, setListBusiness] = useRecoilState(aB2B)
    const [currentBusiness, setCurrentBusiness] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const getB2B = async () => {
        const props = {
            page: currentPage,
            limit: limitRow,
            type: typePost !== 'ALL' ? typePost : null,
            category: categoryPost !== 'ALL' ? categoryPost : null
        }
        const result = await getB2BController(props)
        if (result.status) {
            setListBusiness(result.data)
        }
    }

    const applyBusiness = async (slug) => {
        setLoading(true)
        const props = {
            id: slug,
            type: 'apply',
            note: 'Saya tertarik dengan postingan Anda di Layanan Business to Business Simfora UTY'
        }
        const result = await applyB2BController(props)
        if (result.status) {
            setTimeout(() => {
                onApplyPost()
                setLoading(false)
                toast.message(result.message)
            }, 200)
        } else {
            setTimeout(() => {
                setLoading(false)
                toast.error(result.message)
            }, 200)
        }
    }

    const onCancelApply = (post) => {
        setCurrentBusiness(post)
        setShowDialog(true)
    }

    const cancelApplyBusiness = async () => {
        setShowDialog(false)
        setLoading(true)
        const props = {
            id: currentBusiness.slug,
            type: 'cancel',
            note: 'Saya tidak jadi tertarik dengan postingan Anda di Layanan Business to Business Simfora UTY'
        }
        const result = await applyB2BController(props)
        if (result.status) {
            setTimeout(() => {
                getB2B()
                setLoading(false)
                toast.success(result.message)
            }, 200)
        } else {
            setTimeout(() => {
                setLoading(false)
                toast.error(result.message)
            }, 200)
        }
    }

    useEffect(() => {
        getB2B()
    }, [typePost, categoryPost])

    return (
        <>
            <Column className='gap-y-8'>
                <Card className='w-full p-8 shadow-lg shadow-gray-200'>
                    <Column className='gap-y-5'>
                        <Column className='gap-y-2'>
                            <Headline1 className='font-semibold'>
                                Business to Business
                            </Headline1>
                            <Subtitle className='font-inter font-normal'>
                                Business to Business dimaksudkan untuk memfasilitasi sesama alumni UTY dalam mengembangkan bisnis mereka. B2B berperan sebagai “Platform Penghubung” antara alumni yang membutuhkan informasi produk/jasa dengan alumni yang dapat memenuhi kebutuhan bisnis tersebut.
                            </Subtitle>
                        </Column>
                        <Card className='w-full p-2 bg-indigo-50 border-indigo-300 rounded-full'>
                            <Row className='items-center justify-between gap-x-5'>
                                <Subtitle className='pl-6 text-gray-500'>
                                    Yuk, buat postingan sekarang...
                                </Subtitle>
                                <ButtonHover>
                                    <PrimaryButton pills onClick={onAddPost} color='indigo' className='pl-5 pr-6' leadingIcon={<TbPlus className='h-5 w-5 my-auto' />}>
                                        Buat Postingan
                                    </PrimaryButton>
                                </ButtonHover>
                            </Row>
                        </Card>
                    </Column>
                </Card>
                {listBusiness.map((business, idx) => {
                    return (
                        <CAlmBusiness
                            key={idx}
                            business={business}
                            onApply={applyBusiness}
                            showApply={decodeCookies.username === business.id_user ? false : true}
                            onCancelApply={onCancelApply}
                            onChangeIndex={onChangeIndex} />
                    )
                })}
            </Column>
            <AlertDialog className='max-w-lg' show={showDialog} onClose={() => setShowDialog(false)}>
                <Column className='p-8 gap-y-8'>
                    <Column className='gap-y-3'>
                        <Headline3>
                            Batal Tertarik
                        </Headline3>
                        {currentBusiness !== null && <Text>
                            Apakah anda yakin ingin membatalkan tertarik pada postingan <span className='font-medium'>{currentBusiness.title}</span>?
                        </Text>}
                    </Column>
                    <Row className='gap-x-5 justify-end'>
                        <ButtonHover>
                            <SecondaryButton className='pr-5' onClick={() => setShowDialog(false)} leadingIcon={<TbX className='h-5 w-5 my-auto' />}>
                                Tutup
                            </SecondaryButton>
                        </ButtonHover>
                        <ButtonHover>
                            <PrimaryButton className='pr-5' color='red' onClick={cancelApplyBusiness} leadingIcon={<TbAlertTriangle className='h-5 w-5 my-auto' />}>
                                Batalkan
                            </PrimaryButton>
                        </ButtonHover>
                    </Row>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loading} message='Sedang memproses...' />
        </>
    )
}

export default WAlmB2BPost