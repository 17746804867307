import React, { useState } from "react";
import randomstring from "randomstring";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import ProdAppBarComponents from "../components/appbar/prod_appbar";
import ProdSideBarComponent from "../components/sidebar/prod_sidebar";
import { FullScreen, ToasterView } from "../shared/ui";

TopBarProgress.config({
  barColors: {
    0: "#6366F1",
    0.6: "#D50000",
    "1.0": "#6366F1",
  },
  barThickness: "2.5",
  shadowColor: "#fff",
  shadowBlur: "1",
});

const ProdiLayout = () => {
  const { state } = useNavigation();

  const [ShowSideBar, setShowSideBar] = useState("0");

  const onShowSidebar = () => {
    setShowSideBar(randomstring.generate(10));
  };
  return (
    <>
      {state === "loading" && <TopBarProgress />}
      <ProdAppBarComponents onShowSidebar={onShowSidebar} />
      <ProdSideBarComponent showOnMobile={ShowSideBar} />
      <FullScreen className={"py-14 xl:pl-72"}>
        <ScrollRestoration
          getKey={(location) => {
            return location.pathname;
          }}
        />
        <div className="w-full px-4 py-4 sm:px-6 sm:py-6 xl:px-8 xl:py-8">
          <Outlet />
        </div>
      </FullScreen>
      <ToasterView />
    </>
  );
};

export default ProdiLayout;
