import React, { useState } from 'react'
import { Card, Column, HorizontalDivider, Row, Table, TableBody, TableCell, TableHead, TableRow, Text, Title } from '../../shared/ui'
import RowTableApplyB2B from './rows/row_apply_b2b'

const TableAppltB2B = ({ listApply = [], onApprove }) => {

    const [searchQuery, setSearchQuery] = useState(null)
    const [sortTable, setShortTable] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)

    const onChangeShowNumber = (value) => {
        setCurrentPage(1)
        setLimitRow(value)
    }

    const onChangeQuerySearch = (value) => {
        setCurrentPage(1)
        setSearchQuery(value)
    }

    const onChangePageNumber = (value) => {
        setCurrentPage(value)
    }

    const onShortableValue = (value) => {
        setCurrentPage(1)
        setShortTable(value)
    }

    return (
        <Card className='w-full border-gray-200 p-0'>
            <Column>
                <Column className='px-5 py-4'>
                    <Column className='gap-0.5'>
                        <Title>
                            Alumni Tertarik
                        </Title>
                        <Text>
                            Daftar alumni yang tertarik dengan postingan saya
                        </Text>
                    </Column>
                </Column>
                <HorizontalDivider className='mt-0 mb-0' />
                <div className='p-5'>
                    <Table
                        className='border-[1px] border-gray-200'
                        limitRow={limitRow}
                        totalRow={totalRow}
                        totalPage={totalPage}
                        trigerPagination={sortTable}
                        onChangeShowNumber={onChangeShowNumber}
                        onChangeQuerySearch={onChangeQuerySearch}
                        onChangePageNumber={onChangePageNumber}>
                        <TableHead>
                            <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                <TableCell className='font-semibold font-manrope'>
                                    No.
                                </TableCell>
                                <TableCell className='font-semibold font-manrope'>
                                    Nama Alumni
                                </TableCell>
                                <TableCell className='font-semibold font-manrope w-[25%]'>
                                    Catatan Alumni
                                </TableCell>
                                <TableCell className='font-semibold font-manrope'>
                                    Email
                                </TableCell>
                                <TableCell className='font-semibold font-manrope'>
                                    Nomor Whatsapp
                                </TableCell>
                                <TableCell className='font-semibold font-manrope px-3 text-center'>
                                    Tentukan Pilihan
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='divide-y-[1px] divide-gray-200'>
                            {listApply.length > 0 ? listApply.map((apply, idx) => {
                                return <RowTableApplyB2B
                                    key={idx}
                                    idx={idx}
                                    limitRow={limitRow}
                                    currentPage={currentPage}
                                    apply={apply}
                                    onApprove={onApprove} />
                            }) : <TableRow>
                                <TableCell colSpan={8} className='text-center text-gray-500'>
                                    No data to display
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
            </Column>
        </Card>
    )
}

export default TableAppltB2B