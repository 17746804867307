import React from 'react'
import { HiArchiveBox, HiArrowLeft, HiArrowRight, HiArrowRightCircle, HiBackward, HiForward, HiMinus, HiPlay, HiPlus, HiShare, HiShoppingBag, HiTrash } from 'react-icons/hi2'
import { Column, GridCol, Headline1, IconButton, PrimaryButton, SecondaryButton, Subtitle, TertiaryButton } from '../../shared/ui'
import DesignLayout from '../../layouts/design_layout'

const ButtonUI = () => {
  return (
    <DesignLayout currentPage='/design/button'>
      <Headline1 className='mb-8'>
        Buttons
      </Headline1>
      <GridCol className='grid-cols-1 gap-x-12 gap-y-16 mb-20 lg:grid-cols-2'>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Primary button</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-4'>
            <PrimaryButton>Black</PrimaryButton>
            <PrimaryButton color='red'>Red</PrimaryButton>
            <PrimaryButton color='green'>Green</PrimaryButton>
            <PrimaryButton color='purple'>Purple</PrimaryButton>
            <PrimaryButton color='orange'>Orange</PrimaryButton>
            <PrimaryButton color='indigo'>Indigo</PrimaryButton>
            <PrimaryButton color='blue'>Blue</PrimaryButton>
            <PrimaryButton disabled={true}>Disable</PrimaryButton>
          </GridCol>
        </Column>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Circle primary button</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-4'>
            <PrimaryButton pills={true}>Black</PrimaryButton>
            <PrimaryButton color='red' pills={true}>Red</PrimaryButton>
            <PrimaryButton color='green' pills={true}>Green</PrimaryButton>
            <PrimaryButton color='purple' pills={true}>Purple</PrimaryButton>
            <PrimaryButton color='orange' pills={true}>Orange</PrimaryButton>
            <PrimaryButton color='indigo' pills={true}>Indigo</PrimaryButton>
            <PrimaryButton color='blue' pills={true}>Blue</PrimaryButton>
            <PrimaryButton disabled={true} pills={true}>Disable</PrimaryButton>
          </GridCol>
        </Column>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Secondary button</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-4'>
            <SecondaryButton>Gray</SecondaryButton>
            <SecondaryButton color='red'>Red</SecondaryButton>
            <SecondaryButton color='green'>Green</SecondaryButton>
            <SecondaryButton color='purple'>Purple</SecondaryButton>
            <SecondaryButton color='orange'>Orange</SecondaryButton>
            <SecondaryButton color='indigo'>Indigo</SecondaryButton>
            <SecondaryButton color='blue'>Blue</SecondaryButton>
            <SecondaryButton disabled={true}>Disable</SecondaryButton>
          </GridCol>
        </Column>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Circle secondary button</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-4'>
            <SecondaryButton pills={true}>Gray</SecondaryButton>
            <SecondaryButton color='red' pills={true}>Red</SecondaryButton>
            <SecondaryButton color='green' pills={true}>Green</SecondaryButton>
            <SecondaryButton color='purple' pills={true}>Purple</SecondaryButton>
            <SecondaryButton color='orange' pills={true}>Orange</SecondaryButton>
            <SecondaryButton color='indigo' pills={true}>Indigo</SecondaryButton>
            <SecondaryButton color='blue' pills={true}>Blue</SecondaryButton>
            <SecondaryButton disabled={true} pills={true}>Disable</SecondaryButton>
          </GridCol>
        </Column>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Tertiary button</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-4'>
            <TertiaryButton>Gray</TertiaryButton>
            <TertiaryButton color='red'>Red</TertiaryButton>
            <TertiaryButton color='green'>Green</TertiaryButton>
            <TertiaryButton color='purple'>Purple</TertiaryButton>
            <TertiaryButton color='orange'>Orange</TertiaryButton>
            <TertiaryButton color='indigo'>Indigo</TertiaryButton>
            <TertiaryButton color='blue'>Blue</TertiaryButton>
            <TertiaryButton disabled={true}>Disable</TertiaryButton>
          </GridCol>
        </Column>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Circle tertiary button</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-4'>
            <TertiaryButton pills={true}>Gray</TertiaryButton>
            <TertiaryButton color='red' pills={true}>Red</TertiaryButton>
            <TertiaryButton color='green' pills={true}>Green</TertiaryButton>
            <TertiaryButton color='purple' pills={true}>Purple</TertiaryButton>
            <TertiaryButton color='orange' pills={true}>Orange</TertiaryButton>
            <TertiaryButton color='indigo' pills={true}>Indigo</TertiaryButton>
            <TertiaryButton color='blue' pills={true}>Blue</TertiaryButton>
            <TertiaryButton disabled={true} pills={true}>Disable</TertiaryButton>
          </GridCol>
        </Column>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Button with leading icon</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-3'>
            <PrimaryButton color='blue' leadingIcon={<HiShoppingBag className='h-5 w-5 my-auto' />}>Order Now</PrimaryButton>
            <SecondaryButton color='blue' leadingIcon={<HiShoppingBag className='h-5 w-5 my-auto' />}>Order Now</SecondaryButton>
            <TertiaryButton color='blue' leadingIcon={<HiShoppingBag className='h-5 w-5 my-auto' />}>Order Now</TertiaryButton>
            <PrimaryButton disabled={true} leadingIcon={<HiShoppingBag className='h-5 w-5 my-auto' />}>Order Now</PrimaryButton>
            <SecondaryButton disabled={true} leadingIcon={<HiShoppingBag className='h-5 w-5 my-auto' />}>Order Now</SecondaryButton>
            <TertiaryButton disabled={true} leadingIcon={<HiShoppingBag className='h-5 w-5 my-auto' />}>Order Now</TertiaryButton>
          </GridCol>
        </Column>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Button with trailing icon</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-3'>
            <PrimaryButton color='blue' trailingIcon={<HiArrowRightCircle className='h-5 w-5 my-auto' />}>Sign In</PrimaryButton>
            <SecondaryButton color='blue' trailingIcon={<HiArrowRightCircle className='h-5 w-5 my-auto' />}>Sign In</SecondaryButton>
            <TertiaryButton color='blue' trailingIcon={<HiArrowRightCircle className='h-5 w-5 my-auto' />}>Sign In</TertiaryButton>
            <PrimaryButton disabled={true} trailingIcon={<HiArrowRightCircle className='h-5 w-5 my-auto' />}>Sign In</PrimaryButton>
            <SecondaryButton disabled={true} trailingIcon={<HiArrowRightCircle className='h-5 w-5 my-auto' />}>Sign In</SecondaryButton>
            <TertiaryButton disabled={true} trailingIcon={<HiArrowRightCircle className='h-5 w-5 my-auto' />}>Sign In</TertiaryButton>
          </GridCol>
        </Column>
        <Column className='gap-3'>
          <Subtitle className='font-semibold'>Icon button</Subtitle>
          <GridCol className='gap-3 sm:grid-cols-10'>
            <IconButton color='blue'>
              <HiArrowLeft className='h-5 w-5' />
            </IconButton>
            <IconButton color='indigo'>
              <HiArrowRight className='h-5 w-5' />
            </IconButton>
            <IconButton color='purple'>
              <HiShare className='h-5 w-5' />
            </IconButton>
            <IconButton color='green'>
              <HiPlus className='h-5 w-5' />
            </IconButton>
            <IconButton color='red'>
              <HiMinus className='h-5 w-5' />
            </IconButton>
            <IconButton color='red'>
              <HiTrash className='h-5 w-5' />
            </IconButton>
            <IconButton color='blue'>
              <HiBackward className='h-5 w-5' />
            </IconButton>
            <IconButton color='orange'>
              <HiPlay className='h-5 w-5' />
            </IconButton>
            <IconButton color='blue'>
              <HiForward className='h-5 w-5' />
            </IconButton>
            <IconButton color='blue' disabled={true}>
              <HiArchiveBox className='h-5 w-5' />
            </IconButton>
            <IconButton color='blue' bordered={true}>
              <HiArrowLeft className='h-5 w-5' />
            </IconButton>
            <IconButton color='blue' bordered={true}>
              <HiArrowRight className='h-5 w-5' />
            </IconButton>
            <IconButton color='purple' bordered={true}>
              <HiShare className='h-5 w-5' />
            </IconButton>
            <IconButton color='green' bordered={true}>
              <HiPlus className='h-5 w-5' />
            </IconButton>
            <IconButton color='red' bordered={true}>
              <HiMinus className='h-5 w-5' />
            </IconButton>
            <IconButton color='red' bordered={true}>
              <HiTrash className='h-5 w-5' />
            </IconButton>
            <IconButton color='blue' bordered={true}>
              <HiBackward className='h-5 w-5' />
            </IconButton>
            <IconButton color='orange' bordered={true}>
              <HiPlay className='h-5 w-5' />
            </IconButton>
            <IconButton color='blue' bordered={true}>
              <HiForward className='h-5 w-5' />
            </IconButton>
            <IconButton color='green' bordered={true} disabled={true}>
              <HiArchiveBox className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiArrowLeft className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiArrowRight className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiShare className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiPlus className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiMinus className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiTrash className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiBackward className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiPlay className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiForward className='h-5 w-5' />
            </IconButton>
            <IconButton bordered={true}>
              <HiArchiveBox className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiArrowLeft className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiArrowRight className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiShare className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiPlus className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiMinus className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiTrash className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiBackward className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiPlay className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiForward className='h-5 w-5' />
            </IconButton>
            <IconButton>
              <HiArchiveBox className='h-5 w-5' />
            </IconButton>
          </GridCol>
        </Column>
      </GridCol>
    </DesignLayout>
  )
}

export default ButtonUI
