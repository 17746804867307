import { TbLock, TbUpload } from "react-icons/tb";
import {
  ButtonHover,
  Caption,
  Column,
  OutlineInput,
  PrimaryButton,
  Row,
  TertiaryButton,
  Title,
} from "../../shared/ui";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { almProfileRoute } from "../../shared/variable";
import { gantiKataSandiController } from "../../controllers/alumni_controllers";
import toast from "react-hot-toast";

const AlmChangePassword = () => {
  const navigate = useNavigate();
  const [errPassword, setErrPassword] = useState(null);
  const [passStrength, setPassStrength] = useState(null);
  const [formData, setFormData] = useState({
    old_password: "",
    password: "",
    konf_password: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const password = formData.password;
    if (password.length === 0) {
      setPassStrength();
    } else if (password.length >= 1 && password.length <= 8) {
      setPassStrength("Weak");
    } else if (
      password.length >= 10 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[!@#$%^&*_]/.test(password)
    ) {
      setPassStrength("Strong");
    } else if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password)
    ) {
      setPassStrength("Medium");
    } else {
      setPassStrength("Weak");
    }
  }, [formData.password]);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const res = await gantiKataSandiController(formData);
    if (res.status) {
      navigate(almProfileRoute);
      toast.success("Berhasil ubah kata sandi");
    } else {
      setErrPassword(res.message);
      return;
    }
  };

  return (
    <>
      <Column className="w-full max-w-4xl mx-auto gap-y-5 sm:gap-y-8 sm:px-14">
        <Column className={"gap-y-3"}>
          <form onSubmit={handleSubmitForm}>
            <Column
              className={
                "p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x"
              }
            >
              <Row className={"items-center gap-x-3 justify-between"}>
                <Title className={"font-semibold"}>Ubah Kata Sandi</Title>
                <div
                  className={
                    "flex h-7 w-7 text-base font-inter font-medium text-gray-500 border bg-gray-100 rounded-full justify-center items-center"
                  }
                >
                  <TbLock className="w-5 h-5 text-gray-500" />
                </div>
              </Row>
              <Column className={"gap-y-4 mb-6"}>
                <OutlineInput
                  type={"password"}
                  label={"Kata Sandi Lama"}
                  placeholder={"Masukkan kata sandi lama"}
                  inputWidth={"w-full"}
                  bgColor={"bg-slate-50"}
                  togglePass={true}
                  id="old_password"
                  name="old_password"
                  defaultValue={formData.old_password}
                  onChange={handleInputChange}
                  required
                />
                <Column>
                  <OutlineInput
                    type="password"
                    label="Kata Sandi Baru"
                    placeholder="Masukkan kata sandi baru"
                    inputWidth="w-full"
                    bgColor="bg-slate-50"
                    togglePass={true}
                    id="password"
                    name="password"
                    defaultValue={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                  {passStrength && (
                    <Column className="mt-2">
                      <Row className="gap-x-2 grid grid-cols-5">
                        <span
                          className={`w-full h-1 rounded-full col-span-1 ${
                            passStrength === "Weak"
                              ? "bg-red-500"
                              : passStrength === "Medium"
                              ? "bg-orange-500"
                              : passStrength === "Strong"
                              ? "bg-green-500"
                              : "bg-gray-200"
                          }`}
                        />
                        <span
                          className={` w-full h-1 rounded-full col-span-1 ${
                            passStrength === "Weak"
                              ? "bg-gray-200"
                              : passStrength === "Medium"
                              ? "bg-orange-500"
                              : passStrength === "Strong"
                              ? "bg-green-500"
                              : "bg-slate-200"
                          }`}
                        />
                        <span
                          className={` w-full h-1 rounded-full col-span-3 ${
                            passStrength === "Weak"
                              ? "bg-gray-200"
                              : passStrength === "Medium"
                              ? "bg-gray-200"
                              : passStrength === "Strong"
                              ? "bg-green-500"
                              : "bg-gray-200"
                          }`}
                        />
                      </Row>
                      <small
                        className={`overlines mt-1 px-4 ${
                          passStrength === "Medium"
                            ? "text-orange-500"
                            : passStrength === "Strong"
                            ? "text-green-500"
                            : "text-red-500"
                        } `}
                      >
                        {passStrength}
                      </small>
                    </Column>
                  )}
                </Column>

                <Column>
                  <OutlineInput
                    type={"password"}
                    label={"Konfirmasi Kata Sandi Baru"}
                    placeholder={"Masukkan konfirmasi kata sandi baru"}
                    inputWidth={"w-full"}
                    bgColor={"bg-slate-50"}
                    togglePass={true}
                    id="konf_password"
                    name="konf_password"
                    defaultValue={formData.konf_password}
                    onChange={handleInputChange}
                    required
                  />
                  {errPassword && (
                    <Column className={`gap-y-1 mt-2 px-4 `}>
                      <small className={`overlines ${"text-red-500"} `}>
                        {errPassword}
                      </small>
                    </Column>
                  )}
                </Column>
              </Column>
              <Column>
                <div className="flex justify-end">
                  <ButtonHover>
                    <button type="submit">
                      <PrimaryButton
                        pills
                        color="indigo"
                        className={"pl-5 pr-6 "}
                        leadingIcon={
                          <TbUpload className={"h-5 w-5 my-auto "} />
                        }
                      >
                        Simpan
                      </PrimaryButton>
                    </button>
                  </ButtonHover>
                </div>
              </Column>
            </Column>
          </form>

          <Caption className={"px-4"}>
            Silahkan ubah kata sandi anda supaya akun anda lebih aman.
          </Caption>
        </Column>
      </Column>
    </>
  );
};
export default AlmChangePassword;
