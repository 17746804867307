
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { TbArchive, TbCards, TbCarouselVertical, TbListDetails } from 'react-icons/tb'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentIndexTabB2BAlm, currentUserData } from '../../atoms'
import WAlmB2BApply from '../../components/walm_b2b_apply'
import WAlmB2bCreate from '../../components/walm_b2b_create'
import WAlmB2BMyPost from '../../components/walm_b2b_mypost'
import WAlmB2BPost from '../../components/walm_b2b_post'
import { Card, Column, Headline3, HorizontalDivider, Row, SelectView, Text, Title, TransitionPage } from '../../shared/ui'

function AlmBusinessPage() {

    const user = useRecoilValue(currentUserData)
    const [currentIndex, setCurrentIndex] = useRecoilState(currentIndexTabB2BAlm)
    const [initialType, setInitialType] = useState({ label: 'Semua Jenis', value: 'ALL' })
    const [initialCategory, setInitialCategory] = useState({ label: 'Semua Kategori', value: 'ALL' })
    const opsiType = [{ label: 'Semua Jenis', value: 'ALL' }, { label: 'Produk', value: 'PRODUK' }, { label: 'Jasa', value: 'JASA' },]
    const opsiCategory = [{ label: 'Semua Kategori', value: 'ALL' }, { label: 'Kebutuhan', value: 'KEBUTUHAN' }, { label: 'Penawaran', value: 'PENAWARAN' },]

    const changeCurrentIndex = (index) => {
        setInitialType({ label: 'Semua Jenis', value: 'ALL' })
        setInitialCategory({ label: 'Semua Kategori', value: 'ALL' })
        setCurrentIndex(index)
    }

    const onAddPost = () => {
        changeCurrentIndex(10)
    }

    const onApplyPost = () => {
        setCurrentIndex(2)
    }

    const onSavedPost = () => {
        setCurrentIndex(1)
    }

    return (
        <Column className='max-w-7xl mx-auto gap-x-8 gap-y-8 pt-20 pb-12 sm:pt-24 sm:pb-16 lg:pt-28 lg:pb-24 xl:flex-row'>
            <Column className='w-full sm:max-w-xs sticky top-28 gap-y-8'>
                <Card className='w-full p-0 shadow-lg shadow-gray-200'>
                    <Column>
                        <Column className='px-7 py-6 gap-y-2'>
                            <Headline3 className='line-clamp-1'>
                                Halo, {user.name.split(' ')[0]}
                            </Headline3>
                            <Text>
                                Belum Ada Postingan
                            </Text>
                        </Column>
                        <HorizontalDivider removeMargin />
                        <button onClick={() => changeCurrentIndex(0)} className={`flex flex-row gap-x-4 pl-6 pr-8 py-4 border-l-[3px] font-semibold font-manrope text-base text-left transition-all ${currentIndex === 0 || currentIndex === 10 ? 'border-l-indigo-600 text-indigo-600' : 'border-l-transparent text-gray-800'} hover:bg-slate-50`}>
                            <TbCarouselVertical className='h-6 w-6' />
                            Semua Postingan
                        </button>
                        <HorizontalDivider removeMargin />
                        <button onClick={() => changeCurrentIndex(2)} className={`flex flex-row gap-x-4 pl-6 pr-8 py-4 border-l-[3px] font-semibold font-manrope text-base text-left transition-all ${currentIndex === 2 ? 'border-l-indigo-600 text-indigo-600' : 'border-l-transparent text-gray-800'} hover:bg-slate-50`}>
                            <TbArchive className='h-6 w-6' />
                            Postingan Tertarik
                        </button>
                        <HorizontalDivider removeMargin />
                        <button onClick={() => changeCurrentIndex(1)} className={`flex flex-row gap-x-4 pl-6 pr-8 py-4 border-l-[3px] font-semibold font-manrope text-base text-left transition-all ${currentIndex === 1 ? 'border-l-indigo-600 text-indigo-600' : 'border-l-transparent text-gray-800'} hover:bg-slate-50`}>
                            <TbCards className='h-6 w-6' />
                            Postingan Saya
                        </button>
                        <HorizontalDivider removeMargin />
                        <Column className='px-6 pt-5 pb-6 gap-y-5'>
                            <Row className='justify-between items-center'>
                                <Title>
                                    Filter Postingan
                                </Title>
                                <TbListDetails className='h-5 w-5' />
                            </Row>
                            <SelectView
                                width='w-full'
                                label='Jenis Postingan'
                                initial={initialType}
                                options={opsiType}
                                bgColor='bg-slate-50'
                                py='py-3'
                                pl='pl-4'
                                pr='pr-3'
                                zindex='z-20'
                                onChangeOptions={(value) => setInitialType(value)} />
                            <SelectView
                                width='w-full'
                                label='Kategori Postingan'
                                initial={initialCategory}
                                options={opsiCategory}
                                bgColor='bg-slate-50'
                                py='py-3'
                                pl='pl-4'
                                pr='pr-3'
                                onChangeOptions={(value) => setInitialCategory(value)} />
                        </Column>
                    </Column>
                </Card>
            </Column>
            <Column className='w-full'>
                <AnimatePresence mode='wait' initial={false}>
                    <TransitionPage key={currentIndex} horizontal={true}>
                        {currentIndex === 0 && <WAlmB2BPost typePost={initialType.value} categoryPost={initialCategory.value} onChangeIndex={() => changeCurrentIndex(2)} onAddPost={onAddPost} onApplyPost={onApplyPost} />}
                        {currentIndex === 1 && <WAlmB2BMyPost typePost={initialType.value} categoryPost={initialCategory.value} />}
                        {currentIndex === 2 && <WAlmB2BApply />}
                        {currentIndex === 10 && <WAlmB2bCreate onSaved={onSavedPost} />}
                    </TransitionPage>
                </AnimatePresence>
            </Column>
        </Column>
    )
}

export default AlmBusinessPage