import React, { useEffect, useState } from 'react'
import { TbAlertHexagon, TbClick, TbHeart, TbNews, TbProgress, TbShieldCheck } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import { Column, GridCol, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { BodyText, CaptionText, Headline2Text, LargeHeadlineText, TitleText } from '../../../components/interfaces/text'
import { getGrafikOverviewRecognitionController, getOverviewRecognitionController, getRekapOverviewRecognitionController } from '../../../controllers/admin_controllers'
import { getViewDisplay } from '../../../shared/lib'
import { AreaChart, DotSeparated } from '../../../shared/ui'
import TableRecapRecognition from './tables/table_recap_recognition'

function AdmOverviewRecognitionPage() {

  const result = useLoaderData()

  const [listOverview, setListOverview] = useState([])
  const [listSeries, setListSeries] = useState([])
  const [listRekap, setListRekap] = useState([])

  const listLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']
  const listColors = ['#818DF8', '#F472B5', '#38BEF8']

  const getOverviewFromLoader = () => {
    if (result.oview.status === 'fulfilled') {
      const overview = result.oview.value
      if (overview.status) {
        const overviewTemp = [
          {
            icon: <TbNews className={'h-6 w-6 text-indigo-500'} />,
            label: 'Total Berita',
            value: overview.totalPost,
          },
          {
            icon: <TbHeart className={'h-6 w-6 text-pink-500'} />,
            label: 'Total Menyukai',
            value: overview.totalLike,
          },
          {
            icon: <TbClick className={'h-6 w-6 text-sky-500'} />,
            label: 'Total Kunjungan',
            value: overview.totalView,
          },
          {
            icon: <TbShieldCheck className={'h-6 w-6 text-green-500'} />,
            label: 'Berita Terverifikasi',
            value: overview.totalAcc,
          },
          {
            icon: <TbProgress className={'h-6 w-6 text-orange-500'} />,
            label: 'Menunggu Verifikasi',
            value: overview.totalPending,
          },
          {
            icon: <TbAlertHexagon className={'h-6 w-6 text-red-500'} />,
            label: 'Berita Ditolak',
            value: overview.totalReject,
          },
        ]
        setListOverview(overviewTemp)
      }
    }
    if (result.chart.status === 'fulfilled') {
      const chart = result.chart.value
      if (chart.status) {
        setListSeries(chart.data)
      }
    }
    if (result.rekap.status === 'fulfilled') {
      const rekap = result.rekap.value
      if (rekap.status) {
        setListRekap(rekap.data)
      }
    }
  }

  useEffect(() => {
    getOverviewFromLoader()
  }, [])

  return (
    <Column className={'p-4 gap-y-5 sm:p-8'}>
      <Headline2Text className={'font-semibold'}>
        Ringkasan Berita
      </Headline2Text>
      <GridCol className='grid-cols-1 gap-x-5 gap-y-5 sm:grid-cols-2 xl:grid-cols-3'>
        {
          listOverview.map((overview, idx) => {
            return (
              <Card
                key={idx}
                className={'w-full sm:p-6'}>
                <Column className={'gap-y-5'}>
                  <Row className={'gap-x-2 justify-between'}>
                    <BodyText className={'font-medium text-gray-500'}>
                      {overview.label}
                    </BodyText>
                    {overview.icon}
                  </Row>
                  <LargeHeadlineText className='font-normal font-inter'>
                    {getViewDisplay(overview.value)}
                  </LargeHeadlineText>
                </Column>
              </Card>
            )
          })
        }
      </GridCol>
      <Card className={'w-full p-0'}>
        <Column className={'gap-y-2'}>
          <Column className={'p-4 gap-y-1 sm:p-6'}>
            <TitleText>
              Aktivitas Berita
            </TitleText>
            <Row>
              {listSeries.map((series, idx) => {
                return <Row key={idx} className='w-fit'>
                  <CaptionText>
                    {series.name}
                  </CaptionText>
                  {idx < (listSeries.length - 1) && <DotSeparated />}
                </Row>
              })}
            </Row>
          </Column>
          <div className={'px-3 pb-6'}>
            <AreaChart
              height={300}
              lineWidth={2.3}
              series={listSeries}
              label={listLabels}
              listColors={listColors} />
          </div>
        </Column>
      </Card>
      <Card className={'w-full'}>
        <Column className={'gap-y-5'}>
          <Column className={'gap-y-1'}>
            <TitleText>
              Rekap Berita
            </TitleText>
            <CaptionText>
              Rekapitulasi berita alumni berdasarkan Program Studi
            </CaptionText>
          </Column>
          <TableRecapRecognition listRekap={listRekap} />
        </Column>
      </Card>
    </Column>
  )
}

export default AdmOverviewRecognitionPage

export const getAdmOviewRecognition = async () => {
  const [oview, chart, rekap] = await Promise.allSettled([
    getOverviewRecognitionController(),
    getGrafikOverviewRecognitionController(),
    getRekapOverviewRecognitionController()
  ])
  return {
    'oview': oview,
    'chart': chart,
    'rekap': rekap
  }
}