import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import { getAdmRekapPenilaianRespondenController } from "../../../../controllers/admin_controllers";
import { Tooltip } from "../../../../components/interfaces/utility";
import { Caption } from "../../../../shared/ui";

function TablePenilaianRespondenComponents({year}) {
  const [labelKategori, setLabelKategori] = useState([])
  const [labelPenilaian, setLabelPenilaian] = useState([])
  const [data, setData] = useState(null)

  const getOvTabelPenilaian=async()=>{
    const props ={
      tahun:year.value,
    }
    const result = await getAdmRekapPenilaianRespondenController(props)
    if(result.status){
      setLabelKategori(result.label_kategori)
      setLabelPenilaian(result.label_penilaian)
      setData(result.data)
    }
  }
  useEffect(()=>{
      getOvTabelPenilaian()
  },[year])
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>No</TableHeadCell>
          <TableHeadCell>Aspek Penilaian</TableHeadCell>
          {labelPenilaian.map((label, id)=>(
            <TableHeadCell
            key={id}
            >
              {label}
            </TableHeadCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className="divide-y-[1px] divide-gray-200">
        {data && Object.entries(data).map(([key, values], id)=>(
          <TableRow
          key={id}
          className={`${id % 2 === 1 ? "bg-slate-50" : "bg-white"}`}
          >
            <TableCell className={'text-center'}>{id + 1}</TableCell>
            <TableCell>
              <Tooltip message={`${labelKategori[id]}`}>
                <Caption className={'line-clamp-1 text-gray-800'}>
                  {labelKategori[id]}
                </Caption>
              </Tooltip>
            </TableCell>
            {values.map((value,index)=>(
              <TableCell key={index} className={'text-center'}>
                <Tooltip message={`${value}${'%'}`}>
                  <Caption className={'line-clamp-1 text-gray-800'}>
                    {value!=null? value:0}{"%"}
                  </Caption>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TablePenilaianRespondenComponents;
