import React from "react";
import { Headline2Text } from "../../components/interfaces/text";

const MitDashboardPage = () => {
  return (
    <>
      <div className="p-4 gap-y-8 sm:p-8">
        <Headline2Text className={"font-semibold"}>Dashboard</Headline2Text>
      </div>
    </>
  );
};

export default MitDashboardPage;
