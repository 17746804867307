import React from 'react'
import { TbArrowRight, TbChecks } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { ButtonHover, IconButton, Image, TagView } from '../../../../../shared/ui'

function RowTableAcceptedMitra({ idx, mitra, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow
      striped
      onClick={() => handleClick(mitra)}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <Image
          src={mitra.logo}
          className='aspect-square max-w-[3rem]'
          imgClasName='aspect-square object-scale-down mix-blend-multiply'
          hoverScale={false}
          removeBgColor />
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {mitra.nama_mitra}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='line-clamp-2'>
          {mitra.deskripsi}
        </BodyText>
      </TableCell>
      <TableCell>
        {mitra.pic_name}
      </TableCell>
      <TableCell>
        <TagView
          className={'mx-auto'}
          leadingIcon={
            <TbChecks className={'h-4 w-4'} />
          }
          label='Aktif'
          color='green' />
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            className='p-1.5'
            color='indigo'
            bordered
            onClick={() => handleClick(mitra)}>
            <TbArrowRight className='h-5 w-5' />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableAcceptedMitra