import React, { useState } from 'react'
import { TbMoodCry, TbTrash, TbUserCircle, TbX } from 'react-icons/tb'
import { searchUserRecognition } from '../controllers/apicontrollers'
import avatar2 from '../images/avatar2.png'
import { AlertDialog, Avatar, ButtonHover, Caption, Card, Column, GridCol, Headline3, IconButton, OutlineInput, Overline, Row, Text, TextButton } from '../shared/ui'
import CTagAlumni from './cards/ctag_alumni'

const WInputTagsRecognition = ({ onChangeTags }) => {

    const [listUser, setListUser] = useState([])
    const [listTagUser, setListTagUser] = useState([])
    const [listTagUserTemp, setListTagUserTemp] = useState([])
    const [showSearchDialog, setShowSearchDialog] = useState(false)
    const [deleteUser, setDeleteUser] = useState(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const showSearchUserDialog = () => {
        getSearchUser('')
        const currentTagUser = [...listTagUser]
        setListTagUserTemp(currentTagUser)
        setShowSearchDialog(true)
    }

    const onChangeTagRec = async (event) => {
        event.preventDefault();
        const query = event.target.value
        getSearchUser(query)
    }

    const getSearchUser = async (query) => {
        const result = await searchUserRecognition(query)
        if (result.status) {
            setListUser(result.data)
        }
    }

    const chooseTagUser = (user) => {
        const currentTagUser = [...listTagUserTemp]
        const searchUser = currentTagUser.filter((taguser) => taguser.id_user === user.id_user)
        if (searchUser.length > 0) {
            const newTagUser = currentTagUser.filter((taguser) => taguser.id_user !== user.id_user)
            setListTagUserTemp(newTagUser)
        } else {
            currentTagUser.unshift(user)
            setListTagUserTemp(currentTagUser)
        }
    }

    const selectTagUser = () => {
        const listTagUserFix = [...listTagUserTemp]
        setListTagUser(listTagUserFix)
        onChangeTags(listTagUserFix)
        setListTagUserTemp([])
        setShowSearchDialog(false)
    }

    const setSelectedDeleteUser = (user) => {
        setDeleteUser(user)
        setShowDeleteDialog(true)
    }

    const deleteUserFromTags = () => {
        const newTagAlumni = listTagUser.filter((user) => user.id_user !== deleteUser.id_user)
        setListTagUser(newTagAlumni)
        onChangeTags(newTagAlumni)
        setDeleteUser(null)
        setShowDeleteDialog(false)
    }

    return (
        <>
            <Column className='gap-1.5'>
                <Caption>Tag Alumni (opsional)</Caption>
                <Card className='w-full p-4 bg-slate-50'>
                    <Column>
                        <Row className='gap-4'>
                            <ButtonHover>
                                <IconButton onClick={showSearchUserDialog} color='custom' className='flex flex-col flex-shrink-0 gap-3 h-28 w-28 items-center justify-center border-[1px] rounded-lg bg-white' bordered>
                                    <TbUserCircle className='h-7 w-7 text-indigo-400 stroke-1.5' />
                                    <Caption className='text-center new-line'>
                                        {'Tambahkan\nAlumni'}
                                    </Caption>
                                </IconButton>
                            </ButtonHover>
                            {listTagUser.length > 0 && <Row className='gap-4 overflow-x-auto rounded-lg scrollbar-hide scroll-smooth'>
                                {listTagUser.map((user, idx) => {
                                    return (
                                        <Card key={idx} className='w-40 h-28 relative flex-shrink-0 p-2.5 rounded-lg'>
                                            <Column className='gap-2 my-auto'>
                                                <Avatar />
                                                <Column>
                                                    <Caption className='text-gray-800 font-manrope font-semibold truncate capitalize'>
                                                        {user.name.toLowerCase()}
                                                    </Caption>
                                                    <Overline>
                                                        {user.id_user}
                                                    </Overline>
                                                </Column>
                                            </Column>
                                            <IconButton onClick={() => setSelectedDeleteUser(user)} color='custom' className='absolute top-1.5 right-1.5 p-0 backdrop-blur-sm bg-black/30'>
                                                <TbX className='h-5 w-5 p-0.5 text-white' />
                                            </IconButton>
                                        </Card>
                                    )
                                })}
                            </Row>}
                        </Row>
                    </Column>
                </Card>
            </Column>
            <AlertDialog className='max-w-sm' show={showSearchDialog} onClose={() => setShowSearchDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 pt-10 pb-8 gap-8 items-center'>
                        <Card className='p-1.5 rounded-2xl bg-indigo-50/50 border-indigo-200/50'>
                            <TbUserCircle className='h-14 w-14 stroke-1.5 text-indigo-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Tandai Alumni
                            </Headline3>
                            <Text className='text-center'>
                                Alumni yang dipilih akan ditambahkan pada rekognisi yang akan dipublish
                            </Text>
                        </Column>
                    </Column>
                    {listTagUserTemp.length > 0 && <Column className='pt-3 pb-5 gap-1.5'>
                        <Caption className='px-5 text-gray-800 font-medium'>
                            {listTagUserTemp.length} Alumni terpilih:
                        </Caption>
                        <Row className='gap-2 overflow-x-auto scrollbar-hide px-5'>
                            {listTagUserTemp.map((user, idx) => {
                                return (
                                    <Row className='w-fit gap-2 shrink-0 bg-gray-100/50 border border-gray-200 pl-2.5 pr-2 py-1.5 rounded-lg'>
                                        <Caption key={idx} className='shrink-0 text-gray-800'>
                                            {user.name}
                                        </Caption>
                                        <IconButton onClick={() => chooseTagUser(user)} className='p-0.5 my-auto' color='red'>
                                            <TbX className='h-3 w-3' />
                                        </IconButton>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>}
                    <Column className='px-5 py-6 gap-4'>
                        <OutlineInput id='query' inputWidth='w-full' placeholder='Cari nik atau nama' onChange={onChangeTagRec} />
                        {listUser.length > 0 ? <Card className='w-full p-0 overflow-hidden'>
                            <Column className='max-h-72 divide-y overflow-y-auto scrollbar-hide'>
                                {listUser.map((user, idx) => {
                                    return (
                                        <CTagAlumni key={idx} user={user} listTagUser={listTagUserTemp} onClick={() => chooseTagUser(user)} />
                                    )
                                })}
                            </Column>
                        </Card> : <Column className='items-center gap-2'>
                            <TbMoodCry className='h-12 w-12 stroke-1.5 text-gray-500' />
                            <Caption className='text-center'>
                                Hasil pencarian tidak ditemukan
                            </Caption>
                        </Column>}
                    </Column>
                    <GridCol className='gap-0 divide-x divide-gray-200'>
                        <TextButton onClick={() => { setShowSearchDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                            Batal
                        </TextButton>
                        <TextButton disabled={listTagUserTemp.length > 0 ? false : true} onClick={selectTagUser} color='indigo' className='w-full py-4 text-base hover:bg-indigo-50/50'>
                            Pilih Alumni
                        </TextButton>
                    </GridCol>
                </Column>
            </AlertDialog>
            <AlertDialog className='max-w-xs' show={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 pt-10 pb-8 gap-8 items-center'>
                        <Card className='p-1.5 rounded-2xl bg-red-50/50 border-red-200/50'>
                            <TbTrash className='h-14 w-14 stroke-1.5 text-red-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Hapus Tag Alumni
                            </Headline3>
                            <Text className='text-center'>
                                Apakah Anda yakin ingin menghapus Alumni dari daftar Tag Rekognisi?
                            </Text>
                        </Column>
                        {deleteUser !== null && <Card className='w-full px-2 py-2 bg-gray-50'>
                            <Row className='gap-2.5'>
                                <Avatar src={avatar2} />
                                <Column className='flex-1 my-auto gap-0.5'>
                                    <Caption className='text-gray-800 font-medium line-clamp-1'>
                                        {deleteUser.name}
                                    </Caption>
                                    <Overline>
                                        {deleteUser.id_user}
                                    </Overline>
                                </Column>
                            </Row>
                        </Card>}
                    </Column>
                    <GridCol className='gap-0 divide-x divide-gray-200'>
                        <TextButton onClick={() => { setShowDeleteDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                            Batal
                        </TextButton>
                        <TextButton onClick={deleteUserFromTags} color='red' className='w-full py-4 text-base hover:bg-red-50/50'>
                            Ya, Hapus
                        </TextButton>
                    </GridCol>
                </Column>
            </AlertDialog>
        </>
    )
}

export default WInputTagsRecognition
