import HTMLParse from "html-react-parser";
import React, { useState } from "react";
import { processCommentRecognition } from "../../../../controllers/apicontrollers";
import {
  AlertDialog,
  Avatar,
  Caption,
  Card,
  Column,
  DotSeparated,
  IconButton,
  LoadingDialog,
  Row,
  Subtitle,
} from "../../../../shared/ui";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import { TbMessageCircle, TbX } from "react-icons/tb";
import RowTableAcceptedComments from "./rows/row_Accepted_comments";
import toast from "react-hot-toast";
import CCommentsRecognition from "../../../../components/list/item/item_comment_recognition";

function TableAcceptedComments({ listComments, page, limit, onShort }) {
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [comments, setComments] = useState(null);
  const [timeAgo, setTimeAgo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [komentar, setKomentar] = useState(null);

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const closeDialog = () => {
    setShowDialog();
  };

  const onHandleClick = (recognition) => {
    setComments(recognition);
    setKomentar(recognition.comments);
    setShowDialog(true);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  const processCurrentComment = async (idcom) => {
    setShowDialog(false);
    setLoading(true);
    const result = await processCommentRecognition(idcom);
    if (result.status) {
      setTimeout(() => {
        setLoading(false);
        toast.success("Processed komentar berhasil");
      }, 200);
    }
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Penulis</TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="title"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Judul Berita
            </TableHeadCell>
            <TableHeadCell>Komentar</TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody className={"border"}>
          {listComments.length > 0 ? (
            listComments.map((comment, idx) => {
              return (
                <RowTableAcceptedComments
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  comment={comment}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog
        className="max-w-4xl"
        show={showDialog}
        onClose={closeDialog}
      >
        <Column className="divide-y">
          {comments !== null && (
            <Row className="divide-x ">
              <Column className="w-full max-w-lg max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
                <Row className="gap-2.5 sticky top-0 bg-white p-6">
                  <Avatar />
                  <Column className="flex-1 my-auto">
                    <Caption className="font-bold font-manrope text-gray-800">
                      {comments.nama}
                    </Caption>
                    <Row>
                      <Caption>{comments.id_user}</Caption>
                      <DotSeparated />
                      <Caption>{timeAgo}</Caption>
                    </Row>
                  </Column>
                </Row>
                <Column className="px-6 gap-3 pb-6">
                  <Subtitle className="font-semibold">
                    {comments.title}
                  </Subtitle>
                  <Column className="text-base inter">
                    {HTMLParse(comments.body, options)}
                  </Column>
                </Column>
              </Column>
              <Column className="w-full max-w-sm min-h-[45rem] max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
                <Row className="gap-2.5 px-4 py-4 bg-white fixed top-0 z-10 border-b">
                  <Card className="rounded-full border-none bg-indigo-50 p-0 h-8 w-8 justify-center items-center">
                    <TbMessageCircle className="h-5 w-5 text-indigo-700" />
                  </Card>
                  <Subtitle className="font-semibold my-auto">
                    Komentar
                  </Subtitle>
                </Row>
                test123
                <Column className="px-4 pt-16 flex-col-reverse h-full overflow-y-auto scrollbar-hide scroll-smooth">
                  {komentar &&
                    komentar.map((comment, idx) => (
                      <CCommentsRecognition
                        key={idx}
                        comment={comment}
                        action={30}
                        onUndo={processCurrentComment}
                        komentar={komentar}
                      />
                    ))}
                </Column>
              </Column>
            </Row>
          )}
        </Column>
        <IconButton
          onClick={closeDialog}
          className="absolute top-1 right-1 z-10"
        >
          <TbX className="h-5 w-5" />
        </IconButton>
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
}

export default TableAcceptedComments;
