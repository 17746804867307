import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import {
  getSavedRecognition,
  getTaggedRecognition,
} from "../controllers/apicontrollers";
import AdminLayout from "../layouts/admin_layout";
import AlumniLayout from "../layouts/alumni_layout";
import MitraLayout from "../layouts/mitra_layout";
import KonselorLayout from "../layouts/konselor_layout";
import AdmAddEventPage from "../pages/admin/adm_add_event";
import AdmAddRecognitionPage from "../pages/admin/adm_add_recognition";
import AdmBusinessPage from "../pages/admin/adm_business";
import AdmCareerPage from "../pages/admin/adm_career";
import AdmCommentsPage from "../pages/admin/adm_comments";
import AdmConsultationPage from "../pages/admin/adm_consultation";
import AdmDashboardPage from "../pages/admin/adm_dashboard";
import AdmKonselorPage from "../pages/admin/adm_konselor";
import AdmLegalitasBusinessPage from "../pages/admin/adm_legalitas_business";
import AdmLowonganPage from "../pages/admin/adm_lowongan";
import AdmMitraPage from "../pages/admin/adm_mitra";
import AdmPenggunaLulusanPage from "../pages/admin/adm_pengguna_lulusan";
import AdmRecognitionPage from "../pages/admin/adm_recognition";
import AdmTracerPage from "../pages/admin/adm_tracer";
import AdmTracerIPTPage from "../pages/admin/adm_tracer_ipt";
import AdmEventPage, { getAdmEvent } from "../pages/admin/event/adm_event";
import AdmLokerCareerPage, {
  getAdmLokerByMitra,
} from "../pages/admin/karir/adm_loker_career";
import AdmOverviewCareerPage from "../pages/admin/karir/adm_oveview_career";
import AdmAcceptedCommentsPage, {
  getAdmAcceptedComments,
} from "../pages/admin/komentar/adm_accepted_comments";
import AdmProcessedCommentsPage, {
  getAdmProcessedComments,
} from "../pages/admin/komentar/adm_processed_comments";
import AdmRejectedCommentsPage, {
  getAdmRejectedComments,
} from "../pages/admin/komentar/adm_rejected_comments";
import AdmCounselorConsultationPage, {
  getAdmCounselorConsultation,
} from "../pages/admin/konselor/adm_counselor_consultation";
import AdmTopicConsultationPage, {
  getAdmTopicConsultation,
} from "../pages/admin/konselor/adm_topic_consultation";
import AdmAcceptedConsultationPage, {
  getAdmAcceptedConsultation,
} from "../pages/admin/konsultasi/adm_accepted_consultation";
import AdmProcessedConsultationPage, {
  getAdmProcessedConsultation,
} from "../pages/admin/konsultasi/adm_processed_consultation";
import AdmRejectedConsultationPage, {
  getAdmRejectedConsultation,
} from "../pages/admin/konsultasi/adm_rejected_consultation";
import AdmAcceptedMitraPage, {
  getAdmAcceptedMitra,
} from "../pages/admin/mitra/adm_accepted_mitra";
import AdmProcessedMitraPage, {
  getAdmProcessedMitra,
} from "../pages/admin/mitra/adm_processed_mitra";
import AdmRejectedMitraPage, {
  getAdmRejectedMitra,
} from "../pages/admin/mitra/adm_rejected_mitra";
import AdmOverviewPenggunaPage, {
  getOvDataResponden,
} from "../pages/admin/pengguna_lulusan/adm_overview_pengguna";
import AdmSurveiPenggunaPage, {
  getAdmSurveiPengguna,
} from "../pages/admin/pengguna_lulusan/adm_survei_pengguna";
import AdmAcceptedRecognitionPage, {
  getAdmAcceptedRecognition,
} from "../pages/admin/rekognisi/adm_accepted_recognition";
import AdmOverviewRecognitionPage, {
  getAdmOviewRecognition,
} from "../pages/admin/rekognisi/adm_overview_recognition";
import AdmProcessedRecognitionPage, {
  getAdmProcessedRecognition,
} from "../pages/admin/rekognisi/adm_processed_recognition";
import AdmRejectedRecognitionPage, {
  getAdmRejectedRecognition,
} from "../pages/admin/rekognisi/adm_rejected_recognition";
import AdmProdiCertificatePage, {
  getAdmProdiCertificate,
} from "../pages/admin/sertifikat/adm_prodi_certificate";
import AdmUnivCertificatePage, {
  getAdmUnivCertificate,
} from "../pages/admin/sertifikat/adm_univ_certificate";
import AdmKuesionerTracerPage from "../pages/admin/tracer/adm_kuesioner_tracer";
import AdmOverviewTracerPage, {
  getAdmOviewTracer,
} from "../pages/admin/tracer/adm_overview_tracer";
import AdmSaranTracerPage, {
  getAdmSaranTracer,
} from "../pages/admin/tracer/adm_saran_tracer";
import AdmWisudaTracerPage, {
  getAdmWisudaTracer,
} from "../pages/admin/tracer/adm_wisuda_tracer";
import AdmNotificationPage, {
  getAdmNotifications,
} from "../pages/admin/notifikasi/adm_notification_admin";
import InputTracerAlumni from "../pages/alumni/alm_input_tracer";
import AlmTracerPage from "../pages/alumni/alm_tracer";
import AlmApplyProfilePage, {
  getApplyLowongan,
} from "../pages/alumni/profile/alm_apply_profile";
import AlmDetailProfilePage, {
  getProfileAlumni,
} from "../pages/alumni/profile/alm_detail_profile";
import AlmDocumentProfilePage, {
  getDocumentAlumni,
} from "../pages/alumni/profile/alm_doc_profile";
import AlmNotificationProfilePage, {
  getNotifications,
} from "../pages/alumni/profile/alm_notification_profile";
import AlmProfilePage from "../pages/alumni/profile/alm_profile";
import AlmChangePassword from "../pages/alumni/alm_change_password";
import AlmSavedProfilePage from "../pages/alumni/profile/alm_saved_profile";
import AlmTaggedProfilePage from "../pages/alumni/profile/alm_tagged_profile";
import DetailEventPage, { getDetailEvent } from "../pages/detail_event";
import DetailRecognitionPage, {
  getDetailRecognition,
} from "../pages/detail_recognition";
import EventPage, { getEvent } from "../pages/event";
import KonChatConsultationPage, {
  getKonChatConsultation,
} from "../pages/konselor/kon_chat_consultation";
import KonCompleteConsultationPage, {
  getKonCompleteConsultation,
} from "../pages/konselor/kon_complete_consultation";
import KonDashboardPage from "../pages/konselor/kon_dashboard";
import KonNotificationPage, {
  getKonNotifications,
} from "../pages/konselor/kon_notification";
import KonOngoingConsultationPage, {
  getKonOngoingConsultation,
} from "../pages/konselor/kon_ongoing_consultation";
import KonPendingConsultationPage, {
  getKonPendingConsultation,
} from "../pages/konselor/kon_pending_consultation";
import KonPlotingPage from "../pages/konselor/kon_ploting";
import LandingPage from "../pages/landing";
import LoginPage from "../pages/login";
import MitApplyCareerPage from "../pages/mitra/mit_applycareer";
import MitAddCareerPage from "../pages/mitra/mit_addcareer";
import NotFoundPage from "../pages/notfound";
import RecognitionPage, { getRecognition } from "../pages/recognition";
import RegisterMitraPage from "../pages/register";
import SuccessRegMitraPage from "../pages/success";
import KuesionerLulusanPage from "../pages/kuesioner-lulusan";
import TestPagee from "../pages/test-page";
import AdmPrivateRoutes from "../routes/admprivate";
import AlmPrivateRoutes from "../routes/almprivate";
import KonPrivateRoutes from "../routes/konprivate";
import {
  admAcceptedCommentsRoute,
  admAcceptedConsultationRoute,
  admAcceptedMitraRoute,
  admAcceptedRecognitionRoute,
  admAddCareerRoute,
  admAddEventRoute,
  admAddRecognitionRoute,
  admApplyCareerRoute,
  admBusinessRoute,
  admCareerRoute,
  admCommentsRoute,
  admConsultationRoute,
  admCounselorConsultationRoute,
  admEventRoute,
  admKonselorRoute,
  admKuesionerTracerRoute,
  admLegalitasBusinessRoute,
  admLokerCareerRoute,
  admLowonganRoute,
  admMitraRoute,
  admNotificationRoute,
  admOverviewCareerRoute,
  admOverviewPenggunaLulusanRoute,
  admOverviewRecognitionRoute,
  admOverviewTracerRoute,
  admPenggunaLulusanRoute,
  admProcessedCommentsRoute,
  admProcessedConsultationRoute,
  admProcessedMitraRoute,
  admProcessedRecognitionRoute,
  admProdiCertificateRoute,
  admRecognitionRoute,
  admRejectedCommentsRoute,
  admRejectedConsultationRoute,
  admRejectedMitraRoute,
  admRejectedRecognitionRoute,
  admRootRoute,
  admSaranTracerRoute,
  admSurveiPenggunaLulusanRoute,
  admTopicConsultationRoute,
  admTracerIPTRoute,
  admTracerLowonganRoute,
  admTracerRoute,
  admUnivCertificateRoute,
  admWisudaTracerRoute,
  almApplyProfileRoute,
  almChangePasswordAlumniRoute,
  almChatConsultationRoute,
  almConsultationRoute,
  almCreateConsultationRoute,
  almDocProfileRoute,
  almInputTracerRoute,
  almNotificationProfileRoute,
  almProfileRoute,
  almRejectedConsultationRoute,
  almSavedProfileRoute,
  almTaggedProfileRoute,
  almTracerRoute,
  almWaitingConsultationRoute,
  careerRoute,
  changePasswordRoute,
  detailEventRoute,
  detailRecognitionRoute,
  eventRoute,
  forgotPasswordRoute,
  konChatConsultationRoute,
  konCompleteConsultationRoute,
  konConsultationRoute,
  konNotificationRoute,
  konPendingConsultationRoute,
  konPlotingRoute,
  konRootRoute,
  landingRoute,
  loginRoute,
  mitAddCareerRoute,
  mitApplyCareerRoute,
  mitCareerRoute,
  mitNotificationRoute,
  mitRootRoute,
  notFoundRoute,
  proAddRecognitionRoute,
  proRecognitionRoute,
  proRootRoute,
  proTracerRoute,
  prodNotificationRoute,
  recognitionRoute,
  registerMitraRoute,
  successRegMitraRoute,
  surveiLulusanRoute,
  testRoute,
} from "./variable";
import ProPrivateRoutes from "../routes/proprivate";
import ProdiLayout from "../layouts/prodi_layout";
import ProDashboardPage from "../pages/prodi/pro_dashboard";
import ProRecognitionPage from "../pages/prodi/pro_recognition";
import ProAddRecognitionPage from "../pages/prodi/pro_add_recognition";
import ProTracerPage from "../pages/prodi/pro_tracer";
import { getProdOvTracer } from "../components/overview/ov_tracer";
import MitPrivateRoutes from "../routes/mitprivate";
import MitDashboardPage from "../pages/mitra/mit_dashboard";
import MitCareerPage from "../pages/mitra/mit_career";
import MitNotificationPage, {
  getNotificationsMitra,
} from "../pages/mitra/mit_notification_mit";
import ProdNotificationPage, {
  getProdNotifications,
} from "../pages/prodi/notfikasi/prod_notification_prodi";
import CareerPage, { getFilterCareer } from "../pages/career";
import AdmAddCareerPage from "../pages/admin/karir/adm_add_career";
import ForgotPasswordPage from "../pages/forgot_password";
import ChangePasswordPage from "../pages/change_password";
import AlmAcceptedConsultationPage, { getAcceptedConsultation } from "../pages/alumni/consultation/alm_accepted_consultation";
import AlmChatConsultationPage, { getChatConsultation } from "../pages/alumni/consultation/alm_chat_consultation";
import AlmWaitingConsultationPage, { getWaitingConsultation } from "../pages/alumni/consultation/alm_waiting_consultation";
import AlmRejectedConsultationPage, { getRejectedConsultation } from "../pages/alumni/consultation/alm_rejected_consultation";
import AlmCreateConsultationPage, { getFilterConsultation } from "../pages/alumni/consultation/alm_create_consultation";
import AlmConsultationPage from "../pages/alumni/consultation/alm_consultation";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route exact path={landingRoute} element={<AlumniLayout />}>
        <Route exact path={landingRoute} element={<LandingPage />} />
        <Route
          exact
          path={recognitionRoute}
          element={<RecognitionPage />}
          loader={getRecognition}
        />
        <Route
          exact
          path={detailRecognitionRoute}
          element={<DetailRecognitionPage />}
          loader={getDetailRecognition}
        />
        <Route
          exact
          path={eventRoute}
          element={<EventPage />}
          loader={getEvent}
        />
        <Route
          exact
          path={detailEventRoute}
          element={<DetailEventPage />}
          loader={getDetailEvent}
        />
        {/* <Route exact path={careerRoute} element={<CareerPage />} loader={getFilterCareer} /> */}
        <Route
          exact
          path={careerRoute}
          element={<CareerPage />}
          loader={getFilterCareer} />
        <Route element={<AlmPrivateRoutes />}>
          {/* <Route exact path={almConsultationRoute} element={<AlmConsultationPage />}>
            <Route
              exact
              path={almConsultationRoute}
              element={<AlmAcceptedConsultationPage />}
              loader={getAcceptedConsultation} />
            <Route
              exact
              path={almChatConsultationRoute}
              element={<AlmChatConsultationPage />}
              loader={getChatConsultation} />
            <Route
              exact
              path={almWaitingConsultationRoute}
              element={<AlmWaitingConsultationPage />}
              loader={getWaitingConsultation} />
            <Route
              exact
              path={almRejectedConsultationRoute}
              element={<AlmRejectedConsultationPage />}
              loader={getRejectedConsultation} />
            <Route
              exact
              path={almCreateConsultationRoute}
              element={<AlmCreateConsultationPage />}
              loader={getFilterConsultation} />
          </Route> */}
          <Route exact path={almTracerRoute} element={<AlmTracerPage />} />
          <Route
            exact
            path={almInputTracerRoute}
            element={<InputTracerAlumni />}
          />
          {/* <Route exact path={almBusinessRoute} element={<AlmBusinessPage />} /> */}
          <Route exact path={almProfileRoute} element={<AlmProfilePage />}>
            <Route
              exact
              path={almProfileRoute}
              element={<AlmDetailProfilePage />}
              loader={getProfileAlumni}
            />
            <Route
              exact
              path={almChangePasswordAlumniRoute}
              element={<AlmChangePassword />}
            />
            <Route
              exact
              path={almDocProfileRoute}
              element={<AlmDocumentProfilePage />}
              loader={getDocumentAlumni}
            />
            <Route
              exact
              path={almApplyProfileRoute}
              element={<AlmApplyProfilePage />}
              loader={getApplyLowongan}
            />
            <Route
              exact
              path={almTaggedProfileRoute}
              element={<AlmTaggedProfilePage />}
              loader={getTaggedRecognition}
            />
            <Route
              exact
              path={almSavedProfileRoute}
              element={<AlmSavedProfilePage />}
              loader={getSavedRecognition}
            />
            <Route
              exact
              path={almNotificationProfileRoute}
              element={<AlmNotificationProfilePage />}
              loader={getNotifications}
            />
          </Route>
        </Route>
        <Route
          exact
          path={careerRoute}
          element={<CareerPage />}
          loader={getFilterCareer}
        />
      </Route>
      <Route element={<AdmPrivateRoutes />}>
        <Route exact path={admRootRoute} element={<AdminLayout />}>
          <Route exact path={admRootRoute} element={<AdmDashboardPage />} />
          <Route
            exact
            path={admOverviewRecognitionRoute}
            element={<AdmOverviewRecognitionPage />}
            loader={getAdmOviewRecognition}
          />
          <Route
            exact
            path={admAcceptedRecognitionRoute}
            element={<AdmAcceptedRecognitionPage />}
            loader={getAdmAcceptedRecognition}
          />
          <Route
            exact
            path={admProcessedRecognitionRoute}
            element={<AdmProcessedRecognitionPage />}
            loader={getAdmProcessedRecognition}
          />
          <Route
            exact
            path={admRejectedRecognitionRoute}
            element={<AdmRejectedRecognitionPage />}
            loader={getAdmRejectedRecognition}
          />
          <Route
            exact
            path={admRecognitionRoute}
            element={<AdmRecognitionPage />}
          />
          <Route
            exact
            path={admAddRecognitionRoute}
            element={<AdmAddRecognitionPage />}
          />
          <Route exact path={admCommentsRoute} element={<AdmCommentsPage />} />
          <Route
            exact
            path={admAcceptedCommentsRoute}
            element={<AdmAcceptedCommentsPage />}
            loader={getAdmAcceptedComments}
          />
          <Route
            exact
            path={admProcessedCommentsRoute}
            element={<AdmProcessedCommentsPage />}
            loader={getAdmProcessedComments}
          />
          <Route
            exact
            path={admRejectedCommentsRoute}
            element={<AdmRejectedCommentsPage />}
            loader={getAdmRejectedComments}
          />
          <Route
            exact
            path={admEventRoute}
            element={<AdmEventPage />}
            loader={getAdmEvent}
          />
          <Route exact path={admAddEventRoute} element={<AdmAddEventPage />} />
          <Route exact path={admCareerRoute} element={<AdmCareerPage />} />
          <Route
            exact
            path={admAddCareerRoute}
            element={<AdmAddCareerPage />}
          />
          <Route exact path={admTracerRoute} element={<AdmTracerPage />} />
          <Route
            exact
            path={admOverviewCareerRoute}
            element={<AdmOverviewCareerPage />}
          />
          <Route
            exact
            path={admLokerCareerRoute}
            element={<AdmLokerCareerPage />}
            loader={getAdmLokerByMitra}
          />
          <Route
            exact
            path={admApplyCareerRoute}
            element={<MitApplyCareerPage />}
          />
          <Route exact path={admLowonganRoute} element={<AdmLowonganPage />} />
          <Route exact path={admMitraRoute} element={<AdmMitraPage />} />
          <Route
            exact
            path={admAcceptedMitraRoute}
            element={<AdmAcceptedMitraPage />}
            loader={getAdmAcceptedMitra}
          />
          <Route
            exact
            path={admProcessedMitraRoute}
            element={<AdmProcessedMitraPage />}
            loader={getAdmProcessedMitra}
          />
          <Route
            exact
            path={admRejectedMitraRoute}
            element={<AdmRejectedMitraPage />}
            loader={getAdmRejectedMitra}
          />
          <Route
            exact
            path={admConsultationRoute}
            element={<AdmConsultationPage />}
          />
          <Route
            exact
            path={admNotificationRoute}
            element={<AdmNotificationPage />}
            loader={getAdmNotifications}
          />
          <Route
            exact
            path={admAcceptedConsultationRoute}
            element={<AdmAcceptedConsultationPage />}
            loader={getAdmAcceptedConsultation}
          />
          <Route
            exact
            path={admProcessedConsultationRoute}
            element={<AdmProcessedConsultationPage />}
            loader={getAdmProcessedConsultation}
          />
          <Route
            exact
            path={admRejectedConsultationRoute}
            element={<AdmRejectedConsultationPage />}
            loader={getAdmRejectedConsultation}
          />
          <Route
            exact
            path={admCounselorConsultationRoute}
            element={<AdmCounselorConsultationPage />}
            loader={getAdmCounselorConsultation}
          />
          <Route
            exact
            path={admTopicConsultationRoute}
            element={<AdmTopicConsultationPage />}
            loader={getAdmTopicConsultation}
          />
          <Route exact path={admKonselorRoute} element={<AdmKonselorPage />} />
          <Route exact path={admTracerRoute} element={<AdmTracerPage />} />
          <Route
            exact
            path={admOverviewTracerRoute}
            element={<AdmOverviewTracerPage />}
            loader={getAdmOviewTracer}
          />
          <Route
            exact
            path={admKuesionerTracerRoute}
            element={<AdmKuesionerTracerPage />}
          />
          <Route
            exact
            path={admWisudaTracerRoute}
            element={<AdmWisudaTracerPage />}
            loader={getAdmWisudaTracer}
          />
          <Route
            exact
            path={admSaranTracerRoute}
            element={<AdmSaranTracerPage />}
            loader={getAdmSaranTracer}
          />
          <Route
            exact
            path={admPenggunaLulusanRoute}
            element={<AdmPenggunaLulusanPage />}
          />
          <Route
            exact
            path={admOverviewPenggunaLulusanRoute}
            element={<AdmOverviewPenggunaPage />}
            loader={getOvDataResponden}
          />
          <Route
            exact
            path={admSurveiPenggunaLulusanRoute}
            element={<AdmSurveiPenggunaPage />}
            loader={getAdmSurveiPengguna}
          />
          <Route exact path={admBusinessRoute} element={<AdmBusinessPage />} />
          <Route
            exact
            path={admLegalitasBusinessRoute}
            element={<AdmLegalitasBusinessPage />}
          />
          <Route
            exact
            path={admUnivCertificateRoute}
            element={<AdmUnivCertificatePage />}
            loader={getAdmUnivCertificate}
          />
          <Route
            exact
            path={admProdiCertificateRoute}
            element={<AdmProdiCertificatePage />}
            loader={getAdmProdiCertificate}
          />
          <Route
            exact
            path={admTracerIPTRoute}
            element={<AdmTracerIPTPage />}
          />
        </Route>
      </Route>
      <Route element={<KonPrivateRoutes />}>
        <Route exact path={konRootRoute} element={<KonselorLayout />}>
          <Route exact path={konRootRoute} element={<KonDashboardPage />} />
          <Route exact path={konPlotingRoute} element={<KonPlotingPage />} />
          <Route
            exact
            path={konConsultationRoute}
            element={<KonOngoingConsultationPage />}
            loader={getKonOngoingConsultation}
          />
          <Route
            exact
            path={konChatConsultationRoute}
            element={<KonChatConsultationPage />}
            loader={getKonChatConsultation}
          />
          <Route
            exact
            path={konPendingConsultationRoute}
            element={<KonPendingConsultationPage />}
            loader={getKonPendingConsultation}
          />
          <Route
            exact
            path={konCompleteConsultationRoute}
            element={<KonCompleteConsultationPage />}
            loader={getKonCompleteConsultation}
          />
          <Route
            exact
            path={konNotificationRoute}
            element={<KonNotificationPage />}
            loader={getKonNotifications}
          />
        </Route>
      </Route>
      <Route element={<ProPrivateRoutes />}>
        <Route exact path={proRootRoute} element={<ProdiLayout />}>
          <Route exact path={proRootRoute} element={<ProDashboardPage />} />
          <Route
            exact
            path={proRecognitionRoute}
            element={<ProRecognitionPage />}
          />
          <Route
            exact
            path={proAddRecognitionRoute}
            element={<ProAddRecognitionPage />}
          />
          <Route
            exact
            path={proTracerRoute}
            element={<ProTracerPage />}
            loader={getProdOvTracer}
          />
          <Route
            exact
            path={prodNotificationRoute}
            element={<ProdNotificationPage />}
            loader={getProdNotifications}
          />
        </Route>
      </Route>
      <Route element={<MitPrivateRoutes />}>
        <Route exact path={mitRootRoute} element={<MitraLayout />}>
          <Route exact path={mitRootRoute} element={<MitDashboardPage />} />
          <Route exact path={mitCareerRoute} element={<MitCareerPage />} />
          <Route
            exact
            path={mitNotificationRoute}
            element={<MitNotificationPage />}
            loader={getNotificationsMitra}
          />
          <Route
            exact
            path={mitAddCareerRoute}
            element={<MitAddCareerPage />}
          />
          <Route
            exact
            path={mitApplyCareerRoute}
            element={<MitApplyCareerPage />}
          />
        </Route>
      </Route>
      <Route exact path={loginRoute} element={<LoginPage />} />
      <Route
        exact
        path={forgotPasswordRoute}
        element={<ForgotPasswordPage />}
      />
      <Route
        exact
        path={changePasswordRoute}
        element={<ChangePasswordPage />}
      />
      <Route exact path={notFoundRoute} element={<NotFoundPage />} />
      <Route exact path={registerMitraRoute} element={<RegisterMitraPage />} />
      <Route
        exact
        path={surveiLulusanRoute}
        element={<KuesionerLulusanPage />}
      />
      <Route exact path={testRoute} element={<TestPagee />} />
      <Route
        exact
        path={successRegMitraRoute}
        element={<SuccessRegMitraPage />}
      />
    </Route>
  )
);
