import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { gLandingRecognition } from '../../atoms'
import { getRecognitionController } from '../../controllers/global_controllers'
import { GridCol } from '../../shared/ui'
import ItemRecognitionLanding from './item/item_recognition_landing'

const ListRecognitionLanding = () => {

    const [listRecognition, setListRecognition] = useRecoilState(gLandingRecognition)

    const getRecognition = async () => {
        const props = {
            page: 1,
            limit: 3,
        }
        const result = await getRecognitionController(props)
        if (result.status) {
            setListRecognition(result.data)
        }
    }

    useEffect(() => {
        getRecognition()
    }, [])

    return (
        <GridCol className='grid-cols-1 auto-rows-fr gap-0 sm:grid-cols-3 sm:gap-x-8 sm:gap-y-16'>
            {
                listRecognition.map((recognition, idx) => {
                    return (
                        <ItemRecognitionLanding key={idx} recognition={recognition} idx={idx} length={listRecognition.length - 1} />
                    )
                })
            }
        </GridCol>
    )
}

export default ListRecognitionLanding
