import randomstring from 'randomstring';
import React, { useState } from 'react';
import { Outlet, ScrollRestoration, useNavigation } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import KonAppBar from '../components/appbar/kon_appbar';
import KonSidebar from '../components/sidebar/kon_sidebar';
import { FullScreen, ToasterView } from '../shared/ui';

TopBarProgress.config({
    barColors: {
        '0': '#B71C1C',
        '0.6': '#D50000',
        '1.0': '#E53935'
    },
    barThickness: '3.0',
    shadowColor: '#ffff',
    shadowBlur: '0'
});

const KonselorLayout = () => {

    const { state } = useNavigation()

    const [showOnMobile, setShowOnMobile] = useState('0')

    const onShowSidebar = () => {
        setShowOnMobile(randomstring.generate(10))
    }

    return (
        <>
            {state === 'loading' && <TopBarProgress />}
            <KonAppBar onShowSidebar={onShowSidebar} />
            <KonSidebar showOnMobile={showOnMobile} />
            <FullScreen className={'relative py-14 lg:pl-72'}>
                <ScrollRestoration getKey={(location) => {
                    return location.pathname
                }} />
                <Outlet />
            </FullScreen>
            <ToasterView />
        </>
    )
}

export default KonselorLayout
