import React from 'react'
import { TbArrowRight } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { getViewDisplay } from '../../../../../shared/lib'
import { ButtonHover, IconButton } from '../../../../../shared/ui'

function RowTableEvent({ idx, event, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow
      striped
      onClick={() => handleClick(event)}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {event.title}
        </BodyText>
      </TableCell>
      <TableCell>
        <div className='text-gray-800 font-inter text-base line-clamp-2'
          dangerouslySetInnerHTML={{ __html: event.body.split('\n')[0] }} />
      </TableCell>
      <TableCell className={'text-center'}>
        {event.jenis_event === 'ALUMNI' ? 'Alumni' : 'Karir'}
      </TableCell>
      <TableCell className={'text-center'}>
        {getViewDisplay(event.viewer)}
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            bordered
            color='indigo'
            className={'p-1.5'}
            onClick={() => handleClick(event)}>
            <TbArrowRight className={'h-5 w-5'} />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableEvent