import React from 'react'
import { Column, DotSeparated, HorizontalDivider, Row, ShimmerAnimation, ShimmerItem } from '../../shared/ui'

const SAlmDetailEvent = () => {
    return (
        <ShimmerAnimation>
            <Column className={'gap-y-8'}>
                <Column className='gap-y-5'>
                    <Column className={'gap-y-3'}>
                        <ShimmerItem className={'w-full h-12 rounded-xl'} />
                        <ShimmerItem className={'w-[55%] h-12 rounded-xl'} />
                    </Column>
                    <ShimmerItem className={'w-36 h-5 rounded-xl'} />
                    <ShimmerItem className={'w-full h-auto aspect-video rounded-xl'} />
                    <Column className={'gap-y-2'}>
                        {Array.from({ length: 5 }, (_, idx) => {
                            return (
                                <ShimmerItem key={idx} className={`${idx < 4 ? 'w-full' : 'w-[65%]'} h-6 rounded-xl`} />
                            )
                        })}
                    </Column>
                    <Column className={'gap-y-2'}>
                        {Array.from({ length: 5 }, (_, idx) => {
                            return (
                                <ShimmerItem key={idx} className={`${idx < 4 ? 'w-full' : 'w-[20%]'} h-6 rounded-xl`} />
                            )
                        })}
                    </Column>
                    <ShimmerItem className={'w-full h-auto aspect-video rounded-xl'} />
                    <Column className={'gap-y-2'}>
                        {Array.from({ length: 5 }, (_, idx) => {
                            return (
                                <ShimmerItem key={idx} className={`${idx < 4 ? 'w-full' : 'w-[80%]'} h-6 rounded-xl`} />
                            )
                        })}
                    </Column>
                    <Column className={'gap-y-2'}>
                        {Array.from({ length: 5 }, (_, idx) => {
                            return (
                                <ShimmerItem key={idx} className={`${idx < 4 ? 'w-full' : 'w-[30%]'} h-6 rounded-xl`} />
                            )
                        })}
                    </Column>
                </Column>
                <Column className={'gap-y-5'}>
                    <Column className={'gap-y-2'}>
                        <ShimmerItem className={'w-48 h-6 rounded-xl'} />
                        <Row>
                            <ShimmerItem className={'w-32 h-5 rounded-xl'} />
                            <DotSeparated />
                            <ShimmerItem className={'w-32 h-5 rounded-xl'} />
                        </Row>
                    </Column>
                    <HorizontalDivider className='my-4' />
                    <ShimmerItem className={'w-72 h-8 rounded-xl'} />
                    <Row className={'gap-x-3'}>
                        <ShimmerItem className={'w-12 h-12 rounded-full'} />
                        <ShimmerItem className={'w-12 h-12 rounded-full'} />
                        <ShimmerItem className={'w-12 h-12 rounded-full'} />
                        <ShimmerItem className={'w-12 h-12 rounded-full'} />
                    </Row>
                </Column>
            </Column>
        </ShimmerAnimation>
    )
}

export default SAlmDetailEvent
