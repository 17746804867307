import React from "react";
import { TbArrowRight, TbTrash } from "react-icons/tb";
import { Row } from "../../../../../components/interfaces/block";
import {
  TableCell,
  TableRow,
} from "../../../../../components/interfaces/table";
import { BodyText } from "../../../../../components/interfaces/text";
import { ButtonHover, IconButton } from "../../../../../shared/ui";

function RowTableTopicConsultation({
  idx,
  topic,
  page = 1,
  limit = 10,
  handleClick,
}) {
  const onHandleClick = (event) => {
    event.stopPropagation();
    handleClick({
      action: 10,
      topic: topic,
    });
  };

  const onHandleDeleteClick = (event) => {
    event.stopPropagation();
    handleClick({
      action: 20,
      topic: topic,
    });
  };

  return (
    <TableRow striped onClick={onHandleClick}>
      <TableCell>{limit * (page - 1) + (idx + 1)}</TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{topic.nik}</BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{topic.nama_konselor}</BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{topic.kategori}</BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{topic.topik}</BodyText>
      </TableCell>
      <TableCell>
        <Row className={"gap-x-2"}>
          <ButtonHover>
            <IconButton
              className="p-1.5"
              color="red"
              bordered
              onClick={onHandleDeleteClick}
            >
              <TbTrash className="h-5 w-5" />
            </IconButton>
          </ButtonHover>
          {/* <ButtonHover>
            <IconButton
              className='p-1.5'
              color='indigo'
              bordered
              onClick={onHandleClick}>
              <TbArrowRight className='h-5 w-5' />
            </IconButton>
          </ButtonHover> */}
        </Row>
      </TableCell>
    </TableRow>
  );
}

export default RowTableTopicConsultation;
