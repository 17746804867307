import React from 'react'
import { BiImages } from 'react-icons/bi'
import { CgSpinner } from 'react-icons/cg'
import { Caption, Card, Image, Row } from '../shared/ui'

const WListImageRecognition = ({ images = [], aspectRatio, action = 10 }) => {
    return (
        <div className='relative'>
            <Row className='snap-x snap-mandatory overflow-x-auto scrollbar-hide scroll-smooth gap-1 rounded-xl border border-gray-200'>
                {images.map((image, idx) => (
                    <div key={idx} className='w-full snap-start flex-shrink-0'>
                        {!image.loading ? <Image src={action === 10 ? URL.createObjectURL(image.file) : image.image_name} imgClasName={`w-full ${aspectRatio}`} hoverScale={false} /> :
                            <Card className={`w-full ${aspectRatio} bg-slate-50 border-none`}>
                                <CgSpinner className='h-7 w-7 my-auto mx-auto animate-spin text-indigo-700' />
                            </Card>}
                    </div>
                ))}
            </Row>
            {images.length > 1 && <Row className='absolute w-fit right-2 bottom-2 bg-black/60 gap-1.5 backdrop-blur-xl pl-2 pr-3 py-1 rounded-lg'>
                <BiImages className='h-4 w-4 text-white my-auto' />
                <Caption className='text-white'>
                    {images.length} Gambar
                </Caption>
            </Row>}
        </div>
    )
}

export default WListImageRecognition
