import React, { useEffect, useState } from "react";
import {
  getDataProCariKerjaOvTracer,
  getDataProCariKerjaOvTracerV1,
  getDataProCariKerjaOvTracerV2,
} from "../controllers/apicontrollers";
import {
  ButtonHover,
  Caption,
  Card,
  Column,
  DotSeparated,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../shared/ui";
import { TitleText } from "./interfaces/text";
import { SiMicrosoftexcel } from "react-icons/si";
import moment from "moment";
import useDownloadExcel from "../hooks/useDownloadExcel";
import { getExcelDataProsesCariKerjaController } from "../controllers/admin_controllers";

const WTableProCariKerjaOvTracer = ({
  currentDateStart,
  currentDateEnd,
  prodi,
  currentTabIndex,
  binaryData,
  onDoneLoadData,
  tahun,
  tabIndex = false,
  btnDownload = false,
}) => {
  const [title, setTitle] = useState(null);
  const [header, setHeader] = useState([]);
  const [label, setLabel] = useState([]);
  const [data, setData] = useState([]);
  const [sortTable, setShortTable] = useState(null);

  useEffect(() => {
    const getDataBedaKerjapetensiV1 = async () => {
      const props = {
        tahun: tahun.value,
        prodi: prodi.value,
        order: sortTable !== null ? sortTable.key : null,
        asc: sortTable !== null ? sortTable.type : null,
      };
      const result = await getDataProCariKerjaOvTracerV1(props);
      if (result.status) {
        setTitle(result.title);
        setHeader(result.header);
        setData(result.data);
        setLabel(result.label);
      }
      onDoneLoadData(180);
    };
    getDataBedaKerjapetensiV1();
  }, [sortTable, tahun, prodi]);

  useEffect(() => {
    const getDataProCariKerjaV2 = async () => {
      const props = {
        date_start: currentDateStart.value,
        date_end: currentDateEnd.value,
        prodi: prodi.value,
        order: sortTable !== null ? sortTable.key : null,
        asc: sortTable !== null ? sortTable.type : null,
      };
      const result = await getDataProCariKerjaOvTracerV2(props);
      if (result.status) {
        setTitle(result.message);
        setHeader(result.header);
        setLabel(result.label);
        setData(result.data);
      }
    };
    getDataProCariKerjaV2();
  }, [sortTable, currentDateStart, currentDateEnd, prodi]);

  const onShortableValue = (value) => {
    setShortTable(value);
  };

  // Download excel
  const fileName = `Proses Lulusan Mencari Pekerjaan_${
    currentTabIndex === 0
      ? currentDateStart.value !== null || currentDateEnd.value !== null
        ? `${moment(currentDateStart.value).format("YYYY")}_${moment(
            currentDateEnd.value
          ).format("YYYY")}`
        : "Semua Tahun"
      : currentTabIndex === 1
      ? currentDateStart.value !== null || currentDateEnd.value !== null
        ? moment(currentDateStart.value).format("YYYY")
        : "Semua Tahun"
      : "Semua Tahun"
  }_${prodi.value === null ? "Semua Program Studi" : prodi.label}`;

  const downloadExcel = useDownloadExcel({ fileName });

  const handleDownloadExcel = async () => {
    const res = await getExcelDataProsesCariKerjaController({
      date_start: currentDateStart.value,
      date_end: currentDateEnd.value,
      prodi: prodi.value,
    });
    downloadExcel(res);
  };

  return (
    <Card className="w-full p-0">
      <Column>
        <Column className="px-4 py-3  md:flex-row md:justify-between space-y-3 md:space-y-0">
          <Column className={"gap-1"}>
            <Row>
              <Column>
                <TitleText>Proses Lulusan Mencari Pekerjaan</TitleText>
              </Column>
            </Row>
            {tabIndex ? (
              <Row className="flex-wrap gap-y-1">
                <Caption>{prodi.value ? prodi.label : "Semua Prodi"}</Caption>
                <DotSeparated />
                <Caption>
                  {currentTabIndex === 0
                    ? currentDateStart.value && currentDateEnd.value
                      ? `Tahun ${moment(currentDateStart.value).format(
                          "YYYY"
                        )}/${moment(currentDateEnd.value).format("YYYY")}`
                      : "Semua Tahun"
                    : currentDateStart.value && currentDateEnd.value
                    ? `${moment(currentDateStart.value).format("YYYY")}`
                    : "Semua Tahun"}
                </Caption>
              </Row>
            ) : (
              <Row className="flex-wrap gap-y-1">
                <Caption className="font-medium">
                  {prodi.value ? prodi.label : "Semua Program Studi"}
                </Caption>
                <DotSeparated />
                <Caption className="font-medium">
                  {tahun.value ? tahun.label : "Semua Tahun"}
                </Caption>
              </Row>
            )}
          </Column>

          {btnDownload && (
            <ButtonHover>
              <button
                onClick={() => handleDownloadExcel(binaryData)}
                className="flex border rounded-md px-4 py-2 gap-x-2  bg-gray-50 "
              >
                Download
                <SiMicrosoftexcel className="w-6 h-6 text-gray-500" />
              </button>
            </ButtonHover>
          )}
        </Column>
        <div className="p-4">
          <Table
            className="border border-gray-200"
            trigerPagination={sortTable}
            showPaginationAndSearch={false}
          >
            <TableHead>
              <TableRow className="bg-slate-100/50 border-b border-b-gray-200">
                {header.map((head, idx) => {
                  return (
                    <TableCell
                      key={idx}
                      className={`font-semibold jakarta`}
                      //   sortable={head.col !== null ? true : false}
                      //   sortkey={head.col}
                      //   onShortable={onShortableValue}
                    >
                      {head}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="divide-y divide-gray-200">
              {label.length > 0 ? (
                label.map((lab, idx) => (
                  <TableRow
                    key={idx}
                    className={`${
                      idx % 2 === 1 ? "bg-slate-50" : "bg-white"
                    } hover:bg-slate-100/100 cursor-default`}
                  >
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>
                      <Caption className={"line-clamp-1 text-gray-800"}>
                        {lab}
                      </Caption>
                    </TableCell>
                    <TableCell>
                      <Caption
                        className={"line-clamp-1 text-gray-800 text-center"}
                      >
                        {data[idx]}
                      </Caption>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    Belum da data untuk dtampilkan
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Column>
    </Card>
  );
};

export default WTableProCariKerjaOvTracer;
