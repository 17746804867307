import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BiPlus } from 'react-icons/bi'
import { TbTrash, TbX } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import RowTableEvent from '../../components/tabels/rows/row_event'
import { deleteEventController, getEventController } from '../../controllers/apicontrollers'
import { AlertDialog, ButtonHover, Card, Column, Headline1, Headline3, HorizontalDivider, Image, LoadingDialog, PrimaryButton, Row, SecondaryButton, Table, TableBody, TableCell, TableHead, TableRow, Text, Title, ToasterView } from '../../shared/ui'
import { admAddEventRoute } from '../../shared/variable'

const AdmEventPageOld = () => {

    const navigateTo = useNavigate()
    const [listEvent, setListEvent] = useState([])
    const [currentEvent, setCurrentEvent] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [sortTable, setShortTable] = useState(null)
    const [searchQuery, setSearchQuery] = useState(null)
    const [showProgress, setShowProgress] = useState(true)
    const [showDialog, setShowDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const getEvent = async () => {
        const props = {
            action: 10,
            limit: limitRow,
            page: currentPage,
        }
        const result = await getEventController(props)
        if (result.status) {
            setListEvent(result.data)
            setTotalRow(result.totalRows)
            setTotalPage(result.totalPage)
        }
        setShowProgress(false)
    }

    const deleteEvent = async () => {
        setShowDialog(false)
        setLoading(true)
        const result = await deleteEventController(currentEvent.slug)
        if (result.status) {
            setTimeout(() => {
                filterEvent()
                setLoading(false)
                toast.success(result.message)
            }, 200)
        }
    }

    const filterEvent = () => {
        const newEvent = listEvent.filter((event) => event.slug !== currentEvent.slug)
        const rows = totalRow - 1
        const page = rows / limitRow
        setTotalRow(rows)
        setTotalPage(page)
        setListEvent(newEvent)
    }

    const onChangeShowNumber = (value) => {
        setCurrentPage(1)
        setLimitRow(value)
    }

    const onChangeQuerySearch = (value) => {
        setCurrentPage(1)
        setSearchQuery(value)
    }

    const onChangePageNumber = (value) => {
        setCurrentPage(value)
    }

    const onShortableValue = (value) => {
        setCurrentPage(1)
        setShortTable(value)
    }

    const onShowDialog = (event) => {
        setShowDialog(true)
        setCurrentEvent(event)
    }

    useEffect(() => {
        getEvent()
    }, [])

    return (
        <>
            <Column className='gap-y-5'>
                <Row className='flex-col gap-x-5 sm:flex-row sm:justify-between'>
                    <Headline1>
                        Event
                    </Headline1>
                    <ButtonHover>
                        <PrimaryButton className='pr-5' leadingIcon={<BiPlus className='h-5 w-5 my-auto' />} color='indigo' onClick={() => navigateTo(admAddEventRoute)}>
                            Buat Event
                        </PrimaryButton>
                    </ButtonHover>
                </Row>
                <Card className='w-full p-0'>
                    <Column>
                        <Column className='px-5 py-4 gap-0.5'>
                            <Title>
                                Event Alumni & Karir
                            </Title>
                            <Text>
                                Semua data Event Alumni & Karir yang dibuat oleh Bidang Alumni Universitas Teknologi Yogyakarta
                            </Text>
                        </Column>
                        <HorizontalDivider className='mt-0 mb-0' />
                        <div className='p-5'>
                            <Table
                                className='border-[1px] border-gray-200'
                                limitRow={limitRow}
                                totalRow={totalRow}
                                totalPage={totalPage}
                                trigerPagination={sortTable}
                                onChangeShowNumber={onChangeShowNumber}
                                onChangeQuerySearch={onChangeQuerySearch}
                                onChangePageNumber={onChangePageNumber}>
                                <TableHead>
                                    <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                        <TableCell className='font-semibold font-manrope'>
                                            No.
                                        </TableCell>
                                        <TableCell className='font-semibold font-manrope'>
                                            Gambar Event
                                        </TableCell>
                                        <TableCell className='font-semibold font-manrope w-[30%]'>
                                            Judul Event
                                        </TableCell>
                                        <TableCell className='font-semibold font-manrope w-[30%]'>
                                            Deskripsi Event
                                        </TableCell>
                                        <TableCell className='font-semibold font-manrope'>
                                            Kategori Event
                                        </TableCell>
                                        <TableCell className='font-semibold font-manrope px-3 text-center'>
                                            Detail
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className='divide-y-[1px] divide-gray-200'>
                                    {listEvent.length > 0 ? listEvent.map((event, idx) => {
                                        return <RowTableEvent
                                            key={idx}
                                            idx={idx}
                                            limitRow={limitRow}
                                            currentPage={currentPage}
                                            event={event}
                                            onShowDialog={onShowDialog} />
                                    }) : <TableRow>
                                        <TableCell colSpan={7} className='text-center text-gray-500'>
                                            No data to display
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </div>
                    </Column>
                </Card>
            </Column>
            <AlertDialog className='max-w-3xl' show={showDialog} onClose={() => setShowDialog(false)}>
                {currentEvent !== null && <Column>
                    <Column className='px-8 py-8 gap-y-5 max-h-[50rem] overflow-y-auto scrollbar-hide scroll-smooth'>
                        <Column>
                            <Image
                                src={currentEvent.images[0].image_name}
                                className={`w-full rounded-xl ${currentEvent.rasio}`}
                                imgClasName={`w-full ${currentEvent.rasio}`}
                                hoverScale={false} />
                        </Column>
                        <Headline3>
                            {currentEvent.title}
                        </Headline3>
                        <div className='text-gray-800 font-inter text-base'
                            dangerouslySetInnerHTML={{ __html: currentEvent.body }} />
                    </Column>
                    <HorizontalDivider removeMargin />
                    <Row className='gap-x-5 justify-end px-8 pt-6 pb-8'>
                        <ButtonHover>
                            <PrimaryButton onClick={deleteEvent} color='red' leadingIcon={<TbTrash className='h-5 w-5 my-auto' />} className='pr-5'>
                                Hapus
                            </PrimaryButton>
                        </ButtonHover>
                        <ButtonHover>
                            <SecondaryButton leadingIcon={<TbX className='h-5 w-5 my-auto' />} className='pr-5' onClick={() => setShowDialog(false)}>
                                Tutup
                            </SecondaryButton>
                        </ButtonHover>
                    </Row>
                </Column>}
            </AlertDialog>
            <LoadingDialog show={loading} message='Sedang memproses...' />
            <ToasterView />
        </>
    )
}

export default AdmEventPageOld