import moment from 'moment'
import React from 'react'
import { TbAlertTriangle, TbBellRinging, TbCircleCheck, TbHandClick, TbInfoCircle, TbRefresh, TbShieldCheck, TbTrash, TbUserCircle, TbX } from 'react-icons/tb'
import { ButtonHover, Caption, Card, Column, Headline3, PrimaryButton, Row, SimpleAlert, TagView, TertiaryButton, Text } from '../../shared/ui'

const CAlmBusiness = ({ action = 10, business, onApply, onCancelApply, onDeletePost, showApply = true, onShowDialog, onChangeIndex }) => {
    return (
        <Card className='w-full p-0 transition-all cursor-pointer shadow-lg shadow-gray-200'>
            <Column className='divide-y-[1px] divide-gray-200'>
                <Row className='px-8 pt-8 pb-5 gap-x-5 items-start justify-between'>
                    <Column className='flex-1 gap-y-2'>
                        <Headline3>
                            {business.title}
                        </Headline3>
                        {action === 10 || action === 30 ? <Text>
                            Diposting oleh {business.nama_alumni}
                        </Text> : <Text className={`font-medium ${business.acc_bidal === 'REJECTED' ? 'text-red-600' : business.status === 'PENDING' ? 'text-orange-600' : business.status === 'ONGOING' ? 'text-blue-600' : 'text-emerald-600'}`}>
                            {business.acc_bidal === 'REJECTED' ? 'Postingan Ditolak' : <span>
                                {business.status === 'PENDING' ? 'Menunggu Verifikasi' : business.status === 'ONGOING' ? 'Postingan Terverifikasi' : 'Postingan Selesai'}
                            </span>}
                        </Text>}
                    </Column>
                    <Card className={`p-2 ${business.acc_bidal === 'REJECTED' ? 'bg-red-50 border-red-300' : business.status === 'PENDING' ? 'bg-orange-50 border-orange-300' : business.status === 'ONGOING' ? 'bg-blue-50 border-blue-300' : 'bg-emerald-50 border-emerald-300'}`}>
                        {business.acc_bidal === 'REJECTED' ? <TbAlertTriangle className='h-5 w-5 text-red-600' /> : business.status === 'PENDING' ? <TbRefresh className='h-5 w-5 text-orange-600' /> : business.status === 'ONGOING' ? <TbShieldCheck className='h-5 w-5 text-blue-600' /> : <TbCircleCheck className='h-5 w-5 text-emerald-600' />}
                    </Card>
                </Row>
                <Column className='px-8 pt-5 pb-7 gap-y-3'>
                    <Row className='gap-x-3 justify-between'>
                        {action === 10 && business.applied ? <div>
                            <TagView color='green' className='rounded-lg' label='SAYA TERTARIK' leadingIcon={<TbHandClick className='h-5 w-5 my-auto' />} />
                        </div> : null}
                        {action === 30 && <div>
                            {business.acc_ts === 'REJECTED' && <TagView color='red' className='rounded-lg' label='TIDAK TERPILIH' />}
                            {business.acc_ts === 'PROCESSED' && <TagView color='orange' className='rounded-lg' label='SEDANG DIPROSES' />}
                            {business.acc_ts === 'ACCEPTED' && <TagView color='green' className='rounded-lg' label='TERPILIH' />}
                        </div>}
                        {action === 20 && <div>
                            {business.acc_bidal === 'REJECTED' && <TagView color='red' className='rounded-lg' label='POSTINGAN DITOLAK' />}
                            {business.acc_bidal === 'PROCESSED' && business.status === 'PENDING' && <TagView color='orange' className='rounded-lg' label='POSTINGAN BELUM AKTIF' />}
                            {business.status === 'ONGOING' && <TagView color='blue' className='rounded-lg' label='POSTINGAN AKTIF' />}
                            {business.status === 'COMPLETE' && <TagView color='green' className='rounded-lg' label='POSTINGAN SELESAI' />}
                        </div>}
                        <Row className='w-fit gap-x-3'>
                            <TagView color='blue' className='rounded-lg' label={business.jenis} />
                            <TagView color='indigo' className='rounded-lg' label={business.kategori} />
                        </Row>
                    </Row>
                    <div
                        className='border-[1px] rounded-xl bg-gray-50 px-5 py-4 text-gray-800 font-inter text-base'
                        dangerouslySetInnerHTML={{ __html: business.body.replaceAll('<ul>', '<ul class="list-disc space-y-2 pl-5">').replaceAll('<ol>', '<ol class="list-decimal pl-6 space-y-2">').replaceAll('undefined', '') }} />
                </Column>
                {action === 30 && <div className='px-8 py-6'>
                    <SimpleAlert
                        type='custom'
                        className={`py-3 ${business.acc_ts === 'PROCESSED' ? 'bg-blue-50 border-l-blue-600 border-blue-300' : business.acc_ts === 'REJECTED' ? 'bg-red-50 border-l-red-600 border-red-300' : 'bg-green-50 border-l-green-600 border-green-300'}`}
                        icon={<TbBellRinging className={`h-5 w-5 ${business.acc_ts === 'PROCESSED' ? 'text-blue-700' : business.acc_ts === 'REJECTED' ? 'text-red-700' : 'text-green-700'}`} />}
                        bordered
                        borderLeft >
                        <span className={`${business.acc_ts === 'PROCESSED' ? 'text-blue-700' : business.acc_ts === 'REJECTED' ? 'text-red-700' : 'text-green-700'} font-medium`}>
                            {business.acc_ts === 'PROCESSED' ? 'Anda tertarik dengan postingan ini, selanjutnya {business.nama_alumni} akan memproses pendaftaran Anda.' :
                                business.acc_ts === 'REJECTED' ? 'Anda tidak terpilih pada penawaran bussiness to bussiness ini!' :
                                    'Anda terpilih pada penawaran bussiness to bussiness ini.'
                            }
                        </span>
                    </SimpleAlert>
                </div>}
                {action === 10 ? <Row className={`px-8 ${showApply && business.status === 'ONGOING' ? 'pb-8 pt-5' : 'py-5'} gap-x-8 justify-between items-center`}>
                    <Caption>
                        Dibuat {moment(business.created).format('DD MMMM YYYY HH.mm')}
                    </Caption>
                    {showApply && business.status === 'ONGOING' && <div>
                        {!business.applied ? <ButtonHover className='w-full sm:w-fit'>
                            <PrimaryButton onClick={() => onApply(business.slug)} leadingIcon={<TbHandClick className='h-5 w-5 my-auto' />} color='blue' className='w-full sm:w-fit pl-6 pr-8'>
                                Tertarik
                            </PrimaryButton>
                        </ButtonHover> : <ButtonHover>
                            <TertiaryButton onClick={onChangeIndex} color='blue' leadingIcon={<TbInfoCircle className='h-5 w-5 my-auto' />} className='pr-5'>
                                Info Selengkapnya
                            </TertiaryButton>
                        </ButtonHover>}
                    </div>}
                </Row> : action === 20 ? <Row className='px-8 pb-8 pt-5 gap-x-8 justify-between items-center'>
                    <Caption>
                        Dibuat {moment(business.created).format('DD MMMM YYYY HH.mm')}
                    </Caption>
                    {business.status === 'PENDING' ? <ButtonHover>
                        <PrimaryButton onClick={() => onDeletePost(business)} color='red' leadingIcon={<TbTrash className='h-5 w-5 my-auto' />} className='pr-5'>
                            Hapus Postingan
                        </PrimaryButton>
                    </ButtonHover> : <ButtonHover>
                        <PrimaryButton onClick={() => onShowDialog(business)} color='indigo' leadingIcon={<TbUserCircle className='h-5 w-5 my-auto' />} className='pr-5'>
                            Lihat Tertarik
                        </PrimaryButton>
                    </ButtonHover>}
                </Row> : <Row className={`px-8 ${business.acc_ts === 'PROCESSED' ? 'pb-8 pt-5' : 'py-5'} gap-x-8 justify-between items-center`}>
                    <Caption>
                        Dibuat {moment(business.created).format('DD MMMM YYYY HH.mm')}
                    </Caption>
                    {business.acc_ts === 'PROCESSED' && <ButtonHover>
                        <TertiaryButton onClick={() => onCancelApply(business)} color='red' leadingIcon={<TbX className='h-5 w-5 my-auto' />} className='pr-5'>
                            Batal Tertarik
                        </TertiaryButton>
                    </ButtonHover>}
                </Row>}
            </Column>
        </Card>
    )
}

export default CAlmBusiness