import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableEvent from "./rows/row_event";
import {
  AlertDialog,
  ButtonHover,
  Column,
  Headline3,
  HorizontalDivider,
  Image,
  LoadingDialog,
  PrimaryButton,
  Row,
  SecondaryButton,
  ToasterView,
} from "../../../../shared/ui";
import { TbTrash, TbX } from "react-icons/tb";
import { deleteEventController } from "../../../../controllers/apicontrollers";
import toast from "react-hot-toast";

function TableEvent({ listEvent, page, limit, onShort }) {
  const [currentEvent, setCurrentEvent] = useState([]);
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventImage, setEventImage] = useState([]);

  const onHandleClick = (recognition) => {
    console.log("event :", recognition);
    setCurrentEvent(recognition);
    setShowDialog(true);
    setEventImage(recognition.images);
  };

  const deleteEvent = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await deleteEventController(currentEvent.slug);
    if (result.status) {
      setTimeout(() => {
        // filterEvent();
        setLoading(false);
        toast.success(result.message);
      }, 200);
    }
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  return (
    <>
      {/* <div>
        <img src={currentEvent.images[0].image_name} alt="gambar-event" />
      </div> */}
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Judul Event</TableHeadCell>
            <TableHeadCell>Deskripsi Event</TableHeadCell>
            <TableHeadCell className={"text-center"}>Kategori</TableHeadCell>
            <TableHeadCell className={"text-center"}>Kunjungan</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listEvent.length > 0 ? (
            listEvent.map((event, idx) => {
              return (
                <RowTableEvent
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  event={event}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={5} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* {eventImage.map((image, index) => (
        <div key={index}>
          <Image
            src={image.image_name}
            className={`w-full rounded-xl ${currentEvent.rasio}`}
            imgClasName={`w-full ${currentEvent.rasio}`}
            hoverScale={false}
          />
        </div>
      ))} */}
      <AlertDialog
        className="max-w-3xl"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        {currentEvent !== null && (
          <Column>
            <Column className="px-8 py-8 gap-y-5 max-h-[50rem] overflow-y-auto scrollbar-hide scroll-smooth">
              <Column>
                {eventImage.map((image, index) => (
                  <div key={index}>
                    <Image
                      src={image.image_name}
                      className={`w-full rounded-xl ${currentEvent.rasio}`}
                      imgClasName={`w-full ${currentEvent.rasio}`}
                      hoverScale={false}
                    />
                  </div>
                ))}
                {/* <Image
                  src={eventImage.image_name}
                  className={`w-full rounded-xl ${currentEvent.rasio}`}
                  imgClasName={`w-full ${currentEvent.rasio}`}
                  hoverScale={false}
                /> */}
              </Column>
              <Headline3>{currentEvent.title}</Headline3>
              <div
                className="text-gray-700 font-inter text-base"
                dangerouslySetInnerHTML={{ __html: currentEvent.body }}
              />
            </Column>
            <HorizontalDivider removeMargin />
            <Row className="gap-x-5 justify-end px-8 pt-6 pb-8">
              <ButtonHover>
                <PrimaryButton
                  onClick={deleteEvent}
                  color="red"
                  leadingIcon={<TbTrash className="h-5 w-5 my-auto" />}
                  className="pr-5"
                >
                  Hapus
                </PrimaryButton>
              </ButtonHover>
              <ButtonHover>
                <SecondaryButton
                  leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                  className="pr-5"
                  onClick={() => setShowDialog(false)}
                >
                  Tutup
                </SecondaryButton>
              </ButtonHover>
            </Row>
          </Column>
        )}
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
      <ToasterView />
    </>
  );
}

export default TableEvent;
