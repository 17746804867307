import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadCell2,
  TableRow,
} from "../../../../components/interfaces/table";
import RowNotFillKuesionerTracer from "./rows/row_not_fill_kuesioner_tracer";

function TableNotFillKuesionerTracer({
  listNotFillKuesioner,
  page,
  limit,
  onShort,
}) {
  const [currentSort, setCurrentSort] = useState(null);

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>No</TableHeadCell>
          <TableHeadCell2
            sortable
            sortkey={"nim"}
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}
          >
            NPM
          </TableHeadCell2>
          <TableHeadCell>Nama Lengkap</TableHeadCell>
          <TableHeadCell>Jenjang</TableHeadCell>
          <TableHeadCell>Program Studi</TableHeadCell>
          <TableHeadCell>No Hp</TableHeadCell>
          <TableHeadCell2
            sortable
            sortkey={"tgllulus"}
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}
          >
            Tanggal Lulus
          </TableHeadCell2>
        </TableRow>
      </TableHead>
      <TableBody>
        {listNotFillKuesioner.length > 0 ? (
          listNotFillKuesioner.map((kuesioner, idx) => {
            return (
              <RowNotFillKuesionerTracer
                key={idx}
                idx={idx}
                page={page}
                limit={limit}
                kuesioner={kuesioner}
              />
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={7} className="text-center text-gray-500">
              No data to display
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default TableNotFillKuesionerTracer;
