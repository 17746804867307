import React, { useEffect, useState } from 'react'

const WOpsiViewImageRecognition = ({ initOpsi, onChange }) => {

    const [selectedId, setSelectedId] = useState()

    const opsiView = [
        {
            id: 1,
            label: 'List',
            value: 'list',
        },
        {
            id: 2,
            label: 'Grid',
            value: 'grid',
        },
    ]

    const changeSelectedId = (opsi) => {
        setSelectedId(opsi.id)
        onChange(opsi.value)
    }

    useEffect(() => {
        switch (initOpsi) {
            case 'list':
                setSelectedId(1)
                break;
            case 'grid':
                setSelectedId(2)
                break;
            default:
                setSelectedId(1)
                break;
        }
    }, [initOpsi])

    return (
        <div className="w-fit max-w-full flex flex-row bg-white rounded-lg overflow-x-auto scrollbar-hide scroll-smooth border-[1px] divide-x-[1px]" role="group">
            {opsiView.map((opsi, idx) => {
                return <button
                    key={idx}
                    type='button'
                    onClick={() => changeSelectedId(opsi)}
                    className={`w-20 flex-shrink-0 px-4 py-2 font-inter text-base font-medium ${selectedId === opsi.id ?
                        'text-indigo-700 bg-white' : 'text-gray-500 hover:text-gray-800 hover:bg-slate-50'}`}>
                    {opsi.label}
                </button>
            })}
        </div>
    )
}

export default WOpsiViewImageRecognition
