import {
  AlertDialog,
  ButtonHover,
  Card,
  Column,
  Form,
  Headline1,
  Headline3,
  LoadingView,
  OutlineInput,
  PrimaryButton,
  Row,
  Text,
  TextButton,
  TransitionPage,
} from "../shared/ui";
import bguty from "../images/bguty.jpeg";
import simfora from "../images/logo2.png";
import { TbAlertTriangle } from "react-icons/tb";
import { useEffect, useState } from "react";
import useCountDown from "../hooks/useCountDown";
import {
  confirmChangePasswordController,
  getTokenChangePasswordController,
} from "../controllers/alumni_controllers";
import { useNavigate, useParams } from "react-router-dom";
import {
  countDownChangeKey,
  loginRoute,
  resetTokenKey,
} from "../shared/variable";
import toast from "react-hot-toast";

export const ChangePasswordPage = () => {
  const { id } = useParams();
  const navigateTo = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [errMessageLimiter, setErrMessageLimiter] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startCountDown, setStartCountDown] = useState(false);
  const { timeLeft, formatTime } = useCountDown({
    initialTime: startCountDown ? 900 : 0,
    text: "Kirim",
    storageKey: countDownChangeKey,
  });
  const [formData, setFormData] = useState({
    password: "",
    konf_password: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const getTokenChangePassword = async () => {
      const res = await getTokenChangePasswordController({
        token: id,
      });
      if (res.status) {
        localStorage.setItem(resetTokenKey, res.token);
      } else if (res.message === "limiter") {
        setShowDialog(true);
        setErrorMessage(null);
        setErrMessageLimiter(
          "Request akses anda sudah lebih dari 3 kali, harap tunggu 15 menit lagi"
        );
      } else {
        setShowDialog(true);
        setErrMessageLimiter(null);
        setErrorMessage(res.message);
      }
    };
    getTokenChangePassword();
  }, []);

  const [passStrength, setPassStrength] = useState(null);
  useEffect(() => {
    const password = formData.password;

    if (password.length === 0) {
      setPassStrength();
    } else if (password.length >= 1 && password.length <= 8) {
      setPassStrength("Weak");
    } else if (
      password.length >= 10 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[!@#$%^&*_]/.test(password)
    ) {
      setPassStrength("Strong");
    } else if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password)
    ) {
      setPassStrength("Medium");
    } else {
      setPassStrength("Weak");
    }
  }, [formData.password]);

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    const res = await confirmChangePasswordController(formData);

    if (res.status) {
      setLoading(true);
      setTimeout(() => {
        navigateTo(loginRoute);
      }, 200);
      toast.success("Berhasil ganti sandi akun");
    } else if (res.message === "limiter") {
      setLoading(false);
      setStartCountDown(true);
      setErrorMessage(null);
      setErrMessageLimiter(
        "Request akses anda sudah lebih dari 3 kali, harap tunggu 15 menit lagi"
      );
      setShowDialog(true);
      return;
    } else {
      setShowDialog(true);
      setErrMessageLimiter(null);
      setErrorMessage(res.message);
    }
  };

  useEffect(() => {
    if (!startCountDown) {
      setStartCountDown(false);
    }
  }, [startCountDown]);

  return (
    <>
      <TransitionPage>
        <div className="min-h-screen fixed w-full">
          <Row>
            <div className="w-full hidden lg:flex ">
              <img src={bguty} className="object-cover" />
            </div>
            <div className="w-full lg:max-w-2xl">
              <Column className="h-screen  ">
                <Column className="max-w-lg mx-auto my-auto p-6 gap-y-6">
                  <img src={simfora} className="w-44" />
                  <Column className="gap-y-2">
                    <Headline1 className="font-semibold">
                      Ganti Kata Sandi Akun{" "}
                      <span className="text-indigo-600">Alumni</span>
                    </Headline1>
                  </Column>

                  <Column className="gap-y-10 pb-14">
                    <Form onSubmit={handleSubmitChangePassword}>
                      <Column className="relative gap-y-8">
                        <Text>
                          Silahkan masukkan password baru untuk mengganti
                          password lama akun anda.
                        </Text>
                        <Column>
                          <OutlineInput
                            id="password"
                            type="password"
                            className="py-3"
                            inputWidth="w-full"
                            bgColor="bg-gray-100"
                            label="Password Baru"
                            placeholder="Masukkan password baru"
                            maxLength={20}
                            togglePass={true}
                            required={true}
                            defaultValue={formData.password}
                            onChange={handleInputChange}
                            name="password"
                          />
                          {passStrength && (
                            <Column className="mt-2">
                              <Row className="gap-x-2 grid grid-cols-5">
                                <span
                                  className={`w-full h-1 rounded-full col-span-1 ${
                                    passStrength === "Weak"
                                      ? "bg-red-500"
                                      : passStrength === "Medium"
                                      ? "bg-orange-500"
                                      : passStrength === "Strong"
                                      ? "bg-green-500"
                                      : "bg-gray-200"
                                  }`}
                                />
                                <span
                                  className={` w-full h-1 rounded-full col-span-1 ${
                                    passStrength === "Weak"
                                      ? "bg-gray-200"
                                      : passStrength === "Medium"
                                      ? "bg-orange-500"
                                      : passStrength === "Strong"
                                      ? "bg-green-500"
                                      : "bg-slate-200"
                                  }`}
                                />
                                <span
                                  className={` w-full h-1 rounded-full col-span-3 ${
                                    passStrength === "Weak"
                                      ? "bg-gray-200"
                                      : passStrength === "Medium"
                                      ? "bg-gray-200"
                                      : passStrength === "Strong"
                                      ? "bg-green-500"
                                      : "bg-gray-200"
                                  }`}
                                />
                              </Row>
                              <small
                                className={`overlines mt-1 px-4 ${
                                  passStrength === "Medium"
                                    ? "text-orange-500"
                                    : passStrength === "Strong"
                                    ? "text-green-500"
                                    : "text-red-500"
                                } `}
                              >
                                {passStrength}
                              </small>
                            </Column>
                          )}
                        </Column>

                        <OutlineInput
                          id="konf_password"
                          type="password"
                          className="py-3"
                          inputWidth="w-full"
                          bgColor="bg-gray-100"
                          label="Konfirmasi Password"
                          placeholder="Masukkan konfirmasi password"
                          maxLength={20}
                          togglePass={true}
                          required={true}
                          defaultValue={formData.konf_password}
                          onChange={handleInputChange}
                          name="konf_password"
                        />

                        <ButtonHover className="w-full mt-2">
                          <PrimaryButton
                            disabled={timeLeft > 0}
                            color="indigo"
                            type="submit"
                            className="w-full py-3"
                          >
                            {loading && <LoadingView />}
                            {loading
                              ? "Mengirim Kata Sandi Baru..."
                              : `${formatTime(timeLeft)}`}
                          </PrimaryButton>
                        </ButtonHover>
                      </Column>
                    </Form>
                  </Column>
                </Column>
              </Column>
            </div>
          </Row>
        </div>
      </TransitionPage>

      <AlertDialog
        className="max-w-xs"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <Column className="divide-y divide-gray-200">
          <Column className="px-5 py-10 items-center gap-8">
            <Card className="p-1.5 rounded-2xl bg-orange-50/50 border-orange-200/50">
              <TbAlertTriangle className="h-14 w-14 stroke-1.5 text-orange-600" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Terjadi Kesalahan</Headline3>
              <Text className="text-center">{errorMessage}</Text>
              {errMessageLimiter && (
                <Text className="text-center">{errMessageLimiter}</Text>
              )}
            </Column>
          </Column>
          <TextButton
            onClick={() => {
              setShowDialog(false);
            }}
            className="w-full py-4 text-base hover:bg-gray-50/50"
          >
            Tutup
          </TextButton>
        </Column>
      </AlertDialog>
    </>
  );
};
export default ChangePasswordPage;
