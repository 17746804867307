import React from 'react'
import { TbArrowRight, TbChecks, TbCircleDashed, TbCircleDotted } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { ButtonHover, IconButton, TagView } from '../../../../../shared/ui'

function RowTableAcceptedConsultation({ idx, consultation, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow
      striped
      onClick={() => handleClick(consultation)}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {consultation.nama_alumni}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {consultation.topik}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='line-clamp-2'>
          {consultation.subjek}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='line-clamp-2'>
          {consultation.nama_konselor}
        </BodyText>
      </TableCell>
      <TableCell>
        <TagView
          className={'mx-auto'}
          leadingIcon={
            consultation.status === 'ONGOING' ? <TbCircleDashed className={'h-4 w-4'} /> :
              consultation.status === 'COMPLETE' ? <TbChecks className='h-4 w-4' /> :
                <TbCircleDotted className='h-4 w-4' />
          }
          label={
            consultation.status === 'ONGOING' ? 'Aktif' :
              consultation.status === 'COMPLETE' ? 'Selesai' :
                'Pending'
          }
          color={
            consultation.status === 'ONGOING' ? 'blue' :
              consultation.status === 'COMPLETE' ? 'green' :
                'orange'
          } />
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            className='p-1.5'
            color='indigo'
            bordered
            onClick={() => handleClick(consultation)}>
            <TbArrowRight className='h-5 w-5' />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableAcceptedConsultation