import Pusher from 'pusher-js'
import Randomstring from 'randomstring'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { TbCheck, TbChecks, TbClock, TbMessageCircle, TbRefresh, TbSearch, TbX } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { currentIndexTabPloting } from '../../atoms'
import WKonChats from '../../components/wkon_chats'
import WKonHistoryConsultation from '../../components/wkon_list_consultation'
import { updateResponPloting } from '../../controllers/apicontrollers'
import { AlertDialog, Caption, Card, Column, FullScHeight, GridCol, Headline3, LoadingDialog, OutlineInput, Row, TabPanel, TabsLine, Text, TextButton, ToasterView } from '../../shared/ui'

const KonConsultationPage = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabPloting)
    const [currentMessage, setCurrentMessage] = useState(null)
    const [currentUpdate, setCurrentUpdate] = useState(null)
    const [showDilaog, setShowDialog] = useState(false)
    const [reqUpdate, setReqUpdate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [action, setAction] = useState(null)
    const [channel, setChannel] = useState(null)

    const onSelectedMessage = (message) => {
        if (currentMessage !== null) {
            if (currentMessage.status === 'ONGOING') {
                unSubscribePusher(10, message)
            } else {
                if (message.status === 'ONGOING') {
                    setupPusher(message)
                } else {
                    setCurrentMessage(message)
                }
            }
        } else {
            setupPusher(message)
        }
    }

    const onApproveConsultation = (action) => {
        setAction(action)
        setShowDialog(true)
    }

    const responPloting = async () => {
        setShowDialog(false)
        setLoading(true)
        const result = await updateResponPloting(currentMessage.id_konsultasi, action === 10 ? false : true)
        if (result.status) {
            setTimeout(() => {
                setReqUpdate(Randomstring.generate(6))
                setCurrentMessage(null)
                setAction(null)
                setLoading(false)
                toast(result.message)
            }, 200)
        } else {
            setTimeout(() => {
                setReqUpdate(Randomstring.generate(6))
                setLoading(false)
                toast(result.message)
            }, 200)
        }
    }

    const onChangeTabIndex = (index) => {
        if (currentMessage !== null) {
            if (currentMessage.status === 'ONGOING') {
                unSubscribePusher(20, null)
            } else {
                setCurrentMessage(null)
            }
        }
        setCurrentTabIndex(index)
    }

    const setupPusher = (message) => {
        if (message.status === 'ONGOING') {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY_DEV, {
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER_DEV
            })
            const channel = pusher.subscribe(`konsultasi_${message.id_konsultasi}`)
            setChannel(channel)
        }
        setCurrentMessage(message)
    }

    const unSubscribePusher = async (action, message) => {
        await channel.unsubscribe(`konsultasi_${currentMessage.id_konsultasi}`)
        if (action === 10) {
            setupPusher(message)
        } else {
            setCurrentMessage(null)
        }
    }

    const onFinishConsultation = () => {
        unSubscribePusher(30, null)
    }

    const updateMessage = (data) => {
        setCurrentUpdate(data)
    }

    return (
        <>
            <Row className='divide-x divide-gray-200/50'>
                <FullScHeight className='w-full sm:max-w-md sm:overflow-y-auto scrollbar-hide bg-white'>
                    <Column>
                        <Column className='px-6 pt-6 pb-5 gap-y-2.5'>
                            <Headline3>
                                Konsultasi Karier
                            </Headline3>
                            <OutlineInput
                                className='pl-10 pr-3'
                                inputWidth='w-full'
                                placeholder='Cari Konsultasi'
                                bgColor='bg-slate-50'
                                prefixClassName='px-3'
                                prefix={<TbSearch className='h-5 w-5 text-gray-400' />} />
                        </Column>
                        <TabsLine className='justify-around' selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                            <TabPanel removePadding title='Diterima' icon={<TbMessageCircle className='h-5 w-5' />}>
                                <WKonHistoryConsultation currentMessage={currentMessage ?? {}} action={10} onSelectedMessage={onSelectedMessage} updateMessage={currentUpdate} reqUpdate={reqUpdate} />
                            </TabPanel>
                            <TabPanel removePadding title='Menunggu' icon={<TbRefresh className='h-5 w-5' />} textActiveColor='text-orange-600' borderActiveColor='border-orange-600'>
                                <WKonHistoryConsultation currentMessage={currentMessage ?? {}} action={20} onSelectedMessage={onSelectedMessage} reqUpdate={reqUpdate} />
                            </TabPanel>
                            <TabPanel removePadding title='Selesai' icon={<TbChecks className='h-5 w-5' />} textActiveColor='text-green-600' borderActiveColor='border-green-600'>
                                <WKonHistoryConsultation currentMessage={currentMessage ?? {}} action={30} onSelectedMessage={onSelectedMessage} />
                            </TabPanel>
                        </TabsLine>
                    </Column>
                </FullScHeight>
                <WKonChats
                    currentMessage={currentMessage}
                    channel={channel}
                    onApprove={onApproveConsultation}
                    onFinish={onFinishConsultation}
                    onUpdateMessage={updateMessage} />
            </Row>
            <AlertDialog className='max-w-sm' show={showDilaog} onClose={() => { setShowDialog(false) }}>
                {action !== null && <Column>
                    <Column className='px-5 pt-10 pb-5 items-center gap-8'>
                        <Card className={`p-1.5 rounded-2xl ${action === 10 ? 'bg-red-50/50 border-red-200/50' : 'bg-indigo-50/50 border-indigo-200/50'}`}>
                            {action === 10 ? <TbX className='h-14 w-14 text-red-600 stroke-1.5' /> : <TbCheck className='h-14 w-14 text-indigo-600 stroke-1.5' />}
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                {action === 10 ? 'Tolak Konsultasi' : 'Terima Konsultasi'}
                            </Headline3>
                            <Text className='text-center'>
                                {action === 10 ? 'Apakah anda yakin ingin menolak ploting sebagai konselor pada sesi konsultasi bersama ' : 'Anda akan menjadi konselor dalam sesi konsultasi bersama '} {currentMessage.fullName}
                            </Text>
                        </Column>
                    </Column>
                    <div className='px-5 pb-8'>
                        <Column className={`px-3 py-5 gap-5 rounded-md border ${action === 10 ? 'border-red-200/50 bg-red-50' : 'border-indigo-200/50 bg-indigo-50'}`}>
                            <div className='w-fit flex px-2.5 py-2 bg-white shadow-sm rounded-tl-2xl rounded-br-2xl rounded-tr-2xl'>
                                <Caption className='w-fit text-gray-800 my-auto whitespace-pre-line'>
                                    {currentMessage.topik}
                                </Caption>
                            </div>
                            <Card className='max-w-xl self-center pl-1.5 pr-4 py-1 rounded-full'>
                                <Row className='w-fit gap-1.5'>
                                    <TbClock className={`h-5 w-5 my-auto ${action === 10 ? 'text-red-500' : 'text-indigo-500'}`} />
                                    <Caption>
                                        Menunggu Acc Konselor
                                    </Caption>
                                </Row>
                            </Card>
                        </Column>
                    </div>
                    <GridCol className='gap-0 divide-x border-t border-gray-200 divide-gray-200'>
                        <TextButton onClick={() => { setShowDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                            Batal
                        </TextButton>
                        <TextButton onClick={responPloting} color={action === 10 ? 'red' : 'indigo'} className={`w-full py-4 text-base ${action === 10 ? 'hover:bg-red-50/50' : 'hover:bg-indigo-50/50'}`}>
                            {action === 10 ? 'Tolak Konsultasi' : 'Terima Konsultasi'}
                        </TextButton>
                    </GridCol>
                </Column>}
            </AlertDialog>
            <LoadingDialog show={loading} message='Sedang memproses...' />
            <ToasterView />
        </>
    )
}

export default KonConsultationPage
