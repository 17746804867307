import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { HiChevronUpDown } from "react-icons/hi2";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import ReactPaginate from "react-paginate";
import { Column, Row } from "./block";
import { BodyText } from "./text";

const paginationOption = [5, 10, 20, 30, 50, 100]

export const PaginationOption = ({ name, initial, btnClassName, optClassName, onChange }) => {

    const [optionSelected, setOptionSelected] = useState(initial ?? 10)

    const onChangeOption = (value) => {
        setOptionSelected(value)
        onChange(value)
    }

    return (
        <Listbox
            name={name}
            value={optionSelected}
            onChange={onChangeOption}>
            <div className={'relative z-10 w-fit'}>
                <Listbox.Button className={`listbox-button-new ${btnClassName}`}>
                    <Row className={'gap-x-1.5'}>
                        <BodyText className={'w-fit flex-1'}>
                            {optionSelected}
                        </BodyText>
                        <HiChevronUpDown className={'h-5 w-5 text-gray-500 my-auto'} />
                    </Row>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    enter={'transition ease-in-out duration-300'}
                    enterFrom={'-translate-y-8 opacity-0'}
                    enterTo={'translate-y-0 opacity-100'}
                    leave={'transition ease-out duration-200'}
                    leaveFrom={'translate-y-0 opacity-100'}
                    leaveTo={'translate-y-3 opacity-0'}>
                    <Listbox.Options className={`listbox-options-new mt-1`}>
                        {paginationOption.map((option, idx) => (
                            <Listbox.Option
                                key={idx}
                                className={`relative cursor-pointer select-none pl-3.5 pr-2.5 py-2 ${option === optionSelected ? 'bg-indigo-50/50' : 'hover:bg-slate-50'} ${optClassName}`}
                                value={option}>
                                <BodyText className={`w-fit flex-1 ${option === optionSelected && 'font-medium text-indigo-700'}`}>
                                    {option}
                                </BodyText>
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export const Pagination = ({ className, initialPage = 0, limitRow = 10, totalRow, totalPage = 1, triger, onChange }) => {

    const [currentPage, setCurrentPage] = useState(initialPage)

    const onChangeCurrentPage = (page) => {
        const { selected } = page
        setCurrentPage(selected)
        onChange(selected + 1)
    }

    useEffect(() => {
        setCurrentPage(0)
    }, [limitRow, totalRow, totalPage, triger])

    return (
        <Column className={`items-center md:flex-row md:items-start gap-x-4 gap-y-2 justify-between ${className}`}>
            <BodyText className={'my-auto border border-gray-200 px-3.5 py-1.5 rounded-lg bg-gray-50/50'}>
                <span className='text-gray-500'>Menampilkan </span>
                <span className='font-medium text-gray-700'>{`${(currentPage * limitRow) + 1}`}</span>
                <span className='text-gray-500'> s.d </span>
                <span className='font-medium text-gray-700'>{`${(limitRow * (currentPage + 1)) > totalRow ? totalRow : limitRow * (currentPage + 1)}`}</span>
                <span className='text-gray-500'> dari </span>
                <span className='font-medium text-gray-700'>{`${totalRow}`}</span>
                <span className='text-gray-500'> data</span>
            </BodyText>
            <ReactPaginate
                forcePage={currentPage}
                className={'max-w-full sm:w-fit overflow-x-auto scrollbar-hide flex flex-row border border-gray-200 bg-gray-50/50 rounded-lg gap-x-1 font-inter font-normal text-base p-1.5'}
                activeClassName={'text-gray-800 font-semibold'}
                activeLinkClassName={'flex w-7 h-7 bg-gray-100 border border-gray-200 rounded-full items-center justify-center'}
                pageClassName={'text-gray-500 rounded-full hover:bg-gray-100'}
                pageLinkClassName={'flex w-7 h-7 rounded-full items-center justify-center'}
                previousLabel={<TbChevronLeft className='h-5 w-5 mx-auto' />}
                previousLinkClassName={'flex w-7 h-7 rounded-full items-center justify-center hover:bg-gray-100 text-gray-500'}
                nextLabel={<TbChevronRight className='h-5 w-5 mx-auto' />}
                nextLinkClassName={'flex w-7 h-7 rounded-full items-center justify-center hover:bg-gray-100 text-gray-500'}
                breakLabel='...'
                breakClassName={'text-gray-500'}
                breakLinkClassName={'flex w-7 h-7 rounded-full items-center justify-center'}
                onPageChange={onChangeCurrentPage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={Math.ceil(totalPage)}
                renderOnZeroPageCount={null} />
        </Column>
    )
}

export const Tooltip = ({ children, className, message = 'Message' }) => {

    const tipRef = useRef();

    function handleMouseEnter() {
        tipRef.current.style.opacity = 1;
        tipRef.current.style.marginLeft = '20px';
    }
    function handleMouseLeave() {
        tipRef.current.style.opacity = 0;
        tipRef.current.style.marginLeft = '10px';
    }

    return (
        <div
            className={`relative flex items-center ${className}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div
                className={'absolute whitespace-no-wrap bg-black text-white px-4 py-2 rounded-lg flex items-center transition-all duration-150'}
                style={{ left: '100%', opacity: 0 }}
                ref={tipRef}>
                <div
                    className='bg-black h-3 w-3 absolute rounded-bl'
                    style={{ left: '-6px', transform: 'rotate(45deg)', }} />
                {message}
            </div>
            {children}
        </div>
    )
}

export const LoadingView = ({ className }) => {
    return (
        <CgSpinner className={`h-5 w-5 my-auto animate-spin ${className}`} />
    )
}

export const useDebounce = ({ value, delay = 500 }) => {

    const [debounceVal, setDebounceVal] = useState(value)

    useEffect(() => {
        const id = setTimeout(() => {
            setDebounceVal(value)
        }, delay)
        return () => {
            clearTimeout(id)
        }
    }, [value, delay])

    return debounceVal
}