import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Caption, Column, HorizontalDivider, Overline, Row, Text } from '../../shared/ui'
import { TbChecks, TbCircleDashed, TbHistoryToggle, TbMessageCircleOff } from 'react-icons/tb'
import { getCurrentUser } from '../../shared/user'
import { expiredKonsultasi } from '../../controllers/apicontrollers'
import { convertDateToString } from '../../shared/lib'

const CHistory = ({ action, history, onClick, currentId }) => {

    const user = getCurrentUser()
    const [currentHistory, setCurrentHistory] = useState(history)
    const [timeAgo, setTimeAgo] = useState('')

    const setTimeAgoKonsultasi = () => {
        const strDateKonsul = convertDateToString({ date: currentHistory.last_chat_time ?? currentHistory.created_at, utc: true })
        const parseDateKonsul = Date.parse(strDateKonsul)

        const dateNow = moment(Date.now()).format('DD MMM YYYY')
        const dateKonsul = moment(parseDateKonsul).format('DD MMM YYYY')

        if (dateNow !== dateKonsul) {
            setTimeAgo(moment(parseDateKonsul).format('DD MMM'))
        } else {
            setTimeAgo(moment(parseDateKonsul).format('HH.mm'))
        }
    }

    const checkExpiredKonsultasi = () => {
        const strDateKonsul = convertDateToString({ date: currentHistory.last_chat_time ?? currentHistory.created_at, utc: true })
        const parseDateKonsul = Date.parse(strDateKonsul)

        const dateNow = moment(Date.now())
        const dateKonsul = moment(parseDateKonsul)

        const diffDate = dateNow.diff(dateKonsul)
        const diffDuration = moment.duration(diffDate);

        if (diffDuration.days() >= 1 && currentHistory.status === 'ONGOING') {
            setCurrentHistory(currentHistory => { return { ...currentHistory, status: 'COMPLETE' } })
            updateExpiredKonsultasi()
        }
    }

    const updateExpiredKonsultasi = async () => {
        const result = await expiredKonsultasi(currentHistory.id_konsultasi)
        if (!result.status) {
            setCurrentHistory(currentHistory => { return { ...currentHistory, status: 'ONGOING' } })
        }
    }

    const onClickMessage = () => {
        onClick(history)
    }

    useEffect(() => {
        setTimeAgoKonsultasi()
        checkExpiredKonsultasi()
    }, [history, currentHistory.last_chat_time, currentHistory.status])

    return (
        <Column>
            <Row onClick={onClickMessage} className={`gap-x-3 px-4 py-3.5 border-l-[3px] transition-all duration-200 cursor-pointer ${currentId === currentHistory.id_konsultasi ? 'border-l-indigo-700 bg-slate-50' : 'border-l-transparent hover:bg-slate-50'}`}>
                <div className='relative h-10 w-10'>
                    <div className='flex h-10 w-10 rounded-full justify-center items-center bg-gray-700'>
                        {action === 10 ? <Text className='text-white uppercase font-medium'>
                            {currentHistory.nama_konselor !== null ? currentHistory.nama_konselor.substring(0, 2) : currentHistory.subject !== null ? currentHistory.subject.substring(0, 2) : 'SK'}
                        </Text> : <Text className='text-white uppercase font-medium'>
                            {currentHistory.nama_alumni !== null ? currentHistory.nama_alumni.substring(0, 2) : currentHistory.subject !== null ? currentHistory.subject.substring(0, 2) : 'SK'}
                        </Text>}
                    </div>
                    <div className={`h-3.5 w-3.5 rounded-full absolute right-0 bottom-0 border-2 border-white ${currentHistory.acc_bidal === 'REJECTED' ? 'bg-red-600' : currentHistory.acc_bidal === 'PROCESSED' ? 'bg-orange-600' : currentHistory.status === 'ONGOING' ? 'bg-blue-600' : currentHistory.status === 'COMPLETE' ? 'bg-green-600' : currentHistory.respon === null ? 'bg-orange-600' : 'bg-red-600'}`}></div>
                </div>
                <Column className='flex-1 my-auto'>
                    {currentHistory.nama_konselor !== null && <Overline className='font-medium uppercase'>
                        {currentHistory.nama_konselor}
                    </Overline>}
                    {currentHistory.nama_alumni !== null && <Overline className='font-medium uppercase'>
                        {currentHistory.nama_alumni}
                    </Overline>}
                    <Text className='font-manrope font-semibold line-clamp-1'>
                        {currentHistory.subject ?? 'Subject Konsultasi'}
                    </Text>
                    <Caption className='line-clamp-1 mt-1'>
                        {currentHistory.last_chat ?? currentHistory.topik}
                    </Caption>
                </Column>
                <Column className='w-fit items-end gap-2'>
                    <Overline className='font-medium'>
                        {timeAgo}
                    </Overline>
                    {currentHistory.acc_bidal === 'REJECTED' ? <TbMessageCircleOff className='h-[1.15rem] w-[1.15rem] text-gray-400' /> : <div>
                        {currentHistory.status === 'PENDING' && currentHistory.respon === null ? <TbHistoryToggle className='h-[1.15rem] w-[1.15rem] text-gray-400' /> : !currentHistory.respon && <TbMessageCircleOff className='h-[1.15rem] w-[1.15rem] text-gray-400' />}
                        {currentHistory.status === 'ONGOING' && <TbCircleDashed className='h-[1.15rem] w-[1.15rem] text-gray-400' />}
                        {currentHistory.status === 'COMPLETE' && <TbChecks className='h-[1.15rem] w-[1.15rem] text-gray-400' />}
                    </div>}
                </Column>
            </Row>
            <HorizontalDivider removeMargin className='pl-16' />
        </Column>
    )
}

export default CHistory
