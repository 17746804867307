import React from 'react'
import { ButtonHover, IconButton, Row, TableCell, TableRow, TagView, Text, Tooltip } from '../../../shared/ui'
import { TbAlertTriangle, TbArrowRight, TbArrowUpRight, TbCheck, TbChecks, TbFile, TbLink, TbRefresh, TbX } from 'react-icons/tb'
import moment from 'moment'

const RowTableApplyLowongan = ({ apply, idx, onShowDialog, currentPage = 1, limitRow = 1 }) => {

    return (
        <TableRow onClick={() => onShowDialog(apply)} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} cursor-pointer hover:bg-slate-100/50`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                <Tooltip message={apply.nama}>
                    <Text className='line-clamp-2'>
                        {apply.nama}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <ButtonHover>
                    <a onClick={(event) => event.stopPropagation()} className='flex flex-row gap-x-2 tertiary-btn py-1.5 pl-2.5 pr-4 hover:border-blue-500' href={apply.ijazah} target='_blank' rel='noreferrer'>
                        <TbArrowUpRight className='h-5 w-5 my-auto' /> Ijazah
                    </a>
                </ButtonHover>
            </TableCell>
            <TableCell>
                <ButtonHover>
                    <a onClick={(event) => event.stopPropagation()} className='flex flex-row gap-x-2 tertiary-btn py-1.5 pl-2.5 pr-4 hover:border-blue-500' href={apply.transkrip} target='_blank' rel='noreferrer'>
                        <TbArrowUpRight className='h-5 w-5 my-auto' /> Transkrip
                    </a>
                </ButtonHover>
            </TableCell>
            <TableCell>
                <ButtonHover>
                    <a onClick={(event) => event.stopPropagation()} className='flex flex-row gap-x-2 tertiary-btn py-1.5 pl-2.5 pr-4 hover:border-blue-500' href={apply.cv} target='_blank' rel='noreferrer'>
                        <TbArrowUpRight className='h-5 w-5 my-auto' /> CV
                    </a>
                </ButtonHover>
            </TableCell>
            <TableCell>
                {moment(apply.created_at).format('DD MMM YYYY HH.mm')}
            </TableCell>
            <TableCell>
                {apply.acc_mitra === 'ACCEPTED' ? <TagView className='mx-auto' leadingIcon={<TbChecks className='h-4 w-4' />} label='Disetujui' color='green' /> : apply.acc_mitra === 'REJECTED' ? <TagView className='mx-auto' leadingIcon={<TbAlertTriangle className='h-4 w-4' />} label='Ditolak' color='red' /> : <TagView className='mx-auto' leadingIcon={<TbRefresh className='h-4 w-4' />} label='Menunggu' color='orange' />}
            </TableCell>
            <TableCell className='px-3 flex justify-center'>
                <ButtonHover>
                    <IconButton onClick={() => onShowDialog(apply)} className='p-1.5' bordered={true} color='indigo'>
                        <TbArrowRight className='h-5 w-5' />
                    </IconButton>
                </ButtonHover>
            </TableCell>
        </TableRow>
    )
}

export default RowTableApplyLowongan