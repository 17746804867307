import React, { Suspense, lazy, useState } from 'react';
import { TbBriefcase, TbChartDonut2 } from 'react-icons/tb';
import { useRecoilState } from 'recoil';
import { currentIndexTabCareer } from '../../atoms';
import OvCareer from '../../components/overview/ov_career';
import STable from '../../components/shimmer/stable';
import { Column, Headline1, TabPanel, Tabs } from '../../shared/ui';

const TableAcceptedMitra = lazy(() => import('../../components/tabels/accepted_mitra'))

const AdmCareerPage = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabCareer)
    const [showProgress, setShowProgress] = useState(true)

    const onChangeTabIndex = (index) => {
        setShowProgress(true)
        setCurrentTabIndex(index)
    }

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    return (
        <Column className='gap-y-5'>
            <Headline1>
                Pusat Karir
            </Headline1>
            <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                <TabPanel icon={<TbChartDonut2 className='h-5 w-5' />} title='Ringkasan'>
                    <OvCareer onDoneLoadData={onDoneLoadData} />
                </TabPanel>
                <TabPanel icon={<TbBriefcase className='h-5 w-5' />} title='Lowongan' textActiveColor='text-blue-700'>
                    <Suspense fallback={<STable column={8} listWidth={['w-12', 'w-32', 'w-72', 'w-20', 'w-20', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end', 'justify-end']} />}>
                        <TableAcceptedMitra onDoneLoadData={onDoneLoadData} action={20} />
                    </Suspense>
                </TabPanel>
            </Tabs>
        </Column>
    )
}

export default AdmCareerPage
