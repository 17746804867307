import React from 'react'
import { TableCell, TableRow, Text, Tooltip } from '../../../shared/ui';
import moment from 'moment';

const RowTableRekapSurveiPenggunaLulusan = ({ survei, idx, currentPage = 1, limitRow = 1 }) => {
    return (
        <TableRow className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50 cursor-pointer`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                <Tooltip message={survei.pengisi_nama}>
                    <Text className='capitalize line-clamp-2'>
                        {survei.pengisi_nama}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip message={survei.pengisi_perusahaan}>
                    <Text className='capitalize line-clamp-2'>
                        {survei.pengisi_perusahaan}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip message={survei.mhs_nama}>
                    <Text className='capitalize line-clamp-2'>
                        {survei.mhs_nama}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip message={survei.nama_prodi}>
                    <Text className='capitalize line-clamp-2'>
                        {survei.nama_prodi}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                {moment(survei.created_at).format('DD MMM YYYY HH.mm')}
            </TableCell>
        </TableRow>
    );
}

export default RowTableRekapSurveiPenggunaLulusan
