import { useState } from 'react'
import toast from 'react-hot-toast'
import { TbTrash, TbX } from 'react-icons/tb'
import { Column, Row } from '../../../../components/interfaces/block'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import { BodyText, Headline3Text } from '../../../../components/interfaces/text'
import { deleteTopicConsultationController } from '../../../../controllers/admin_controllers'
import { AlertDialog, ButtonHover, LoadingDialog, PrimaryButton, TertiaryButton } from '../../../../shared/ui'
import RowTableTopicConsultation from './rows/row_topic_consultation'

function TableTopicConsultation({ listTopic, page, limit, onShort, onRefresh }) {

  const [currentTopic, setCurrentTopic] = useState(null)
  const [currentSort, setCurrentSort] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const onHandleClick = ({ action, topic }) => {
    setCurrentTopic(topic)
    if (action === 20) {
      setShowDialog(true)
    }
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  const deleteTopicConsultation = async () => {
    setShowDialog(false)
    setLoading(true)
    const result = await deleteTopicConsultationController({
      topic: currentTopic.id_topik,
      nik: currentTopic.nik
    })
    if (result.status) {
      setTimeout(() => {
        onRefresh()
        setLoading(false)
        toast.success(result.message)
      }, 200)
    } else {
      setTimeout(() => {
        setLoading(false)
        toast.error(result.message)
      }, 200)
    }
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              No
            </TableHeadCell>
            <TableHeadCell>
              NIK
            </TableHeadCell>
            <TableHeadCell>
              Nama
            </TableHeadCell>
            <TableHeadCell>
              Kategori
            </TableHeadCell>
            <TableHeadCell>
              Topik
            </TableHeadCell>
            <TableHeadCell className={'text-center'}>
              Aksi
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listTopic.length > 0 ? listTopic.map((topic, idx) => {
            return (
              <RowTableTopicConsultation
                key={idx}
                idx={idx}
                page={page}
                limit={limit}
                topic={topic}
                handleClick={onHandleClick} />
            )
          }) : <TableRow>
            <TableCell colSpan={7} className='text-center text-gray-500'>
              No data to display
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
      <AlertDialog
        show={showDialog}
        className={'max-w-md'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3Text>
              Hapus Ploting Konselor
            </Headline3Text>
            {currentTopic !== null && <BodyText>
              Apakah anda yakin ingin menghapus ploting topik <span className={'font-medium'}>{currentTopic.topik}</span> untuk konselor <span className={'font-medium'}>{currentTopic.nama_konselor}</span>?
            </BodyText>}
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='red'
                className='pl-5 pr-6'
                leadingIcon={
                  <TbTrash className='h-5 w-5 my-auto' />
                }
                onClick={deleteTopicConsultation}>
                Hapus
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Menghapus ploting topik...' />
    </>
  )
}

export default TableTopicConsultation