import { useEffect, useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { Column, Row } from "../../../../components/interfaces/block";
import { Card } from "../../../../components/interfaces/card";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from "../../../../components/interfaces/table";
import { CaptionText, TitleText } from "../../../../components/interfaces/text";
import { getExportProsesCariKerjaExitSurveyController, getProsesCariKerjaExitSurveyController } from "../../../../controllers/admin_controllers";
import useDownloadExcel from "../../../../hooks/useDownloadExcel";
import { ButtonHover, DotSeparated } from "../../../../shared/ui";

function TableProsesCariKerja({ prodi, wisuda, binaryData }) {

  const [tableTitle, setTableTitle] = useState("Proses Alumni Mencari Pekerjaan")
  const [tableHeader, setTableHeader] = useState([]);
  const [tableLabel, setTableLabel] = useState([]);
  const [tableData, setTableData] = useState([]);

  const fileName = `Proses Mencari Kerja - ${prodi.label} - ${wisuda.label} (Exit Survey)`
  const downloadExcelFill = useDownloadExcel({ fileName });

  const getProsesCariKerjaExitSurvey = async () => {
    const res = await getProsesCariKerjaExitSurveyController({
      prodi: prodi.value,
      tglwisuda: wisuda.value,
    });
    if (res.status) {
      setTableTitle(res.data?.title)
      setTableHeader(res.data?.header)
      setTableLabel(res.data?.label)
      setTableData(res.data?.rekap)
    }
  };

  const exportExcel = async () => {
    const res = await getExportProsesCariKerjaExitSurveyController({
      prodi: prodi.value,
      tglwisuda: wisuda.value,
    });
    downloadExcelFill(res);
  };

  useEffect(() => {
    getProsesCariKerjaExitSurvey()
  }, [prodi, wisuda])

  return (
    <Card className="w-full">
      <Column className={'gap-y-5'}>
        <Row className="gap-x-5">
          <Column className="gap-y-1">
            <TitleText>
              {
                tableTitle
              }
            </TitleText>
            <Row>
              <CaptionText>
                {
                  prodi.label
                }
              </CaptionText>
              <DotSeparated />
              <CaptionText>
                {
                  wisuda.label
                }
              </CaptionText>
            </Row>
          </Column>
          <ButtonHover>
            <button
              onClick={() => exportExcel(binaryData)}
              className="flex border rounded-md px-4 py-2 gap-x-2  bg-gray-50 "
            >
              Download
              <SiMicrosoftexcel className="w-6 h-6 text-gray-500" />
            </button>
          </ButtonHover>
        </Row>
        <Table>
          <TableHead>
            <TableRow>
              {
                tableHeader.map((header, idx) => {
                  return (
                    <TableHeadCell key={idx}>
                      {
                        header
                      }
                    </TableHeadCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tableData.length > 0 ? (
                tableData.map((data, idx) => {
                  return (
                    <TableRow striped>
                      <TableCell>
                        {
                          idx + 1
                        }
                      </TableCell>
                      <TableCell>
                        {
                          tableLabel[idx]
                        }
                      </TableCell>
                      <TableCell>
                        {
                          data
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={tableHeader.length} className="text-center text-gray-500">
                    No data to display
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </Column>
    </Card>
  )
}

export default TableProsesCariKerja;