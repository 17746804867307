import React, { useEffect, useState } from 'react'
import { Caption, Card, Column, Overline, Row, Subtitle, Text } from '../../shared/ui'
import { TbBellRinging } from 'react-icons/tb'
import moment from 'moment'
import { getAgo } from '@jlln/ago'
import { convertDateToString } from '../../shared/lib'

const CNotifications = ({ action, notif, onReadNotification }) => {

    const [timeAgo, setTimeAgo] = useState('')

    useEffect(() => {
        const strDateNotif = convertDateToString({ date: notif.created_at, utc: true })
        const parseDateNotif = Date.parse(strDateNotif)

        const dateNow = moment(Date.now()).format('DD MMM YYYY')
        const dateNotif = moment(parseDateNotif).format('DD MMM YYYY')

        if (dateNow !== dateNotif) {
            setTimeAgo(moment(parseDateNotif).format('DD MMM YYYY HH.mm'))
        } else {
            setTimeAgo(moment(parseDateNotif).format('HH.mm'))
        }
    }, [notif])

    return (
        <Card onClick={() => onReadNotification(action, notif)} className={`w-full gap-x-3 py-4 transition-all duration-200 rounded-xl cursor-pointer ${action === 10 ? 'border-indigo-200 bg-indigo-50/50 hover:bg-indigo-50' : 'bg-white'}`}>
            <Column className='gap-y-3'>
                <Column className='gap-y-1'>
                    <Text className='font-manrope font-semibold'>
                        {notif.title}
                    </Text>
                    <Caption>
                        {notif.body}
                    </Caption>
                </Column>
                <Overline>
                    {timeAgo}
                </Overline>
            </Column>
        </Card>
    )
}

export default CNotifications
