import React from "react";
import { KuesionerProvider } from "../context/kuesionerContext";
import KuesionerPageLayout from "../context/kuesionerContext/KuesionerPageLayout";

const KuesionerLulusanPage = () => {
  return (
    <KuesionerProvider>
      <KuesionerPageLayout />
    </KuesionerProvider>
  );
};

export default KuesionerLulusanPage;
