import { Navigate, Outlet } from 'react-router-dom'
import { identifyUserKey, loginRoute, prodi, prodiDoubleCheck, refreshTokenKey, tokenApiKey } from '../shared/variable'
import Cookies from 'universal-cookie'
import jwtDecode from 'jwt-decode'
import NotFound from '../pages/notfound'

const ProPrivateRoutes = () => {

    const cookies = new Cookies()

    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    const identifyUser = localStorage.getItem(identifyUserKey)
    const tokenApi = localStorage.getItem(tokenApiKey)

    let decodeCookies;
    let decodeLocal;

    if (tokenCookies != null) {
        decodeCookies = jwtDecode(tokenCookies)
    }
    if (tokenApi != null) {
        decodeLocal = jwtDecode(tokenApi)
    }

    return (
        tokenCookies !== null ? (identifyUser == prodi && decodeCookies.kode == prodi && decodeLocal.kode == prodi && decodeCookies.nama_jabatan == prodiDoubleCheck && decodeLocal.nama_jabatan == prodiDoubleCheck) ? <Outlet /> : <NotFound /> : <Navigate to={loginRoute} />
    )
}

export default ProPrivateRoutes
