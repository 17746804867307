import { Dialog, Listbox, Menu, Popover, Transition } from "@headlessui/react";
import { Editor } from "@tinymce/tinymce-react";
import { AnimatePresence, motion } from "framer-motion";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Chart from "react-apexcharts";
import TextareaAutosize from "react-autosize-textarea/lib";
import { ToastIcon, Toaster, resolveValue } from "react-hot-toast";
import { BiLockAlt } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { HiChevronUpDown, HiEye, HiEyeSlash } from "react-icons/hi2";
import {
  TbArrowDown,
  TbArrowUp,
  TbArrowsSort,
  TbBrandFacebook,
  TbBrandTelegram,
  TbBrandWhatsapp,
  TbBrandX,
  TbCheck,
  TbChevronDown,
  TbChevronLeft,
  TbChevronRight,
  TbDotsVertical,
  TbPhoto,
  TbSearch,
  TbX,
} from "react-icons/tb";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { toastDuration } from "./variable";

const paginationOption = [5, 10, 20, 30, 50, 100];
let currentSortKey = null;

export const FullScreen = ({ id, className, children }) => {
  return (
    <div id={id} className={`full-screen ${className}`}>
      {children}
    </div>
  );
};

export const FullWidth = ({ id, className, children }) => {
  return (
    <div id={id} className={`full-width ${className}`}>
      {children}
    </div>
  );
};

export const FullHeight = ({ id, className, children }) => {
  return (
    <div id={id} className={`full-height ${className}`}>
      {children}
    </div>
  );
};

export const FullScHeight = ({ id, className, children, close }) => {
  return (
    <div id={id} className={`full-sc-height ${className}`} close={close}>
      {children}
    </div>
  );
};

export const Row = ({ id, children, className, onClick, onMouseEnter }) => {
  return (
    <div
      id={id}
      className={`row ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </div>
  );
};

export const Column = ({ id, refId, children, className, onClick }) => {
  return (
    <div
      ref={refId}
      id={id}
      className={`column ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const GridRow = ({ id, children, className }) => {
  return (
    <div id={id} className={`grid-row ${className}`}>
      {children}
    </div>
  );
};

export const GridCol = ({ id, children, className }) => {
  return (
    <div id={id} className={`grid-col ${className}`}>
      {children}
    </div>
  );
};

export const FullLargeHeadline = ({ id, children, className, onClick }) => {
  return (
    <h1
      id={id}
      className={`full-large-headline ${className}`}
      onClick={onClick}
    >
      {children}
    </h1>
  );
};

export const ExtraLargeHeadline1 = ({ id, children, className, onClick }) => {
  return (
    <h1
      id={id}
      className={`extra-large-headline1 ${className}`}
      onClick={onClick}
    >
      {children}
    </h1>
  );
};

export const ExtraLargeHeadline2 = ({ id, children, className, onClick }) => {
  return (
    <h1
      id={id}
      className={`extra-large-headline2 ${className}`}
      onClick={onClick}
    >
      {children}
    </h1>
  );
};

export const LargeHeadline = ({ id, children, className, onClick }) => {
  return (
    <h1 id={id} className={`large-headline ${className}`} onClick={onClick}>
      {children}
    </h1>
  );
};

export const Headline1 = ({ id, children, className, onClick }) => {
  return (
    <h1 id={id} className={`headline1 ${className}`} onClick={onClick}>
      {children}
    </h1>
  );
};

export const Headline2 = ({ id, children, className, onClick }) => {
  return (
    <h2 id={id} className={`headline2 ${className}`} onClick={onClick}>
      {children}
    </h2>
  );
};

export const Headline3 = ({ id, children, className, onClick }) => {
  return (
    <h3 id={id} className={`headline3 ${className}`} onClick={onClick}>
      {children}
    </h3>
  );
};

export const Title = ({ id, refId, children, className, onClick }) => {
  return (
    <h4 id={id} ref={refId} className={`title ${className}`} onClick={onClick}>
      {children}
    </h4>
  );
};

export const Subtitle = ({ id, children, className, onClick }) => {
  return (
    <h5 id={id} className={`subtitle ${className}`} onClick={onClick}>
      {children}
    </h5>
  );
};

export const Text = ({ id, children, className, onClick }) => {
  return (
    <p id={id} className={`text ${className}`} onClick={onClick}>
      {children}
    </p>
  );
};

export const Caption = ({ id, children, className, onClick, required }) => {
  return (
    <p id={id} className={`caption ${className}`} onClick={onClick}>
      {children} {required && <span className="text-red-500">*</span>}
    </p>
  );
};

export const Overline = ({ id, children, className, onClick }) => {
  return (
    <p id={id} className={`overlines ${className}`} onClick={onClick}>
      {children}
    </p>
  );
};

export const Form = ({
  id,
  name,
  refs,
  children,
  className,
  action,
  method,
  onSubmit,
}) => {
  return (
    <form
      ref={refs}
      action={action}
      onSubmit={onSubmit}
      method={method}
      id={id}
      name={name}
      className={className}
    >
      {children}
    </form>
  );
};

export const Input = ({
  action = 0,
  refs,
  value,
  id,
  name,
  label = null,
  labelClassName,
  className,
  type,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  required,
  optional,
  disabled,
  underline,
  maxLength,
  defaultValue,
  bgColor = null,
  minDate = null,
  maxDate = null,
}) => {
  const [typeInput, setTypeInput] = useState(type);

  const onInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    const result = {
      action: action,
      value: value,
    };
    onChange(result);
  };
  return (
    <div className="grid md:grid-cols-2">
      {label !== null && label !== "" && (
        <label
          htmlFor={id ?? name}
          className="text font-sans flex justify-start items-center md:w-[calc(100%-2.5rem)]  max-w-full"
        >
          <span>
            {required && <span className="text-red-500">*</span>}{" "}
            <span>{label}</span>{" "}
            {optional && (
              <span>
                {"("}
                <span className="italic">{`optional`}</span>
                {")"}
              </span>
            )}
          </span>
        </label>
      )}

      <input
        ref={refs}
        id={id}
        name={name}
        value={value}
        type={typeInput ?? "text"}
        onChange={action === 0 ? onChange : onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`input focus:border-indigo-500 focus:outline-none font-sans text-[16px] w-full h-10 mt-3 md:mt-0 ${className} ${
          disabled
            ? "bg-gray-100 text-gray-800 cursor-not-allowed"
            : bgColor ?? "bg-white"
        } ${
          underline
            ? "border-b-2 rounded-t-lg"
            : "border border-slate-200 rounded-md focus:ring-2 focus:ring-indigo-50"
        }`}
        placeholder={placeholder}
        required={required ?? false}
        disabled={disabled ?? false}
        defaultValue={defaultValue}
        min={typeInput === "date" ? minDate : null}
        max={typeInput === "date" ? maxDate : null}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        autoComplete="off"
      />
    </div>
  );
};

export const InputRadioButtonGrid = ({
  id,
  name,
  label = null,
  labelClassName,
  className,
  checkedValue = "",
  onChange,
  required,
  optional,
  radioOptions = [],
  inputClassname,
}) => {
  const onChangeRadio = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="grid">
      {label !== null && label !== "" && (
        <label
          className={`text font-sans flex justify-start items-start md:w-[calc(100%-2.5rem)]  max-w-full ${labelClassName}`}
        >
          <span>
            {required && <span className="text-red-500">* </span>}{" "}
            <span>{label}</span>{" "}
            {optional && (
              <span>
                {"("}
                <span className="italic">{`optional`}</span>
                {")"}
              </span>
            )}
          </span>
        </label>
      )}

      <div className="grid gap-2 mt-3 md:mt-0 w-max">
        {radioOptions.map((option, index) => (
          <div key={index} className={`cursor-pointer ${className}`}>
            <input
              id={`${id}-${index}`}
              name={name}
              type="radio"
              value={option.value}
              checked={checkedValue === option.value}
              onChange={onChangeRadio}
              className={`h-4 w-4 my-auto cursor-pointer appearance-none border-[3px] border-transparent bg-transparent ring-1 rounded-full ring-slate-400 transition-all duration-200 hover:border-slate-50 hover:bg-slate-400 checked:bg-indigo-700 checked:border-white checked:ring-indigo-700 checked:hover:bg-indigo-800 ${className}`}
            />
            <label
              className="text font-sans text-gray-800 py-3 px-3 cursor-pointer flex-1"
              htmlFor={`${id}-${index}`}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export const InputRadioButton = ({
  id,
  name,
  label = null,
  labelClassName,
  className,
  checkedValue = "",
  onChange,
  required,
  optional,
  radioOptions = [],
}) => {
  const onChangeRadio = (event) => {
    onChange(event.target.value);
  };

  //   const onChangeRadio = (event) => {
  //     let result = event;
  //     if (action !== 0) {
  //       const { value } = event.target;
  //       result = {
  //         value: value,
  //         action: action,
  //       };
  //     }
  //     onChange(result);
  //   };

  return (
    <div className="grid md:grid-cols-2">
      {label !== null && label !== "" && (
        <label
          className={`text font-sans flex justify-start items-start md:w-[calc(100%-2.5rem)]  max-w-full ${labelClassName}`}
        >
          <span>
            {required && <span className="text-red-500">* </span>}{" "}
            <span>{label}</span>{" "}
            {optional && (
              <span>
                {"("}
                <span className="italic">{`optional`}</span>
                {")"}
              </span>
            )}
          </span>
        </label>
      )}

      <div className="grid gap-2 mt-3 md:mt-0 w-max">
        {radioOptions.map((option, index) => (
          <div key={index} className={`cursor-pointer ${className}`}>
            <input
              id={`${id}-${index}`}
              name={name}
              type="radio"
              value={option.value}
              checked={checkedValue === option.value}
              onChange={onChangeRadio}
              className="h-4 w-4 my-auto cursor-pointer appearance-none border-[3px] border-transparent bg-transparent ring-1 rounded-full ring-slate-400 transition-all duration-200  checked:bg-indigo-700 checked:border-white checked:ring-indigo-700 checked:hover:bg-indigo-800"
            />
            <label
              className="text font-sans text-gray-800 py-3 px-3 cursor-pointer flex-1"
              htmlFor={`${id}-${index}`}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export const InputTextArea = ({
  action = 0,
  refs,
  id,
  name,
  label = null,
  labelClassName,
  className,
  type,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  required,
  optional,
  disabled,
  underline,
  maxLength,
  defaultValue,
  bgColor = null,
  minDate = null,
  maxDate = null,
}) => {
  const [typeInput, setTypeInput] = useState(type);

  const onInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    const result = {
      action: action,
      value: value,
    };
    onChange(result);
  };
  return (
    <div className="grid">
      {label !== null && label !== "" && (
        <label
          htmlFor={id ?? name}
          className={`text font-sans flex justify-start items-center ${labelClassName}`}
        >
          <span>
            {required && <span className="text-red-500">*</span>}{" "}
            <span className="text-justify">{label}</span>{" "}
            {optional && (
              <span>
                {"("}
                <span className="italic">{`optional`}</span>
                {")"}
              </span>
            )}
          </span>
        </label>
      )}

      <textarea
        ref={refs}
        id={id}
        name={name}
        type={typeInput ?? "text"}
        onChange={action === 0 ? onChange : onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`input focus:border-indigo-500 focus:outline-none w-full mt-3  ${className} ${
          disabled
            ? "bg-gray-100 text-gray-800 cursor-not-allowed"
            : bgColor ?? "bg-white"
        } ${
          underline
            ? "border-b-2 rounded-t-lg"
            : "border border-slate-300 rounded-md focus:ring-2 focus:ring-indigo-50"
        }`}
        placeholder={placeholder}
        required={required ?? false}
        disabled={disabled ?? false}
        defaultValue={defaultValue}
        min={typeInput === "date" ? minDate : null}
        max={typeInput === "date" ? maxDate : null}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        autoComplete="off"
      />
    </div>
  );
};

export const ButtonGradient = ({ children, type }) => {
  return (
    <button
      type={type}
      className="bg-gradient-to-r w-full h-[48px] mt-6 from-orange-400 via-yellow-400 to-green-300 text-center text-white uppercase rounded-full font-semibold
                   transition-colors duration-300 delay-0 hover:delay-300 hover:via-yellow-400 hover:from-green-300 hover:to-orange-400"
    >
      {children}
    </button>
  );
};

export const OutlineInput = ({
  action = 0,
  refs,
  id,
  name,
  label = null,
  labelClassName,
  className,
  type,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  required,
  disabled,
  underline,
  maxLength,
  defaultValue,
  mb,
  inputWidth,
  togglePass,
  bgColor = null,
  prefix = null,
  prefixClassName,
  minDate = null,
  maxDate = null,
  helperText = null,
}) => {
  const [typeInput, setTypeInput] = useState(type);

  const onClickVisiblePassword = () => {
    if (typeInput === "password") {
      setTypeInput("text");
    } else {
      setTypeInput("password");
    }
  };

  const onInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    const result = {
      action: action,
      value: value,
    };
    onChange(result);
  };

  const handleKeyDown = (e) => {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Column className={`w-fit gap-1.5 ${mb} ${inputWidth}`}>
      {label !== null && label !== "" && (
        <label
          htmlFor={id ?? name}
          className={`caption w-fit ${labelClassName}`}
        >
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="relative">
        {prefix !== null && (
          <div
            className={`absolute flex inset-y-0 items-center ${prefixClassName}`}
          >
            {prefix}
          </div>
        )}
        <input
          ref={refs}
          id={id}
          name={name}
          type={typeInput ?? "text"}
          onChange={action === 0 ? onChange : onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={`input focus:border-indigo-500 focus:outline-none ${className} ${inputWidth} ${
            disabled
              ? "bg-gray-100 text-gray-800 cursor-not-allowed"
              : bgColor ?? "bg-gray-50"
          } ${
            underline
              ? "border-b-2 rounded-t-lg"
              : "border-[1px] rounded-lg focus:ring-2 focus:ring-indigo-50"
          }`}
          placeholder={placeholder ?? "Outline Input"}
          required={required ?? false}
          disabled={disabled ?? false}
          defaultValue={defaultValue}
          min={typeInput === "date" ? minDate : null}
          max={typeInput === "date" ? maxDate : null}
          maxLength={maxLength}
          onKeyDown={onKeyDown ? handleKeyDown : undefined}
          autoComplete="off"
        />
        <div className="absolute inset-y-0 right-4 flex items-center">
          {togglePass && type === "password" && (
            <button
              type="button"
              onClick={onClickVisiblePassword}
              className="p-1.5 rounded-full translate-x-1.5 text-slate-500 hover:text-slate-600"
            >
              {typeInput === "password" ? (
                <HiEyeSlash className="h-6 w-6" />
              ) : (
                <HiEye className="h-6 w-6" />
              )}
            </button>
          )}
        </div>
      </div>
      {helperText !== null && helperText !== "" && (
        <small className={`overlines px-4 ${labelClassName}`}>
          {helperText}
        </small>
      )}
    </Column>
  );
};

export const OutlineInput2 = ({
  action = 0,
  refs,
  id,
  name,

  label = null,
  labelClassName,
  className,
  type,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  required,
  disabled,
  underline,
  maxLength,
  defaultValue,
  mb,
  inputWidth,
  togglePass,
  bgColor = null,
  prefix = null,
  prefixClassName,
  minDate = null,
  maxDate = null,
  helperText = null,
}) => {
  const [typeInput, setTypeInput] = useState(type);

  const onClickVisiblePassword = () => {
    if (typeInput === "password") {
      setTypeInput("text");
    } else {
      setTypeInput("password");
    }
  };

  const onInputChange = (event) => {
    const { value } = event.target;
    onChange(value); // Kirimkan langsung nilai baru ke fungsi onChange dari parent
  };

  return (
    <Column className={`w-fit gap-1.5 ${mb} ${inputWidth}`}>
      {label !== null && label !== "" && (
        <label
          htmlFor={id ?? name}
          className={`caption w-fit ${labelClassName}`}
        >
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="relative">
        {prefix !== null && (
          <div
            className={`absolute flex inset-y-0 items-center ${prefixClassName}`}
          >
            {prefix}
          </div>
        )}
        <input
          ref={refs}
          id={id}
          name={name}
          type={typeInput ?? "text"}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={`input focus:border-indigo-500 focus:outline-none ${className} ${inputWidth} ${
            disabled
              ? "bg-gray-100 text-gray-800 cursor-not-allowed"
              : bgColor ?? "bg-gray-50"
          } ${
            underline
              ? "border-b-2 rounded-t-lg"
              : "border-[1px] rounded-lg focus:ring-2 focus:ring-indigo-50"
          }`}
          placeholder={placeholder ?? "Outline Input"}
          required={required ?? false}
          disabled={disabled ?? false}
          defaultValue={defaultValue}
          min={typeInput === "date" ? minDate : null}
          max={typeInput === "date" ? maxDate : null}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          autoComplete="off"
        />
        <div className="absolute inset-y-0 right-4 flex items-center">
          {togglePass && type === "password" && (
            <button
              type="button"
              onClick={onClickVisiblePassword}
              className="p-1.5 rounded-full translate-x-1.5 text-slate-500 hover:text-slate-600"
            >
              {typeInput === "password" ? (
                <HiEyeSlash className="h-6 w-6" />
              ) : (
                <HiEye className="h-6 w-6" />
              )}
            </button>
          )}
        </div>
      </div>
      {helperText !== null && helperText !== "" && (
        <small className={`overlines px-4 ${labelClassName}`}>
          {helperText}
        </small>
      )}
    </Column>
  );
};

export const FilledInput = ({
  id,
  name,
  label,
  labelClassName,
  className,
  type,
  onChange,
  placeholder,
  required,
  disabled,
  underline,
  maxLength,
  defaultValue,
  mb,
  togglePass,
  prefix,
}) => {
  const [typeInput, setTypeInput] = useState(type);

  const onClickVisiblePassword = () => {
    if (typeInput === "password") {
      setTypeInput("text");
    } else {
      setTypeInput("password");
    }
  };

  return (
    <div className={`column gap-1.5 ${mb}`}>
      {label !== null && (
        <label htmlFor={id ?? name} className={`caption ${labelClassName}`}>
          {label}
        </label>
      )}
      <div className="relative">
        <div className="absolute inset-y-0 left-4 flex items-center">
          {prefix}
        </div>
        <input
          id={id}
          name={name}
          type={typeInput ?? "text"}
          onChange={onChange}
          className={`input focus:outline-none ${
            disabled ? "bg-gray-100 text-gray-500" : "bg-gray-50"
          } ${
            underline
              ? "border-b-2 rounded-t-lg focus:border-blue-700"
              : "rounded-lg"
          } ${prefix && "pl-12"} ${togglePass && "pr-12"} ${className}`}
          placeholder={placeholder ?? "Filled Input"}
          required={required ?? false}
          disabled={disabled ?? false}
          defaultValue={defaultValue}
          maxLength={maxLength}
          autoComplete="off"
        />
        <div className="absolute inset-y-0 right-4 flex items-center">
          {disabled && (
            <div className="p-1.5">
              <BiLockAlt className="h-5 w-5 text-gray-500" />
            </div>
          )}
          {togglePass && type === "password" && (
            <button
              onClick={onClickVisiblePassword}
              className="p-1.5 rounded-full translate-x-1.5 text-gray-800 hover:text-gray-800 hover:bg-slate-100"
            >
              {typeInput === "password" ? (
                <HiEyeSlash className="h-5 w-5" />
              ) : (
                <HiEye className="h-5 w-5" />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const OutlineTextArea = ({
  action = 0,
  id,
  name,
  refs,
  label = null,
  labelClassName,
  className,
  type,
  onChange,
  onKeyDown,
  placeholder,
  required,
  disabled,
  underline,
  maxLength,
  defaultValue,
  mb,
  inputWidth,
  initialRow = 1,
  maxRows = 10,
  bgColor = null,
  autoFocus = false,
  helperText = null,
}) => {
  const onInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    const result = {
      action: action,
      value: value,
    };
    onChange(result);
  };
  return (
    <div className={`column gap-1.5 ${mb} ${inputWidth}`}>
      {label !== null && (
        <label htmlFor={id ?? name} className={`caption ${labelClassName}`}>
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <TextareaAutosize
        id={id}
        name={name}
        ref={refs}
        type={type ?? "text"}
        spellCheck={false}
        onChange={action === 0 ? onChange : onInputChange}
        rows={initialRow}
        maxRows={maxRows}
        onKeyDown={onKeyDown}
        className={`input scrollbar-hide focus:border-indigo-500 focus:outline-none ${inputWidth} ${
          disabled ? "bg-gray-100" : bgColor ?? "bg-gray-50"
        } ${
          underline
            ? `border-b-2 rounded-t-lg ${className}`
            : `border-[1px] rounded-lg focus:ring-2 focus:ring-indigo-50 ${className}`
        }`}
        placeholder={placeholder ?? "Outline Textarea"}
        required={required ?? false}
        disabled={disabled ?? false}
        maxLength={maxLength}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
      />
      {helperText !== null && helperText !== "" && (
        <small className={`overlines px-4 ${labelClassName}`}>
          {helperText}
        </small>
      )}
    </div>
  );
};

export const FilledTextArea = ({
  id,
  name,
  label,
  labelClassName,
  className,
  type,
  onChange,
  placeholder,
  required,
  disabled,
  underline,
  maxLines,
  maxLength,
  defaultValue,
  mb,
}) => {
  return (
    <div className={`column gap-1.5 ${mb}`}>
      {label != null && (
        <label htmlFor={id ?? name} className={`caption ${labelClassName}`}>
          {label}
        </label>
      )}
      <textarea
        id={id}
        name={name}
        type={type ?? "text"}
        onChange={onChange}
        className={`input focus:outline-none ${className} ${
          disabled ? "bg-gray-100" : "bg-gray-50"
        } ${
          underline
            ? "border-b-2 rounded-t-lg focus:border-blue-700"
            : "rounded-lg"
        }`}
        placeholder={placeholder ?? "Filled Textarea"}
        required={required ?? false}
        disabled={disabled ?? false}
        maxLines={maxLines}
        maxLength={maxLength}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const TextEditor = ({
  id,
  label,
  labelClassName,
  height = 500,
  showMenuBar = true,
  required,
  mb_label,
  onChange,
}) => {
  const handleOnChangeEditor = (value) => {
    onChange(value);
  };

  return (
    <Column className={`gap-1.5 ${mb_label}`}>
      {label !== null && (
        <label htmlFor={id} className={`caption ${labelClassName}`}>
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Editor
        id={id}
        apiKey="o9vdaiol7yu6q6z0tt9lhfzkq8nwgpyif8lltpcz3rt925ro"
        onEditorChange={handleOnChangeEditor}
        init={{
          selector: "textarea",
          height: height,
          menubar: showMenuBar,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | link removeformat",
          content_style:
            '@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap"); ' +
            "p { margin: 0; padding: 0; } body { font-family: Inter, sans-serif; font-size: 1rem; line-height: 1.5rem; color: rgb(55,65,81); font-weight: 350; scrollbar-hide; };",
        }}
      />
    </Column>
  );
};

export const RadioButton = ({
  action = 0,
  id,
  name,
  value,
  label = "Label Radio Button",
  centerLabel = false,
  className,
  onChange,
  checked = false,
}) => {
  const onChangeRadio = (event) => {
    let result = event;
    if (action !== 0) {
      const { value } = event.target;
      result = {
        value: value,
        action: action,
      };
    }
    onChange(result);
  };

  return (
    <Row
      className={`cursor-pointer ${checked && "bg-indigo-50/50"} ${className}`}
    >
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChangeRadio}
        className="h-4 w-4 my-auto cursor-pointer appearance-none border-[3px] border-transparent bg-transparent ring-1 rounded-full ring-slate-400 transition-all duration-200 hover:border-slate-50 hover:bg-slate-400 checked:bg-indigo-700 checked:border-white checked:ring-indigo-700 checked:hover:bg-indigo-800"
      />
      <label
        className={`caption text-gray-800 py-3 px-3 cursor-pointer flex-1 ${
          centerLabel && `text-center`
        }`}
        htmlFor={id}
      >
        {label}
      </label>
    </Row>
  );
};

export const CheckboxButton = ({
  action = 0,
  id,
  name,
  label = "Label Checkbox Button",
  centerLabel = false,
  className,
  onChange,
  checked,
}) => {
  const onChangeCheckbox = (event) => {
    let result = event;
    if (action !== 0) {
      const { checked } = event.target;
      result = {
        value: checked,
        action: action,
      };
    }
    onChange(result);
  };

  return (
    <Row
      className={`w-fit relative cursor-pointer ${
        checked && "bg-indigo-50/50"
      } ${className}`}
    >
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked ?? false}
        onChange={onChangeCheckbox}
        className="h-4 w-4 my-auto cursor-pointer appearance-none border border-transparent bg-transparent ring-1 rounded-[0.25rem] transition-all duration-200 ring-slate-400 hover:ring-indigo-600 checked:ring-indigo-700 checked:bg-indigo-700"
      />
      <TbCheck
        className={`h-4 w-4 absolute inset-y-0 my-auto text-white transition-all duration-200 ${
          checked ? "opacity-100" : "opacity-0"
        }`}
      />
      <label
        className={`caption text-gray-800 py-3 px-3 cursor-pointer flex-1 ${
          centerLabel && `text-center`
        }`}
        htmlFor={id}
      >
        {label}
      </label>
    </Row>
  );
};

export const SelectInput = ({
  action = 0,
  options = [],
  width = "w-full",
  maxHeight = "max-h-64",
  name,
  onChange,
  label = null,
  labelClassName,
  placeholder = null,
  required = false,
  initial = null,
  zindex,
  background,
}) => {
  const [optionSelected, setOptionSelected] = useState({
    label: "Pilih salah satu",
    value: -100,
  });

  const onChangeSelected = (value) => {
    setOptionSelected(value);
    let result = value;
    if (action !== 0) {
      result = {
        action: action,
        value: value,
      };
    }
    onChange(result);
  };

  useEffect(() => {
    setOptionSelected(
      initial ?? {
        label: "Pilih salah satu",
        value: -100,
      }
    );
  }, [initial]);

  return (
    <Column className="gap-1.5">
      {label != null && (
        <label htmlFor={name} className={`caption ${labelClassName}`}>
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Listbox name={name} value={optionSelected} onChange={onChangeSelected}>
        <div className={`relative ${width} ${zindex}`}>
          <Listbox.Button
            className={`relative cursor-pointer rounded-lg py-2.5 pl-4 pr-2 text-left ${
              background ? background : "bg-white"
            } border border-gray-200 ${width}`}
          >
            <Row className="gap-2">
              <Caption
                className={`${
                  optionSelected.value === -100
                    ? "text-gray-500"
                    : "text-gray-800"
                } w-fit flex-1`}
              >
                {optionSelected.label}
              </Caption>
              <HiChevronUpDown className="h-5 w-5 text-slate-500" />
            </Row>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition ease-in-out duration-200"
            enterFrom="-translate-y-8 opacity-0"
            enterTo="translate-y-1 opacity-100"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute w-full ${maxHeight} flex flex-col h-fit  overflow-y-auto divide-y divide-gray-200 scrollbar-hide rounded-lg bg-white border border-gray-200 shadow-xl shadow-slate-200/50`}
            >
              {options.map((option, idx) => (
                <Listbox.Option
                  key={idx}
                  className={`relative cursor-pointer select-none py-2.5 px-4 ${
                    option.value === optionSelected.value
                      ? "text-indigo-700 bg-indigo-50/50"
                      : "hover:bg-slate-50 text-gray-800 hover:text-indigo-700"
                  }`}
                  value={option}
                >
                  <Row>
                    <p className="flex-1 text-sm inter">{option.label}</p>
                    {option.value === optionSelected.value && (
                      <TbCheck className="h-5 w-5 text-indigo-700" />
                    )}
                  </Row>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </Column>
  );
};

export const Card = ({ id, children, className, onClick }) => {
  return (
    <div id={id} className={`card ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export const PrimaryButton = ({
  id,
  name,
  className,
  type,
  onClick,
  children,
  color,
  pills,
  leadingIcon,
  trailingIcon,
  trailingIconLeft,
  disabled,
}) => {
  const [btnColor, setBtnColor] = useState({
    bgColor: "",
    textColor: "",
    hoverColor: "",
  });

  useEffect(() => {
    switch (color) {
      case "red":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-red-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-red-700" };
        });
        break;
      case "green":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-green-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-green-700" };
        });
        break;
      case "purple":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-purple-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-purple-700" };
        });
        break;
      case "orange":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-orange-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-orange-700" };
        });
        break;
      case "indigo":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-indigo-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-indigo-700" };
        });
        break;
      case "blue":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-blue-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-blue-700" };
        });
        break;
      case "pink":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-pink-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-pink-700" };
        });
        break;
      case "amber":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-amber-500" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-amber-600" };
        });
        break;
      case "yellow":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-yellow-500" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-yellow-600" };
        });
        break;
      default:
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-black" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-gray-800" };
        });
        break;
    }
  }, [color]);

  return (
    <button
      id={id}
      name={name}
      type={type ?? "button"}
      onClick={onClick}
      className={`primary-btn ${
        disabled
          ? "text-gray-400 bg-gray-200 cursor-not-allowed"
          : btnColor.bgColor +
            " " +
            btnColor.textColor +
            " " +
            btnColor.hoverColor
      } ${pills && "rounded-full"} ${className}`}
      disabled={disabled}
    >
      <Row className="w-fit mx-auto gap-2">
        {trailingIconLeft}
        <Row className="w-fit mx-auto gap-2">
          {leadingIcon}
          {children}
          {trailingIcon}
        </Row>
      </Row>
    </button>
  );
};

export const SecondaryButton = ({
  id,
  name,
  className,
  type,
  onClick,
  children,
  color,
  pills,
  leadingIcon,
  trailingIcon,
  disabled,
}) => {
  const [btnColor, setBtnColor] = useState({
    bgColor: "",
    textColor: "",
    hoverColor: "",
  });

  useEffect(() => {
    switch (color) {
      case "red":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-red-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-red-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-red-100" };
        });
        break;
      case "green":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-green-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-green-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-green-100" };
        });
        break;
      case "purple":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-purple-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-purple-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-purple-100" };
        });
        break;
      case "orange":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-orange-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-orange-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-orange-100" };
        });
        break;
      case "indigo":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-indigo-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-indigo-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-indigo-100" };
        });
        break;
      case "blue":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-blue-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-blue-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-blue-100" };
        });
        break;
      case "pink":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-pink-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-pink-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-pink-100" };
        });
        break;
      case "amber":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-amber-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-amber-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-amber-100" };
        });
        break;
      case "yellow":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-yellow-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-yellow-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-yellow-100" };
        });
        break;
      case "transparant":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-transparent" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-gray-800" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-gray-100" };
        });
        break;
      case "custom":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "" };
        });
        break;
      default:
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-gray-100" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-gray-800" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-gray-200" };
        });
        break;
    }
  }, [color]);

  return (
    <button
      id={id}
      name={name}
      type={type ?? "button"}
      onClick={onClick}
      className={`secondary-btn ${
        disabled
          ? "text-gray-400 bg-gray-200 cursor-not-allowed"
          : btnColor.bgColor +
            " " +
            btnColor.textColor +
            " " +
            btnColor.hoverColor
      } ${pills && "rounded-full"} ${className}`}
      disabled={disabled}
    >
      <Row className="w-fit mx-auto gap-2">
        {leadingIcon}
        {children}
        {trailingIcon}
      </Row>
    </button>
  );
};

export const TertiaryButton = ({
  id,
  name,
  className,
  type,
  onClick,
  children,
  color,
  pills,
  leadingIcon,
  trailingIcon,
  disabled,
}) => {
  const [btnColor, setBtnColor] = useState({
    borderColor: "",
    textColor: "",
    hoverColor: "",
  });

  useEffect(() => {
    switch (color) {
      case "red":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-red-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-red-500" };
        });
        break;
      case "green":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-green-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-green-500" };
        });
        break;
      case "purple":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-purple-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-purple-500" };
        });
        break;
      case "orange":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-orange-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-orange-500" };
        });
        break;
      case "indigo":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-indigo-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-indigo-500" };
        });
        break;
      case "blue":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-blue-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-blue-500" };
        });
        break;
      case "amber":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-amber-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-amber-500" };
        });
        break;
      case "yellow":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-yellow-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-yellow-500" };
        });
        break;
      case "pink":
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-pink-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-pink-500" };
        });
        break;
      default:
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-slate-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-gray-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:border-gray-500" };
        });
        break;
    }
  }, [color]);

  return (
    <button
      id={id}
      name={name}
      type={type ?? "button"}
      onClick={onClick}
      className={`tertiary-btn ${
        disabled
          ? "text-gray-400 border-gray-200 cursor-not-allowed"
          : btnColor.borderColor +
            " " +
            btnColor.textColor +
            " " +
            btnColor.hoverColor
      } ${pills && "rounded-full"} ${className}`}
      disabled={disabled}
    >
      <Row className="w-fit mx-auto gap-2">
        {leadingIcon}
        {children}
        {trailingIcon}
      </Row>
    </button>
  );
};

export const TextButton = ({
  id,
  name,
  className,
  type,
  onClick,
  children,
  color,
  leadingIcon,
  trailingIcon,
  disabled,
  align = "center",
}) => {
  const [btnColor, setBtnColor] = useState({
    textColor: "",
    hoverColor: "",
  });

  useEffect(() => {
    switch (color) {
      case "red":
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-red-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:text-red-700" };
        });
        break;
      case "green":
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-green-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:text-green-700" };
        });
        break;
      case "purple":
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-purple-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:text-purple-700" };
        });
        break;
      case "orange":
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-orange-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:text-orange-700" };
        });
        break;
      case "indigo":
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-indigo-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:text-indigo-700" };
        });
        break;
      case "blue":
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-blue-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:text-blue-700" };
        });
        break;
      case "custom":
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "" };
        });
        break;
      default:
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-gray-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:text-gray-800" };
        });
        break;
    }
  }, [color]);

  return (
    <button
      id={id}
      name={name}
      type={type ?? "button"}
      onClick={onClick}
      className={`text-btn ${
        disabled
          ? "text-gray-400 cursor-not-allowed"
          : btnColor.textColor + " " + btnColor.hoverColor
      } ${className}`}
      disabled={disabled}
    >
      <Row className={`w-fit gap-x-2 ${align === "center" && "mx-auto"}`}>
        {leadingIcon}
        {children}
        {trailingIcon}
      </Row>
    </button>
  );
};

export const IconButton = ({
  id,
  name,
  type,
  children,
  className,
  onClick,
  color,
  bordered,
  disabled,
}) => {
  const [btnColor, setBtnColor] = useState({
    bgColor: "",
    textColor: "",
    hoverColor: "",
    borderColor: "",
    textBorderColor: "",
    hoverBorderColor: "",
  });

  useEffect(() => {
    switch (color) {
      case "red":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-red-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-red-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-red-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "text-red-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "hover:bg-red-50" };
        });
        break;
      case "green":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-green-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-green-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-green-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "text-green-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "hover:bg-green-50" };
        });
        break;
      case "purple":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-purple-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-purple-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-purple-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "text-purple-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "hover:bg-purple-50" };
        });
        break;
      case "orange":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-orange-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-orange-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-orange-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "text-orange-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "hover:bg-orange-50" };
        });
        break;
      case "indigo":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-indigo-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-indigo-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-indigo-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "text-indigo-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "hover:bg-indigo-50" };
        });
        break;
      case "blue":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-blue-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-blue-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-blue-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "text-blue-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "hover:bg-blue-50" };
        });
        break;
      case "pink":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-pink-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-white" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-pink-700" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-pink-300" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "text-pink-600" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "hover:bg-pink-50" };
        });
        break;
      case "custom":
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "" };
        });
        break;
      default:
        setBtnColor((btnColor) => {
          return { ...btnColor, bgColor: "bg-transparent" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textColor: "text-gray-800" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverColor: "hover:bg-gray-50" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, borderColor: "border-gray-200" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, textBorderColor: "text-gray-800" };
        });
        setBtnColor((btnColor) => {
          return { ...btnColor, hoverBorderColor: "hover:bg-gray-50" };
        });
        break;
    }
  }, [color]);

  return (
    <button
      id={id}
      name={name}
      type={type ?? "button"}
      className={`icon-btn transition-all duration-200 ${
        bordered && "border"
      } ${
        disabled
          ? "text-gray-400 bg-gray-200 cursor-not-allowed"
          : bordered
          ? btnColor.borderColor +
            " " +
            btnColor.textBorderColor +
            " " +
            btnColor.hoverBorderColor
          : btnColor.bgColor +
            " " +
            btnColor.textColor +
            " " +
            btnColor.hoverColor
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const MenuButton = ({
  className,
  id,
  icon = "",
  title,
  onClick,
  pressEffect = true,
  scaleOnHover = true,
}) => {
  return (
    <motion.a
      id={id}
      onClick={onClick}
      whileHover={{
        scale: scaleOnHover ? 1.02 : 1.0,
        cursor: "pointer",
      }}
      whileTap={
        pressEffect
          ? {
              scale: 0.99,
            }
          : {}
      }
      className={`menu-btn hover:text-gray-800 hover:bg-slate-50 ${className}`}
    >
      {icon !== "" && <div className="my-auto">{icon}</div>}
      <span className="my-auto flex-shrink-0">{title ?? "Text Button"}</span>
    </motion.a>
  );
};

export const Appbar = ({ children, className }) => {
  return <div className={`appbar ${className}`}>{children}</div>;
};

export const AppbarMenu = ({ id, className, children }) => {
  return (
    <ul id={id} className={`appbar-menu ${className}`}>
      {children}
    </ul>
  );
};

export const AppbarItem = ({
  id,
  icon = "",
  title,
  onClick,
  active = false,
  className,
}) => {
  return (
    <li
      id={id}
      onClick={onClick}
      className={`appbar-item transition-all duration-200 ${className} ${
        active
          ? "font-semibold text-indigo-600"
          : "text-gray-800 hover:text-indigo-600"
      }`}
    >
      {icon !== "" && <div className="mr-3 my-auto">{icon}</div>}
      <span className="my-auto">{title ?? "Sidebar Menu"}</span>
    </li>
  );
};

export const Sidebar = ({
  children,
  className,
  showOnMobile,
  hide = false,
}) => {
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (showOnMobile !== "0") {
      setShowSidebar(true);
    } else if (showOnMobile === "CLOSECUY") {
      setShowSidebar(false);
    }
  }, [showOnMobile]);

  return (
    <>
      <Transition
        show={showSidebar}
        className={"fixed inset-0 border-none z-30 bg-black/40"}
        enter="transition ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        onClick={() => setShowSidebar(false)}
      />
      <aside
        className={`sidebar ${
          showSidebar
            ? "translate-x-0"
            : hide
            ? "-translate-x-full"
            : "xl:translate-x-0 xl:border-r-[1px] xl:border-r-gray-200"
        } ${className}`}
      >
        {children}
      </aside>
    </>
  );
};

export const SidebarHeader = ({ className, children }) => {
  return (
    <div className={`relative w-full h-fit px-4 py-6 ${className}`}>
      {children}
    </div>
  );
};

export const SidebarMenu = ({ id, className, children }) => {
  return (
    <ul id={id} className={`sidebar-menu ${className}`}>
      {children}
    </ul>
  );
};

export const SidebarItem = ({
  id,
  icon,
  title,
  onClick,
  active = "",
  activeSubmenu = "",
  trailing,
  className,
  asSubMenu = false,
  useSubMenu = false,
  submenu,
}) => {
  return (
    <>
      <li id={id}>
        <motion.a
          id={id}
          onClick={onClick}
          className={`sidebar-item ${className} ${
            asSubMenu
              ? active === id
                ? "text-indigo-700 bg-indigo-50/50"
                : "text-[#343F52] hover:text-gray-800 hover:bg-slate-50"
              : active === id && activeSubmenu === id
              ? "text-indigo-700 bg-indigo-50/50"
              : active === id && activeSubmenu !== id
              ? "text-indigo-700"
              : "text-[#343F52] hover:text-gray-800 hover:bg-slate-50"
          }`}
        >
          {icon !== null && icon !== "" && (
            <div className="mr-3 my-auto">{icon}</div>
          )}
          <span className="my-auto flex-1 text-sm sm:text-base">
            {title ?? "Sidebar Menu"}
          </span>
          {trailing !== null && trailing !== "" && (
            <div className="ml-3 my-auto">{trailing}</div>
          )}
          {useSubMenu && (
            <TbChevronDown
              className={`h-5 w-5 ${active === id ? "rotate-180" : "rotate-0"}`}
            />
          )}
        </motion.a>
      </li>
      {useSubMenu && active === id && submenu}
    </>
  );
};

export const SideNotifPanel = ({
  children,
  className,
  showNotifPanel = false,
  hideNotifPanel = () => {},
  closeBtnAlign = "left",
}) => {
  return (
    <>
      <div
        onClick={() => hideNotifPanel(false)}
        className={`top-0 w-screen h-screen bg-black/20 z-30 ${
          !showNotifPanel ? "hidden" : "fixed"
        }`}
      />
      <FullScHeight
        className={`notif-panel ${
          showNotifPanel ? "translate-x-0" : "translate-x-full"
        } ${className}`}
      >
        {children}
        <ButtonHover
          className={`absolute top-3 ${
            closeBtnAlign === "left" ? "left-4" : "right-4"
          }`}
        >
          <IconButton onClick={() => hideNotifPanel(false)}>
            <TbX className="h-6 w-6" />
          </IconButton>
        </ButtonHover>
      </FullScHeight>
    </>
  );
};

export const SimpleAlert = ({
  id,
  className,
  type,
  children,
  icon,
  borderLeft,
  bordered,
  showCloseBtn,
}) => {
  const [hidden, setHidden] = useState(false);
  const [colorAlert, setColorAlert] = useState({
    bgColor: "",
    contentColor: "",
    borderLeftColor: "",
    borderColor: "",
  });

  const hiddenAlert = () => {
    setHidden(true);
  };

  useEffect(() => {
    switch (type) {
      case "info":
        setColorAlert((colorAlert) => {
          return { ...colorAlert, bgColor: "bg-indigo-50" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, contentColor: "text-indigo-700" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderLeftColor: "border-l-indigo-600" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderColor: "border-indigo-300" };
        });
        break;
      case "warning":
        setColorAlert((colorAlert) => {
          return { ...colorAlert, bgColor: "bg-orange-50" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, contentColor: "text-orange-700" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderLeftColor: "border-l-orange-600" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderColor: "border-orange-300" };
        });
        break;
      case "error":
        setColorAlert((colorAlert) => {
          return { ...colorAlert, bgColor: "bg-red-50" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, contentColor: "text-red-700" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderLeftColor: "border-l-red-600" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderColor: "border-red-300" };
        });
        break;
      case "success":
        setColorAlert((colorAlert) => {
          return { ...colorAlert, bgColor: "bg-green-50" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, contentColor: "text-green-700" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderLeftColor: "border-l-green-600" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderColor: "border-green-300" };
        });
        break;
      case "custom":
        setColorAlert((colorAlert) => {
          return { ...colorAlert, bgColor: "" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, contentColor: "" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderLeftColor: "" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderColor: "" };
        });
        break;
      default:
        setColorAlert((colorAlert) => {
          return { ...colorAlert, bgColor: "bg-gray-50" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, contentColor: "text-gray-800" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderLeftColor: "border-l-gray-600" };
        });
        setColorAlert((colorAlert) => {
          return { ...colorAlert, borderColor: "border-gray-300" };
        });
        break;
    }
  }, [type]);

  return (
    <div
      id={id}
      className={`row simple-alert ${colorAlert.bgColor} ${
        borderLeft && "border-l-[3.5px] " + colorAlert.borderLeftColor
      } ${bordered && "border-[1px] " + colorAlert.borderColor} ${className} ${
        hidden && "hidden"
      }`}
    >
      {icon}
      <Caption className={`${colorAlert.contentColor} flex-1`}>
        {children}
      </Caption>
      {showCloseBtn && (
        <button
          type="button"
          onClick={hiddenAlert}
          className="cursor-pointer w-fit h-fit"
        >
          <BsX className={`${colorAlert.contentColor} h-5 w-5`} />
        </button>
      )}
    </div>
  );
};

export const Avatar = ({ id, className, src = "" }) => {
  return (
    <div id={id} className={`avatar ${className}`}>
      {src !== "" ? (
        <img src={src} alt="img" loading={`lazy`} />
      ) : (
        <GridRow className="h-full gap-0 translate-y-1">
          <FullHeight className="flex w-full">
            <div className="avatar-placeholder1"></div>
          </FullHeight>
          <div className="flex h-full">
            <div className="avatar-placeholder2"></div>
          </div>
        </GridRow>
      )}
    </div>
  );
};

export const Image = ({
  id,
  className,
  imgClasName,
  hoverScale = true,
  src = null,
  removeBgColor = false,
  alt = "Images",
}) => {
  return (
    <div id={id} className={`img ${className}`}>
      {src !== null && src !== "" ? (
        <img
          src={src}
          className={`my-auto object-cover transition-all duration-200 ${
            !removeBgColor && "bg-gray-100"
          } ${hoverScale && "hover:scale-105"} ${imgClasName}`}
          loading="lazy"
          alt={alt}
        />
      ) : (
        <div className="flex w-full h-full bg-gray-100">
          <TbPhoto className="w-1/3 h-auto my-auto mx-auto text-slate-300 stroke-1.5" />
        </div>
      )}
    </div>
  );
};

export const DotSeparated = ({ id, className }) => {
  return <div id={id} className={`dot-separated ${className}`} />;
};

export const Tabs = ({
  className,
  children,
  selectedTabIdx,
  onChangeTabIndex,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTabIdx);

  useEffect(() => {
    onChangeTabIndex(selectedTabIndex);
  }, [selectedTabIdx, selectedTabIndex]);

  return (
    <Column>
      <div
        className={`w-fit max-w-full flex flex-row border-[1px] bg-white border-gray-200 rounded-lg overflow-x-auto scrollbar-hide scroll-smooth ${className}`}
      >
        <ul className="w-full flex flex-row divide-x-[1px] divide-gray-200">
          {children.map((item, idx) => {
            return (
              <TabTitle
                key={idx}
                icon={item.props.icon}
                title={item.props.title}
                idx={idx}
                active={idx === selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                textActiveColor={item.props.textActiveColor}
              />
            );
          })}
        </ul>
      </div>
      <AnimatePresence mode="wait" initial={false}>
        <TransitionPage key={selectedTabIndex} horizontal={true}>
          {children[selectedTabIndex]}
        </TransitionPage>
      </AnimatePresence>
    </Column>
  );
};

export const TabTitle = ({
  icon,
  title,
  idx,
  active,
  setSelectedTabIndex,
  textActiveColor,
}) => {
  const onClickTab = useCallback(() => {
    setSelectedTabIndex(idx);
  }, [setSelectedTabIndex, idx]);

  return (
    <li className="flex-shrink-0">
      <button
        onClick={onClickTab}
        className={`flex flex-row w-fit gap-1.5 py-2 font-semibold text-base font-manrope ${
          icon ? "pl-3 pr-4" : "px-4"
        } ${
          active
            ? textActiveColor ?? "text-indigo-700"
            : "text-gray-500 hover:text-gray-800"
        }`}
      >
        {icon && <span className="my-auto">{icon}</span>}
        <span className="my-auto">{title}</span>
      </button>
    </li>
  );
};

export const TabsLine = ({
  className,
  ulClassName,
  children,
  selectedTabIdx,
  onChangeTabIndex,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTabIdx);

  useEffect(() => {
    if (selectedTabIndex !== selectedTabIdx) {
      setSelectedTabIndex(selectedTabIdx);
      console.log(`Kesini 1 ${selectedTabIdx}`);
    }
  }, [selectedTabIdx]);

  useEffect(() => {
    if (selectedTabIdx !== selectedTabIndex) {
      onChangeTabIndex(selectedTabIndex);
    }
  }, [selectedTabIndex]);

  return (
    <Column>
      <div
        className={`w-full flex flex-row border-t-[1px] overflow-x-auto scrollbar-hide scroll-smooth ${className}`}
      >
        <ul className={`w-fit flex flex-row gap-1 ${ulClassName}`}>
          {children.map((item, idx) => {
            return (
              <TabLineTitle
                key={idx}
                icon={item.props.icon}
                title={item.props.title}
                idx={idx}
                active={idx === selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                textActiveColor={item.props.textActiveColor}
                borderActiveColor={item.props.borderActiveColor}
              />
            );
          })}
        </ul>
      </div>
      <AnimatePresence mode="wait" initial={false}>
        <TransitionPage key={selectedTabIndex} horizontal={true}>
          {children[selectedTabIndex]}
        </TransitionPage>
      </AnimatePresence>
    </Column>
  );
};

export const TabLineTitle = ({
  icon,
  title,
  idx,
  active,
  setSelectedTabIndex,
  textActiveColor,
  borderActiveColor,
}) => {
  const onClickTab = useCallback(() => {
    setSelectedTabIndex(idx);
  }, [setSelectedTabIndex, idx]);

  return (
    <li className="flex-shrink-0">
      <button
        onClick={onClickTab}
        className={`flex flex-row w-fit gap-1.5 py-3 font-semibold border-t-[3px] text-base font-manrope ${
          icon ? "pl-3 pr-5" : "px-5"
        } ${
          active
            ? `${textActiveColor ?? "text-indigo-700"} ${
                borderActiveColor ?? "border-indigo-700"
              }`
            : "text-gray-500 border-transparent hover:text-gray-800"
        }`}
      >
        {icon && <span className="my-auto">{icon}</span>}
        <span className="my-auto">{title}</span>
      </button>
    </li>
  );
};

export const TabPanel = ({
  children,
  className,
  removePadding = false,
  icon,
  title,
  textActiveColor,
  borderActiveColor,
}) => {
  return (
    <Column className={`${removePadding ? "py-0" : "py-5"} ${className}`}>
      {children}
    </Column>
  );
};

export const ZoomInAnimation = ({ children, delay }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 1.1,
      }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.8,
          delay: delay ?? 0,
        },
      }}
    >
      {children}
    </motion.div>
  );
};

export const SlideUpWithScaleHover = ({ children, delay }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 10,
      }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          delay: delay ?? 0,
        },
      }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.99 }}
    >
      {children}
    </motion.div>
  );
};

export const ScaleHover = ({ children, className, pressEffect = true }) => {
  return (
    <motion.div
      className={`${className}`}
      whileHover={{
        scale: 1.02,
        cursor: "pointer",
      }}
      whileTap={
        pressEffect
          ? {
              scale: 0.99,
            }
          : {}
      }
    >
      {children}
    </motion.div>
  );
};

export const ButtonHover = ({ children, className, pressEffect = true }) => {
  return (
    <motion.div
      className={`w-fit ${className}`}
      whileHover={{
        y: -3,
        cursor: "pointer",
      }}
      whileTap={
        pressEffect
          ? {
              scale: 0.98,
            }
          : {}
      }
    >
      {children}
    </motion.div>
  );
};

export const HorizontalDivider = ({
  className,
  removeMargin = false,
  color,
}) => {
  return (
    <div className={` ${!removeMargin && "my-2"} ${className}`}>
      <div className={`w-full border-t-[1px] border-t-gray-200 ${color}`} />
    </div>
  );
};

export const AreaChart = ({
  series,
  type = "category",
  label = null,
  height = 380,
  showLine = true,
  showLegend = true,
  showGrid = true,
  showXGrid = true,
  showYGrid = true,
  gridColor = "#F5F5F5",
  listColors,
  lineWidth = 3.5,
  pl = 12,
  pr = 18,
  pt = -20,
  pb = 20,
}) => {
  return (
    <Chart
      height={height}
      type="area"
      series={series}
      options={{
        chart: {
          type: "area",
          fontFamily: "inter",
          toolbar: {
            show: false,
          },
          parentHeightOffset: 0,
        },
        fill: {
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        dataLabels: {
          enabled: false,
          distributed: false,
        },
        legend: {
          show: showLegend,
        },
        grid: {
          show: showGrid,
          borderColor: gridColor,
          xaxis: {
            lines: {
              show: showXGrid,
            },
          },
          yaxis: {
            lines: {
              show: showYGrid,
            },
          },
          padding: {
            top: pt,
            bottom: pb,
            left: pl,
            right: pr,
          },
        },
        stroke: {
          curve: "smooth",
          width: lineWidth,
          show: showLine,
        },
        colors: listColors,
        xaxis:
          label !== null
            ? {
                type: type,
                tickPlacement: "on",
                categories: label,
              }
            : {
                type: type,
                tickPlacement: "on",
              },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
      }}
    />
  );
};

export const Color = () => {
  return (
    <div>
      <div className="bg-[#088395]"></div>
      <div className="bg-[#7A9D54]"></div>
      <div className="bg-[#F1C93B]"></div>
      <div className="bg-[#6A5495]"></div>
      <div className="bg-[#7900FF]"></div>
      <div className="bg-[#3F51B5]"></div>
      <div className="bg-[#6C3428]"></div>
      <div className="bg-[#F86F03]"></div>
      <div className="bg-[#526D82]"></div>
      <div className="bg-[#5A8F7B]"></div>
      <div className="bg-[#F44336]"></div>
      <div className="bg-[#2196F3]"></div>
      <div className="bg-[#99627A]"></div>
      <div className="bg-[#FF55BB]"></div>
      <div className="bg-[#9C0F48]"></div>
      <div className="bg-[#AE4CCF]"></div>
      <div className="bg-[#61677A]"></div>
      <div className="bg-[#245953]"></div>
      <div className="bg-[#5D3891]"></div>
      <div className="bg-[#EA906C]"></div>
      <div className="bg-[#2155CD]"></div>
      <div className="bg-[#FF6000]"></div>
      <div className="bg-[#5F685A]"></div>
    </div>
  );
};

export const BarChart = ({
  series,
  type = "category",
  horizontal = false,
  label = null,
  tooltip = null,
  height = 380,
  showLine = true,
  showLegend = true,
  showGrid = true,
  showXGrid = true,
  showYGrid = true,
  gridColor = "#F5F5F5",
  listColors = [],
  pl = 12,
  pr = 18,
  pt = -20,
  pb = 10,
  groupChart = false,
  formatRupiah = false,
  formatPercent = false,
  tooltipSuffix = null,
}) => {
  return (
    <Chart
      height={height}
      type="bar"
      series={series}
      options={{
        chart: {
          type: "bar",
          fontFamily: "inter",
          toolbar: {
            show: false,
          },
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          stacked: false,
        },
        plotOptions: {
          bar: {
            horizontal: horizontal,
            columnWidth: "50%",
            borderRadius: 3,
            borderRadiusApplication: "end",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return formatRupiah
              ? "Rp." + Intl.NumberFormat("ID").format(val)
              : formatPercent
              ? val + "%"
              : val + tooltipSuffix;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        legend: {
          show: showLegend,
        },
        grid: {
          show: showGrid,
          borderColor: gridColor,
          xaxis: {
            lines: {
              show: showXGrid,
            },
          },
          yaxis: {
            lines: {
              show: showYGrid,
            },
          },
          padding: {
            top: pt,
            bottom: label !== null ? pb : -10,
            left: pl,
            right: label !== null ? 0 : pr,
          },
        },
        colors: [
          function ({ value, seriesIndex, dataPointIndex, w }) {
            return listColors[groupChart ? seriesIndex : dataPointIndex];
          },
        ],
        xaxis:
          label !== null
            ? {
                type: type,
                tickPlacement: "on",
                categories: label,
              }
            : {
                type: type,
                tickPlacement: "on",
                labels: {
                  rotate: 0,
                  formatter: function (value) {
                    return "";
                  },
                },
              },
        yaxis: {
          labels: {
            formatter: function (value) {
              return formatRupiah
                ? "Rp." + Intl.NumberFormat("ID").format(value)
                : formatPercent
                ? value + "%"
                : value + tooltipSuffix;
            },
          },
        },
        tooltip: {
          x:
            tooltip !== null
              ? {
                  format: "dd MMM yyyy",
                  formatter: function (
                    value,
                    { series, seriesIndex, dataPointIndex, w }
                  ) {
                    return groupChart
                      ? tooltip[seriesIndex]
                      : tooltip[dataPointIndex];
                  },
                }
              : {
                  format: "dd MMM yyyy",
                },
        },
      }}
    />
  );
};

export const TransitionPage = ({ children, className, horizontal }) => {
  const animationsX = {
    initial: {
      opacity: 0,
      x: 50,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: -50,
    },
  };
  const animationsY = {
    initial: {
      opacity: 0,
      y: 50,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: -50,
    },
  };

  return (
    <motion.div
      className={`${className}`}
      variants={horizontal ? animationsX : animationsY}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{
        duration: 0.2,
        ease: "easeInOut",
      }}
    >
      {children}
    </motion.div>
  );
};

export const Pagination = ({
  className,
  initialPage = 0,
  limitRow = 10,
  totalRow,
  totalPage = 1,
  triger,
  onChangePageNumber,
}) => {
  const [currentPage, setCurrentPage] = useState(initialPage ?? 0);

  const changeCurrentPage = (page) => {
    const { selected } = page;
    setCurrentPage(selected);
    onChangePageNumber(selected + 1);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [limitRow, totalRow, totalPage, triger]);

  return (
    <Row
      className={`flex-col items-center md:items-start md:flex-row gap-4 justify-between ${className}`}
    >
      <Text className="my-auto border-[1px] px-3 py-1.5 rounded-lg">
        <span className="text-gray-500">Menampilkan </span>
        <span className="font-medium text-gray-800">{`${
          currentPage * limitRow + 1
        }`}</span>
        <span className="text-gray-500"> s.d </span>
        <span className="font-medium text-gray-800">{`${
          limitRow * (currentPage + 1) > totalRow
            ? totalRow
            : limitRow * (currentPage + 1)
        }`}</span>
        <span className="text-gray-500"> dari </span>
        <span className="font-medium text-gray-800">{`${totalRow}`}</span>
        <span className="text-gray-500"> data</span>
      </Text>
      <ReactPaginate
        forcePage={currentPage}
        className="max-w-full sm:w-fit overflow-x-auto scrollbar-hide flex flex-row border-[1px] border-gray-200 rounded-lg gap-2 inter font-medium p-1.5"
        activeClassName="text-white text-base font-medium"
        activeLinkClassName="flex w-7 h-7 bg-indigo-600 rounded-full items-center justify-center"
        pageClassName="text-base text-gray-500 font-medium rounded-full hover:bg-slate-100"
        pageLinkClassName="flex w-7 h-7 rounded-full items-center justify-center"
        previousLabel={<TbChevronLeft className="h-5 w-5 mx-auto" />}
        previousLinkClassName="flex w-7 h-7 rounded-full items-center justify-center hover:bg-slate-100 text-gray-500 hover:text-indigo-700"
        nextLabel={<TbChevronRight className="h-5 w-5 mx-auto" />}
        nextLinkClassName="flex w-7 h-7 rounded-full items-center justify-center hover:bg-slate-100 text-gray-500 hover:text-indigo-700"
        breakLabel="..."
        breakClassName="text-gray-500 text-base"
        breakLinkClassName="flex w-7 h-7 rounded-full items-center justify-center"
        onPageChange={changeCurrentPage}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(totalPage)}
        renderOnZeroPageCount={null}
      />
    </Row>
  );
};

export const PaginationOption = ({
  initialShow,
  onChangeOptions,
  bgColor = null,
}) => {
  const [optionSelected, setOptionSelected] = useState(initialShow ?? 10);

  const onChangeSelectedOption = (value) => {
    setOptionSelected(value);
    onChangeOptions(value);
  };

  return (
    <Listbox
      name="option"
      value={optionSelected}
      onChange={onChangeSelectedOption}
    >
      <div className="relative z-10 w-fit">
        <Listbox.Button
          className={`relative w-30 cursor-pointer rounded-lg py-2 pl-3 pr-2 text-left border-[1px] border-gray-200 ${
            bgColor ?? "bg-white"
          }`}
        >
          <Row className="gap-2">
            <Text className="w-fit flex-1">{optionSelected} Data</Text>
            <HiChevronUpDown className="h-5 w-5 text-slate-500 my-auto" />
          </Row>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="transition ease-in-out duration-200"
          enterFrom="-translate-y-8 opacity-0"
          enterTo="translate-y-1 opacity-100"
          leave="transition ease-out duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full flex flex-col h-fit max-h-64 overflow-y-auto divide-y-[1px] divide-gray-200 scrollbar-hide rounded-lg bg-white border-[1px] border-gray-200 shadow-xl shadow-gray-300/50">
            {paginationOption.map((option, idx) => (
              <Listbox.Option
                key={idx}
                className={`relative cursor-pointer select-none py-2 px-2 ${
                  option === optionSelected
                    ? "text-indigo-700"
                    : "text-gray-800 hover:bg-slate-50"
                }`}
                value={option}
              >
                <Row>
                  <p className="flex-1 text-base font-inter">{option} Data</p>
                  {option === optionSelected && (
                    <Card className="p-0.5 rounded-full my-auto shadow-none border-indigo-300">
                      <TbCheck className="h-4 w-4 text-indigo-700" />
                    </Card>
                  )}
                </Row>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export const Table = ({
  children,
  className,
  limitRow,
  totalRow,
  initialPage,
  totalPage,
  trigerPagination,
  onChangeShowNumber,
  onChangeQuerySearch,
  onChangePageNumber,
  leadingWidget,
  showPaginationAndSearch = true,
}) => {
  const onInputChange = (event) => {
    event.preventDefault();
    onChangeQuerySearch(event.target.value);
  };

  return (
    <Column className="gap-4">
      {showPaginationAndSearch && (
        <Row className="flex-col sm:flex-row justify-between gap-3">
          <Row className="w-full gap-3">
            <PaginationOption
              initialShow={limitRow}
              onChangeOptions={onChangeShowNumber}
              bgColor="bg-slate-50"
            />
            {leadingWidget}
          </Row>
          <OutlineInput
            id="query"
            type="search"
            placeholder="Cari Data"
            className="py-2 pl-10 pr-3 border-gray-200"
            inputWidth="w-full sm:max-w-xs"
            bgColor="bg-slate-50"
            prefix={<TbSearch className="h-5 w-5 text-gray-400" />}
            prefixClassName="px-3"
            onChange={onInputChange}
          />
        </Row>
      )}
      <div className={`table-style ${className}`}>
        <table className="w-full">{children}</table>
      </div>
      {totalRow > 0 && (
        <Pagination
          className="mt-2"
          triger={trigerPagination}
          limitRow={limitRow}
          totalRow={totalRow}
          initialPage={initialPage}
          totalPage={totalPage}
          onChangePageNumber={onChangePageNumber}
        />
      )}
    </Column>
  );
};

export const TableHead = ({ children, className }) => {
  return (
    <thead className={`text-gray-800 text-base ${className}`}>{children}</thead>
  );
};

export const TableBody = ({ children, className }) => {
  return (
    <tbody className={`text-gray-800 text-base ${className}`}>{children}</tbody>
  );
};

export const TableRow = ({
  children,
  className,
  showCheckbox = false,
  onClick,
}) => {
  return (
    <tr className={`table-row ${className}`} onClick={onClick}>
      {showCheckbox && (
        <th className="bg-red-50 px-2">
          <input type="checkbox" />
        </th>
      )}
      {children}
    </tr>
  );
};

export const TableCell = ({
  children,
  colSpan,
  className,
  sortkey,
  sortable = false,
  onShortable,
  currentSortKey,
}) => {
  const [shortType, setShortType] = useState(null);

  const shortTable = () => {
    if (shortType === null) {
      onShortable({
        key: sortkey,
        type: "ASC",
      });
      setShortType("ASC");
    } else if (shortType === "DESC") {
      onShortable({
        key: sortkey,
        type: "ASC",
      });
      setShortType("ASC");
    } else {
      onShortable({
        key: sortkey,
        type: "DESC",
      });
      setShortType("DESC");
    }
  };

  useEffect(() => {
    if (sortkey !== currentSortKey) {
      setShortType(null);
    }
  }, [currentSortKey]);

  useEffect(() => {}, [shortType]);

  return (
    <th
      onClick={sortable ? shortTable : null}
      colSpan={colSpan}
      className={`table-cell-style ${
        sortable && "cursor-pointer hover:text-indigo-700"
      } ${className}`}
    >
      {sortable ? (
        <Row className="gap-1">
          {children}
          <div className="flex items-center ml-auto">
            {shortType === "ASC" ? (
              <TbArrowUp className="h-4 w-4 text-indigo-700" />
            ) : shortType === "DESC" ? (
              <TbArrowDown className="h-4 w-4 text-indigo-700" />
            ) : (
              <TbArrowsSort className="h-4 w-4 text-gray-400" />
            )}
          </div>
        </Row>
      ) : (
        children
      )}
    </th>
  );
};

export const SelectView = ({
  label = null,
  labelClassName,
  initial,
  required,
  onChangeOptions,
  zindex = "z-10",
  options,
  width = "w-fit",
  bgColor = null,
  py = "py-2.5",
  pl = "pl-3.5",
  pr = "pr-2",
  mb,
  textSize = "text-base",
  iconSize = "h-6 w-6",
  helperText = null,
}) => {
  const [optionSelected, setOptionSelected] = useState(initial ?? null);

  const onChangeSelected = (value) => {
    setOptionSelected(value);
    onChangeOptions(value);
  };

  useEffect(() => {
    setOptionSelected(initial);
  }, [initial]);

  return (
    <div className={`column gap-1.5 ${mb} ${width}`}>
      {label !== null && (
        <label className={`caption ${labelClassName}`}>
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Listbox name="option" value={optionSelected} onChange={onChangeSelected}>
        <div className={`relative ${width} ${zindex}`}>
          <Listbox.Button
            className={`relative cursor-pointer rounded-lg ${py} ${pl} ${pr} text-left border-[1px] border-gray-200 ${
              bgColor ?? "bg-white"
            } ${width}`}
          >
            <Row className="gap-2">
              <Text className={`w-fit flex-1 line-clamp-1 ${textSize}`}>
                {optionSelected.label}
              </Text>
              <HiChevronUpDown
                className={`${iconSize} text-slate-500 my-auto`}
              />
            </Row>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition ease-in-out duration-200"
            enterFrom="-translate-y-2 opacity-0"
            enterTo="translate-y-1 opacity-100"
            leave="transition ease-out duration-200"
            leaveFrom="translate-y-1 opacity-100"
            leaveTo="-translate-y-2 opacity-0"
          >
            <Listbox.Options
              className={`absolute w-full flex flex-col h-fit max-h-64 overflow-y-auto divide-y-[1px] divide-gray-200 scrollbar-hide rounded-lg bg-white border border-gray-200 shadow-xl`}
            >
              {options.map((option, idx) => (
                <Listbox.Option
                  key={idx}
                  className="relative cursor-pointer select-none"
                  value={option}
                >
                  <Row
                    className={`px-4 py-2.5 gap-x-2 group transition-all duration-200 ${
                      option.value === optionSelected.value
                        ? "font-medium text-indigo-600"
                        : "text-gray-800"
                    } hover:bg-gray-50`}
                  >
                    <p
                      className={`flex-1 text-base font-inter ${textSize} group-hover:text-indigo-600`}
                    >
                      {option.label}
                    </p>
                    {option.value === optionSelected.value && (
                      <Card className="p-0.5 rounded-full my-auto bg-indigo-600 border-none">
                        <TbCheck className="h-4 w-4 text-white" />
                      </Card>
                    )}
                  </Row>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {helperText !== null && helperText !== "" && (
        <small className={`overlines px-4 ${labelClassName}`}>
          {helperText}
        </small>
      )}
    </div>
  );
};

export const DropdownView = ({
  button,
  children,
  className,
  showOverlay = false,
}) => {
  let [refDropdown, setRefDropDown] = useState();
  return (
    <Popover className="relative bg-red-50">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setRefDropDown}
            className={`h-full w-full focus:outline-none focus-visible:ring-0`}
          >
            {button}
          </Popover.Button>
          {showOverlay && (
            <Popover.Overlay className="fixed inset-0 bg-black z-30 opacity-30" />
          )}
          <Transition
            show={open}
            className="absolute bg-red-50 z-30 self-center right-0 p-4"
            enter="transition ease-in-out duration-200"
            enterFrom="-translate-y-5 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in-out duration-200"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-3 opacity-0"
          >
            <Popover.Panel
              ref={refDropdown}
              className={`w-fit bg-white rounded-xl p-1.5 shadow-xl border border-slate-200 ${className}`}
            >
              {children}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export const Dropdown = ({ children, button, className, onClick }) => {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
      onClick={onClick}
    >
      <div>
        <Menu.Button>{button}</Menu.Button>
      </div>
      <Transition
        as={Fragment}
        className="w-fit absolute z-10"
        enter="transition ease-in-out duration-200"
        enterFrom="-translate-y-5 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in-out duration-200"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="-translate-y-3 opacity-0"
      >
        <Menu.Items
          className={`w-32 bg-white rounded-xl right-0 p-1.5 shadow-xl border border-slate-200 ${className}`}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export const DropDownMenu = ({ children, className }) => {
  const [show, setShow] = useState(false);

  const showMenu = () => {
    setShow(true);
  };

  const hideMenu = () => {
    setShow(false);
  };

  return (
    <div
      className="w-fit h-full relative"
      onMouseEnter={showMenu}
      onMouseLeave={hideMenu}
    >
      <div className="h-full flex">
        <IconButton className="my-auto">
          <TbDotsVertical className="h-5 w-5" />
        </IconButton>
      </div>
      <Transition
        show={show}
        className={`w-max overflow-hidden absolute z-30 right-0 rounded-lg bg-white border border-slate-200/50 shadow-xl ${className}`}
        enter="transition ease-in-out duration-200"
        enterFrom="translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in-out duration-200"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="translate-y-2 opacity-0"
      >
        {children}
      </Transition>
    </div>
  );
};

export const AlertDialog = ({ children, show = false, onClose, className }) => {
  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = () => {
    setShowDialog(false);
    onClose(onClose);
  };

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  return (
    <Transition appear show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/40" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in-out duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <button
              className={
                "bg-black/20 fixed right-6 top-6 p-2 rounded-full transition-all duration-200 cursor-pointer hover:-translate-y-1"
              }
              onClick={onClose}
            >
              <TbX className="h-5 w-5 text-white/90" />
            </button>
          </Transition.Child>
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-200"
              enterFrom="opacity-0 scale-90"
              enterTo="opacity-100 scale-100"
              leave="ease-in-out duration-150"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-90"
            >
              <Dialog.Panel className={`alert-dialog ${className}`}>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const LoadingDialog = ({ show = false, message = "" }) => {
  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = () => {};

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  return (
    <Transition appear show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/40" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in-out duration-150"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-fit max-w-md transform overflow-hidden rounded-xl pl-4 pr-6 py-4 bg-white align-middle shadow-2xl transition-all">
                <Row className="gap-3">
                  <LoadingView className="h-8 w-8 text-indigo-700" />
                  <Text className="my-auto">{message}</Text>
                </Row>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const LoadingView = ({ className }) => {
  return <CgSpinner className={`h-5 w-5 my-auto animate-spin ${className}`} />;
};

export const ShimmerAnimation = ({ children, className }) => {
  return <div className={`animate-pulse ${className}`}>{children}</div>;
};

export const ShimmerItem = ({ className }) => {
  return <div className={`h-10 w-10 bg-gray-200 rounded-full ${className}`} />;
};

export const ToasterView = ({
  className,
  textColor,
  durationShow = toastDuration,
  position = "top-center",
}) => {
  return (
    <Toaster
      position={position}
      toastOptions={{
        duration: durationShow,
      }}
    >
      {(t) => (
        <Transition
          appear
          show={t.visible}
          className={`toast ${className}`}
          enter="transition-all duration-200"
          enterFrom="opacity-0 -translate-y-full"
          enterTo="opacity-100 translate-y-0"
          leave="transition-all duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-full"
        >
          <ToastIcon toast={t} />
          <Text className={`${textColor ?? "text-white"} my-auto`}>
            {resolveValue(t.message)}
          </Text>
        </Transition>
      )}
    </Toaster>
  );
};

export const ListTileView = ({
  className,
  leading = null,
  label,
  labelClassName,
  valueClassName,
  direction = "flex-row",
  value,
}) => {
  return (
    <div
      className={`w-full flex ${direction} py-2 px-4 gap-x-3 gap-y-1 align-middle ${className}`}
    >
      {leading !== null && <div>{leading}</div>}
      <Caption className={`w-full text-start ${labelClassName}`}>
        {label}
      </Caption>
      <Caption
        className={`listtile-value ${
          direction === "flex-row" ? "text-end" : "text-start"
        } ${valueClassName}`}
      >
        {value}
      </Caption>
    </div>
  );
};

export const TagView = ({ className, leadingIcon = null, label, color }) => {
  const [tagColor, setTagColor] = useState({
    bgColor: "",
    textColor: "",
    hoverColor: "",
    borderColor: "",
  });

  useEffect(() => {
    switch (color) {
      case "red":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-red-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-red-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-red-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-red-200" };
        });
        break;
      case "green":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-green-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-green-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-green-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-green-200" };
        });
        break;
      case "purple":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-purple-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-purple-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-purple-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-purple-200" };
        });
        break;
      case "orange":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-orange-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-orange-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-orange-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-orange-200" };
        });
        break;
      case "indigo":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-indigo-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-indigo-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-indigo-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-indigo-200" };
        });
        break;
      case "blue":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-blue-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-blue-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-blue-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-blue-200" };
        });
        break;
      case "amber":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-amber-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-amber-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-amber-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-amber-200" };
        });
        break;
      case "yellow":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-yellow-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-yellow-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-yellow-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-yellow-200" };
        });
        break;
      case "white":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-white" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-gray-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-white" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-gray-200" };
        });
        break;
      case "rose":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-rose-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-rose-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-rose-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-rose-200" };
        });
        break;
      case "custom":
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "" };
        });
        break;
      default:
        setTagColor((tagColor) => {
          return { ...tagColor, bgColor: "bg-gray-50" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, textColor: "text-gray-600" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, hoverColor: "hover:bg-gray-100" };
        });
        setTagColor((tagColor) => {
          return { ...tagColor, borderColor: "border-gray-200" };
        });
        break;
    }
  }, [color]);

  return (
    <div
      className={`tags flex-shrink-0 ${
        tagColor.bgColor +
        " " +
        tagColor.textColor +
        " " +
        tagColor.borderColor +
        " " +
        tagColor.hoverColor
      } ${leadingIcon !== null ? "pl-3 pr-4" : "px-3.5"} ${className}`}
    >
      {leadingIcon !== null && <span className="my-auto">{leadingIcon}</span>}
      <span className="my-auto flex-shrink-0">{label}</span>
    </div>
  );
};

export const Tooltip = ({
  children,
  message = "Tooltip",
  delay = 300,
  width = "w-[16rem]",
  align = "text-center",
}) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="relative w-fit"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      <Transition
        show={active}
        enter="transition ease-in-out duration-200"
        enterFrom="translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in-out duration-200"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="translate-y-2 opacity-0"
      >
        <Column className="absolute items-center left-[50%] translate-x-[-50%] z-50 -translate-y-full">
          <div className={`rounded-xl max-w-sm px-3 py-2 bg-gray-700 z-10`}>
            <Caption className={`w-full text-white ${align}`}>
              {message}
            </Caption>
          </div>
          <div className="w-6 overflow-hidden inline-block -translate-y-1.5 pb-2">
            <div className="h-5 w-5 bg-gray-700 -rotate-45 transform origin-top-left rounded-sm"></div>
          </div>
        </Column>
      </Transition>
    </div>
  );
};

export const Steps = ({ children, index = 0 }) => {
  return (
    <AnimatePresence mode="wait" initial={false}>
      <TransitionPage className="w-full" key={index} horizontal={true}>
        {children[index]}
      </TransitionPage>
    </AnimatePresence>
  );
};

export const Breadcrumbs = ({ className }) => {
  const location = useLocation();
  const navigateTo = useNavigate();
  const [currentPath, setCurrentPath] = useState([]);

  const getPath = (index) => {
    let path = "/";
    for (var i = 0; i <= index; i++) {
      if (i == 0) {
        path = currentPath[i];
      } else {
        path = path + "/" + currentPath[i];
      }
    }
    navigateTo(path);
  };

  useEffect(() => {
    const path = location.pathname;
    const listPath = path.split("/");
    setCurrentPath(listPath);
  }, []);

  return (
    <Row className={`w-fit px-4 py-1 gap-2.5 ${className}`}>
      {currentPath.map((path, idx) => {
        return (
          path !== "" && (
            <Row key={idx} className="w-fit gap-2.5">
              <TextButton
                onClick={() => getPath(idx)}
                className={`capitalize ${
                  idx !== currentPath.length - 1
                    ? "text-slate-500 hover:text-indigo-600"
                    : "font-semibold"
                }`}
              >
                {path}
              </TextButton>
              {idx < currentPath.length - 1 && (
                <Title className="font-normal text-gray-500">/</Title>
              )}
            </Row>
          )
        );
      })}
    </Row>
  );
};

export const DotsList = ({ className, children }) => {
  return <ul className={`list-disc ${className}`}>{children}</ul>;
};

export const DotsItem = ({ className, children }) => {
  return (
    <li className={`text-gray-800 text-base font-inter ${className}`}>
      {children}
    </li>
  );
};

export const ShareFBButton = ({ location }) => {
  return (
    <a
      className={
        "transition-all duration-200 cursor-pointer rounded-full bg-blue-500 text-white p-2.5 hover:bg-blue-600 hover:-translate-y-1"
      }
      href={`https://www.facebook.com/sharer.php?u=${location}`}
      target="_blank"
      rel="noreferrer"
    >
      <TbBrandFacebook className={"h-7 w-7 stroke-1.5 -translate-x-[1px]"} />
    </a>
  );
};

export const ShareXButton = ({ location }) => {
  return (
    <a
      className={
        "transition-all duration-200 cursor-pointer rounded-full bg-gray-800 text-white p-3 hover:bg-gray-900 hover:-translate-y-1"
      }
      href={`https://twitter.com/share?url=${location}`}
      target="_blank"
      rel="noreferrer"
    >
      <TbBrandX className="h-6 w-6 stroke-1.5" />
    </a>
  );
};

export const ShareWAButton = ({ location }) => {
  return (
    <a
      className={
        "transition-all duration-200 cursor-pointer rounded-full bg-green-500 text-white p-2.5 hover:bg-green-600 hover:-translate-y-1"
      }
      href={`whatsapp://send?text=${location}`}
      target="_blank"
      rel="noreferrer"
    >
      <TbBrandWhatsapp className={"h-7 w-7 stroke-1.5"} />
    </a>
  );
};

export const ShareTelegramButton = ({ location }) => {
  return (
    <a
      className={
        "transition-all duration-200 cursor-pointer rounded-full bg-sky-500 text-white p-2.5 hover:bg-sky-600 hover:-translate-y-1"
      }
      href={`https://telegram.me/share/url?url=${location}`}
      target="_blank"
      rel="noreferrer"
    >
      <TbBrandTelegram className="h-7 w-7 stroke-1.5 -translate-x-[1px]" />
    </a>
  );
};

export const SidebarAccordionMenu = ({
  icon,
  label,
  labelClassName,
  childrenClassName,
  children,
  active,
}) => {
  const childrenRef = useRef(null);

  const [open, setOpen] = useState(active);

  return (
    <div className={"flex flex-col"}>
      <button
        type={"button"}
        className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${
          active && "text-indigo-600"
        } hover:text-indigo-600 hover:bg-gray-50`}
        onClick={() => setOpen(!open)}
      >
        <Row className={"w-full gap-x-2 items-center"}>
          {icon}
          <p
            className={`flex-1 text-left text-base font-manrope font-semibold ${labelClassName}`}
          >
            {label}
          </p>
          <TbChevronDown
            className={`transition-transform duration-200 h-5 w-5 translate-x-1 ${
              open ? "text-indigo-600 rotate-180" : "text-gray-500"
            }`}
          />
        </Row>
      </button>
      <div
        className={`overflow-hidden transition-all duration-200 ${
          open && "mt-3.5"
        }`}
        style={{ height: open ? childrenRef.current?.offsetHeight || 0 : 0 }}
      >
        <div ref={childrenRef} className={`flex flex-col ${childrenClassName}`}>
          {children}
        </div>
      </div>
    </div>
  );
};
