import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../shared/user";
import { useEffect, useRef, useState } from "react";
import {
  checkUserFilledTracer,
  getDataMasterPosisiTracer,
  getDataMasterProvinsiTracer,
  getDataMasterTingKerjaTracer,
} from "../../controllers/apicontrollers";
import {
  getCekExitSurveyController,
  saveExitSurveyController,
} from "../../controllers/alumni_controllers";
import toast from "react-hot-toast";
import { mhsExitRoute, notFoundRoute } from "../../shared/variable";
import {
  Caption,
  Card,
  CheckboxButton,
  Column,
  HorizontalDivider,
  OutlineInput,
  OutlineTextArea,
  Overline,
  PrimaryButton,
  RadioButton,
  Row,
  SelectInput,
  Text,
  Title,
} from "../../shared/ui";
import { TbClipboardText, TbUpload } from "react-icons/tb";
import { FormatRupiah } from "@arismun/format-rupiah";
import { CaptionText } from "../../components/interfaces/text";

export default function InputExitMhs() {
  const navigate = useNavigate();
  const { name, username, prodi } = getCurrentUser();

  const refYes6Bln = useRef(null);
  const refNo6Bln = useRef(null);
  const refCariLainnya = useRef(null);
  const refJPLain = useRef(null);

  const [masterProvinsi, setMasterProvinsi] = useState([]);
  const [masterTingKerja, setMasterTingKerja] = useState([]);
  const [masterPosisi, setMasterPosisi] = useState([]);

  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentStatusBekerja, setCurrentStatusBekerja] = useState(null);
  const [currentJPerusahaan, setCurrentJPerusahaan] = useState(null);
  const [currentCariKerja, setcurrentCariKerja] = useState(null);

  const [nmKantorInput, setNmKantorInput] = useState(null);
  const [posisiInput, setPosisiInput] = useState(null);
  const [bidangInput, setBidangInput] = useState(null);
  const [tglKerjaInput, setTglKerjaInput] = useState(null);
  const [bdUsahaInput, setBdUsahaInput] = useState(null);
  const [tglWiraInput, setTglWiraInput] = useState(null);
  const [omsWiraInput, setOmsWiraInput] = useState(null);
  const [ya6BlnInput, setYa6BlnInput] = useState(null);
  const [no6BlnInput, setNo6BlnInput] = useState(null);
  const [lainCariKerja, setLainCariKerja] = useState(null);
  const [lainJPerusahaan, setLainJPerusahaan] = useState(null);
  const [tglCariKerja, setTglCariKerja] = useState(null);
  const [cari1, setCari1] = useState(null);
  const [cari2, setCari2] = useState(null);
  const [cari3, setCari3] = useState(null);
  const [cari4, setCari4] = useState(null);
  const [cari5, setCari5] = useState(null);
  const [cari6, setCari6] = useState(null);
  const [cari7, setCari7] = useState(null);
  const [cari8, setCari8] = useState(null);
  const [cari9, setCari9] = useState(null);
  const [cari10, setCari10] = useState(null);
  const [cari11, setCari11] = useState(null);
  const [cari12, setCari12] = useState(null);
  const [cari13, setCari13] = useState(null);
  const [cari14, setCari14] = useState(null);
  const [cari15, setCari15] = useState(null);
  const [jmlLamarPerusahaan, setJmlLamarPerusahaan] = useState(null);
  const [jmlResponLamaranPerusahaan, setJmlResponLamaranPerusahaan] =
    useState(null);
  const [jmlUndanganPerusahaan, setJmlUndanganPerusahaan] = useState(null);
  const [noCariKerja, setNoCariKerja] = useState(null);
  const [takeHomeInput, setTakeHomeInput] = useState(null);

  const [showDialog, setShowDialog] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [errorStatusBekerja, setErrorStatusBekerja] = useState(null);
  const [errorTglKerja, setErrorTglKerja] = useState(null);
  const [errorJPerusahaan, setErrorJPerusahaan] = useState(null);
  const [errorNmKantor, setErrorNmKantor] = useState(null);
  const [errorProv, setErrorProv] = useState(null);
  const [errorKab, setErrorKab] = useState(null);
  const [errorTingkat, setErrorTingkat] = useState(null);
  const [errorBidangInput, setErrorBidangInput] = useState(null);
  const [errorPosisi, setErrorPosisi] = useState(null);
  const [errorTglWira, setErrorTglWira] = useState(null);
  const [errorBdUsahaInput, setErrorBdUsahaInput] = useState(null);
  const [errorOmsWiraInput, setErrorOmsWiraInput] = useState(null);
  const [errorKerja6Bln, setErrorKerja6Bln] = useState(null);
  const [errorLainCarikerja, setErrorLainCarikerja] = useState(null);
  const [errorTglCariKerja, setErrorTglCariKerja] = useState(null);
  const [errorJmlLamarPerusahaan, setErrorJmlLamarPerusahaan] = useState(null);
  const [errorJmlResponLamaranPerusahaan, setErrorJmlResponLamaranPerusahaan] =
    useState(null);
  const [errorJmlUndanganPerusahaan, setErrorJmlUndanganPerusahaan] =
    useState(null);
  const [errorCariKerja, setErrorCariKerja] = useState(null);
  const [errorNoCariKerja, setErrorNoCariKerja] = useState(null);
  const [errorTingkatWira, setErrorTingkatWira] = useState(null);
  const [errorPosisiWira, setErrorPosisiWira] = useState(null);
  const [exitSurvey, setExitSurvey] = useState(null);
  const [errorCurrentCariKerja, setErrorCurrentCariKerja] = useState(null);
  const [errorTakeHomeInput, setErrortakeHomeInput] = useState(null);

  const [opsiProv, setOpsiProv] = useState([]);
  const [initialProv, setInitialProv] = useState({
    label: "Pilih provinsi",
    value: null,
  });
  const [opsiKab, setOpsiKab] = useState([]);
  const [initialKab, setInitialKab] = useState({
    label: "Pilih kabupaten atau kota",
    value: null,
  });
  const [opsiTingkat, setOpsiTingkat] = useState([]);
  const [initialTingkat, setInitialTingkat] = useState({
    label: "Pilih tingkat",
    value: null,
  });
  const [opsiPosisi, setOpsiPosisi] = useState([]);
  const [initialPosisi, setInitialPosisi] = useState({
    label: "Pilih posisi",
    value: null,
  });

  const [opsiTingkatWira, setOpsiTingkatWira] = useState([]);
  const [initialTingkatWira, setInitialTingkatWira] = useState({
    label: "Pilih tingkat",
    value: null,
  });
  const [opsiPosisiWira, setOpsiPosisiWira] = useState([]);
  const [initialPosisiWira, setInitialPosisiWira] = useState({
    label: "Pilih posisi",
    value: null,
  });

  const getMasterProvinsi = async () => {
    const result = await getDataMasterProvinsiTracer();
    if (result.status) {
      setMasterProvinsi(result.data);
    }
  };

  const getMasterTingkatKerja = async () => {
    const result = await getDataMasterTingKerjaTracer();
    if (result.status) {
      setMasterTingKerja(result.data);
    }
  };

  const getMasterPosisi = async () => {
    const result = await getDataMasterPosisiTracer();
    if (result.status) {
      setMasterPosisi(result.data);
    }
  };

  const setupOpsiProvinsi = () => {
    const opsiTemp = masterProvinsi.map((prov) => ({
      label: prov.prov_nama,
      value: prov.prov_id,
    }));
    setInitialProv({ label: "Pilih provinsi", value: null });
    setOpsiProv(opsiTemp);
  };

  const setupOpsiKabupaten = (currentProv) => {
    if (!currentProv?.value) {
      setInitialKab({ label: "Pilih kabupaten atau kota", value: null });
      setOpsiKab([]);
      return;
    }

    const findProv = masterProvinsi.find(
      (prov) => prov.prov_id === currentProv.value
    );

    const opsiTemp =
      findProv?.kota.map((kota) => ({
        label: kota.kota_nama,
        value: kota.kota_id,
      })) || [];

    setInitialKab({ label: "Pilih kabupaten atau kota", value: null });
    setOpsiKab(opsiTemp);
  };

  const setupTingkatTempatKerja = () => {
    const opsiTemp = masterTingKerja.map((tingkat) => ({
      label: tingkat.tmp_kerja,
      value: tingkat.id_tmp_kerja,
    }));

    setInitialTingkat({ label: "Pilih tingkat", value: null });
    setOpsiTingkat(opsiTemp);
    setInitialTingkatWira({ label: "Pilih tingkat", value: null });
    setOpsiTingkatWira(opsiTemp);
  };

  const setupPosisiWirausaha = () => {
    const opsiTemp = masterPosisi.map((posisi) => ({
      label: posisi.posisi,
      value: posisi.id_posisi,
    }));
    setInitialPosisi({ label: "Pilih posisi", value: null });
    setOpsiPosisi(opsiTemp);
    setInitialPosisiWira({ label: "Pilih posisi", value: null });
    setOpsiPosisiWira(opsiTemp);
  };

  const onChangeSelect = (result) => {
    switch (result["action"]) {
      case 10:
        const prov = result["value"];
        setInitialProv(prov);
        setupOpsiKabupaten(prov);
        if (errorProv !== null) {
          setErrorProv(null);
        }
        break;
      case 20:
        const kab = result["value"];
        setInitialKab(kab);
        if (errorKab !== null) {
          setErrorKab(null);
        }
        break;
      case 30:
        const tingkat = result["value"];
        setInitialTingkat(tingkat);
        if (errorTingkat !== null) {
          setErrorTingkat(null);
        }
        break;
      case 40:
        const posisi = result["value"];
        setInitialPosisi(posisi);
        if (errorPosisi !== null) {
          setErrorPosisi(null);
        }
        break;
      case 50:
        const tingkatWira = result["value"];
        setInitialTingkatWira(tingkatWira);
        if (errorTingkatWira !== null) {
          setErrorTingkatWira(null);
        }
        break;
      case 60:
        const posisiWira = result["value"];
        setInitialPosisiWira(posisiWira);
        if (errorPosisiWira !== null) {
          setErrorPosisiWira(null);
        }
        break;
    }
  };

  const onChangeRadio = (result) => {
    switch (result["action"]) {
      case 10:
        setCurrentStatus(parseInt(result["value"]));
        if (errorStatus !== null) {
          setErrorStatus(null);
        }
        break;
      case 20:
        setCurrentJPerusahaan(parseInt(result["value"]));
        if (parseInt(result["value"]) !== 5) {
          refJPLain.current.value = "";
          setLainJPerusahaan(null);
        }
        if (errorJPerusahaan !== null) {
          setErrorJPerusahaan(null);
        }
        break;
      case 30:
        setcurrentCariKerja(parseInt(result["value"]));
        if (parseInt(result["value"]) === 1) {
          refNo6Bln.current.value = "";
          setNo6BlnInput(null);
        } else {
          refYes6Bln.current.value = "";
          setYa6BlnInput(null);
        }
        if (errorKerja6Bln !== null) {
          setErrorKerja6Bln(null);
        }
        break;
      case 40:
        setCurrentStatusBekerja(result["value"]);
        if (errorStatusBekerja !== null) {
          setErrorStatusBekerja(null);
        }
        break;
    }
  };

  const onChangeInput = (result) => {
    switch (result["action"]) {
      case 10:
        setTglKerjaInput(result["value"]);
        if (errorTglKerja !== null) {
          setErrorTglKerja(null);
        }
        break;
      case 20:
        setNmKantorInput(result["value"]);
        if (errorNmKantor !== null) {
          setErrorNmKantor(null);
        }
        break;
      case 30:
        setPosisiInput(result["value"]);
        if (errorPosisi !== null) {
          setErrorPosisi(null);
        }
        break;
      case 40:
        setBidangInput(result["value"]);
        if (errorBidangInput !== null) {
          setErrorBidangInput(null);
        }
        break;
      case 50:
        setTglWiraInput(result["value"]);
        if (errorTglWira !== null) {
          setErrorTglWira(null);
        }
        break;
      case 60:
        setBdUsahaInput(result["value"]);
        if (errorBdUsahaInput !== null) {
          setErrorBdUsahaInput(null);
        }
        break;
      case 70:
        setOmsWiraInput(result["value"]);
        if (errorOmsWiraInput !== null) {
          setErrorOmsWiraInput(null);
        }
        break;
      case 80:
        setTglCariKerja(result["value"]);
        if (errorTglCariKerja !== null) {
          setErrorTglCariKerja(null);
        }
        break;
      case 90:
        setLainCariKerja(result["value"]);
        if (errorLainCarikerja !== null) {
          setErrorLainCarikerja(null);
        }
        break;
      case 100:
        setJmlLamarPerusahaan(result["value"]);
        if (errorJmlLamarPerusahaan !== null) {
          setErrorJmlLamarPerusahaan(null);
        }
        break;
      case 110:
        setJmlResponLamaranPerusahaan(result["value"]);
        if (errorJmlResponLamaranPerusahaan !== null) {
          setErrorJmlResponLamaranPerusahaan(null);
        }
        break;
      case 120:
        setJmlUndanganPerusahaan(result["value"]);
        if (errorJmlUndanganPerusahaan !== null) {
          setErrorJmlUndanganPerusahaan(null);
        }
        break;
      case 130:
        setNoCariKerja(result["value"]);
        if (errorNoCariKerja !== null) {
          setErrorNoCariKerja(null);
        }
        break;
      case 140:
        setTakeHomeInput(result["value"]);
        if (errorTakeHomeInput !== null) {
          setErrortakeHomeInput(null);
        }
        break;
    }
  };

  const onChangeCheckbox = (result) => {
    const newValue = result.value ? 1 : null;
    switch (result["action"]) {
      case 10:
        setCari1(newValue);
        break;
      case 20:
        setCari2(newValue);
        break;
      case 30:
        setCari3(newValue);
        break;
      case 40:
        setCari4(newValue);
        break;
      case 50:
        setCari5(newValue);
        break;
      case 60:
        setCari6(newValue);
        break;
      case 70:
        setCari7(newValue);
        break;
      case 80:
        setCari8(newValue);
        break;
      case 90:
        setCari9(newValue);
        break;
      case 100:
        setCari10(newValue);
        break;
      case 110:
        setCari11(newValue);
        break;
      case 120:
        setCari12(newValue);
        break;
      case 130:
        setCari13(newValue);
        break;
      case 140:
        setCari14(newValue);
        break;
      case 150:
        setCari15(newValue);
        if (!result["value"]) {
          refCariLainnya.current.value = "";
          setLainCariKerja(null);
        }
        break;
    }

    setTimeout(() => {
      if (
        result["value"] ||
        cari1 ||
        cari2 ||
        cari3 ||
        cari4 ||
        cari5 ||
        cari6 ||
        cari7 ||
        cari8 ||
        cari9 ||
        cari10 ||
        cari11 ||
        cari12 ||
        cari13 ||
        cari14 ||
        cari15
      ) {
        setErrorCariKerja(null);
      }
    }, 0);
  };

  const validateForm = () => {
    let isValid = true;

    if (currentStatus === 1) {
      if (!currentStatusBekerja) {
        setErrorStatusBekerja("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorStatusBekerja(null);
      }

      if (!tglKerjaInput) {
        setErrorTglKerja("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorTglKerja(null);
      }

      if (!nmKantorInput) {
        setErrorNmKantor("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorNmKantor(null);
      }

      if (!takeHomeInput) {
        setErrortakeHomeInput("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrortakeHomeInput(null);
      }

      if (!initialProv.value) {
        setErrorProv("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorProv(null);
      }

      if (!initialKab.value) {
        setErrorKab("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorKab(null);
      }

      if (!currentJPerusahaan) {
        setErrorJPerusahaan("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorJPerusahaan(null);
      }

      if (!initialTingkat.value) {
        setErrorTingkat("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorTingkat(null);
      }

      if (!posisiInput) {
        setErrorPosisi("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorPosisi(null);
      }

      if (!bidangInput) {
        setErrorBidangInput("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorBidangInput(null);
      }
    }

    if (currentStatus === 3) {
      if (!tglWiraInput) {
        setErrorTglWira("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorTglWira(null);
      }

      if (!initialPosisiWira.value) {
        setErrorPosisiWira("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorPosisiWira(null);
      }

      if (!initialTingkatWira.value) {
        setErrorTingkatWira("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorTingkatWira(null);
      }

      if (!bdUsahaInput) {
        setErrorBdUsahaInput("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorBdUsahaInput(null);
      }

      if (!omsWiraInput) {
        setErrorOmsWiraInput("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorOmsWiraInput(null);
      }
    }

    if (currentStatus === 2) {
      if (!currentCariKerja) {
        setErrorCurrentCariKerja("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorCurrentCariKerja(null);
      }
    }

    if (currentStatus === 2 && currentCariKerja === 1) {
      const checkboxCheck =
        cari1 ||
        cari2 ||
        cari3 ||
        cari4 ||
        cari5 ||
        cari6 ||
        cari7 ||
        cari8 ||
        cari9 ||
        cari10 ||
        cari11 ||
        cari12 ||
        cari13 ||
        cari14 ||
        cari15 ||
        lainCariKerja;

      if (!tglCariKerja) {
        setErrorTglCariKerja("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorTglCariKerja(null);
      }

      if (!checkboxCheck) {
        setErrorCariKerja("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorCariKerja(null);
      }

      if (!jmlLamarPerusahaan) {
        setErrorJmlLamarPerusahaan("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorJmlLamarPerusahaan(null);
      }

      if (!jmlResponLamaranPerusahaan) {
        setErrorJmlResponLamaranPerusahaan("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorJmlResponLamaranPerusahaan(null);
      }

      if (!jmlUndanganPerusahaan) {
        setErrorJmlUndanganPerusahaan("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorJmlUndanganPerusahaan(null);
      }
    }

    if (currentStatus === 2 && currentCariKerja === 2) {
      if (!noCariKerja) {
        setErrorNoCariKerja("Inputan wajib diisi");
        isValid = false;
      } else {
        setErrorNoCariKerja(null);
      }
    }

    return isValid;
  };

  const handleSubmitExitSurvey = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const result = {
        f8: currentStatus === 1 ? currentStatusBekerja : currentStatus,
        tgl_kerja: tglKerjaInput || tglWiraInput,
        f505: takeHomeInput,
        f5b: nmKantorInput,
        f5a1: initialProv.value,
        f5a2: initialKab.value,
        f1101: currentJPerusahaan,
        f5d: initialTingkat.value || initialTingkatWira.value,
        n1a: posisiInput,
        n2a: bidangInput,
        f5c: initialPosisi.value || initialPosisiWira.value,
        n1b: bdUsahaInput,
        n2b: omsWiraInput,
        n1c: currentCariKerja,
        n2c: tglCariKerja,
        f401: cari1,
        f402: cari2,
        f403: cari3,
        f404: cari4,
        f405: cari5,
        f406: cari6,
        f407: cari7,
        f408: cari8,
        f409: cari9,
        f410: cari10,
        f411: cari11,
        f412: cari12,
        f413: cari13,
        f414: cari14,
        f415: cari15,
        f416: lainCariKerja,
        f6: jmlLamarPerusahaan,
        f7: jmlResponLamaranPerusahaan,
        f7a: jmlUndanganPerusahaan,
        n3c: noCariKerja,
      };
      const response = await saveExitSurveyController(result);

      if (response.meta?.code === 200) {
        setTimeout(() => {
          setLoading(false);
          toast.success("Berhasil mengirim kuseioner exit survey");
        }, 200);
        navigate(mhsExitRoute);
      } else {
        setTimeout(() => {
          setLoading(false);
          toast.error(response.message);
        }, 200);
      }
    }
  };

  const getExitSurvey = async () => {
    const result = await getCekExitSurveyController();
    setExitSurvey(result);
  };

  useEffect(() => {
    if (currentStatus === 1) {
      setTglWiraInput(null);
      setInitialPosisiWira({ label: "Pilih posisi", value: null });
      setInitialTingkatWira({ label: "Pilih tingkat", value: null });
      setBdUsahaInput(null);
      setOmsWiraInput(null);

      setCari1(null);
      setCari2(null);
      setCari3(null);
      setCari4(null);
      setCari5(null);
      setCari6(null);
      setCari7(null);
      setCari8(null);
      setCari9(null);
      setCari10(null);
      setCari11(null);
      setCari12(null);
      setCari13(null);
      setCari14(null);
      setCari15(null);
      setLainCariKerja(null);
      setTglCariKerja(null);
      setJmlLamarPerusahaan(null);
      setJmlResponLamaranPerusahaan(null);
      setJmlUndanganPerusahaan(null);
      setNoCariKerja(null);
      setcurrentCariKerja(null);
    }

    if (currentStatus === 3) {
      setCurrentStatusBekerja(null);
      setTglKerjaInput(null);
      setNmKantorInput(null);
      setInitialProv({ label: "Pilih provinsi", value: null });
      setInitialKab({ label: "Pilih kabupaten atau kota", value: null });
      setCurrentJPerusahaan(null);
      setInitialTingkat({ label: "Pilih tingkat", value: null });
      setPosisiInput(null);
      setBidangInput(null);

      setCari1(null);
      setCari2(null);
      setCari3(null);
      setCari4(null);
      setCari5(null);
      setCari6(null);
      setCari7(null);
      setCari8(null);
      setCari9(null);
      setCari10(null);
      setCari11(null);
      setCari12(null);
      setCari13(null);
      setCari14(null);
      setCari15(null);
      setLainCariKerja(null);
      setTglCariKerja(null);
      setJmlLamarPerusahaan(null);
      setJmlResponLamaranPerusahaan(null);
      setJmlUndanganPerusahaan(null);
      setNoCariKerja(null);
      setcurrentCariKerja(null);
    }

    if (currentStatus === 2) {
      setCurrentStatusBekerja(null);
      setTglKerjaInput(null);
      setNmKantorInput(null);
      setInitialProv({ label: "Pilih provinsi", value: null });
      setInitialKab({ label: "Pilih kabupaten atau kota", value: null });
      setCurrentJPerusahaan(null);
      setInitialTingkat({ label: "Pilih tingkat", value: null });
      setPosisiInput(null);
      setBidangInput(null);

      setTglWiraInput(null);
      setInitialPosisiWira({ label: "Pilih posisi", value: null });
      setInitialTingkatWira({ label: "Pilih tingkat", value: null });
      setBdUsahaInput(null);
      setOmsWiraInput(null);
    }
  }, [currentStatus]);

  useEffect(() => {
    getMasterProvinsi();
    getMasterTingkatKerja();
    getMasterPosisi();
    getExitSurvey();
  }, []);

  useEffect(() => {
    if (masterProvinsi.length > 0) {
      setupOpsiProvinsi();
    }
    if (masterTingKerja.length > 0) {
      setupTingkatTempatKerja();
    }
    if (masterPosisi.length > 0) {
      setupPosisiWirausaha();
    }
  }, [masterProvinsi, masterTingKerja, masterPosisi]);

  useEffect(() => {
    if (currentJPerusahaan) {
      setErrorJPerusahaan(null);
    }
    if (currentCariKerja) {
      setErrorCurrentCariKerja(null);
    }
  }, [currentJPerusahaan, currentCariKerja]);

  //   useEffect(() => {
  //     if (username !== "5200411340") {
  //       navigate(notFoundRoute);
  //     }
  //   }, []);

  useEffect(() => {
    if (exitSurvey?.data?.sts_input) {
      navigate(notFoundRoute);
    }
  }, [exitSurvey]);

  return (
    <Column className="w-full relative overflow-hidden max-w-3xl px-3.5 pt-[6.5rem] pb-14 gap-5 mx-auto">
      {exitSurvey?.data?.date_input === null && (
        <Card className="w-full">
          <Column className="pb-4">
            <Row className="px-5 lg:px-9">
              <Column className="gap-0.5 my-auto">
                <Title>Survei Wajib</Title>
                <Caption>Lengkapi semua isian yang ada</Caption>
              </Column>
              <TbClipboardText className="hidden sm:flex h-14 w-14 my-auto stroke-1.5 text-indigo-300" />
            </Row>
            <HorizontalDivider className="-mx-5 lg:-mx-5 py-2" />
            <Column className="px-5 lg:px-9 gap-6">
              <Column className="gap-1.5">
                <Text className="font-manrope font-semibold">
                  Jelaskan status Anda saat ini?
                  <span className="text-red-500">*</span>
                </Text>
                <Card className="w-full p-0 overflow-hidden shadow-none">
                  <Column className="divide-y">
                    <RadioButton
                      action={10}
                      onChange={onChangeRadio}
                      id="f8-1"
                      name="f8"
                      value={1}
                      checked={currentStatus === 1}
                      label="Bekerja (full time/part time)"
                      className="w-full pl-3 hover:bg-slate-50"
                    />
                    <RadioButton
                      action={10}
                      onChange={onChangeRadio}
                      id="f8-2"
                      name="f8"
                      value={3}
                      checked={currentStatus === 3}
                      label="Wiraswasta"
                      className="w-full pl-3 hover:bg-slate-50"
                    />
                    <RadioButton
                      action={10}
                      onChange={onChangeRadio}
                      id="f8-3"
                      name="f8"
                      value={2}
                      checked={currentStatus === 2}
                      label="Belum Bekerja"
                      className="w-full pl-3 hover:bg-slate-50"
                    />
                  </Column>
                </Card>
                {errorStatus !== null && (
                  <Overline className="text-red-600">* {errorStatus}</Overline>
                )}
              </Column>
              {currentStatus === 1 ? (
                <Column className="gap-6">
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Jika bekerja tipe pekerjaan apa yang anda kerjakan?
                      <span className="text-red-500">*</span>
                    </Text>
                    <Card className="w-full p-0 overflow-hidden shadow-none">
                      <Column className="divide-y">
                        <RadioButton
                          action={40}
                          onChange={onChangeRadio}
                          id="f8-fulltime"
                          name="jobtype"
                          value="fulltime"
                          checked={currentStatusBekerja === "fulltime"}
                          label="Full Time"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                        <RadioButton
                          action={40}
                          onChange={onChangeRadio}
                          id="f8-parttime"
                          name="jobtype"
                          value="parttime"
                          checked={currentStatusBekerja === "parttime"}
                          label="Part Time"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                      </Column>
                    </Card>
                    {errorStatusBekerja !== null && (
                      <Overline className="text-red-600">
                        * {errorStatusBekerja}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Kapan kira-kira anda mulai bekerja?
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={10}
                      id="tgl_kerja"
                      type="date"
                      inputWidth="w-full"
                      className="rounded-md appearance-none h-11"
                      placeholder="dd/mm/yyyy"
                      onChange={onChangeInput}
                      defaultValue={tglKerjaInput}
                      required
                    />
                    {errorTglKerja !== null && (
                      <Overline className="text-red-600">
                        * {errorTglKerja}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Apa nama perusahaan/kantor tempat Anda bekerja?
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={20}
                      id="f5b"
                      type="text"
                      inputWidth="w-full"
                      className="rounded-md"
                      placeholder="Jawaban anda"
                      onChange={onChangeInput}
                      defaultValue={nmKantorInput}
                      maxLength={50}
                      required
                    />
                    {errorNmKantor !== null && (
                      <Overline className="text-red-600">
                        * {errorNmKantor}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Berapa rata-rata pendapatan anda per bulan? (take home
                      pay)?
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={140}
                      id="f505"
                      type="number"
                      inputWidth="w-full"
                      className="no-spinner rounded-md"
                      placeholder="Jawaban anda"
                      onChange={onChangeInput}
                      onKeyDown={(event) => {
                        if (
                          event.key !== "Backspace" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight"
                        ) {
                          if (!/^[0-9\b]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }
                      }}
                      defaultValue={takeHomeInput}
                      maxLength={10}
                      required
                    />
                    <Caption>
                      <FormatRupiah value={takeHomeInput} />
                    </Caption>
                    {errorTakeHomeInput !== null && (
                      <Overline className="text-red-600">
                        * {errorTakeHomeInput}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Dimana lokasi tempat Anda bekerja?
                      <span className="text-red-500">*</span>
                    </Text>
                    <Column className="gap-3">
                      <Column className="gap-1.5">
                        <SelectInput
                          action={10}
                          zindex="z-20"
                          initial={initialProv}
                          options={opsiProv}
                          onChange={onChangeSelect}
                          required
                        />
                        {errorProv !== null && (
                          <Overline className="text-red-600">
                            * {errorProv}
                          </Overline>
                        )}
                      </Column>
                      <Column className="gap-1.5">
                        <SelectInput
                          action={20}
                          zindex="z-10"
                          initial={initialKab}
                          options={opsiKab}
                          onChange={onChangeSelect}
                          required
                        />
                        {errorKab !== null && (
                          <Overline className="text-red-600">
                            * {errorKab}
                          </Overline>
                        )}
                      </Column>
                    </Column>
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Apa jenis perusahaan/instansi/institusi tempat anda
                      bekerja sekarang?
                      <span className="text-red-500">*</span>
                    </Text>
                    <Card className="w-full p-0 overflow-hidden shadow-none">
                      <Column className="divide-y">
                        <RadioButton
                          action={20}
                          onChange={onChangeRadio}
                          id="f1101-1"
                          name="f1101"
                          value={1}
                          checked={currentJPerusahaan === 1}
                          label="Instansi pemerintah"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                        <RadioButton
                          action={20}
                          onChange={onChangeRadio}
                          id="f1101-2"
                          name="f1101"
                          value={6}
                          checked={currentJPerusahaan === 6}
                          label="BUMN"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                        <RadioButton
                          action={20}
                          onChange={onChangeRadio}
                          id="f1101-3"
                          name="f1101"
                          value={7}
                          checked={currentJPerusahaan === 7}
                          label="Institusi/Organisasi Multilateral"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                        <RadioButton
                          action={20}
                          onChange={onChangeRadio}
                          id="f1101-4"
                          name="f1101"
                          value={2}
                          checked={currentJPerusahaan === 2}
                          label="Lembaga Swadaya Masyarakat"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                        <RadioButton
                          action={20}
                          onChange={onChangeRadio}
                          id="f1101-5"
                          name="f1101"
                          value={3}
                          checked={currentJPerusahaan === 3}
                          label="Perusahaan swasta"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                      </Column>
                    </Card>
                    {errorJPerusahaan !== null && (
                      <Overline className="text-red-600">
                        * {errorJPerusahaan}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Apa tingkat tempat kerja Anda?
                      <span className="text-red-500">*</span>
                    </Text>
                    <SelectInput
                      action={30}
                      zindex="z-10"
                      initial={initialTingkat}
                      options={opsiTingkat}
                      onChange={onChangeSelect}
                      required
                    />
                    {errorTingkat !== null && (
                      <Overline className="text-red-600">
                        * {errorTingkat}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Apa posisi/jabatan Anda?
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={30}
                      id="n1a"
                      type="text"
                      inputWidth="w-full"
                      className="rounded-md"
                      placeholder="Jawaban anda"
                      onChange={onChangeInput}
                      defaultValue={posisiInput}
                      maxLength={50}
                      required
                    />
                    {errorPosisi !== null && (
                      <Overline className="text-red-600">
                        * {errorPosisi}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Sebutkan bidang perusahaan tempat Anda bekerja!
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={40}
                      id="n2a"
                      type="text"
                      inputWidth="w-full"
                      className="rounded-md"
                      placeholder="Jawaban anda"
                      helperText="Contoh: Pajak, pertambangan, dll"
                      onChange={onChangeInput}
                      defaultValue={bidangInput}
                      maxLength={50}
                      required
                    />
                    {errorBidangInput !== null && (
                      <Overline className="text-red-600">
                        * {errorBidangInput}
                      </Overline>
                    )}
                  </Column>
                </Column>
              ) : currentStatus === 3 ? (
                <Column className="gap-6">
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Kapan kira-kira anda mulai wirausaha?
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={50}
                      id="tgl_wirausaha"
                      type="date"
                      inputWidth="w-full"
                      className="rounded-md appearance-none h-11"
                      placeholder="dd/mm/yyyy"
                      onChange={onChangeInput}
                      defaultValue={tglWiraInput}
                      required
                    />
                    {errorTglWira !== null && (
                      <Overline className="text-red-600">
                        * {errorTglWira}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Bila berwiraswasta, apa posisi/jabatan Anda saat ini?
                      <span className="text-red-500">*</span>
                    </Text>
                    <SelectInput
                      action={60}
                      zindex="z-20"
                      initial={initialPosisiWira}
                      options={opsiPosisiWira}
                      onChange={onChangeSelect}
                      required
                    />
                    {errorPosisiWira !== null && (
                      <Overline className="text-red-600">
                        * {errorPosisiWira}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Apa tingkat tempat kerja Anda?
                      <span className="text-red-500">*</span>
                    </Text>
                    <SelectInput
                      action={50}
                      zindex="z-10"
                      initial={initialTingkatWira}
                      options={opsiTingkatWira}
                      onChange={onChangeSelect}
                      required
                    />
                    {errorTingkatWira !== null && (
                      <Overline className="text-red-600">
                        * {errorTingkatWira}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      bergerak di bidang apakah usaha anda?
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={60}
                      id="n1b"
                      type="text"
                      inputWidth="w-full"
                      className="rounded-md"
                      placeholder="Jawaban anda"
                      helperText="Contoh: Jasa, pertambangan, dll"
                      onChange={onChangeInput}
                      defaultValue={bdUsahaInput}
                      maxLength={50}
                      required
                    />
                    {errorBdUsahaInput !== null && (
                      <Overline className="text-red-600">
                        * {errorBdUsahaInput}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Kira - kira berapa omset usaha Anda dalam 1 bulan?
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={70}
                      id="n2b"
                      type="number"
                      inputWidth="w-full"
                      className="no-spinner rounded-md"
                      placeholder="Jawaban anda"
                      onChange={onChangeInput}
                      onKeyDown={(event) => {
                        if (
                          event.key !== "Backspace" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight"
                        ) {
                          if (!/^[0-9\b]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }
                      }}
                      defaultValue={omsWiraInput}
                      maxLength={10}
                      required
                    />
                    <Caption>
                      <FormatRupiah value={omsWiraInput} />
                    </Caption>
                    {errorOmsWiraInput !== null && (
                      <Overline className="text-red-600">
                        * {errorOmsWiraInput}
                      </Overline>
                    )}
                  </Column>
                </Column>
              ) : currentStatus === 2 ? (
                <Column className="gap-6">
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Apakah anda sudah mulai mencari/melamar pekerjaan?
                      <span className="text-red-500">*</span>
                    </Text>
                    <Card className="w-full p-0 overflow-hidden shadow-none mb-6">
                      <Column className="divide-y">
                        <Column>
                          <RadioButton
                            action={30}
                            onChange={onChangeRadio}
                            id="n1c-1"
                            name="n1c"
                            value={1}
                            checked={currentCariKerja === 1}
                            label="Sudah"
                            className="w-full pl-3 hover:bg-slate-50"
                          />
                        </Column>
                        <Column>
                          <RadioButton
                            action={30}
                            onChange={onChangeRadio}
                            id="n1c-2"
                            name="n1c"
                            value={2}
                            checked={currentCariKerja === 2}
                            label="Belum"
                            className="w-full pl-3 hover:bg-slate-50"
                          />
                        </Column>
                      </Column>
                    </Card>

                    {/* current status sudah mencari/melamar pekerjaan  */}
                    {currentCariKerja === 1 && (
                      <Column className={`gap-6`}>
                        <Column className="gap-1.5">
                          <Text className="font-manrope font-semibold">
                            Kapan anda mulai mencari pekerjaan? Mohon pekerjaan
                            sambilan tidak dimasukkan.{" "}
                            <span className="text-red-500">*</span>
                          </Text>
                          <OutlineInput
                            action={80}
                            id="tgl_cari_kerja"
                            type="date"
                            inputWidth="w-full"
                            className="rounded-md appearance-none h-11"
                            placeholder="dd/mm/yyyy"
                            onChange={onChangeInput}
                            defaultValue={tglCariKerja}
                            required
                          />
                          {errorTglCariKerja !== null && (
                            <Overline className="text-red-600">
                              * {errorTglCariKerja}
                            </Overline>
                          )}
                        </Column>
                        <Column className="gap-3">
                          <Column className="gap-1.5">
                            <Text className="font-manrope font-semibold">
                              Bagaimana anda mencari pekerjaan tersebut?{" "}
                              <span className="text-red-500">*</span>
                            </Text>
                            <Overline className="text-indigo-700">
                              Jawaban bisa lebih dari satu
                            </Overline>
                          </Column>
                          <Card className="w-full p-0 overflow-hidden shadow-none">
                            <Column className="divide-y">
                              <CheckboxButton
                                action={10}
                                onChange={onChangeCheckbox}
                                id="f401"
                                label="Melalui iklan di koran/majalah, brosur."
                                checked={cari1 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={20}
                                onChange={onChangeCheckbox}
                                id="f402"
                                label="Melamar ke perusahaan tanpa mengetahui lowongan yang ada."
                                checked={cari2 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={30}
                                onChange={onChangeCheckbox}
                                id="f403"
                                label="Pergi ke bursa/pameran kerja."
                                checked={cari3 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={40}
                                onChange={onChangeCheckbox}
                                id="f404"
                                label="Mencari lewat internet/iklan online/milis."
                                checked={cari4 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={50}
                                onChange={onChangeCheckbox}
                                id="f405"
                                label="Dihubungi oleh perusahaan."
                                checked={cari5 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={60}
                                onChange={onChangeCheckbox}
                                id="f406"
                                label="Menghubungi Kemenakertrans."
                                checked={cari6 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={70}
                                onChange={onChangeCheckbox}
                                id="f407"
                                label="Menghubungi agen tenaga kerja komersial/swasta."
                                checked={cari7 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={80}
                                onChange={onChangeCheckbox}
                                id="f408"
                                label="Memeroleh informasi dari pusat/kantor pengembangan karir fakultas/universitas."
                                checked={cari8 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={90}
                                onChange={onChangeCheckbox}
                                id="f409"
                                label="Menghubungi kantor kemahasiswaan/hubungan alumni."
                                checked={cari9 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={100}
                                onChange={onChangeCheckbox}
                                id="f410"
                                label="Membangun jejaring (network) sejak masih kuliah."
                                checked={cari10 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={110}
                                onChange={onChangeCheckbox}
                                id="f411"
                                label="Melalui relasi (misalnya dosen, orang tua, saudara, teman, dll.)"
                                checked={cari11 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={120}
                                onChange={onChangeCheckbox}
                                id="f412"
                                label="Membangun bisnis sendiri."
                                checked={cari12 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={130}
                                onChange={onChangeCheckbox}
                                id="f413"
                                label="Melalui penempatan kerja atau magang."
                                checked={cari13 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <CheckboxButton
                                action={140}
                                onChange={onChangeCheckbox}
                                id="f414"
                                label="Bekerja di tempat yang sama dengan tempat kerja semasa kuliah."
                                checked={cari14 === 1}
                                className="w-full pl-3 hover:bg-slate-50"
                              />
                              <Column>
                                <CheckboxButton
                                  action={150}
                                  onChange={onChangeCheckbox}
                                  id="f415"
                                  label="Lainnya"
                                  checked={cari15 === 1}
                                  className="w-full pl-3 hover:bg-slate-50"
                                />
                                <div
                                  className={`p-4 border-t ${
                                    cari15 ? "flex" : "hidden"
                                  }`}
                                >
                                  <OutlineInput
                                    refs={refCariLainnya}
                                    action={90}
                                    id="f416"
                                    type="text"
                                    inputWidth="w-full"
                                    className="rounded-md"
                                    label="Tuliskan lainnya"
                                    labelClassName="text-gray-800"
                                    placeholder="Jawaban anda"
                                    onChange={onChangeInput}
                                    defaultValue={lainCariKerja}
                                    maxLength={50}
                                    required={cari15}
                                  />
                                </div>
                              </Column>
                            </Column>
                          </Card>
                          {errorCariKerja !== null && (
                            <Overline className="text-red-600">
                              * {errorCariKerja}
                            </Overline>
                          )}
                        </Column>
                        <Column className="gap-1.5">
                          <Text className="font-manrope font-semibold">
                            Berapa perusahaan/instansi/institusi yang sudah anda
                            lamar (lewat surat atau e-mail)?
                            <span className="text-red-500">*</span>
                          </Text>
                          <OutlineInput
                            action={100}
                            id="f6"
                            type="number"
                            inputWidth="w-full"
                            helperText="Contoh: 10"
                            className="no-spinner rounded-md"
                            placeholder="Jawaban anda"
                            onChange={onChangeInput}
                            onKeyDown={(event) => {
                              if (
                                event.key !== "Backspace" &&
                                event.key !== "ArrowLeft" &&
                                event.key !== "ArrowRight"
                              ) {
                                if (!/^[0-9\b]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }
                            }}
                            defaultValue={jmlLamarPerusahaan}
                            maxLength={10}
                            required
                          />
                          {errorJmlLamarPerusahaan !== null && (
                            <Overline className="text-red-600">
                              * {errorJmlLamarPerusahaan}
                            </Overline>
                          )}
                        </Column>
                        <Column className="gap-1.5">
                          <Text className="font-manrope font-semibold">
                            Berapa banyak perusahan/instansi/institusi yang
                            merespons lamaran anda?{" "}
                            <span className="text-red-500">*</span>
                          </Text>
                          <OutlineInput
                            action={110}
                            id="f7"
                            type="number"
                            inputWidth="w-full"
                            helperText="Contoh: 10"
                            className="no-spinner rounded-md"
                            placeholder="Jawaban anda"
                            onChange={onChangeInput}
                            onKeyDown={(event) => {
                              if (
                                event.key !== "Backspace" &&
                                event.key !== "ArrowLeft" &&
                                event.key !== "ArrowRight"
                              ) {
                                if (!/^[0-9\b]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }
                            }}
                            defaultValue={jmlResponLamaranPerusahaan}
                            maxLength={10}
                            required
                          />
                          {errorJmlResponLamaranPerusahaan !== null && (
                            <Overline className="text-red-600">
                              * {errorJmlResponLamaranPerusahaan}
                            </Overline>
                          )}
                        </Column>
                        <Column className="gap-1.5">
                          <Text className="font-manrope font-semibold">
                            Berapa banyak perusahan/instansi/institusi yang
                            mengundang anda untuk wawancara?{" "}
                            <span className="text-red-500">*</span>
                          </Text>
                          <OutlineInput
                            action={120}
                            id="f7a"
                            type="number"
                            inputWidth="w-full"
                            helperText="Contoh: 10"
                            className="no-spinner rounded-md"
                            placeholder="Jawaban anda"
                            onChange={onChangeInput}
                            onKeyDown={(event) => {
                              if (
                                event.key !== "Backspace" &&
                                event.key !== "ArrowLeft" &&
                                event.key !== "ArrowRight"
                              ) {
                                if (!/^[0-9\b]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }
                            }}
                            defaultValue={jmlUndanganPerusahaan}
                            maxLength={10}
                            required
                          />
                          {errorJmlUndanganPerusahaan !== null && (
                            <Overline className="text-red-600">
                              * {errorJmlUndanganPerusahaan}
                            </Overline>
                          )}
                        </Column>
                      </Column>
                    )}

                    {/* current status belum mencari/melamar pekerjaan */}
                    {currentCariKerja === 2 && (
                      <Column className="gap-1.5">
                        <Text className="font-manrope font-semibold">
                          Mengapa anda belum mulai mencari/melamar kerja?{" "}
                          <span className="text-red-500">*</span>
                        </Text>
                        <OutlineTextArea
                          action={130}
                          id="n3c"
                          type="text"
                          inputWidth="w-full"
                          className="rounded-md"
                          labelClassName="text-gray-800"
                          placeholder="Jawaban anda"
                          maxLength={150}
                          initialRow={5}
                          onChange={onChangeInput}
                          defaultValue={noCariKerja}
                          required={currentCariKerja === 2}
                        />
                      </Column>
                    )}
                    {errorNoCariKerja !== null && (
                      <Overline className="text-red-600">
                        * {errorNoCariKerja}
                      </Overline>
                    )}
                    {errorCurrentCariKerja !== null && (
                      <Overline className="text-red-600">
                        * {errorCurrentCariKerja}
                      </Overline>
                    )}
                  </Column>
                </Column>
              ) : null}
            </Column>
          </Column>
        </Card>
      )}

      {currentStatus !== null && (
        <Row className={`w-fit gap-3`}>
          <PrimaryButton
            onClick={handleSubmitExitSurvey}
            color="indigo"
            leadingIcon={<TbUpload className="h-5 w-5" />}
          >
            Simpan Jawaban
          </PrimaryButton>
        </Row>
      )}
    </Column>
  );
}
