import Pusher from 'pusher-js'
import Randomstring from 'randomstring'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { TbAlertTriangle, TbMessageCircle, TbPlus, TbRefresh, TbSearch, TbSend, TbX } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { currentIndexTabKonsul, gConsultationCategory } from '../../atoms'
import WAlmChats from '../../components/walm_chats'
import WAlmHistoryConsultation from '../../components/walm_list_consultation'
import { checkRequestConsultationController, requestConsultationController } from '../../controllers/alumni_controllers'
import { getConsultationCategoryController } from '../../controllers/global_controllers'
import AlumniLayout from '../../layouts/alumni_layout'
import { AlertDialog, ButtonHover, Card, Column, Form, FullScHeight, Headline3, IconButton, LoadingDialog, OutlineInput, OutlineTextArea, PrimaryButton, Row, SecondaryButton, SelectView, TabPanel, TabsLine, Text, TextButton, ToasterView, TransitionPage } from '../../shared/ui'

const ConsultastionAlumni = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabKonsul)
    const [listCategory, setListCategory] = useRecoilState(gConsultationCategory)
    const [initialCategory, setInitialCategory] = useState({ label: 'Pilih Kategori', value: null })
    const [currentCategoty, setCurrentCategory] = useState({ label: 'Pekerjaan', value: 1 })
    const [emptyRequest, setEmptyRequest] = useState({ status: false, message: '' })
    const [errorTopic, setErrorTopic] = useState({ status: false, message: '' })
    const [currentMessage, setCurrentMessage] = useState(null)
    const [currentUpdate, setCurrentUpdate] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [showAlertDialog, setShowAlertDialog] = useState(false)
    const [reqUpdate, setReqUpdate] = useState(Randomstring.generate(6))
    const [loading, setLoading] = useState(false)
    const [channel, setChannel] = useState(null)

    const getConsultationCategory = async () => {
        const result = await getConsultationCategoryController()
        if (result.status) {
            let listCategoryTemp = []
            const category = [...result.data]
            if (category.length > 0) {
                category.map((cat) => {
                    listCategoryTemp.push({
                        label: cat.kategori_konsultasi,
                        value: cat.id_kategori_konsultasi,
                    })
                })
                setListCategory(listCategoryTemp)
            }
        }
    }

    const checkReqConsultation = async () => {
        const result = await checkRequestConsultationController()
        setEmptyRequest({
            status: result.status,
            message: result.message
        })
    }

    const onSubmitForm = async (event) => {
        event.preventDefault()
        const subject = event.target.subject.value
        const topic = event.target.topic.value

        if (initialCategory.value !== null && subject && topic) {
            setShowDialog(false)
            setLoading(true)
            const props = {
                category: initialCategory.value,
                subject: subject,
                topic: topic,
            }
            const result = await requestConsultationController(props)
            if (result.status) {
                setTimeout(() => {
                    if (currentTabIndex !== 1) {
                        onChangeTabIndex(1)
                    } else {
                        setReqUpdate(Randomstring.generate(6))
                    }
                    setLoading(false)
                    toast.success(result.message)
                }, 200)
            } else {
                setTimeout(() => {
                    setLoading(false)
                    toast.error(result.message)
                }, 200)
            }
        }
    }

    const onSelectedMessage = (message) => {
        if (currentMessage !== null) {
            if (currentMessage.status === 'ONGOING') {
                unSubscribePusher(10, message)
            } else {
                if (message.status === 'ONGOING') {
                    setupPusher(message)
                } else {
                    setCurrentMessage(message)
                }
            }
        } else {
            setupPusher(message)
        }
    }

    const setupPusher = (message) => {
        if (message.status === 'ONGOING') {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY_DEV, {
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER_DEV
            })
            const channel = pusher.subscribe(`konsultasi_${message.id_konsultasi}`)
            setChannel(channel)
        }
        setCurrentMessage(message)
    }

    const unSubscribePusher = async (action, message) => {
        await channel.unsubscribe(`konsultasi_${currentMessage.id_konsultasi}`)
        if (action === 10) {
            setupPusher(message)
        } else {
            setCurrentMessage(null)
        }
    }

    const updateMessage = (data) => {
        setCurrentUpdate(data)
    }

    const showDialogConsultation = () => {
        if (emptyRequest.status) {
            setErrorTopic({
                status: false,
                message: ''
            })
            setShowDialog(true)
        } else {
            setShowAlertDialog(true)
        }
    }

    const onChangeTabIndex = (index) => {
        if (currentMessage !== null) {
            if (currentMessage.status === 'ONGOING') {
                unSubscribePusher(20, null)
            } else {
                setCurrentMessage(null)
            }
        }
        setCurrentTabIndex(index)
    }

    useEffect(() => {
        getConsultationCategory()
        checkReqConsultation()
    }, [])

    return (
        <>
            <AlumniLayout idpage='consultation' removePadding showAppBarBorder>
                <TransitionPage>
                    <FullScHeight className='flex max-w-7xl pt-20 mx-auto'>
                        <Card className='w-full p-0 my-auto overflow-hidden shadow-lg shadow-gray-200'>
                            <Row className='divide-x-[1px] divide-gray-200'>
                                <Column className='relative sm:h-[85vh] sm:max-w-md'>
                                    <Column className='px-6 pt-6 pb-5 gap-y-2.5'>
                                        <Headline3>
                                            Konsultasi Karier
                                        </Headline3>
                                        <OutlineInput
                                            className='pl-10 pr-3'
                                            inputWidth='w-full'
                                            placeholder='Cari Konsultasi'
                                            bgColor='bg-slate-50'
                                            prefixClassName='px-3'
                                            prefix={<TbSearch className='h-5 w-5 text-gray-400' />} />
                                    </Column>
                                    <TabsLine className='justify-around' selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                                        <TabPanel className='max-h-[67vh]' removePadding title='Diterima' icon={<TbMessageCircle className='h-5 w-5' />}>
                                            <WAlmHistoryConsultation currentMessage={currentMessage ?? {}} action={10} onSelectedMessage={onSelectedMessage} updateMessage={currentUpdate} reqUpdate={reqUpdate} />
                                        </TabPanel>
                                        <TabPanel className='max-h-[67vh]' removePadding title='Menunggu' icon={<TbRefresh className='h-5 w-5' />} textActiveColor='text-orange-600' borderActiveColor='border-orange-600'>
                                            <WAlmHistoryConsultation currentMessage={currentMessage ?? {}} action={20} onSelectedMessage={onSelectedMessage} reqUpdate={reqUpdate} />
                                        </TabPanel>
                                        <TabPanel className='max-h-[67vh]' removePadding title='Ditolak' icon={<TbAlertTriangle className='h-5 w-5' />} textActiveColor='text-red-600' borderActiveColor='border-red-600'>
                                            <WAlmHistoryConsultation currentMessage={currentMessage ?? {}} action={30} onSelectedMessage={onSelectedMessage} />
                                        </TabPanel>
                                    </TabsLine>
                                    <Row className='w-fit absolute bottom-5 right-5'>
                                        <ButtonHover>
                                            <IconButton color='custom' className='p-3.5 bg-gradient-to-br from-blue-600  to-indigo-600 text-white' onClick={emptyRequest.status ? () => setShowDialog(true) : () => setShowAlertDialog(true)}>
                                                <TbPlus className='h-6 w-6' />
                                            </IconButton>
                                        </ButtonHover>
                                    </Row>
                                </Column>
                                <WAlmChats
                                    currentMessage={currentMessage}
                                    channel={channel}
                                    onUpdateMessage={updateMessage} />
                            </Row>
                        </Card>
                    </FullScHeight>
                </TransitionPage>
            </AlumniLayout>
            <AlertDialog className='max-w-2xl' show={showDialog} onClose={() => setShowDialog(false)}>
                <Form onSubmit={onSubmitForm}>
                    <Column className='p-6 sm:p-8 gap-y-8'>
                        <Column className='gap-y-2'>
                            <Headline3>
                                Pengajuan Konsultasi
                            </Headline3>
                            <Text>
                                Pengajuan Konsultasi akan melalui proses Verifikasi oleh Admin Alumni UTY dan semua informasi terkait perkembangan pengajuan konsultasi akan dikirimkan melalui email.
                            </Text>
                        </Column>
                        <SelectView
                            width='w-full'
                            label='Kategori Konsultasi'
                            bgColor='bg-white'
                            helperText='Pilih salah satu kategori'
                            initial={initialCategory}
                            options={listCategory}
                            onChangeOptions={(value) => setInitialCategory(value)}
                            required />
                        <OutlineInput
                            id='subject'
                            inputWidth='w-full'
                            label='Subjek Konsultasi'
                            placeholder='Masukkan subjek konsultasi'
                            helperText='Contoh: Langkah mulai berkarir setelah lulus'
                            bgColor='bg-white'
                            required />
                        <OutlineTextArea
                            id='topic'
                            className='py-3'
                            inputWidth='w-full'
                            bgColor='bg-white'
                            label='Silakan tulis pertanyaan anda di sini'
                            placeholder=''
                            helperText='Contoh: Apa saja yang harus disiapkan untuk mulai berkarir setelah lulus'
                            initialRow={5}
                            maxLength={500}
                            required />
                        <Row className='gap-x-3 justify-end'>
                            <ButtonHover>
                                <SecondaryButton onClick={() => setShowDialog(false)} leadingIcon={<TbX className='h-5 w-5 my-auto' />} type='submit' className='pr-5'>
                                    Batal
                                </SecondaryButton>
                            </ButtonHover>
                            <ButtonHover>
                                <PrimaryButton type='submit' leadingIcon={<TbSend className='h-5 w-5 rotate-12 my-auto' />} className='pr-5' color='indigo'>
                                    Kirim
                                </PrimaryButton>
                            </ButtonHover>
                        </Row>
                    </Column>
                </Form>
            </AlertDialog>
            <AlertDialog className='max-w-xs' show={showAlertDialog} onClose={() => setShowAlertDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 py-10 items-center gap-8'>
                        <Card className='p-1.5 rounded-2xl bg-indigo-50/50 border-indigo-200/50'>
                            <TbMessageCircle className='h-14 w-14 stroke-1.5 text-indigo-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Konsultasi Baru
                            </Headline3>
                            <Text className='text-center'>
                                {emptyRequest.message}
                            </Text>
                        </Column>
                    </Column>
                    <TextButton onClick={() => { setShowAlertDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                        Oke
                    </TextButton>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loading} message='Mengirim pengajuan...' />
            <ToasterView />
        </>
    )
}

export default ConsultastionAlumni
