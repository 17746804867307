import moment from "moment";
import React from "react";
import {
  TableCell,
  TableRow,
} from "../../../../../components/interfaces/table";
import { BodyText } from "../../../../../components/interfaces/text";
import { ButtonHover, IconButton } from "../../../../../shared/ui";
import { TbArrowRight } from "react-icons/tb";

function RowSurveiPengguna({ idx, survei, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow striped onClick={() => handleClick(survei)}>
      <TableCell>{limit * (page - 1) + (idx + 1)}</TableCell>
      <TableCell>
        <BodyText className="capitalize line-clamp-2">
          {survei.pengisi_nama}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className="capitalize line-clamp-2">
          {survei.pengisi_perusahaan}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className="capitalize line-clamp-2">
          {survei.mhs_nama}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className="capitalize line-clamp-2">
          {survei.nama_prodi}
        </BodyText>
      </TableCell>
      <TableCell>
        {moment(survei.created_at).format("DD MMM YYYY HH.mm")}
      </TableCell>
      <TableCell>
        <ButtonHover className={"mx-auto"}>
          <IconButton
            className="p-1.5"
            color="indigo"
            bordered
            onClick={() => handleClick(survei)}
          >
            <TbArrowRight className="h-5 w-5" />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  );
}

export default RowSurveiPengguna;
