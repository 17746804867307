import React, { useEffect, useState } from 'react'
import { TbCheck } from 'react-icons/tb'
import avatar2 from '../../images/avatar2.png'
import { Avatar, Caption, Column, Overline, Row } from '../../shared/ui'

const CAdmKonselor = ({ konselor, listSelected = [], onClick }) => {

    const [selected, setSelected] = useState(false)

    useEffect(() => {
        const searchKonselor = listSelected.filter((selected) => selected.nik === konselor.nik)
        if (searchKonselor.length > 0) {
            setSelected(true)
        } else {
            setSelected(false)
        }
    }, [konselor, listSelected])

    return (
        <Row onClick={onClick} className={`relative gap-2.5 px-3 py-2 cursor-pointer hover:bg-slate-50`}>
            <div className={`w-1 bg-indigo-700 absolute top-0 bottom-0 left-0 transform transition-transform ease-in-out ${selected ? 'translate-x-0' : '-translate-x-full'}`} />
            <Avatar src={avatar2} />
            <Column className='flex-1 gap-0.5 my-auto'>
                <Caption className='text-gray-800 font-medium'>
                    {konselor.nama}
                </Caption>
                <Overline>
                    {konselor.nik}
                </Overline>
            </Column>
            <TbCheck className={`absolute top-0 bottom-0 right-0 h-4 w-4 my-auto text-white p-0.5 bg-indigo-700 rounded-full transform transition-transform ease-in-out ${selected ? '-translate-x-3' : 'translate-x-full'}`} />
        </Row>
    )
}

export default CAdmKonselor
