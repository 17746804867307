import { gapi } from "gapi-script";
import React, { useEffect, useState } from "react";

const TestPagee = ({}) => {
  const CLIENT_ID =
    "78029760049-h419cmq53o75dv3ukk270ld3pgblggtu.apps.googleusercontent.com";
  const API_KEY = "AIzaSyD_ZshZw7_x6qD14WsvBAOXelDOIjwxpMA";
  const VIDEO_ID = "qKo0Jlao5ec";

  useEffect(() => {
    function start() {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        scope: "https://www.googleapis.com/auth/youtube.readonly",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const handleLogin = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        loadVideo();
      });
  };

  const loadVideo = async () => {
    const response = await gapi.client.youtube.videos.list({
      part: "player",
      id: VIDEO_ID,
    });

    const iframeHtml = response.result.items[0].player.embedHtml;
    document.getElementById("videoContainer").innerHTML = iframeHtml;
  };

  return (
    <>
      <button onClick={handleLogin}>Tonton</button>
      <div id="videoContainer"></div>
    </>
  );
};

export default TestPagee;
