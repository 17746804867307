import React, { useEffect, useState } from 'react'
import { getRekapSurveiPenggunaController } from '../../controllers/apicontrollers'
import { Card, Column, DotSeparated, OutlineInput, Row, SelectView, Table, TableBody, TableCell, TableHead, TableRow, Text, Title } from '../../shared/ui'
import moment from 'moment'
import { TbArrowRight } from 'react-icons/tb'
import { dateRangeSurveiPenggunaLulusan, masterFilterOvTracer } from '../../atoms'
import { useRecoilState } from 'recoil'
import RowTableRekapSurveiPenggunaLulusan from './rows/row_survei_pengguna_lulusan'

const TableRekapSurveiPenggunaLulusan = ({ action, onDoneLoadData }) => {

    const [dateRange, setDateRange] = useRecoilState(dateRangeSurveiPenggunaLulusan)
    const [listFilter, setListFilter] = useRecoilState(masterFilterOvTracer)
    const [listSurveiPengguna, setListSurveiPengguna] = useState([])
    const [currentProdi, setCurrentProdi] = useState({ label: 'Semua Program Studi', value: null })
    const [searchQuery, setSearchQuery] = useState(null)
    const [sortTable, setShortTable] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)

    const getRekapSurveiPengguna = async (start, end) => {
        const props = {
            action: action,
            limit: limitRow,
            page: currentPage,
            search: searchQuery,
            order: sortTable !== null ? sortTable.key : null,
            asc: sortTable !== null ? sortTable.type : null,
            date_start: start,
            date_end: end,
            mhs_prodi: currentProdi.value,
        }
        const result = await getRekapSurveiPenggunaController(props)
        if (result.status) {
            setListSurveiPengguna(result.data)
            setTotalRow(result.totalRows)
            setTotalPage(result.totalPage)
        }
        onDoneLoadData(true)
    }

    const onChangeInput = (result) => {
        if (result['action'] === 10) {
            setDateRange({
                start: result['value'],
                end: dateRange.end,
            })
            validateDate(result['value'], dateRange.end)
        } else {
            setDateRange({
                start: dateRange.start,
                end: result['value'],
            })
            validateDate(dateRange.start, result['value'])
        }
    }

    const validateDate = (start, end) => {
        if (start.length > 0 && end.length > 0) {
            if (new Date(end).getTime() >= new Date(start).getTime()) {
                getRekapSurveiPengguna(start, end)
            }
        }
    }

    const onChangeShowNumber = (value) => {
        setCurrentPage(1)
        setLimitRow(value)
    }

    const onChangeQuerySearch = (value) => {
        setCurrentPage(1)
        setSearchQuery(value)
    }

    const onChangePageNumber = (value) => {
        setCurrentPage(value)
    }

    const onShortableValue = (value) => {
        setCurrentPage(1)
        setShortTable(value)
    }

    const onChangeProdi = (value) => {
        setCurrentProdi(value)
    }

    useEffect(() => {
        getRekapSurveiPengguna(dateRange.start, dateRange.end)
    }, [searchQuery, sortTable, limitRow, currentPage, currentProdi.value])

    return (
        <Card className='w-full p-0'>
            <Column className='divide-y-[1px] divide-gray-200'>
                <Row className='flex-col sm:flex-row gap-4 px-5 py-4 justify-between'>
                    <Column className='gap-0.5 my-auto'>
                        <Title>
                            Survei Pengguna Lulusan
                        </Title>
                        <Row className='flex-wrap gap-y-1 gap-x-2'>
                            <Text>
                                {currentProdi.label}
                            </Text>
                            <DotSeparated className='mx-0.5' />
                            <Text>
                                Periode {moment(dateRange.start).format('DD MMM YYYY')}
                            </Text>
                            <TbArrowRight className='h-4 w-4 my-auto text-gray-500' />
                            <Text>
                                {moment(dateRange.end).format('DD MMM YYYY')}
                            </Text>
                        </Row>
                    </Column>
                    {/* <ButtonHover className='my-auto'>
                        <TertiaryButton color='indigo' className='py-2 pl-3 pr-4' leadingIcon={<TbDownload className='h-5 w-5 my-auto' />}>
                            Unduh
                        </TertiaryButton>
                    </ButtonHover> */}
                </Row>
                <Column className='gap-3 my-auto p-5 md:flex-row md:justify-between'>
                    <SelectView width='w-full sm:w-[21rem]' initial={currentProdi} options={listFilter.prodi} onChangeOptions={onChangeProdi} zindex='z-20' bgColor='bg-slate-50' />
                    <Column className='md:w-fit md:flex-row gap-3'>
                        <OutlineInput
                            action={10}
                            id='start'
                            type='date'
                            inputWidth='w-full sm:w-fit'
                            maxDate={new Date().toISOString().slice(0, 10)}
                            className='appearance-none h-[2.55rem] pl-3 pr-2'
                            bgColor='bg-slate-50'
                            placeholder='dd/mm/yyyy'
                            onChange={onChangeInput}
                            defaultValue={dateRange.start}
                            required />
                        <TbArrowRight className='h-5 w-5 my-auto text-gray-500' />
                        <OutlineInput
                            action={20}
                            id='end'
                            type='date'
                            inputWidth='w-full sm:w-fit'
                            minDate={dateRange.start}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            className='appearance-none h-[2.55rem] pl-3 pr-2'
                            bgColor='bg-slate-50'
                            placeholder='dd/mm/yyyy'
                            onChange={onChangeInput}
                            defaultValue={dateRange.end}
                            required />
                    </Column>
                </Column>
                <div className='p-5'>
                    <Table
                        className='border-[1px] border-gray-200'
                        limitRow={limitRow}
                        totalRow={totalRow}
                        totalPage={totalPage}
                        trigerPagination={sortTable}
                        onChangeShowNumber={onChangeShowNumber}
                        onChangeQuerySearch={onChangeQuerySearch}
                        onChangePageNumber={onChangePageNumber}>
                        <TableHead>
                            <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                <TableCell className='font-semibold font-manrope w-[5%]'>
                                    No.
                                </TableCell>
                                <TableCell className='font-semibold font-manrope w-[20%]' sortable sortkey='pengisi_nama' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Nama Pengisi
                                </TableCell>
                                <TableCell className='font-semibold font-manrope w-[25%]' sortable sortkey='pengisi_perusahaan' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Nama Perusahaan
                                </TableCell>
                                <TableCell className='font-semibold font-manrope w-[20%]' sortable sortkey='mhs_nama' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Nama Mahasiswa
                                </TableCell>
                                <TableCell className='font-semibold font-manrope w-[15%]' sortable sortkey='nama_prodi' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Program Studi
                                </TableCell>
                                <TableCell className='font-semibold font-manrope w-[15%]' sortable sortkey='created_at' currentSortKey={sortTable !== null ? sortTable.key : null} onShortable={onShortableValue}>
                                    Waktu Pengisian
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='divide-y-[1px] divide-gray-200'>
                            {listSurveiPengguna.length > 0 ? listSurveiPengguna.map((survei, idx) => {
                                return (
                                    <RowTableRekapSurveiPenggunaLulusan
                                        key={idx}
                                        idx={idx}
                                        survei={survei}
                                        currentPage={currentPage}
                                        limitRow={limitRow} />
                                )
                            }) : <TableRow>
                                <TableCell colSpan={6} className='text-center text-gray-500'>
                                    No data to display
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
            </Column>
        </Card>
    )
}

export default TableRekapSurveiPenggunaLulusan
