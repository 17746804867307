import React from 'react'
import { ShimmerAnimation, ShimmerItem, Table, TableBody, TableCell, TableHead, TableRow } from '../../shared/ui'

const STable = ({ rows = 5, column = 5, listWidth = ['w-16', 'w-56', 'w-32', 'w-32', 'w-32'], listAlign = ['justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end'] }) => {
    return (
        <Table className='border'>
            <TableHead>
                <TableRow className='bg-slate-100/50 border-b'>
                    {Array.from({ length: column }, (_, idx) => {
                        return <TableCell key={idx}>
                            <ShimmerAnimation className={`flex ${listAlign[idx]}`}>
                                <ShimmerItem className={`h-6 ${listWidth[idx]}`} />
                            </ShimmerAnimation>
                        </TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody className='divide-y'>
                {Array.from({ length: rows }, (_, idx) => {
                    return <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'}`}>
                        {Array.from({ length: column }, (_, idxx) => {
                            return <TableCell key={idxx}>
                                <ShimmerAnimation className={`flex ${listAlign[idxx]}`}>
                                    <ShimmerItem className={`h-6 ${listWidth[idxx]}`} />
                                </ShimmerAnimation>
                            </TableCell>
                        })}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    )
}

export default STable
