import React, { useState } from 'react'
import { TbAlertTriangle, TbPencil, TbPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { registerMitra } from '../controllers/apicontrollers'
import bguty from '../images/bguty.jpeg'
import { AlertDialog, ButtonHover, Caption, Card, Column, Form, FullScHeight, FullScreen, Headline1, Headline3, Image, LoadingView, OutlineInput, OutlineTextArea, Overline, PrimaryButton, Row, Text, TextButton, Title, TransitionPage } from '../shared/ui'
import { successRegMitraRoute } from '../shared/variable'

const RegisterMitraPage = () => {

    const navigateTo = useNavigate()
    const [fotoMitra, setFotoMitra] = useState(null)
    const [logoMitra, setLogoMitra] = useState(null)
    const [errLogoMitra, setErrLogoMitra] = useState(null)
    const [docMitra, setDocMitra] = useState(null)
    const [errDocMitra, setErrDocMitra] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showLargeDialog, setShowLargeDialog] = useState({
        show: false,
        message: '-'
    })

    const onChangeLogo = (event) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const size = file.size

            if ((size / 1024) <= 1024) {
                setLogoMitra(file)
                setErrLogoMitra(null)
            } else {
                setShowLargeDialog({
                    show: true,
                    message: 'Ukuran file logo yang dipilih tidak boleh lebih dari 1 MB'
                })
            }
        }
    }

    const onChangeDocument = (event) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const size = file.size

            if ((size / 1024) <= 2048) {
                setDocMitra(file)
                setErrDocMitra(null)
            } else {
                setShowLargeDialog({
                    show: true,
                    message: 'Ukuran file dokumen legalitas yang dipilih tidak boleh lebih dari 2 MB'
                })
            }
        }
    }

    const onChangeFoto = (event) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const size = file.size

            if ((size / 1024) <= 2048) {
                setFotoMitra(file)
            } else {
                setShowLargeDialog({
                    show: true,
                    message: 'Ukuran file foto yang dipilih tidak boleh lebih dari 2 MB'
                })
            }
        }
    }

    const onSubmitForm = async (event) => {
        event.preventDefault()
        const logo = event.target.logo.value
        const name = event.target.name.value
        const email = event.target.email.value
        const doc = event.target.doc.value
        const desc = event.target.desc.value
        const pic = event.target.pic.value
        const phone = event.target.phone.value
        const nowa = event.target.nowa.value
        const address = event.target.address.value

        if (logo && doc) {
            setLoading(true)
            const result = await registerMitra(fotoMitra, logoMitra, name, email, docMitra, desc, pic, phone, nowa, address)
            if (result.status) {
                setTimeout(() => {
                    setLoading(false)
                    navigateTo(successRegMitraRoute, { replace: true })
                }, 200)
            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 200)
            }
        } else {
            if (!logo) {
                setErrLogoMitra('Logo mitra tidak boleh kosong!')
            }
            if (!doc) {
                setErrDocMitra('Dokumen legalitas tidak boleh kosong!')
            }
        }
    }

    return (
        <>
            <FullScreen>
                <TransitionPage>
                    <Row>
                        <FullScHeight className='w-full hidden lg:flex bg-white'>
                            <img src={bguty} className='object-cover' alt='background' />
                        </FullScHeight>
                        <FullScHeight className='w-full bg-white'>
                            <Column className='h-screen p-5 sm:p-12 gap-12 overflow-y-auto scrollbar-hide'>
                                <Column className='gap-12 p-3 sm:p-8'>
                                    <Column className='gap-2'>
                                        <Headline1 className='font-semibold'>
                                            Mendaftar sebagai Mitra
                                        </Headline1>
                                        <Title className='font-medium'>
                                            Universitas Teknologi Yogyakarta
                                        </Title>
                                    </Column>
                                    <Form onSubmit={onSubmitForm}>
                                        <Column className='gap-7'>
                                            <Column className='gap-y-2'>
                                                <Column className='relative h-[17.5rem]'>
                                                    <Card className={`w-full relative h-[14rem] p-0 ${fotoMitra ? 'overflow-hidden' : 'bg-slate-50'}`}>
                                                        {fotoMitra && <Image src={URL.createObjectURL(fotoMitra)} imgClasName='w-full h-[14rem] object-cover' hoverScale={false} />}
                                                        <div className='flex absolute bottom-3 left-3 right-3 justify-end'>
                                                            <input
                                                                id='foto'
                                                                type='file'
                                                                className='hidden'
                                                                accept='image/*'
                                                                onChange={onChangeFoto}
                                                                onClick={(event) => {
                                                                    event.currentTarget.value = null
                                                                }} />
                                                            <label htmlFor='foto' className='p-1.5 bg-indigo-50 border border-indigo-300 rounded-full cursor-pointer'>
                                                                {fotoMitra ? <TbPencil className='h-5 w-5 translate-x-[0.2px] text-indigo-600' /> : <TbPlus className='h-5 w-5 translate-x-[0.2px] text-indigo-600' />}
                                                            </label>
                                                        </div>
                                                    </Card>
                                                    <div className='w-fit absolute bottom-0 left-7 inset-x-0'>
                                                        <input
                                                            id='logo'
                                                            type='file'
                                                            className='hidden'
                                                            accept='image/*'
                                                            onChange={onChangeLogo}
                                                            onClick={(event) => {
                                                                event.currentTarget.value = null
                                                            }} />
                                                        <label
                                                            htmlFor='logo' className={`flex w-32 h-32 p-2 aspect-square items-center cursor-pointer rounded-full border ring-4 ring-white overflow-hidden ${logoMitra ? 'bg-white' : 'bg-slate-50'} ${errLogoMitra && 'border-red-300'}`}>
                                                            {logoMitra ? <Image src={URL.createObjectURL(logoMitra)} imgClasName='w-full max-w-[8rem] object-fill' hoverScale={false} /> : <Column className='items-center'>
                                                                <Caption className='text-center new-line'>
                                                                    {'Logo Mitra'} <span className='text-red-500'>*</span>
                                                                </Caption>
                                                            </Column>}
                                                        </label>
                                                    </div>
                                                    <label htmlFor='logo' className='absolute bottom-2 left-[8rem] p-1.5 bg-indigo-50 border border-indigo-300 rounded-full cursor-pointer'>
                                                        {logoMitra ? <TbPencil className='h-5 w-5 translate-x-[0.2px] text-indigo-600' /> : <TbPlus className='h-5 w-5 translate-x-[0.2px] text-indigo-600' />}
                                                    </label>
                                                </Column>
                                                {errLogoMitra && <Overline className='text-red-500 font-medium px-4'>
                                                    {errLogoMitra}
                                                </Overline>}
                                            </Column>
                                            <OutlineInput
                                                id='name'
                                                type='text'
                                                className='py-3'
                                                inputWidth='w-full'
                                                bgColor='bg-slate-50'
                                                label='Nama Perusahaan'
                                                placeholder='Masukkan nama perusahaan'
                                                helperText='Contoh: KAI Indonesia'
                                                maxLength={100}
                                                required />
                                            <OutlineTextArea
                                                id='desc'
                                                className='py-3'
                                                inputWidth='w-full'
                                                bgColor='bg-slate-50'
                                                label='Deskripsi Perusahaan'
                                                placeholder='Masukkan deskripsi perusahaan'
                                                initialRow={3}
                                                maxLength={500}
                                                required />
                                            <Column className='w-full gap-1.5'>
                                                <label className='caption'>
                                                    Dokumen Legalitas Perusahaan <span className='text-red-500'>*</span>
                                                </label>
                                                <input
                                                    id='doc'
                                                    type='file'
                                                    className='hidden'
                                                    onChange={onChangeDocument}
                                                    accept='image/*,application/pdf' />
                                                <label htmlFor="doc" className={`w-full flex flex-row items-center gap-x-3 px-4 py-3 bg-slate-50 rounded-lg border-[1px] cursor-pointer ${errDocMitra && 'border-red-300'}`}>
                                                    <div className='p-1 bg-indigo-100 rounded-full border border-indigo-200'>
                                                        {docMitra ? <TbPencil className='h-5 w-5 translate-x-[0.2px] text-indigo-600' /> : <TbPlus className='h-5 w-5 translate-x-[0.2px] text-indigo-600' />}
                                                    </div>
                                                    {docMitra ? <span className='text-gray-800 line-clamp-1'>
                                                        {docMitra.name}
                                                    </span> : <span className='text-gray-400'>
                                                        Pilih dokumen legalitas
                                                    </span>}
                                                </label>
                                                {errDocMitra && <Overline className='text-red-500 font-medium px-4'>
                                                    {errDocMitra}
                                                </Overline>}
                                            </Column>
                                            <Row className='gap-x-5'>
                                                <OutlineInput
                                                    id='email'
                                                    type='email'
                                                    className='py-3'
                                                    inputWidth='w-full'
                                                    bgColor='bg-slate-50'
                                                    label='Email Perusahaan'
                                                    placeholder='Masukkan alamat email'
                                                    helperText='Contoh: info@mail.com'
                                                    maxLength={100}
                                                    required />
                                                <OutlineInput
                                                    id='phone'
                                                    type='text'
                                                    className='py-3'
                                                    inputWidth='w-full'
                                                    bgColor='bg-slate-50'
                                                    label='Telepon Perusahaan'
                                                    placeholder='Masukkan nomor telepon'
                                                    helperText='Contoh: (0274) 623310 atau 0811283923'
                                                    maxLength={20}
                                                    required />
                                            </Row>
                                            <Row className='gap-x-5'>
                                                <OutlineInput
                                                    id='pic'
                                                    type='text'
                                                    className='py-3'
                                                    inputWidth='w-full'
                                                    bgColor='bg-slate-50'
                                                    label='Nama Penanggung Jawab (PIC)'
                                                    placeholder='Masukkan nama penanggung jawab'
                                                    helperText='Contoh: Muhammad Omar Fikri'
                                                    maxLength={50}
                                                    required />
                                                <OutlineInput
                                                    id='nowa'
                                                    type='number'
                                                    className='py-3'
                                                    inputWidth='w-full'
                                                    bgColor='bg-slate-50'
                                                    label='Nomor Whatsapp'
                                                    placeholder='Masukkan nomor whatsapp'
                                                    helperText='Contoh: 0811283923'
                                                    maxLength={13}
                                                    required />
                                            </Row>
                                            <OutlineTextArea
                                                id='address'
                                                className='py-3'
                                                inputWidth='w-full'
                                                bgColor='bg-slate-50'
                                                label='Alamat Mitra'
                                                placeholder='Masukkan alamat'
                                                helperText='Jl. Siliwangi (Ringroad Utara) Jombor Sleman Yogyakarta'
                                                initialRow={3}
                                                maxLength={300}
                                                required />
                                            <ButtonHover className='w-fit'>
                                                <PrimaryButton disabled={loading} color='indigo' type='submit' className='w-full py-3 px-8 mt-5'>
                                                    {loading && <LoadingView />}
                                                    {loading ? 'Memproses pendaftaran...' : 'Daftar Sekarang'}
                                                </PrimaryButton>
                                            </ButtonHover>
                                        </Column>
                                    </Form>
                                </Column>
                            </Column>
                        </FullScHeight>
                    </Row>
                </TransitionPage>
            </FullScreen>
            <AlertDialog className='max-w-xs' show={showLargeDialog.show} onClose={() => setShowLargeDialog({ show: false, message: '-' })}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 pt-10 pb-8 gap-8 items-center'>
                        <Card className='p-1.5 rounded-2xl bg-orange-50/50 border-orange-200/50'>
                            <TbAlertTriangle className='h-14 w-14 stroke-1.5 text-orange-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Perhatian!
                            </Headline3>
                            <Text className='text-center'>
                                {showLargeDialog.message}
                            </Text>
                        </Column>
                    </Column>
                    <TextButton onClick={() => { setShowLargeDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                        Tutup
                    </TextButton>
                </Column>
            </AlertDialog>
        </>
    )
}

export default RegisterMitraPage