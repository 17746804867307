import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { BarChart, Card, Column, GridCol, Overline, Row, SelectView, Table, TableBody, TableCell, TableHead, TableRow, Text, Title } from '../../shared/ui';

const OvPenggunaLulusanIPT = ({ onDoneLoadData }) => {

    const [currentYear, setCurrentYear] = useState({ label: '2023', value: 1 })
    const listYear = [
        { label: '2023', value: 1 },
        { label: '2022', value: 2 },
        { label: '2021', value: 3 },
        { label: '2020', value: 4 },
        { label: '2019', value: 5 },
    ]

    const data2019 = [
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                {
                    name: 'Total',
                    data: [
                        13,
                        7,
                        5,
                        12,
                        9,
                        69,
                        32,
                        9,
                        42,
                        35,
                        7,
                        10,
                        6,
                        18,
                        4,
                        17,
                        15,
                        4,
                        37,
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S2 Manajemen',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Manajemen',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                13,
                7,
                5,
                12,
                9,
                69,
                32,
                9,
                42,
                35,
                7,
                10,
                6,
                18,
                4,
                17,
                15,
                4,
                37,
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S2 Manajemen',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Manajemen',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            head: [
                'Aspek Penilaian',
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 86.93,
                    val2: 12.12,
                    val3: 0.95,
                    val4: 0,
                },
                {
                    label: 'Keahlian pada bidang ilmu (kompetensi utama)',
                    val1: 70.05,
                    val2: 26.8,
                    val3: 3.15,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berbahasa asing',
                    val1: 75.63,
                    val2: 21.25,
                    val3: 2.15,
                    val4: 0.97,
                },
                {
                    label: 'Penggunaan teknologi informasi',
                    val1: 85.76,
                    val2: 14.24,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berkomunikasi',
                    val1: 83.17,
                    val2: 16.83,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kerjasama',
                    val1: 84.22,
                    val2: 14.88,
                    val3: 0.90,
                    val4: 0,
                },
                {
                    label: 'Pengembangan diri',
                    val1: 79.34,
                    val2: 19.78,
                    val3: 0.88,
                    val4: 0,
                },
                {
                    label: 'Kesiapan terjun di masyarakat',
                    val1: 80.78,
                    val2: 17.65,
                    val3: 1.57,
                    val4: 0,
                },
            ]
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                80.74,
                17.94,
                1.20,
                0.12,
            ],
            label: [
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                {
                    name: 'Sangat Baik',
                    data: [
                        86.93,
                        70.05,
                        75.63,
                        85.76,
                        83.17,
                        84.22,
                        79.34,
                        80.78
                    ]
                },
                {
                    name: 'Baik',
                    data: [
                        12.12,
                        26.8,
                        21.25,
                        14.24,
                        16.83,
                        14.88,
                        19.78,
                        17.65
                    ]
                },
                {
                    name: 'Cukup',
                    data: [
                        0.95,
                        3.15,
                        2.15,
                        0,
                        0,
                        0.90,
                        0.88,
                        1.57,
                    ]
                },
                {
                    name: 'Kurang',
                    data: [
                        0,
                        0,
                        0.97,
                        0,
                        0,
                        0,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                '[1]',
                '[2]',
                '[3]',
                '[4]',
                '[5]',
                '[6]',
                '[7]',
                '[8]',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
    ]

    const data2020 = [
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                {
                    name: 'Total',
                    data: [
                        15,
                        9,
                        6,
                        11,
                        10,
                        75,
                        30,
                        9,
                        39,
                        34,
                        7,
                        9,
                        6,
                        26,
                        4,
                        13,
                        9,
                        2,
                        49
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S2 Manajemen',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Manajemen',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                15,
                9,
                6,
                11,
                10,
                75,
                30,
                9,
                39,
                34,
                7,
                9,
                6,
                26,
                4,
                13,
                9,
                2,
                49
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S2 Manajemen',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Manajemen',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            head: [
                'Aspek Penilaian',
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 87.88,
                    val2: 12.12,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Keahlian pada bidang ilmu (kompetensi utama)',
                    val1: 71.35,
                    val2: 24.52,
                    val3: 4.13,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berbahasa asing',
                    val1: 77.41,
                    val2: 20.39,
                    val3: 2.20,
                    val4: 0,
                },
                {
                    label: 'Penggunaan teknologi informasi',
                    val1: 84.85,
                    val2: 15.15,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berkomunikasi',
                    val1: 85.12,
                    val2: 14.88,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kerjasama',
                    val1: 83.20,
                    val2: 15.43,
                    val3: 1.38,
                    val4: 0,
                },
                {
                    label: 'Pengembangan diri',
                    val1: 80.17,
                    val2: 19.83,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kesiapan terjun di masyarakat',
                    val1: 82.92,
                    val2: 16.25,
                    val3: 0.83,
                    val4: 0,
                },
            ]
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                81.61,
                17.32,
                1.07,
                0,
            ],
            label: [
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                {
                    name: 'Sangat Baik',
                    data: [
                        87.88,
                        71.35,
                        77.41,
                        84.85,
                        85.12,
                        83.20,
                        80.17,
                        82.92
                    ]
                },
                {
                    name: 'Baik',
                    data: [
                        12.12,
                        24.52,
                        20.39,
                        15.15,
                        14.88,
                        15.43,
                        19.83,
                        16.25
                    ]
                },
                {
                    name: 'Cukup',
                    data: [
                        0,
                        4.13,
                        2.20,
                        0,
                        0,
                        1.38,
                        0,
                        0.83,
                    ]
                },
                {
                    name: 'Kurang',
                    data: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                '[1]',
                '[2]',
                '[3]',
                '[4]',
                '[5]',
                '[6]',
                '[7]',
                '[8]',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
    ]

    const data2021 = [
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                {
                    name: 'Total',
                    data: [
                        13,
                        8,
                        6,
                        65,
                        29,
                        3,
                        22,
                        25,
                        2,
                        11,
                        6,
                        17,
                        6,
                        15,
                        18,
                        4,
                        83,
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Jepang',
                'S2 Manajemen',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Manajemen',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                13,
                8,
                6,
                65,
                29,
                3,
                22,
                25,
                2,
                11,
                6,
                17,
                6,
                15,
                18,
                4,
                83,
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Jepang',
                'S2 Manajemen',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Informatika',
                'S1 Manajemen',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            head: [
                'Aspek Penilaian',
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 89.19,
                    val2: 10.81,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Keahlian pada bidang ilmu (kompetensi utama)',
                    val1: 74.77,
                    val2: 20.72,
                    val3: 4.5,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berbahasa asing',
                    val1: 79.28,
                    val2: 18.62,
                    val3: 2.1,
                    val4: 0,
                },
                {
                    label: 'Penggunaan teknologi informasi',
                    val1: 86.79,
                    val2: 13.21,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berkomunikasi',
                    val1: 87.39,
                    val2: 12.61,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kerjasama',
                    val1: 85.59,
                    val2: 15.51,
                    val3: 0.9,
                    val4: 0,
                },
                {
                    label: 'Pengembangan diri',
                    val1: 82.88,
                    val2: 17.12,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kesiapan terjun di masyarakat',
                    val1: 85.29,
                    val2: 14.11,
                    val3: 0.6,
                    val4: 0,
                },
            ]
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                83.90,
                15.09,
                1.01,
                0,
            ],
            label: [
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                {
                    name: 'Sangat Baik',
                    data: [
                        89.19,
                        74.77,
                        79.28,
                        86.79,
                        87.39,
                        85.59,
                        82.88,
                        85.29
                    ]
                },
                {
                    name: 'Baik',
                    data: [
                        10.81,
                        20.72,
                        18.62,
                        13.21,
                        12.61,
                        13.51,
                        17.12,
                        14.11
                    ]
                },
                {
                    name: 'Cukup',
                    data: [
                        0,
                        4.5,
                        2.1,
                        0,
                        0,
                        0.9,
                        0,
                        0.6,
                    ]
                },
                {
                    name: 'Kurang',
                    data: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                '[1]',
                '[2]',
                '[3]',
                '[4]',
                '[5]',
                '[6]',
                '[7]',
                '[8]',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
    ]

    const data2022 = [
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                {
                    name: 'Total',
                    data: [
                        31,
                        26,
                        4,
                        18,
                        43,
                        43,
                        9,
                        13,
                        16,
                        44,
                        5,
                        21,
                        44,
                        26,
                        45,
                        45,
                        22,
                        39
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Sistem Informasi',
                'S3 Ilmu Manajemen',
                'S2 Manajemen',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Ilmu Hubungan Internasional',
                'S1 Ilmu Komunikasi',
                'S1 Informatika',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Perencanaan Wilayah dan Kota',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                31,
                26,
                4,
                18,
                43,
                43,
                9,
                13,
                16,
                44,
                5,
                21,
                44,
                26,
                45,
                45,
                22,
                39
            ],
            label: [
                'D3 Akuntansi',
                'D3 Sistem Informasi',
                'S3 Ilmu Manajemen',
                'S2 Manajemen',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Ilmu Hubungan Internasional',
                'S1 Ilmu Komunikasi',
                'S1 Informatika',
                'S1 Pendidikan Bahasa Inggris',
                'S1 Perencanaan Wilayah dan Kota',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            head: [
                'Aspek Penilaian',
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 91.30,
                    val2: 8.7,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Keahlian pada bidang ilmu (kompetensi utama)',
                    val1: 77.73,
                    val2: 19.84,
                    val3: 2.43,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berbahasa asing',
                    val1: 83.4,
                    val2: 15.59,
                    val3: 1.01,
                    val4: 0,
                },
                {
                    label: 'Penggunaan teknologi informasi',
                    val1: 88.46,
                    val2: 11.54,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berkomunikasi',
                    val1: 89.27,
                    val2: 10.73,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kerjasama',
                    val1: 88.46,
                    val2: 11.13,
                    val3: 0.4,
                    val4: 0,
                },
                {
                    label: 'Pengembangan diri',
                    val1: 85.43,
                    val2: 14.57,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kesiapan terjun di masyarakat',
                    val1: 87.65,
                    val2: 12.35,
                    val3: 0,
                    val4: 0,
                },
            ]
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                86.46,
                13.06,
                0.48,
                0,
            ],
            label: [
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                {
                    name: 'Sangat Baik',
                    data: [
                        91.30,
                        77.73,
                        73.4,
                        88.46,
                        89.27,
                        88.46,
                        85.43,
                        87.65
                    ]
                },
                {
                    name: 'Baik',
                    data: [
                        8.7,
                        19.84,
                        15.59,
                        11.54,
                        10.73,
                        11.13,
                        14.57,
                        12.35
                    ]
                },
                {
                    name: 'Cukup',
                    data: [
                        0,
                        2.43,
                        1.01,
                        0,
                        0,
                        0.4,
                        0,
                        0,
                    ]
                },
                {
                    name: 'Kurang',
                    data: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                '[1]',
                '[2]',
                '[3]',
                '[4]',
                '[5]',
                '[6]',
                '[7]',
                '[8]',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
    ]

    const data2023 = [
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                {
                    name: 'Total',
                    data: [
                        30,
                        5,
                        9,
                        21,
                        14,
                        7,
                        39,
                        48,
                        10,
                        9,
                        12,
                        47,
                        38,
                        12,
                        13,
                        31,
                        29,
                        34,
                        30,
                        18,
                        9,
                        48
                    ]
                },
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S2 Manajemen',
                'S2 Teknologi Informasi',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Ilmu Hubungan Internasional',
                'S1 Ilmu Komunikasi',
                'S1 Informatika',
                'S1 Manajemen',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Perencanaan Wilayah dan Kota',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
                '#EA906C',
                '#2155CD',
                '#FF6000',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
                'bg-[#EA906C]',
                'bg-[#2155CD]',
                'bg-[#FF6000]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Sebaran Responden Tracer Pengguna Lulusan',
            chart: [
                30,
                5,
                9,
                21,
                14,
                7,
                39,
                48,
                10,
                9,
                12,
                47,
                38,
                12,
                13,
                31,
                29,
                34,
                30,
                18,
                9,
                48
            ],
            label: [
                'D3 Akuntansi',
                'D3 Bahasa Inggris',
                'D3 Bahasa Jepang',
                'D3 Sistem Informasi',
                'S2 Manajemen',
                'S2 Teknologi Informasi',
                'S1 Akuntansi',
                'S1 Arsitektur',
                'S1 Bimbingan dan Konseling',
                'S1 Ilmu Hubungan Internasional',
                'S1 Ilmu Komunikasi',
                'S1 Informatika',
                'S1 Manajemen',
                'S1 Pendidikan Teknologi Informasi',
                'S1 Perencanaan Wilayah dan Kota',
                'S1 Psikologi',
                'S1 Sastra Inggris',
                'S1 Sistem Informasi',
                'S1 Teknik Elektro',
                'S1 Teknik Industri',
                'S1 Teknik Komputer',
                'S1 Teknik Sipil',
            ],
            chartcolor: [
                '#088395',
                '#7A9D54',
                '#F1C93B',
                '#6A5495',
                '#7900FF',
                '#3F51B5',
                '#6C3428',
                '#F86F03',
                '#526D82',
                '#5A8F7B',
                '#F44336',
                '#2196F3',
                '#99627A',
                '#FF55BB',
                '#9C0F48',
                '#AE4CCF',
                '#61677A',
                '#245953',
                '#5D3891',
                '#EA906C',
                '#2155CD',
                '#FF6000',
            ],
            legendcolor: [
                'bg-[#088395]',
                'bg-[#7A9D54]',
                'bg-[#F1C93B]',
                'bg-[#6A5495]',
                'bg-[#7900FF]',
                'bg-[#3F51B5]',
                'bg-[#6C3428]',
                'bg-[#F86F03]',
                'bg-[#526D82]',
                'bg-[#5A8F7B]',
                'bg-[#F44336]',
                'bg-[#2196F3]',
                'bg-[#99627A]',
                'bg-[#FF55BB]',
                'bg-[#9C0F48]',
                'bg-[#AE4CCF]',
                'bg-[#61677A]',
                'bg-[#245953]',
                'bg-[#5D3891]',
                'bg-[#EA906C]',
                'bg-[#2155CD]',
                'bg-[#FF6000]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            head: [
                'Aspek Penilaian',
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang'
            ],
            row: [
                {
                    label: 'Etika',
                    val1: 89.05,
                    val2: 10.95,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Keahlian pada bidang ilmu (kompetensi utama)',
                    val1: 90.02,
                    val2: 8.05,
                    val3: 1.93,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berbahasa asing',
                    val1: 82.33,
                    val2: 16.13,
                    val3: 1.54,
                    val4: 0,
                },
                {
                    label: 'Penggunaan teknologi informasi',
                    val1: 89.35,
                    val2: 10.65,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kemampuan berkomunikasi',
                    val1: 86.65,
                    val2: 11.75,
                    val3: 1.6,
                    val4: 0,
                },
                {
                    label: 'Kerjasama',
                    val1: 89.46,
                    val2: 10.54,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Pengembangan diri',
                    val1: 87.54,
                    val2: 12.46,
                    val3: 0,
                    val4: 0,
                },
                {
                    label: 'Kesiapan terjun di masyarakat',
                    val1: 90.65,
                    val2: 9.35,
                    val3: 0,
                    val4: 0,
                },
            ]
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                88.13,
                11.24,
                0.63,
                0,
            ],
            label: [
                'Sangat Baik',
                'Baik',
                'Cukup',
                'Kurang',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
        {
            name: 'Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden',
            chart: [
                {
                    name: 'Sangat Baik',
                    data: [
                        89.05,
                        90.02,
                        82.33,
                        89.35,
                        86.65,
                        89.46,
                        87.54,
                        90.65
                    ]
                },
                {
                    name: 'Baik',
                    data: [
                        10.95,
                        8.05,
                        16.13,
                        10.65,
                        11.75,
                        10.54,
                        12.46,
                        9.35
                    ]
                },
                {
                    name: 'Cukup',
                    data: [
                        0,
                        1.93,
                        1.54,
                        0,
                        0,
                        1.6,
                        0,
                        0,
                    ]
                },
                {
                    name: 'Kurang',
                    data: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                    ]
                },
            ],
            label: [
                '[1]',
                '[2]',
                '[3]',
                '[4]',
                '[5]',
                '[6]',
                '[7]',
                '[8]',
            ],
            chartcolor: [
                '#5A8F7B',
                '#2196F3',
                '#F86F03',
                '#F44336',
            ],
            legendcolor: [
                'bg-[#5A8F7B]',
                'bg-[#2196F3]',
                'bg-[#F86F03]',
                'bg-[#F44336]',
            ],
        },
    ]

    const [currentData, setCurrentData] = useState(data2023)

    const onChangeYear = (year) => {
        setCurrentYear(year)
        switch (year.value) {
            case 1:
                setCurrentData(data2023)
                break;
            case 2:
                setCurrentData(data2022)
                break;
            case 3:
                setCurrentData(data2021)
                break;
            case 4:
                setCurrentData(data2020)
                break;
            case 5:
                setCurrentData(data2019)
                break;
            default:
                setCurrentData(data2023)
                break;
        }
    }

    const setupDataPenggunaLulusan = () => {
        setTimeout(() => {
            onDoneLoadData(true)
        }, 500)
    }

    const exportTracerStudi = () => {
        FileSaver.saveAs(
            `https://files.uty.ac.id/sialumni/export/rekap_tracer_kepuasan_pengguna_lulusan_${currentYear.label}.xlsx`,
        );
    }

    useEffect(() => {
        setupDataPenggunaLulusan()
    }, [])

    return (
        <Column className='gap-5'>
            <Row className='justify-between gap-5'>
                <SelectView width='w-full sm:w-44' initial={currentYear} options={listYear} onChangeOptions={onChangeYear} zindex='z-10' />
                {/* <ButtonHover className='my-auto'>
                    <PrimaryButton onClick={exportTracerStudi} color='indigo' className='py-2 pr-5' leadingIcon={<TbDownload className='h-5 w-5 my-auto' />}>
                        Unduh
                    </PrimaryButton>
                </ButtonHover> */}
            </Row>
            <GridCol className='gap-8'>
                <Card className='w-full h-full p-0 col-span-2'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[0].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='pr-2 pl-1 pt-1'>
                            <BarChart series={currentData[0].chart} height={300} listColors={currentData[0].chartcolor} />
                        </div>
                        <Row className='px-5 pb-5 gap-x-3 gap-y-2 flex-wrap justify-center'>
                            {currentData[0].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[0].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </Row>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0 col-span-2'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[1].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <div className='my-auto pb-4'>
                            <Chart
                                height={400}
                                type="donut"
                                series={currentData[1].chart}
                                options={{
                                    chart: {
                                        width: 720,
                                        height: 720,
                                        type: 'donut',
                                        fontFamily: 'inter',
                                    },
                                    labels: currentData[1].label,
                                    dataLabels: {
                                        enabled: true,
                                        fontSize: '12px',
                                    },
                                    colors: currentData[1].chartcolor,
                                    legend: {
                                        show: false,
                                        position: 'bottom',
                                        offsetX: 0,
                                    }
                                }} />
                        </div>
                        <div className='gap-x-3 gap-y-1 flex flex-row flex-wrap justify-center pb-5 px-4'>
                            {currentData[1].label.map((label, idx) => {
                                return (
                                    <Row key={idx} className='w-fit gap-1.5'>
                                        <Card className={`${currentData[1].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                        <Overline className='my-auto text-gray-800 font-normal'>
                                            {label}
                                        </Overline>
                                    </Row>
                                )
                            })}
                        </div>
                    </Column>
                </Card>
                <Card className='w-full h-full p-0 col-span-2'>
                    <Column>
                        <Column className='p-5'>
                            <Title>
                                {currentData[2].name}
                            </Title>
                            <Text>
                                {currentYear.value !== 0 && 'Tahun '} {currentYear.label}
                            </Text>
                        </Column>
                        <GridCol>
                            <Column>
                                <div className='my-auto pb-4'>
                                    <Chart
                                        height={300}
                                        type="donut"
                                        series={currentData[3].chart}
                                        options={{
                                            chart: {
                                                width: 720,
                                                height: 720,
                                                type: 'donut',
                                                fontFamily: 'inter',
                                            },
                                            labels: currentData[3].label,
                                            dataLabels: {
                                                enabled: true,
                                                fontSize: '12px',
                                            },
                                            colors: currentData[3].chartcolor,
                                            legend: {
                                                show: false,
                                                position: 'bottom',
                                                offsetX: 0,
                                            }
                                        }} />
                                </div>
                                <div className='gap-x-3 gap-y-1 flex flex-row flex-wrap justify-center pb-5 px-4'>
                                    {currentData[3].label.map((label, idx) => {
                                        return (
                                            <Row key={idx} className='w-fit gap-1.5'>
                                                <Card className={`${currentData[3].legendcolor[idx]} border-none p-1.5 rounded-full my-auto`} />
                                                <Overline className='my-auto text-gray-800 font-normal'>
                                                    {label}
                                                </Overline>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </Column>
                            <Column>
                                <div className='my-auto pb-4'>
                                    <Chart
                                        height={300}
                                        type="radar"
                                        series={currentData[4].chart}
                                        options={{
                                            chart: {
                                                width: 720,
                                                height: 720,
                                                type: 'radar',
                                                toolbar: {
                                                    show: false,
                                                },
                                                fontFamily: 'inter',
                                            },
                                            labels: currentData[4].label,
                                            dataLabels: {
                                                enabled: true,
                                                fontSize: '12px',
                                            },
                                            colors: currentData[4].chartcolor,
                                        }} />
                                </div>
                            </Column>
                        </GridCol>
                        <div className='p-5'>
                            <Table className='border-[1px] border-gray-200' showPaginationAndSearch={false}>
                                <TableHead>
                                    <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                        <TableCell className='font-semibold font-manrope'>
                                            No
                                        </TableCell>
                                        {currentData[2].head.map((head, idx) => {
                                            return (
                                                <TableCell key={idx} className='font-semibold font-manrope'>
                                                    {head}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody className='divide-y-[1px] divide-gray-200'>
                                    {currentData[2].row.map((row, idx) => {
                                        return (
                                            <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50`}>
                                                <TableCell>
                                                    {(idx + 1)}
                                                </TableCell>
                                                <TableCell>
                                                    {row.label}
                                                </TableCell>
                                                <TableCell>
                                                    {row.val1}%
                                                </TableCell>
                                                <TableCell>
                                                    {row.val2}%
                                                </TableCell>
                                                <TableCell>
                                                    {row.val3}%
                                                </TableCell>
                                                <TableCell>
                                                    {row.val4}%
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </Column>
                </Card>
            </GridCol>
        </Column>
    )
}

export default OvPenggunaLulusanIPT
