import React, { useState } from 'react'
import TablePlotingKonselor from '../../components/tabels/ploting_konselor'
import { Column, Headline1 } from '../../shared/ui'

const AdmKonselorPage = () => {

    const [showProgress, setShowProgress] = useState(true)

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    return (
        <Column className='gap-5'>
            <Headline1>
                Konselor Konsultasi
            </Headline1>
            <TablePlotingKonselor onDoneLoadData={onDoneLoadData} />
        </Column>
    )
}

export default AdmKonselorPage
