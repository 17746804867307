import React, { useEffect, useState } from "react";
import { TbAlertHexagon, TbArrowRight } from "react-icons/tb";
import {
  TableCell,
  TableRow,
} from "../../../../../components/interfaces/table";
import { BodyText } from "../../../../../components/interfaces/text";
import { getFullNameUser } from "../../../../../controllers/apicontrollers";
import {
  ButtonHover,
  IconButton,
  ShimmerAnimation,
  ShimmerItem,
  TagView,
} from "../../../../../shared/ui";

function RowTableRejectedComments({
  idx,
  comment,
  page = 1,
  limit = 10,
  handleClick,
}) {
  return (
    <TableRow striped onClick={() => handleClick(comment)}>
      <TableCell>{limit * (page - 1) + (idx + 1)}</TableCell>
      <TableCell>{comment.nama}</TableCell>
      <TableCell>
        <BodyText className={"line-clamp-2"}>{comment.title}</BodyText>
      </TableCell>
      <TableCell>{comment.comments.length} Komentar</TableCell>
      <TableCell>
        <TagView
          className={"mx-auto"}
          leadingIcon={<TbAlertHexagon className={"h-4 w-4"} />}
          label="Ditolak"
          color="red"
        />
      </TableCell>
      <TableCell>
        <ButtonHover className={"mx-auto"}>
          <IconButton
            bordered
            color="indigo"
            className={"p-1.5"}
            onClick={() => handleClick(comment)}
          >
            <TbArrowRight className={"h-5 w-5"} />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  );
}

export default RowTableRejectedComments;
