import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { TbInfoCircle, TbMessageShare, TbMessageX, TbTrash, TbX } from 'react-icons/tb';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import ListChatConsultation from '../../../components/list/list_chat_consultation';
import { deleteChatConsultationController, finishConsultationController, getChatConsultationController, saveChatConsultationController } from '../../../controllers/global_controllers';
import { AlertDialog, ButtonHover, Caption, Column, DotSeparated, Form, Headline3, IconButton, LoadingDialog, LoadingView, OutlineTextArea, Overline, PrimaryButton, Row, TertiaryButton, Text, TextButton } from '../../../shared/ui';
import { almConsultationRoute } from '../../../shared/variable';

function AlmChatConsultationPage() {

  const result = useLoaderData()
  const location = useLocation()
  const navigateTo = useNavigate()

  const replyRef = useRef()

  const [listChat, setListChat] = useState([])
  const [replyChat, setReplyChat] = useState(null)
  const [deleteChat, setDeleteChat] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [showFinishDialog, setShowFinishDialog] = useState(false)
  const [loadingSend, setLoadingSend] = useState(false)
  const [loadingFinish, setLoadingFinish] = useState(false)

  const getConsultationFromLoader = () => {
    if (result.status) {
      const chatTemp = [...result.data]
      chatTemp.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
      setListChat(chatTemp)
    }
  }

  const onReplyChat = (chat) => {
    setReplyChat(chat)
    replyRef.current?.focus()
  }

  const onDeleteChat = (chat) => {
    setDeleteChat(chat)
    setShowDialog(true)
  }

  const onSubmitForm = (event) => {
    event.preventDefault();
    const reply = event.target.reply.value;
    if (reply.length > 0) {
      saveChatConsultation(reply)
    }
  }

  const enterKeyPressed = (event) => {
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault()
      const reply = event.target.value
      if (reply.length > 0) {
        saveChatConsultation(reply)
      } else {
        toast.error('Tulis balasan sebelum mengirim!')
      }
    }
  }

  const saveChatConsultation = async (reply) => {
    setLoadingSend(true)
    const result = await saveChatConsultationController(location.state.slug, reply, replyChat !== null ? replyChat.id_chat_konsultasi : null)
    if (result.status) {
      setListChat([...listChat, {
        id_chat_konsultasi: result.data.id_chat_konsultasi,
        id_konsultasi: location.state.id_konsultasi,
        chat: result.data.chat,
        from_who: result.data.from_who,
        replay_to: result.data.replay_to,
        created_at: result.data.created_at,
        updated_at: result.data.created_at
      }])
      replyRef.current.value = ''
      if (replyChat !== null) {
        setReplyChat(null)
      }
      setTimeout(() => {
        setLoadingSend(false)
        toast.success(result.message)
      }, 200)
    } else {
      toast.error(result.message)
    }
  }

  const deleteChatConsultation = async () => {
    setShowDialog(false)
    const result = await deleteChatConsultationController(deleteChat.id_chat_konsultasi)
    if (result.status) {
      const filterChat = listChat.filter((chat) => chat.id_chat_konsultasi !== deleteChat.id_chat_konsultasi)
      setListChat(filterChat)
      toast.success(result.message)
    } else {
      toast.error(result.message)
    }
  }

  const finishConsultation = async () => {
    setShowFinishDialog(false)
    setLoadingFinish(true)
    const result = await finishConsultationController(location.state.slug)
    if (result.status) {
      setTimeout(() => {
        setLoadingFinish(false)
        toast.success(result.message)
        navigateTo(almConsultationRoute, { replace: true })
      }, 200)
    } else {
      setTimeout(() => {
        setLoadingFinish(false)
        toast.error(result.message)
      }, 200)
    }
  }

  useEffect(() => {
    getConsultationFromLoader()
  }, [])

  return (
    <>
      <Column className={'w-full max-w-4xl mx-auto items-center gap-y-5 sm:gap-y-8 sm:px-14'}>
        <Column className={'p-4 gap-y-6 bg-white border-y border-gray-200 transition-all duration-200 group sm:p-8 sm:shadow-sm lg:border-x lg:hover:shadow-md'} >
          <Overline className={'font-medium'}>
            {moment(location.state.created_at).format('DD/MM/YYYY HH.mm')} WIB
          </Overline>
          <Column className={'gap-y-2'}>
            <Headline3 className={'hover:text-indigo-600'}>
              {location.state.subjek}
            </Headline3>
            <Row>
              <Caption className={'font-medium'}>
                {location.state.topik}
              </Caption>
              <DotSeparated />
              <Caption className={'font-medium text-blue-600'}>
                {(location.state.publik === 0 || !location.state.publik) ? 'Private' : 'Public'}
              </Caption>
            </Row>
          </Column>
          <Text className={'whitespace-pre-line'}>
            {location.state.permasalahan}
          </Text>
          {
            listChat.length > 0 &&
            <Column className={'border border-gray-200 rounded-lg divide-y divide-gray-200'}>
              <ListChatConsultation
                action={10}
                chats={listChat}
                konName={location.state.nama_konselor}
                onReply={onReplyChat}
                onDelete={onDeleteChat} />
            </Column>
          }
          {location.state.status === 'ONGOING' ?
            <Form onSubmit={onSubmitForm}>
              <Column className={'gap-y-4'}>
                <Column className={'divide-y divide-gray-200 bg-gray-50 rounded-lg border border-gray-200 overflow-hidden'}>
                  {replyChat !== null && <Row className={'gap-x-4 pl-4 pr-3 py-4 bg-white'}>
                    <Column className={'px-4 border-l-[3px] gap-y-1 rounded-l-sm border-l-indigo-500'}>
                      <Overline className={'font-medium line-clamp-1 text-indigo-600'}>
                        Membalas {replyChat.from_who === 4 ? 'Konselor' : 'Pesan Saya'}
                      </Overline>
                      <Overline className={'line-clamp-1 whitespace-pre-line'}>
                        {replyChat.chat}
                      </Overline>
                    </Column>
                    <IconButton
                      className={'my-auto'}
                      onClick={() => setReplyChat(null)}>
                      <TbX className={'h-4 w-4 text-red-600'} />
                    </IconButton>
                  </Row>}
                  <OutlineTextArea
                    id='reply'
                    refs={replyRef}
                    inputWidth={'w-full'}
                    bgColor='bg-gray-50'
                    placeholder={'Tulis balasan'}
                    className={'border-none rounded-none focus:ring-0 focus:ring-transparent'}
                    initialRow={3}
                    maxRows={8}
                    maxLength={1000}
                    onKeyDown={enterKeyPressed}
                    disabled={loadingSend}
                    required />
                </Column>
                <ButtonHover>
                  <PrimaryButton
                    pills
                    type='submit'
                    color='indigo'
                    className={'pl-5 pr-6'}
                    disabled={loadingSend}
                    leadingIcon={
                      loadingSend ?
                        <LoadingView /> :
                        <TbMessageShare className={'h-5 w-5 my-auto'} />
                    }>
                    {loadingSend ? 'Mengirim...' : 'Balas'}
                  </PrimaryButton>
                </ButtonHover>
              </Column>
            </Form> :
            <Row className={`px-4 py-2 gap-x-2 border border-gray-200 rounded-md justify-center items-center ${(location.state.status === 'PENDING' && location.state.acc_bidal !== 'REJECTED') ? 'text-orange-600' : 'text-red-600'}`}>
              <TbInfoCircle className={'h-5 w-5'} />
              <Text className={`font-medium ${(location.state.status === 'PENDING' && location.state.acc_bidal !== 'REJECTED') ? 'text-orange-600' : 'text-red-600'}`}>
                {location.state.status === 'PENDING' ?
                  (location.state.acc_bidal === 'PROCESSED' ? 'Menunggu Verifikasi Admin' : location.state.acc_bidal === 'REJECTED' ? 'Pengajuan Konsultasi ditolak' : 'Menunggu Jawaban Konselor') :
                  'Sesi Konsultasi sudah berakhir'}
              </Text>
            </Row>
          }
        </Column>
        {
          location.state.status === 'ONGOING' && <TextButton
            color='red'
            leadingIcon={
              <TbMessageX className={'h-5 w-5'} />
            }
            onClick={() => setShowFinishDialog(true)}>
            Akhiri Konsultasi
          </TextButton>
        }
      </Column>
      <AlertDialog
        show={showDialog}
        className={'max-w-md'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3>
              Hapus Pesan
            </Headline3>
            <Text>
              Pesan akan dihapus dari sesi konsultasi dan mungkin konselor sudah melihat sebelumnya
            </Text>
            <Column className={'px-4 py-2 border-l-[3px] gap-y-1 mt-2 rounded-l-sm border-l-gray-500 bg-gray-50 rounded-md'}>
              <Caption className={'font-medium text-gray-800'}>
                Hapus Pesan
              </Caption>
              {deleteChat !== null &&
                <Caption className={'line-clamp-2 whitespace-pre-line'}>
                  {deleteChat.chat}
                </Caption>
              }
            </Column>
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='red'
                className='pl-5 pr-6'
                leadingIcon={
                  <TbTrash className='h-5 w-5 my-auto' />
                }
                onClick={deleteChatConsultation}>
                Hapus
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <AlertDialog
        show={showFinishDialog}
        className={'max-w-md'}
        onClose={() => { setShowFinishDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3>
              Akhiri Konsultasi
            </Headline3>
            <Text>
              Apakah Anda yakin ingin mengakhiri sesi konsultasi? Setelah sesi konsultasi diakhiri Anda tidak dapat berkomunikasi kembali dengan konselor pada sesi saat ini.
            </Text>
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowFinishDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='red'
                className='pl-5 pr-6'
                leadingIcon={
                  <TbMessageX className='h-5 w-5 my-auto' />
                }
                onClick={finishConsultation}>
                Akhiri
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loadingFinish}
        message='Mengakhiri sesi...' />
    </>
  )
}

export default AlmChatConsultationPage

export const getChatConsultation = async ({ params }) => {
  const result = await getChatConsultationController(params.id)
  return result;
}