import React, { useState } from 'react'
import { TbX } from 'react-icons/tb'
import { AlertDialog, ButtonHover, Column, Headline3, Row, ShareFBButton, ShareTelegramButton, ShareWAButton, ShareXButton, TertiaryButton, Text } from '../../shared/ui'
import ItemEvent from './item/item_event'

function ListEvent({ events }) {

  const [currentEvent, setCurrentEvent] = useState(null)
  const [showDialogShare, setShowDialogShare] = useState(false)

  const onShare = (event) => {
    setCurrentEvent(event)
    setShowDialogShare(true)
  }

  return (
    <>
      {
        events.map((event, idx) => {
          return (
            <ItemEvent
              key={idx}
              event={event}
              onShare={onShare} />
          )
        })
      }
      <AlertDialog
        className={'max-w-lg'}
        show={showDialogShare}
        onClose={() => setShowDialogShare(false)}>
        <Column className={'p-5 sm:p-8 gap-y-5'}>
          <Column className={'gap-y-2'}>
            <Headline3>
              Bagikan
            </Headline3>
            {currentEvent !== null && <Text>
              Bagikan event
              <span className={'font-medium ml-1'}>
                {currentEvent.title}
              </span>.
            </Text>}
          </Column>
          {currentEvent !== null && <Row className={'gap-x-3 pb-1'}>
            <ShareFBButton location={`${window.location.href}/${currentEvent.slug}`} />
            <ShareXButton location={`${window.location.href}/${currentEvent.slug}`} />
            <ShareWAButton location={`${window.location.href}/${currentEvent.slug}`} />
            <ShareTelegramButton location={`${window.location.href}/${currentEvent.slug}`} />
          </Row>}
          <Row className={'justify-end'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialogShare(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
    </>
  )
}

export default ListEvent