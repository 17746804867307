import moment from 'moment'
import { TbArrowUpRight } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { ButtonHover, Caption, Column, Headline3, HorizontalDivider, Image, TertiaryButton } from '../../../shared/ui'
import { recognitionRoute } from '../../../shared/variable'

const ItemRecognitionLanding = ({ recognition, idx, length }) => {

    const navigateTo = useNavigate()

    return (
        <Column className='h-full'>
            <Column className='h-full gap-y-5 px-4 py-8 sm:px-0 sm:py-0'>
                {recognition.images.length > 0 ? <Image src={recognition.images[0].image_name} className='w-full bg-white aspect-square rounded-2xl border-[1px]' imgClasName='w-full aspect-square' hoverScale={false} /> : null}
                <Column className='gap-y-3'>
                    <Headline3 onClick={() => navigateTo(`${recognitionRoute}/${recognition.slug}`)} className='line-clamp-2 transition-all cursor-pointer hover:text-indigo-600'>
                        {recognition.title}
                    </Headline3>
                    <Caption>
                        {moment(recognition.created_at).format('DD MMMM YYYY HH.mm')}
                    </Caption>
                    <div className='text-gray-800 font-inter text-base line-clamp-3'
                        dangerouslySetInnerHTML={{ __html: recognition.body.split('\n')[0] }} />
                </Column>
                <ButtonHover className='mt-auto'>
                    <TertiaryButton onClick={() => navigateTo(`${recognitionRoute}/${recognition.slug}`)} color='indigo' className='pr-5' leadingIcon={<TbArrowUpRight className='h-5 w-5 my-auto' />}>
                        Selengkapnya
                    </TertiaryButton>
                </ButtonHover>
            </Column>
            <HorizontalDivider className={`sm:hidden`} />
        </Column>
    )
}

export default ItemRecognitionLanding
