import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { Column, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { SearchInput, SelectInput } from '../../../components/interfaces/input'
import { BodyText, Headline2Text } from '../../../components/interfaces/text'
import { Pagination, PaginationOption, useDebounce } from '../../../components/interfaces/utility'
import { getSaranTracerController } from '../../../controllers/admin_controllers'
import { getFilterTracerController } from '../../../controllers/global_controllers'
import TablesaranTracer from './tables/table_saran_tracer'

function AdmSaranTracerPage() {

  const result = useLoaderData()

  const [currentYear, setCurrentYear] = useState({ label: 'Semua Tahun', value: null })
  const [currentProdi, setCurrentProdi] = useState({ label: 'Semua Program Studi', value: null })

  const [filterTahun, setFilterTahun] = useState([])
  const [filterProdi, setFilterProdi] = useState([])

  const [listSaran, setListSaran] = useState([])
  const [limitRow, setLimitRow] = useState(10)
  const [totalRow, setTotalRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [currentSort, setCurrentSort] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [stsGetLoader, setStsGetLoader] = useState(false)

  const debounceSearch = useDebounce({ value: searchQuery, delay: 1000 })

  const getSaranTracerFromLoader = () => {
    if (result.filter.status === 'fulfilled') {
      const res = result.filter.value
      if (res.status) {
        const tahun = res.data.tahun
        const prodi = res.data.prodi

        const listTahun = []
        const listProdi = []

        const currentYear = new Date().getFullYear()
        const haveCurrentYear = false;
        tahun.map((tahun) => {
          const t = {
            label: tahun,
            value: tahun
          }
          listTahun.unshift(t)
          if (tahun === currentYear) {
            haveCurrentYear = true;
          }
        })
        if (!haveCurrentYear) {
          listTahun.unshift({ label: currentYear, value: currentYear })
        }
        listTahun.unshift({ label: 'Semua Tahun', value: null })

        prodi.map((prodi) => {
          const p = {
            label: prodi.label,
            value: prodi.kode_prodi
          }
          listProdi.unshift(p)
        })
        listProdi.unshift({ label: 'Semua Program Studi', value: null })

        setFilterTahun(listTahun)
        setFilterProdi(listProdi)
      }
    }
    if (result.saran.status === 'fulfilled') {
      const res = result.saran.value
      if (res.status) {
        setListSaran(res.data)
        setTotalRow(res.totalRows)
        setTotalPage(res.totalPage)
      }
    }
    setStsGetLoader(true)
  }

  const regetSaranTarcer = async () => {
    const result = await getSaranTracerController({
      search: searchQuery,
      page: currentPage,
      limit: limitRow,
      order: currentSort !== null ? currentSort.key : null,
      asc: currentSort !== null ? currentSort.type : null,
      tahun: currentYear.value,
      prodi: currentProdi.value,
    })
    if (result.status) {
      setListSaran(result.data)
      setTotalRow(result.totalRows)
      setTotalPage(result.totalPage)
    }
  }

  const onHandlePaginationOption = (value) => {
    setLimitRow(value)
    setCurrentPage(1)
  }

  const onHandleSearch = (event) => {
    event.preventDefault()
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null)
    setCurrentPage(1)
    if (currentSort !== null) {
      setCurrentSort(null)
    }
  }

  const onChangeYear = (value) => {
    setCurrentYear(value)
  }

  const onChangeProdi = (value) => {
    setCurrentProdi(value)
  }

  const onHandlePagination = (value) => {
    setCurrentPage(value)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
  }

  useEffect(() => {
    getSaranTracerFromLoader()
  }, [])

  useEffect(() => {
    if (stsGetLoader) {
      regetSaranTarcer()
    }
  }, [debounceSearch, limitRow, currentPage, currentSort, currentYear, currentProdi])

  return (
    <Column className={'p-4 gap-y-5 sm:p-8'}>
      <Column className={'gap-y-2 flex-1'}>
        <Headline2Text className={'font-semibold'}>
          Data Saran
        </Headline2Text>
        <BodyText>
          Data saran/masukkan alumni dari {currentProdi.value === null ? 'Semua Program Studi' : `Prodi ${currentProdi.label}`} {currentYear.value === null ? 'di Semua Tahun' : `pada Tahun ${currentYear.value}`}.
        </BodyText>
      </Column>
      <Card className={'w-full'}>
        <Column className={'gap-y-5'}>
          <Row className='w-full h-[2.64rem] gap-3'>
            <div>
              <SelectInput
                width='w-full sm:w-80'
                initial={currentProdi}
                options={filterProdi}
                onChange={onChangeProdi}
                zindex='z-20' />
            </div>
            <div>
              <SelectInput
                width='w-full sm:w-40'
                initial={currentYear}
                options={filterTahun}
                onChange={onChangeYear}
                zindex='z-10' />
            </div>
          </Row>
          <Column className='gap-x-4 gap-y-2 md:flex-row md:justify-between'>
            <PaginationOption
              initial={limitRow}
              onChange={onHandlePaginationOption} />
            <SearchInput
              defaultValue={searchQuery}
              placeholder='Cari berita'
              onChange={onHandleSearch} />
          </Column>
          <TablesaranTracer
            limit={limitRow}
            page={currentPage}
            listSaran={listSaran}
            onShort={onHandleSort} />
          {listSaran.length > 0 && <Pagination
            limitRow={limitRow}
            totalRow={totalRow}
            totalPage={totalPage}
            triger={currentSort !== null && currentSort.key}
            onChange={onHandlePagination} />}
        </Column>
      </Card>
    </Column>
  )
}

export default AdmSaranTracerPage

export const getAdmSaranTracer = async ({ search = null, page = 1, limit = 10, order = null, asc = null }) => {
  const props = {
    search: search,
    page: page,
    limit: limit,
    order: order,
    asc: asc,
    tahun: null,
    prodi: null
  }
  const [filter, saran] = await Promise.allSettled([
    getFilterTracerController(),
    getSaranTracerController(props)
  ])
  return {
    'filter': filter,
    'saran': saran,
  }
}