import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Column } from '../../shared/ui';
import SAdmOvRecognition from '../shimmer/sadm_ov_recognition';
import SChartOvRecognition from '../shimmer/sadm_chart_ov_recognition';
import STable from '../shimmer/stable';

const COvRecognition = lazy(() => import('../cards/cov_recognition'))
const ChartOvRecognition = lazy(() => import('../cards/cchart_overview_recognition'))
const TableRekapOvRecognition = lazy(() => import('../wtable_rekap_ov_recognition'))

const OvRecognition = ({ onDoneLoadData, action = 10 }) => {

    const [dataOverview, setDataOverview] = useState(false)
    const [dataChartOverview, setDataChartOverview] = useState(false)
    const [dataRekapOverview, setDataRekapOverview] = useState(action === 10 ? false : true)

    const statusLoadData = (value) => {
        if (value === 10) {
            setDataOverview(true)
        } else if (value === 20) {
            setDataChartOverview(true)
        } else {
            setDataRekapOverview(true)
        }
    }

    useEffect(() => {
        if (dataOverview && dataChartOverview && dataRekapOverview) {
            onDoneLoadData(true)
        }
    }, [dataOverview, dataChartOverview, dataRekapOverview])

    return (
        <Column className='gap-5'>
            <Suspense fallback={<SAdmOvRecognition />}>
                <COvRecognition onDoneLoadData={statusLoadData} action={action} />
            </Suspense>
            <Suspense fallback={<SChartOvRecognition />}>
                <ChartOvRecognition onDoneLoadData={statusLoadData} action={action} />
            </Suspense>
            {action === 10 && <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-72', 'w-32', 'w-32', 'w-32', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end', 'justify-end']} />}>
                <TableRekapOvRecognition onDoneLoadData={statusLoadData} />
            </Suspense>}
        </Column>
    )
}

export default OvRecognition
