import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbPlus, TbSend, TbX } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import { Column, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { SearchInput, SelectInput } from '../../../components/interfaces/input'
import { BodyText, CaptionText, Headline2Text, Headline3Text, LabelText } from '../../../components/interfaces/text'
import { Pagination, PaginationOption, useDebounce } from '../../../components/interfaces/utility'
import { getCounselorConsultationController, getFilterConsultationController, getTopicConsultationController, saveTopicConsultationController } from '../../../controllers/admin_controllers'
import { AlertDialog, ButtonHover, IconButton, LoadingDialog, PrimaryButton, TertiaryButton } from '../../../shared/ui'
import ItemCounselor from './items/item_counselor'
import TableTopicConsultation from './tables/table_topic_consultation'

function AdmTopicConsultationPage() {

  const result = useLoaderData()

  const [listTopic, setListTopic] = useState([])
  const [listFilter, setListFilter] = useState([])
  const [listCounselor, setListCounselor] = useState([])
  const [listCounselorTemp, setListCounselorTemp] = useState([])
  const [limitRow, setLimitRow] = useState(10)
  const [totalRow, setTotalRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [currentSort, setCurrentSort] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [searchQuery2, setSearchQuery2] = useState(null)
  const [stsGetLoader, setStsGetLoader] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const debounceSearch = useDebounce({ value: searchQuery, delay: 1000 })
  const debounceSearch2 = useDebounce({ value: searchQuery2, delay: 1000 })

  const [defaultCategory, setDefaultCategory] = useState({ label: 'Pilih kategori', value: null })
  const [initCategory, setInitCategory] = useState({ label: 'Pilih kategori', value: null })
  const [optionCategory, setOptionCategory] = useState([])
  const [defaultTopic, setDefaultTopic] = useState({ label: 'Pilih topik', value: null })
  const [initTopic, setInitTopic] = useState({ label: 'Pilih topik', value: null })
  const [optionTopic, setOptionTopic] = useState([])

  const getTopicConsultationFromLoader = () => {
    if (result.topic.status === 'fulfilled') {
      const topic = result.topic.value
      if (topic.status) {
        setListTopic(topic.data)
        setTotalRow(topic.totalRows)
        setTotalPage(topic.totalPage)
      }
    }
    if (result.category.status === 'fulfilled') {
      const category = result.category.value
      if (category.status) {
        const listTemp = [...category.data]
        const listFilterTemp = listTemp.filter((filter) => filter.topik_konsultasi.length > 0)
        if (listFilterTemp.length > 0) {
          listFilterTemp.sort((a, b) => a.kategori_konsultasi > b.kategori_konsultasi ? 1 : -1)

          const optionCategoryTemp = []
          const optionTopicTemp = []

          listFilterTemp.map((filter, idx) => {
            const newCategory = {
              label: filter.kategori_konsultasi,
              value: filter.id_kategori_konsultasi,
            }
            optionCategoryTemp.push(newCategory)
            if (idx === 0) {
              const listTopic = [...filter.topik_konsultasi]
              listTopic.sort((a, b) => a.topik > b.topik ? 1 : -1)
              listTopic.map((topic, idxx) => {
                const newTopic = {
                  label: topic.topik,
                  value: topic.id_topik,
                }
                optionTopicTemp.push(newTopic)
                if (idxx === 0) {
                  setDefaultTopic(newTopic)
                  setInitTopic(newTopic)
                }
              })
              setDefaultCategory(newCategory)
              setInitCategory(newCategory)
            }
          })

          setOptionCategory(optionCategoryTemp)
          setOptionTopic(optionTopicTemp)
        }
        setListFilter(listFilterTemp)
      }
    }
    if (result.counselor.status === 'fulfilled') {
      const counselor = result.counselor.value
      if (counselor.status) {
        setListCounselor(counselor.data)
      }
    }
    setStsGetLoader(true)
  }

  const regetTopicConsultation = async () => {
    const props = {
      search: searchQuery,
      limit: limitRow,
      page: currentPage,
      order: currentSort !== null ? currentSort.key : null,
      asc: currentSort !== null ? currentSort.type : null
    }
    const result = await getTopicConsultationController(props)
    if (result.status) {
      setListTopic(result.data)
      setTotalRow(result.totalRows)
      setTotalPage(result.totalPage)
    }
  }

  const regetCounselorConsultation = async () => {
    const props = {
      search: searchQuery2,
      limit: 10,
      page: 1,
      order: null,
      asc: null
    }
    const result = await getCounselorConsultationController(props)
    if (result.status) {
      setListCounselor(result.data)
    }
  }

  const onHandlePaginationOption = (value) => {
    setLimitRow(value)
    setCurrentPage(1)
  }

  const onHandleSearch = (event) => {
    event.preventDefault()
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null)
    setCurrentPage(1)
    if (currentSort !== null) {
      setCurrentSort(null)
    }
  }

  const onHandlePagination = (value) => {
    setCurrentPage(value)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
  }

  const onAddPlotCounselor = () => {
    const opsiTopicTemp = []
    const listTopic = [...listFilter.find((filter) => filter.id_kategori_konsultasi === defaultCategory.value).topik_konsultasi]
    listTopic.sort((a, b) => a.topik > b.topik ? 1 : -1)
    listTopic.map((topic) => {
      const newTopic = {
        label: topic.topik,
        value: topic.id_topik,
      }
      opsiTopicTemp.push(newTopic)
    })
    setOptionTopic(opsiTopicTemp)
    setInitCategory(defaultCategory)
    setInitTopic(defaultTopic)
    setListCounselorTemp([])
    setShowDialog(true)
  }

  const onChangeCategory = (category) => {
    const opsiTopicTemp = []
    const listTopic = [...listFilter.find((filter) => filter.id_kategori_konsultasi === category.value).topik_konsultasi]
    listTopic.sort((a, b) => a.topik > b.topik ? 1 : -1)
    listTopic.map((topic, idxx) => {
      const newTopic = {
        label: topic.topik,
        value: topic.id_topik,
      }
      opsiTopicTemp.push(newTopic)
      if (idxx === 0) {
        setInitTopic(newTopic)
      }
    })
    setOptionTopic(opsiTopicTemp)
    setInitCategory(category)
  }

  const onHandleSearchCounselor = (event) => {
    event.preventDefault()
    setSearchQuery2(event.target.value.length > 0 ? event.target.value : null)
  }

  const onHandleCounselor = (value) => {
    const find = listCounselorTemp.filter((counselor) => counselor.nik === value.nik)
    if (find.length > 0) {
      const newCounselor = listCounselorTemp.filter((counselor) => counselor.nik !== value.nik)
      setListCounselorTemp(newCounselor)
    } else {
      setListCounselorTemp([...listCounselorTemp, value])
    }
  }

  const saveTopicConsultation = async () => {
    setShowDialog(false)
    setLoading(true)
    const listNik = []
    if (listCounselorTemp.length > 0) {
      listCounselorTemp.map((selected) => {
        listNik.push(selected.nik)
      })
    }
    const result = await saveTopicConsultationController({
      topic: initTopic.value,
      nik: listNik
    })
    if (result.status) {
      setTimeout(() => {
        regetTopicConsultation()
        setLoading(false)
        toast.success(result.message)
      }, 200)
    } else {
      setTimeout(() => {
        setLoading(false)
        toast.error(result.message)
      }, 200)
    }
  }

  useEffect(() => {
    if (!stsGetLoader) {
      getTopicConsultationFromLoader()
    } else {
      regetCounselorConsultation()
    }
  }, [initCategory, initTopic, debounceSearch2])

  useEffect(() => {
    if (stsGetLoader) {
      regetTopicConsultation()
    }
  }, [debounceSearch, limitRow, currentPage, currentSort])

  return (
    <>
      <Column className={'p-4 gap-y-5 sm:p-8'}>
        <Column className={'gap-x-4 gap-y-2 md:flex-row'}>
          <Column className={'gap-y-2 flex-1'}>
            <Headline2Text className={'font-semibold'}>
              Ploting Konselor
            </Headline2Text>
            <BodyText>
              Semua data dosen atau mitra berdasarkan topik konsultasi yang ditentukan oleh Bidang Alumni.
            </BodyText>
          </Column>
          <ButtonHover>
            <PrimaryButton
              color='indigo'
              className={'pr-6'}
              leadingIcon={
                <TbPlus className='h-5 w-5 my-auto' />
              }
              onClick={onAddPlotCounselor}>
              Ploting Konselor
            </PrimaryButton>
          </ButtonHover>
        </Column>
        <Card className={'w-full'}>
          <Column className={'gap-y-5'}>
            <Column className='gap-x-4 gap-y-2 md:flex-row md:justify-between'>
              <PaginationOption
                initial={limitRow}
                onChange={onHandlePaginationOption} />
              <SearchInput
                defaultValue={searchQuery}
                placeholder='Cari konselor'
                onChange={onHandleSearch} />
            </Column>
            <TableTopicConsultation
              limit={limitRow}
              page={currentPage}
              listTopic={listTopic}
              onShort={onHandleSort}
              onRefresh={regetTopicConsultation} />
            {listTopic.length > 0 && <Pagination
              limitRow={limitRow}
              totalRow={totalRow}
              totalPage={totalPage}
              triger={currentSort !== null && currentSort.key}
              onChange={onHandlePagination} />}
          </Column>
        </Card>
      </Column>
      <AlertDialog
        show={showDialog}
        className={'max-w-xl'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3Text>
              Ploting Konselor
            </Headline3Text>
            <BodyText>
              Tentukan topik konsultasi dari setiap dosen atau mitra untuk sesi konsultasi bersama alumni Universitas Teknologi Yogyakarta
            </BodyText>
          </Column>
          <Column className={'gap-y-6'}>
            <SelectInput
              width={'w-full'}
              label='Kategori'
              initial={initCategory}
              options={optionCategory}
              onChange={(value) => onChangeCategory(value)}
              btnClassName={'py-2.5 pl-4 pr-3'}
              zindex={'z-20'}
              required />
            <SelectInput
              width={'w-full'}
              label='Topik'
              initial={initTopic}
              options={optionTopic}
              onChange={(value) => setInitTopic(value)}
              btnClassName={'py-2.5 pl-4 pr-3'}
              required />
            {listCounselorTemp.length > 0 && <Column className={'gap-y-1.5'}>
              <LabelText className={'px-2 text-gray-800'}>
                {listCounselorTemp.length} konselor dipilih:
              </LabelText>
              <div className={'w-full h-fit max-h-24 flex flex-wrap gap-x-2 gap-y-2 overflow-y-auto scrollbar-hide'}>
                {
                  listCounselorTemp.map((counselor, idx) => {
                    return (
                      <Row
                        key={idx}
                        className={'w-fit shrink-0 gap-x-2 border rounded-full pl-4 pr-2.5 py-2 items-center bg-green-50 border-green-200'}>
                        <CaptionText className={'shrink-0 line-clamp-1 text-green-700 font-medium'}>
                          {counselor.nama}
                        </CaptionText>
                        <IconButton
                          color='custom'
                          className='p-[0.1rem] text-red-500 hover:bg-red-500 hover:text-white'
                          onClick={() => onHandleCounselor(counselor)}>
                          <TbX className='h-4 w-4' />
                        </IconButton>
                      </Row>
                    )
                  })
                }
              </div>
            </Column>}
            <Column className={'gap-y-1'}>
              <LabelText className={'px-2 text-gray-800'}>
                Pilih Konselor <span className='text-red-500'>*</span>
              </LabelText>
              <Column className={'gap-y-3'}>
                <SearchInput
                  defaultValue={searchQuery2}
                  placeholder='Cari nik atau nama'
                  onChange={onHandleSearchCounselor} />
                {listCounselor.length > 0 ? <Column className={'max-h-72 rounded-lg border divide-y bg-gray-50/50 overflow-scroll scrollbar-hide'}>
                  {listCounselor.map((counselor, idx) => {
                    return (
                      <ItemCounselor
                        key={idx}
                        counselor={counselor}
                        initial={listCounselorTemp.find((item) => item.nik === counselor.nik)}
                        onClick={onHandleCounselor} />
                    )
                  })}
                </Column> : <div></div>}
              </Column>
            </Column>
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className={'h-5 w-5 my-auto'} />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='indigo'
                className={'pl-5 pr-6'}
                leadingIcon={
                  <TbSend className={'h-5 w-5 my-auto'} />
                }
                disabled={initCategory.value !== null && initTopic.value !== null && listCounselorTemp.length > 0 ? false : true}
                onClick={saveTopicConsultation}>
                Simpan
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Menyimpan ploting topik...' />
    </>
  )
}

export default AdmTopicConsultationPage

export const getAdmTopicConsultation = async ({ search = null, limit = 10, page = 1, order = null, asc = null }) => {
  const props = {
    search: search,
    limit: limit,
    page: page,
    order: order,
    asc: asc
  }
  const [topic, category, counselor] = await Promise.allSettled([
    getTopicConsultationController(props),
    getFilterConsultationController(props),
    getCounselorConsultationController(props)
  ])
  return {
    'topic': topic,
    'category': category,
    'counselor': counselor,
  }
}