import { lazy, Suspense, useState } from 'react'
import { TbAlertTriangle, TbRefresh } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { currentIndexTabComments } from '../../atoms'
import STable from '../../components/shimmer/stable'
import { Column, Headline1, TabPanel, Tabs } from '../../shared/ui'

const TableProcessedComments = lazy(() => import('../../components/tabels/processed_comments'))
const TabeleRejectedComments = lazy(() => import('../../components/tabels/rejected_comments'))

const AdmCommentsPage = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabComments)
    const [showProgress, setShowProgress] = useState(true)

    const onChangeTabIndex = (index) => {
        setShowProgress(true)
        setCurrentTabIndex(index)
    }

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    return (
        <Column className='gap-5'>
            <Headline1>
                Acc Komentar
            </Headline1>
            <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                <TabPanel icon={<TbRefresh className='h-5 w-5' />} title='Menunggu' textActiveColor='text-blue-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableProcessedComments onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbAlertTriangle className='h-5 w-5' />} title='Ditolak' textActiveColor='text-red-700'>
                    <Suspense fallback={<STable column={6} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TabeleRejectedComments onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
            </Tabs>
        </Column>
    )
}

export default AdmCommentsPage
