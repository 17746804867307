import React, { Suspense, useEffect, useState } from "react";
import { TbArrowRight, TbChecks, TbX } from "react-icons/tb";
import { SiMicrosoftexcel } from "react-icons/si";
import {
  getExcelDataIsiKuesionerController,
  getExcelDataNotFillKuesionerController,
  getFillKuesionerTracerController,
  getNotFillKuesionerTracerController,
} from "../../../controllers/admin_controllers";
import {
  ButtonHover,
  Card,
  Column,
  OutlineInput,
  Pagination,
  PaginationOption,
  Row,
  TabPanel,
  Tabs,
} from "../../../shared/ui";
import TableNotFillKuesionerTracer from "./tables/table_not_fill_kuesioner_tracer";
import { BodyText, Headline2Text } from "../../../components/interfaces/text";
import TableFillKuesionerTracer from "./tables/table_fill_kuesioner_tracer";
import moment from "moment";
import { getFilterTracerController } from "../../../controllers/global_controllers";
import { SelectInput } from "../../../components/interfaces/input";
import useDownloadExcel from "../../../hooks/useDownloadExcel";

function AdmKuesionerTracerPage({ binaryData }) {
  const [currentProdi, setCurrentProdi] = useState({
    label: "Semua Program Studi",
    value: null,
  });
  // FILL KUESIONER
  const [listFillKuesioner, setListFillKuesioner] = useState([]);
  const [limitRowFill, setLimitRowFill] = useState(10);
  const [totalPageFill, setTotalPageFill] = useState(null);
  const [currentPageFill, setCurrentPageFill] = useState(null);
  const [totalRowFill, setTotalRowFill] = useState(null);
  const [currentSortFill, setCurrentSortFill] = useState(null);
  const [filterStartFill, setFilterStartFill] = useState(
    new Date(new Date().setDate(new Date().getDate() - 365))
      .toISOString()
      .slice(0, 10)
  );
  const [filterEndFill, setFilterEndFill] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const onHandleFilterStartFill = (event) => {
    event.preventDefault();
    setFilterStartFill(event.target.value);
  };

  const onHandleFilterEndFill = (event) => {
    event.preventDefault();
    setFilterEndFill(event.target.value);
  };

  const onHandlePaginationOptionFill = (value) => {
    setLimitRowFill(value);
    setCurrentPageFill(1);
  };

  const onHandlePaginationFill = (value) => {
    setCurrentPageFill(value);
  };

  const onHandleSortFill = (value) => {
    setCurrentSortFill(value);
  };

  const fillKuesioner = async () => {
    const res = await getFillKuesionerTracerController({
      page: currentPageFill,
      limit: limitRowFill,
      date_start: filterStartFill,
      date_end: filterEndFill,
      prodi: currentProdi.value,
      order: currentSortFill !== null ? currentSortFill : null,
      sorting: currentSortFill !== null ? currentSortFill : null,
    });
    if (res.status) {
      setListFillKuesioner(res.data);
      setTotalPageFill(res.totalPage);
      setTotalRowFill(res.totalRows);
    }
  };

  useEffect(() => {
    getFilterProdi();
    fillKuesioner();
  }, [
    limitRowFill,
    filterStartFill,
    filterEndFill,
    currentPageFill,
    currentProdi,
    currentSortFill,
  ]);

  // ==================================== NOT FILL KUESIONER ===================================

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [listNotFillKuesioner, setListNotFillKuesioner] = useState([]);
  const [limitRowNotFill, setLimitRowNotFill] = useState(10);
  const [totalPageNotFill, setTotalPageNotFill] = useState(null);
  const [currentPageNotFill, setCurrentPageNotFill] = useState(null);
  const [totalRowNotFill, setTotalRowNotFill] = useState(null);
  const [currentSortNotFill, setCurrentSortNotFill] = useState(null);
  const [filterStartNotFill, setFilterStartNotFill] = useState(
    new Date(new Date().setDate(new Date().getDate() - 365))
      .toISOString()
      .slice(0, 10)
  );
  const [filterEndNotFill, setFilterEndNotFill] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const onChangeTabIndex = (index) => {
    setCurrentTabIndex(index);
  };

  const onHandleFilterStartNotFill = (event) => {
    event.preventDefault();
    setFilterStartNotFill(event.target.value);
  };

  const onHandleFilterEndNotFill = (event) => {
    event.preventDefault();
    setFilterEndNotFill(event.target.value);
  };

  const onHandlePaginationOptionNotFill = (value) => {
    setLimitRowNotFill(value);
    setCurrentPageNotFill(1);
  };

  const onHandlePaginationNotFill = (value) => {
    setCurrentPageNotFill(value);
  };

  const onHandleSortNotFill = (value) => {
    setCurrentSortNotFill(value);
  };

  const onChangeProdi = (value) => {
    setCurrentProdi(value);
  };

  const [filterProdi, setFilterProdi] = useState([]);
  const getFilterProdi = async () => {
    const res = await getFilterTracerController();
    if (res.status) {
      const prodi = res.data.prodi;
      const listProdi = prodi.map((prodi) => ({
        label: prodi.label,
        value: prodi.kode_prodi,
      }));
      listProdi.unshift({ label: "Semua Program Studi", value: null });
      setFilterProdi(listProdi);
    }
  };

  const notFillKuesioner = async () => {
    const res = await getNotFillKuesionerTracerController({
      page: currentPageNotFill,
      limit: limitRowNotFill,
      date_start: filterStartNotFill,
      date_end: filterEndNotFill,
      prodi: currentProdi.value,
      order: currentSortNotFill !== null ? currentSortNotFill : null,
      sorting: currentSortNotFill !== null ? currentSortNotFill : null,
    });
    if (res.status) {
      setListNotFillKuesioner(res.data);
      setTotalPageNotFill(res.totalPage);
      setTotalRowNotFill(res.totalRows);
    }
  };

  useEffect(() => {
    getFilterProdi();
    notFillKuesioner();
  }, [
    limitRowNotFill,
    filterStartNotFill,
    filterEndNotFill,
    currentPageNotFill,
    currentProdi,
    currentSortNotFill,
  ]);

  //  =========================== DOWNLOAD EXCEL

  const fileName =
    currentTabIndex === 0
      ? `Kuesioner Mengisi_${filterStartFill}_${filterEndFill}_${currentProdi.label}`
      : `Kuesioner Belum Mengisi_${filterStartNotFill}_${filterEndNotFill}_${currentProdi.label}`;

  //  Download excel fill kuesioner
  const downloadExcelFill = useDownloadExcel({ fileName });
  const handleExportExcelFill = async () => {
    const res = await getExcelDataIsiKuesionerController({
      date_start: filterStartFill,
      date_end: filterEndFill,
      prodi: currentProdi.value,
    });
    downloadExcelFill(res);
  };

  //  Export excel not fill kuesioner
  const downloadExcelNotFill = useDownloadExcel({ fileName });
  const handleExportExcelNotFill = async () => {
    const res = await getExcelDataNotFillKuesionerController({
      date_start: filterStartNotFill,
      date_end: filterEndNotFill,
      prodi: currentProdi.value,
    });
    downloadExcelNotFill(res);
  };

  return (
    <>
      <Column className={"p-4 gap-y-5 sm:p-8"}>
        <Headline2Text>Data Kuesioner</Headline2Text>
        <BodyText>
          Semua data alumni yang lulus pada rentang tanggal{" "}
          {moment(
            ` ${currentTabIndex === 0 ? filterStartFill : filterStartNotFill}`
          ).format("DD MMM YYYY")}{" "}
          s.d{" "}
          {moment(
            `${currentTabIndex === 0 ? filterEndFill : filterEndNotFill}`
          ).format("DD MMM YYYY")}{" "}
          dan {currentTabIndex === 0 ? "sudah" : "belum"} mengisi tracer studi.
        </BodyText>
        <Tabs
          selectedTabIdx={currentTabIndex}
          onChangeTabIndex={onChangeTabIndex}
        >
          <TabPanel
            icon={<TbChecks className="h-5 w-5 " />}
            title="Sudah Mengisi"
            textActiveColor="text-green-700"
          >
            <Suspense>
              <Card className={"w-full gap-y-6"}>
                <Row
                  className={
                    "flex-col lg:flex-row gap-y-2 lg:gap-y-0 justify-between lg:justify-start gap-x-3"
                  }
                >
                  <div className="justify-end">
                    <SelectInput
                      width="w-full lg:w-80"
                      initial={currentProdi}
                      options={filterProdi}
                      onChange={onChangeProdi}
                      zindex="z-20"
                    />
                  </div>
                  <div>
                    <OutlineInput
                      id="start"
                      type="date"
                      maxDate={filterEndNotFill}
                      className="appearance-none h-[2.64rem] px-3 hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50"
                      inputWidth={"w-full lg:w-fit"}
                      placeholder="dd/mm/yyyy"
                      onChange={onHandleFilterStartFill}
                      defaultValue={filterStartFill}
                      hideLabel
                      required
                    />
                  </div>
                  <div className="hidden lg:flex justify-center items-center">
                    <TbArrowRight className="hidden sm:flex h-5 w-5 my-auto text-white bg-gray-700 rounded-full" />
                  </div>
                  <div>
                    <OutlineInput
                      id="end"
                      type="date"
                      minDate={filterStartFill}
                      maxDate={new Date().toISOString().slice(0, 10)}
                      className="appearance-none h-[2.64rem] px-3 hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50"
                      inputWidth={"w-full lg:w-fit"}
                      placeholder="dd/mm/yyyy"
                      onChange={onHandleFilterEndFill}
                      defaultValue={filterEndFill}
                      hideLabel
                      required
                    />
                  </div>
                </Row>
                <Row className="justify-between">
                  <div className="w-40">
                    <PaginationOption
                      initial={limitRowFill}
                      onChangeOptions={onHandlePaginationOptionFill}
                    />
                  </div>

                  <div className={"w-full sm:w-fit"}>
                    <ButtonHover>
                      <button
                        onClick={() => handleExportExcelFill(binaryData)}
                        className="flex border rounded-md px-4 py-2 gap-x-2  bg-gray-50"
                      >
                        Download
                        <SiMicrosoftexcel className="w-6 h-6 text-gray-600" />
                      </button>
                    </ButtonHover>
                  </div>
                </Row>

                <TableFillKuesionerTracer
                  listFillKuesioner={listFillKuesioner}
                  onShort={onHandleSortFill}
                  // limit={limitRowFill}
                  // page={currentPageFill}
                />
                {listFillKuesioner.length > 0 && (
                  <Pagination
                    limitRow={limitRowFill}
                    totalRow={totalRowFill}
                    totalPage={totalPageFill}
                    triger={currentSortFill !== null && currentSortFill.key}
                    onChangePageNumber={onHandlePaginationFill}
                  />
                )}
              </Card>
            </Suspense>
          </TabPanel>
          <TabPanel
            icon={<TbX className="h-5 w-5" />}
            title="Belum Mengisi"
            textActiveColor="text-red-700"
          >
            <Suspense>
              <Card className={"w-full gap-y-6"}>
                <Row
                  className={
                    "flex-col lg:flex-row gap-y-2 lg:gap-y-0 justify-start gap-x-3"
                  }
                >
                  <div className="justify-end">
                    <SelectInput
                      width="w-full lg:w-80"
                      initial={currentProdi}
                      options={filterProdi}
                      onChange={onChangeProdi}
                      zindex="z-20"
                    />
                  </div>
                  <div>
                    <OutlineInput
                      id="start"
                      type="date"
                      maxDate={filterEndNotFill}
                      className="appearance-none h-[2.64rem] px-3 hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50"
                      inputWidth={"w-full lg:w-fit"}
                      placeholder="dd/mm/yyyy"
                      onChange={onHandleFilterStartNotFill}
                      defaultValue={filterStartNotFill}
                      hideLabel
                      required
                    />
                  </div>
                  <div className="hidden lg:flex justify-center items-center">
                    <TbArrowRight className="hidden sm:flex h-5 w-5 my-auto text-white bg-gray-700 rounded-full" />
                  </div>
                  <div>
                    <OutlineInput
                      id="end"
                      type="date"
                      minDate={filterStartNotFill}
                      maxDate={new Date().toISOString().slice(0, 10)}
                      className="appearance-none h-[2.64rem] px-3 hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50"
                      inputWidth={"w-full lg:w-fit"}
                      placeholder="dd/mm/yyyy"
                      onChange={onHandleFilterEndNotFill}
                      defaultValue={filterEndNotFill}
                      hideLabel
                      required
                    />
                  </div>
                </Row>
                <Row className="justify-between">
                  <div className="w-40">
                    <PaginationOption
                      initial={limitRowNotFill}
                      onChangeOptions={onHandlePaginationOptionNotFill}
                    />
                  </div>
                  <div className={"w-full sm:w-fit"}>
                    <ButtonHover>
                      <button
                        onClick={() => handleExportExcelNotFill(binaryData)}
                        className="flex border rounded-md px-4 py-2 gap-x-2  bg-gray-50"
                      >
                        Download
                        <SiMicrosoftexcel className="w-6 h-6 text-gray-600" />
                      </button>
                    </ButtonHover>
                  </div>
                </Row>

                <TableNotFillKuesionerTracer
                  listNotFillKuesioner={listNotFillKuesioner}
                  onShort={onHandleSortNotFill}
                  // limit={limitRowFill}
                  // page={currentPageFill}
                />
                {listNotFillKuesioner.length > 0 && (
                  <Pagination
                    limitRow={limitRowNotFill}
                    totalRow={totalRowNotFill}
                    totalPage={totalPageNotFill}
                    triger={
                      currentSortNotFill !== null && currentSortNotFill.key
                    }
                    onChangePageNumber={onHandlePaginationNotFill}
                  />
                )}
              </Card>
            </Suspense>
          </TabPanel>
        </Tabs>
      </Column>
    </>
  );
}

export default AdmKuesionerTracerPage;
