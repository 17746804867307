export const LargeHeadlineText = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <h1
            id={id}
            ref={refId}
            className={`large-headline ${className}`}
            onClick={onClick}>
            {children}
        </h1>
    )
}

export const Headline1Text = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <h2
            id={id}
            ref={refId}
            className={`headline1 ${className}`}
            onClick={onClick}>
            {children}
        </h2>
    )
}

export const Headline2Text = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <h3
            id={id}
            ref={refId}
            className={`headline2 ${className}`}
            onClick={onClick}>
            {children}
        </h3>
    )
}

export const Headline3Text = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <h4
            id={id}
            ref={refId}
            className={`headline3 ${className}`}
            onClick={onClick}>
            {children}
        </h4>
    )
}

export const TitleText = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <h5
            id={id}
            ref={refId}
            className={`title ${className}`}
            onClick={onClick}>
            {children}
        </h5>
    )
}

export const SubtitleText = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <h6 id={id}
            ref={refId}
            className={`subtitle ${className}`}
            onClick={onClick}>
            {children}
        </h6>
    )
}

export const BodyText = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <p
            id={id}
            ref={refId}
            className={`texts ${className}`}
            onClick={onClick}>
            {children}
        </p>
    )
}

export const CaptionText = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <p
            id={id}
            ref={refId}
            className={`caption ${className}`}
            onClick={onClick}>
            {children}
        </p>
    )
}

export const OverlineText = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <p
            id={id}
            ref={refId}
            className={`overlines ${className}`}
            onClick={onClick}>
            {children}
        </p>
    )
}

export const LabelText = ({ id, refId, htmlForId, children, className = null, onClick = null }) => {
    return (
        <label
            id={id}
            ref={refId}
            htmlFor={htmlForId}
            className={`caption ${className}`}
            onClick={onClick}>
            {children}
        </label>
    )
}

export const HelperText = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <small
            id={id}
            ref={refId}
            className={`overlines ${className}`}
            onClick={onClick}>
            {children}
        </small>
    )
}