import { FormatRupiah } from "@arismun/format-rupiah";
import React, { useEffect, useRef, useState } from "react";
import { TbArrowsHorizontal, TbClipboardText } from "react-icons/tb";
import { useRecoilValue } from "recoil";
import {
  masterPosisiTracer,
  masterProvinsiTracer,
  masterSumBiayaTracer,
  masterTingKerjaTracer,
} from "../atoms";
import {
  AlertDialog,
  Caption,
  Card,
  Column,
  HorizontalDivider,
  OutlineInput,
  Overline,
  RadioButton,
  Row,
  SelectInput,
  Text,
  Title,
} from "../shared/ui";

const WAlmKueDua = ({ reqCheck, onCheck, answer }) => {
  const refYes6Bln = useRef(null);
  const refNo6Bln = useRef(null);
  const refJPLain = useRef(null);
  const refSDanaLain = useRef(null);

  const masterProvinsi = useRecoilValue(masterProvinsiTracer);
  const masterTingKerja = useRecoilValue(masterTingKerjaTracer);
  const masterPosisi = useRecoilValue(masterPosisiTracer);
  const masterSumBiaya = useRecoilValue(masterSumBiayaTracer);

  const [currentStatus, setCurrentStatus] = useState(-1);
  const [currentSumDana, setCurrentSumDana] = useState(-1);
  const [currentEtikaLulus, setCurrentEtikaLulus] = useState(-1);
  const [currentBidIlmuLulus, setCurrentBidIlmuLulus] = useState(-1);
  const [currentBIngLulus, setCurrentBIngLulus] = useState(-1);
  const [currentPTILulus, setCurrentPTILulus] = useState(-1);
  const [currentKomkasiLulus, setCurrentKomkasiLulus] = useState(-1);
  const [currentKTimLulus, setCurrentKTimLulus] = useState(-1);
  const [currentPDLulus, setCurrentPDLulus] = useState(-1);
  const [currentEtikaKerja, setCurrentEtikaKerja] = useState(-1);
  const [currentBidIlmuKerja, setCurrentBidIlmuKerja] = useState(-1);
  const [currentBIngKerja, setCurrentBIngKerja] = useState(-1);
  const [currentPTIKerja, setCurrentPTIKerja] = useState(-1);
  const [currentKomkasiKerja, setCurrentKomkasiKerja] = useState(-1);
  const [currentKTimKerja, setCurrentKTimKerja] = useState(-1);
  const [currentPDKerja, setCurrentPDKerja] = useState(-1);
  const [currentKerja6Bln, setCurrentKerja6Bln] = useState(-1);
  const [currentJPerusahaan, setCurrentJPerusahaan] = useState(-1);
  const [currentEratKerja, setCurrentEratKerja] = useState(-1);
  const [currentPendSesuai, setCurrentPendSesuai] = useState(-1);

  const [tglKerjaInput, setTglKerjaInput] = useState(null);
  const [ya6BlnInput, setYa6BlnInput] = useState(null);
  const [no6BlnInput, setNo6BlnInput] = useState(null);
  const [takeHomeInput, setTakeHomeInput] = useState(null);
  const [nmKantorInput, setNmKantorInput] = useState(null);
  const [tglWiraInput, setTglWiraInput] = useState(null);
  const [nmKampusInput, setNmKampusInput] = useState(null);
  const [nmProdiInput, setNmProdiInput] = useState(null);
  const [tglKuliahInput, setTglKuliahInput] = useState(null);

  const [lainJPerusahaan, setLainJPerusahaan] = useState(null);
  const [lainSumDana, setLainSumDana] = useState(null);

  const [initialProv, setInitialProv] = useState({
    label: "Pilih provinsi",
    value: null,
  });
  const [opsiProv, setOpsiProv] = useState([]);
  const [initialKab, setInitialKab] = useState({
    label: "Pilih kabupaten atau kota",
    value: null,
  });
  const [opsiKab, setOpsiKab] = useState([]);
  const [initialTingkat, setInitialTingkat] = useState({
    label: "Pilih tingkat",
    value: null,
  });
  const [opsiTingkat, setOpsiTingkat] = useState([]);
  const [initialPosisi, setInitialPosisi] = useState({
    label: "Pilih posisi",
    value: null,
  });
  const [opsiPosisi, setOpsiPosisi] = useState([]);
  const [initialBiaya, setInitialBiaya] = useState({
    label: "Pilih biaya",
    value: null,
  });
  const [opsiBiaya, setOpsiBiaya] = useState([]);

  const [errorStatus, setErrorStatus] = useState(null);
  const [errorSumDana, setErrorSumDana] = useState(null);
  const [errorEtikaLulus, setErrorEtikaLulus] = useState(null);
  const [errorBidIlmuLulus, setErrorBidIlmuLulus] = useState(null);
  const [errorBIngLulus, setErrorBIngLulus] = useState(null);
  const [errorPTILulus, setErrorPTILulus] = useState(null);
  const [errorKomkasiLulus, setErrorKomkasiLulus] = useState(null);
  const [errorKTimLulus, setErrorKTimLulus] = useState(null);
  const [errorPDLulus, setErrorPDLulus] = useState(null);
  const [errorEtikaKerja, setErrorEtikaKerja] = useState(null);
  const [errorBidIlmuKerja, setErrorBidIlmuKerja] = useState(null);
  const [errorBIngKerja, setErrorBIngKerja] = useState(null);
  const [errorPTIKerja, setErrorPTIKerja] = useState(null);
  const [errorKomkasiKerja, setErrorKomkasiKerja] = useState(null);
  const [errorKTimKerja, setErrorKTimKerja] = useState(null);
  const [errorPDKerja, setErrorPDKerja] = useState(null);
  const [errorTglKerja, setErrorTglKerja] = useState(null);
  const [errorTakeHome, setErrorTakeHome] = useState(null);
  const [errorNmKantor, setErrorNmKantor] = useState(null);
  const [errorKerja6Bln, setErrorKerja6Bln] = useState(null);
  const [errorProv, setErrorProv] = useState(null);
  const [errorKab, setErrorKab] = useState(null);
  const [errorTingkat, setErrorTingkat] = useState(null);
  const [errorJPerusahaan, setErrorJPerusahaan] = useState(null);
  const [errorEratKerja, setErrorEratKerja] = useState(null);
  const [errorPendSesuai, setErrorPendSesuai] = useState(null);
  const [errorTglWira, setErrorTglWira] = useState(null);
  const [errorPosisi, setErrorPosisi] = useState(null);
  const [errorBiaya, setErrorBiaya] = useState(null);
  const [errorNmKampus, setErrorNmKampus] = useState(null);
  const [errorNmProdi, setErrorNmProdi] = useState(null);
  const [errorTglKuliah, setErrorTglKuliah] = useState(null);

  const GetAnswerQuestion = (kode) => {
    const value = answer[kode];
    return value;
  };

  const setupOpsiProvinsi = () => {
    const answerProv = GetAnswerQuestion("f5a1");
    let initTemp = { label: "Pilih provinsi", value: null };
    const opsiTemp = [];
    let foundMatch = false;
    masterProvinsi.map((prov) => {
      const opsi = {
        label: prov.prov_nama,
        value: prov.prov_id,
      };
      if (prov.prov_id === answerProv) {
        initTemp = opsi;
        foundMatch = true;
      }
      opsiTemp.push(opsi);
    });
    setInitialProv(initTemp);
    setOpsiProv(opsiTemp);
    if (foundMatch) {
      setupOpsiKabupaten(initTemp);
    }
  };

  const setupOpsiKabupaten = (currentProv) => {
    const answerKab = GetAnswerQuestion("f5a2");
    let initTemp = { label: "Pilih kabupaten atau kota", value: null };
    const opsiTemp = [];
    const findProv =
      masterProvinsi.filter((prov) => prov.prov_id === currentProv.value) ?? [];
    if (findProv.length > 0) {
      findProv[0].kota.map((kota) => {
        const opsi = {
          label: kota.kota_nama,
          value: kota.kota_id,
        };
        if (kota.kota_id === answerKab) {
          initTemp = opsi;
        }
        opsiTemp.push(opsi);
      });
    }
    setInitialKab(initTemp);
    setOpsiKab(opsiTemp);
  };

  const setupTingkatTempatKerja = () => {
    const answerTingkat = GetAnswerQuestion("f5d");
    let initTemp = { label: "Pilih tingkat", value: null };
    const opsiTemp = [];
    masterTingKerja.map((tingkat) => {
      const opsi = {
        label: tingkat.tmp_kerja,
        value: tingkat.id_tmp_kerja,
      };
      if (tingkat.id_tmp_kerja === answerTingkat) {
        initTemp = opsi;
      }
      opsiTemp.push(opsi);
    });
    setInitialTingkat(initTemp);
    setOpsiTingkat(opsiTemp);
  };

  const setupPosisiWirausaha = () => {
    const answerPosisi = GetAnswerQuestion("f5c");
    let initTemp = { label: "Pilih posisi", value: null };
    const opsiTemp = [];
    masterPosisi.map((posisi) => {
      const opsi = {
        label: posisi.posisi,
        value: posisi.id_posisi,
      };
      if (posisi.id_posisi === answerPosisi) {
        initTemp = opsi;
      }
      opsiTemp.push(opsi);
    });
    setInitialPosisi(initTemp);
    setOpsiPosisi(opsiTemp);
  };

  const setupSumberBiaya = () => {
    const answerBiaya = GetAnswerQuestion("f18a");
    let initTemp = { label: "Pilih biaya", value: null };
    const opsiTemp = [];
    masterSumBiaya.map((biaya) => {
      const opsi = {
        label: biaya.sumber_biaya,
        value: biaya.id_sumber_biaya,
      };
      if (biaya.id_sumber_biaya === answerBiaya) {
        initTemp = opsi;
      }
      opsiTemp.push(opsi);
    });
    setInitialBiaya(initTemp);
    setOpsiBiaya(opsiTemp);
  };

  const onChangeRadio = (result) => {
    switch (result["action"]) {
      case 10:
        setCurrentStatus(parseInt(result["value"]));
        if (errorStatus !== null) {
          setErrorStatus(null);
        }
        break;
      case 20:
        setCurrentSumDana(parseInt(result["value"]));
        if (parseInt(result["value"]) !== 7) {
          refSDanaLain.current.value = "";
          setLainSumDana(null);
        }
        if (errorSumDana !== null) {
          setErrorSumDana(null);
        }
        break;
      case 30:
        setCurrentEtikaLulus(parseInt(result["value"]));
        if (errorEtikaLulus !== null) {
          setErrorEtikaLulus(null);
        }
        break;
      case 40:
        setCurrentBidIlmuLulus(parseInt(result["value"]));
        if (errorBidIlmuLulus !== null) {
          setErrorBidIlmuLulus(null);
        }
        break;
      case 50:
        setCurrentBIngLulus(parseInt(result["value"]));
        if (errorBIngLulus !== null) {
          setErrorBIngLulus(null);
        }
        break;
      case 60:
        setCurrentPTILulus(parseInt(result["value"]));
        if (errorPTILulus !== null) {
          setErrorPTILulus(null);
        }
        break;
      case 70:
        setCurrentKomkasiLulus(parseInt(result["value"]));
        if (errorKomkasiLulus !== null) {
          setErrorKomkasiLulus(null);
        }
        break;
      case 80:
        setCurrentKTimLulus(parseInt(result["value"]));
        if (errorKTimLulus !== null) {
          setErrorKTimLulus(null);
        }
        break;
      case 90:
        setCurrentPDLulus(parseInt(result["value"]));
        if (errorPDLulus !== null) {
          setErrorPDLulus(null);
        }
        break;
      case 100:
        setCurrentEtikaKerja(parseInt(result["value"]));
        if (errorEtikaKerja !== null) {
          setErrorEtikaKerja(null);
        }
        break;
      case 110:
        setCurrentBidIlmuKerja(parseInt(result["value"]));
        if (errorBidIlmuKerja !== null) {
          setErrorBidIlmuKerja(null);
        }
        break;
      case 120:
        setCurrentBIngKerja(parseInt(result["value"]));
        if (errorBIngKerja !== null) {
          setErrorBIngKerja(null);
        }
        break;
      case 130:
        setCurrentPTIKerja(parseInt(result["value"]));
        if (errorPTIKerja !== null) {
          setErrorPTIKerja(null);
        }
        break;
      case 140:
        setCurrentKomkasiKerja(parseInt(result["value"]));
        if (errorKomkasiKerja !== null) {
          setErrorKomkasiKerja(null);
        }
        break;
      case 150:
        setCurrentKTimKerja(parseInt(result["value"]));
        if (errorKTimKerja !== null) {
          setErrorKTimKerja(null);
        }
        break;
      case 160:
        setCurrentPDKerja(parseInt(result["value"]));
        if (errorPDKerja !== null) {
          setErrorPDKerja(null);
        }
        break;
      case 170:
        setCurrentKerja6Bln(parseInt(result["value"]));
        if (parseInt(result["value"]) === 1) {
          refNo6Bln.current.value = "";
          setNo6BlnInput(null);
        } else {
          refYes6Bln.current.value = "";
          setYa6BlnInput(null);
        }
        if (errorKerja6Bln !== null) {
          setErrorKerja6Bln(null);
        }
        break;
      case 180:
        setCurrentJPerusahaan(parseInt(result["value"]));
        if (parseInt(result["value"]) !== 5) {
          refJPLain.current.value = "";
          setLainJPerusahaan(null);
        }
        if (errorJPerusahaan !== null) {
          setErrorJPerusahaan(null);
        }
        break;
      case 190:
        setCurrentEratKerja(parseInt(result["value"]));
        if (errorEratKerja !== null) {
          setErrorEratKerja(null);
        }
        break;
      case 200:
        setCurrentPendSesuai(parseInt(result["value"]));
        if (errorPendSesuai !== null) {
          setErrorPendSesuai(null);
        }
        break;
    }
  };

  const onChangeSelect = (result) => {
    switch (result["action"]) {
      case 10:
        const prov = result["value"];
        setInitialProv(prov);
        setupOpsiKabupaten(prov);
        if (errorProv !== null) {
          setErrorProv(null);
        }
        break;
      case 20:
        const kab = result["value"];
        setInitialKab(kab);
        if (errorKab !== null) {
          setErrorKab(null);
        }
        break;
      case 30:
        const tingkat = result["value"];
        setInitialTingkat(tingkat);
        if (errorTingkat !== null) {
          setErrorTingkat(null);
        }
        break;
      case 40:
        const posisi = result["value"];
        setInitialPosisi(posisi);
        if (errorPosisi !== null) {
          setErrorPosisi(null);
        }
        break;
      case 50:
        const biaya = result["value"];
        setInitialBiaya(biaya);
        if (errorBiaya !== null) {
          setErrorBiaya(null);
        }
        break;
    }
  };

  const onChangeInput = (result) => {
    switch (result["action"]) {
      case 10:
        setTglKerjaInput(result["value"]);
        if (errorTglKerja !== null) {
          setErrorTglKerja(null);
        }
        break;
      case 20:
        setYa6BlnInput(result["value"]);
        if (errorKerja6Bln !== null) {
          setErrorKerja6Bln(null);
        }
        break;
      case 30:
        setNo6BlnInput(result["value"]);
        if (errorKerja6Bln !== null) {
          setErrorKerja6Bln(null);
        }
        break;
      case 40:
        setTakeHomeInput(result["value"]);
        if (errorTakeHome !== null) {
          setErrorTakeHome(null);
        }
        break;
      case 50:
        setLainJPerusahaan(result["value"]);
        if (errorJPerusahaan !== null) {
          setErrorJPerusahaan(null);
        }
        break;
      case 60:
        setNmKantorInput(result["value"]);
        if (errorNmKantor !== null) {
          setErrorNmKantor(null);
        }
        break;
      case 70:
        setTglWiraInput(result["value"]);
        if (errorTglWira !== null) {
          setErrorTglWira(null);
        }
        break;
      case 80:
        setNmKampusInput(result["value"]);
        if (errorNmKampus !== null) {
          setErrorNmKampus(null);
        }
        break;
      case 90:
        setNmProdiInput(result["value"]);
        if (errorNmProdi !== null) {
          setErrorNmProdi(null);
        }
        break;
      case 100:
        setTglKuliahInput(result["value"]);
        if (errorTglKuliah !== null) {
          setErrorTglKuliah(null);
        }
        break;
      case 110:
        setLainSumDana(result["value"]);
        if (errorSumDana !== null) {
          setErrorSumDana(null);
        }
        break;
    }
  };

  const verifyInputQuestion = () => {
    let status = true;
    if (currentStatus === -1) {
      status = false;
      setErrorStatus("Pilih salah satu");
    } else {
      if (currentStatus === 1) {
        if (
          !tglKerjaInput ||
          !takeHomeInput ||
          !nmKantorInput ||
          takeHomeInput <= 0
        ) {
          status = false;
          if (tglKerjaInput === null || !tglKerjaInput) {
            setErrorTglKerja("Masukkan tanggal mulai bekerja");
          }
          if (takeHomeInput === null || !takeHomeInput || takeHomeInput <= 0) {
            setErrorTakeHome("Masukkan rata-rata pendapatan");
          }
          if (nmKantorInput === null || !nmKantorInput) {
            setErrorNmKantor("Masukkan nama perusahaan/kantor");
          }
        }

        if (currentKerja6Bln === -1) {
          status = false;
          setErrorKerja6Bln("Pilih salah satu");
        } else if (currentKerja6Bln === 1) {
          if (ya6BlnInput === null || !ya6BlnInput) {
            status = false;
            setErrorKerja6Bln("Masukkan berapa bulan");
          }
        } else {
          if (no6BlnInput === null) {
            status = false;
            setErrorKerja6Bln("Masukkan berapa bulan");
          }
        }

        if (
          initialProv.value === null ||
          initialKab.value === null ||
          initialTingkat.value === null
        ) {
          status = false;
          if (initialProv.value === null) {
            setErrorProv("Pilih provinsi");
          }
          if (initialKab.value === null) {
            setErrorKab("Pilih kabupaten");
          }
          if (initialTingkat.value === null) {
            setErrorTingkat("Pilih tingkat");
          }
        }

        if (currentJPerusahaan === -1) {
          status = false;
          setErrorJPerusahaan("Pilih salah satu");
        } else if (currentJPerusahaan === 5) {
          if (lainJPerusahaan === null) {
            status = false;
            setErrorJPerusahaan("Masukkan pilihan lainnya");
          }
        }

        if (currentEratKerja === -1 || currentPendSesuai === -1) {
          status = false;
          if (currentEratKerja === -1) {
            setErrorEratKerja("Pilih salah satu");
          }
          if (currentPendSesuai === -1) {
            setErrorPendSesuai("Pilih salah satu");
          }
        }
      } else if (currentStatus === 3) {
        if (tglWiraInput === null) {
          status = false;
          setErrorTglWira("Masukkan tanggal mulai wirausaha");
        }

        if (initialPosisi.value === null || initialTingkat.value === null) {
          status = false;
          if (initialPosisi.value === null) {
            setErrorPosisi("Pilih posisi");
          }
          if (initialTingkat.value === null) {
            setErrorTingkat("Pilih tingkat");
          }
        }

        if (currentEratKerja === -1 || currentPendSesuai === -1) {
          status = false;
          if (currentEratKerja === -1) {
            setErrorEratKerja("Pilih salah satu");
          }
          if (currentPendSesuai === -1) {
            setErrorPendSesuai("Pilih salah satu");
          }
        }
      } else if (currentStatus === 4) {
        if (initialBiaya.value === null) {
          status = false;
          setErrorBiaya("Pilih sumber biaya");
        }

        if (
          nmKampusInput === null ||
          nmProdiInput === null ||
          tglKuliahInput === null
        ) {
          status = false;
          if (nmKampusInput === null) {
            setErrorNmKampus("Masukkan perguruan tinggi");
          }
          if (nmProdiInput === null) {
            setErrorNmProdi("Masukkan program studi");
          }
          if (tglKuliahInput === null) {
            setErrorTglKuliah("Masukkan tanggal masuk");
          }
        }
      }
    }

    if (currentSumDana === -1) {
      status = false;
      setErrorSumDana("Pilih salah satu");
    } else if (currentSumDana === 7) {
      if (lainSumDana === null) {
        status = false;
        setErrorSumDana("Masukkan pilihan lainnya");
      }
    }

    if (
      currentEtikaLulus === -1 ||
      currentBidIlmuLulus === -1 ||
      currentBIngLulus === -1 ||
      currentPTILulus === -1 ||
      currentKomkasiLulus === -1 ||
      currentKTimLulus === -1 ||
      currentPDLulus === -1 ||
      currentEtikaKerja === -1 ||
      currentBidIlmuKerja === -1 ||
      currentBIngKerja === -1 ||
      currentPTIKerja === -1 ||
      currentKomkasiKerja === -1 ||
      currentKTimKerja === -1 ||
      currentPDKerja === -1
    ) {
      status = false;
      if (currentEtikaLulus === -1) {
        setErrorEtikaLulus("Pilih salah satu");
      }
      if (currentBidIlmuLulus === -1) {
        setErrorBidIlmuLulus("Pilih salah satu");
      }
      if (currentBIngLulus === -1) {
        setErrorBIngLulus("Pilih salah satu");
      }
      if (currentPTILulus === -1) {
        setErrorPTILulus("Pilih salah satu");
      }
      if (currentKomkasiLulus === -1) {
        setErrorKomkasiLulus("Pilih salah satu");
      }
      if (currentKTimLulus === -1) {
        setErrorKTimLulus("Pilih salah satu");
      }
      if (currentPDLulus === -1) {
        setErrorPDLulus("Pilih salah satu");
      }
      if (currentEtikaKerja === -1) {
        setErrorEtikaKerja("Pilih salah satu");
      }
      if (currentBidIlmuKerja === -1) {
        setErrorBidIlmuKerja("Pilih salah satu");
      }
      if (currentBIngKerja === -1) {
        setErrorBIngKerja("Pilih salah satu");
      }
      if (currentPTIKerja === -1) {
        setErrorPTIKerja("Pilih salah satu");
      }
      if (currentKomkasiKerja === -1) {
        setErrorKomkasiKerja("Pilih salah satu");
      }
      if (currentKTimKerja === -1) {
        setErrorKTimKerja("Pilih salah satu");
      }
      if (currentPDKerja === -1) {
        setErrorPDKerja("Pilih salah satu");
      }
    }
    return status;
  };

  useEffect(() => {
    setCurrentStatus(parseInt(GetAnswerQuestion("f8") ?? -1));
    setCurrentSumDana(parseInt(GetAnswerQuestion("f1201") ?? -1));
    setCurrentEtikaLulus(parseInt(GetAnswerQuestion("f1761") ?? -1));
    setCurrentBidIlmuLulus(parseInt(GetAnswerQuestion("f1763") ?? -1));
    setCurrentBIngLulus(parseInt(GetAnswerQuestion("f1765") ?? -1));
    setCurrentPTILulus(parseInt(GetAnswerQuestion("f1767") ?? -1));
    setCurrentKomkasiLulus(parseInt(GetAnswerQuestion("f1769") ?? -1));
    setCurrentKTimLulus(parseInt(GetAnswerQuestion("f1771") ?? -1));
    setCurrentPDLulus(parseInt(GetAnswerQuestion("f1773") ?? -1));
    setCurrentEtikaKerja(parseInt(GetAnswerQuestion("f1762") ?? -1));
    setCurrentBidIlmuKerja(parseInt(GetAnswerQuestion("f1764") ?? -1));
    setCurrentBIngKerja(parseInt(GetAnswerQuestion("f1766") ?? -1));
    setCurrentPTIKerja(parseInt(GetAnswerQuestion("f1768") ?? -1));
    setCurrentKomkasiKerja(parseInt(GetAnswerQuestion("f1770") ?? -1));
    setCurrentKTimKerja(parseInt(GetAnswerQuestion("f1772") ?? -1));
    setCurrentPDKerja(parseInt(GetAnswerQuestion("f1774") ?? -1));
    setCurrentKerja6Bln(parseInt(GetAnswerQuestion("f504") ?? -1));
    setCurrentJPerusahaan(parseInt(GetAnswerQuestion("f1101") ?? -1));
    setCurrentEratKerja(parseInt(GetAnswerQuestion("f14") ?? -1));
    setCurrentPendSesuai(parseInt(GetAnswerQuestion("f15") ?? -1));

    setTglKerjaInput(GetAnswerQuestion("tgl_kerja"));
    setYa6BlnInput(GetAnswerQuestion("f502"));
    setNo6BlnInput(GetAnswerQuestion("f506"));
    setTakeHomeInput(GetAnswerQuestion("f505"));
    setNmKantorInput(GetAnswerQuestion("f5b"));
    setTglWiraInput(GetAnswerQuestion("tgl_kerja"));
    setNmKampusInput(GetAnswerQuestion("f18b"));
    setNmProdiInput(GetAnswerQuestion("f18c"));
    setTglKuliahInput(GetAnswerQuestion("f18d"));
    setLainJPerusahaan(GetAnswerQuestion("f1102"));
    setLainSumDana(GetAnswerQuestion("f1202"));

    setupOpsiProvinsi();
    setupTingkatTempatKerja();
    setupPosisiWirausaha();
    setupSumberBiaya();
  }, []);

  useEffect(() => {
    if (reqCheck) {
      const status = verifyInputQuestion();
      const result = {
        status: status,
        action: 20,
        value: {
          f8: currentStatus === -1 ? null : currentStatus,
          tgl_kerja:
            currentStatus === 1
              ? tglKerjaInput
              : currentStatus === 3
              ? tglWiraInput
              : null,
          f504: currentKerja6Bln === -1 ? null : currentKerja6Bln,
          f502: ya6BlnInput,
          f506: no6BlnInput,
          f505: takeHomeInput,
          f5a1: initialProv.value,
          f5a2: initialKab.value,
          f1101: currentJPerusahaan === -1 ? null : currentJPerusahaan,
          f1102: lainJPerusahaan,
          f5d: initialTingkat.value,
          f5b: nmKantorInput,
          f14: currentEratKerja === -1 ? null : currentEratKerja,
          f15: currentPendSesuai === -1 ? null : currentPendSesuai,
          f1201: currentSumDana === -1 ? null : currentSumDana,
          f1202: lainSumDana,
          f1761: currentEtikaLulus === -1 ? null : currentEtikaLulus,
          f1762: currentEtikaKerja === -1 ? null : currentEtikaKerja,
          f1763: currentBidIlmuLulus === -1 ? null : currentBidIlmuLulus,
          f1764: currentBidIlmuKerja === -1 ? null : currentBidIlmuKerja,
          f1765: currentBIngLulus === -1 ? null : currentBIngLulus,
          f1766: currentBIngKerja === -1 ? null : currentBIngKerja,
          f1767: currentPTILulus === -1 ? null : currentPTILulus,
          f1768: currentPTIKerja === -1 ? null : currentPTIKerja,
          f1769: currentKomkasiLulus === -1 ? null : currentKomkasiLulus,
          f1770: currentKomkasiKerja === -1 ? null : currentKomkasiKerja,
          f1771: currentKTimLulus === -1 ? null : currentKTimLulus,
          f1772: currentKTimKerja === -1 ? null : currentKTimKerja,
          f1773: currentPDLulus === -1 ? null : currentPDLulus,
          f1774: currentPDKerja === -1 ? null : currentPDKerja,
          f5c: initialPosisi.value,
          f18a: initialBiaya.value,
          f18b: nmKampusInput,
          f18c: nmProdiInput,
          f18d: tglKuliahInput,
        },
      };
      onCheck(result);
    }
  }, [reqCheck]);

  return (
    <>
      <Card className="w-full p-0">
        <Column>
          <Row className="px-4 py-3 sm:px-8 sm:py-5">
            <Column className="gap-0.5 my-auto">
              <Title>Kuesioner Wajib</Title>
              <Caption>Lengkapi semua isian yang ada</Caption>
            </Column>
            <TbClipboardText className="hidden sm:flex h-14 w-14 my-auto stroke-1.5 text-indigo-300" />
          </Row>
          <HorizontalDivider className="mt-0 mb-0" />
          <Column className="px-4 py-3 sm:px-8 sm:py-6 gap-6 mb-4">
            <Column className="gap-1.5">
              <Text className="font-manrope font-semibold">
                Jelaskan status Anda saat ini?
                <span className="text-red-500">*</span>
              </Text>
              <Card className="w-full p-0 overflow-hidden shadow-none">
                <Column className="divide-y">
                  <RadioButton
                    action={10}
                    onChange={onChangeRadio}
                    id="f8-1"
                    name="f8"
                    value={1}
                    checked={currentStatus === 1}
                    label="Bekerja (full time/part time)"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={10}
                    onChange={onChangeRadio}
                    id="f8-2"
                    name="f8"
                    value={3}
                    checked={currentStatus === 3}
                    label="Wiraswasta"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={10}
                    onChange={onChangeRadio}
                    id="f8-3"
                    name="f8"
                    value={4}
                    checked={currentStatus === 4}
                    label="Melanjutkan Pendidikan"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={10}
                    onChange={onChangeRadio}
                    id="f8-4"
                    name="f8"
                    value={5}
                    checked={currentStatus === 5}
                    label="Tidak Kerja tetapi sedang mencari kerja"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={10}
                    onChange={onChangeRadio}
                    id="f8-5"
                    name="f8"
                    value={2}
                    checked={currentStatus === 2}
                    label="Belum memungkinkan bekerja"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Column>
              </Card>
              {errorStatus !== null && (
                <Overline className="text-red-600">* {errorStatus}</Overline>
              )}
            </Column>
            {currentStatus === 1 ? (
              <Column className={`gap-6`}>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Kapan kira-kira anda mulai bekerja?
                    <span className="text-red-500">*</span>
                  </Text>
                  <OutlineInput
                    action={10}
                    id="tgl_kerja"
                    type="date"
                    inputWidth="w-full"
                    className="rounded-md appearance-none h-11"
                    placeholder="dd/mm/yyyy"
                    onChange={onChangeInput}
                    defaultValue={tglKerjaInput}
                    required
                  />
                  {errorTglKerja !== null && (
                    <Overline className="text-red-600">
                      * {errorTglKerja}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    {
                      "Apakah anda telah mendapatkan pekerjaan <= 6 bulan / termasuk bekerja sebelum lulus ?"
                    }
                    <span className="text-red-500">*</span>
                  </Text>
                  <Card className="w-full p-0 overflow-hidden shadow-none">
                    <Column className="divide-y">
                      <Column>
                        <RadioButton
                          action={170}
                          onChange={onChangeRadio}
                          id="f504-1"
                          name="f504"
                          value={1}
                          checked={currentKerja6Bln === 1}
                          label="Ya"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                        <div
                          className={`p-4 border-t ${
                            currentKerja6Bln === 1 ? "flex" : "hidden"
                          }`}
                        >
                          <OutlineInput
                            refs={refYes6Bln}
                            action={20}
                            id="f502"
                            type="text"
                            inputWidth="w-full"
                            className="rounded-md"
                            label="Dalam berapa bulan anda mendapatkan pekerjaan?"
                            labelClassName="text-gray-800"
                            placeholder="Jawaban anda"
                            maxLength={2}
                            onChange={onChangeInput}
                            onKeyDown={(event) => {
                              if (
                                event.key !== "Backspace" &&
                                event.key !== "ArrowLeft" &&
                                event.key !== "ArrowRight"
                              ) {
                                if (!/^[0-9\b]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }
                            }}
                            defaultValue={ya6BlnInput}
                            required={currentKerja6Bln === 1}
                          />
                        </div>
                      </Column>
                      <Column>
                        <RadioButton
                          action={170}
                          onChange={onChangeRadio}
                          id="f504-2"
                          name="f504"
                          value={2}
                          checked={currentKerja6Bln === 2}
                          label="Tidak"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                        <div
                          className={`p-4 border-t ${
                            currentKerja6Bln === 2 ? "flex" : "hidden"
                          }`}
                        >
                          <OutlineInput
                            refs={refNo6Bln}
                            action={30}
                            id="f506"
                            type="text"
                            inputWidth="w-full"
                            className="rounded-md"
                            label="Dalam berapa bulan anda mendapatkan pekerjaan?"
                            labelClassName="text-gray-800"
                            placeholder="Jawaban anda"
                            maxLength={2}
                            onChange={onChangeInput}
                            onKeyDown={(event) => {
                              if (
                                event.key !== "Backspace" &&
                                event.key !== "ArrowLeft" &&
                                event.key !== "ArrowRight"
                              ) {
                                if (!/^[0-9\b]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }
                            }}
                            defaultValue={no6BlnInput}
                            required={currentKerja6Bln === 2}
                          />
                        </div>
                      </Column>
                    </Column>
                  </Card>
                  {errorKerja6Bln !== null && (
                    <Overline className="text-red-600">
                      * {errorKerja6Bln}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Berapa rata-rata pendapatan anda per bulan? (take home pay)?
                    <span className="text-red-500">*</span>
                  </Text>
                  <OutlineInput
                    action={40}
                    id="f505"
                    type="number"
                    inputWidth="w-full"
                    className="no-spinner rounded-md"
                    placeholder="Jawaban anda"
                    onChange={onChangeInput}
                    onKeyDown={(event) => {
                      if (
                        event.key !== "Backspace" &&
                        event.key !== "ArrowLeft" &&
                        event.key !== "ArrowRight"
                      ) {
                        if (!/^[0-9\b]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }
                    }}
                    defaultValue={takeHomeInput}
                    maxLength={10}
                    required
                  />
                  <Caption>
                    <FormatRupiah value={takeHomeInput} />
                  </Caption>
                  {errorTakeHome !== null && (
                    <Overline className="text-red-600">
                      * {errorTakeHome}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Dimana lokasi tempat Anda bekerja?
                    <span className="text-red-500">*</span>
                  </Text>
                  <Column className="gap-3">
                    <Column className="gap-1.5">
                      <SelectInput
                        action={10}
                        zindex="z-20"
                        initial={initialProv}
                        options={opsiProv}
                        onChange={onChangeSelect}
                        required
                      />
                      {errorProv !== null && (
                        <Overline className="text-red-600">
                          * {errorProv}
                        </Overline>
                      )}
                    </Column>
                    <Column className="gap-1.5">
                      <SelectInput
                        action={20}
                        zindex="z-10"
                        initial={initialKab}
                        options={opsiKab}
                        onChange={onChangeSelect}
                        required
                      />
                      {errorKab !== null && (
                        <Overline className="text-red-600">
                          * {errorKab}
                        </Overline>
                      )}
                    </Column>
                  </Column>
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Apa jenis perusahaan/instansi/institusi tempat anda bekerja
                    sekarang?
                    <span className="text-red-500">*</span>
                  </Text>
                  <Card className="w-full p-0 overflow-hidden shadow-none">
                    <Column className="divide-y">
                      <RadioButton
                        action={180}
                        onChange={onChangeRadio}
                        id="f1101-1"
                        name="f1101"
                        value={1}
                        checked={currentJPerusahaan === 1}
                        label="Instansi pemerintah"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={180}
                        onChange={onChangeRadio}
                        id="f1101-2"
                        name="f1101"
                        value={6}
                        checked={currentJPerusahaan === 6}
                        label="BUMN/BUMD"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={180}
                        onChange={onChangeRadio}
                        id="f1101-3"
                        name="f1101"
                        value={7}
                        checked={currentJPerusahaan === 7}
                        label="Institusi/Organisasi Multilateral"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={180}
                        onChange={onChangeRadio}
                        id="f1101-4"
                        name="f1101"
                        value={2}
                        checked={currentJPerusahaan === 2}
                        label="Organisasi non-profit/Lembaga Swadaya Masyarakat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={180}
                        onChange={onChangeRadio}
                        id="f1101-5"
                        name="f1101"
                        value={3}
                        checked={currentJPerusahaan === 3}
                        label="Perusahaan swasta"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={180}
                        onChange={onChangeRadio}
                        id="f1101-6"
                        name="f1101"
                        value={4}
                        checked={currentJPerusahaan === 4}
                        label="Wiraswasta/perusahaan sendiri"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <Column>
                        <RadioButton
                          action={180}
                          onChange={onChangeRadio}
                          id="f1101-7"
                          name="f1101"
                          value={5}
                          checked={currentJPerusahaan === 5}
                          label="Lainnya"
                          className="w-full pl-3 hover:bg-slate-50"
                        />
                        <div
                          className={`p-4 border-t ${
                            currentJPerusahaan === 5 ? "flex" : "hidden"
                          }`}
                        >
                          <OutlineInput
                            refs={refJPLain}
                            action={50}
                            id="f1102"
                            type="text"
                            inputWidth="w-full"
                            className="rounded-md"
                            label="Tuliskan lainnya"
                            labelClassName="text-gray-800"
                            placeholder="Jawaban anda"
                            maxLength={50}
                            onChange={onChangeInput}
                            defaultValue={lainJPerusahaan}
                            required={currentJPerusahaan === 5}
                          />
                        </div>
                      </Column>
                    </Column>
                  </Card>
                  {errorJPerusahaan !== null && (
                    <Overline className="text-red-600">
                      * {errorJPerusahaan}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Apa tingkat tempat kerja Anda?
                    <span className="text-red-500">*</span>
                  </Text>
                  <SelectInput
                    action={30}
                    zindex="z-10"
                    initial={initialTingkat}
                    options={opsiTingkat}
                    onChange={onChangeSelect}
                    required
                  />
                  {errorTingkat !== null && (
                    <Overline className="text-red-600">
                      * {errorTingkat}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Apa nama perusahaan/kantor tempat Anda bekerja?
                    <span className="text-red-500">*</span>
                  </Text>
                  <OutlineInput
                    action={60}
                    id="f5b"
                    type="text"
                    inputWidth="w-full"
                    className="rounded-md"
                    placeholder="Jawaban anda"
                    onChange={onChangeInput}
                    defaultValue={nmKantorInput}
                    maxLength={50}
                    required
                  />
                  {errorNmKantor !== null && (
                    <Overline className="text-red-600">
                      * {errorNmKantor}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Seberapa erat hubungan antara bidang studi dengan pekerjaan
                    anda?
                    <span className="text-red-500">*</span>
                  </Text>
                  <Card className="w-full p-0 overflow-hidden shadow-none">
                    <Column className="divide-y">
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-1"
                        name="f14"
                        value={1}
                        checked={currentEratKerja === 1}
                        label="Sangat Erat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-2"
                        name="f14"
                        value={2}
                        checked={currentEratKerja === 2}
                        label="Erat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-3"
                        name="f14"
                        value={3}
                        checked={currentEratKerja === 3}
                        label="Cukup Erat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-4"
                        name="f14"
                        value={4}
                        checked={currentEratKerja === 4}
                        label="Kurang Erat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-5"
                        name="f14"
                        value={5}
                        checked={currentEratKerja === 5}
                        label="Tidak Sama Sekali"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                    </Column>
                  </Card>
                  {errorEratKerja !== null && (
                    <Overline className="text-red-600">
                      * {errorEratKerja}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Tingkat pendidikan apa yang paling tepat/sesuai untuk
                    pekerjaan anda saat ini?
                    <span className="text-red-500">*</span>
                  </Text>
                  <Card className="w-full p-0 overflow-hidden shadow-none">
                    <Column className="divide-y">
                      <RadioButton
                        action={200}
                        onChange={onChangeRadio}
                        id="f15-1"
                        name="f15"
                        value={1}
                        checked={currentPendSesuai === 1}
                        label="Setingkat Lebih Tinggi"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={200}
                        onChange={onChangeRadio}
                        id="f15-2"
                        name="f15"
                        value={2}
                        checked={currentPendSesuai === 2}
                        label="Tingkat yang Sama"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={200}
                        onChange={onChangeRadio}
                        id="f15-3"
                        name="f15"
                        value={3}
                        checked={currentPendSesuai === 3}
                        label="Setingkat Lebih Rendah"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={200}
                        onChange={onChangeRadio}
                        id="f15-4"
                        name="f15"
                        value={4}
                        checked={currentPendSesuai === 4}
                        label="Tidak Perlu Pendidikan Tinggi"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                    </Column>
                  </Card>
                  {errorPendSesuai !== null && (
                    <Overline className="text-red-600">
                      * {errorPendSesuai}
                    </Overline>
                  )}
                </Column>
              </Column>
            ) : currentStatus === 3 ? (
              <Column className={`gap-6`}>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Kapan kira-kira anda mulai wirausaha?
                    <span className="text-red-500">*</span>
                  </Text>
                  <OutlineInput
                    action={70}
                    id="tgl_wirausaha"
                    type="date"
                    inputWidth="w-full"
                    className="rounded-md appearance-none h-11"
                    placeholder="dd/mm/yyyy"
                    onChange={onChangeInput}
                    defaultValue={tglWiraInput}
                    required
                  />
                  {errorTglWira !== null && (
                    <Overline className="text-red-600">
                      * {errorTglWira}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Bila berwiraswasta, apa posisi/jabatan Anda saat ini?
                    <span className="text-red-500">*</span>
                  </Text>
                  <SelectInput
                    action={40}
                    zindex="z-20"
                    initial={initialPosisi}
                    options={opsiPosisi}
                    onChange={onChangeSelect}
                    required
                  />
                  {errorPosisi !== null && (
                    <Overline className="text-red-600">
                      * {errorPosisi}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Apa tingkat tempat kerja Anda?
                    <span className="text-red-500">*</span>
                  </Text>
                  <SelectInput
                    action={30}
                    zindex="z-10"
                    initial={initialTingkat}
                    options={opsiTingkat}
                    onChange={onChangeSelect}
                    required
                  />
                  {errorTingkat !== null && (
                    <Overline className="text-red-600">
                      * {errorTingkat}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Seberapa erat hubungan antara bidang studi dengan pekerjaan
                    anda?
                    <span className="text-red-500">*</span>
                  </Text>
                  <Card className="w-full p-0 overflow-hidden shadow-none">
                    <Column className="divide-y">
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-1"
                        name="f14"
                        value={1}
                        checked={currentEratKerja === 1}
                        label="Sangat Erat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-2"
                        name="f14"
                        value={2}
                        checked={currentEratKerja === 2}
                        label="Erat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-3"
                        name="f14"
                        value={3}
                        checked={currentEratKerja === 3}
                        label="Cukup Erat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-4"
                        name="f14"
                        value={4}
                        checked={currentEratKerja === 4}
                        label="Kurang Erat"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={190}
                        onChange={onChangeRadio}
                        id="f14-5"
                        name="f14"
                        value={5}
                        checked={currentEratKerja === 5}
                        label="Tidak Sama Sekali"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                    </Column>
                  </Card>
                  {errorEratKerja !== null && (
                    <Overline className="text-red-600">
                      * {errorEratKerja}
                    </Overline>
                  )}
                </Column>
                <Column className="gap-1.5">
                  <Text className="font-manrope font-semibold">
                    Tingkat pendidikan apa yang paling tepat/sesuai untuk
                    pekerjaan anda saat ini?
                    <span className="text-red-500">*</span>
                  </Text>
                  <Card className="w-full p-0 overflow-hidden shadow-none">
                    <Column className="divide-y">
                      <RadioButton
                        action={200}
                        onChange={onChangeRadio}
                        id="f15-1"
                        name="f15"
                        value={1}
                        checked={currentPendSesuai === 1}
                        label="Setingkat Lebih Tinggi"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={200}
                        onChange={onChangeRadio}
                        id="f15-2"
                        name="f15"
                        value={2}
                        checked={currentPendSesuai === 2}
                        label="Tingkat yang Sama"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={200}
                        onChange={onChangeRadio}
                        id="f15-3"
                        name="f15"
                        value={3}
                        checked={currentPendSesuai === 3}
                        label="Setingkat Lebih Rendah"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                      <RadioButton
                        action={200}
                        onChange={onChangeRadio}
                        id="f15-4"
                        name="f15"
                        value={4}
                        checked={currentPendSesuai === 4}
                        label="Tidak Perlu Pendidikan Tinggi"
                        className="w-full pl-3 hover:bg-slate-50"
                      />
                    </Column>
                  </Card>
                  {errorPendSesuai !== null && (
                    <Overline className="text-red-600">
                      * {errorPendSesuai}
                    </Overline>
                  )}
                </Column>
              </Column>
            ) : (
              currentStatus === 4 && (
                <Column className={`gap-6`}>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Sumber Biaya
                      <span className="text-red-500">*</span>
                    </Text>
                    <SelectInput
                      action={50}
                      zindex="z-10"
                      initial={initialBiaya}
                      options={opsiBiaya}
                      onChange={onChangeSelect}
                      required
                    />
                    {errorBiaya !== null && (
                      <Overline className="text-red-600">
                        * {errorBiaya}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Perguruan Tinggi
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={80}
                      id="f18b"
                      type="text"
                      inputWidth="w-full"
                      className="rounded-md"
                      placeholder="Jawaban anda"
                      onChange={onChangeInput}
                      defaultValue={nmKampusInput}
                      maxLength={50}
                      required
                    />
                    {errorNmKampus !== null && (
                      <Overline className="text-red-600">
                        * {errorNmKampus}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Program Studi
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={90}
                      id="f18c"
                      type="text"
                      inputWidth="w-full"
                      className="rounded-md"
                      placeholder="Jawaban anda"
                      onChange={onChangeInput}
                      defaultValue={nmProdiInput}
                      maxLength={50}
                      required
                    />
                    {errorNmProdi !== null && (
                      <Overline className="text-red-600">
                        * {errorNmProdi}
                      </Overline>
                    )}
                  </Column>
                  <Column className="gap-1.5">
                    <Text className="font-manrope font-semibold">
                      Tanggal Masuk
                      <span className="text-red-500">*</span>
                    </Text>
                    <OutlineInput
                      action={100}
                      id="f18d"
                      type="date"
                      inputWidth="w-full"
                      className="rounded-md appearance-none h-11"
                      placeholder="dd/mm/yyyy"
                      onChange={onChangeInput}
                      defaultValue={tglKuliahInput}
                      required
                    />
                    {errorTglKuliah !== null && (
                      <Overline className="text-red-600">
                        * {errorTglKuliah}
                      </Overline>
                    )}
                  </Column>
                </Column>
              )
            )}
            <Column className={`${currentStatus === 4 ? "gap-3" : "gap-1.5"}`}>
              <Column className="gap-1.5">
                <Text className="font-manrope font-semibold">
                  Sebutkan sumberdana dalam pembiayaan kuliah?
                  <span className="text-red-500">*</span>
                </Text>
                {currentStatus === 4 && (
                  <Overline className="text-indigo-700">
                    Bukan sumberdana saat anda Studi Lanjut
                  </Overline>
                )}
              </Column>
              <Card className="w-full p-0 overflow-hidden shadow-none">
                <Column className="divide-y">
                  <RadioButton
                    action={20}
                    onChange={onChangeRadio}
                    id="f1201-1"
                    name="f1201"
                    value={1}
                    checked={currentSumDana === 1}
                    label="Biaya Sendiri / Keluarga"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={20}
                    onChange={onChangeRadio}
                    id="f1201-2"
                    name="f1201"
                    value={2}
                    checked={currentSumDana === 2}
                    label="Beasiswa ADIK"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={20}
                    onChange={onChangeRadio}
                    id="f1201-3"
                    name="f1201"
                    value={3}
                    checked={currentSumDana === 3}
                    label="Beasiswa BIDIKMISI"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={20}
                    onChange={onChangeRadio}
                    id="f1201-4"
                    name="f1201"
                    value={4}
                    checked={currentSumDana === 4}
                    label="Beasiswa PPA"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={20}
                    onChange={onChangeRadio}
                    id="f1201-5"
                    name="f1201"
                    value={5}
                    checked={currentSumDana === 5}
                    label="Beasiswa AFIRMASI"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={20}
                    onChange={onChangeRadio}
                    id="f1201-6"
                    name="f1201"
                    value={6}
                    checked={currentSumDana === 6}
                    label="Beasiswa Perusahaan/Swasta"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <Column>
                    <RadioButton
                      action={20}
                      onChange={onChangeRadio}
                      id="f1201-7"
                      name="f1201"
                      value={7}
                      checked={currentSumDana === 7}
                      label="Lainnya"
                      className="w-full pl-3 hover:bg-slate-50"
                    />
                    <div
                      className={`p-4 border-t ${
                        currentSumDana === 7 ? "flex" : "hidden"
                      }`}
                    >
                      <OutlineInput
                        refs={refSDanaLain}
                        action={110}
                        id="f1202"
                        type="text"
                        inputWidth="w-full"
                        className="rounded-md"
                        label="Tuliskan lainnya"
                        labelClassName="text-gray-800"
                        placeholder="Jawaban anda"
                        onChange={onChangeInput}
                        defaultValue={lainSumDana}
                        maxLength={50}
                        required={currentSumDana === 7}
                      />
                    </div>
                  </Column>
                </Column>
              </Card>
              {errorSumDana !== null && (
                <Overline className="text-red-600">* {errorSumDana}</Overline>
              )}
            </Column>
            <Column className="gap-1.5">
              <Text className="font-manrope font-semibold">
                Pada saat lulus, pada tingkat mana kompetensi di bawah ini anda
                kuasai?
                <span className="text-red-500">*</span>
              </Text>
              <Row className="gap-3">
                <Overline className="text-indigo-700">
                  1 = Sangat Rendah
                </Overline>
                <TbArrowsHorizontal className="h-4 w-4 text-indigo-600" />
                <Overline className="text-indigo-700">
                  5 = Sangat Tinggi
                </Overline>
              </Row>
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Etika
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={30}
                    onChange={onChangeRadio}
                    id="f1761-1"
                    name="f1761"
                    value={1}
                    checked={currentEtikaLulus === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={30}
                    onChange={onChangeRadio}
                    id="f1761-2"
                    name="f1761"
                    value={2}
                    checked={currentEtikaLulus === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={30}
                    onChange={onChangeRadio}
                    id="f1761-3"
                    name="f1761"
                    value={3}
                    checked={currentEtikaLulus === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={30}
                    onChange={onChangeRadio}
                    id="f1761-4"
                    name="f1761"
                    value={4}
                    checked={currentEtikaLulus === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={30}
                    onChange={onChangeRadio}
                    id="f1761-5"
                    name="f1761"
                    value={5}
                    checked={currentEtikaLulus === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorEtikaLulus !== null && (
                <Overline className="text-red-600">
                  * {errorEtikaLulus}
                </Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Keahlian berdasarkan bidang ilmu
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={40}
                    onChange={onChangeRadio}
                    id="f1763-1"
                    name="f1763"
                    value={1}
                    checked={currentBidIlmuLulus === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={40}
                    onChange={onChangeRadio}
                    id="f1763-2"
                    name="f1763"
                    value={2}
                    checked={currentBidIlmuLulus === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={40}
                    onChange={onChangeRadio}
                    id="f1763-3"
                    name="f1763"
                    value={3}
                    checked={currentBidIlmuLulus === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={40}
                    onChange={onChangeRadio}
                    id="f1763-4"
                    name="f1763"
                    value={4}
                    checked={currentBidIlmuLulus === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={40}
                    onChange={onChangeRadio}
                    id="f1763-5"
                    name="f1763"
                    value={5}
                    checked={currentBidIlmuLulus === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorBidIlmuLulus !== null && (
                <Overline className="text-red-600">
                  * {errorBidIlmuLulus}
                </Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Bahasa Inggris
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={50}
                    onChange={onChangeRadio}
                    id="f1765-1"
                    name="f1765"
                    value={1}
                    checked={currentBIngLulus === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={50}
                    onChange={onChangeRadio}
                    id="f1765-2"
                    name="f1765"
                    value={2}
                    checked={currentBIngLulus === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={50}
                    onChange={onChangeRadio}
                    id="f1765-3"
                    name="f1765"
                    value={3}
                    checked={currentBIngLulus === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={50}
                    onChange={onChangeRadio}
                    id="f1765-4"
                    name="f1765"
                    value={4}
                    checked={currentBIngLulus === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={50}
                    onChange={onChangeRadio}
                    id="f1765-5"
                    name="f1765"
                    value={5}
                    checked={currentBIngLulus === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorBIngLulus !== null && (
                <Overline className="text-red-600">* {errorBIngLulus}</Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Penggunaan Teknologi Informasi
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={60}
                    onChange={onChangeRadio}
                    id="f1767-1"
                    name="f1767"
                    value={1}
                    checked={currentPTILulus === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={60}
                    onChange={onChangeRadio}
                    id="f1767-2"
                    name="f1767"
                    value={2}
                    checked={currentPTILulus === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={60}
                    onChange={onChangeRadio}
                    id="f1767-3"
                    name="f1767"
                    value={3}
                    checked={currentPTILulus === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={60}
                    onChange={onChangeRadio}
                    id="f1767-4"
                    name="f1767"
                    value={4}
                    checked={currentPTILulus === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={60}
                    onChange={onChangeRadio}
                    id="f1767-5"
                    name="f1767"
                    value={5}
                    checked={currentPTILulus === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorPTILulus !== null && (
                <Overline className="text-red-600">* {errorPTILulus}</Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Komunikasi
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={70}
                    onChange={onChangeRadio}
                    id="f1769-1"
                    name="f1769"
                    value={1}
                    checked={currentKomkasiLulus === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={70}
                    onChange={onChangeRadio}
                    id="f1769-2"
                    name="f1769"
                    value={2}
                    checked={currentKomkasiLulus === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={70}
                    onChange={onChangeRadio}
                    id="f1769-3"
                    name="f1769"
                    value={3}
                    checked={currentKomkasiLulus === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={70}
                    onChange={onChangeRadio}
                    id="f1769-4"
                    name="f1769"
                    value={4}
                    checked={currentKomkasiLulus === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={70}
                    onChange={onChangeRadio}
                    id="f1769-5"
                    name="f1769"
                    value={5}
                    checked={currentKomkasiLulus === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorKomkasiLulus !== null && (
                <Overline className="text-red-600">
                  * {errorKomkasiLulus}
                </Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Kerja sama tim
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={80}
                    onChange={onChangeRadio}
                    id="f1771-1"
                    name="f1771"
                    value={1}
                    checked={currentKTimLulus === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={80}
                    onChange={onChangeRadio}
                    id="f1771-2"
                    name="f1771"
                    value={2}
                    checked={currentKTimLulus === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={80}
                    onChange={onChangeRadio}
                    id="f1771-3"
                    name="f1771"
                    value={3}
                    checked={currentKTimLulus === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={80}
                    onChange={onChangeRadio}
                    id="f1771-4"
                    name="f1771"
                    value={4}
                    checked={currentKTimLulus === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={80}
                    onChange={onChangeRadio}
                    id="f1771-5"
                    name="f1771"
                    value={5}
                    checked={currentKTimLulus === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorKTimLulus !== null && (
                <Overline className="text-red-600">* {errorKTimLulus}</Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Pengembangan Diri
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={90}
                    onChange={onChangeRadio}
                    id="f1773-1"
                    name="f1773"
                    value={1}
                    checked={currentPDLulus === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={90}
                    onChange={onChangeRadio}
                    id="f1773-2"
                    name="f1773"
                    value={2}
                    checked={currentPDLulus === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={90}
                    onChange={onChangeRadio}
                    id="f1773-3"
                    name="f1773"
                    value={3}
                    checked={currentPDLulus === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={90}
                    onChange={onChangeRadio}
                    id="f1773-4"
                    name="f1773"
                    value={4}
                    checked={currentPDLulus === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={90}
                    onChange={onChangeRadio}
                    id="f1773-5"
                    name="f1773"
                    value={5}
                    checked={currentPDLulus === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorPDLulus !== null && (
                <Overline className="text-red-600">* {errorPDLulus}</Overline>
              )}
            </Column>
            <Column className="gap-1.5">
              <Text className="font-manrope font-semibold">
                Pada saat ini, pada tingkat mana kompetensi di bawah ini
                diperlukan dalam pekerjaan?
                <span className="text-red-500">*</span>
              </Text>
              <Row className="gap-3">
                <Overline className="text-indigo-700">
                  1 = Sangat Rendah
                </Overline>
                <TbArrowsHorizontal className="h-4 w-4 text-indigo-600" />
                <Overline className="text-indigo-700">
                  5 = Sangat Tinggi
                </Overline>
              </Row>
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Etika
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={100}
                    onChange={onChangeRadio}
                    id="f1762-1"
                    name="f1762"
                    value={1}
                    checked={currentEtikaKerja === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={100}
                    onChange={onChangeRadio}
                    id="f1762-2"
                    name="f1762"
                    value={2}
                    checked={currentEtikaKerja === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={100}
                    onChange={onChangeRadio}
                    id="f1762-3"
                    name="f1762"
                    value={3}
                    checked={currentEtikaKerja === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={100}
                    onChange={onChangeRadio}
                    id="f1762-4"
                    name="f1762"
                    value={4}
                    checked={currentEtikaKerja === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={100}
                    onChange={onChangeRadio}
                    id="f1762-5"
                    name="f1762"
                    value={5}
                    checked={currentEtikaKerja === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorEtikaKerja !== null && (
                <Overline className="text-red-600">
                  * {errorEtikaKerja}
                </Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Keahlian berdasarkan bidang ilmu
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={110}
                    onChange={onChangeRadio}
                    id="f1764-1"
                    name="f1764"
                    value={1}
                    checked={currentBidIlmuKerja === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={110}
                    onChange={onChangeRadio}
                    id="f1764-2"
                    name="f1764"
                    value={2}
                    checked={currentBidIlmuKerja === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={110}
                    onChange={onChangeRadio}
                    id="f1764-3"
                    name="f1764"
                    value={3}
                    checked={currentBidIlmuKerja === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={110}
                    onChange={onChangeRadio}
                    id="f1764-4"
                    name="f1764"
                    value={4}
                    checked={currentBidIlmuKerja === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={110}
                    onChange={onChangeRadio}
                    id="f1764-5"
                    name="f1764"
                    value={5}
                    checked={currentBidIlmuKerja === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorBidIlmuKerja !== null && (
                <Overline className="text-red-600">
                  * {errorBidIlmuKerja}
                </Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Bahasa Inggris
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={120}
                    onChange={onChangeRadio}
                    id="f1766-1"
                    name="f1766"
                    value={1}
                    checked={currentBIngKerja === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={120}
                    onChange={onChangeRadio}
                    id="f1766-2"
                    name="f1766"
                    value={2}
                    checked={currentBIngKerja === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={120}
                    onChange={onChangeRadio}
                    id="f1766-3"
                    name="f1766"
                    value={3}
                    checked={currentBIngKerja === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={120}
                    onChange={onChangeRadio}
                    id="f1766-4"
                    name="f1766"
                    value={4}
                    checked={currentBIngKerja === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={120}
                    onChange={onChangeRadio}
                    id="f1766-5"
                    name="f1766"
                    value={5}
                    checked={currentBIngKerja === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorBIngKerja !== null && (
                <Overline className="text-red-600">* {errorBIngKerja}</Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Penggunaan Teknologi Informasi
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={130}
                    onChange={onChangeRadio}
                    id="f1768-1"
                    name="f1768"
                    value={1}
                    checked={currentPTIKerja === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={130}
                    onChange={onChangeRadio}
                    id="f1768-2"
                    name="f1768"
                    value={2}
                    checked={currentPTIKerja === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={130}
                    onChange={onChangeRadio}
                    id="f1768-3"
                    name="f1768"
                    value={3}
                    checked={currentPTIKerja === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={130}
                    onChange={onChangeRadio}
                    id="f1768-4"
                    name="f1768"
                    value={4}
                    checked={currentPTIKerja === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={130}
                    onChange={onChangeRadio}
                    id="f1768-5"
                    name="f1768"
                    value={5}
                    checked={currentPTIKerja === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorPTIKerja !== null && (
                <Overline className="text-red-600">* {errorPTIKerja}</Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Komunikasi
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={140}
                    onChange={onChangeRadio}
                    id="f1770-1"
                    name="f1770"
                    value={1}
                    checked={currentKomkasiKerja === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={140}
                    onChange={onChangeRadio}
                    id="f1770-2"
                    name="f1770"
                    value={2}
                    checked={currentKomkasiKerja === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={140}
                    onChange={onChangeRadio}
                    id="f1770-3"
                    name="f1770"
                    value={3}
                    checked={currentKomkasiKerja === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={140}
                    onChange={onChangeRadio}
                    id="f1770-4"
                    name="f1770"
                    value={4}
                    checked={currentKomkasiKerja === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={140}
                    onChange={onChangeRadio}
                    id="f1770-5"
                    name="f1770"
                    value={5}
                    checked={currentKomkasiKerja === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorKomkasiKerja !== null && (
                <Overline className="text-red-600">
                  * {errorKomkasiKerja}
                </Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Kerja sama tim
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={150}
                    onChange={onChangeRadio}
                    id="f1772-1"
                    name="f1772"
                    value={1}
                    checked={currentKTimKerja === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={150}
                    onChange={onChangeRadio}
                    id="f1772-2"
                    name="f1772"
                    value={2}
                    checked={currentKTimKerja === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={150}
                    onChange={onChangeRadio}
                    id="f1772-3"
                    name="f1772"
                    value={3}
                    checked={currentKTimKerja === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={150}
                    onChange={onChangeRadio}
                    id="f1772-4"
                    name="f1772"
                    value={4}
                    checked={currentKTimKerja === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={150}
                    onChange={onChangeRadio}
                    id="f1772-5"
                    name="f1772"
                    value={5}
                    checked={currentKTimKerja === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorKTimKerja !== null && (
                <Overline className="text-red-600">* {errorKTimKerja}</Overline>
              )}
              <Row className="column mt-1.5 gap-y-1 sm:row sm:border sm:rounded-lg sm:overflow-hidden sm:pl-4">
                <Caption className="w-full my-auto text-gray-800">
                  Pengembangan Diri
                </Caption>
                <Row className="border rounded-lg overflow-hidden sm:border-none sm:rounded-none sm:overflow-auto">
                  <RadioButton
                    action={160}
                    onChange={onChangeRadio}
                    id="f1774-1"
                    name="f1774"
                    value={1}
                    checked={currentPDKerja === 1}
                    label="1"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={160}
                    onChange={onChangeRadio}
                    id="f1774-2"
                    name="f1774"
                    value={2}
                    checked={currentPDKerja === 2}
                    label="2"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={160}
                    onChange={onChangeRadio}
                    id="f1774-3"
                    name="f1774"
                    value={3}
                    checked={currentPDKerja === 3}
                    label="3"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={160}
                    onChange={onChangeRadio}
                    id="f1774-4"
                    name="f1774"
                    value={4}
                    checked={currentPDKerja === 4}
                    label="4"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                  <RadioButton
                    action={160}
                    onChange={onChangeRadio}
                    id="f1774-5"
                    name="f1774"
                    value={5}
                    checked={currentPDKerja === 5}
                    label="5"
                    className="w-full pl-3 hover:bg-slate-50"
                  />
                </Row>
              </Row>
              {errorPDKerja !== null && (
                <Overline className="text-red-600">* {errorPDKerja}</Overline>
              )}
            </Column>
          </Column>
        </Column>
      </Card>
      {/* <AlertDialog show={}>alert</AlertDialog> */}
    </>
  );
};

export default WAlmKueDua;
