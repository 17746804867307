import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { useRecoilState } from "recoil";
import { gEvent } from "../atoms";
import ListEvent from "../components/list/list_event";
import SAlmRecommendation from "../components/shimmer/salm_recommendation";
import { getEventController } from "../controllers/global_controllers";
import {
  ButtonHover,
  Column,
  Headline2,
  Headline3,
  HorizontalDivider,
  PrimaryButton,
  ShareFBButton,
  ShareTelegramButton,
  ShareWAButton,
  ShareXButton,
  Text,
  TextButton,
} from "../shared/ui";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const ListRecognitionRecommendation = lazy(() =>
  import("../components/list/list_recognition_recommendation")
);

function Carousel() {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [tumbnail, setTumbnail] = useState(null);
  const videoId = "qKo0Jlao5ec";
  // useEffect(() => {
  //   const getVideoYoutube = async () => {
  //     try {
  //       const API_KEY = "AIzaSyD_ZshZw7_x6qD14WsvBAOXelDOIjwxpMA";
  //       const response = await axios.get(
  //         `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet`
  //       );
  //       setTitle(response.data.items[0]?.snippet?.title);
  //       setDescription(response.data.items[0]?.snippet?.description);
  //       setTumbnail(response.data.items[0]?.snippet?.thumbnails?.maxres?.url);
  //     } catch (error) {
  //       console.error("Error fetching video title:", error);
  //       setTitle("Failed to load title");
  //     }
  //   };

  //   getVideoYoutube();
  // }, [videoId]);
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      pagination={{ clickable: true }}
      loop={true}
      spaceBetween={20}
      className="w-full max-w-8xl mx-auto gap-x-5 gap-y-8 xl:flex-row mt-20 lg:mt-32 px-4 lg:px-0"
      autoplay={{
        delay: 100000,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <Column className="grid lg:grid-cols-5 p-12 bg-white border">
          <Column className="lg:col-span-3 lg:pr-20 space-y-6 order-last lg:order-first mt-6 lg:mt-0 h-64 lg:h-full">
            <Column className="space-y-1 text-center lg:text-left">
              <Headline3>{title}</Headline3>
              <Text className="line-clamp-3">{description}</Text>
            </Column>
            <Column className="items-center lg:items-start">
              <ButtonHover>
                <Link to="https://pmb.uty.ac.id/" target="_blank">
                  <PrimaryButton
                    color="orange"
                    className="w-full py-3 rounded-full"
                  >
                    Daftar
                  </PrimaryButton>
                </Link>
              </ButtonHover>
            </Column>
          </Column>
          <Column className="lg:col-span-2 items-center lg:items-end">
            <iframe
              className="h-48 w-72 md:w-96 rounded-xl"
              src={`https://www.youtube.com/embed/${videoId}?playlist=qKo0Jlao5ec&loop=1`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameborder="0"
              name="iframe_video1"
            ></iframe>{" "}
          </Column>
        </Column>
      </SwiperSlide>
      <SwiperSlide>
        <Column className="grid lg:grid-cols-5 p-12 bg-white border">
          <Column className="lg:col-span-3 lg:pr-20 space-y-6 order-last lg:order-first mt-6 lg:mt-0 h-64 lg:h-full">
            <Column className="space-y-1 text-center lg:text-left">
              <Headline3>{title}</Headline3>
              <Text className="line-clamp-3">{description}</Text>
            </Column>
            <Column className="justify-center lg:justify-start items-center lg:items-start flex-row gap-x-4">
              <ButtonHover>
                <Link to="https://pmb.uty.ac.id/" target="_blank">
                  <PrimaryButton
                    color="orange"
                    className="w-full py-3 rounded-full"
                  >
                    Daftar
                  </PrimaryButton>
                </Link>
              </ButtonHover>
              <ButtonHover>
                <Link to="https://pmb.uty.ac.id/" target="_blank">
                  <PrimaryButton
                    color="blue"
                    className="w-full py-3 rounded-full"
                  >
                    Info
                  </PrimaryButton>
                </Link>
              </ButtonHover>
            </Column>
          </Column>
          <Column className="lg:col-span-2 items-center lg:items-end">
            <iframe
              className="h-48 w-72 md:w-96 rounded-xl"
              src={`https://www.youtube.com/embed/${videoId}?playlist=qKo0Jlao5ec&loop=1`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameborder="0"
              name="iframe_video1"
            ></iframe>{" "}
          </Column>
        </Column>
      </SwiperSlide>
      <SwiperSlide>
        <Column className="grid lg:grid-cols-5 p-12 bg-white border">
          <Column className="lg:col-span-3 lg:pr-20 space-y-6 order-last lg:order-first mt-6 lg:mt-0 h-64 lg:h-full">
            <Column className="space-y-1 text-center lg:text-left">
              <Headline3>{title}</Headline3>
              <Text className="line-clamp-3">{description}</Text>
            </Column>
          </Column>
          <Column className="lg:col-span-2 items-center lg:items-end">
            <iframe
              className="h-48 w-72 md:w-96 rounded-xl"
              src={`https://www.youtube.com/embed/${videoId}?playlist=qKo0Jlao5ec&loop=1`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameborder="0"
              name="iframe_video1"
            ></iframe>{" "}
          </Column>
        </Column>
      </SwiperSlide>
    </Swiper>
  );
}

export default function EventPage() {
  const result = useLoaderData();
  const [listEvent, setListEvent] = useRecoilState(gEvent);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const loaderRef = useRef(null);

  useEffect(() => {
    if (result.status) {
      setListEvent(result.data);
      setTotalPage(result.totalPage);
    }
  }, [result]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && page < totalPage && !loading) {
          setPage((prev) => prev + 1);
        }
      },
      { threshold: 1 }
    );
    if (loaderRef.current) observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, [page, totalPage, loading]);

  useEffect(() => {
    if (page > 1) {
      fetchMoreEvents();
    }
  }, [page]);

  const fetchMoreEvents = async () => {
    setLoading(true);
    try {
      const newResult = await getEvent({ page, limit: 10 });
      if (newResult?.status) {
        setListEvent((prev) => [...prev, ...newResult.data]);
      }
    } catch (error) {
      console.error("error fetching event");
    }
    setLoading(false);
  };

  return (
    <>
      {/* <Carousel /> */}

      <Column
        className={
          "w-full max-w-8xl mx-auto gap-x-5 gap-y-8 pt-20 pb-16 sm:pt-32 xl:flex-row"
        }
      >
        <Column
          className={
            "w-fit hidden fixed gap-x-3 gap-y-3 lg:flex lg:left-10 lg:top-3 xl:sticky"
          }
        >
          <ShareFBButton location={window.location.href} />
          <ShareXButton location={window.location.href} />
          <ShareWAButton location={window.location.href} />
          <ShareTelegramButton location={window.location.href} />
        </Column>
        <Column
          className={
            "w-full gap-y-5 px-4 max-w-4xl mx-auto sm:px-14 sm:gap-y-8"
          }
        >
          <ListEvent events={listEvent} />
          <div ref={loaderRef}>
            {loading ? (
              <Column className="w-full justify-center items-center">
                <ButtonHover>
                  <TextButton className="rounded-full" color="indigo">
                    Memuat Event
                  </TextButton>
                </ButtonHover>
              </Column>
            ) : page >= totalPage ? (
              <Column>
                <ButtonHover>
                  <PrimaryButton disabled className="rounded-full">
                    Tidak Ada Lagi Event
                  </PrimaryButton>
                </ButtonHover>
              </Column>
            ) : null}
          </div>
        </Column>

        <HorizontalDivider className={"my-4 xl:hidden"} />
        <Column
          className={
            "w-full max-w-4xl mx-auto sm:px-14 xl:px-0 xl:max-w-sm xl:sticky xl:top-32"
          }
        >
          <Suspense fallback={<SAlmRecommendation />}>
            <ListRecognitionRecommendation />
          </Suspense>
        </Column>
      </Column>
    </>
  );
}

export const getEvent = async () => {
  const props = {
    page: 1,
    limit: 10,
  };
  const result = await getEventController(props);
  return result;
};
