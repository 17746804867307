import HTMLParse from "html-react-parser";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableRejecetedRecognition from "./rows/row_rejected_recognition";
import {
  AlertDialog,
  Avatar,
  Caption,
  Column,
  IconButton,
  LoadingDialog,
  PrimaryButton,
  Row,
  SecondaryButton,
  ShimmerAnimation,
  ShimmerItem,
  Subtitle,
  Text,
  Title,
} from "../../../../shared/ui";
import WListImageRecognition from "../../../../components/wlist_image_recognition";
import { TbReload, TbTrash, TbX } from "react-icons/tb";
import WGridImageRecognition from "../../../../components/wgrid_image_recognition";
import toast from "react-hot-toast";
import {
  deleteRecognition,
  getFullNameUser,
  undoRejectRecognition,
} from "../../../../controllers/apicontrollers";

function TableRejectedRecognition({
  listRecognition,
  setListRecognition,
  page,
  limit,
  onShort,
  action = 10,
}) {
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [tags, setTags] = useState([]);

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const onHandleClick = (recognition) => {
    setShowDialog(true);
    setRecognition(recognition);
    setTags(recognition.tags);
  };

  console.log("tags :", tags);

  const closeDialog = () => {
    setShowDialog();
  };

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  const openDeleteDialog = () => {
    setShowDialog(false);
    setShowDeleteDialog(true);
  };

  const filterRejectRecognition = () => {
    const newRejectRec = listRecognition.filter(
      (rec) => rec.id_rekognisi !== recognition.id_rekognisi
    );
    setListRecognition(newRejectRec);
  };

  const deleteCurrentRecognition = async () => {
    setShowDeleteDialog(false);
    setLoading(true);
    const result = await deleteRecognition(recognition.id_rekognisi);
    if (result.status) {
      setTimeout(() => {
        filterRejectRecognition();
        setLoading(false);
        toast.success("Hapus rekognisi berhasil");
      }, 200);
    }
  };

  const undoRejectCurrentRecognition = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await undoRejectRecognition(recognition.id_rekognisi);
    if (result.status) {
      setTimeout(() => {
        filterRejectRecognition();
        setLoading(false);
        toast.success("Pembatalan reject rekognisi berhasil");
        return true;
      }, 200);
    } else {
      return false;
    }
  };

  const getFullNameOfUser = async () => {
    const result = await getFullNameUser(
      recognition.id_identify,
      recognition.id_user
    );
    if (result.status) {
      setFullName(result.data);
    }
  };

  useEffect(() => {
    getFullNameOfUser();
  }, [recognition]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Penulis</TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="title"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Judul Berita
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="created_at"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Pengajuan
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="updated_at"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Diproses
            </TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listRecognition.length > 0 ? (
            listRecognition.map((recognition, idx) => {
              return (
                <RowTableRejecetedRecognition
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  recognition={recognition}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog className="max-w-lg" show={showDialog} onClose={closeDialog}>
        <Column className="divide-y">
          {recognition !== null && (
            <Column className="max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
              <Row className="gap-2.5 px-6 pt-6 pb-5 sticky top-0 bg-white z-10">
                <Avatar src={recognition.avatar} />
                <Column className="flex-1">
                  {recognition && recognition.id_user ? (
                    <Caption className="font-bold font-manrope text-gray-800">
                      {fullName}
                    </Caption>
                  ) : (
                    <ShimmerAnimation>
                      <ShimmerItem className={"w-32 h-4"} />
                    </ShimmerAnimation>
                  )}

                  <Caption>{recognition.id_user}</Caption>
                </Column>
              </Row>
              <Column className="gap-3 px-6 pb-10">
                {recognition.images.length > 0 ? (
                  recognition.view === "list" ? (
                    <WListImageRecognition
                      images={recognition.images}
                      aspectRatio={recognition.rasio}
                      action={20}
                    />
                  ) : (
                    <WGridImageRecognition
                      images={recognition.images}
                      aspectRatio={recognition.rasio}
                      action={20}
                    />
                  )
                ) : null}
                <Subtitle className="font-semibold">
                  {recognition.slug}
                </Subtitle>
                <Column className="text-base inter">
                  {HTMLParse(recognition.body, options)}
                </Column>
                {recognition !== null ? (
                  <Caption className="w-fit font-bold jakarta text-gray-800">
                    <span className="font-normal inter">Diposting oleh </span>
                    <span>{fullName}</span>
                    {recognition.tags.length > 0 && (
                      <span className="font-normal inter"> bersama </span>
                    )}
                    {recognition.tags.length > 0 &&
                      tags.map(
                        (tag, idx) =>
                          idx === 0 && <span key={idx}>{tag.nama}</span>
                      )}
                    {recognition.tags.length > 1 && (
                      <span className="font-normal inter">{" dan "}</span>
                    )}

                    {recognition.tags.length > 1 && (
                      <span>{recognition.tags.length - 1} lainnya</span>
                    )}
                  </Caption>
                ) : (
                  <ShimmerAnimation>
                    <ShimmerItem className="h-4 w-32 mb-1" />
                  </ShimmerAnimation>
                )}
              </Column>
            </Column>
          )}
          <Row className="gap-3 justify-end px-6 py-5">
            <SecondaryButton className="w-fit pr-5" onClick={closeDialog}>
              <TbX className="h-5 w-5" /> Tutup
            </SecondaryButton>
            {action === 10 ? (
              <PrimaryButton
                className="w-fit pr-5"
                onClick={undoRejectCurrentRecognition}
                color="indigo"
              >
                <TbReload className="h-5 w-5" /> Proses Ulang
              </PrimaryButton>
            ) : (
              <PrimaryButton
                className="w-fit pr-5 flex-shrink-0"
                color="red"
                onClick={openDeleteDialog}
              >
                <TbTrash className="h-5 w-5" /> Hapus Rekognisi
              </PrimaryButton>
            )}
          </Row>
        </Column>
        <IconButton
          onClick={closeDialog}
          className="absolute top-1 right-1 z-10"
        >
          <TbX className="h-5 w-5" />
        </IconButton>
      </AlertDialog>
      <AlertDialog show={showDeleteDialog} onClose={closeDeleteDialog}>
        <Column className="divide-y">
          <Column className="gap-5 p-6">
            <Column className="gap-1">
              <Title className="font-bold">Hapus Rekognisi</Title>
              {recognition !== null && (
                <Text>
                  Apakah anda yakin ingin menghapus rekognisi{" "}
                  <span className="font-medium text-indigo-700">
                    {recognition.title}
                  </span>
                  ?
                </Text>
              )}
            </Column>
          </Column>
          <Row className="gap-3 justify-end px-6 py-5">
            <SecondaryButton className="w-fit pr-5" onClick={closeDeleteDialog}>
              <TbX className="h-5 w-5" /> Batal
            </SecondaryButton>
            <PrimaryButton
              className="w-fit pr-5"
              color="red"
              onClick={deleteCurrentRecognition}
            >
              <TbTrash className="h-5 w-5" /> Ya, Hapus
            </PrimaryButton>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
}

export default TableRejectedRecognition;
