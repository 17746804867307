import moment from "moment"

export const convertDateToString = ({ date, format = 'YYYY-MM-DD HH:mm:ss', utc = false }) => {
    return moment(date).utc(utc).format(format)
}

export const getViewDisplay = (view) => {
    let result = 0
    if (view >= 1000000) {
        result = `${(view / 1000000).toFixed(2)}M`
    } else if (view >= 1000) {
        result = `${(view / 1000).toFixed(2)}K`
    } else {
        result = view
    }
    return result
}