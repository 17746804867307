export const Card = ({ id, refId, children, className = null, onClick = null }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`card-new ${className}`}
            onClick={onClick}>
            {children}
        </div>
    )
}