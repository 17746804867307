import { useState } from 'react'
import toast from 'react-hot-toast'
import { TbMessageCheck, TbMessageX, TbX } from 'react-icons/tb'
import { Column, Row } from '../../../../components/interfaces/block'
import { OutlineTextArea } from '../../../../components/interfaces/input'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import { BodyText, Headline3Text } from '../../../../components/interfaces/text'
import { approveConsultationController } from '../../../../controllers/admin_controllers'
import { AlertDialog, ButtonHover, LoadingDialog, PrimaryButton, TertiaryButton } from '../../../../shared/ui'
import RowTableProcessedConsultation from './rows/row_processed_consultation'

function TableProcessedConsultation({ listConsultation, page, limit, onShort, onRefresh }) {

  const [currentConsultation, setCurrentConsultation] = useState(null)
  const [currentAction, setCurrentAction] = useState(10)
  const [currentSort, setCurrentSort] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState(null)

  const onHandleClick = ({ action, consultation }) => {
    setCurrentAction(action)
    setCurrentConsultation(consultation)
    setReason(null)
    if (action !== 10) {
      setShowDialog(true)
    }
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  const onHandleReason = (event) => {
    event.preventDefault()
    setReason(event.target.value.length > 0 ? event.target.value : null)
  }

  const approveConsultation = async () => {
    setShowDialog(false)
    setLoading(true)
    const result = await approveConsultationController({
      id: currentConsultation.id_konsultasi,
      approve: currentAction === 20 ? 'ACCEPTED' : 'REJECTED',
      reason: currentAction === 20 ? null : reason,
    })
    if (result.status) {
      setTimeout(() => {
        onRefresh()
        setLoading(false)
        toast.success(result.message)
      }, 200)
    } else {
      setTimeout(() => {
        setLoading(false)
        toast.error(result.message)
      }, 200)
    }
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              No
            </TableHeadCell>
            <TableHeadCell>
              Alumni
            </TableHeadCell>
            <TableHeadCell>
              Topik
            </TableHeadCell>
            <TableHeadCell>
              Subjek
            </TableHeadCell>
            <TableHeadCell>
              Pengajuan
            </TableHeadCell>
            <TableHeadCell className={'text-center'}>
              Status
            </TableHeadCell>
            <TableHeadCell className={'text-center'}>
              Aksi
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listConsultation.length > 0 ? listConsultation.map((consultation, idx) => {
            return (
              <RowTableProcessedConsultation
                key={idx}
                idx={idx}
                page={page}
                limit={limit}
                consultation={consultation}
                handleClick={onHandleClick} />
            )
          }) : <TableRow>
            <TableCell colSpan={7} className='text-center text-gray-500'>
              No data to display
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
      <AlertDialog
        show={showDialog}
        className={currentAction === 20 ? 'max-w-md' : 'max-w-2xl'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3Text>
              {
                currentAction === 20 ? 'Verifikasi Konsultasi' :
                  'Tolak Konsultasi'
              }
            </Headline3Text>
            {currentConsultation !== null && <BodyText>
              Apakah anda yakin ingin {currentAction === 20 ? 'memverifikasi' : 'menolak'} pengajuan konsultasi dari <span className={'font-medium'}>{currentConsultation.nama_alumni}</span> dengan topik <span className={'font-medium'}>{currentConsultation.topik}</span>?
            </BodyText>}
          </Column>
          {currentAction === 30 && <OutlineTextArea
            label='Alasan ditolak'
            placeholder='Tuliskan alasan'
            helperText='Alasan konsultasi ditolak akan dikirimkan ke alumni melalui email'
            onChange={onHandleReason}
            initialRow={8}
            maxLength={1000}
            required />}
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color={currentAction === 20 ? 'indigo' : 'red'}
                className='pl-5 pr-6'
                leadingIcon={
                  currentAction === 20 ? <TbMessageCheck className='h-5 w-5 my-auto' /> :
                    <TbMessageX className='h-5 w-5 my-auto' />
                }
                onClick={approveConsultation}>
                {
                  currentAction === 20 ? 'Verifikasi' : 'Tolak'
                }
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Menghapus ploting topik...' />
    </>
  )
}

export default TableProcessedConsultation