import React, { useEffect, useState } from 'react'
import { TbChartDonut2, TbClipboardList, TbMessages, TbSchool } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { currentIndexTabTracer, masterFilterOvTracer } from '../../atoms'
import OvTracer from '../../components/overview/ov_tracer'
import TableRekapKuesionerTracer from '../../components/tabels/rekap_kuesioner_tracer'
import TableRekapWisudaTracer from '../../components/tabels/rekap_wisuda_tracer'
import TableSaranTracerStudi from '../../components/tabels/saran_tracer_studi'
import { getDataMasterFilterOvTracer } from '../../controllers/apicontrollers'
import { Column, Headline1, TabPanel, Tabs } from '../../shared/ui'

const AdmTracerPage = () => {

    const [listFilter, setListFilter] = useRecoilState(masterFilterOvTracer)
    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabTracer)
    const [showProgress, setShowProgress] = useState(true)

    const getMasterFilterOvTracer = async () => {
        const result = await getDataMasterFilterOvTracer()
        if (result.status) {
            const bulan = result.data.bulan
            const tahun = result.data.tahun
            const prodi = result.data.prodi
            const listBulan = []
            const listTahun = []
            const listProdi = []
            bulan.map((bulan) => {
                const b = {
                    label: bulan,
                    value: bulan
                }
                listBulan.unshift(b)
            })
            listBulan.unshift({ label: 'Semua', value: null })
            tahun.map((tahun) => {
                const t = {
                    label: tahun,
                    value: tahun
                }
                listTahun.unshift(t)
            })
            listTahun.unshift({ label: 'Semua Tahun', value: null })
            prodi.map((prodi) => {
                const p = {
                    label: prodi.label,
                    value: prodi.kode_prodi
                }
                listProdi.unshift(p)
            })
            listProdi.unshift({ label: 'Semua Program Studi', value: null })
            const filter = {
                bulan: listBulan,
                tahun: listTahun,
                prodi: listProdi
            }
            setListFilter(filter)
        }
    }

    const onChangeTabIndex = (index) => {
        setShowProgress(true)
        setCurrentTabIndex(index)
    }

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    useEffect(() => {
        getMasterFilterOvTracer()
    }, [])

    return (
        <Column className='gap-5'>
            <Headline1>
                Tracer Study
            </Headline1>
            <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                <TabPanel icon={<TbChartDonut2 className='h-5 w-5' />} title='Ringkasan'>
                    <OvTracer onDoneLoadData={onDoneLoadData} />
                </TabPanel>
                <TabPanel icon={<TbClipboardList className='h-5 w-5' />} title='Kuesioner'>
                    <TableRekapKuesionerTracer onDoneLoadData={onDoneLoadData} />
                </TabPanel>
                <TabPanel icon={<TbSchool className='h-5 w-5' />} title='Wisuda'>
                    <TableRekapWisudaTracer onDoneLoadData={onDoneLoadData} />
                </TabPanel>
                <TabPanel icon={<TbMessages className='h-5 w-5' />} title='Saran'>
                    <TableSaranTracerStudi onDoneLoadData={onDoneLoadData} />
                </TabPanel>
            </Tabs>
        </Column>
    )
}

export default AdmTracerPage
