import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { Column, GridCol, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { SelectInput } from '../../../components/interfaces/input'
import { BodyText, Headline2Text, LargeHeadlineText } from '../../../components/interfaces/text'
import { getWisudaTracerController } from '../../../controllers/admin_controllers'
import { getViewDisplay } from '../../../shared/lib'
import { setupMonth, setupTahun } from '../../../shared/ta'
import TableWisudaTracer from './tables/table_wisuda_tracer'

function AdmWisudaTracerPage() {

  const result = useLoaderData()

  const [listWisuda, setListWisuda] = useState([])
  const [oviewWisuda, setOviewWisuda] = useState([])
  const [currentMonth, setCurrentMonth] = useState({ label: 'Semua Bulan', value: 0 })
  const [currentYear, setCurrentYear] = useState({ label: `${new Date().getFullYear()}`, value: new Date().getFullYear() })
  const [listMonth, setListMonth] = useState([])
  const [listYear, setListYear] = useState([])
  const [limitRow, setLimitRow] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [stsGetLoader, setStsGetLoader] = useState(false)

  const getWisudaTracerFromLoader = () => {
    if (result.status) {
      setListWisuda(result.data)
    }
    setStsGetLoader(true)
  }

  const regetWisudaTracer = async () => {
    const result = await getWisudaTracerController({
      month: currentMonth.value,
      year: currentYear.value
    })
    if (result.status) {
      setListWisuda(result.data)
    }
  }

  const setupOview = () => {
    let jmlWisuda = 0;
    let jmlTracer = 0;
    listWisuda.map((wisuda) => {
      jmlWisuda += wisuda.lulus ?? 0
      jmlTracer += wisuda.isi_tracer ?? 0
    })
    const oview = [
      {
        label: 'Total Wisudawan',
        value: jmlWisuda,
      },
      {
        label: 'Total Mengisi Tracer',
        value: jmlTracer,
      },
      {
        label: 'Persentase Mengisi Tracer',
        value: jmlWisuda > 0 ? `${((jmlTracer / jmlWisuda) * 100).toFixed(2)}%` : '0%',
      },
    ]
    setOviewWisuda(oview)
  }

  const onChangeYear = (value) => {
    setCurrentYear(value)
  }

  const onChangeMonth = (value) => {
    setCurrentMonth(value)
  }

  useEffect(() => {
    setListYear(setupTahun({ start: 2017 }))
    setListMonth(setupMonth({ useAllMonth: true }))
    getWisudaTracerFromLoader()
  }, [])

  useEffect(() => {
    if (stsGetLoader) {
      setupOview()
    }
  }, [listWisuda])

  useEffect(() => {
    regetWisudaTracer()
  }, [currentYear, currentMonth])

  return (
    <Column className={'p-4 gap-y-5 sm:p-8'}>
      <Column className={'gap-y-2 flex-1'}>
        <Headline2Text className={'font-semibold'}>
          Data Wisuda
        </Headline2Text>
        <BodyText>
          Data jumlah wisudawan dan persentase pengisian tracer studi dari setiap program studi pada {currentMonth.value === 0 ? 'Tahun' : 'bulan ' + currentMonth.label} {currentYear.label}.
        </BodyText>
      </Column>
      <Card className={'w-full'}>
        <Column className={'gap-y-5'}>
          <Row className='w-full h-[2.64rem] gap-3'>
            <div>
              <SelectInput
                width='w-full sm:w-40'
                initial={currentMonth}
                options={listMonth}
                onChange={onChangeMonth}
                zindex='z-20' />
            </div>
            <div>
              <SelectInput
                width='w-full sm:w-24'
                initial={currentYear}
                options={listYear}
                onChange={onChangeYear}
                zindex='z-10' />
            </div>
          </Row>
          <GridCol className={'gap-5 md:grid-cols-3'}>
            {oviewWisuda.map((oview, idx) => {
              return (
                <Card key={idx} className={'w-full'}>
                  <Column className={'gap-5'}>
                    <BodyText className={'font-medium text-gray-500'}>
                      {oview.label}
                    </BodyText>
                    <LargeHeadlineText className='font-normal font-inter'>
                      {getViewDisplay(oview.value)}
                    </LargeHeadlineText>
                  </Column>
                </Card>
              )
            })}
          </GridCol>
          <TableWisudaTracer
            limit={limitRow}
            page={currentPage}
            oviewWisuda={oviewWisuda}
            listWisuda={listWisuda} />
        </Column>
      </Card>
    </Column>
  )
}

export default AdmWisudaTracerPage

export const getAdmWisudaTracer = async () => {
  const props = {
    month: 0,
    year: new Date().getFullYear(),
  }
  const result = await getWisudaTracerController(props)
  return result
}