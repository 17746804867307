import React from "react";
import { Column, GridCol, Row } from "../../../components/interfaces/block";
import { useEffect } from "react";
import { useState } from "react";
import {
  getAdmRekapPenilaianRespondenController,
  getAdmSebaranRespondenController,
  getFilterPenggunaLulusanController,
} from "../../../controllers/admin_controllers";
import { useLoaderData } from "react-router-dom";
import {
  CaptionText,
  Headline2Text,
  TitleText,
} from "../../../components/interfaces/text";
import { SelectInput } from "../../../components/interfaces/input";
import ChartGrafikResponden from "./charts/chart_sebaran_responden";
import ChartDiagramResponden from "./charts/chart_sebaran_responden_persen";
import { Card } from "../../../components/interfaces/card";
import ChartRekapitulasiPenilaianComponents from "./charts/chart_diagram_penilaian";
import ChartRekapitulasiPenilaianNilaiComponents from "./charts/chart_radar_diagram_penilaian";
import TablePenilaianRespondenComponents from "./tables/table_penilaian_responden";

function AdmOverviewPenggunaPage() {
  const result = useLoaderData();
  const [filterTahun, setFilterTahun] = useState([]);
  const [currentYear, setCurrentYear] = useState({
    label: "Semua tahun",
    value: null,
  });
  const [grafikResponden, setGrafikResponden] = useState([]);
  const [diagramResponden, setDiagramResponden] = useState([]);
  const [labelProdi, setLabelProdi] = useState([]);

  const [diagramPenilaian, setDiagramPenilaian] = useState([]);
  const [labelPenilaian, setLabelPenilaian] = useState([]);
  const [diagramPenilaian2, setDiagramPenilaian2] = useState([]);
  const [labelKategori, setLabelKategori] = useState([]);

  const getOverviewFromLoader = () => {
    if (result.filter.status === "fulfilled") {
      const res = result.filter.value;
      if (res.status) {
        const tahun = res.data.tahun;

        const listTahun = [];

        const currentYear = new Date().getFullYear();
        const haveCurrentYear = false;
        tahun.map((tahun) => {
          const t = {
            label: tahun,
            value: tahun,
          };
          listTahun.unshift(t);
          if (tahun === currentYear) {
            haveCurrentYear = true;
          }
        });
        listTahun.unshift({ label: "Semua tahun", value: null });
        setFilterTahun(listTahun);
      }
    }
    if (result.ovSebaranResponden.status === "fulfilled") {
      const res = result.ovSebaranResponden.value;
      if (res.status) {
        setGrafikResponden(res.data);
        setDiagramResponden(res.data_persen);
        setLabelProdi(res.label_prodi);
      }
    }
    if (result.ovRekapPenilaian.status === "fulfilled") {
      const res = result.ovRekapPenilaian.value;
      if (res.status) {
        setDiagramPenilaian(res.data_total);
        setDiagramPenilaian2(res.data_penilaian);
        setLabelPenilaian(res.label_penilaian);
        setLabelKategori(res.label_kategori);
      }
    }
  };

  const onChangeTahun = (value) => {
    setCurrentYear(value);
  };

  useEffect(() => {
    getOverviewFromLoader();
  }, []);
  return (
    <Column className={"p-4 gap-y-5 sm:p-8"}>
      <Headline2Text className={"font-semibold"}>
        Ringkasan Survei
      </Headline2Text>
      <div>
        <SelectInput
          width="w-full sm:w-40"
          initial={currentYear}
          options={filterTahun}
          onChange={onChangeTahun}
          btnClassName={"bg-white"}
          zindex="z-10"
        />
      </div>
      <GridCol className="gap-8">
        <ChartGrafikResponden
          year={currentYear}
          Labels={labelProdi}
          series={grafikResponden}
        />
        <ChartDiagramResponden
          year={currentYear}
          Labels={labelProdi}
          series={diagramResponden}
        />
        <Card className={"w-full h-full p-0 col-span-2"}>
          <Column>
            <Column className={"p-5"}>
              <TitleText>
                Rekapitulasi dan Grafik Hasil Penilaian Kepuasan Responden
              </TitleText>
              <Row>
                <CaptionText>
                  {currentYear.value != null && "Tahun "}
                  {currentYear.label}
                </CaptionText>
              </Row>
            </Column>
            <GridCol>
              <ChartRekapitulasiPenilaianComponents
                year={currentYear}
                Labels={labelPenilaian}
                series={diagramPenilaian}
              />
              <ChartRekapitulasiPenilaianNilaiComponents
                year={currentYear}
                Labels={labelPenilaian}
                series={diagramPenilaian2}
              />
            </GridCol>
            <div className='p-5'>
              <TablePenilaianRespondenComponents
              year={currentYear}
              />
            </div>
          </Column>
        </Card>
      </GridCol>
    </Column>
  );
}

export default AdmOverviewPenggunaPage;

export const getOvDataResponden = async () => {
  const [filter, ovSebaranResponden, ovRekapPenilaian] =
    await Promise.allSettled([
      getFilterPenggunaLulusanController(),
      getAdmSebaranRespondenController({ tahun: null }),
      getAdmRekapPenilaianRespondenController({ tahun: null }),
    ]);
  return {
    filter: filter,
    ovSebaranResponden: ovSebaranResponden,
    ovRekapPenilaian: ovRekapPenilaian,
  };
};
