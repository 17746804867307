import { Transition } from '@headlessui/react'
import moment from 'moment'
import React, { useState } from 'react'
import { TbArrowBackUp, TbTrash } from 'react-icons/tb'
import { Caption, Card, IconButton, Overline, Row, Text } from '../../shared/ui'

const CKonBubbleMessage = ({ chat, margin, enableAction = true, replyMessage, onReply, onDelete }) => {

    const [showAction1, setShowAction1] = useState(false)
    const [showAction2, setShowAction2] = useState(false)

    const updateShow = () => {
        if (chat.from_who === 10) {
            setShowAction1(false)
            setShowAction2(true)
        } else {
            setShowAction1(true)
            setShowAction2(false)
        }
    }

    const updateHide = () => {
        setShowAction1(false)
        setShowAction2(false)
    }

    return (
        <div className='relative' onMouseEnter={updateShow} onMouseLeave={updateHide}>
            <Row className={`px-4 gap-2 ${chat.from_who === 20 ? 'justify-end' : 'justify-start'} ${margin}`}>
                {enableAction && chat.from_who == 20 && <Transition
                    className='my-auto'
                    show={showAction1}
                    enter='transition ease-in-out duration-200'
                    enterFrom='translate-y-2 opacity-0'
                    enterTo='translate-y-0 opacity-100'
                    leave='transition ease-in-out duration-200'
                    leaveFrom='translate-y-0 opacity-100'
                    leaveTo='translate-y-2 opacity-0'>
                    <Row className='gap-2 pr-1 my-auto'>
                        <IconButton bordered onClick={() => onDelete(chat)} color='custom' className='text-gray-600 transition-all hover:text-red-600 p-1 hover:border-red-200 my-auto'>
                            <TbTrash className='h-5 w-5' />
                        </IconButton>
                        <IconButton bordered onClick={() => onReply(chat)} color='custom' className='text-gray-600 transition-all hover:text-blue-600 p-1 hover:border-blue-200 my-auto'>
                            <TbArrowBackUp className='h-5 w-5' />
                        </IconButton>
                        <Overline className='my-auto border px-3 py-1.5 rounded-full font-medium'>
                            {moment(chat.created_at).format('DD MMM')}
                        </Overline>
                    </Row>
                </Transition>}
                <div className={`flex flex-col w-fit max-w-[70%] rounded-3xl border-[1px] ${chat.from_who === 10 ? 'bg-white order-2' : 'border-transparent bg-gradient-to-br from-blue-600 bg-indigo-600'} ${replyMessage !== null ? 'p-1.5' : 'px-4 py-3'}`}>
                    {replyMessage !== null && <Card className={`w-full px-3 py-2.5 rounded-2xl ${chat.from_who === 20 ? 'bg-black/10 border-black/20' : 'bg-gray-100 border-slate-300'}`}>
                        <Overline className={`w-fit text-gray-800 ${chat.from_who === 20 && 'text-yellow-300'} line-clamp-1 font-medium`}>
                            {chat.from_who === 10 ? 'Alumni membalas' : 'Anda Membalas'}
                        </Overline>
                        <Caption className={`w-fit whitespace-pre-line ${chat.from_who === 20 && 'text-white/70'} line-clamp-3`}>
                            {replyMessage.chat}
                        </Caption>
                    </Card>}
                    <div className={`gap-x-3 gap-y-2 flex flex-wrap ${replyMessage !== null && 'px-2 py-2.5'}`}>
                        <Text className={`w-full flex gap-x-3 text-chat whitespace-pre-line ${chat.from_who === 20 && 'text-white'}`}>
                            {chat.chat} <span className={`overlines text-gray-400 ${chat.from_who === 20 && 'text-white/50'} ml-auto self-end`}>{moment(chat.created_at).format('HH.mm')}</span>
                        </Text>
                    </div>
                </div>
                {enableAction && chat.from_who === 10 && <Transition
                    className='order-2 my-auto'
                    show={showAction2}
                    enter='transition ease-in-out duration-200'
                    enterFrom='translate-y-2 opacity-0'
                    enterTo='translate-y-0 opacity-100'
                    leave='transition ease-in-out duration-200'
                    leaveFrom='translate-y-0 opacity-100'
                    leaveTo='translate-y-2 opacity-0'>
                    <Row className='gap-2 pl-1 my-auto'>
                        <Overline className='my-auto border px-3 py-1.5 rounded-full font-medium'>
                            {moment(chat.created_at).format('DD MMM')}
                        </Overline>
                        <IconButton bordered onClick={() => onReply(chat)} color='custom' className='text-gray-600 transition-all hover:text-blue-600 p-1 hover:border-blue-200 my-auto'>
                            <TbArrowBackUp className='h-5 w-5' />
                        </IconButton>
                    </Row>
                </Transition>}
            </Row>
        </div>
    )
}

export default CKonBubbleMessage
