import moment from 'moment'
import React from 'react'
import { TbMessageCheck, TbProgress, TbX } from 'react-icons/tb'
import { Row } from '../../../../../components/interfaces/block'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { ButtonHover, IconButton, TagView } from '../../../../../shared/ui'

function RowTableProcessedConsultation({ idx, consultation, page = 1, limit = 10, handleClick }) {

  const onHandleClick = (event) => {
    event.stopPropagation();
    handleClick({
      action: 10,
      consultation: consultation,
    })
  }

  const onHandleAcceptClick = (event) => {
    event.stopPropagation();
    handleClick({
      action: 20,
      consultation: consultation,
    })
  }

  const onHandleRejectClick = (event) => {
    event.stopPropagation();
    handleClick({
      action: 30,
      consultation: consultation,
    })
  }

  return (
    <TableRow
      striped
      onClick={onHandleClick}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {consultation.nama_alumni}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {consultation.topik}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='line-clamp-2'>
          {consultation.subjek}
        </BodyText>
      </TableCell>
      <TableCell>
        {moment(consultation.created_at).format('DD MMM YYYY HH.mm')}
      </TableCell>
      <TableCell>
        <TagView
          className={'mx-auto'}
          leadingIcon={
            <TbProgress className='h-4 w-4' />
          }
          label='Menunggu'
          color='orange' />
      </TableCell>
      <TableCell>
        <Row className={'gap-x-2'}>
          <ButtonHover>
            <IconButton
              className='p-1.5'
              color='red'
              bordered
              onClick={onHandleRejectClick}>
              <TbX className='h-5 w-5' />
            </IconButton>
          </ButtonHover>
          <ButtonHover>
            <IconButton
              className='p-1.5'
              color='indigo'
              bordered
              onClick={onHandleAcceptClick}>
              <TbMessageCheck className='h-5 w-5' />
            </IconButton>
          </ButtonHover>
        </Row>
      </TableCell>
    </TableRow>
  )
}

export default RowTableProcessedConsultation