import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  TbAlertTriangle,
  TbArrowRight,
  TbArrowUpRight,
  TbChecks,
  TbCircleDashed,
  TbClockHour3,
  TbInfoCircle,
  TbMapPin,
  TbUsers,
  TbWallet,
  TbX,
} from "react-icons/tb";
import { useLocation } from "react-router-dom";
import RowTableApplyLowongan from "../../components/tabels/rows/row_apply_lowongan";
import {
  approvePelamarController,
  getApplyLowonganController,
} from "../../controllers/apicontrollers";
import MitraLayout from "../../layouts/mitra_layout";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Card,
  Column,
  DotSeparated,
  Form,
  GridCol,
  Headline1,
  Headline2,
  Headline3,
  HorizontalDivider,
  Image,
  LargeHeadline,
  ListTileView,
  LoadingDialog,
  OutlineTextArea,
  PrimaryButton,
  Row,
  SecondaryButton,
  SimpleAlert,
  Subtitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TagView,
  TertiaryButton,
  Text,
  Title,
  ToasterView,
  TransitionPage,
} from "../../shared/ui";

const MitApplyCareerPage = () => {
  const location = useLocation();
  const [lowongan, setLowongan] = useState(null);
  const [listApply, setListApply] = useState([]);
  const [currentApply, setCurrentApply] = useState(null);
  const [showProgress, setShowProgress] = useState(true);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sortTable, setShortTable] = useState(null);
  const [limitRow, setLimitRow] = useState(10);
  const [totalRow, setTotalRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [sumAccepted, setSumAccepted] = useState(0);
  const [sumProcessed, setSumProcessed] = useState(0);
  const [sumRejected, setSumRejected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogDetail, setShowDialogDetail] = useState(false);
  const [showDialogApprove, setShowDialogApprove] = useState({
    show: false,
    action: 0,
  });

  const getApplyLowongan = async () => {
    let path = location.pathname;
    const props = {
      id: path.split("/").pop(),
      limit: limitRow,
      page: currentPage,
      search: searchQuery,
      order: sortTable !== null ? sortTable.key : null,
      asc: sortTable !== null ? sortTable.type : null,
    };
    const result = await getApplyLowonganController(props);
    if (result.status) {
      const listApply = [...result.data.pelamar];
      if (listApply.length > 0) {
        const accepted = listApply.filter(
          (apply) => apply.acc_mitra === "ACCEPTED"
        );
        const processed = listApply.filter(
          (apply) => apply.acc_mitra === "PROCESSED"
        );
        const rejected = listApply.filter(
          (apply) => apply.acc_mitra === "REJECTED"
        );
        setSumAccepted(accepted.length);
        setSumProcessed(processed.length);
        setSumRejected(rejected.length);
      }
      setLowongan(result.data);
      setListApply(listApply);
      setTotalRow(result.totalRows);
      setTotalPage(result.totalPage);
    }
    setShowProgress(false);
  };

  const approvePelamar = async (action, note) => {
    setShowDialogApprove({
      show: false,
      action: showDialogApprove.action,
    });
    setLoading(true);
    let path = location.pathname;
    const props = {
      id_user: currentApply.id_user,
      approve: action === 10 ? "ACCEPTED" : "REJECTED",
      informasi: action === 10 ? note : null,
      alasan: action === 20 ? note : null,
      idlowongan: path.split("/").pop(),
    };
    const result = await approvePelamarController(props);
    if (result.status) {
      getApplyLowongan();
      setTimeout(() => {
        setLoading(false);
        toast.success(result.message);
      }, 200);
    } else {
      setTimeout(() => {
        setLoading(false);
        toast.error(result.message);
      }, 200);
    }
  };

  const onSubmitApprove = (event) => {
    event.preventDefault();
    const note = event.target.note.value;

    if (note) {
      approvePelamar(showDialogApprove.action, note);
    }
  };

  const onShowDialog = (value) => {
    setCurrentApply(value);
    setShowDialogDetail(true);
  };

  const onApprove = (action) => {
    setShowDialogDetail(false);
    setShowDialogApprove({
      show: true,
      action: action,
    });
  };

  const onChangeShowNumber = (value) => {
    setCurrentPage(1);
    setLimitRow(value);
  };

  const onChangeQuerySearch = (value) => {
    setCurrentPage(1);
    setSearchQuery(value);
  };

  const onChangePageNumber = (value) => {
    setCurrentPage(value);
  };

  const onShortableValue = (value) => {
    setCurrentPage(1);
    setShortTable(value);
  };

  useEffect(() => {
    getApplyLowongan();
  }, [searchQuery, sortTable, limitRow, currentPage]);

  return (
    <>
      <TransitionPage>
        <Column className="gap-y-5 p-6">
          <Headline1>Pelamar Lowongan</Headline1>
          <Card className="w-full p-0">
            <Column>
              {lowongan !== null && (
                <Column>
                  <Row className="gap-x-5 px-5 py-4 items-center">
                    <Column className="flex-1 gap-0.5">
                      <Title>{lowongan.posisi}</Title>
                      <Row>
                        <Text>{lowongan.jenis}</Text>
                        <DotSeparated />
                        <Text>{lowongan.gaji}</Text>
                        <DotSeparated />
                        <Text>{lowongan.kota}</Text>
                      </Row>
                    </Column>
                    <ButtonHover>
                      <TertiaryButton
                        onClick={() => setShowDialog(true)}
                        color="indigo"
                        leadingIcon={
                          <TbInfoCircle className="h-5 w-5 my-auto" />
                        }
                        className="pr-5"
                      >
                        Detail Lowongan
                      </TertiaryButton>
                    </ButtonHover>
                  </Row>
                  <SimpleAlert
                    type="warning"
                    className="rounded-none"
                    borderLeft
                    showCloseBtn
                  >
                    Lowongan dibuka mulai{" "}
                    {moment(lowongan.berlaku_mulai).format("DD MMMM YYYY")}{" "}
                    sampai{" "}
                    {moment(lowongan.berlaku_hingga).format("DD MMMM YYYY")}
                  </SimpleAlert>
                  <HorizontalDivider className="mt-0 mb-0" />
                  <GridCol className="gap-x-5 grid-cols-4 px-5 pt-5">
                    <Card className="w-full">
                      <Column className="gap-y-1.5">
                        <Text>Total Pelamar</Text>
                        <Row className="justify-between items-center">
                          <LargeHeadline className="font-inter font-normal">
                            {listApply.length}
                          </LargeHeadline>
                          <TbUsers className="h-8 w-8 stroke-1.5 text-gray-800" />
                        </Row>
                      </Column>
                    </Card>
                    <Card className="w-full">
                      <Column className="gap-y-1.5">
                        <Text>Disetujui</Text>
                        <Row className="justify-between items-center">
                          <LargeHeadline className="font-inter font-normal">
                            {sumAccepted}
                          </LargeHeadline>
                          <TbChecks className="h-8 w-8 stroke-1.5 text-green-700" />
                        </Row>
                      </Column>
                    </Card>
                    <Card className="w-full">
                      <Column className="gap-y-1.5">
                        <Text>Menunggu</Text>
                        <Row className="justify-between items-center">
                          <LargeHeadline className="font-inter font-normal">
                            {sumProcessed}
                          </LargeHeadline>
                          <TbCircleDashed className="h-8 w-8 stroke-1.5 text-blue-700" />
                        </Row>
                      </Column>
                    </Card>
                    <Card className="w-full">
                      <Column className="gap-y-1.5">
                        <Text>Ditolak</Text>
                        <Row className="justify-between items-center">
                          <LargeHeadline className="font-inter font-normal">
                            {sumRejected}
                          </LargeHeadline>
                          <TbAlertTriangle className="h-8 w-8 stroke-1.5 text-red-700" />
                        </Row>
                      </Column>
                    </Card>
                  </GridCol>
                </Column>
              )}
              <div className="p-5">
                <Table
                  className="border-[1px] border-gray-200"
                  limitRow={limitRow}
                  totalRow={totalRow}
                  totalPage={totalPage}
                  trigerPagination={sortTable}
                  onChangeShowNumber={onChangeShowNumber}
                  onChangeQuerySearch={onChangeQuerySearch}
                  onChangePageNumber={onChangePageNumber}
                >
                  <TableHead>
                    <TableRow className="bg-slate-100/50 border-b-[1px] border-b-gray-200">
                      <TableCell className="font-semibold font-manrope">
                        No.
                      </TableCell>
                      <TableCell className="font-semibold font-manrope">
                        Nama Lengkap
                      </TableCell>
                      <TableCell className="font-semibold font-manrope">
                        Ijazah
                      </TableCell>
                      <TableCell className="font-semibold font-manrope">
                        Transkrip Nilai
                      </TableCell>
                      <TableCell className="font-semibold font-manrope">
                        Curriculum Vitae
                      </TableCell>
                      <TableCell className="font-semibold font-manrope">
                        Tanggal Melamar
                      </TableCell>
                      <TableCell className="font-semibold font-manrope text-center">
                        Status
                      </TableCell>
                      <TableCell className="font-semibold font-manrope px-3 text-center">
                        Detail
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="divide-y-[1px] divide-gray-200">
                    {listApply.length > 0 ? (
                      listApply.map((apply, idx) => {
                        return (
                          <RowTableApplyLowongan
                            key={idx}
                            idx={idx}
                            limitRow={limitRow}
                            currentPage={currentPage}
                            apply={apply}
                            onShowDialog={onShowDialog}
                          />
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={7}
                          className="text-center text-gray-500"
                        >
                          No data to display
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </Column>
          </Card>
        </Column>
      </TransitionPage>

      <AlertDialog
        className="max-w-6xl"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        {lowongan !== null && (
          <Column>
            <Row className={`w-full divide-x-[1px] divide-gray-200`}>
              <Column className="max-w-lg my-auto px-8 py-8">
                <Card className="w-full my-auto rounded-lg relative p-0 overflow-hidden bg-slate-50">
                  <Image
                    src={lowongan.flyer}
                    className="aspect-[3/4]"
                    imgClasName="aspect-[3/4] object-cover"
                    hoverScale={false}
                  />
                </Card>
              </Column>
              <Column className="px-8 py-8 gap-y-8 max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
                <Column className="gap-y-3">
                  <Headline2>{lowongan.posisi}</Headline2>
                  <Row className="gap-x-3 gap-y-3 flex-wrap">
                    <TagView
                      color="purple"
                      className="gap-x-2 py-2 pl-2.5 pr-4 rounded-lg"
                      leadingIcon={<TbClockHour3 className="h-5 w-5" />}
                      label={lowongan.jenis}
                    />
                    <TagView
                      color="rose"
                      className="gap-x-2 py-2 pl-2.5 pr-4 rounded-lg"
                      leadingIcon={<TbWallet className="h-5 w-5" />}
                      label={lowongan.gaji}
                    />
                    <TagView
                      color="blue"
                      className="gap-x-2 py-2 pl-2.5 pr-4 rounded-lg"
                      leadingIcon={<TbMapPin className="h-5 w-5" />}
                      label={lowongan.kota}
                    />
                  </Row>
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">
                    Deskripsi Pekerjaan:
                  </Subtitle>
                  <div
                    className="text-gray-800 font-inter text-base"
                    dangerouslySetInnerHTML={{
                      __html: lowongan.jobdesc
                        .replaceAll(
                          "<ul>",
                          '<ul class="list-disc space-y-2 pl-5">'
                        )
                        .replaceAll(
                          "<ol>",
                          '<ol class="list-decimal pl-6 space-y-2">'
                        )
                        .replaceAll("undefined", ""),
                    }}
                  />
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">Persyaratan:</Subtitle>
                  <div
                    className="text-gray-800 font-inter text-base"
                    dangerouslySetInnerHTML={{
                      __html: lowongan.persyaratan
                        .replaceAll(
                          "<ul>",
                          '<ul class="list-disc space-y-2 pl-5">'
                        )
                        .replaceAll(
                          "<ol>",
                          '<ol class="list-decimal pl-6 space-y-2">'
                        )
                        .replaceAll("undefined", ""),
                    }}
                  />
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">Ketentuan:</Subtitle>
                  <div
                    className="text-gray-800 font-inter text-base"
                    dangerouslySetInnerHTML={{
                      __html: lowongan.ketentuan
                        .replaceAll(
                          "<ul>",
                          '<ul class="list-disc space-y-2 pl-5">'
                        )
                        .replaceAll(
                          "<ol>",
                          '<ol class="list-decimal pl-6 space-y-2">'
                        )
                        .replaceAll("undefined", ""),
                    }}
                  />
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className=" font-semibold">Waktu:</Subtitle>
                  <Row className="gap-x-5">
                    <Card className="w-full py-3 px-4 bg-gray-50">
                      <Column className="gap-y-1">
                        <Caption>Lowongan dibuka mulai:</Caption>
                        <Text className="font-inter">
                          {moment(lowongan.berlaku_mulai).format(
                            "DD MMMM YYYY"
                          )}
                        </Text>
                      </Column>
                    </Card>
                    <div className="flex h-6 aspect-square rounded-full bg-gray-700 my-auto justify-center items-center">
                      <TbArrowRight className="h-5 w-5 text-white" />
                    </div>
                    <Card className="w-full py-3 px-4 bg-gray-50">
                      <Column className="gap-y-1">
                        <Caption>Lowongan ditutup pada:</Caption>
                        <Text className="font-inter">
                          {moment(lowongan.berlaku_hingga).format(
                            "DD MMMM YYYY"
                          )}
                        </Text>
                      </Column>
                    </Card>
                  </Row>
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">Keterangan:</Subtitle>
                  <Text>
                    {lowongan.keterangan?.length > 0
                      ? lowongan.keterangan
                      : "Tidak ada keterangan"}
                  </Text>
                </Column>
                <Column className="gap-y-2">
                  <Subtitle className="font-semibold">Kontak:</Subtitle>
                  <Text>{lowongan.contact_email}</Text>
                </Column>
              </Column>
            </Row>
            <HorizontalDivider removeMargin />
            <Row className="gap-x-3 justify-end px-8 pt-6 pb-8">
              <ButtonHover>
                <SecondaryButton
                  leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                  className="pr-5"
                  onClick={() => setShowDialog(false)}
                >
                  Tutup
                </SecondaryButton>
              </ButtonHover>
            </Row>
          </Column>
        )}
      </AlertDialog>
      <AlertDialog
        className="max-w-2xl"
        show={showDialogDetail}
        onClose={() => setShowDialogDetail(false)}
      >
        {currentApply !== null && (
          <Column className="p-8 gap-y-8">
            <Column className="gap-y-2">
              <Headline3>Detail Pelamar</Headline3>
              <Text>
                Data pelamar yang ditampilkan sudah terverifikasi oleh Admin
                Alumni Universitas Teknologi Yogyakarta
              </Text>
            </Column>
            <Column className="gap-y-5">
              <Card className="w-full px-5 py-0">
                <Column className="divide-y-[1px] divide-gray-200">
                  <ListTileView
                    label="Nama Lengkap"
                    value={currentApply.nama}
                    direction="flex-col sm:flex-row"
                    className="py-4 pl-0 pr-0"
                    labelClassName="text-base"
                    valueClassName="text-base font-normal sm:text-end"
                  />
                  <ListTileView
                    label="Email"
                    value={currentApply.email ?? "Belum Tersedia"}
                    direction="flex-col sm:flex-row"
                    className="py-4 pl-0 pr-0"
                    labelClassName="text-base"
                    valueClassName={`text-base font-normal ${
                      currentApply.email === null && "text-gray-500"
                    } sm:text-end`}
                  />
                  <ListTileView
                    label="Nomor Whatsapp"
                    value={currentApply.no_wa ?? "Belum Tersedia"}
                    direction="flex-col sm:flex-row"
                    className="py-4 pl-0 pr-0"
                    labelClassName="text-base"
                    valueClassName={`text-base font-normal ${
                      currentApply.no_wa === null && "text-gray-500"
                    } sm:text-end`}
                  />
                </Column>
              </Card>
              <Card className="w-full px-5 py-0">
                <Column className="divide-y-[1px] divide-gray-200">
                  <Column className="py-3 gap-x-3 gap-y-3 xl:flex-row xl:items-center">
                    <Column className="gap-y-1">
                      <Text className="text-gray-500">Ijazah</Text>
                      <Text className="line-clamp-1">
                        {currentApply.ijazah?.split("/").pop() ?? (
                          <span className="flex flex-row gap-x-2 text-gray-500">
                            <TbAlertTriangle className="h-5 w-5 my-auto text-red-500" />{" "}
                            Belum dilengkapi
                          </span>
                        )}
                      </Text>
                    </Column>
                    <ButtonHover>
                      <a
                        href={currentApply.ijazah}
                        target="_blank"
                        rel="noreferrer"
                        className="tertiary-btn flex flex-row gap-2 pr-5"
                      >
                        <TbArrowUpRight className="h-5 w-5 my-auto" />
                        Lihat
                      </a>
                    </ButtonHover>
                  </Column>
                  <Column className="py-3 gap-x-3 gap-y-3 xl:flex-row xl:items-center">
                    <Column className="gap-y-1">
                      <Text className="text-gray-500">Transkrip Nilai</Text>
                      <Text className="line-clamp-1">
                        {currentApply.transkrip?.split("/").pop() ?? (
                          <span className="flex flex-row gap-x-2 text-gray-500">
                            <TbAlertTriangle className="h-5 w-5 my-auto text-red-500" />{" "}
                            Belum dilengkapi
                          </span>
                        )}
                      </Text>
                    </Column>
                    <ButtonHover>
                      <a
                        href={currentApply.transkrip}
                        target="_blank"
                        rel="noreferrer"
                        className="tertiary-btn flex flex-row gap-2 pr-5"
                      >
                        <TbArrowUpRight className="h-5 w-5 my-auto" />
                        Lihat
                      </a>
                    </ButtonHover>
                  </Column>
                  <Column className="py-3 gap-x-3 gap-y-3 xl:flex-row xl:items-center">
                    <Column className="gap-y-1">
                      <Text className="text-gray-500">Curriculum Vitae</Text>
                      <Text className="line-clamp-1">
                        {currentApply.cv?.split("/").pop() ?? (
                          <span className="flex flex-row gap-x-2 text-gray-500">
                            <TbAlertTriangle className="h-5 w-5 my-auto text-red-500" />{" "}
                            Belum dilengkapi
                          </span>
                        )}
                      </Text>
                    </Column>
                    <ButtonHover>
                      <a
                        href={currentApply.cv}
                        target="_blank"
                        rel="noreferrer"
                        className="tertiary-btn flex flex-row gap-2 pr-5"
                      >
                        <TbArrowUpRight className="h-5 w-5 my-auto" />
                        Lihat
                      </a>
                    </ButtonHover>
                  </Column>
                </Column>
              </Card>
              <Card className="w-full px-5 py-0">
                <Column className="divide-y-[1px] divide-gray-200">
                  <ListTileView
                    label="Status Pelamar"
                    value={
                      currentApply.acc_mitra === "ACCEPTED"
                        ? "Pelamar Diterima"
                        : currentApply.acc_mitra === "REJECTED"
                        ? "Pelamar Ditolak"
                        : "Menunggu Diproses"
                    }
                    direction="flex-col sm:flex-row"
                    className="py-4 pl-0 pr-0"
                    labelClassName="text-base"
                    valueClassName={`text-base sm:text-end ${
                      currentApply.acc_mitra === "ACCEPTED"
                        ? "text-green-600"
                        : currentApply.acc_mitra === "REJECTED"
                        ? "text-red-600"
                        : "text-orange-600"
                    }`}
                  />
                  <ListTileView
                    label="Tanggal Melamar Lowongan"
                    value={moment(currentApply.created_at).format(
                      "DD MMM YYYY HH.mm"
                    )}
                    direction="flex-col sm:flex-row"
                    className="py-4 pl-0 pr-0"
                    labelClassName="text-base"
                    valueClassName="text-base font-normal sm:text-end"
                  />
                </Column>
              </Card>
              {currentApply.acc_mitra === "ACCEPTED" && (
                <Card className="w-full px-5 py-0">
                  <ListTileView
                    label="Informasi untuk Pelamar"
                    value={currentApply.informasi}
                    direction="flex-col"
                    className="py-4 pl-0 pr-0"
                    labelClassName="text-base"
                    valueClassName="text-base font-normal"
                  />
                </Card>
              )}
              {currentApply.acc_mitra === "REJECTED" && (
                <Card className="w-full px-5 py-0">
                  <ListTileView
                    label="Catatan untuk Pelamar"
                    value={currentApply.alasan}
                    direction="flex-col"
                    className="py-4 pl-0 pr-0"
                    labelClassName="text-base"
                    valueClassName="text-base font-normal"
                  />
                </Card>
              )}
            </Column>
            <Row
              className={`gap-x-3 ${
                currentApply.acc_mitra !== "PROCESSED"
                  ? "justify-end"
                  : "justify-between"
              }`}
            >
              {currentApply.acc_mitra === "PROCESSED" && (
                <Row className="w-fit gap-x-3">
                  <ButtonHover>
                    <PrimaryButton
                      onClick={() => onApprove(10)}
                      color="indigo"
                      leadingIcon={<TbChecks className="h-5 w-5 my-auto" />}
                      className="pr-5"
                    >
                      Lolos Seleksi Administrasi
                    </PrimaryButton>
                  </ButtonHover>
                  <ButtonHover>
                    <TertiaryButton
                      onClick={() => onApprove(20)}
                      color="red"
                      leadingIcon={
                        <TbAlertTriangle className="h-5 w-5 my-auto" />
                      }
                      className="pr-5"
                    >
                      Tolak Lamaran
                    </TertiaryButton>
                  </ButtonHover>
                </Row>
              )}
              <ButtonHover>
                <SecondaryButton
                  onClick={() => setShowDialogDetail(false)}
                  leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                  className="pr-5"
                >
                  Tutup
                </SecondaryButton>
              </ButtonHover>
            </Row>
          </Column>
        )}
      </AlertDialog>
      <AlertDialog
        className="max-w-2xl"
        show={showDialogApprove.show}
        onClose={() =>
          setShowDialogApprove({
            show: false,
            action: showDialogApprove.action,
          })
        }
      >
        {currentApply !== null && (
          <Form onSubmit={onSubmitApprove}>
            <Column className="gap-y-5 p-8">
              <Headline3>
                {showDialogApprove.action === 10
                  ? "Lolos Seleksi Administrasi"
                  : "Menolak Lamaran"}{" "}
                - {currentApply.nama}
              </Headline3>
              <OutlineTextArea
                id="note"
                className="py-3"
                inputWidth="w-full"
                bgColor="bg-slate-50"
                label={
                  showDialogApprove.action === 10
                    ? "Berikan Informasi"
                    : "Berikan Catatan"
                }
                placeholder={
                  showDialogApprove.action === 10
                    ? "Masukkan informasi untuk pelamar"
                    : "Masukkan catatan untuk pelamar"
                }
                helperText={
                  showDialogApprove.action === 10
                    ? "Informasi akan ditampilkan ke Pelamar Lowongan"
                    : "Catatan akan ditampilkan ke Pelamar Lowongan"
                }
                initialRow={8}
                maxLength={500}
                required
              />
              <Row className="gap-x-3 justify-end">
                <ButtonHover>
                  <PrimaryButton
                    type="submit"
                    color={showDialogApprove.action === 10 ? "indigo" : "red"}
                    className="pr-5"
                    leadingIcon={
                      showDialogApprove.action === 10 ? (
                        <TbChecks className="h-5 w-5 my-auto" />
                      ) : (
                        <TbAlertTriangle className="h-5 w-5 my-auto" />
                      )
                    }
                  >
                    {showDialogApprove.action === 10
                      ? "Lolos Seleksi Administrasi"
                      : "Tolak Lamaran"}
                  </PrimaryButton>
                </ButtonHover>
                <ButtonHover>
                  <SecondaryButton
                    className="pr-5"
                    leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                    onClick={() =>
                      setShowDialogApprove({
                        show: false,
                        action: showDialogApprove.action,
                      })
                    }
                  >
                    Batal
                  </SecondaryButton>
                </ButtonHover>
              </Row>
            </Column>
          </Form>
        )}
      </AlertDialog>
      <ToasterView />
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
};

export default MitApplyCareerPage;
