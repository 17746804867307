import { Suspense, lazy, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { HiBookmark } from "react-icons/hi2";
import { TbPinFilled } from "react-icons/tb";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Cookies from "universal-cookie";
import { gRecognition } from "../atoms";
import ListRecognition from "../components/list/list_recognition";
import SAlmRecommendation from "../components/shimmer/salm_recommendation";
import { getRecognitionController } from "../controllers/global_controllers";
import {
  ButtonHover,
  Column,
  HorizontalDivider,
  IconButton,
  PrimaryButton,
  TextButton,
} from "../shared/ui";
import {
  almSavedProfileRoute,
  almTaggedProfileRoute,
  refreshTokenKey,
  toastDuration,
} from "../shared/variable";

const ListEventRecommendation = lazy(() =>
  import("../components/list/list_event_recommendation")
);

function RecognitionPage() {
  const result = useLoaderData();
  const cookies = new Cookies();
  const tokenCookies = cookies.get(refreshTokenKey) ?? null;
  const navigateTo = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listRecognition, setListRecognition] = useRecoilState(gRecognition);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const loaderRef = useRef(null);

  useEffect(() => {
    if (result.status) {
      setListRecognition(result.data);
      setTotalPage(result.totalPage);
    }
  }, [result]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && page < totalPage && !loading) {
          setPage((prev) => prev + 1);
        }
      },
      { threshold: 1 }
    );
    if (loaderRef.current) observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, [page, totalPage, loading]);

  useEffect(() => {
    if (page > 1) {
      fetchMoreEvents();
    }
  }, [page]);

  const fetchMoreEvents = async () => {
    setLoading(true);

    try {
      const newResult = await getRecognition({ page, limit: 10 });
      if (newResult?.status) {
        setListRecognition((prev) => [...prev, ...newResult.data]);
      }
    } catch (error) {
      console.error("error fetching event");
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const onClickSideButton = (action) => {
    if (tokenCookies !== null) {
      if (action === 10) {
        navigateTo(almSavedProfileRoute);
      } else {
        navigateTo(almTaggedProfileRoute);
      }
    } else {
      if (!showToast) {
        setShowToast(true);
        toast("Silakan masuk ke Akun Anda!");
        setTimeout(() => {
          setShowToast(false);
        }, toastDuration + 500);
      }
    }
  };

  return (
    <Column
      className={
        "w-full max-w-8xl mx-auto gap-x-5 gap-y-8 pt-20 pb-16 sm:pt-24 lg:pt-32 xl:flex-row"
      }
    >
      <Column
        className={
          "w-fit hidden fixed gap-x-3 gap-y-3 lg:flex lg:left-10 lg:top-32 xl:sticky"
        }
      >
        <IconButton
          color="custom"
          className={
            "transition-all duration-200 cursor-pointer rounded-full bg-blue-500 text-white p-3 hover:bg-blue-600 hover:-translate-y-1"
          }
          onClick={() => onClickSideButton(20)}
        >
          <TbPinFilled className={"h-7 w-7 stroke-1.5"} />
        </IconButton>
        <IconButton
          color="custom"
          className={
            "transition-all duration-200 cursor-pointer rounded-full bg-indigo-500 text-white p-3.5 hover:bg-indigo-600 hover:-translate-y-1"
          }
          onClick={() => onClickSideButton(10)}
        >
          <HiBookmark className={"h-6 w-6 stroke-1.5"} />
        </IconButton>
      </Column>
      <Column
        className={"w-full gap-y-5 max-w-4xl mx-auto sm:px-14 sm:gap-y-8"}
      >
        <ListRecognition recognitions={listRecognition} />
        <div ref={loaderRef}>
          {loading ? (
            <Column className="w-full justify-center items-center">
              <ButtonHover>
                <TextButton className="rounded-full" color="indigo">
                  Memuat Berita...
                </TextButton>
              </ButtonHover>
            </Column>
          ) : page >= totalPage ? (
            <Column>
              <ButtonHover>
                <PrimaryButton disabled className="rounded-full">
                  Tidak Ada Lagi Berita
                </PrimaryButton>
              </ButtonHover>
            </Column>
          ) : null}
        </div>
      </Column>
      <HorizontalDivider className={"my-4 xl:hidden"} />
      <Column
        className={
          "w-full max-w-4xl mx-auto sm:px-14 xl:px-0 xl:max-w-sm xl:sticky xl:top-32"
        }
      >
        <Suspense fallback={<SAlmRecommendation />}>
          <ListEventRecommendation />
        </Suspense>
      </Column>
    </Column>
  );
}

export default RecognitionPage;

export const getRecognition = async () => {
  const props = {
    page: 1,
    limit: 10,
  };
  const result = await getRecognitionController(props);
  return result;
};
