import React from 'react'
import { TableCell, TableRow, Text, Tooltip } from '../../../shared/ui'

const RowTableRekapWisudaTracer = ({ action = 10, wisuda, idx, currentPage = 1, limitRow = 1 }) => {
    return (
        <TableRow className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50 cursor-pointer`}>
            <TableCell>
                {limitRow * (currentPage - 1) + (idx + 1)}
            </TableCell>
            <TableCell>
                {wisuda.nama_jenjang}
            </TableCell>
            <TableCell>
                <Tooltip message={wisuda.nama_jurusan}>
                    <Text className='line-clamp-2'>
                        {wisuda.nama_jurusan}
                    </Text>
                </Tooltip>
            </TableCell>
            <TableCell className='text-center'>
                {wisuda.lulus}
            </TableCell>
            <TableCell className='text-center'>
                {wisuda.isi_tracer}
            </TableCell>
            {action === 10 ? <TableCell className='text-center'>
                {parseFloat(wisuda.persentase).toFixed(1)}%
            </TableCell> : <TableCell className='text-center'>
                {((wisuda.isi_tracer / wisuda.lulus) * 100).toFixed(1)}%
            </TableCell>}
        </TableRow>
    )
}

export default RowTableRekapWisudaTracer
