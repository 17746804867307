import React, { Suspense, lazy, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  gInitCityCareer,
  gInitProvCareer,
  gInitTimeCareer,
  gInitTypeCareer,
  gMasterFilterCareer,
  gOpsiCityCareer,
  gOpsiProvCareer,
  gOpsiTimeCareer,
  gOpsiTypeCareer,
} from "../atoms";
import WFilterCareer from "../components/other/w_filter_career";
import WHeaderCareer from "../components/other/w_header_career";
import SAlmCareer from "../components/shimmer/salm_carrer";
import { getFilterCareerController } from "../controllers/alumni_controllers";
import { Column } from "../shared/ui";

const ListCareer = lazy(() => import("../components/list/list_career"));

const CareerPage = () => {
  const result = useLoaderData();

  const [masterProvince, setMasterProvince] =
    useRecoilState(gMasterFilterCareer);
  const [opsiType, setOpsiType] = useRecoilState(gOpsiTypeCareer);
  const [opsiTimePost, setOpsiTimePost] = useRecoilState(gOpsiTimeCareer);
  const [opsiProvince, setOpsiProvince] = useRecoilState(gOpsiProvCareer);
  const [opsiCity, setOpsiCity] = useRecoilState(gOpsiCityCareer);
  const [initType, setInitType] = useRecoilState(gInitTypeCareer);
  const [initTimePost, setInitTimePost] = useRecoilState(gInitTimeCareer);
  const [initProvince, setInitProvince] = useRecoilState(gInitProvCareer);
  const [initCity, setInitCity] = useRecoilState(gInitCityCareer);
  const [initSearch, setInitSearch] = useState(null);

  const getFilterFromLoader = () => {
    if (result.status) {
      const type = [...result.jenis_pekerjaan];
      const opsiType = [];
      opsiType.push({
        label: "Semua Tipe",
        value: null,
      });
      type.map((tp) => {
        const opsi = {
          label: tp.jenis,
          value: tp.id_jenispekerjaan,
        };
        opsiType.push(opsi);
      });
      setOpsiType(opsiType);

      const provinsi = [...result.provinsi];
      const opsiProv = [];
      provinsi.map((prov) => {
        if (prov.prov_nama !== "Luar Negeri") {
          const opsi = {
            label: prov.prov_nama,
            value: prov.prov_id,
            order: getOrder(prov.prov_nama),
          };
          opsiProv.push(opsi);
        }
      });
      opsiProv.sort((a, b) => (a.label < b.label ? 1 : -1));
      opsiProv.sort((a, b) => (a.order < b.order ? 1 : -1));
      opsiProv.unshift({
        label: "Semua Provinsi",
        value: null,
      });
      setOpsiProvince(opsiProv);
      setMasterProvince(provinsi);
    }
  };

  const getOrder = (prov) => {
    let order = 0;
    if (prov.includes("Jakarta")) {
      order = 4;
    } else if (prov.includes("Jawa")) {
      order = 3;
    } else if (prov.includes("Banten")) {
      order = 2;
    } else if (prov.includes("Yogya")) {
      order = 1;
    }
    return order;
  };

  const onChangeFilter = (action, value) => {
    if (action === 10) {
      setInitType(value);
    } else if (action === 20) {
      setInitTimePost(value);
    } else if (action === 30) {
      setInitProvince(value);
    } else if (action === 40) {
      setInitCity(value);
    } else {
      setInitSearch(value);
    }
  };

  const onResetFilter = (action) => {
    if (action === 10) {
      setInitType((initType) => {
        return { ...initType, label: "Semua Tipe", value: null };
      });
    } else if (action === 20) {
      setInitTimePost((initType) => {
        return { ...initType, label: "Kapan Saja", value: null };
      });
    } else if (action === 30) {
      setInitProvince((initType) => {
        return { ...initType, label: "Semua Provinsi", value: null };
      });
    } else if (action === 40) {
      setInitCity((initType) => {
        return { ...initType, label: "Semua Kab/Kota", value: null };
      });
    } else {
      setInitSearch(null);
    }
  };

  useEffect(() => {
    getFilterFromLoader();
  }, []);

  return (
    <Column className={"gap-y-7 pb-12 sm:gap-y-14 lg:pb-24"}>
      <WHeaderCareer onSearch={onChangeFilter} />
      <Column className={"max-w-8xl mx-auto gap-x-5 gap-y-8 lg:flex-row"}>
        <Column
          className={"w-full max-w-[10rem] hidden lg:flex lg:sticky lg:top-32"}
        >
          <WFilterCareer
            action={10}
            masters={masterProvince}
            types={opsiType}
            times={opsiTimePost}
            provinces={opsiProvince}
            citys={opsiCity}
            onChangeFilter={onChangeFilter}
          />
        </Column>
        <Column
          className={"w-full gap-y-5 max-w-4xl mx-auto sm:px-14 sm:gap-y-8"}
        >
          <Suspense fallback={<SAlmCareer />}>
            <ListCareer
              type={initType}
              timePost={initTimePost}
              province={initProvince}
              city={initCity}
              search={initSearch}
              onResetFilter={onResetFilter}
              action={10}
              masters={masterProvince}
              types={opsiType}
              times={opsiTimePost}
              provinces={opsiProvince}
              citys={opsiCity}
              onChangeFilter={onChangeFilter}
            />
          </Suspense>
        </Column>
        <Column className={"w-full max-w-[14rem] hidden lg:flex lg:flex-row"}>
          <WFilterCareer
            masters={masterProvince}
            types={opsiType}
            times={opsiTimePost}
            provinces={opsiProvince}
            citys={opsiCity}
            onChangeFilter={onChangeFilter}
          />
        </Column>
      </Column>
    </Column>
  );
};

export default CareerPage;

export const getFilterCareer = async () => {
  const result = await getFilterCareerController();
  return result;
};
