import React, { useEffect, useState } from "react";
import { Column } from "../../../../components/interfaces/block";
import Chart from "react-apexcharts";
import { getAdmRekapPenilaianRespondenController } from "../../../../controllers/admin_controllers";

function ChartRekapitulasiPenilaianNilaiComponents({ year, series }) {
  const [seriesLocal, setSeriesLocal] = useState([]);
  const colors = ["#5A8F7B", "#2196F3", "#F86F03", "#F44336"];
  const label = ["[1]", "[2]", "[3]", "[4]", "[5]", "[6]", "[7]", "[8]"];
  const getOvRekapPenilaian2 = async () => {
    const props = {
      tahun: year.value,
    };
    const result = await getAdmRekapPenilaianRespondenController(props);
    if (result.status) {
      setSeriesLocal(result.data_penilaian);
    }
  };
  useEffect(() => {
    if (year.value != null) {
      getOvRekapPenilaian2();
    }
  }, [year]);
  return (
    <Column>
      <div className="my-auto pb-4">
        <Chart
          height={300}
          type="radar"
          series={year.value != null ? seriesLocal : series}
          options={{
            chart: {
              width: 720,
              height: 720,
              type: "radar",
              toolbar: {
                show: false,
              },
              fontFamily: "inter",
            },
            labels: label,
            dataLabels: {
              enabled: true,
              fontSize: "12px",
            },
            colors: colors,
          }}
        />
      </div>
    </Column>
  );
}

export default ChartRekapitulasiPenilaianNilaiComponents;
