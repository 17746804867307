import React, { lazy, Suspense, useState } from "react";
import { Column, Headline1, TabPanel, Tabs } from "../../../shared/ui";
import { TbChecks, TbX } from "react-icons/tb";
import SAdmAddLowongan from "../../../components/shimmer/sadm_add_lowongan";

const FormMitraTerdaftar = lazy(() =>
  import("../../../pages/admin/karir/form/adm_lowongan_mitra_terdaftar")
);
const FormMitraTidakTerdaftar = lazy(() =>
  import("../../../pages/admin/karir/form/adm_lowongan_mitra_tidak_terdaftar")
);

const AdmAddCareerPage = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const onChangeTabIndex = (index) => {
    setCurrentTabIndex(index);
  };

  return (
    <>
      <Column className="gap-y-5 p-4 sm:p-8">
        <Headline1>Tambah Lowongan</Headline1>
        <Tabs
          selectedTabIdx={currentTabIndex}
          onChangeTabIndex={onChangeTabIndex}
        >
          <TabPanel
            icon={<TbChecks className="h-5 w-5" />}
            title="Mitra Terdaftar"
            textActiveColor="text-green-700"
          >
            <Suspense fallback={<SAdmAddLowongan />}>
              <FormMitraTerdaftar />
            </Suspense>
          </TabPanel>
          <TabPanel
            icon={<TbX className="h-5 w-5" />}
            title="Mitra Tidak Terdaftar"
            textActiveColor="text-red-700"
          >
            <Suspense fallback={<SAdmAddLowongan logo />}>
              <FormMitraTidakTerdaftar />
            </Suspense>
          </TabPanel>
        </Tabs>
      </Column>
    </>
  );
};

export default AdmAddCareerPage;
