import React, { useEffect, useState } from 'react'
import { BiArrowToBottom } from 'react-icons/bi'
import { useRecoilState } from 'recoil'
import { blnWisudaTracer, rekapInfoWisudaTracer, rekapWisudaTracer, thnWisudaTracer } from '../../atoms'
import { getRekapWisudaTracerController } from '../../controllers/apicontrollers'
import { setupMonth, setupTahun } from '../../shared/ta'
import { Caption, Card, Column, GridCol, HorizontalDivider, LargeHeadline, Row, SelectView, SimpleAlert, Table, TableBody, TableCell, TableHead, TableRow, Text, Title } from '../../shared/ui'
import RowTableRekapWisudaTracer from './rows/row_rekap_wisuda_tracer'

const TableRekapWisudaTracer = ({ onDoneLoadData, action = 10 }) => {

    const [listRekapWisuda, setListRekapWisuda] = useRecoilState(rekapWisudaTracer)
    const [infoRekapWisuda, setInfoRekapWisuda] = useRecoilState(rekapInfoWisudaTracer)
    const [searchQuery, setSearchQuery] = useState(null)
    const [sortTable, setShortTable] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [listYear, setListYear] = useState([])
    const [listMonth, setListMonth] = useState([])
    const [currentYear, setCurrentYear] = useRecoilState(thnWisudaTracer)
    const [currentMonth, setCurrentMonth] = useRecoilState(blnWisudaTracer)

    const getRekapWisudaTracer = async () => {
        const props = {
            action: action,
            year: currentYear.value,
            month: currentMonth.value
        }
        const result = await getRekapWisudaTracerController(props)
        if (result.status) {
            setListRekapWisuda(result.data)
        }
        onDoneLoadData(true)
    }

    const sumJmlWisuda = () => {
        let jmlWisuda = 0;
        let jmlTracer = 0;
        listRekapWisuda.map((wisuda) => {
            jmlWisuda += wisuda.lulus
            jmlTracer += wisuda.isi_tracer
        })
        if (currentYear.value === 2019) {
            jmlTracer = 769
            jmlWisuda = 1833
        } else if (currentYear.value === 2018) {
            jmlTracer = 826
            jmlWisuda = 1428
        } else if (currentYear.value === 2017) {
            jmlTracer = 801
            jmlWisuda = 1539
        }
        const infoRekap = [
            {
                label: 'Total Wisudawan',
                value: jmlWisuda,
            },
            {
                label: 'Total Mengisi Tracer',
                value: jmlTracer,
            },
            {
                label: 'Persentase Mengisi Tracer',
                value: jmlWisuda > 0 ? `${((jmlTracer / jmlWisuda) * 100).toFixed(2)}%` : '0%',
            },
        ]
        setInfoRekapWisuda(infoRekap)
    }

    const onChangeShowNumber = (value) => {
        setLimitRow(value)
    }

    const onChangeQuerySearch = (value) => {
        setSearchQuery(value)
    }

    const onShortableValue = (value) => {
        setShortTable(value)
    }

    const onChangeYear = (value) => {
        setCurrentYear(value)
    }

    const onChangeSmt = (value) => {
        setCurrentMonth(value)
    }

    useEffect(() => {
        sumJmlWisuda()
    }, [listRekapWisuda])

    useEffect(() => {
        setListYear(setupTahun({ start: 2017 }))
        setListMonth(setupMonth({ useAllMonth: true }))
    }, [])

    useEffect(() => {
        getRekapWisudaTracer()
    }, [currentYear, currentMonth])

    return (
        <Card className='w-full p-0'>
            <Column>
                <Row className='flex-col sm:flex-row gap-4 px-5 py-4 justify-between'>
                    <Column className='gap-0.5 my-auto'>
                        <Title>
                            Data Wisuda
                        </Title>
                        <Row className='flex-wrap gap-y-1 gap-x-1'>
                            <Text>
                                {currentMonth.value !== 0 ? currentMonth.label : 'Tahun'}
                            </Text>
                            <Text>
                                {currentYear.value}
                            </Text>
                        </Row>
                    </Column>
                    <Row className='w-fit gap-3 my-auto flex-wrap sm:flex-nowrap'>
                        <SelectView width='w-28' initial={currentYear} options={listYear} onChangeOptions={onChangeYear} zindex='z-20' bgColor='bg-slate-50' />
                        <SelectView width='w-40' initial={currentMonth} options={listMonth} onChangeOptions={onChangeSmt} zindex='z-10' bgColor='bg-slate-50' />
                        {/* <ButtonHover className='my-auto'>
                            <TertiaryButton color='indigo' className='py-2 pl-3 pr-4' leadingIcon={<TbDownload className='h-5 w-5 my-auto' />}>
                                Unduh
                            </TertiaryButton>
                        </ButtonHover> */}
                    </Row>
                </Row>
                <SimpleAlert type='warning' className='rounded-none' borderLeft showCloseBtn>
                    Menampilkan total wisudawan dan total pengisian Tracer Studi setiap program studi pada {currentMonth.value === 0 ? 'Tahun' : 'bulan ' + currentMonth.label} {currentYear.label}.
                </SimpleAlert>
                <HorizontalDivider className='mt-0 mb-0' />
                <div>
                    <GridCol className='gap-5 grid-cols-1 px-5 pt-5 sm:grid-cols-2 xl:grid-cols-3'>
                        {infoRekapWisuda.map((info, idx) => {
                            return (
                                <Card key={idx} className='w-full h-full p-5 shadow-none'>
                                    <Column className='gap-5'>
                                        <Text className='font-medium'>
                                            {info.label}
                                        </Text>
                                        <Row className='justify-between'>
                                            <LargeHeadline className='font-normal font-inter'>
                                                {info.value}
                                            </LargeHeadline>
                                            <Card className='px-2.5 py-1 rounded-full mt-auto p-5 shadow-none'>
                                                <Row className='gap-1'>
                                                    <BiArrowToBottom className='h-4 w-4 my-auto text-orange-600' />
                                                    <Caption className='text-gray-800'>
                                                        10%
                                                    </Caption>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Column>
                                </Card>
                            )
                        })}
                    </GridCol>
                </div>
                <div className='p-5'>
                    <Table
                        className='border-[1px] border-gray-200'
                        limitRow={limitRow}
                        trigerPagination={sortTable}
                        showPaginationAndSearch={false}
                        onChangeShowNumber={onChangeShowNumber}
                        onChangeQuerySearch={onChangeQuerySearch}>
                        <TableHead>
                            <TableRow className='bg-slate-100/50 border-b-[1px] border-b-gray-200'>
                                <TableCell className='font-semibold font-manrope w-[5%]'>
                                    No.
                                </TableCell>
                                <TableCell className='font-semibold font-manrope'>
                                    Jenjang
                                </TableCell>
                                <TableCell className='font-semibold font-manrope'>
                                    Jurusan
                                </TableCell>
                                <TableCell className='font-semibold font-manrope text-center'>
                                    Jumlah Lulusan
                                </TableCell>
                                <TableCell className='font-semibold font-manrope text-center'>
                                    Jumlah Mengisi Tracer
                                </TableCell>
                                <TableCell className='font-semibold font-manrope text-center'>
                                    Persentase
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='divide-y-[1px] divide-gray-200'>
                            {listRekapWisuda.length > 0 ? listRekapWisuda.map((wisuda, idx) => {
                                return (
                                    <RowTableRekapWisudaTracer
                                        key={idx}
                                        idx={idx}
                                        wisuda={wisuda}
                                        limitRow={limitRow} />
                                )
                            }) : <TableRow>
                                <TableCell colSpan={6} className='text-center text-gray-500'>
                                    No data to display
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
            </Column>
        </Card>
    )
}

export default TableRekapWisudaTracer
