import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFullNameUser } from '../../../controllers/apicontrollers';
import { getViewDisplay } from '../../../shared/lib';
import { Caption, Column, Headline3, Row, ShimmerAnimation, ShimmerItem, Text } from '../../../shared/ui';
import { recognitionRoute } from '../../../shared/variable';
import WGridImageRecognition from '../../wgrid_image_recognition';
import WListImageRecognition from '../../wlist_image_recognition';

const ItemSavedOrTagRecognition = ({ recognition }) => {

    const navigateTo = useNavigate()

    const [fullName, setFullName] = useState(null)

    const getFullNameOfUser = async () => {
        const result = await getFullNameUser(recognition.id_identify, recognition.id_user)
        if (result.status) {
            setFullName(result.data)
        }
    }

    useEffect(() => {
        getFullNameOfUser()
    }, [])

    return (
        <Column
            className={'cursor-pointer p-4 gap-y-8 bg-white border-y transition-all duration-200 sm:p-8 border-x border-gray-200 lg:shadow-sm lg:hover:shadow-md'}
            onClick={() => navigateTo(`${recognitionRoute}/${recognition.slug}`)}>
            <Column className={'gap-y-5'}>
                <Column className={'gap-y-3'}>
                    <Headline3 className={'transition-all duration-200 cursor-pointer hover:text-indigo-600'}>
                        {recognition.title}
                    </Headline3>
                    <Caption>
                        {moment(recognition.created_at).format('DD/MM/YYYY HH.mm')} WIB
                    </Caption>
                </Column>
                {recognition.images.length > 0 ? recognition.view === 'list' ?
                    <WListImageRecognition images={recognition.images} aspectRatio={recognition.rasio} action={20} /> :
                    <WGridImageRecognition images={recognition.images} aspectRatio={recognition.rasio} action={20} /> : null}
                <div
                    className={'text-gray-800 font-inter text-justify text-base mt-2'}
                    dangerouslySetInnerHTML={{
                        __html: recognition.body.split('\n')[0].replaceAll('</p>', ' <span style="font-weight: 500; color: #4F46E5;">Baca selengkapnya</span></p>')
                    }} />
            </Column>
            <Column className={'gap-y-5'}>
                <Column className={'gap-y-2'}>
                    <Row className={'gap-x-1 flex-wrap items-center'}>
                        <Text>
                            Diposting oleh
                        </Text>
                        {fullName !== null ? <Text className={'font-semibold font-manrope capitalize'}>
                            {fullName}
                        </Text> : <ShimmerAnimation>
                            <ShimmerItem className={'h-4 w-28 rounded-md'} />
                        </ShimmerAnimation>}
                    </Row>
                    <Caption>
                        {getViewDisplay(recognition.viewer ?? 0)}x dilihat
                    </Caption>
                </Column>
            </Column>
        </Column>
    )
}

export default ItemSavedOrTagRecognition
