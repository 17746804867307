import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableAcceptedRecognition from "./rows/row_accepted_recognition";
import {
  AlertDialog,
  Avatar,
  Caption,
  Card,
  IconButton,
  LoadingDialog,
  PrimaryButton,
  SecondaryButton,
  ShimmerAnimation,
  ShimmerItem,
  Subtitle,
  Text,
  Title,
} from "../../../../shared/ui";
import { Column, Row } from "../../../../components/interfaces/block";
import {
  TbChartBar,
  TbHeart,
  TbMessageCircle,
  TbTrash,
  TbX,
} from "react-icons/tb";
import CCommentsRecognition from "../../../../components/list/item/item_comment_recognition";
import WListImageRecognition from "../../../../components/wlist_image_recognition";
import WGridImageRecognition from "../../../../components/wgrid_image_recognition";
import HTMLReactParser from "html-react-parser";
import {
  deleteRecognition,
  getFullNameUser,
} from "../../../../controllers/apicontrollers";
import toast from "react-hot-toast";

function TableAcceptedRecognition({ listRecognition, page, limit, onShort }) {
  const [recognition, setRecognition] = useState(null);
  const [currentSort, setCurrentSort] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState(null);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDeleteDialog = () => {
    setShowDialog(false);
    setShowDeleteDialog(true);
  };

  const onHandleClick = (recognition) => {
    setRecognition(recognition);
    setTags(recognition.tags);
    setTitle(recognition.title);
    setShowDialog(true);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const deleteCurrentRecognition = async () => {
    setShowDeleteDialog(false);
    setLoading(true);
    const result = await deleteRecognition(recognition.id_rekognisi);
    if (result.status) {
      setTimeout(() => {
        filterRecognition();
        setLoading(false);
        toast.success("Hapus rekognisi berhasil");
      }, 200);
    }
  };

  const getViewDisplay = (view) => {
    let result = 0;
    if (view >= 1000000) {
      result = `${(view / 1000000).toFixed(1)}M`;
    } else if (view >= 1000) {
      result = `${(view / 1000).toFixed(1)}K`;
    } else {
      result = view;
    }
    return result;
  };

  const filterRecognition = () => {
    // const newRecognition = listRecognition.filter(
    //   (rec) => rec.id_rekognisi !== recognition.id_rekognisi
    // );
  };

  const [fullName, setFullName] = useState(null);
  const getFullNameOfUser = async () => {
    const result = await getFullNameUser(
      recognition.id_identify,
      recognition.id_user
    );
    if (result.status) {
      setFullName(result.data);
    }
  };

  useEffect(() => {
    getFullNameOfUser();
  }, [recognition]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Penulis</TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="title"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Judul Berita
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="viewer"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Kunjungan
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="likes"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Menyukai
            </TableHeadCell>
            <TableHeadCell>Komentar</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listRecognition.length > 0 ? (
            listRecognition.map((recognition, idx) => {
              return (
                <RowTableAcceptedRecognition
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  recognition={recognition}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog
        className="max-w-4xl"
        show={showDialog}
        onClose={closeDialog}
      >
        <Column className="divide-y">
          {recognition !== null && (
            <Row className="divide-x">
              <Column className="w-full max-w-lg max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
                <Row className="gap-2.5 px-6 pt-6 pb-5 sticky top-0 bg-white z-10">
                  <Avatar src={recognition.avatar} />

                  <Column className="flex-1">
                    {recognition && recognition.id_user ? (
                      <Caption className="font-bold font-manrope text-gray-800">
                        {fullName}
                      </Caption>
                    ) : (
                      <ShimmerAnimation>
                        <ShimmerItem className={"w-32 h-4"} />
                      </ShimmerAnimation>
                    )}
                    <Caption>{recognition.id_user}</Caption>
                  </Column>
                </Row>
                <Column className="gap-3 px-6 pb-10">
                  {recognition.images.length > 0 ? (
                    recognition.view === "list" ? (
                      <WListImageRecognition
                        images={recognition.images}
                        aspectRatio={recognition.rasio}
                        action={20}
                      />
                    ) : (
                      <WGridImageRecognition
                        images={recognition.images}
                        aspectRatio={recognition.rasio}
                        action={20}
                      />
                    )
                  ) : null}
                  <Subtitle className="font-semibold">
                    {recognition.title}
                  </Subtitle>
                  <Column className="text-base inter">
                    {HTMLReactParser(recognition.body, options)}
                  </Column>
                  {recognition !== null ? (
                    <Caption className="w-fit font-bold jakarta text-gray-800">
                      <span className="font-normal inter">Diposting oleh </span>
                      <span>{fullName}</span>
                      {recognition.tags.length > 0 && (
                        <span className="font-normal inter"> bersama </span>
                      )}
                      {recognition.tags.length > 0 &&
                        tags.map(
                          (tag, idx) => idx === 0 && <span>{tag.nama}</span>
                        )}
                      {recognition.tags.length > 1 && (
                        <span className="font-normal inter"> dan </span>
                      )}
                      {recognition.tags.length > 1 && (
                        <span>{recognition.tags.length - 1} lainnya</span>
                      )}
                    </Caption>
                  ) : (
                    <ShimmerAnimation>
                      <ShimmerItem className="h-4 w-32 mb-1" />
                    </ShimmerAnimation>
                  )}
                </Column>
              </Column>
              <Column className="w-full max-w-sm min-h-[45rem] max-h-[45rem] overflow-y-auto scrollbar-hide scroll-smooth">
                <Row className="gap-2.5 px-4 py-4 bg-white fixed top-0 z-10 border-b">
                  <Card className="rounded-full border-none bg-indigo-50 p-0 h-8 w-8 justify-center items-center">
                    <TbMessageCircle className="h-5 w-5 text-indigo-700" />
                  </Card>
                  <Subtitle className="font-semibold my-auto">
                    Komentar
                  </Subtitle>
                </Row>
                <Column className="px-4 pt-16 flex-col-reverse h-full overflow-y-auto scrollbar-hide scroll-smooth gap-y-6 mt-6">
                  {recognition.comments.length > 0 ? (
                    recognition.comments.map((comment, idx) => (
                      <CCommentsRecognition key={idx} comment={comment} />
                    ))
                  ) : (
                    <Column className="gap-2 pt-4">
                      <TbMessageCircle className="h-20 w-20 stroke-1.5 text-gray-300 mx-auto" />
                      <Caption className="mx-auto">Tidak ada komentar</Caption>
                    </Column>
                  )}
                </Column>
              </Column>
            </Row>
          )}
          <Row className="gap-3 justify-end px-5 py-5">
            {recognition !== null && (
              <Row className="gap-5 my-auto">
                <Row className="w-fit gap-2">
                  <TbChartBar className="h-5 w-5 text-blue-700" />
                  <Caption>
                    {getViewDisplay(recognition.viewer ?? 0)} views
                  </Caption>
                </Row>
                <Row className="w-fit gap-2">
                  <TbHeart className="h-5 w-5 text-pink-700" />
                  <Caption>
                    {getViewDisplay(recognition.likes ?? 0)} likes
                  </Caption>
                </Row>
                <Row className="w-fit gap-2">
                  <TbMessageCircle className="h-5 w-5 text-green-700" />
                  <Caption>
                    {getViewDisplay(recognition.comments.length ?? 0)} comments
                  </Caption>
                </Row>
              </Row>
            )}
            <SecondaryButton className="w-fit pr-5" onClick={closeDialog}>
              <TbX className="h-5 w-5" /> Tutup
            </SecondaryButton>
            <PrimaryButton
              className="w-fit pr-5 flex-shrink-0"
              color="red"
              onClick={openDeleteDialog}
            >
              <TbTrash className="h-5 w-5" /> Hapus Rekognisi
            </PrimaryButton>
          </Row>
        </Column>
        <IconButton
          onClick={closeDialog}
          className="absolute top-1 right-1 z-10"
        >
          <TbX className="h-5 w-5" />
        </IconButton>
      </AlertDialog>
      <AlertDialog show={showDeleteDialog} onClose={closeDeleteDialog}>
        <Column className="divide-y">
          <Column className="gap-5 p-6">
            <Column className="gap-1">
              <Title className="font-bold">Hapus Rekognisi</Title>
              {recognition !== null && (
                <Text>
                  Apakah anda yakin ingin menghapus rekognisi{" "}
                  <span className="font-medium text-indigo-700">
                    {recognition.title}
                  </span>
                  ?
                </Text>
              )}
            </Column>
          </Column>
          <Row className="gap-3 justify-end px-6 py-5">
            <SecondaryButton className="w-fit pr-5" onClick={closeDeleteDialog}>
              <TbX className="h-5 w-5" /> Batal
            </SecondaryButton>
            <PrimaryButton
              className="w-fit pr-5"
              color="red"
              onClick={deleteCurrentRecognition}
            >
              <TbTrash className="h-5 w-5" /> Ya, Hapus
            </PrimaryButton>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
}

export default TableAcceptedRecognition;
