import { useEffect, useState } from "react";
import { Column, Row } from "../../../../components/interfaces/block";
import { Card } from "../../../../components/interfaces/card";
import {
  CaptionText,
  Headline1Text,
  TitleText,
} from "../../../../components/interfaces/text";
import {
  getExcelDataKerja6BulanController,
  getOvRataPendapatanTracerController,
} from "../../../../controllers/admin_controllers";
import { ButtonHover, DotSeparated } from "../../../../shared/ui";
import { SiMicrosoftexcel } from "react-icons/si";
import moment from "moment";
import useDownloadExcel from "../../../../hooks/useDownloadExcel";

function CardRataPenghasilan({
  prodi,
  currentDateStart,
  currentDateEnd,
  takehome,
  currentTabIndex,
  binaryData,
}) {
  const [locTakeHome, setLocTakeHome] = useState(0);

  const getOvRataPendapatanTracer = async () => {
    const props = {
      prodi: prodi.value,
      date_start: currentDateStart.value,
      date_end: currentDateEnd.value,
    };
    const result = await getOvRataPendapatanTracerController(props);
    if (result.status) {
      setLocTakeHome(result.data);
    }
  };

  useEffect(() => {
    if (
      prodi.value != null ||
      currentDateStart.value != null ||
      currentDateEnd.value != null
    ) {
      getOvRataPendapatanTracer();
    }
  }, [prodi, currentDateStart, currentDateEnd]);

  // download file excel
  const fileName = `Rata-Rata Penghasilan Lulusan_${
    currentTabIndex === 0
      ? currentDateStart.value !== null || currentDateEnd.value !== null
        ? `${moment(currentDateStart.value).format("YYYY")}_${moment(
            currentDateEnd.value
          ).format("YYYY")}`
        : "Semua Tahun"
      : currentTabIndex === 1
      ? currentDateStart.value !== null || currentDateEnd.value !== null
        ? moment(currentDateStart.value).format("YYYY")
        : "Semua Tahun"
      : "Semua Tahun"
  }_${prodi.value === null ? "Semua Program Studi" : prodi.label}`;

  const downloadExcel = useDownloadExcel({ fileName });

  const handleDownloadExcel = async () => {
    const res = await getExcelDataKerja6BulanController({
      date_start: currentDateStart.value,
      date_end: currentDateEnd.value,
      prodi: prodi.value,
    });
    downloadExcel(res);
  };

  return (
    <Card className="w-full h-full p-6">
      <Column className={"gap-y-6 h-full"}>
        <Column className={"gap-y-1"}>
          <Row>
            <Column>
              <TitleText> Rata-Rata Penghasilan Lulusan</TitleText>
            </Column>
            <Column className={"flex-row w-fit justify-end items-end "}>
              <ButtonHover>
                <button onClick={() => handleDownloadExcel(binaryData)}>
                  <SiMicrosoftexcel className={`w-6 h-6 text-gray-500`} />
                </button>
              </ButtonHover>
            </Column>
          </Row>
          <Row>
            <CaptionText>
              {prodi.value ? prodi.label : "Semua Prodi"}
            </CaptionText>
            <DotSeparated />
            <CaptionText>
              {currentTabIndex === 0
                ? currentDateStart.value && currentDateEnd.value
                  ? `Tahun ${moment(currentDateStart.value).format(
                      "YYYY"
                    )}/${moment(currentDateEnd.value).format("YYYY")}`
                  : "Semua Tahun"
                : currentDateStart.value && currentDateEnd.value
                ? `Tahun ${moment(currentDateStart.value).format("YYYY")}`
                : "Semua Tahun"}
            </CaptionText>
          </Row>
        </Column>
        <Headline1Text className={"font-semibold my-auto"}>
          {(prodi.value != null ||
          currentDateStart.value != null ||
          currentDateEnd.value != null
            ? locTakeHome
            : takehome) ?? "Rp 0"}
        </Headline1Text>
      </Column>
    </Card>
  );
}

export default CardRataPenghasilan;
