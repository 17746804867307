import moment from 'moment'
import React from 'react'
import { TbAlertHexagon, TbArrowRight } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { ButtonHover, IconButton, TagView } from '../../../../../shared/ui'

function RowTableRejectedConsultation({ idx, consultation, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow
      striped
      onClick={() => handleClick(consultation)}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {consultation.nama_alumni}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {consultation.topik}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='line-clamp-2'>
          {consultation.subjek}
        </BodyText>
      </TableCell>
      <TableCell>
        {moment(consultation.created_at).format('DD MMM YYYY HH.mm')}
      </TableCell>
      <TableCell>
        {moment(consultation.updated_at).format('DD MMM YYYY HH.mm')}
      </TableCell>
      <TableCell>
        <TagView
          className={'mx-auto'}
          leadingIcon={
            <TbAlertHexagon className='h-4 w-4' />
          }
          label='Ditolak'
          color='red' />
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            className='p-1.5'
            color='indigo'
            bordered
            onClick={() => handleClick(consultation)}>
            <TbArrowRight className='h-5 w-5' />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableRejectedConsultation