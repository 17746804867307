import React, { useEffect, useState } from "react";
import {
  TbAlertTriangle,
  TbArrowLeft,
  TbArrowRight,
  TbUpload,
  TbX,
} from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataTracer, indexInputTracer, inputTracerAction } from "../../atoms";
import WAlmKueDua from "../../components/walm_kue_dua";
import WAlmKueSatu from "../../components/walm_kue_satu";
import WAlmKueTiga from "../../components/walm_kue_tiga";
import { saveTracerStudyUser } from "../../controllers/apicontrollers";
import {
  AlertDialog,
  Card,
  Column,
  Headline2,
  Headline3,
  LoadingDialog,
  PrimaryButton,
  Row,
  SecondaryButton,
  Steps,
  Subtitle,
  TertiaryButton,
  Text,
  TextButton,
  Title,
  TransitionPage,
} from "../../shared/ui";
import {
  almTracerRoute,
  landingRoute,
  notFoundRoute,
} from "../../shared/variable";
import { getCurrentUser } from "../../shared/user";

const InputTracerAlumni = () => {
  const { id } = useParams();
  const { tglwisuda, tgllulus } = getCurrentUser();
  const navigateTo = useNavigate();
  const tracer = useRecoilValue(dataTracer);
  const inputAction = useRecoilValue(inputTracerAction);
  const [currentIndex, setCurrentIndex] = useRecoilState(indexInputTracer);
  const [reqCheckOne, setReqChekOne] = useState(false);
  const [reqCheckTwo, setReqChekTwo] = useState(false);
  const [reqCheckThree, setReqChekThree] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(tracer);

  const changeCurrentIndex = (action) => {
    window.scrollTo(0, 0);
    if (action === 10) {
      setReqChekOne(false);
      setReqChekTwo(false);
      setReqChekThree(false);

      setCurrentIndex(currentIndex - 1);
    } else {
      if (currentIndex === 0) {
        setReqChekOne(true);
      } else if (currentIndex === 1) {
        setReqChekTwo(true);
      } else {
        setReqChekThree(true);
      }
    }
  };

  const onCheckResult = (result) => {
    if (result["status"]) {
      if (currentIndex < 2) {
        const value = result["value"];
        const temp = currentAnswer;
        setCurrentAnswer({ ...temp, ...value });
        setCurrentIndex(currentIndex + 1);
      } else {
        const value = result["value"];
        const temp = currentAnswer;
        setCurrentAnswer({ ...temp, ...value });
        setShowDialog(true);
      }
    } else {
      if (currentIndex === 0) {
        setReqChekOne(false);
      } else if (currentIndex === 1) {
        setReqChekTwo(false);
      } else {
        setReqChekThree(false);
      }
    }
  };

  const closeDialog = (value) => {
    setShowDialog(false);
    setReqChekThree(false);
  };

  const saveTracerStudy = async () => {
    const payload = { ...currentAnswer, tgl_lulus: tgllulus };

    const result = await saveTracerStudyUser(payload);
    if (result.status) {
      setShowDialog(false);
      navigateTo(almTracerRoute, { replace: true });
    }
  };

  useEffect(() => {
    if (tglwisuda) {
      const sixMonth = new Date();
      sixMonth.setMonth(sixMonth.getMonth() - 6);

      const graduationDate = new Date(tglwisuda);

      if (graduationDate >= sixMonth) {
        navigateTo(notFoundRoute);
      }
    }
  }, [tglwisuda]);

  useEffect(() => {}, [currentAnswer]);

  useEffect(() => {
    if (id !== "input" && id !== "update") {
      navigateTo(notFoundRoute);
    }
  }, [id, navigateTo]);

  useEffect(() => {}, [tgllulus]);

  return (
    <>
      <TransitionPage>
        <Column className="w-full relative overflow-hidden max-w-3xl px-3.5 pt-[6.5rem] pb-14 gap-5 mx-auto">
          <Column className="gap-1">
            <Headline2>
              {inputAction === 10
                ? "Input Tracer Studi"
                : "Perbarui Tracer Studi"}
            </Headline2>
            <Subtitle className="font-medium">
              Universitas Teknologi Yogyakarta
            </Subtitle>
          </Column>
          <Steps index={currentIndex}>
            <WAlmKueSatu
              answer={currentAnswer}
              reqCheck={reqCheckOne}
              onCheck={onCheckResult}
            />
            <WAlmKueDua
              answer={currentAnswer}
              reqCheck={reqCheckTwo}
              onCheck={onCheckResult}
            />
            <WAlmKueTiga
              answer={currentAnswer}
              reqCheck={reqCheckThree}
              onCheck={onCheckResult}
            />
          </Steps>
          <Row className={`w-fit gap-3`}>
            {currentIndex > 0 && (
              <TertiaryButton
                onClick={() => changeCurrentIndex(10)}
                leadingIcon={<TbArrowLeft className="h-5 w-5" />}
                className="pr-5"
              >
                Kembali
              </TertiaryButton>
            )}
            <PrimaryButton
              color="indigo"
              onClick={() => changeCurrentIndex(20)}
              leadingIcon={
                currentIndex === 2 && <TbUpload className="h-5 w-5" />
              }
              trailingIcon={
                currentIndex < 2 && <TbArrowRight className="h-5 w-5" />
              }
              className={`${currentIndex === 2 ? "pr-5" : "pl-5"}`}
            >
              {currentIndex === 2 ? "Simpan" : "Berikutnya"}
            </PrimaryButton>
          </Row>
        </Column>
      </TransitionPage>
      <AlertDialog
        show={showDialog}
        onClose={closeDialog}
        className="w-full max-w-sm"
      >
        <Column className="divide-y">
          <Column className="p-6 gap-1">
            <Title className="font-bold">
              {inputAction === 10 ? "Simpan Tracer" : "Simpan Perubahan"}
            </Title>
            <Text>
              {inputAction === 10
                ? "Apakah anda yakin ingin menyimpan tracer studi?"
                : "Apakah anda yakin ingin menyimpan perubahan data tracer studi?"}
            </Text>
          </Column>
          <Row className="gap-3 justify-end px-5 py-5">
            <SecondaryButton
              onClick={closeDialog}
              className="pr-5"
              leadingIcon={<TbX className="h-5 w-5" />}
            >
              Batal
            </SecondaryButton>
            <PrimaryButton
              onClick={saveTracerStudy}
              color="indigo"
              className="pr-5"
              leadingIcon={<TbUpload className="h-5 w-5" />}
            >
              Simpan
            </PrimaryButton>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loadingSave} message="Menyimpan tracer..." />
    </>
  );
};

export default InputTracerAlumni;
