import React, { useEffect, useState } from 'react'

const WAspectRatioImage = ({ initRatio, onChange }) => {

    const [selectedId, setSelectedId] = useState()

    const aspectRatio = [
        {
            id: 1,
            label: '16 : 9',
            value: 'aspect-video',
        },
        {
            id: 2,
            label: '4 : 3',
            value: 'aspect-[4/3]',
        },
        {
            id: 3,
            label: '3 : 4',
            value: 'aspect-[3/4]',
        },
        {
            id: 4,
            label: '1 : 1',
            value: 'aspect-square',
        },
    ]

    const changeSelectedId = (aspect) => {
        setSelectedId(aspect.id)
        onChange(aspect.value)
    }

    useEffect(() => {
        switch (initRatio) {
            case 'aspect-video':
                setSelectedId(1)
                break;
            case 'aspect-[4/3]':
                setSelectedId(2)
                break;
            case 'aspect-square':
                setSelectedId(3)
                break;
            case 'aspect-auto':
                setSelectedId(4)
                break;
            default:
                setSelectedId(1)
                break;
        }
    }, [initRatio])

    return (
        <div className='w-fit max-w-full flex flex-row bg-slate-50 rounded-lg overflow-x-auto scrollbar-hide scroll-smooth border-[1px] divide-x-[1px]' role='group'>
            {aspectRatio.map((aspect, idx) => {
                return <button
                    key={idx}
                    type='button'
                    onClick={() => changeSelectedId(aspect)}
                    className={`w-20 flex-shrink-0 px-4 py-2 font-inter text-base font-medium ${selectedId === aspect.id ?
                        'text-indigo-700 bg-white' : 'text-gray-800 hover:text-indigo-700 hover:bg-slate-100'}`}>
                    {aspect.label}
                </button>
            })}
        </div>
    )
}

export default WAspectRatioImage
