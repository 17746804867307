import { useLocation, useNavigate } from "react-router-dom";
import {
  AlertDialog,
  Avatar,
  ButtonHover,
  Column,
  Headline3,
  HorizontalDivider,
  LoadingDialog,
  PrimaryButton,
  Row,
  Sidebar,
  SidebarHeader,
  SidebarMenu,
  Subtitle,
  TertiaryButton,
  Text,
  TextButton,
} from "../../shared/ui";
import { logoutAccountController } from "../../controllers/global_controllers";
import { getCurrentUser } from "../../shared/user";
import {
  TbBell,
  TbBriefcase,
  TbCategory,
  TbLogout2,
  TbX,
} from "react-icons/tb";
import {
  loginRoute,
  mitCareerRoute,
  mitNotificationRoute,
  mitRootRoute,
} from "../../shared/variable";
import { useState } from "react";
import avatar1 from "../../images/logo.png";

function MitSidebar({ showOnMobile }) {
  const location = useLocation();
  const navigateTo = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { name, username } = getCurrentUser();

  const logoutAccount = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await logoutAccountController();
    if (result) {
      setTimeout(() => {
        localStorage.clear();
        setLoading(false);
        navigateTo(loginRoute, { replace: true });
      }, 300);
    }
  };

  return (
    <>
      <Sidebar showOnMobile={showOnMobile}>
        <SidebarMenu className={"p-4 gap-y-3.5 pb-36"}>
          <SidebarHeader>
            <Column className="gap-3 py-3 text-center">
              <Avatar className="h-24 w-24 mx-auto" src={avatar1} />
              <Column>
                <Subtitle className="text-gray-800 font-semibold jakarta">
                  {name}
                </Subtitle>
                {username}
              </Column>
            </Column>
          </SidebarHeader>
          <TextButton
            align="left"
            color="custom"
            leadingIcon={<TbCategory className={"h-6 w-6"} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${
              location.pathname === mitRootRoute && "text-indigo-600 bg-gray-50"
            } hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(mitRootRoute)}
          >
            Dashboard
          </TextButton>
          <TextButton
            align="left"
            color="custom"
            leadingIcon={<TbBriefcase className={"h-6 w-6"} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${
              location.pathname === mitCareerRoute &&
              "text-indigo-600 bg-gray-50"
            } hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(mitCareerRoute)}
          >
            Lowongan
          </TextButton>
          <HorizontalDivider />
          <TextButton
            align="left"
            color="custom"
            leadingIcon={<TbBell className={"h-6 w-6"} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${
              location.pathname === mitNotificationRoute &&
              "text-indigo-600 bg-gray-50"
            } hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(mitNotificationRoute)}
          >
            Notifikasi
          </TextButton>
        </SidebarMenu>
        <Column
          className={
            "absolute bottom-0 inset-x-0 p-4 bg-white border-t border-gray-200"
          }
        >
          <ButtonHover className={"w-full"}>
            <PrimaryButton
              pills
              color="red"
              className={"w-full"}
              leadingIcon={<TbLogout2 className={"h-5 w-5 my-auto"} />}
              onClick={() => setShowDialog(true)}
            >
              Keluar Akun
            </PrimaryButton>
          </ButtonHover>
        </Column>
      </Sidebar>
      <AlertDialog
        show={showDialog}
        className={"max-w-md"}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <Column className={"p-5 gap-y-8 sm:p-8"}>
          <Column className="gap-y-2">
            <Headline3>Keluar Akun</Headline3>
            <Text>Apakah anda yakin ingin keluar dari Simfora UTY?</Text>
          </Column>
          <Row className={"gap-x-3 justify-between"}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                className={"pl-5 pr-6"}
                onClick={() => setShowDialog(false)}
              >
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color="red"
                className="pl-5 pr-6"
                leadingIcon={<TbLogout2 className="h-5 w-5 my-auto" />}
                onClick={logoutAccount}
              >
                Keluar
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message="Keluar dari akun..." />
    </>
  );
}

export default MitSidebar;
